import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';
import { getCompanyLogo } from './../../service/commonService'
import CONSTANTS from '../../constants/constants';

const { Text } = Typography;

const SignupPoster = () => {
    const [clientLogos, setClientLogos] = useState([]);

    useEffect(() => {
        (async () => {
            const res = await getCompanyLogo(CONSTANTS.SIGNUP_PAGE_LOGO_CLIENTS);

            setClientLogos(res?.data?.logos)
        })();
    }, []);

    return (
        <div className='main-poster'>
            <div className='poster-container'>
                <div className='poster-top'>
                    <div className='poster-left'>
                        <div className='poster-title'>
                            <Text className='text-props'>Become a part of the</Text>
                            <Text>Vahan Leader Program</Text>
                        </div>
                        <div className='poster-middle'>
                            <Text className='text-props'>Refer Delivery Boys<br />Earn ₹25,000 to<br />₹50,000 per month</Text>
                        </div>
                        <div className='poster-perks'>
                            <div className='perk-props'>
                                <img className='perk-icon' src="/image/rupee.png" />
                                <Text className='perk-detail'>Get Paid <br />Every 10 Days</Text>
                            </div>
                            <div className='perk-props'>
                                <img className='perk-icon' src="/image/perf.png" />
                                <Text className='perk-detail'>Track Performance <br />& Earnings</Text>
                            </div>
                            <div className='perk-props'>
                                <img className='perk-icon' src="/image/face.png" />
                                <Text className='perk-detail'>Dedicated Account <br />Manager for Support</Text>
                            </div>
                        </div>
                    </div>
                    <div className='poster-right'>
                        <img className='poster-image' src='image/signup_man.png' />
                    </div>
                </div>
                <div className='poster-bottom'>
                    <div className='bottom-container'>
                        <Text>Work with <Text className='text-props'>India’s Top Companies</Text></Text>
                        <div className='logo-container'>
                            {
                                clientLogos ? clientLogos.map((clientData, index) => {
                                    return <img key={index} className='bottom-logos' alt={clientData?.title} src={clientData?.logo} />
                                }) : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignupPoster;
