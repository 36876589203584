export const navigationCheck = (role) => {
    const insurance_team_member = (role != 'admin' && process.env.REACT_APP_INSURANCE_SHOW_TEAM_MEMBER == '1') ? true : false;
    const insurance_manager = (role == 'admin' && process.env.REACT_APP_INSURANCE_SHOW_MANAGER == '1') ? true : false;
    const incentive = (role == 'admin' && process.env.REACT_APP_INSURANCE_SHOW_INCENTIVE == '1') ? true : false;
    const client_update = (process.env.REACT_APP_INSURANCE_SHOW_CLIENT_UPDATE == '1') ? true : false;
    const training_Hub = (process.env.REACT_APP_INSURANCE_SHOW_TRAINING_HUB == '1') ? true : false;
    const help = (process.env.REACT_APP_INSURANCE_SHOW_HELP == '1') ? true : false;

    return { insurance_team_member, insurance_manager, incentive, client_update, training_Hub, help }
};

export const getAllsearchParams = (search) => {

    let query = new URLSearchParams(search);
    let data = {};
    for (let params of query.entries()) {
        data[params[0]] = params[1] ? params[1] : null;
    }
    return data
};

export const getFilteredValue = (arr, keyLabel) => {
    let result = [];
    arr.filter(item => item?.value === true).map(item => {
        result.push(item[keyLabel])
    });
    return result;
}

export const uniquefilteredArrObj = (arr, label) => {
    const seen = new Set();
    const filteredArr = arr.filter(el => {
        const duplicate = seen.has(el[label]);
        seen.add(el[label]);
        return !duplicate;
    });
    return filteredArr;
}

export const getDisplayableClientName = (clientName) => {
    if (clientName) {
        clientName = clientName.replace(/_3PL/g, ' With Vahan').replace(/_Salary/g, ' - Salary')
        return clientName;
    }
    else return null;
}
export const getLast6MonthsData = () => {
    const today = new Date();
    const months = [];

    for (let i = 5; i >= 0; i--) {
        const year = today.getFullYear();
        const month = today.getMonth() - i;
        const firstDayOfMonth = new Date(year, month, 1);
        const lastDayOfMonth = new Date(year, month + 1, 0);
        lastDayOfMonth.setDate(lastDayOfMonth.getDate() + 1);
        firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
        const monthLabel = `${firstDayOfMonth.toLocaleString('default', { month: 'short' })} ${firstDayOfMonth.getFullYear()}`;

        months.push({
            label: monthLabel,
            startDate: (firstDayOfMonth).toISOString().slice(0, 10),
            endDate: (lastDayOfMonth).toISOString().slice(0, 10)
        });
    }
    return months;
}

export const getCurrentMonthData = () => {
    const today = new Date();
    const months = [];

        const year = today.getFullYear();
        const month = today.getMonth();
        const firstDayOfMonth = new Date(year, month, 1);
        const lastDayOfMonth = new Date(year, month + 1, 0);
        lastDayOfMonth.setDate(lastDayOfMonth.getDate() + 1);
        firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
        const monthLabel = `${firstDayOfMonth.toLocaleString('default', { month: 'long' })} ${firstDayOfMonth.getFullYear()}`;

        months.push({
            label: monthLabel,
            startDate: (firstDayOfMonth).toISOString().slice(0, 10),
            endDate: (lastDayOfMonth).toISOString().slice(0, 10)
        });
    return months;
}

export default { navigationCheck, getAllsearchParams, getFilteredValue, uniquefilteredArrObj, getDisplayableClientName };
