import React, { useState } from 'react';
import './Carousel.scss';
import { Card, Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import '../css/globalStyles.scss'
import FeedbackModal from './FeedbackModal';
const GoToFeedback = ({ heading, description, buttonText, goToTraining}) => {
    const [feedbackModalVisible, setFeedbackModalVisible] = useState(false);

    const redirectFromCTA = (buttonText) => {
        if(buttonText === 'Go to Training') {
            goToTraining();
        } else {
            setFeedbackModalVisible(true);
        }
    };
    const handleCloseModal = () => {
        setFeedbackModalVisible(false);
    };

  return (
    <>
    <Card className={buttonText === 'Share Feedback' ? 'feedback-modal-class feedback-modal' : 'feedback-modal'} bodyStyle={{ padding: '12px' }}>
        <div className='semibold16-24 feedback-header'>{heading}</div>
        <div className='regular14-22 feedback-description'>{description}</div>
        <Button onClick={() => redirectFromCTA(buttonText)} style={{marginTop: '20px'}} className='d-flex align-items-center vl-secondary-btn'>
            <span className='bold14-22 d-flex align-items-center feedback-button-text'>{buttonText}
                {buttonText === 'Go to Training' && <ArrowRightOutlined style={{marginLeft: '12px'}} />}
            </span>
        </Button>
    </Card>
    <FeedbackModal visible={feedbackModalVisible} onCancel={handleCloseModal} />
    </>
  );
};

export default GoToFeedback;
