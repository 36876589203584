import axios from 'axios';
import axiosInstance from './interceptorService';

export const getZeptoStores = (city) => {
    return axiosInstance.get(process.env.REACT_APP_BACKEND_URL + `/zeptoSsu/storeAndShiftDetails?city=${city}`, {withCredentials: true});
}

export const validateZeptoUser = (body) => {
    return axiosInstance.post(process.env.REACT_APP_BACKEND_URL + `/zepto/validateUser`, body, {withCredentials: true});
}

export const triggerZeptoOtp = (body) => {
    return axiosInstance.post(process.env.REACT_APP_BACKEND_URL + `/zepto/sendOtp`, body, {withCredentials: true});
}

export const verifyZeptoOtp = (body) => {
    return axiosInstance.post(process.env.REACT_APP_BACKEND_URL + `/zepto/verifyOtp`, body, {withCredentials: true});
}

export const submitZeptoDetails = (body) => {
    return axiosInstance.post(process.env.REACT_APP_BACKEND_URL + `/zepto/submitAdditionalDetails`, body, {withCredentials: true});
}

export const getZeptoObSubmissionData = async ({source, phoneNumber, mlName}) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/getZeptoSubmissionDetails?phoneNumber=${phoneNumber}&source=${source}&mlName=${mlName}`
    return axiosInstance.get(url, { withCredentials: true });
}