import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Button, Select, Skeleton, Empty, Input, Pagination, Dropdown, Menu } from 'antd';
import { UsergroupAddOutlined, PlusOutlined, EditOutlined, ArrowDownOutlined, ArrowUpOutlined, DownOutlined, RightCircleFilled, RightCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import './TeamSectionContainerStyle.scss'
import '../../../../../css/globalStyles.scss'
import TeamListItem from './TeamListItem';
import AccountSidesheet from '../../common/AccountSidesheet';
import { getOverallTeamCount } from '../../../../../service/accountService';
import LockingComponent from '../../../../common/Locking.component';
import CreateEditTeamModal from './CreateTeam/CreateEditTeamModal';
import RolePermissionsContainer from './RolePermissions/RolePermissionsContainer';
import { trackEvent } from '../../../../../service/engagementMonitoringService';
import CONSTANTS from '../../../../../constants/constants';
import AccountLocking from '../../common/AccountLocking';

const { Search } = Input;

const TeamSectionContainer = ({ }) => {
    const [overAllCount, setOverAllCount] = useState();
    const [selectedRoleFromCard, setSelectedRoleFromCard] = useState("totalMembers")
    const [totalTeamMembersList, setTotalTeamMembersList] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [teamMembersListCopy, setTeamMembersListCopy] = useState([
        {
            "id": "",
            "name": "",
            "phoneNumber": "",
            "managermitraid": null,
            "role": "",
            "roleName": "",
            "rank": 0,
            "createdAt": "",
            "status": "",
            "email": "",
            "config": {
                "bulkReferral": 0
            },
            "count": "0",
            "team_lead": null,
            "team": null
        }
    ]
    )
    const [filterSidebar, setFilterSidebar] = useState(false);
    const [loading, setLoading] = useState(false);
    const [newTeamMemberDetails, setNewTeamMemberDetails] = useState(
        {
            "id": "",
            "name": "",
            "phoneNumber": "",
            "photoUrl": null,
            "status": "",
            "dateOfJoining": null,
            "createdAt": "",
            "updatedAt": "",
            "deletedAt": null,
            "type": "",
            "sourcingCities": null,
            "sourcingLocalities": null,
            "homeCity": null,
            "managerMitraID": "",
            "teamLeadMitraID": null,
            "role": "teamMember",
            "email": null,
            "optInInfo": null,
            "accountManagerId": null,
            "acquisitionSource": "",
            "password": null,
            "bridgeNumber": null,
            "placementCountDivision": null
        }
    )
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [filtersList, setFiltersList] = useState({
        limit: 10,
        offset: 0,
        search: "",
        teamListOnly: false,
        type: "",
        sortBy: "role desc",
        team: ""
    })
    const [selectedRoleFromFilter, setSelectedRoleFromFilter] = useState("TOTAL MEMBERS")
    const [selectedStatus, setSelectedStatus] = useState("Role")
    const [selectedStatusOrder, setSelectedStatusOrder] = useState("desc")
    const [showCreateTeamModal, setShowCreateTeamModal] = useState(false)
    const [showRolePermissionsModal, setShowRolePermissionsModal] = useState(false)

    const statusMenu = (
        <Menu className='jdc-menu'>
            <Menu.Item key="1" className='jdc-menu-item' onClick={() => { onStatusFilterApplied("role", "desc") }}>
                <ArrowDownOutlined className='jdc-menu-item-icon' /> Roles
            </Menu.Item>
            <Menu.Item key="2" className='jdc-menu-item' onClick={() => { onStatusFilterApplied("role", "asc") }}>
                <ArrowUpOutlined className='jdc-menu-item-icon' /> Roles
            </Menu.Item>
            <Menu.Item key="3" className='jdc-menu-item' onClick={() => { onStatusFilterApplied("status", "desc") }}>
                <ArrowDownOutlined className='jdc-menu-item-icon' /> Status
            </Menu.Item>
            <Menu.Item key="4" className='jdc-menu-item' onClick={() => { onStatusFilterApplied("status", "asc") }}>
                <ArrowUpOutlined className='jdc-menu-item-icon' /> Status
            </Menu.Item>
            {/* <Menu.Item key="5" className='jdc-menu-item' onClick={() => { onStatusFilterApplied("teams") }}>
                Teams
            </Menu.Item> */}
        </Menu>
    );

    useEffect(() => {
        fetchMembersList(!overAllCount ? false : true)
    }, [filtersList])

    useEffect(() => {
        if (currentPage)
            fetchMembersList()
    }, [currentPage])

    const fetchUpdatedMembersList = () => {
        updateFiltersList("type", "totalMembers")
        fetchMembersList(false)
    }

    const fetchMembersList = (fetchListOnly = true) => {
        setLoading(true)
        let offset = currentPage == 1 ? 0 : ((currentPage - 1) * 10)
        const queryParams = {
            limit: filtersList.limit,
            offset: offset,
            search: filtersList.search ? filtersList.search : "",
            // teamListOnly: filtersList.teamListOnly,
            type: filtersList.type ? filtersList.type : "",
            sortBy: filtersList.sortBy ? filtersList.sortBy : "",
            team: filtersList.team ? filtersList.team : ""
        };

        if (fetchListOnly) {
            queryParams.teamListOnly = true
        }

        getOverallTeamCount(queryParams).then((teamListRes) => {
            if (teamListRes?.data?.teamList) {
                setLoading(false)
                setTeamMembersListCopy(teamListRes.data.teamList)
                if (!fetchListOnly) {
                    setOverAllCount(teamListRes.data.overallCounts)
                }
                setTotalTeamMembersList(teamListRes.data.totalCount)
            }
        })
    }

    const onRoleCardClick = (role, from = "") => {
        setSelectedRoleFromCard(role.key)
        // if (from == "dropdown") {
            setSelectedRoleFromFilter(role?.label ? returnRoleLabel(role.key, role.label) : "")
        // }
        updateFiltersList("type", role.key)
    }

    const updateFiltersList = (updateKey, updateVal) => {
        let tempFilterList = filtersList;
        tempFilterList[updateKey] = updateVal;
        setFiltersList(JSON.parse(JSON.stringify(tempFilterList)))
    }

    const onStatusFilterApplied = (status, order) => {
        setSelectedStatus(status)
        setSelectedStatusOrder(order)
        updateFiltersList("sortBy", `${status} ${order}`)
    }

    const checkForAuth = () => {
        let isAllowed = false
        if (!localStorage.getItem('payoutAccessToken')) {
            setShowAuthModal(true)
            isAllowed = false
        }
        else {
            setShowAuthModal(false)
            isAllowed = true
        }
        return isAllowed
    }

    const addNewMember = () => {
        updateFiltersList("type", "totalMembers")
        if (checkForAuth()) {
            setNewTeamMemberDetails({
                "id": "",
                "name": "",
                "phoneNumber": "",
                "photoUrl": null,
                "status": "",
                "dateOfJoining": null,
                "createdAt": "",
                "updatedAt": "",
                "deletedAt": null,
                "type": "",
                "sourcingCities": null,
                "sourcingLocalities": null,
                "homeCity": null,
                "managerMitraID": "",
                "teamLeadMitraID": null,
                "role": "teamMember",
                "email": null,
                "optInInfo": null,
                "accountManagerId": null,
                "acquisitionSource": "",
                "password": null,
                "bridgeNumber": null,
                "placementCountDivision": null
            })
            setFilterSidebar(true)
        }
    }

    const editRolePermissions = () => {
        setShowRolePermissionsModal(true);
    }

    const createTeamClicked = () => {
        setShowCreateTeamModal(true);
    }

    const searchTeamMember = (inp) => {
        let tempFilterList = filtersList
        if (inp?.target?.value) {
            tempFilterList.search = inp.target.value
        }
        else {
            tempFilterList.search = ""
        }
        trackEvent('searched_team_member', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        setFiltersList(JSON.parse(JSON.stringify(tempFilterList)))
    }

    const returnRoleLabel = (role, label) => {
        switch (role) {
            case "admin":
                return "ADMIN";
            case "teamLeader":
                return "TLs (TEAM LEADS)";
            case "teamMember":
                return "TCs (TELECALLERS)"
            case "totalMembers":
                return "TOTAL MEMBERS"
            default:
                return label.toUpperCase()
        }
    }

    const roleMenu = (
        <Menu className='jdc-menu'>
            {overAllCount && overAllCount.length > 0 ?
                overAllCount.map((item, itemInd) => {
                    return (
                        <Menu.Item key={itemInd} className='jdc-menu-item' onClick={() => { onRoleCardClick(item, "dropdown") }}>
                            {returnRoleLabel(item.key, item.label)}
                        </Menu.Item>
                    )
                }) : null}
        </Menu>
    )

    return (
        <div className='team-section-parent'>
            <AccountLocking path="account" pathName="Account" visibleAuth={showAuthModal} setVisibleAuth={setShowAuthModal} dontGoBack={true} onCancelClick={() => setShowAuthModal(false)}/>
            <div className='ts-header-container d-flex justify-content-between align-items-center'>
                <div className='ts-header-left d-flex align-items-start'>
                    <div className='ts-header d-flex align-items-center'>
                        <UsergroupAddOutlined className='ts-header-icon' />
                        <span className='ts-header-txt semibold16-24'>
                            Teams
                        </span>
                    </div>
                    <span className='ts-header-sub-txt regular14-22 black45'>
                        Add or remove team members, change roles
                    </span>
                </div>

                <div className='ts-header-right d-flex'>
                    <Button className='vl-secondary-btn ts-header-right-btn d-flex align-items-center justify-content-center' onClick={editRolePermissions}>
                        <EditOutlined className='ts-header-add-member-btn-icon primary-orange2' />
                        <span className='ts-header-role-permission-btn-txt d-flex'>
                            <span className='show-only-desktop-no-font bold16-24 primary-orange2'>Role</span>&nbsp;<span className='bold16-24 primary-orange2'>Permissions</span>
                        </span>
                    </Button>

                    <Button className='vl-secondary-btn ts-header-right-btn d-flex align-items-center justify-content-center' onClick={createTeamClicked}>
                        <PlusOutlined className='ts-header-add-member-btn-icon primary-orange2' />
                        <span className='ts-header-create-team-btn-txt d-flex'>
                            <span className='show-only-desktop-no-font bold16-24 primary-orange2'>Create</span>&nbsp;<span className='bold16-24 primary-orange2'>Team</span>
                        </span>
                    </Button>

                    <Button className='vl-primary-btn ts-header-add-member-btn d-flex align-items-center justify-content-center' onClick={addNewMember}>
                        <PlusOutlined className='ts-header-add-member-btn-icon' />
                        <span className='ts-header-add-member-btn-txt bold16-24 d-flex'>
                            Add <span className='show-only-desktop-no-font'>&nbsp;Member</span>
                        </span>
                    </Button>
                </div>
            </div>

            <div className='ts-team-cards-container d-flex'>
                {
                    overAllCount && overAllCount.length > 0 ?
                        overAllCount.map((item, itemInd) => {
                            return (
                                item.count > 0 ?
                                    <div className={`ts-team-card d-flex align-items-start ${item.key == "teams" ? "" : "ts-team-card-margin"}`} onClick={() => onRoleCardClick(item)} key={itemInd}>
                                        {
                                            item.key.toLowerCase() == "teams" ?
                                                <div className='ts-team-card-vertical-divider-container show-only-desktop-no-font'>
                                                    <div className='ts-team-card-vertical-divider' />
                                                </div> : null
                                        }
                                        <div className={`ts-team-card-content d-flex justify-content-between jp-cp ${selectedRoleFromCard == item.key ? "ts-team-card-selected" : "ts-team-card-unselected"}`} key={itemInd}>
                                            <div className='ts-team-card-count-label d-flex'>
                                                <span className={`ts-team-card-count semibold24-32 ${item.key == "admin" ? "primary-orange2" : item.key == "teamLeader" ? "blue-secondary2" : item.key == "teamMember" ? "green-positive" : item.key == "teams" ? "blue-secondary2" : null}`}>
                                                    {item.count}
                                                </span>
                                                <span className='ts-team-card-txt semibold14-22 black45'>
                                                    {returnRoleLabel(item.key, item.label)}
                                                </span>
                                            </div>
                                            {
                                                selectedRoleFromCard == item.key ?
                                                    <RightCircleFilled className={`ts-team-card-icon primary-orange2 ts-team-card-icon-selected`} />
                                                    :
                                                    <RightCircleOutlined className={`ts-team-card-icon primary-orange2 ts-team-card-icon-unselected`} />
                                            }
                                        </div>
                                    </div>
                                    : null
                            )
                        })
                        : null
                }
            </div>

            <div className='ts-team-list'>
                <div className='ts-list-actions-container d-flex align-items-center'>
                    <div className='d-flex align-items-center ts-list-actions'>
                        <div className='ts-sort-actions d-flex align-items-center'>
                            <div className='ts-sort-action-item ts-sort-action-item-status d-flex align-items-center'>
                                <span className='ts-sort-action-item-label regular14-22'>Showing</span>
                                <div className='ts-role-sort d-flex align-items-center jp-cp'>
                                    <Dropdown overlay={roleMenu} trigger={['hover']}>
                                        <div className='d-flex align-items-center'>
                                            <span className='bold14-22 primary-orange2'>
                                                {selectedRoleFromFilter}
                                            </span>
                                            <DownOutlined className='ts-sort-action-small-icon' />
                                        </div>
                                    </Dropdown>

                                </div>
                            </div>


                            <div className='ts-sort-action-item ts-sort-action-item-role d-flex align-items-center'>
                                <span className='ts-sort-action-item-label regular14-22'>sorted by</span>
                                <div className='ts-status-sort d-flex align-items-center jp-cp'>
                                    <Dropdown overlay={statusMenu} trigger={['hover']}>
                                        <div className='d-flex align-items-center'>
                                            {
                                                selectedStatusOrder == "desc" ?
                                                    <ArrowDownOutlined className='ts-sort-action-icon red-negative' />
                                                    :
                                                    selectedStatusOrder == "asc" ?
                                                        <ArrowUpOutlined className='ts-sort-action-icon red-negative' />
                                                        :
                                                        null
                                            }
                                            <span className='bold14-22 primary-orange2 capital-case-txt'>
                                                {selectedStatus}
                                            </span>
                                            <DownOutlined className='ts-sort-action-small-icon' />
                                        </div>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>

                        <div className="ts-member-search">
                            <Search className='ts-member-search-inp' placeholder={"Member Name"} allowClear onChange={(inp) => searchTeamMember(inp)} enterButton />
                        </div>
                    </div>

                    <div className='show-only-desktop-no-font w-50 justify-content-end'>
                        <Pagination
                            total={totalTeamMembersList}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} members`}
                            defaultPageSize={10}
                            defaultCurrent={currentPage}
                            responsive={true}
                            showLessItems={true}
                            onChange={(page, pagesize) => { setCurrentPage(page) }}
                            showSizeChanger={false}
                        />
                    </div>
                </div>

                {!loading ?
                    <div className='ts-team-list-container'>
                        {
                            teamMembersListCopy && teamMembersListCopy.length > 0
                                ?
                                teamMembersListCopy.map((member, memberInd) => {
                                    return (
                                        <div key={memberInd}>
                                            <TeamListItem listItem={member} updatedMemberDetails={() => fetchUpdatedMembersList()} role={selectedRoleFromCard} />
                                        </div>
                                    )
                                }) :
                                <div className='ts-empty-list'>
                                    <Empty className='w-100' />
                                </div>
                        }

                        <div className='show-only-mobile w-100'>
                            <Pagination
                                total={totalTeamMembersList}
                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} members`}
                                defaultPageSize={10}
                                defaultCurrent={currentPage}
                                responsive={true}
                                showLessItems={true}
                                onChange={(page, pagesize) => { setCurrentPage(page) }}
                                showSizeChanger={false}
                            />
                        </div>
                    </div>
                    : <Skeleton />
                }

                {filterSidebar &&
                    <AccountSidesheet memberDetails={newTeamMemberDetails} isDrawerOpen={filterSidebar} closeDrawer={() => setFilterSidebar(false)} mode={"add"} memberDetailsUpdated={() => fetchMembersList(false)} />
                }
            </div >
            {showCreateTeamModal && <CreateEditTeamModal isModalOpen={showCreateTeamModal} closeModal={() => setShowCreateTeamModal(false)} teamCreated={() => fetchMembersList(false)} />}
            {showRolePermissionsModal && <RolePermissionsContainer isModalOpen={showRolePermissionsModal} closeModal={() => setShowRolePermissionsModal(false)} />}
        </div>
    )
}

export default TeamSectionContainer; 