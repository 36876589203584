import React, { useContext, useEffect, useState } from 'react'
import '../styles/VehicleTypeSelection.css';
import { Button, Select, notification } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { getZomatoCitiesData, getZomatoZonesData, postAdditionalInfo, updateZomatoData } from '../../../../service/additionalInfoService';
import { AppContext } from '../../../../App';
import RiderCompletedBanner from './RiderCompletedBanner';
const { Option } = Select;

function CitySelection({goToNextScreen, lead, leadAdditionalDetails, getLeadInformation, showBannerForScreen}) {
    const [selectedCity, setSelectedCity] = useState();
    const [selectedCityDetails, setSelectedCityDetails] = useState();
    const [cityInfo, setCityInfo] = useState([]);
    const {mitraReducer,mitraDispatch, setAlert,setSpin } = useContext(AppContext);
    const [showStepCompletedSection, setShowStepCompletedSection] = useState(false);
    const [riderHasCompletedTheStep, setRiderHasCompletedTheStep] = useState(false);


    const showLoader = (value) => {
        setSpin({
            loading: value, //false or true when actived
            delay: null,
            tip: 'Loading...'  //Loading copy here
        })
    }

    const handleChange = (item, cityObject) => {
        setSelectedCity(item);
        setSelectedCityDetails(cityObject);

        setRiderHasCompletedTheStep(false);
    };

    const checkErrors = (res) => {
        const newErrors = [];
        for (let key in res.data) {
            if (res.data[key] !== "200") {
                newErrors.push(`${key}: ${res.data[key]}`)
            }
        }
      
        if(newErrors.length) {
            // setErrors([...newErrors]);
            alert(newErrors.join("\n"))
            return true;
        } 
        return false;
      }

    const submitCity = async() => {
        if(riderHasCompletedTheStep) {
            goToNextScreen();
            return
        }

        if(!selectedCity) {
            notification['error']({
                message: 'Please select a city to continue',
              });
            return
        } else {

            showLoader(true);

            try{

                // check if there are any active zones for the selected city
                // if no then show toast message and error note
                // const reqData = {
                //     phoneNumber: lead.phoneNumber,
                //     city: selectedCity
                // };
        
                // const response = await getZomatoZonesData(reqData);

                // if(!response?.data?.allZones?.length) {
                //     showLoader(false);
                //     notification['error']({
                //         message: 'There are no active zones for the selected city, please choose a different city.',
                //     });
                //     return;
                // }

                const submissionData = {
                    zomatoWorkCity: selectedCity
                }
                const res = await postAdditionalInfo(submissionData, mitraReducer?.mitraInfo, lead);

                if(!checkErrors(res)) {
                    const zomatoDataRes = await updateZomatoData({
                        phoneNumber: lead.phoneNumber,
                        dataToUpdate: {
                            updateCity: {
                                cityId: Number(selectedCityDetails.id),
                                cityName: selectedCity
                            }
                        }
                    });

                    if(zomatoDataRes?.data?.updateCity?.status?.code === 200) {
                        showLoader(false); 
                        getLeadInformation();
                        goToNextScreen();
                    } else {
                        showLoader(false); 
                        notification['error']({
                            message: zomatoDataRes?.data?.updateCity?.message || 'Something went wrong, please try again in some time.',
                          });
                    }
    
                    
                } else {
                    showLoader(false); 
                }

                
            } catch (e) {
                // do something
                showLoader(false);
                notification['error']({
                    message: 'Error submitting the city, please try again in some time.',
                  });
            }
            
        }
    }

    const fetchCitiesData = async() => {
        showLoader(true);
        try {
            const response = await getZomatoCitiesData({phoneNumber: lead.phoneNumber});

            if(response?.data?.status?.code === 200) {
                // do something
                showLoader(false);
                if(response?.data?.allCities?.length) {
                    const cityData = response?.data?.allCities;
                    for (let i = 0; i < cityData.length; i++) {
                        cityData[i].value = cityData?.[i]?.name?.text;
                        cityData[i].label = cityData?.[i]?.name?.text + `: OB Fee starting from ₹${cityData?.[i]?.feeData?.minUpfrontFee || 0}`;

                        if(cityData[i].id === response?.data?.selected?.id) {
                            // handling the condition wherein the city data was already submitted
                            setSelectedCity(response?.data?.selected?.value);
                            setSelectedCityDetails(cityData[i]);

                            setShowStepCompletedSection(true);
                        }
                    }
                    setCityInfo([...cityData]);
                }

            } else {
                showLoader(false);
                // do something
            }
        } catch(e) {
            showLoader(false);
            //do something
            console.log(e);
        }
        
    }

    useEffect(() => {
      fetchCitiesData();
      setRiderHasCompletedTheStep(showBannerForScreen('city'));
    }, [])

    const filterOption = (input, option) =>(option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    

  return (
    <>

        {
            showStepCompletedSection && !riderHasCompletedTheStep ? (
                <div style={{display: 'flex', alignItems: 'center', gap: '6px', marginTop: '16px', fontWeight: 700}}>
                    <CheckCircleOutlined style={{color: '#4BB543'}}/>
                    City details successfully submitted to Zomato.
                </div>
            ): null
        }

        {
            riderHasCompletedTheStep ? <RiderCompletedBanner />: null
        }

        <div className='vehicle-type-container'>
            <div className='container-header'>City Details</div>

            <div className='city-details-container'>
                <div className='label'>Select city<sup>*</sup></div>
                <Select
                    style={{ width: '100%', textAlign: 'start', marginTop: '8px' }}
                    placeholder="Select one of the cities.."
                    value={selectedCity}
                    onChange={(v,o)=>handleChange(v,o)}
                    optionLabelProp="label"
                    // options={cityInfo}
                    showSearch
                    filterOption={filterOption}>
                        {cityInfo.map(item => (
                            <Option
                            id={item.id}
                            key={item.value}
                            value={item.value}
                            disabled={item?.is_enabled === false}
                            label={item.label}>
                                {item?.is_enabled === false ? item.label + ' (NOT HIRING)' : item.label}
                            </Option>
                        ))}
                </Select>
            </div>
        </div>

        <div className='button-container'>
            <Button className='submit-button' onClick={()=>submitCity()}>Next</Button>
        </div>
        
    </>
    
  )
}

export default CitySelection
