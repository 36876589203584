import React, { useContext, useEffect, useState } from 'react'
import { RouterPrompt } from '../../RouterPrompt/RouterPrompt';
import commonFunction from '../../../utils/commonFunction';
import { useHistory } from "react-router-dom";
import {CheckOutlined} from '@ant-design/icons'
import { message, notification } from 'antd';
import { AppContext } from '../../../App';
import BlinkitOTPScreen from './components/BlinkitOTPScreen';
import BlinkitVehicleTypeSelection from './components/BlinkitVehicleTypeSelection';
import BlinkitCitySelection from './components/BlinkitCitySelection';
import BlinkitZoneSelection from './components/BlinkitZoneSelection';
import BlinkitSelfieUpload from './components/BlinkitSelfieUpload';
import BlinkitVerificationStep from './components/BlinkitVerificationStep';
import { getBlinkitData } from '../../../service/BlinkitAdditionalFormService';
import { getCandidateInfo } from '../../../service/additionalInfoService';

function BlinkitAdditionalInfoContainer() {
    const totalNumOfSteps = 5;
    const [currentTab, setCurrentTab] = useState();
    const [showPrompt, setShowPrompt] = useState(true);
    const history = useHistory();
    const [leadAdditionalDetails, setLeadAdditionalDetails] = useState({});
    const [lead] = useState(commonFunction.getAllsearchParams(history.location.search))
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const {mitraReducer,mitraDispatch, setAlert,setSpin } = useContext(AppContext);
    const [isInitialOtpVerificationCheck, setIsInitialOtpVerificationCheck] = useState(true);
    /*
    lead variable contains the following info
        companyCity: "Pune",
        companyName: "Zomato"
        jobDemandId: "300e4189-66e4-46ac-a2b6-99d262934c86"
        jobId: "0001567a-886a-44dc-89f8-7c53911ec2e4"
        meta: "null"
        name: "Test"
        phoneNumber: "5235234234"
        scheduledInterviewId: "d0c2bc8c-a499-440c-bc93-27aab2db8649"
    */

    const [tabInfo, setTabInfo] = useState([
        {
            id: 'otpVerification',
            label: 'OTP Verification',
            status: 'pending',
            isClickable: true // This will be used when otp expires
        },
        {
            id: 'vehicleType',
            label: 'Vehicle Type',
            status: 'pending',
            isClickable: true
        },
        {
            id: 'city',
            label: 'City',
            status: 'pending',
            isClickable: true
        },
        {
            id: 'zone',
            label: 'Store',
            status: 'pending',
            isClickable: true
        },
        {
            id: 'verification',
            label: 'Document Upload',
            status: 'pending',
            isClickable: true
        },
        {
            id: 'selfieUpload',
            label: 'Selfie Upload',
            status: 'pending',
            isClickable: true
        },
        // {
        //     id: 'appDownload',
        //     label: 'App Download',
        //     status: 'pending',
        //     isClickable: true
        // }
    ]);

    const getLeadInformation = async() => {
        const populatedRes = await getCandidateInfo(lead.phoneNumber);

        const populatedData = {}

        populatedRes.data.forEach(el => {
            try {
                const parsedValue = JSON.parse(el.value)
                if (typeof parsedValue === "number")
                    populatedData[el.type] = `${parsedValue}`
                else
                    populatedData[el.type] = [{ ...parsedValue, originalName: parsedValue.url, storage: "url" }]
            } catch (error) {
                populatedData[el.type] = el.value
            }
        });

        console.log(populatedData, 'leadAdditionalDetails');

        setLeadAdditionalDetails(populatedData);
    }

    const showLoader = (value) => {
        setSpin({
            loading: value, //false or true when actived
            delay: null,
            tip: 'Loading...'  //Loading copy here
        })
    }

    const onTabClick = (item) => {
        if(item.status === 'pending') return;

        if(!item.isClickable) {
            notification['error'] ({
                message: 'Please verify the OTP to continue'
            });
            return;
        }

        const newTabInfo = [...tabInfo];

        for (let i = 0; i < newTabInfo.length; i++) {
            if(newTabInfo[i].id === item.id) {
                newTabInfo[i].status = 'inProgress';
                setCurrentTab(newTabInfo[i].id);
            } else {
                if(newTabInfo[i].status === 'inProgress') {
                    newTabInfo[i].status = 'done';
                }
            }
        }

        setTabInfo([...newTabInfo]);
    };

    const goToNextScreen = async() => {
        const newTabInfo = [...tabInfo];

        for (let i = 0; i < newTabInfo.length; i++) {
            if(newTabInfo[i].status === 'inProgress' && (i+1 < newTabInfo.length)) {
                newTabInfo[i].status = 'done';
                newTabInfo[i+1].status = 'inProgress';
                newTabInfo[i+1].isClickable = 'true';
                setCurrentTab(newTabInfo[i+1].id);
                break;
            }
        }

        setTabInfo([...newTabInfo]);
    };

    const otpVerificationFailedProcess = () => {
        setIsOtpVerified(false);

            // make current step as otp step, disable all the tab clicks
            const newTabInfo = [...tabInfo];

            for (let i = 0; i < newTabInfo.length; i++) {
                if(newTabInfo[i].id === 'otpVerification') {
                    newTabInfo[i].status = 'inProgress';
                    setCurrentTab(newTabInfo[i].id);
                } else {
                    newTabInfo[i].status = 'pending';
                    newTabInfo[i].isClickable = false;
                }
            }
    }

    const getOtpValidationStatus = async() => {

        try {
            const payload = {
                phoneNumber: lead?.phoneNumber,
                dataToFetch: {
                    getStatus:{}
                }
            }
        
            const response = await getBlinkitData(payload);

            if (response?.data?.getStatus?.status?.code == 200) {
                // here we will check the current application step of the lead
                setIsOtpVerified(true);
                getLeadInformation();
            } else {
                otpVerificationFailedProcess();
            }
        }catch(e) {
            otpVerificationFailedProcess();
        }

    };

    async function getSubmissionDetails() {
        if(lead?.phoneNumber) {
            const payload = {
                phoneNumber: lead.phoneNumber,
                dataToFetch: {
                    getBlinkitSubmissionDetails: {
                        mlName: mitraReducer?.mitraInfo?.name,
                        phoneNumber: lead.phoneNumber,
                    }
                }
            };
            const response = await getBlinkitData(payload);

            const userFlowStatus = response?.data;

            if(!userFlowStatus?.getBlinkitSubmissionDetails) {
                notification['error'] ({
                    message: 'Error determining current step, please try again later'
                });
                setIsOtpVerified(false);
                showLoader(false);
                return;
            }
    
            const blinkitSubmissionDetails = userFlowStatus?.getBlinkitSubmissionDetails;
    
            if(blinkitSubmissionDetails && Object.values(blinkitSubmissionDetails).length !== totalNumOfSteps) {
                notification['error'] ({
                    message: 'Error fetching all the steps, please try again later'
                });
                setIsOtpVerified(false);
                showLoader(false);
                return;
            }
    
            const steps = Object.values(blinkitSubmissionDetails);
    
            // setting the data of submission details similar to zomatoSubmission details
            // only difference is that doing it in FE for blinkit
    
            let currentStep = '';
            // const source= mitraReducer?.mitraInfo?.name;
    
            if(steps && steps.length) {
                for (let i = 0; i < steps.length; i++) {
                    // if any of the step is incomplete(first incomplete occurence)
                    if(!steps[i].isCompleted){
                        currentStep = steps[i].step;
                        break;
                    }
                }
                if(!currentStep.length) {
                    // if all the steps are completed then go to last screen
                    currentStep = 'selfieUpload';
                }
            }
    
            const submissionData = {
                steps: [...steps],
                currentStep,
            }
    
            return submissionData;
        }
    }

    const determineCurrentStep = async() => {
        showLoader(true);

        try {
            const submissionData = await getSubmissionDetails();

            const evaluatedCurrentStep = submissionData.currentStep;
            let currentStepStatusIsInProgress = false;

            const updatedTabInfo = [...tabInfo];

            for (let i = 0; i < updatedTabInfo.length; i++) {
                if(updatedTabInfo[i].id === evaluatedCurrentStep) {
                    updatedTabInfo[i].status = 'inProgress';
                    currentStepStatusIsInProgress = true;
                    setCurrentTab(updatedTabInfo[i].id);
                } else {
                    if(currentStepStatusIsInProgress) {
                        // this means we are checking tabs which comes after current tab
                        // meaning they are still pending
                        updatedTabInfo[i].status = 'pending';
                        updatedTabInfo[i].isClickable = false;
                    } else {
                        // this means that the current item is not the current step
                        // and is completed because we havent yet encountered 'currentStepStatusIsInProgress'
                        updatedTabInfo[i].status = 'done';
                        updatedTabInfo[i].isClickable = true;
                    }
                }
            }

            setTabInfo([...updatedTabInfo]);
            showLoader(false);
        } catch(err) {
            showLoader(false);
            notification['error'] ({
                message: 'Error evaluating current step.'
            });
            otpVerificationFailedProcess();
        }
        
    }

    useEffect(() => {
      if(lead?.phoneNumber) {
        getOtpValidationStatus();
      }
    }, [lead])

    useEffect(() => {
        if(isInitialOtpVerificationCheck && isOtpVerified) {
          // Here if OTP is verified and we have the details to check the current step then call determineCurrentStep()
          determineCurrentStep();
          setIsInitialOtpVerificationCheck(false);
        }
      }, [isOtpVerified])

  return (
    <div className="AdditionalInfoScreen mainContentZ">
        {
            // <RouterPrompt
            //     when={showPrompt}
            //     title="Leave this page"
            //     cancelText="No"
            //     okText="Yes"
            //     onOK={() => true}
            //     onCancel={() => false}
            //     promptMessage= {"The lead has not yet been referred. Are you sure you want to leave the form?"}
            // />
        }
        <div className='header-text'>Blinkit Additional Form</div>
        <ul>
            <li style={{display: 'flex', textAlign: 'start', gap: '6px'}}>
                <b>Note:</b>
                <p>Please upload your candidates details and documents in this section. These will be directly submitted to Blinkit. You can also upload the rider selfie image through this flow</p>
            </li>
        </ul>

        <div className="tab-container">
            {
                tabInfo.map((item, index) => {
                    return (
                        <div 
                        key={item.id} 
                        className={
                            `tab-item${item.status === 'inProgress' ? ' active': item.status === 'pending'? ' pending': ' done'}`
                        }
                        onClick={()=>onTabClick(item)}
                        >
                            {item.label} {item.status === 'done' ? <CheckOutlined style={{color: '#4BB543'}}/>: null}
                        </div>
                    )
                })
            }
        </div>

        <div className='form-content-container'>
            
            {
                currentTab === 'otpVerification' ? (
                    <BlinkitOTPScreen 
                    goToNextScreen={goToNextScreen}
                    isOtpVerified={isOtpVerified}
                    setIsOtpVerified={setIsOtpVerified}
                    lead={lead}
                    determineCurrentStep={determineCurrentStep}
                    getLeadInformation={getLeadInformation}
                    />
                ): null
            }

            {
                currentTab === 'vehicleType' ? (
                    <BlinkitVehicleTypeSelection 
                    goToNextScreen={goToNextScreen}
                    lead={lead}
                    />
                ): null
            }

            {
                currentTab === 'city' ? (
                    <BlinkitCitySelection 
                    goToNextScreen={goToNextScreen}
                    lead={lead}
                    leadAdditionalDetails={leadAdditionalDetails}
                    />
                ): null
            }

            {
                currentTab === 'zone' ? (
                    <BlinkitZoneSelection 
                    goToNextScreen={goToNextScreen}
                    lead={lead}
                    leadAdditionalDetails={leadAdditionalDetails}
                    />
                ): null
            }

            {
                currentTab === 'verification' ? (
                    <BlinkitVerificationStep 
                    goToNextScreen={goToNextScreen}
                    lead={lead}
                    leadAdditionalDetails={leadAdditionalDetails}
                    getLeadInformation={getLeadInformation}
                    />
                ): null
            }

            {
                currentTab === 'selfieUpload' ? (
                    <BlinkitSelfieUpload 
                    goToNextScreen={goToNextScreen}
                    lead={lead}
                    leadAdditionalDetails={leadAdditionalDetails}
                    />
                ): null
            }

            {/* {
                currentTab === 'appDownload' ? (
                    <AppDownload 
                    goToNextScreen={goToNextScreen}
                    lead={lead}
                    leadAdditionalDetails={leadAdditionalDetails}
                    getLeadInformation={getLeadInformation}
                    setShowPrompt={setShowPrompt}
                    />
                ): null
            } */}
            
        </div>
    </div>
  )
}

export default BlinkitAdditionalInfoContainer

