import React, { useState, useEffect, useContext } from 'react';
import { getTeamLeaderBoardData, DownloadTeamLeaderBoardData } from '../../service/reportService'
import PageHeaderComponent from '../common/PageHeader';
import { AppContext } from '../../App';
import moment from 'moment';
import SearchComponent from '../common/search.component';
import { Select, Button, Skeleton, DatePicker, Empty, Tag } from 'antd';
import '../../css/leaderboardPage.scss';
import ReportTeamMemberCard from './ReportTeamMemberCard.component'
import { getActiveClientsForFilter, getMitraTeamMembersList, disabledDate } from './../../service/commonService'
import CONSTANTS from '../../constants/constants';

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'DD-MMMM YYYY';


const TeamLeaderBoard = () => {

    const { mitraReducer, setAlert, mitraDispatch } = useContext(AppContext);
    const [mitra, setMitra] = useState({});
    const [isAdmin, setIsAdmin] = useState(false)
    const [breadcrumb, setBreadcrumb] = useState([{ link: '/report/performance-dashboard', label: 'Report' }, { link: '/report/performance-dashboard', label: 'Performance Dashboard' }, { link: '/team-leader-board', label: 'Leaderboard' }])
    const [downloadLoading, setDownloadLoading] = useState(false)
    const [loading, setLoading] = useState(true)
    const [startDate, setStartDate] = useState(mitraReducer?.reportParams?.startDate ? mitraReducer?.reportParams?.startDate : moment().startOf('month'))
    const [endDate, setEndDate] = useState(mitraReducer?.reportParams?.endDate ? mitraReducer?.reportParams?.endDate : moment())
    const [selectedClient, setSelectedClient] = useState(mitraReducer?.reportParams?.selectedClient ? mitraReducer?.reportParams?.selectedClient : '')
    const [teamList, setTeamList] = useState(mitraReducer?.reportParams?.teamList ? mitraReducer?.reportParams?.teamList : [])
    const [teamLeaderBoardList, setTeamLeaderBoardList] = useState('');
    const [clientFilterList, setClientFilterList] = useState([])
    const [selectedTeamMember, setSelectedTeamMember] = useState(mitraReducer?.reportParams?.selectedTeamMember ? mitraReducer?.reportParams?.selectedTeamMember : 0)
    const [searchText, setSearchText] = useState('')
    const [disableDatesBefore, setDisableDatesBefore] = useState(moment());

    const [hasDownloadAccess, setHasDownloadAccess] = useState(false);

    useEffect(() => {
        if (mitraReducer && mitraReducer.allowedFeatures) {
            setHasDownloadAccess(mitraReducer?.allowedFeatures[CONSTANTS.DOWNLOAD_DATA_ACCESS]?.isEnabledOverall)
        }
    }, [mitraReducer])

    useEffect(() => {
        if (mitraReducer?.mitraInfo) {
            setIsAdmin(mitraReducer?.mitraInfo?.role === 'admin' ? true : false)
            setMitra(mitraReducer?.mitraInfo ? mitraReducer?.mitraInfo : {})
        }
        if (mitra.id) {
            getMitraTeamMembersList().then(response => {
                let list = []
                list.push({ name: 'Entire Team', id: mitra.id })
                list.push(mitra)
                for (let user in response.data.data) {
                    list.push(response.data.data[user]);
                }
                setTeamList(list);
                getActiveClientsForFilter().then(data => {
                    const clientArray = [{ label: 'All', value: '' }, ...data.data.activeClients]
                    setClientFilterList(data.data.activeClients)
                })
            })
        }

    }, [mitraReducer, mitra])

    useEffect(() => {
        if (mitraReducer && mitraReducer.allowedFeatures) {
            let viewAccessRestricted = mitraReducer?.allowedFeatures[CONSTANTS.VIEW_DATA_ACCESS]?.isEnable
            if (viewAccessRestricted) {
                let temp_var = moment().subtract(mitraReducer?.allowedFeatures[CONSTANTS.VIEW_DATA_ACCESS]?.numberOfDays, 'days')
                setDisableDatesBefore(moment().subtract(mitraReducer?.allowedFeatures[CONSTANTS.VIEW_DATA_ACCESS]?.numberOfDays, 'days'))
            }
        }
    }, [mitraReducer])

    useEffect(() => {
        if (mitra?.id) {
            setTeamLeaderBoardList('')
            setLoading(true)
            let data = {}
            data = {
                managerMitraID: isAdmin ? mitra.id : mitra.managerMitraID,
                mitraId: selectedTeamMember !== 0 ? teamList[selectedTeamMember].id : 'null',
                startDate: moment(startDate).startOf('month').format('YYYY-MM-DD'),
                endDate: moment(endDate).endOf('month').format('YYYY-MM-DD'),
                clientNames: [selectedClient],
                search: searchText
            }
            getTeamLeaderBoardData(data)
                .then(response => {
                    console.log(response.data.data)
                    setLoading(false)
                    setTeamLeaderBoardList(response.data.data.data);
                })
        }

    }, [mitra, selectedTeamMember, searchText, startDate, endDate, selectedClient]);

    const searchCandidate = (searchText) => {
        setSearchText(searchText)
        console.log(searchText)
    }
    const handleSheet = async () => {
        setDownloadLoading(true)
        let data = {
            managerMitraID: isAdmin ? mitra.id : mitra.managerMitraID,
            mitraId: selectedTeamMember !== 0 ? teamList[selectedTeamMember].id : 'null',
            startDate: moment(startDate).startOf('month').format('YYYY-MM-DD'),
            endDate: moment(endDate).endOf('month').format('YYYY-MM-DD'),
            clientNames: [selectedClient],
            search: searchText
        }
        DownloadTeamLeaderBoardData(data).then(response => {
            if (response.data.responseCandidatesUrl) {
                console.log(response)
                window.open(response.data.responseCandidatesUrl, '_blank')
                setDownloadLoading(false)
            }
            else {
                setAlert({
                    display: true,
                    text: 'No data Present',
                    type: 'error'
                });
            }
            setDownloadLoading(false)
        })
    }
    const handleDateRange = (dates, dateStrings) => {
        if (dates === null) {
            setStartDate(moment().startOf('month'));
            setEndDate(moment());
        }
        else {
            setStartDate(dates[0]);
            setEndDate(dates[1]);
        }
    }
    const handleClientChange = (e) => {
        setSelectedClient([e])
    }
    const handleTeamMemberChange = (e) => {
        setSelectedTeamMember(e)
    }
    const deleteChip = (type, value) => {
        if (type === 'month') {
            setStartDate(moment().startOf('month'));
            setEndDate(moment());
        }
        if (type === 'teamMember') {
            setSelectedTeamMember(0);
        }
        if (type === 'client') {
            setSelectedClient('')
        }
    }

    const fetchDisabledDates = (current) => {
        return ((current && current > moment().endOf('day')) || (current && current < disableDatesBefore.endOf("day")));
    }

    return (
        <div className="team-leaderboard-page-container">
            <div className="header">
                <PageHeaderComponent backBtnBool={true} breadcrumb={breadcrumb} />
            </div>
            <div className='leaderboard-filters'>
                <RangePicker
                    defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                    format={dateFormat}
                    onChange={handleDateRange}
                    ranges={{
                        Today: [moment().startOf('day'), moment().endOf('day')],
                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                    }}
                    disabledDate={fetchDisabledDates}
                    numberOfMonths={1}
                    allowClear
                />
                <Select
                    style={{ margin: '0px 10px 0px 10px' }}
                    value={selectedClient === '' ? 'All Clients' : selectedClient}
                    placeholder="Select Client"
                    onChange={handleClientChange}
                >
                    {clientFilterList.map((item, index) => {
                        return <Option key={"clientFilter" + index} value={item.label}>{item.label}</Option>
                    })}
                </Select>
                {
                    <>
                        {
                            isAdmin ?
                                <Select
                                    value={selectedTeamMember === '' ? null : selectedTeamMember}
                                    placeholder="Select Team Member"
                                    onChange={handleTeamMemberChange}
                                >
                                    {teamList.map((item, index) => {
                                        return <Option key={"teamList" + index} value={index}>{item.name}</Option>
                                    })}
                                </Select>
                                :
                                null
                        }

                        {
                            hasDownloadAccess ?
                                <Button danger loading={downloadLoading} style={{ margin: '0px 10px 0px auto' }}
                                    onClick={() => {
                                        handleSheet();
                                    }}>
                                    Download File
                                </Button>
                                :
                                null
                        }
                    </>
                }

            </div>
            {
                (selectedTeamMember !== 0 || selectedClient !== '') ?
                    <div className="Chips-container" style={{ padding: "0 20px" }}>
                        {
                            selectedTeamMember !== 0 && teamList.length ?
                                <Tag className="single-chip" closable onClose={() => deleteChip('teamMember')}> {teamList[selectedTeamMember].name}</Tag>
                                : null
                        }
                        {
                            selectedClient !== '' ?
                                <Tag className="single-chip" closable onClose={() => deleteChip('client')}> {selectedClient}</Tag>
                                : null
                        }
                    </div>
                    : null
            }
            <div className='team-member-cards-container'>
                {
                    teamLeaderBoardList ?
                        teamLeaderBoardList.length ?
                            teamLeaderBoardList.map((ele, index) => {
                                return <ReportTeamMemberCard member={ele} key={index} />
                            })
                            :
                            <Empty />
                        :
                        <Skeleton loading={loading} avatar active />
                }
            </div>
        </div>
    );
};

export default TeamLeaderBoard;