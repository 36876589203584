import React, { useEffect} from 'react'
import { Modal, Divider, Button} from 'antd';
import {
    CloseOutlined
} from '@ant-design/icons';
import './UnsavedChangesModalStyle.scss'
import '../../../../css/globalStyles.scss'

const UnsavedChangesModal = ({ isModalOpen, closeModal, onStayClicked, onLeaveClicked }) => {

    return (
        <Modal
            title={null}
            centered
            visible={isModalOpen}
            footer={null}
            width={600}
            className="unsaved-changes-modal-parent"
            onCancel={closeModal}
        >
            <div className='ucm-content'>
                <div className='ucm-header d-flex justify-content-between align-items-center'>
                    <div className='ucm-header-title d-flex justify-content-between align-items-center'>
                        <div className='d-flex align-items-center'>
                            <span className='bold16-24 black85'>
                                Unsaved Changes
                            </span>
                        </div>
                    </div>
                    <div className='ucm-header-close jp-cp' onClick={closeModal}>
                        <CloseOutlined className='ucm-header-close-icon' />
                    </div>
                </div>

                <Divider />

                <div className='ucm-body'>
                    <span className='regular14-22 black85'>
                        You have unsaved changes in this section. Are you sure you want to leave? <br />Unsaved changes will be discarded.
                    </span>
                </div>


                <div className='ucm-footer'>
                    <Divider />
                    <div className='ucm-footer-btns-container d-flex justify-content-end h-100 align-items-center'>
                        <Button onClick={onStayClicked} className='ucm-footer-stay-btn h-100'>
                            <span className='regular14-22'>
                                Stay
                            </span>
                        </Button>

                        <Button onClick={onLeaveClicked} className='ucm-footer-leave-btn h-100'>
                            <span className='bold16-24 plain-white'>
                                Leave
                            </span>
                        </Button>
                    </div>

                </div>
            </div>
        </Modal >
    )
}

export default UnsavedChangesModal; 