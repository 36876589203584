import React from "react";

function CityIcon({fillColor='none',height=18,width=20,strokeColor="#252526"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
    height={height}
    fill={fillColor}
      viewBox="0 0 20 18"
    >
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M1.35 17.602h17.417M16.218 17.61v-3.53M16.234 8.807c-.966 0-1.742.775-1.742 1.741v1.797c0 .966.776 1.742 1.742 1.742.965 0 1.741-.776 1.741-1.742v-1.797c0-.966-.776-1.741-1.741-1.741zM2.221 17.602V4.96c0-1.591.792-2.39 2.367-2.39H9.52c1.576 0 2.36.799 2.36 2.39v12.643M5.15 6.717H9.07M5.15 9.686H9.07M7.09 17.602v-2.968"
      ></path>
    </svg>
  );
}

export default CityIcon;