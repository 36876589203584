import axiosInstance from './interceptorService';

export const getBlinkitDarkStoreFormFields = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/getBlinkitDarkStoreForm`;
    return axiosInstance.get(url, { withCredentials: true });
}

export const getBlinkitDarkStoreContants = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/getBlinkitDarkStoreMasterData`;
    return axiosInstance.get(url, { withCredentials: true });
}

export const submitBlinkitDarkStoreForm = ( data ) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/submitBlinkitDarkStoreForm`;
    return axiosInstance.post(url, data, { withCredentials: true });
}