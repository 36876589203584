



import React from 'react';
import '../../css/sidesheet.scss';
import { Button } from 'antd'; 
import { DeleteOutlined, ArrowRightOutlined, FilterOutlined, CloseOutlined } from '@ant-design/icons';

const SideSheetComponent = (props) => {    
    const {
        onDismiss,
        removeAccess,
        addAccess,
        containerStyle,
        submit,
        sidername,
        clearAll,
        hideDismissIcon,
        hideBottomButtons=false,
        performance=false,
        showNavigator=true,
        parentContainerStyles={}
    } = props;

    return (
		<>
            <div className='parentContainer' style={parentContainerStyles}>
                <div onClick={onDismiss} className='emptyContainer'>
                </div>
                <div  style={containerStyle} className='containerSS'>
                    {
                        showNavigator ? (
                            <div className='navigator'>
                                <div className='nav1 align-items-center'>
                                    {performance ? <FilterOutlined className='filter-icon'/> : <img onClick={onDismiss} src={`/image/arrow-left.svg`}/>}
                                    <div className='sidername'>{sidername}</div>
                                </div>
                                {!hideDismissIcon && 
                                <>
                                {performance ? <CloseOutlined className='close-icon' onClick={onDismiss}/> : <img onClick={onDismiss} src={`/image/close.svg`}/>}
                                </>
                                }
                            </div>
                        ): null
                    }
                    
                    <div className='childContainer'>
                        {props.children}
                    </div>
                    {
                        hideBottomButtons ? null : (
                            <div className='btns'>
                                {performance ?
                                <>
                                {clearAll && <Button disabled={clearAll.disabled} className='commonlayoutbtn clear-all' onClick={()=>clearAll.onClick('clearAll')}><DeleteOutlined/>Clear All</Button>}
                                {submit && <Button className={['submit-btn', submit.disabled ? "disabledSubmit" : "submit"]} onClick={()=>{submit.onClick(); onDismiss()}} disabled={submit.disabled} type="primary">Apply<ArrowRightOutlined/></Button>}
                                </>
                                :
                                <>
                                {clearAll && <Button  style={{marginRight:5}}disabled={clearAll.disabled} className='commonlayoutbtn' onClick={()=>clearAll.onClick('clearAll')}>Clear All</Button>}
                                {submit && <Button onClick={()=>{submit.onClick(); onDismiss()}} disabled={submit.disabled} className={submit.disabled ? "disabledSubmit" : "submit"} type="primary">Submit</Button>}
                                </>
                                }
                                {addAccess && !addAccess.disabled && <Button onClick={()=>addAccess.onClick('addAccess')} className='addaccess' danger  type="primary">Re-activate Member</Button>}
                                {removeAccess && !removeAccess.disabled && <Button onClick={()=>removeAccess.onClick('removeAccess')} className='removeaccess' danger  type="primary">Remove Access</Button>}
                            </div>
                        )
                    }
                    
                </div>
            </div>
            
		</>
	);
};

export default SideSheetComponent;
