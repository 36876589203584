import React, { useContext, useEffect, useState } from 'react'
import '../styles/VerificationStep.css'
import { Button, Image, Input, Radio, Select, Upload, notification } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import { AppContext } from '../../../../App';
import { getDocVerificationData, postAdditionalInfo, updateZomatoData, uploadImageToS3 } from '../../../../service/additionalInfoService';

function ManualDocumentVerification({goToNextScreen, lead, docVerificationData, selectedVerificationType, leadAdditionalDetails,getLeadInformation, riderHasCompletedTheStep, setRiderHasCompletedTheStep}) {
    const {mitraReducer,mitraDispatch, setAlert,setSpin } = useContext(AppContext);
    const [stateInfo, setStateInfo] = useState([]);
    const [errors, setErrors] = useState();
    const [panDetails, setPanDetails] = useState({
        panNumber: null,
        gender: 'Male',
        fatherName: '',
        panProof: null
    });

    const [addressDetails, setAddressDetails] = useState({
        houseNumber: null,
        area: null,
        town: null,
        state: null,
        pincode: null,
        landmark: null,
        documentType: null,
        documentFront: null,
        documentBack: null
    });

    const setPanData = (data, fieldName) => {
        setPanDetails({...panDetails, [fieldName]:data});
        setRiderHasCompletedTheStep(false);
    };

    const setAddressData = (data, fieldName) => {
        setAddressDetails({...addressDetails, [fieldName]:data});
        setRiderHasCompletedTheStep(false);
    }

    const handleBeforeUpload = (file) => {

        // Return false to prevent upload
        return false;
      };

      const showLoader = (value) => {
        setSpin({
            loading: value, //false or true when actived
            delay: null,
            tip: 'Updating Information...'  //Loading copy here
        })
    }

    const checkErrors = (res) => {
        const newErrors = [];
        for (let key in res.data) {
            if (res.data[key] !== "200") {
                newErrors.push(`${key}: ${res.data[key]}`)
            }
        }

        if(newErrors.length) {
            // setErrors([...newErrors]);
            alert(newErrors.join("\n"))
            return true;
        } 
        return false;
    }
    
      const handleUpload =(info, type) => {
        showLoader(true);
    
        uploadImageToS3(info.file)
            .then(async(res)=> {
                showLoader(false);
    
                if(res?.data?.imageUrl) {
                    // for pan card
                    if(type==='panProof') {
                        const submissionData = {
                            panCard: {
                                url: res.data.imageUrl,
                                originalName: info?.file?.name,
                                size: info?.file?.size
                            }
                        }
            
                        const response = await postAdditionalInfo(submissionData, mitraReducer?.mitraInfo, lead);

                        if(!checkErrors(response)) {
                            setPanData(res?.data?.imageUrl, 'panProof');
                            setRiderHasCompletedTheStep(false);
                        }
                        
                    }
                    else if(type==='documentFront') {
                        // for document front
                        let submissionData = {}
                        if(addressDetails.documentType === 'DL') {
                            submissionData = {
                                drivingLicense: {
                                    url: res.data.imageUrl,
                                    originalName: info?.file?.name,
                                    size: info?.file?.size
                                }
                            }
                        } else {
                            submissionData = {
                                aadhaarCard: {
                                    url: res.data.imageUrl,
                                    originalName: info?.file?.name,
                                    size: info?.file?.size
                                }
                            }
                        }

                        const response = await postAdditionalInfo(submissionData, mitraReducer?.mitraInfo, lead);

                        if(!checkErrors(response)) {
                            setAddressData(res.data.imageUrl, 'documentFront');
                            setRiderHasCompletedTheStep(false);
                        }
                    }
                    else if(type==='documentBack') {
                        // for document back
                        let submissionData = {}
                        if(addressDetails.documentType === 'DL') {
                            submissionData = {
                                drivingLicenseBack: {
                                    url: res.data.imageUrl,
                                    originalName: info?.file?.name,
                                    size: info?.file?.size
                                }
                            }
                        } else {
                            submissionData = {
                                aadhaarCardBack: {
                                    url: res.data.imageUrl,
                                    originalName: info?.file?.name,
                                    size: info?.file?.size
                                }
                            }
                        }

                        const response = await postAdditionalInfo(submissionData, mitraReducer?.mitraInfo, lead);

                        if(!checkErrors(response)) {
                            setAddressData(res.data.imageUrl, 'documentBack');
                            setRiderHasCompletedTheStep(false);
                        }
                        
                    }
                } else {
                    showLoader(false);
                }
            }).catch(e=> {
                showLoader(false);
    
                notification['error']({
                    message: 'Image upload failed, please try again later.',
                  });
    
                console.log(e);
            })
    };

    const submitDetails = async() => {
        if(riderHasCompletedTheStep) {
            goToNextScreen();
            return
        }

        showLoader(true);
        
        if(
            !panDetails.fatherName || !panDetails.gender || !panDetails.panNumber || !panDetails.panProof ||
            !addressDetails.houseNumber || !addressDetails.area || !addressDetails.town || !addressDetails.state || 
            !addressDetails.pincode || !addressDetails.landmark || !addressDetails.documentType || !addressDetails.documentFront ||
            !addressDetails.documentBack
            ) {
                // if any of the details is not filled then show error message and stop the process
                notification['error']({
                    message: 'Please fill all the details to continue',
                });
                showLoader(false);
                return;
            } else {
                // Adding individual field validations for some fields
                let panNumberRegex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
                if(!panNumberRegex.test(panDetails?.panNumber)) {
                    notification['error']({
                        message: 'Please enter a valid PAN Number',
                    }); 
                    showLoader(false);
                    return
                }

                let pincodeRegex = new RegExp(/^[[0-9]{6}$/);
                if (!pincodeRegex.test(addressDetails?.pincode)) {
                    notification['error']({
                        message: 'Please enter a valid PIN code',
                    }); 
                    showLoader(false);
                    return 
                }

                // Actual submission of data post all validation checks
                const submissionData = {
                    panNumber: panDetails.panNumber,
                    gender: panDetails.gender,
                    fathersName: panDetails.fatherName,
                    permanentAddressHouseNumber: addressDetails?.houseNumber,
                    permanentAddressStreet: addressDetails?.area,
                    permanentAddressCity: addressDetails?.town,
                    permanentAddressState: addressDetails?.state,
                    permanentAddressPincode: addressDetails?.pincode,
                    permanentAddressLandmark: addressDetails?.landmark,
                    zomatoAddressProofType: addressDetails?.documentType,
                    zomatoVerificationType: selectedVerificationType,
                }

                // Updating info in our DB
                const saveDataInOurDB = await postAdditionalInfo(submissionData, mitraReducer?.mitraInfo, lead);
                
                if (!checkErrors(saveDataInOurDB)) {
                    // If there are no errors while saving information in our DB
                    // only then forward the details to Zomato

                    // updating PAN Details through zomato api
                    const panSubmissionResponse = await updateZomatoData({
                        phoneNumber: lead.phoneNumber,
                        dataToUpdate: {
                            updatePanCardDetails: {
                                panNumber: submissionData.panNumber,
                                fathersName: submissionData.fathersName,
                                gender: submissionData.gender,
                                imageUrl: panDetails.panProof,
                                verifyPan: true
                            }
                        }
                    });

                    if(panSubmissionResponse?.data?.updatePanCardDetails?.status?.code !== 200) {
                        notification['error']({
                            message: panSubmissionResponse?.data?.updatePanCardDetails?.message || 'Something went wrong, please try again in some time.',
                          });
                          showLoader(false);
                        return;
                    }

                    // updating Address Details through zomato api
                    const addressSubmissionResponse = await updateZomatoData({
                        phoneNumber: lead.phoneNumber,
                        dataToUpdate: {
                            updateAddressDetails: {
                                houseNumber: submissionData.permanentAddressHouseNumber,
                                street: submissionData.permanentAddressStreet,
                                city: submissionData.permanentAddressCity,
                                state: submissionData.permanentAddressState,
                                pincode: submissionData.permanentAddressPincode ,
                                landmark: submissionData.permanentAddressLandmark,
                                frontImageUrl: addressDetails?.documentFront,
                                backImageUrl: addressDetails?.documentBack
                            }
                        }
                    });

                    if(addressSubmissionResponse?.data?.updateAddressDetails?.status?.code !== 200) {
                        notification['error']({
                            message: addressSubmissionResponse?.data?.updateAddressDetails?.message || 'Something went wrong, please try again in some time.',
                          });
                          showLoader(false);
                        return;
                    } else {
                        showLoader(false);
                        getLeadInformation();
                        goToNextScreen();
                    }
                } else {
                    showLoader(false);
                }

            }
    };

    const extractStateInfo = () => {
        const stateList = docVerificationData?.addressDetails?.data?.stateList;
        const newStateList = [];
        for (let i = 0; i < stateList.length; i++) {
            newStateList.push({label:stateList[i], value: stateList[i]})
        }

        setStateInfo([...newStateList]);
    }

    useEffect(() => {
        if(docVerificationData?.addressDetails?.data?.stateList?.length) {
            extractStateInfo();
        }
    }, [docVerificationData]);

    const autoFillTheDetails = () => {
        // populating fields individually
        const panCardFormInfo = {
            panNumber: null,
            gender: 'Male',
            fatherName: '',
            panProof: null
        };

        const addressFormInfo = {
            houseNumber: null,
            area: null,
            town: null,
            state: null,
            pincode: null,
            landmark: null,
            documentType: null,
            documentFront: null,
            documentBack: null
        }
        if(leadAdditionalDetails?.panNumber) {
            panCardFormInfo.panNumber = leadAdditionalDetails?.panNumber;
        }
        if(leadAdditionalDetails?.gender) {
            panCardFormInfo.gender = leadAdditionalDetails?.gender;
        }
        if(leadAdditionalDetails?.fathersName) {
            panCardFormInfo.fatherName = leadAdditionalDetails?.fathersName;
        }
        if(leadAdditionalDetails?.panCard?.[0]?.url) {
            panCardFormInfo.panProof = leadAdditionalDetails?.panCard?.[0]?.url;
        }

        setPanDetails(panCardFormInfo);

        if(leadAdditionalDetails?.permanentAddressHouseNumber) {
            addressFormInfo.houseNumber = leadAdditionalDetails?.permanentAddressHouseNumber;
        }

        if(leadAdditionalDetails?.permanentAddressStreet) {
            addressFormInfo.area = leadAdditionalDetails?.permanentAddressStreet;
        }

        if(leadAdditionalDetails?.permanentAddressCity) {
            addressFormInfo.town = leadAdditionalDetails?.permanentAddressCity;
        }
        if(leadAdditionalDetails?.permanentAddressState) {
            addressFormInfo.state = leadAdditionalDetails?.permanentAddressState;
        }
        
        if(leadAdditionalDetails?.permanentAddressPincode) {
            addressFormInfo.pincode = leadAdditionalDetails?.permanentAddressPincode;
        }
        if(leadAdditionalDetails?.permanentAddressLandmark) {
            addressFormInfo.landmark = leadAdditionalDetails?.permanentAddressLandmark;
        }
        if(leadAdditionalDetails?.zomatoAddressProofType) {
            addressFormInfo.documentType = leadAdditionalDetails?.zomatoAddressProofType;
        }
        if(leadAdditionalDetails?.zomatoAddressProofType) {
            addressFormInfo.documentFront = leadAdditionalDetails?.zomatoAddressProofType === 'DL' ? leadAdditionalDetails?.drivingLicense?.[0].url: 
            leadAdditionalDetails?.zomatoAddressProofType === 'Aadhar' ? leadAdditionalDetails?.aadhaarCard?.[0].url: null;
        }
        if(leadAdditionalDetails?.zomatoAddressProofType) {
            addressFormInfo.documentBack = leadAdditionalDetails?.zomatoAddressProofType === 'DL' ? leadAdditionalDetails?.drivingLicenseBack?.[0].url: 
            leadAdditionalDetails?.zomatoAddressProofType === 'Aadhar' ? leadAdditionalDetails?.aadhaarCardBack?.[0].url: null;
        }

        setAddressDetails(addressFormInfo);
      
    }

    useEffect(() => {
      // for autofill of pre-added information
      autoFillTheDetails();
    }, [leadAdditionalDetails])
    
    

  return (
    <>
        {/* PAN Details Starts here */}
        <div className='pan-verification-container'>
            <div className='container-header'>PAN Details</div>

            <div className='pan-form-container'>

                <div className='label'>Pan Number<sup>*</sup></div>
                <Input
                    className='input'
                    maxLength={10}
                    placeholder={`Enter your PAN Number`}
                    value={panDetails.panNumber}
                    onChange={(e) => setPanData((e.target.value).toUpperCase(), 'panNumber')}
                />

                <div className='label mt-24'>Gender<sup>*</sup></div>
                <Radio.Group onChange={(e)=>setPanData(e.target.value, 'gender')} value={panDetails.gender}>
                    <Radio value={'Male'}>Male</Radio>
                    <Radio value={'Female'}>Female</Radio>
                    <Radio value={'Other'}>Other</Radio>
                </Radio.Group>

                <div className='label mt-24'>{`Father's Name`}<sup>*</sup></div>
                <Input
                    className='input'
                    placeholder={`Enter your father's name`}
                    value={panDetails.fatherName}
                    onChange={(e) => setPanData(e.target.value, 'fatherName')}
                />

                <div className='label mt-24'>PAN Card<sup>*</sup></div>
                {
                  panDetails.panProof ? (
                      <div style={{display: 'flex', gap: '8px'}}>
                          <img src={panDetails.panProof} width={150} height={150}/>
                          <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>setPanData(null, 'panProof')}>x</p>
                      </div>
                  ) : (
                      <Upload
                          name="avatar"
                          listType="picture-card"
                          showUploadList={false}
                          accept="image/*"
                          onChange={(info)=>handleUpload(info, 'panProof')}
                          beforeUpload={handleBeforeUpload}
                          >
                          <div>
                              <Input type="file" accept="image/*" style={{ display: 'none' }} />
                              <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                          </div>
                      </Upload>
                  )
                }
            </div>
        </div>
        {/* PAN Details Ends here */}

        {/* Address Details starts here */}
        <div className='permanent-address-container'>
            <div className='container-header'>Permanent Address Details</div>

            <div className='pan-form-container'>

                <div className='label mt-24'>House Number<sup>*</sup></div>
                <Input
                    className='input'
                    placeholder={`Enter your House Number`}
                    value={addressDetails.houseNumber}
                    onChange={(e) => setAddressData(e.target.value, 'houseNumber')}
                />

                <div className='label mt-24'>Area / Street<sup>*</sup></div>
                <Input
                    className='input'
                    placeholder={`Enter your Area/Street`}
                    value={addressDetails.area}
                    onChange={(e) => setAddressData(e.target.value, 'area')}
                />

                <div className='label mt-24'>Town / City<sup>*</sup></div>
                <Input
                    className='input'
                    placeholder={`Enter your Town/City`}
                    value={addressDetails.town}
                    onChange={(e) => setAddressData(e.target.value, 'town')}
                />

                <div className='label mt-24'>State<sup>*</sup></div>
                <Select
                    style={{ width: '100%', textAlign: 'start', marginTop: '8px' }}
                    placeholder="Select Your State"
                    value={addressDetails.state}
                    onChange={(item) => setAddressData(item, 'state')}
                    optionLabelProp="label"
                    options={stateInfo}
                />

                <div className='label mt-24'>Pincode<sup>*</sup></div>
                <Input
                    className='input'
                    maxLength={6}
                    placeholder={`Enter your pincode`}
                    value={addressDetails.pincode}
                    onChange={(e) => setAddressData(e.target.value, 'pincode')}
                />

                <div className='label mt-24'>Landmark<sup>*</sup></div>
                <Input
                    className='input'
                    placeholder={`Enter landmark information`}
                    value={addressDetails.landmark}
                    onChange={(e) => setAddressData(e.target.value, 'landmark')}
                />

                <div className='label mt-24'>Do you want to upload DL or Aadhar Card<sup>*</sup></div>
                <Radio.Group onChange={(e)=>setAddressData(e.target.value, 'documentType')} value={addressDetails.documentType}>
                    <Radio value={'DL'}>Driving License</Radio>
                    <Radio value={'Aadhar'}>Aadhar Card</Radio>
                </Radio.Group>

                {
                    addressDetails.documentType ? (
                        <>
                            <div className='label mt-24'>{addressDetails.documentType} Front<sup>*</sup></div>
                            {
                                addressDetails.documentFront ? (
                                    <div style={{display: 'flex', gap: '8px'}}>
                                        <img src={addressDetails.documentFront} width={150} height={150}/>
                                        <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>setAddressData(null, 'documentFront')}>x</p>
                                    </div>
                                ) : (
                                    <Upload
                                        name="avatar"
                                        listType="picture-card"
                                        showUploadList={false}
                                        accept="image/*"
                                        onChange={(info)=>handleUpload(info, 'documentFront')}
                                        beforeUpload={handleBeforeUpload}
                                        >
                                        <div>
                                            <Input type="file" accept="image/*" style={{ display: 'none' }} />
                                            <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                                        </div>
                                    </Upload>
                                )
                            }

                            <div className='label mt-24'>{addressDetails.documentType} Back<sup>*</sup></div>
                            {
                                addressDetails.documentBack ? (
                                    <div style={{display: 'flex', gap: '8px'}}>
                                        <img src={addressDetails.documentBack} width={150} height={150}/>
                                        <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>setAddressData(null, 'documentBack')}>x</p>
                                    </div>
                                ) : (
                                    <Upload
                                        name="avatar"
                                        listType="picture-card"
                                        showUploadList={false}
                                        accept="image/*"
                                        onChange={(info)=>handleUpload(info, 'documentBack')}
                                        beforeUpload={handleBeforeUpload}
                                        >
                                        <div>
                                            <Input type="file" accept="image/*" style={{ display: 'none' }} />
                                            <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                                        </div>
                                    </Upload>
                                )
                            }
                        </>
                    ): null
                }
            </div>
        </div>

        <div className='button-container'>
            <Button className='submit-button' onClick={()=>submitDetails()}>Next</Button>
        </div>
    </>
  )
}

export default ManualDocumentVerification
