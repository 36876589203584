import React, { useEffect, useMemo , useState, useContext } from 'react';
import {Modal, Input, Switch, message} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import { AppContext } from "../../../App";

  
  const DownloadFilePopup = ({ mitra, visible, closePopup, downloadFunction, changeEmail, setChangeEmail}) => {
    const { mitraDispatch, mitraReducer } = useContext(AppContext);
    const [email, setEmail] = useState(mitra.email);
    // const [isDisabled, setIsDisabled] = useState(email && email !== '' ? true : false);
    useEffect(() => {
      setEmail(mitraReducer?.mitraInfo?.email);
    }, [mitraReducer?.mitraInfo?.email]);

    const validateEmail = (email) => {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailPattern.test(email);
    };

    const handleOnOk = ( changeEmail ) => {
      if (!email) {
        message.error('Email is required');
        return;
      }
      if (!validateEmail(email)) {
        message.error('Please enter a valid email address');
        return;
      }
      changeEmail && mitraDispatch({ type: 'LoginInfo', value: {...mitraReducer?.mitraInfo, email: email} });
      downloadFunction(email);
      closePopup(false);
    }

    return (
        <Modal
          centered
          visible={visible}
          onOk={() => handleOnOk(changeEmail)}
          onCancel={() => closePopup(false)}
          okText='Confirm'
        >
        <div style={{textAlign: 'center', margin: '20px', fontWeight: 'bold'}}>
            Your report will be sent to the below email id <br />
            Please edit this if not correct 
        </div>
        
        <div style={{display: 'flex', flexDirection: 'row', margin: '10px'}}>
            <Input placeholder="abc@xyz.com" value={email} onChange={(e) => setEmail(e.target.value)}/> 
            {/* <div onClick={() => setIsDisabled(!isDisabled)}><EditOutlined style={{margin: 'auto 10px', fontSize: '20px', color: '#4e94ff'}} /></div>  */}
        </div>
        <div style={{padding: '7px 10px'}}>
          <Switch defaultChecked={changeEmail} onChange={() => setChangeEmail( !changeEmail )} />
        <span style={{marginLeft: '10px'}}>Save as default.</span>
        </div>
      </Modal>
    )
}

export default DownloadFilePopup;