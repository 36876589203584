import React from "react";

function FileAddIcon({fillColor='none',height=25,width=26,strokeColor="#1D5FFF"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
    height={height}
    fill={fillColor}
      viewBox="0 0 26 25"
    >
      <path
        stroke="#1D5FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12.63 18.72v-6.24M9.503 15.6h6.25m4.167-6.24h-2.917c-1.167 0-1.75 0-2.196-.227a2.08 2.08 0 01-.91-.909c-.227-.445-.227-1.027-.227-2.192V3.12m6.25 15.392V9.699c0-.51 0-.764-.058-1.003a2.08 2.08 0 00-.25-.601c-.128-.21-.308-.39-.668-.75l-3.256-3.25c-.36-.36-.54-.54-.75-.668a2.084 2.084 0 00-.603-.25c-.24-.057-.495-.057-1.004-.057H8.67c-1.167 0-1.75 0-2.196.227-.392.2-.71.517-.91.909-.228.445-.228 1.027-.228 2.192v12.064c0 1.165 0 1.747.227 2.192.2.392.519.71.91.91.446.226 1.03.226 2.197.226h7.917c1.166 0 1.75 0 2.195-.227.392-.2.711-.517.91-.909.228-.445.228-1.027.228-2.192z"
      ></path>
    </svg>
  );
}

export default FileAddIcon;