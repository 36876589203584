import { Button, Card, Collapse, Popover } from 'antd'
import React from 'react'
import './SamvadiniModuleStyles.scss';
import { CaretRightOutlined } from '@ant-design/icons';
const { Panel } = Collapse;

const getInstructionsContent = () => {
    return (
        <div style={{textAlign: 'start'}}>
            <b>{`Use Vahan’s Samvaadini Bot to do intent check on your candidates, and increase your team’s productivity to 1.6 times!`}</b>
            <ol>
                <li className='list-style'>{`Select the required number of interested candidates and the calling duration based on your team’s availability and calling capacity. Example - If you have 2 TCs available for the next 1.5 hours, and each TC can call 25 candidates per hour, select 75 (2 * 1.5 * 25 = 75) as the required number of interested candidates, and 1 hour 30 minutes as duration`}</li>
                <li className='list-style'>{`Choose the mode of selecting candidates who should be called by Samvaadini. You can either upload an excel file yourself, or choose Vahan’s smart candidate selection logic. If you choose the manual mode, you should upload 4x number of the required interested candidates. If the required interested candidate count is 75, you should upload ~300 candidates.`}</li>
                <li className='list-style'>{`After submitting all details, Interested candidates will start appearing on the screen within 4-5 minutes. The Bot will generate candidates for the call duration selected by you until your input candidate count is met`}</li>
                <li className='list-style'>We suggest that your team starts calling candidates <b>IMMEDIATELY</b> after the Samvaadini Bot generates a candidate, to maximise the chances of the candidate picking up your call.</li>
            </ol>
        </div>
    )
}

function Instructions() {

  return (
    <>
    <div className='desktop-view' style={{position: 'relative'}}>
        <Popover
        content={getInstructionsContent()}
        title={<b>Instructions to use Samvaadini Bot</b>} 
        trigger="click"
        style={{display: 'flex', justifyContent: 'center'}}
        className='instructions-popover'
        >
            <Button className='no-border-highlight'>How to use Samvaadini Bot?</Button>
        </Popover>
        {/* <Card
        style={{margin: '12px 24px'}}
        bodyStyle={{padding: '8px 8px'}}
        >
            {getInstructionsContent()}
        </Card> */}
    </div>

    <div className='mobile-view'>
        <Collapse>
            <Panel header={<b>Important instructions</b>} key="1">
                {getInstructionsContent()}
            </Panel>
        </Collapse>
    </div>
    </>
  )
}

export default Instructions