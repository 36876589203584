import * as React from "react";
const UpArrow = ({fillColor='none',height=10,width=10,strokeColor="black"}) => (
    <svg
    width={width}
    height={height}
    fill={fillColor}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9201 15.05L13.4001 8.53001C12.6301 7.76001 11.3701 7.76001 10.6001 8.53001L4.08008 15.05"
      stroke={strokeColor}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default UpArrow;