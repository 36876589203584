import React, { useState, useEffect, useReducer, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import PageHeaderComponent from '../../common/PageHeader';
import { Divider, Tag, Button, Skeleton, Empty, message, Select, Input } from 'antd';
import { CloseOutlined, FilterOutlined, SearchOutlined, EnvironmentOutlined } from '@ant-design/icons';
import axios from 'axios';
import '../style/JobsPageContainerStyle.scss'
import JobDemandCard from './JobRequirementsSection/JobDemandCard';
import { getJobCategories, getJobCities, getJobLocalities, getJobRequirementsListing, pinClient, unPinClient } from '../../../service/jobDemandsService';
import { getActiveClientsForFilter } from '../../../service/commonService';
import { getDisplayableClientName } from '../../../utils/commonFunction';
import PosterModal from './JobPoster/PosterModal';
import LeadsPageContainer from './LeadsPageContainer';
import FilterSideSheetComponent from './common/FilterSideSheet';
import { trackEvent } from '../../../service/engagementMonitoringService';
import CONSTANTS from '../../../constants/constants';

const JobsPageContainer = ({ }) => {
    const { Option } = Select;
    const history = useHistory()
    const location = useLocation()
    const [breadcrumb, setBreadcrumb] = useState([{ link: '/job-demands/job-requirements', label: 'Jobs' }, { link: '/job-demands/job-requirements', label: "Job Requirements" }]);
    const [showJobRequirementsListing, setShowJobRequirementsListing] = useState(true);
    const [showLeadsFromPostersListing, setShowLeadsFromPostersListing] = useState(false);
    const [jobDemandList, setJobDemandList] = useState([
        {
            "vertical": "",
            "jobType": "",
            "maxSalary": "",
            "cityCount": "",
            "companyName": "",
            "openings": "",
            "localities": "",
            "companyId": "",
            "companyLogo": "",
            "requirements": {
                "education": [
                    ""
                ],
                "jobPreference": ""
            },
            "jobLocations": "",
            "jobs": "",
            "salaryText": ""
        }
    ])

    const [jobRequirementsKeyValue, setJobRequirementsKeyValue] = useState({
        key: "jobRequirements",
        value: "Job Requirements",
        path: "job-requirements"
    });
    const [leadsFromPostersKeyValue, setLeadsFromPostersKeyValue] = useState({
        key: "leadsFromPosters",
        value: "Leads from Posters",
        path: "leads-from-posters"
    });

    const [jobTypeFilterList, setJobTypeFilterList] = useState([]);
    const [selectedJobType, setSelectedJobType] = useState(undefined);

    const [cityFilterList, setCityFilterList] = useState([]);
    const [copyCityFilterList, setCopyCityFilterList] = useState([]);
    const [citySearch, setCitySearch] = useState("")
    const [selectedCity, setSelectedCity] = useState(undefined);

    const [filterSidebar, setFilterSidebar] = useState(false);
    const [posterListingFilter, setPosterListingFilter] = useState(false);
    const [lfpViewByLeads, setLFPViewByLeads] = useState(true);
    const [lfpViewByPosters, setLFPViewByPosters] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [cancelToken, setCancelToken] = useState(null); // State to store the cancel token
    const [filtersList, setFiltersList] = useState({
        category: "",
        city: "",
        clients: [],
        localities: [],
    })
    const [loadingListData, setLoadingListData] = useState(false);
    const [clientFilterList, setClientFilterList] = useState([])
    const [localitiesFilterList, setLocalitiesFilterList] = useState([])
    const [filteredLocalitiesList, setFilteredLocalitiesList] = useState([])
    const [searchLocality, setSearchLocality] = useState("")
    const [clientsFilterKeyValue, setClientsFilterKeyValue] = useState({
        key: "clients",
        value: "Clients"
    })

    const [localitiesFilterKeyValue, setLocalitiesFilterKeyValue] = useState({
        key: "localities",
        value: "Locality"
    })

    const [_, forceUpdate] = useReducer((x) => x + 1, 0);
    const [showPosterModal, setShowPosterModal] = useState(false);
    const [clientForPoster, setClientForPoster] = useState({
        key: "",
        label: "",
        value: false
    });
    var [viewPostersForClientFromDetailsPage, setViewPostersForClientFromDetailsPage] = useState({
        key: "",
        label: "",
        value: false
    });
    const selectRef = useRef(null);


    useEffect(() => {
        fetchCategoriesFilter()
        fetchCitiesFilter()
        fetchJobRequirementsListing()
        fetchClientsFilter()
    }, [])

    useEffect(() => {
        var currentPath = location.pathname.split('/');
        var currentListingPageVal = currentPath[currentPath.length - 1];

        if (currentListingPageVal && currentListingPageVal == jobRequirementsKeyValue.path) {
            setJobRequirementPage()
        }
        else {
            setLeadsFromPostersPage()
        }
    }, [])

    useEffect(() => {
        if (location && location.state && location.state.key) {
            setViewPostersForClientFromDetailsPage(location.state)
        }
    }, [location])

    useEffect(() => {
        if (clientFilterList.length > 0) {
            if (viewPostersForClientFromDetailsPage && viewPostersForClientFromDetailsPage.key) {
                viewPostersForClient(viewPostersForClientFromDetailsPage)
            }
        }
    }, [clientFilterList])

    const fetchCategoriesFilter = () => {
        getJobCategories().then((categoriesList) => {
            if (categoriesList && categoriesList.data && categoriesList.data.types) {
                setJobTypeFilterList(categoriesList.data.types)
                setSelectedJobType("All Types")
            }
            else {
                setJobTypeFilterList([])
            }
        })
    }

    const fetchCitiesFilter = () => {
        getJobCities().then((citiesList) => {
            if (citiesList && citiesList.data && citiesList.data.cityList) {
                setCityFilterList(citiesList.data.cityList)
                setCopyCityFilterList(citiesList.data.cityList)
                setSelectedCity("All Cities")
            }
            else {
                setCityFilterList([])
            }
        })
    }

    const fetchClientsFilter = () => {
        getActiveClientsForFilter().then(data => {
            if (data && data.data) {
                setClientFilterList(data.data.activeClients)
            }
        })
    }

    const fetchLocalitiesFilter = () => {
        getJobLocalities(filtersList.city).then(data => {
            if (data && data.data) {
                setLocalitiesFilterList(data.data.localities)
                setFilteredLocalitiesList(data.data.localities)
            }
        })
    }

    const changeListingPage = (key) => {
        switch (key) {
            case jobRequirementsKeyValue.key:
                setJobRequirementPage();
                break;
            case leadsFromPostersKeyValue.key:
                setLeadsFromPostersPage();
                break;
        }
    }

    const setJobRequirementPage = () => {
        trackEvent('viewed_job_requirements', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        setShowJobRequirementsListing(true)
        setShowLeadsFromPostersListing(false)
        setBreadcrumb([{ link: '/job-demands/job-requirements', label: 'Jobs' }, { link: '/job-demands/job-requirements', label: jobRequirementsKeyValue.value }])
        history.push({
            pathname: `/job-demands/job-requirements`,
        });
    }

    const setLeadsFromPostersPage = () => {
        trackEvent('viewed_leads_from_posters', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        setShowLeadsFromPostersListing(true)
        setShowJobRequirementsListing(false)
        setBreadcrumb([{ link: '/job-demands/job-requirements', label: 'Jobs' }, { link: '/job-demands/leads-from-posters', label: leadsFromPostersKeyValue.value }])
        history.push({
            pathname: `/job-demands/leads-from-posters`,
        });
    }

    const fetchJobRequirementsListing = () => {
        // Set loader
        setLoadingListData(true)
        if (cancelToken) {
            cancelToken.cancel(); // Used to cancel previous request to prevent data discrepancy
        }
        const newCancelToken = axios.CancelToken.source(); // Create a new CancelToken
        setCancelToken(newCancelToken); // Set the new cancel token

        try {
            // Make API Call with all filters, only releveant ones get added to the URL
            getJobRequirementsListing(currentPage ? currentPage : 1, filtersList.category, filtersList.city, JSON.stringify(filtersList.clients), JSON.stringify(filtersList.localities), newCancelToken.token).then((listRes) => {
                if (listRes && listRes.data && listRes.data.jobsData) {
                    setJobDemandList(listRes.data.jobsData)
                    setLoadingListData(false)
                    // setTotalBulkReferralsCount(listRes.data.totalCount)
                }
                else {
                    setLoadingListData(false)
                    setJobDemandList([])
                    message.error("Something went wrong, please try again later", 4)
                }
            })
        }
        catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled');
            } else {
                console.log('Request err', error);
                setLoadingListData(false)
            }
        }
    }

    const handleJobTypeFilterChange = (jobTypeSelected) => {
        trackEvent('clicked_category_filter', { category: jobTypeSelected }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        let tempFiltersList = filtersList;
        tempFiltersList.category = jobTypeSelected
        setFiltersList(JSON.parse(JSON.stringify(tempFiltersList)))
        fetchJobRequirementsListing()
        setSelectedJobType(jobTypeSelected)
    }

    const onCityInpChange = (inp) => {
        inp.preventDefault();
        if (inp?.target?.value) {
            setCitySearch(inp.target.value)
            let tempCityFilterList = cityFilterList.filter(city => city.toLowerCase().includes(inp.target.value.toLowerCase()))
            setCopyCityFilterList(JSON.parse(JSON.stringify(tempCityFilterList)))
        }
        else if (inp?.target?.value == "") {
            setCitySearch("")
            setCopyCityFilterList(JSON.parse(JSON.stringify(cityFilterList)))
        }
    }

    const handleCityChange = (citySelected) => {
        if (citySelected) {
            trackEvent('clicked_city_filter', { city: citySelected }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
            setCitySearch("")
            let tempFiltersList = filtersList;
            tempFiltersList.city = citySelected
            setCopyCityFilterList(JSON.parse(JSON.stringify(cityFilterList)))

            setFiltersList(JSON.parse(JSON.stringify(tempFiltersList)))
            fetchLocalitiesFilter()
            fetchJobRequirementsListing()
            setSelectedCity(citySelected)
        }
    }

    const applyFilterCondition = () => {
        let result = false;

        if ((filtersList.clients && filtersList.clients.length > 0) || (filtersList.localities && filtersList.localities.length > 0)) {
            result = true
        }
        return result;
    }

    const pinSelectedClient = (companyKey) => {
        trackEvent('clicked_pin_client', {client: companyKey}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        pinClient(companyKey).then((pinRes) => {
            if (pinRes && pinRes.data) {
                fetchJobRequirementsListing()
            }
        })
    }

    const unPinCompany = () => {
        unPinClient().then((unPinRes) => {
            if (unPinRes && unPinRes.data) {
                fetchJobRequirementsListing()
            }
        })
    }

    const createPosterForClient = (clientDetails) => {
        setClientForPoster(clientDetails)
        setShowPosterModal(true);
    }

    const viewPostersForClient = (clientDetails) => {
        setViewPostersForClientFromDetailsPage(clientDetails)
        // for (let i = 0; i < clientFilterList.length; i++) {
        //     clientFilterList[i].value = false
        //     if (clientFilterList[i].key == clientDetails.key) {
        //         onClickTags(clientFilterList[i], clientsFilterKeyValue.value, i)
        //     }
        // }
        changeListingPage(leadsFromPostersKeyValue.key)
        setLFPViewByPosters(true);
        setLFPViewByLeads(false)
    }

    const showFilterSidebar = () => {
        showJobRequirementsListing ? setFilterSidebar(true) : setPosterListingFilter(true)
    }

    const returnFilterLabel = (label, arrObj) => {
        let localityArrObj = []
        if (label == localitiesFilterKeyValue.value) {
            if (searchLocality == "") {
                localityArrObj = localitiesFilterList.slice(0, 5)
            }
            else {
                localityArrObj = filteredLocalitiesList
            }
        }
        return <div className='tag-containers'>
            {label == localitiesFilterKeyValue.value ?
                <div>
                    LOCALITIES in <strong>{selectedCity}</strong>
                </div>
                :
                <div>{label.toUpperCase()}</div>
            }
            {
                label == clientsFilterKeyValue.value ?
                    <div className='tag-wrapper-container'>
                        {arrObj.map((item, index) => {
                            return <Tag className={item && item.value ? 'tags tag-active' : 'tags'}
                                key={index}
                                onClick={() => { onClickTags(item, label, index) }}
                            >
                                {getDisplayableClientName(item.label)}
                            </Tag>
                        })}
                    </div>
                    : label == localitiesFilterKeyValue.value ?
                        <div className='jp-localities-search'>
                            <Input value={searchLocality} placeholder="Search Locality" allowClear prefix={<SearchOutlined />} onChange={onLocalitySearch} />

                            <div className='tag-wrapper-container align-items-center'>
                                {localityArrObj.map((item, index) => {
                                    return <div>
                                        <Tag className={item && item.value ? 'tags tag-active' : 'tags'}
                                            key={index}
                                            onClick={() => { onClickTags(item, label, index) }}
                                        >
                                            {item.name}
                                        </Tag>
                                    </div>

                                })
                                }
                                {
                                    searchLocality == "" ?
                                        <span className='jp-more-localities-txt'>
                                            + {localitiesFilterList.length - 5} localities
                                        </span>
                                        :
                                        null
                                }
                            </div>


                        </div> : null
            }
        </div>
    }

    const onClickTags = (item, label, index) => {
        let allFilterListCopy = filtersList
        setCurrentPage(1)
        if (label == clientsFilterKeyValue.value) {
            clientFilterList[index].value = !clientFilterList[index].value;
            allFilterListCopy.clients = []

            for (let i = 0; i < clientFilterList.length; i++) {
                if (clientFilterList[i].value) {
                    allFilterListCopy.clients.push(clientFilterList[i].key)
                }
            }
            trackEvent('clicked_client_filter', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        }
        else if (label == localitiesFilterKeyValue.value) {

            if (searchLocality) {
                trackEvent('searched_locality', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                selectFilteredLocalityInArray(filteredLocalitiesList, item, index, 'copy')
                localitiesFilterList.map((locality, localityInd) => {
                    if (locality.key == item.key) {
                        locality.value = !locality.value
                        if (locality.value) {
                            const selectedLocality = localitiesFilterList.splice(localityInd, 1)[0];
                            localitiesFilterList.unshift(selectedLocality);
                        }
                    }
                })
            }
            else
                selectFilteredLocalityInArray(localitiesFilterList, item, index, 'OG')
            allFilterListCopy.localities = []
            for (let i = 0; i < localitiesFilterList.length; i++) {
                if (localitiesFilterList[i].value) {
                    allFilterListCopy.localities.push(localitiesFilterList[i].key)
                }
            }
        }

        setFiltersList(JSON.parse(JSON.stringify(allFilterListCopy)))
        if (showJobRequirementsListing)
            fetchJobRequirementsListing()
        forceUpdate()
    }

    const selectFilteredLocalityInArray = (arr, item, index, txt) => {
        console.log("BEFORE_ITEM_YOYO", filteredLocalitiesList[index], txt)

        arr.map((locality, localityInd) => {
            if (locality.key == item.key) {
                locality.value = !locality.value
                if (locality.value) {
                    const selectedLocality = arr.splice(localityInd, 1)[0];
                    arr.unshift(selectedLocality);
                }
            }
        })

        console.log("AFTER_ITEM_YOYO", filteredLocalitiesList[index], txt)

    }

    const removeSingleClientFromFilter = (client, clientInd) => {
        let allFilterListCopy = filtersList

        clientFilterList[clientInd].value = false;

        allFilterListCopy.clients = []
        for (let i = 0; i < clientFilterList.length; i++) {
            if (clientFilterList[i].value) {
                allFilterListCopy.clients.push(clientFilterList[i].key)
            }
        }

        setFiltersList(JSON.parse(JSON.stringify(allFilterListCopy)))
        if (showJobRequirementsListing)
            fetchJobRequirementsListing()
    }

    const removeSingleLocalityFromFilter = (client, localityInd) => {
        let allFilterListCopy = filtersList

        localitiesFilterList[localityInd].value = false;

        allFilterListCopy.localities = []
        for (let i = 0; i < localitiesFilterList.length; i++) {
            if (localitiesFilterList[i].value) {
                allFilterListCopy.localities.push(localitiesFilterList[i].key)
            }
        }

        setFiltersList(JSON.parse(JSON.stringify(allFilterListCopy)))
        if (showJobRequirementsListing)
            fetchJobRequirementsListing()
    }

    const onLocalitySearch = (inp) => {
        setSearchLocality(inp.target.value)
        if (inp && inp.target && inp.target.value) {
            let tempFilteredLocalityList = localitiesFilterList.filter(locality => locality.name.toLowerCase().includes(inp.target.value.toLowerCase()))
            setFilteredLocalitiesList(JSON.parse(JSON.stringify(tempFilteredLocalityList)))
        }
    }

    const clearFilters = (whichFilter) => {
        let existingClientFilterList = clientFilterList
        let existingFilters = filtersList

        if (whichFilter == clientsFilterKeyValue.key || whichFilter == "all") {
            for (let i = 0; i < existingClientFilterList.length; i++) {
                existingClientFilterList[i].value = false
            }
            setClientFilterList(JSON.parse(JSON.stringify(existingClientFilterList)))
            existingFilters.clients = []
        }

        if (whichFilter == localitiesFilterKeyValue.key || whichFilter == "all") {
            let tempFilteredLocalitiesArr = filteredLocalitiesList
            let tempLocalitiesFilterList = localitiesFilterList
            tempFilteredLocalitiesArr.map((locality) => {
                locality.value = false
            })
            tempLocalitiesFilterList.map((locality) => {
                locality.value = false
            })
            setFilteredLocalitiesList(JSON.parse(JSON.stringify(tempFilteredLocalitiesArr)))
            setLocalitiesFilterList(JSON.parse(JSON.stringify(tempLocalitiesFilterList)))
            existingFilters.localities = []
        }

        if (whichFilter == localitiesFilterKeyValue.key || whichFilter == "all") {
            setFilterSidebar(false)
            forceUpdate()
        }
        setFiltersList(JSON.parse(JSON.stringify(existingFilters)));
        fetchJobRequirementsListing()
    }

    return (
        <div className='jobs-page-new-parent mainContentZ'>
            <div className="containerTop">
                <div className="jp-new-breadcrumb-header header">
                    <>
                        <PageHeaderComponent backBtnBool={true} breadcrumb={breadcrumb} />
                    </>
                </div>

            </div>

            <Divider></Divider>

            <div className='jp-padded-section'>
                <div className='jp-tabs-section d-flex justify-content-between'>
                    <div className='jp-tabs d-flex show-only-desktop'>
                        <Button onClick={() => changeListingPage(jobRequirementsKeyValue.key)} className={showJobRequirementsListing ? "jp-selected-tab" : "jp-unselected-tab"}>
                            {jobRequirementsKeyValue.value}
                        </Button>
                        <Button onClick={() => changeListingPage(leadsFromPostersKeyValue.key)} className={showLeadsFromPostersListing ? "jp-selected-tab" : "jp-unselected-tab"}>
                            {leadsFromPostersKeyValue.value}
                        </Button>
                    </div>

                    <div className='show-only-mobile jp-tabs-container-mobile'>
                        <div className='jp-tabs d-flex '>
                            <Select
                                placeholder="Select View"
                                style={{ width: '75%' }}
                                onChange={(jobType) => { changeListingPage(jobType) }}
                                value={showJobRequirementsListing ? jobRequirementsKeyValue.key : leadsFromPostersKeyValue.key}
                            >
                                <Option value={jobRequirementsKeyValue.key} key={jobRequirementsKeyValue.key}>{jobRequirementsKeyValue.value}</Option>
                                <Option value={leadsFromPostersKeyValue.key} key={leadsFromPostersKeyValue.key}>{leadsFromPostersKeyValue.value}</Option>
                            </Select>
                        </div>
                    </div>

                    {
                        showLeadsFromPostersListing ?
                            <div className='jp-lfp-tabs d-flex'>
                                <div className={`jp-lfp-leads-tab jp-lfp-selected-tab-right-margin jp-cp ${lfpViewByLeads ? "jp-lfp-selected-tab" : ""}`} onClick={() => { setLFPViewByLeads(true); setLFPViewByPosters(false) }}>
                                    <div className='jp-lfp-tab-txt show-only-desktop'>
                                        View by Leads
                                    </div>
                                    <div className='jp-lfp-tab-txt show-only-mobile'>
                                        Leads
                                    </div>
                                </div>
                                <div className={`jp-lfp-posters-tab jp-lfp-selected-tab-left-margin jp-cp ${lfpViewByPosters ? "jp-lfp-selected-tab" : ""}`} onClick={() => { setLFPViewByPosters(true); setLFPViewByLeads(false) }}>
                                    <div className='jp-lfp-tab-txt show-only-desktop'>
                                        View by Posters
                                    </div>
                                    <div className='jp-lfp-tab-txt show-only-mobile'>
                                        Posters
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }

                </div>

                {
                    showJobRequirementsListing ?
                        <div>
                            <div className='jp-filters-section-container'>
                                <div className='jp-filters-section jp-job-requirement-listing show-only-desktop-disp-block'>
                                    <div className='jp-filters-top-section d-flex align-items-center'>
                                        <span className='jp-filters-top-section-txt-1'>
                                            Show
                                        </span>

                                        <div className='jp-filters-top-section-select'>
                                            <Select
                                                placeholder="Select Job Type"
                                                style={{ width: '100%' }}
                                                onChange={(jobType) => { handleJobTypeFilterChange(jobType) }}
                                                value={selectedJobType}
                                            >
                                                {jobTypeFilterList?.map((item, index) => {
                                                    return <Option value={item} key={index}>{item}</Option>
                                                })}
                                            </Select>
                                        </div>

                                        <span className='jp-filters-top-section-txt-2 show-only-desktop'>
                                            Jobs in
                                        </span>

                                        <div className='jp-filters-top-section-select'>
                                            <Select
                                                placeholder={"Search City"}
                                                style={{ width: '100%' }}
                                                className='w-100'
                                                onChange={(city) => { handleCityChange(city) }}
                                                value={selectedCity}
                                                dropdownRender={(menu) => (
                                                    <div className='w-100'>
                                                        <Input
                                                            prefix={<SearchOutlined style={{ color: "rgba(0, 0, 0, 0.45)" }} />}
                                                            value={citySearch}
                                                            onChange={onCityInpChange}
                                                            placeholder="Search for a city"
                                                            style={{ marginBottom: 8, maxWidth: "90%" }}
                                                            allowClear
                                                        />
                                                        <div style={{ maxHeight: 200, overflowY: 'auto' }}>
                                                            {menu}
                                                        </div>
                                                    </div>
                                                )}
                                            >
                                                {/* <Space style={{ padding: '0 8px 4px'}}>
                                                        <Input
                                                            value={citySearch}
                                                            defaultValue={citySearch}
                                                            placeholder="Search City"
                                                            allowClear
                                                            onChange={onCityInpChange}
                                                            onKeyDown={(e) => e.stopPropagation()}
                                                            onClick={(e) => e.stopPropagation()}
                                                            clearAll
                                                        />
                                                    </Space> */}
                                                {copyCityFilterList?.map((item, index) => {
                                                    return <Option value={item} key={index}>{item}</Option>
                                                })}
                                            </Select>
                                        </div>
                                    </div>

                                    <div className='jp-filters-bottom-section d-flex align-items-center'>
                                        <Button style={applyFilterCondition() ? { color: '#D34612', backgroundColor: '#FFEAE0', border: '1px solid #D34612' } : { color: '#D34612', border: '1px solid #D34612' }} className='jp-apply-filter-btn d-flex align-items-center' onClick={() => { showFilterSidebar() }} icon={<FilterOutlined />}>Filter</Button>
                                        {
                                            applyFilterCondition() ?
                                                clientFilterList && clientFilterList.length > 0 ?

                                                    clientFilterList.map((client, clientInd) => {
                                                        return (
                                                            client.value ?
                                                                <div className='d-flex jp-single-filter-item justify-content-center align-items-center'>
                                                                    <div className='single-filter-txt'>
                                                                        {getDisplayableClientName(client.label)}
                                                                    </div>
                                                                    <CloseOutlined style={{ color: "rgba(0, 0, 0, 0.45)", marginLeft: "8px" }} onClick={() => removeSingleClientFromFilter(client, clientInd)} />
                                                                </div>
                                                                : null

                                                        )
                                                    })
                                                    : null
                                                : null
                                        }

                                        {
                                            applyFilterCondition() ?
                                                localitiesFilterList && localitiesFilterList.length > 0 ?

                                                    localitiesFilterList.map((locality, localityInd) => {
                                                        return (
                                                            locality.value ?
                                                                <div className='d-flex jp-single-filter-item justify-content-center align-items-center'>
                                                                    <EnvironmentOutlined style={{ color: "rgba(0, 0, 0, 0.45)", marginRight: "4px" }} />
                                                                    <div className='single-filter-txt' title={locality.name} >
                                                                        {locality.name}
                                                                    </div>
                                                                    <CloseOutlined style={{ color: "rgba(0, 0, 0, 0.45)", marginLeft: "8px" }} onClick={() => removeSingleLocalityFromFilter(locality, localityInd)} />
                                                                </div>
                                                                : null

                                                        )
                                                    })
                                                    : null
                                                : null
                                        }

                                    </div>
                                </div>

                                <div className='jp-filters-section jp-job-requirement-listing show-only-mobile'>
                                    <div className='jp-filters-top-section d-flex align-items-center'>
                                        <span className='jp-filters-section-mobile-txt-1'>
                                            Show
                                        </span>

                                        <Select
                                            placeholder="Select Job Type"
                                            style={{ width: '100%' }}
                                            onChange={(jobType) => { handleJobTypeFilterChange(jobType) }}
                                            value={selectedJobType}
                                            className='jp-filters-section-select-mobile'
                                        >
                                            {jobTypeFilterList?.map((item, index) => {
                                                return <Option value={item} key={index}>{item}</Option>
                                            })}
                                        </Select>

                                        <span className=''>
                                            Jobs
                                        </span>
                                    </div>

                                    <div className='d-flex'>
                                        <span className='jp-filters-section-mobile-txt-1'>
                                            in
                                        </span>

                                        <Select
                                            placeholder={"Search City"}
                                            onChange={(city) => { handleCityChange(city) }}
                                            value={selectedCity}
                                            className='jp-filters-section-select-mobile w-100'
                                            dropdownRender={(menu) => (
                                                <div className='w-100'>
                                                    <Input
                                                        prefix={<SearchOutlined style={{ color: "rgba(0, 0, 0, 0.45)" }} />}
                                                        value={citySearch}
                                                        onChange={onCityInpChange}
                                                        placeholder="Search for a city"
                                                        style={{ marginBottom: 8, maxWidth: "90%" }}
                                                        allowClear
                                                    />
                                                    <div style={{ maxHeight: 200, overflowY: 'auto' }}>
                                                        {menu}
                                                    </div>
                                                </div>
                                            )}
                                        >
                                            {/* <Space style={{ padding: '0 8px 4px'}}>
                                                        <Input
                                                            value={citySearch}
                                                            defaultValue={citySearch}
                                                            placeholder="Search City"
                                                            allowClear
                                                            onChange={onCityInpChange}
                                                            onKeyDown={(e) => e.stopPropagation()}
                                                            onClick={(e) => e.stopPropagation()}
                                                            clearAll
                                                        />
                                                    </Space> */}
                                            {copyCityFilterList?.map((item, index) => {
                                                return <Option value={item} key={index}>{item}</Option>
                                            })}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div className='jp-job-demand-listing-section'>
                                <div className='jp-job-demand-count d-flex justify-content-between'>
                                    {!loadingListData && jobDemandList && jobDemandList.length > 0 ? `${jobDemandList.length} ${jobDemandList.length > 1 ? 'clients' : 'client'}` : ""}
                                    <Button style={applyFilterCondition() ? { color: '#D34612', backgroundColor: '#FFEAE0', border: '1px solid #D34612' } : { color: '#D34612', border: '1px solid #D34612' }} className='jp-apply-filter-btn show-only-mobile d-flex align-items-center' onClick={() => { showFilterSidebar() }} icon={<FilterOutlined />}>Filter</Button>
                                </div>
                                {
                                    loadingListData ?
                                        <Skeleton />
                                        :
                                        <div className='jp-job-demand-cards d-flex justify-content-between'>
                                            {
                                                jobDemandList && jobDemandList.length > 0 ?
                                                    // <Flex wrap="wrap" gap="small">
                                                    jobDemandList.map((jobDemand, jobDemandInd) => {
                                                        return (
                                                            <JobDemandCard jobData={jobDemand} jobInd={jobDemandInd} onPinClicked={(companyKey) => pinSelectedClient(companyKey)} onUnpinClicked={() => unPinCompany()} onCreatePosterClicked={(clientDetails) => createPosterForClient(clientDetails)} onViewPostersClicked={(clientDetails) => viewPostersForClient(clientDetails)} selectedCity={filtersList.city} />
                                                        )
                                                    })
                                                    // </Flex>
                                                    : <Empty className='w-100' />
                                            }
                                        </div>
                                }

                            </div>
                        </div>

                        :
                        <LeadsPageContainer viewByLeads={lfpViewByLeads} viewByPosters={lfpViewByPosters} openFilter={posterListingFilter} clientFilterListFromParent={JSON.parse(JSON.stringify(clientFilterList))} viewPosterClicked={viewPostersForClientFromDetailsPage} />
                }

            </div>

            {filterSidebar && clientFilterList &&
                <FilterSideSheetComponent submit={{
                    disabled: false
                    , onClick: () => setFilterSidebar(false)
                }}
                    clearAll={{ disabled: false, onClick: () => clearFilters("all") }}
                    sidername={"Filter Jobs"}
                    onDismiss={() => { setFilterSidebar(false) }}>
                    <div className='filter-home-wrapper'>
                        {returnFilterLabel("Clients", clientFilterList)}
                        {localitiesFilterList && localitiesFilterList.length > 0 ? returnFilterLabel(`Locality`, filteredLocalitiesList) : null}
                    </div>
                </FilterSideSheetComponent>
            }

            {
                showPosterModal ?
                    <PosterModal client={clientForPoster} city={""} isModalOpen={showPosterModal} closeModal={() => setShowPosterModal(false)} /> : null
            }

        </div>
    )
}

export default JobsPageContainer; 