import React, {useState, useEffect, useContext} from 'react'
import * as jobDemandsService from '../../service/jobDemandsService';
import {useParams} from "react-router-dom";
import SubmitPopup from "./../common/SubmitPopup.component"
import {useForm} from 'react-hook-form';
const JobPostingsManagement = () => {
	const {register} = useForm();
	const [name, setName] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const {id} = useParams();
	const {client} = useParams();
	const [showSubmitPopup, setShowSubmitPopup] = useState(false);
	const [submitText, setSubmitText] = useState('Your response has been submitted! One of our representatives will call you back shortly!')
	const [phoneNumberError, setPhoneNumberError] = useState(false)
	const [nameError, setNameError] = useState(false)
	const [APIError, setAPIError] = useState("")
	const [image, setImage] = useState("")

	const [showAPIError, setShowAPIError] = useState(false)


	const handleNameChange = (event) => {
		setName(event.target.value);
	};
	const handleNumberChange = (event) => {
		setPhoneNumber(event.target.value);
	};

	const createJobPostingResponse = async (jobPostingId, name, phoneNumber) => {
		try {
			if (name === undefined || name.trim() === '') {
				setNameError(true)
				return
			}
			else {
				setNameError(false)
			}
			if (phoneNumber === undefined || phoneNumber.trim() === '') {
				setPhoneNumberError(true);
				return;
			}
			else {
				setPhoneNumberError(false)
			}
			jobDemandsService.createJobPostingResponse(jobPostingId, name, phoneNumber).then((result) => {
				if (result && result.data.success) {
					setShowSubmitPopup(true);
				}
			})
				.catch(error => {
					console.log(error.response)
					if (error.response.data) {
						setShowAPIError(true)
						setAPIError(error.response.data.message)
					}
				})

		} catch (error) {
			console.log(error);
		}
	}
	const getJobPostingImage = async () => {
		jobDemandsService.getJobPosterById(id).then(response => {
			if (response.data && response.data.data) {setImage(response.data.data)}
		});
		return '';
	}
	useEffect(() => {
		getJobPostingImage();
	}, [])
	return (
		<>
			<div className="jobs-page">
				<div className="header">
					<div className="header-text">
						<span className="job-post-title">Job Post for {client}</span>
					</div>
				</div>
				<div className="preview">
					<div className="image-layout">
						<img className="poster" alt={client} src={image} />
					</div>

					<div className="input-fields">
						{
							nameError ?
								<div className="error">Please enter Name</div>
								: null
						}
						<input
							className="dropdown-control"
							type="text"
							placeholder="Enter your full name"
							name="name"
							onChange={handleNameChange}
							required
						/>
						{
							phoneNumberError ?
								<div className="error">Please enter a Valid Phone Number</div>
								: null
						}
						<input
							className="dropdown-control"
							ref={register({required: true, minLength: 10, maxLength: 10})}
							type="tel"
							placeholder="Your Mobile number"
							name="phoneNumber"
							onChange={handleNumberChange}
							required
						/>

						<button className="createViewWeblink"
							onClick={(e) => {createJobPostingResponse(id, name, phoneNumber);}}
							style={{width: '95%'}}>
							Apply Now
						</button>
						{
							showAPIError ?
								<div className="error">{APIError}</div>
								: null
						}
					</div>
				</div>
			</div>
			<SubmitPopup visible={showSubmitPopup} setShowPopup={setShowSubmitPopup} text={submitText}></SubmitPopup>
		</>
	)
}

export default JobPostingsManagement;