import React, { useContext, useEffect, useRef, useState } from 'react'
import {Button, Upload,notification, Input} from 'antd';
import { AppContext } from '../../../../App';
import { postAdditionalInfo, uploadImageToS3 } from '../../../../service/additionalInfoService';
import {UploadOutlined} from '@ant-design/icons'
import { submitZeptoDetails } from '../../../../service/ZeptoRecAdditionalInfoService';
import { useHistory } from "react-router-dom";

function ZeptoFormPart3({lead,  leadAdditionalDetails}) {
  const {mitraReducer,mitraDispatch, setAlert,setSpin } = useContext(AppContext);
  const [errorField, setErrorField] = useState(null);
  const startElement = useRef(null);
  const [documents, setDocuments] = useState({ // property name resembles element name in the DB
    panCard: null,
    aadhaarCard: null,
    aadhaarCardBack: null,
    userSelfie: null,
    drivingLicense: null,
    drivingLicenseBack: null,
    vehicleRc: null,
    vehicleRcBack: null
  });
  const history = useHistory();
  const [showRcAndDL, setShowRcAndDL] = useState(true);

  const showLoader = (value) => {
    setSpin({
        loading: value, //false or true when actived
        delay: null,
        tip: 'Updating Information...'  //Loading copy here
    })
  }

  const checkErrors = (res) => {
    const newErrors = [];
    for (let key in res.data) {
        if (res.data[key] !== "200") {
            newErrors.push(`${key}: ${res.data[key]}`)
        }
    }

    if(newErrors.length) {
        // setErrors([...newErrors]);
        alert(newErrors.join("\n"))
        return true;
    } 
    return false;
}

  const handleUpload =(info, type) => { // type is one of the property of documents object
    showLoader(true);

    uploadImageToS3(info.file)
        .then(async(res)=> {

            if(res?.data?.imageUrl) {

                const submissionData = {
                    [type]: {
                        url: res.data.imageUrl,
                        originalName: info?.file?.name,
                        size: info?.file?.size
                    }
                }

                const response = await postAdditionalInfo(submissionData, mitraReducer?.mitraInfo, lead);

                if(!checkErrors(response)) {
                    setDocuments({...documents, [type]: res?.data?.imageUrl});
                }

                showLoader(false);
            } else {
                showLoader(false);
            }
        }).catch(e=> {
            showLoader(false);

            notification['error']({
                message: 'Image upload failed, please try again later.',
              });

            console.log(e);
        })
  };

  const handleBeforeUpload = (file) => {
    // Return false to prevent upload
    return false;
  };

  const handleScrollToPosition = (ref) => {
    if (ref?.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const checkIfAllRequiredFieldsArePopulated = () => {
    if(!documents.panCard) {
        notification['error']({
            message: 'Upload your PAN Card!'
        });

        setErrorField('panCard');
        handleScrollToPosition(startElement);

        return false;
    } else if(!documents.aadhaarCard) {
        notification['error']({
            message: 'Upload your Aadhar Card (Front)!'
        });

        setErrorField('aadharCard');
        handleScrollToPosition(startElement);

        return false;
    } else if(!documents.aadhaarCardBack) {
        notification['error']({
            message: 'Upload your Aadhar Card (Back)!'
        });

        setErrorField('aadhaarCardBack');
        handleScrollToPosition(startElement);

        return false;
    }

    return true;
  }

  const onSubmit = async() => {
    if(checkIfAllRequiredFieldsArePopulated()) {

        try {

            const payload = {
                phoneNumber: lead?.phoneNumber
            }
    
            const response = await submitZeptoDetails(payload);
    
            if(response?.data?.success) {
                notification['success']({
                    message: 'Details submitted successfully!'
                });

                history.replace({ pathname: "/recruiter-candidates" });
            } else {
                notification['error']({
                    message: 'Error submitting details, please try again later.'
                })
            }

            showLoader(false);
        } catch(err) {
            showLoader(false);
            notification['error']({
                message: 'Error submitting details, please try again later.'
            })
        }
        
    }
  }

  const autoPopulateDetails = () => {
    const docs = { // property name resembles element name in the DB
        panCard: null,
        aadhaarCard: null,
        aadhaarCardBack: null,
        userSelfie: null,
        drivingLicense: null,
        drivingLicenseBack: null,
        vehicleRc: null,
        vehicleRcBack: null
      };

    if(leadAdditionalDetails?.panCard?.[0]?.url) {
        docs.panCard = leadAdditionalDetails?.panCard?.[0]?.url;
    }

    if(leadAdditionalDetails?.aadhaarCard?.[0]?.url) {
        docs.aadhaarCard = leadAdditionalDetails?.aadhaarCard?.[0]?.url;
    }

    if(leadAdditionalDetails?.aadhaarCardBack?.[0]?.url) {
        docs.aadhaarCardBack = leadAdditionalDetails?.aadhaarCardBack?.[0]?.url;
    }
    if(leadAdditionalDetails?.userSelfie?.[0]?.url) {
        docs.userSelfie = leadAdditionalDetails?.userSelfie?.[0]?.url;
    }
    if(leadAdditionalDetails?.drivingLicense?.[0]?.url) {
        docs.drivingLicense = leadAdditionalDetails?.drivingLicense?.[0]?.url;
    }
    if(leadAdditionalDetails?.drivingLicenseBack?.[0]?.url) {
        docs.drivingLicenseBack = leadAdditionalDetails?.drivingLicenseBack?.[0]?.url;
    }
    if(leadAdditionalDetails?.vehicleRc?.[0]?.url) {
        docs.vehicleRc = leadAdditionalDetails?.vehicleRc?.[0]?.url;
    }
    if(leadAdditionalDetails?.vehicleRcBack?.[0]?.url) {
        docs.vehicleRcBack = leadAdditionalDetails?.vehicleRcBack?.[0]?.url;
    }

    if(leadAdditionalDetails.vehicleType === 'Petrol Bike') {
        setShowRcAndDL(true);
    } else {
        setShowRcAndDL(false);
    }

    setDocuments(docs);
  }

  useEffect(() => {
    autoPopulateDetails();
  }, [leadAdditionalDetails])
  

  return (
    <>
    <div className='vehicle-type-container' ref={startElement}>
            <div className='container-header'>Document Upload</div>

            <div style={{margin: '16px'}}>
              <div className='label mt-24'>PAN<sup>*</sup></div>
              {
                documents.panCard ? (
                    <div style={{display: 'flex', gap: '8px'}}>
                        <img src={documents.panCard} width={150} height={150}/>
                        <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>setDocuments({...documents, panCard: null})}>x</p>
                    </div>
                ): (
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        showUploadList={false}
                        accept="image/*"
                        onChange={(info)=>handleUpload(info, 'panCard')}
                        className={errorField==='panCard' ? 'input-error':''}
                        beforeUpload={handleBeforeUpload}
                        >
                        <div>
                            <Input type="file" accept="image/*" style={{ display: 'none' }} />
                            <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                        </div>
                    </Upload>
                )
              }
              

              <div className='label mt-24'>Aadhar Card (Front)<sup>*</sup></div>
              {
                documents.aadhaarCard ? (
                    <div style={{display: 'flex', gap: '8px'}}>
                        <img src={documents.aadhaarCard} width={150} height={150}/>
                        <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>setDocuments({...documents, aadhaarCard: null})}>x</p>
                    </div>
                ): (
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        showUploadList={false}
                        accept="image/*"
                        onChange={(info)=>handleUpload(info, 'aadhaarCard')}
                        className={errorField==='aadharCard' ? 'input-error':''}
                        beforeUpload={handleBeforeUpload}
                        >
                        <div>
                            <Input type="file" accept="image/*" style={{ display: 'none' }} />
                            <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                        </div>
                    </Upload>
                )
              }
              

              <div className='label mt-24'>Aadhar Card (Back)<sup>*</sup></div>
              {
                documents?.aadhaarCardBack ? (
                    <div style={{display: 'flex', gap: '8px'}}>
                        <img src={documents.aadhaarCardBack} width={150} height={150}/>
                        <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>setDocuments({...documents, aadhaarCardBack: null})}>x</p>
                    </div>
                ):(
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        showUploadList={false}
                        accept="image/*"
                        onChange={(info)=>handleUpload(info, 'aadhaarCardBack')}
                        className={errorField==='aadhaarCardBack' ? 'input-error':''}
                        beforeUpload={handleBeforeUpload}
                        >
                        <div>
                            <Input type="file" accept="image/*" style={{ display: 'none' }} />
                            <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                        </div>
                    </Upload>
                )
              }
              

              <div className='label mt-24'>Selfie</div>
              {
                documents.userSelfie ? (
                    <div style={{display: 'flex', gap: '8px'}}>
                        <img src={documents.userSelfie} width={150} height={150}/>
                        <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>setDocuments({...documents, userSelfie: null})}>x</p>
                    </div>
                ):(
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        showUploadList={false}
                        accept="image/*"
                        onChange={(info)=>handleUpload(info, 'userSelfie')}
                        beforeUpload={handleBeforeUpload}
                        >
                        <div>
                            <Input type="file" accept="image/*" style={{ display: 'none' }} />
                            <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                        </div>
                    </Upload>
                )
              }
              
              {
                showRcAndDL ? (
                    <>
                        <div className='label mt-24'>Driving License (Front)</div>
                        {
                            documents.drivingLicense ? (
                                <div style={{display: 'flex', gap: '8px'}}>
                                    <img src={documents.drivingLicense} width={150} height={150}/>
                                    <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>setDocuments({...documents, drivingLicense: null})}>x</p>
                                </div>
                            ): (
                                <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    showUploadList={false}
                                    accept="image/*"
                                    onChange={(info)=>handleUpload(info, 'drivingLicense')}
                                    beforeUpload={handleBeforeUpload}
                                    >
                                    <div>
                                        <Input type="file" accept="image/*" style={{ display: 'none' }} />
                                        <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                                    </div>
                                </Upload>
                            )
                        }

                        <div className='label mt-24'>Driving License (Back)</div>
                        {
                            documents.drivingLicenseBack ? (
                                <div style={{display: 'flex', gap: '8px'}}>
                                    <img src={documents.drivingLicenseBack} width={150} height={150}/>
                                    <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>setDocuments({...documents, drivingLicenseBack: null})}>x</p>
                                </div>
                            ): (
                                <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    showUploadList={false}
                                    accept="image/*"
                                    onChange={(info)=>handleUpload(info, 'drivingLicenseBack')}
                                    beforeUpload={handleBeforeUpload}
                                    >
                                    <div>
                                        <Input type="file" accept="image/*" style={{ display: 'none' }} />
                                        <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                                    </div>
                                </Upload>
                            )
                        }

                        <div className='label mt-24'>Vehicle RC (Front)</div>
                        {
                            documents.vehicleRc ? (
                                <div style={{display: 'flex', gap: '8px'}}>
                                    <img src={documents.vehicleRc} width={150} height={150}/>
                                    <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>setDocuments({...documents, vehicleRc: null})}>x</p>
                                </div>
                            ): (
                                <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    showUploadList={false}
                                    accept="image/*"
                                    onChange={(info)=>handleUpload(info, 'vehicleRc')}
                                    beforeUpload={handleBeforeUpload}
                                    >
                                    <div>
                                        <Input type="file" accept="image/*" style={{ display: 'none' }} />
                                        <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                                    </div>
                                </Upload>
                            )
                        }

                        <div className='label mt-24'>Vehicle RC (Back)</div>
                        {
                            documents.vehicleRcBack ? (
                                <div style={{display: 'flex', gap: '8px'}}>
                                    <img src={documents.vehicleRcBack} width={150} height={150}/>
                                    <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>setDocuments({...documents, vehicleRcBack: null})}>x</p>
                                </div>
                            ): (
                                <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    showUploadList={false}
                                    accept="image/*"
                                    onChange={(info)=>handleUpload(info, 'vehicleRcBack')}
                                    beforeUpload={handleBeforeUpload}
                                    >
                                    <div>
                                        <Input type="file" accept="image/*" style={{ display: 'none' }} />
                                        <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                                    </div>
                                </Upload>
                            )
                        }
                    </>
                ): null
              }
              
            </div>
        </div>

        <div className='button-container'>
            <Button className='submit-button' onClick={onSubmit}>Submit</Button>
        </div>
    </>
  )
}

export default ZeptoFormPart3