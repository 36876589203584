import React, { useContext, useEffect, useState } from 'react';
import {CheckOutlined} from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import { AppContext } from '../../../App';
import ZeptoFormPart1 from './components/ZeptoFormPart1';
import ZeptoFormPart2 from './components/ZeptoFormPart2';
import ZeptoFormPart3 from './components/ZeptoFormPart3';
import ZeptoOtpScreen from './components/ZeptoOtpScreen';
import commonFunction from '../../../utils/commonFunction';
import { getCandidateInfo } from '../../../service/additionalInfoService';
import { getZeptoObSubmissionData, validateZeptoUser } from '../../../service/ZeptoRecAdditionalInfoService';
import { notification } from 'antd';

function ZeptoRecruitmentAdditionalInfoContainer() {
    const [currentTab, setCurrentTab] = useState();
    const {mitraReducer,mitraDispatch, setAlert,setSpin } = useContext(AppContext);
    const history = useHistory();
    const [leadAdditionalDetails, setLeadAdditionalDetails] = useState({});
    const [lead] = useState(commonFunction.getAllsearchParams(history.location.search));
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const [isInitialOtpVerificationCheck, setIsInitialOtpVerificationCheck] = useState(true);

    const [tabInfo, setTabInfo] = useState([
        {
            id: 'otpVerification',
            label: 'OTP Verification',
            status: 'inProgress',
            isClickable: true // This will be used when otp expires
        },
        {
            id: 'bankDetails',
            label: 'Bank Details',
            status: 'done',
            isClickable: true
        },
        {
            id: 'workPreference',
            label: 'Work Preference',
            status: 'done',
            isClickable: true // This will be used when otp expires
        },
        {
            id: 'documentUpload',
            label: 'Document Upload',
            status: 'done',
            isClickable: true
        },
    ]);

    const showLoader = (value) => {
        setSpin({
            loading: value, //false or true when actived
            delay: null,
            tip: 'Loading...'  //Loading copy here
        })
    }

    const onTabClick = (item) => {
        if(item.status === 'pending') return;

        if(!item.isClickable) {
            notification['error'] ({
                message: 'Please verify the OTP to continue'
            });
            return;
        }

        const newTabInfo = [...tabInfo];

        for (let i = 0; i < newTabInfo.length; i++) {
            if(newTabInfo[i].id === item.id) {
                newTabInfo[i].status = 'inProgress';
                setCurrentTab(newTabInfo[i].id);
            } else {
                if(newTabInfo[i].status === 'inProgress') {
                    newTabInfo[i].status = 'done';
                }
            }
        }

        setTabInfo([...newTabInfo]);
    };

    const getLeadInformation = async() => {
        try {
            const populatedRes = await getCandidateInfo(lead.phoneNumber);

            const populatedData = {}

            populatedRes.data.forEach(el => {
                try {
                    const parsedValue = JSON.parse(el.value)
                    if (typeof parsedValue === "number")
                        populatedData[el.type] = `${parsedValue}`
                    else
                        populatedData[el.type] = [{ ...parsedValue, originalName: parsedValue.url, storage: "url" }]
                } catch (error) {
                    populatedData[el.type] = el.value
                }
            });

            console.log(populatedData, 'leadAdditionalDetails');

            setLeadAdditionalDetails(populatedData);
        } catch(e) {
            notification['error']({
                message: 'Error fetching lead information.'
            })
        }
        
    }

    async function getSubmissionDetails() {
        if(lead.phoneNumber) {
            const payload = {
                phoneNumber: lead.phoneNumber,
                source: 'mitra-leader',
                mlName: mitraReducer?.mitraInfo?.name
            };
            const response = await getZeptoObSubmissionData(payload);
    
            return response.data;
        }
    }

    const determineCurrentStep = async() => {
        showLoader(true);

        const submissionData = await getSubmissionDetails();

        const currentStep = submissionData.currentStep;

        if(currentStep === 'bankDetails') {
            goToScreen('bankDetails');
            showLoader(false);
            return;
        } else {
            if(!isOtpVerified) {
                getOtpValidationStatus();
            } else {
                let currentStepStatusIsInProgress = false;
                const updatedTabInfo = [...tabInfo];

                for (let i = 0; i < updatedTabInfo.length; i++) {
                    if(updatedTabInfo[i].id === currentStep) {
                        updatedTabInfo[i].status = 'inProgress';
                        currentStepStatusIsInProgress = true;
                        setCurrentTab(updatedTabInfo[i].id);
                    } else {
                        if(currentStepStatusIsInProgress) {
                            // this means we are checking tabs which comes after current tab
                            // meaning they are still pending
                            updatedTabInfo[i].status = 'pending';
                            updatedTabInfo[i].isClickable = false;
                        } else {
                            // this means that the current item is not the current step
                            // and is completed because we havent yet encountered 'currentStepStatusIsInProgress'
                            updatedTabInfo[i].status = 'done';
                            updatedTabInfo[i].isClickable = true;
                        }
                    }
                }

                updatedTabInfo[0].isClickable = false; // not allowing to change bank details once submitted
                updatedTabInfo[0].status = 'pending';

                setTabInfo([...updatedTabInfo]);
            }
        }
        showLoader(false);
    }

    const goToNextScreen = async() => {
        const newTabInfo = [...tabInfo];

        getLeadInformation();

        for (let i = 0; i < newTabInfo.length; i++) {
            if(newTabInfo[i].status === 'inProgress' && (i+1 < newTabInfo.length)) {
                newTabInfo[i].status = 'done';
                newTabInfo[i+1].status = 'inProgress';
                newTabInfo[i+1].isClickable = 'true';
                setCurrentTab(newTabInfo[i+1].id);
                break;
            }
        }

        newTabInfo[0].isClickable = false; // not allowing to change bank details once submitted
        newTabInfo[0].status = 'pending';

        setTabInfo([...newTabInfo]);
    };

    const goToScreen = (screenId) => {
        setIsOtpVerified(false);

        // make current step as otp step, disable all the tab clicks
        const newTabInfo = [...tabInfo];

        for (let i = 0; i < newTabInfo.length; i++) {
            if(newTabInfo[i].id === screenId) {
                newTabInfo[i].status = 'inProgress';
                setCurrentTab(newTabInfo[i].id);
            } else {
                newTabInfo[i].status = 'pending';
                newTabInfo[i].isClickable = false;
            }
        }

        setTabInfo([...newTabInfo]);
    }

    const getOtpValidationStatus = async() => {
        showLoader(true);

        try {
            const payload = {
                phoneNumber: lead?.phoneNumber
            }

            const response = await validateZeptoUser(payload);

            if(response?.data?.success) {
                setIsOtpVerified(true);
            } else {
                goToScreen('otpVerification');
            }

            showLoader(false);

        } catch(err) {
            showLoader(false);
            goToScreen('otpVerification');
        }
    }

    useEffect(() => {

        /* 
        Drop off and Redirection Logic:

        1) Firstly check the currentStep using determineCurrentStep()
        2) If currentStep is bankDetails, take user to that screen and disable all other screens
        3) Else, check if Session is Valid, 
                3.1) if yes then take user to relevant screen by disabling the bank screen
                3.2) else take user to OTP screen and disable all other screens and once OTP is verified goto step 3.1
        */

      if(lead){
        getLeadInformation();
        // getOtpValidationStatus(); // Will call this once the user passes bank details page
        determineCurrentStep();
      }
    }, [lead])

    useEffect(() => {
        if(isInitialOtpVerificationCheck && isOtpVerified) {
        // Here if OTP is verified and we have the details to check the current step then call determineCurrentStep()
        determineCurrentStep();
        setIsInitialOtpVerificationCheck(false);
        }
    }, [isOtpVerified])
    
  return (
    <div className="AdditionalInfoScreen mainContentZ">
        {
            // <RouterPrompt
            //     when={showPrompt}
            //     title="Leave this page"
            //     cancelText="No"
            //     okText="Yes"
            //     onOK={() => true}
            //     onCancel={() => false}
            //     promptMessage= {"The lead has not yet been referred. Are you sure you want to leave the form?"}
            // />
        }
        <div className='header-text'>Zepto Additional Form</div>

        <div className="tab-container">
            {
                tabInfo.map((item, index) => {
                    return (
                        <div 
                        key={item.id} 
                        className={
                            `tab-item${item.status === 'inProgress' ? ' active': item.status === 'pending'? ' pending': ' done'}`
                        }
                        onClick={()=>onTabClick(item)}
                        >
                            {item.label} {item.status === 'done' ? <CheckOutlined style={{color: '#4BB543'}}/>: null}
                        </div>
                    )
                })
            }
        </div>

        <div className='form-content-container'>
            {/* all the tabs goes here */}
            {
                currentTab === 'otpVerification' ? <ZeptoOtpScreen lead={lead} isOtpVerified={isOtpVerified} setIsOtpVerified={setIsOtpVerified} determineCurrentStep={determineCurrentStep}/>:
                currentTab === 'workPreference' ? <ZeptoFormPart1 lead={lead} mitraReducer={mitraReducer} leadAdditionalDetails={leadAdditionalDetails} goToNextScreen={goToNextScreen}/>: 
                currentTab === 'bankDetails' ? <ZeptoFormPart2 lead={lead} mitraReducer={mitraReducer} leadAdditionalDetails={leadAdditionalDetails} goToNextScreen={goToNextScreen}/>:
                currentTab === 'documentUpload' ? <ZeptoFormPart3 lead={lead} leadAdditionalDetails={leadAdditionalDetails}/>: null
            }
        </div>
    </div>
  )
}

export default ZeptoRecruitmentAdditionalInfoContainer