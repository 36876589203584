import React from 'react'
import '../../../css/bulkAction.scss'

import { CheckCircleOutlined, DownloadOutlined } from '@ant-design/icons';

function Step2() {
  return (
    <div className="step-container">
        <div className="step-heading">
            <div className="step-button"> Step 2</div>
            <div className="step-heading-text">Click on 'Edit/View Application'</div>
        </div>
        <div className="step-details">
            <div className="details-left">
                <div className="checkbox">
                    <CheckCircleOutlined />
                </div>
                <div className="pipe"> I </div>
            </div>
            <div className="details-center">
                <div className="application-date">
                    Application Date
                </div>
                <div className="created-date"> 
                    Created on XYZ Date
                </div>
            </div>
            <div className="details-right">
                <div className="edit-view-application">
                    <div className="download">
                        <DownloadOutlined />
                    </div>
                    <div className="edit-view-text">
                        <div className="edit-view"> Edit/View</div>
                        <div className="application">Application</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Step2