import React from 'react';
import './Carousel.scss';
import { Button } from 'antd';
import {RightCircleFilled } from '@ant-design/icons';
import '../css/globalStyles.scss'

const PerformanceDashboardNumbers = ({ FTsPerMonth, ProjectedPerMonath, target, selectedMonth, goToPerformaceDashboard }) => {
  return (
    <div className='performace-dash-numbers'>
        <div className='row' style={{height: '108px'}}>
            <div style={{width: '100%'}} className='performace-container'>
                <div className='semibold20-28 perf-header'>{FTsPerMonth}</div>
                <div className='regular12-20 perf-description'>FTs completed this month ({selectedMonth})</div>
            </div>
        </div>
        <div className='row d-flex justify-content-center' style={{height: '108px', gap: '3%'}}>
            <div style={{width: '48.5%'}} className='performace-container'>
                <div className='semibold20-28 perf-header'>{ProjectedPerMonath}</div>
                <div className='regular12-20 perf-description'>Projected FTs for the month</div>
            </div>

            <div style={{width: '48.5%'}} className='performace-container'>
                <div className='semibold20-28 perf-header' style={{color: '#1890FF'}}>{target}</div>
                <div className='regular12-20 perf-description'>Target FTs per month</div>
            
            </div>
        </div>
        <div className='row' style={{gap: '12px'}}>
            <Button onClick={() => goToPerformaceDashboard()} style={{width: '100%', height: '70px'}} className='performace-container-button'>
            <div className='bold12-20 row d-flex align-items-center' style={{margin: '0px'}}>
            <div className ='go-to-performace-button'>Go to Performance Report</div>
                  <RightCircleFilled className='go-to-performance-icon'/>  
                </div>
            </Button>
        </div>
    </div>
  );
};

export default PerformanceDashboardNumbers;
