import { Button, Divider, notification, Upload } from 'antd'
import React, { useContext, useEffect, useState } from 'react';
import DownloadIcon from '../../../static/svg/DownloadIcon';
import {CloseOutlined} from '@ant-design/icons'
import { AppContext } from '../../../App';
import '../styles/FileUpload.css'
import Dustbin from '../../../static/svg/DustbinIcon';
import { getSamvaadiniSummaryInformation, postSamvaadiniSession } from '../../../service/SamvadiniApiService';

const LAYOUTS = {
    'FILE_SELECTION': 'FILE_SELECTION',
    'UPLOAD_PROGRESS': 'UPLOAD_PROGRESS',
    'UPLOAD_SUCCESS': 'UPLOAD_SUCCESS',
    'UPLOAD_FAILED': 'UPLOAD_FAILED',
    'POST_FILE_UPLOAD_LAYOUT': 'POST_FILE_UPLOAD_LAYOUT',
    'ERROR_LAYOUT': 'ERROR_LAYOUT'
}

const handleBeforeUpload = (file) => {

    // Return false to prevent upload
    return false;
  };

function FileUpload({goToNextScreen, file, setFile, resetSteps}) {
    const [currentLayout, setCurrentLayout] = useState(LAYOUTS.FILE_SELECTION);
    const {mitraReducer,mitraDispatch, setAlert,setSpin } = useContext(AppContext);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [lessThanFiveCandidatesUploadedError, setLessThanFiveCandidatesUploadedError] = useState(false);
    const [isEligibleForCallingCountZero, setIsEligibleForCallingCountZero] = useState(false);

    const layoutToScreenHeaderMap = {
        'FILE_SELECTION': 'Upload or Drag and Drop',
        'UPLOAD_PROGRESS': 'File uploading',
        'UPLOAD_SUCCESS': 'File uploaded',
        'UPLOAD_FAILED': 'File upload failed',
        'POST_FILE_UPLOAD_LAYOUT': 'File uploaded',
        'ERROR_LAYOUT': 'File Upload Error'
    };

    useEffect(() => {
      if(file) {
        setCurrentLayout(LAYOUTS.POST_FILE_UPLOAD_LAYOUT);
      }
    }, [])

    const checkSessionCreationEligibility = async(file) => {
        try {
            const formData = new FormData();
            formData.append('type', 'File Upload');
            formData.append('mitraId', mitraReducer?.mitraInfo?.id)
            formData.append('pitchClient', 'default');
            formData.append('files', file);
            formData.append('isManagerMitra', mitraReducer?.mitraInfo?.managerMitraID ? false: true)

            const response = await getSamvaadiniSummaryInformation(formData);

            if(response?.data?.status) {  
                if(!response?.data?.leadsEligible) {
                    setIsEligibleForCallingCountZero(true);
                    return true;
                } else {
                    setIsEligibleForCallingCountZero(false);
                }
                setLessThanFiveCandidatesUploadedError(false);

            } else { 
                setLessThanFiveCandidatesUploadedError(true);
                setIsEligibleForCallingCountZero(false);

                return true;
            }
            return false;
        } catch(err) {
            return true;
        }
    }

    const layoutToScreenSubHeaderMap = {
        'FILE_SELECTION': 'Select file and upload',
        'UPLOAD_PROGRESS': 'File is being uploaded',
        'UPLOAD_SUCCESS': 'File has been uploaded succesfully',
        'UPLOAD_FAILED': '',
        'POST_FILE_UPLOAD_LAYOUT': 'File is Uploaded'
    };

    const showLoader = (value) => {
        setSpin({
            loading: value, //false or true when actived
            delay: null,
            tip: 'Updating Information...'  //Loading copy here
        })
    }

    const setPostSuccessfulLayout = () => {
        const timeout = setTimeout(() => {
            setCurrentLayout(LAYOUTS.POST_FILE_UPLOAD_LAYOUT);
        }, 2000);
    }

    const setUploadSuccessfulLayout = () => {
        const timeout = setTimeout(() => {
            setCurrentLayout(LAYOUTS.UPLOAD_SUCCESS);
            setUploadPercentage(0);
            setPostSuccessfulLayout();
        }, 1500);
    }

    const handleUpload = async(info) => {
        setFile(info.file);
        setCurrentLayout(LAYOUTS.UPLOAD_PROGRESS);

        const errorProcessingFile = await checkSessionCreationEligibility(info.file);

        let tempCompletionPercentage = 0;

        const uploadInterval = setInterval(() => {
            if(uploadPercentage + parseInt(tempCompletionPercentage) >=100) {
                setUploadPercentage(100);
                clearInterval(uploadInterval);

                if(errorProcessingFile) {
                    setCurrentLayout(LAYOUTS.ERROR_LAYOUT);
                } else {
                    setUploadSuccessfulLayout();
                }

                return;
            }
            setUploadPercentage(uploadPercentage + parseInt(tempCompletionPercentage));
            tempCompletionPercentage = tempCompletionPercentage + Math.random() * 9;
        }, 100);
    }

    const onDownloadTemplateClicked = () => {
        const link = document.createElement('a');
        link.href = "https://mesl-uploads-2.s3.ap-southeast-1.amazonaws.com/intent_calling_upload_template.xlsx";
        link.download = 'template.xlsx'; // Specify the desired filename

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    }

    const deleteFile = () => {
        setFile(null);
        setCurrentLayout(LAYOUTS.FILE_SELECTION);
        resetSteps();
        setUploadPercentage(0);
    }

    const fileUploadLayout = () => (
        <>
            <div className="download-sample-file" onClick={onDownloadTemplateClicked}>
                <DownloadIcon />
                <span className='inter-regular-14-20 color-252526'>Download Sample File</span>
            </div>
            <div className="upload-file-here">
                {
                    file ? (
                        <div className='d-flex flex-column align-items-center excel-upload-section'>
                            <span className='inter-regular-14-20 color-848484' style={{marginTop:'16px'}}>{file?.name}</span>
                        </div>
                    ): (
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            showUploadList={false}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            onChange={(info)=>handleUpload(info)}
                            beforeUpload={handleBeforeUpload}
                            >
                            <div className='d-flex flex-column align-items-center excel-upload-section'>
                                <img src='/image/Upload.png' style={{height:'40px', width: '40px'}} />
                                <span className='inter-regular-14-20 color-848484' style={{marginTop:'16px'}}>Drag and drop your files here</span>
                                <span className='inter-bold-16-18 color-848484'style={{marginTop:"8px"}}>or</span>
                                <Button className='primary-btn-styles-v2 btn-44h' style={{width: '100%', marginTop: '16px'}}><span className='inter-regular-16-24 plain-white'>Excel Upload</span></Button>
                            </div>
                        </Upload>
                    )
                }
                
            </div>
        </>
    )

    const uploadProgressLayout = () => (
        <>
            <span className='inter-semibold-24-28 color-252526 text-align-start upload-progress-layout-container' style={{width:'70%', marginTop: '24px'}}>Uploads</span>
            <div className="upload-file-here" style={{width: '70%',gap:'16px',paddingTop:'8px'}}>
                <img src='/image/file-upload-progress.png' style={{width:'58px', height:"58px",objectFit:'contain', flex: 1}} />
                <div className="file-upload-progress mt-24">
                    <span className='inter-semibold-12-18 color-2C2C2C'>Your Excel Sheet is being uploaded</span>
                    {
                        file?.size ? <span className='inter-medium-8-20 color-606060'>{parseInt(file?.size/1000)} kb</span>: null
                    }
                    
                    <div className="file-upload-progress-bar-container mt-8">
                        <div className="file-upload-progress-bar">
                            <div className="bar-filled" style={{width:`${uploadPercentage}%`,transition: 'width 0.3s ease-in-out'}}></div>
                        </div>
                        <span className='inter-semibold-12-18 color-5A5A5A'>{uploadPercentage}%</span>
                    </div>
                </div>
                <CloseOutlined onClick={deleteFile} style={{cursor:'pointer'}}/>
            </div>
        </>
    )

    const uploadSuccessLayout = () => (
        <div className='successful-upload-container'>
            <img src='/image/trophy.png' className='trophy-image' style={{objectFit:'contain', marginTop: '24px'}} />
            <div className="successful-upload-and-button-container w-100">
                <div className="successful-upload-text-container">
                    <img src="/image/Checkmark.png" style={{height:'37px', objectFit:'contain'}} />
                    <div className="successful-upload-text-details">
                        <span className='inter-semibold-36-38 color-252526 successful-text'>Sucessful</span>
                        <span className='inter-regular-12-16 successful-sub-text' style={{color: '#306417'}}>Your file has been uploaded successfully</span>
                    </div>
                </div>
            </div>
            
        </div>
    )

    const uploadFailedLayout = () => (
        <div className='error-upload-container'>
            <img src='/image/min-candidate-error.png' className='trophy-image upload-failed-image' style={{objectFit:'contain'}} />
            <div className="successful-upload-and-button-container w-100">
                <div className="successful-upload-text-container">
                    <img src="/image/error-image.png" style={{height:'37px', objectFit:'contain'}} />
                    <div className="successful-upload-text-details">
                        <span className='inter-semibold-22-28 color-252526 successful-text'>
                            {
                                lessThanFiveCandidatesUploadedError ? 'Please upload a file with at least 5 eligible leads.': null
                            }

                            {
                                isEligibleForCallingCountZero ? 'No eligible leads to call.': null
                            }
                        </span>
                        <span className='inter-regular-12-16 successful-sub-text failure-sub-text' style={{color: '#306417'}}>
                            Leads must be unique, referred by you in the last 30 days, and not yet onboarded.
                        </span>
                    </div>
                </div>
                <Button className='primary-btn-styles-v2 btn-width-285 btn-36h v2-fileupload-button mt-12' onClick={deleteFile}><span className='inter-regular-14-20 plain-white'>Upload again</span></Button>
            </div> 
        </div>
    )

    const postSuccessfulUploadLayout = () => (
        <>
            <div className="upload-file-here" style={{width: '70%',gap:'16px',paddingTop:'8px'}}>
                <img src='/image/file-upload-progress.png' style={{width:'58px', height:"58px",objectFit:'contain', flex: 1}} />
                <div className="file-upload-progress">
                    <span className='inter-semibold-12-18 color-2C2C2C'>{file?.name} has been uploaded</span>
                    {
                        file?.size ? <span className='inter-medium-8-20 color-606060'>{parseInt(file?.size/1000)} kb</span>: null
                    }
                </div>
                <div onClick={deleteFile} style={{cursor:'pointer'}}>
                    <Dustbin />
                </div>
            </div>
        </>
    )

  return (
    <>

        <div className="step-info-header">{layoutToScreenHeaderMap[currentLayout]}</div>
        <div className="step-info-sub-header">{layoutToScreenSubHeaderMap[currentLayout]}</div>

        {
            currentLayout === LAYOUTS.UPLOAD_SUCCESS ? uploadSuccessLayout():
            currentLayout === LAYOUTS.ERROR_LAYOUT ? uploadFailedLayout(): (
                <div className="upload-container shadow-highlighter">

                    <div className="upload-file-main-section" style={{padding: currentLayout != LAYOUTS.FILE_SELECTION ? '12px 124px 32px':''}}>
                        {
                            currentLayout === LAYOUTS.FILE_SELECTION ? fileUploadLayout():
                            currentLayout === LAYOUTS.UPLOAD_PROGRESS ? uploadProgressLayout():
                            currentLayout === LAYOUTS.POST_FILE_UPLOAD_LAYOUT ? postSuccessfulUploadLayout(): null
                        }
                    </div>
                </div>
            )
        }

        {
            currentLayout === LAYOUTS.POST_FILE_UPLOAD_LAYOUT ? (
                <div className='d-flex justify-content-end' style={{margin:'24px 32px'}}>
                    <Button className='primary-btn-styles-v2 btn-width-285 btn-36h v2-fileupload-button' onClick={()=>goToNextScreen('script')}><span className='inter-regular-14-20 plain-white'>Next</span></Button>
                </div>
            ):null
        }
    </>
  )
}

export default FileUpload