import axios from 'axios';
import axiosInstance from './interceptorService';

export const postUserInfoForm = async (name, phoneNumber, status, role, photoUrl, email, managerId, team) => {
    const data = new FormData();
    data.append('name', name);
    data.append('phoneNumber', phoneNumber);
    data.append('status', status);
    data.append('role', role);
    data.append('photoUrl', photoUrl);
    data.append('email', email);
    data.append('managerId', managerId);
    if (team) {
        data.append('teamId', team);
    }
    let url = process.env.REACT_APP_BACKEND_URL + '/postUserInfoForm';
    return axiosInstance.post(url, data, { withCredentials: true });
};

export const getMitrasByManager = async () => {
    const url = process.env.REACT_APP_BACKEND_URL + '/getMitraByManagerId?includeInactive=true';
    return axiosInstance.get(url, { withCredentials: true });

}
export const updateMitrasById = async (data) => {
    const url = process.env.REACT_APP_BACKEND_URL + '/update';
    return axiosInstance.post(url, data, { withCredentials: true });

}