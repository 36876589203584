import React from 'react';
import '../../css/report.scss'
import '../../css/reportTeamMemberCard.scss'
import { useHistory } from 'react-router-dom';
import { StarOutlined } from '@ant-design/icons';
import { Typography, Avatar, Button } from 'antd';


function ReportTeamMemberCard({ member, index, loading }) {
  const history = useHistory();
  const { Text } = Typography;

  return (
      <div className="card-container">
          <div className="right-card-info">
              <Avatar size={50} className="margin-right-15px">{member.mitraName[0].toUpperCase()}</Avatar>
              <div className="name-container">
                  <Text style={{fontWeight: 'bold'}}>{member.rank} <Text style={{textTransform: 'capitalize'}}>{member.mitraName}</Text></Text>
                  <Text>{member.mitraRole}</Text>
              </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', margin: 'auto 10px auto auto'}}>
            {
                index === 0 ? <Text style={{color: '#D34612', marginBottom: '10px', textAlign: 'left'}}><StarOutlined /> Highest F20 Trips</Text> : null
            }
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <Button className="total-f10">
                  <Text className='trips-heading'>Total F20 </Text>
                  <Text className='trips-number-leaderboard'> {member.totalF20}</Text>
              </Button>
              <Button className="total-activation">
                  <Text className='trips-heading'>Total Activation </Text>
                  <Text className='trips-number-leaderboard'> {member.totalActivation}</Text>
              </Button>
            </div>
          </div>
      </div>
  )
}
export default ReportTeamMemberCard;