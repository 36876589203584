import { Button, Input, Upload, notification } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { postAdditionalInfo, uploadImageToS3 } from '../../../../service/additionalInfoService';
import { AppContext } from '../../../../App';
import { UploadOutlined } from '@ant-design/icons';
import { updateSwiggyData } from '../../../../service/SwiggyAdditionalInfoService';

function SwiggyDocumentUpload({goToNextScreen, lead, submissionDetails}) {
  const {mitraReducer,mitraDispatch, setAlert,setSpin } = useContext(AppContext);

  const aadharRef = useRef(null);
  const panCardRef = useRef(null);
  const dlRef = useRef(null);
  const selfieRef = useRef(null);

  const [errorField, setErrorField] = useState('');

  const [documents, setDocuments] = useState({
    aadharFront: null,
    aadharBack: null,
    voterId: null,
    panCard: null,
    dlFront: null,
    dlBack: null,
    selfie: null
  });

  const changeDocumentDetails = (docName, value) => {
    setDocuments({...documents, [docName]:value});
  }

  const handleBeforeUpload = (file) => {
    // Return false to prevent upload
    return false;
  };

  const showLoader = (value, message=null) => {
    setSpin({
        loading: value, //false or true when actived
        delay: null,
        tip: message || 'Uploading document, please wait..'  //Loading copy here
    })
}

  const checkErrors = (res) => {
    const newErrors = [];
    for (let key in res.data) {
        if (res.data[key] !== "200") {
            newErrors.push(`${key}: ${res.data[key]}`)
        }
    }

    if(newErrors.length) {
        // setErrors([...newErrors]);
        alert(newErrors.join("\n"))
        return true;
    } 
    return false;
  }

  const handleUpload =(info, type) => {
    showLoader(true);

    uploadImageToS3(info.file)
        .then(async(res)=> {
            showLoader(false);

            if(res?.data?.imageUrl) {
              
              let submissionData;
                // for pan card
                if(type==='panCard') {
                    submissionData = {
                        panCard: {
                            url: res.data.imageUrl,
                            originalName: info?.file?.name,
                            size: info?.file?.size
                        }
                      }
                    
                } else if(type === 'aadharFront') {
                    submissionData = {
                        aadhaarCard: {
                            url: res.data.imageUrl,
                            originalName: info?.file?.name,
                            size: info?.file?.size
                        }
                    }
                } else if(type === 'aadharBack') {
                  submissionData = {
                    aadhaarCardBack: {
                          url: res.data.imageUrl,
                          originalName: info?.file?.name,
                          size: info?.file?.size
                      }
                  }
                } else if(type === 'dlFront') {
                  submissionData = {
                    drivingLicense: {
                        url: res.data.imageUrl,
                        originalName: info?.file?.name,
                        size: info?.file?.size
                    }
                  }
                } else if(type === 'dlBack') {
                  submissionData = {
                    drivingLicenseBack: {
                        url: res.data.imageUrl,
                        originalName: info?.file?.name,
                        size: info?.file?.size
                    }
                  }
                } else if(type === 'selfie') {
                  submissionData = {
                    userSelfie: {
                        url: res.data.imageUrl,
                        originalName: info?.file?.name,
                        size: info?.file?.size
                    }
                  }
                } else {
                  return;
                }

                showLoader(true, 'Verifying document..');
                const response = await postAdditionalInfo(submissionData, mitraReducer?.mitraInfo, lead);

                showLoader(false);
                if(!checkErrors(response)) {
                    setDocuments({...documents, [type]:res?.data?.imageUrl});
                }
            } else {
                showLoader(false);
            }
        }).catch(e=> {
            showLoader(false);

            notification['error']({
                message: 'Image upload failed, please try again later.',
              });

            console.log(e);
        })
  };

  const handleScrollToPosition = (ref) => {
    if (ref?.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const allRequiredFieldsHaveValues = () => {
    if(!documents.aadharFront || !documents.aadharBack || !documents.panCard || !documents.selfie) {
      if(!documents.aadharFront) {
        notification['error']({
          message: 'Upload aadhar front image.',
        });
        
        setErrorField('aadharFront');
        handleScrollToPosition(aadharRef);
      } else if(!documents.aadharBack) {
        notification['error']({
          message: 'Upload aadhar back image.',
        });

        setErrorField('aadharBack');
        handleScrollToPosition(aadharRef);
      } else if(!documents.panCard) {
        notification['error']({
          message: 'Upload PAN card image.',
        });

        setErrorField('panCard');
        handleScrollToPosition(panCardRef);
      } 
      // else if(!documents.dlFront) {
      //   notification['error']({
      //     message: 'Upload Driving Licence front image.',
      //   });

      //   setErrorField('dlFront');
      //   handleScrollToPosition(dlRef);
      // } else if(!documents.dlBack) {
      //   notification['error']({
      //     message: 'Upload Driving Licence back image.',
      //   });

      //   setErrorField('dlBack');
      //   handleScrollToPosition(dlRef);
      // } 
      else if(!documents.selfie) {
        notification['error']({
          message: 'Upload rider selfie.',
        });

        setErrorField('selfie');
        handleScrollToPosition(selfieRef);
      } else {
        notification['error']({
          message: 'Upload all the documents to continue.',
        });
      }
      return false;
    } else return true;
  }

  const onSubmitDocumentDetails = async() => {
    if(allRequiredFieldsHaveValues()) {
      try {
        // submission code
        const payload = {
          phoneNumber: lead?.phoneNumber,
          dataToUpdate: {
            postDocuments: [
              {
                documentType: 'PAN',
                imageUrls: [documents.panCard]
              },
              {
                documentType: 'DL',
                imageUrls: [documents?.dlFront, documents?.dlBack]
              },
              {
                documentType: 'Aadhaar',
                imageUrls: [documents.aadharFront, documents.aadharBack]
              },
              {
                documentType: 'SELFIE',
                imageUrls: [documents.selfie]
              }
            ]
          }
        }

        showLoader(true, 'Submitting documents, please wait..');
        const response = await updateSwiggyData(payload);

        showLoader(false);
        // code to check the response to go to next page

        if(response?.data?.data?.length && response?.data?.data?.length === 4) {
          const docRespArr = response?.data?.data;

          let allDocsSuccessfullySubmitted = true;

          if(!docRespArr?.[0]?.["PAN"]?.statusMessage === 'SUCCESS') {
            allDocsSuccessfullySubmitted = false;
            notification['error']({
              message: 'Error submitting PAN card, please try again..'
            })
          } 
          // else if(!docRespArr?.[1]?.["DL"]?.statusMessage === 'SUCCESS') {
          //   allDocsSuccessfullySubmitted = false;
          //   notification['error']({
          //     message: 'Error submitting Driving Licence, please try again..'
          //   })
          // } 
          else if(!docRespArr?.[2]?.["Aadhaar"]?.statusMessage === 'SUCCESS') {
            allDocsSuccessfullySubmitted = false;
            notification['error']({
              message: 'Error submitting Aadhaar card, please try again..'
            })
          } else if(!docRespArr?.[3]?.["SELFIE"]?.statusMessage === 'SUCCESS') {
            allDocsSuccessfullySubmitted = false;
            notification['error']({
              message: 'Error submitting rider selfie, please try again..'
            })
          } 

          if(allDocsSuccessfullySubmitted) {
            goToNextScreen();
          }

        }
      } catch(e) {
        notification["error"]({
          message: 'Something went wrong, please try again later'
        })
      }
    }
  }

  const autopopulateDocs = () => {
    showLoader(true, 'Loading information..')
    try {
      if(submissionDetails?.swiggyElementMap) {

        const docs = {
          aadharFront: null,
          aadharBack: null,
          voterId: null,
          panCard: null,
          dlFront: null,
          dlBack: null,
          selfie: null
        }
  
        if(submissionDetails?.swiggyElementMap?.aadhaarCard?.value) {
          const parsedDocInfo = JSON.parse(submissionDetails?.swiggyElementMap?.aadhaarCard?.value);
          docs.aadharFront = parsedDocInfo?.url;
        }
  
        if(submissionDetails?.swiggyElementMap?.aadhaarCardBack?.value) {
          const parsedDocInfo = JSON.parse(submissionDetails?.swiggyElementMap?.aadhaarCardBack?.value);
          docs.aadharBack = parsedDocInfo?.url;
        }
  
        if(submissionDetails?.swiggyElementMap?.panCard?.value) {
          const parsedDocInfo = JSON.parse(submissionDetails?.swiggyElementMap?.panCard?.value);
          docs.panCard = parsedDocInfo?.url;
        }
  
        if(submissionDetails?.swiggyElementMap?.drivingLicense?.value) {
          const parsedDocInfo = JSON.parse(submissionDetails?.swiggyElementMap?.drivingLicense?.value);
          docs.dlFront = parsedDocInfo?.url;
        }
  
        if(submissionDetails?.swiggyElementMap?.drivingLicenseBack?.value) {
          const parsedDocInfo = JSON.parse(submissionDetails?.swiggyElementMap?.drivingLicenseBack?.value);
          docs.dlBack = parsedDocInfo?.url;
        }
  
        if(submissionDetails?.swiggyElementMap?.userSelfie?.value) {
          const parsedDocInfo = JSON.parse(submissionDetails?.swiggyElementMap?.userSelfie?.value);
          docs.selfie = parsedDocInfo?.url;
        }
  
        setDocuments(docs);
        showLoader(false)
      }
    } catch(e) {
      showLoader(false)
    }
    
  }

  useEffect(() => {
    autopopulateDocs();
  }, [submissionDetails])
  

  return (
    <>
      <div className='pan-verification-container' ref={aadharRef}>
        <div className='container-header'>Aadhar card</div>
        <div className='pan-form-container'>

          <div className='label mt-24'>Front<sup>*</sup></div>
          {
            documents.aadharFront ? (
                <div style={{display: 'flex', gap: '8px'}}>
                    <img src={documents.aadharFront} width={150} height={150}/>
                    <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>changeDocumentDetails('aadharFront',null)}>x</p>
                </div>
            ) : (
                <Upload
                    name="avatar"
                    listType="picture-card"
                    showUploadList={false}
                    accept="image/*"
                    onChange={(info)=>handleUpload(info, 'aadharFront')}
                    beforeUpload={handleBeforeUpload}
                    className={errorField==='aadharFront' ? 'input-error':''}
                    >
                    <div>
                        <Input type="file" accept="image/*" style={{ display: 'none' }} />
                        <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                    </div>
                </Upload>
            )
          }

          <div className='label mt-24'>Back<sup>*</sup></div>
          {
            documents.aadharBack ? (
                <div style={{display: 'flex', gap: '8px'}}>
                    <img src={documents.aadharBack} width={150} height={150}/>
                    <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>changeDocumentDetails('aadharBack',null)}>x</p>
                </div>
            ) : (
                <Upload
                    name="avatar"
                    listType="picture-card"
                    showUploadList={false}
                    accept="image/*"
                    onChange={(info)=>handleUpload(info, 'aadharBack')}
                    beforeUpload={handleBeforeUpload}
                    className={errorField==='aadharBack' ? 'input-error':''}
                    >
                    <div>
                        <Input type="file" accept="image/*" style={{ display: 'none' }} />
                        <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                    </div>
                </Upload>
            )
          }
        </div>
      </div>

      <div className='pan-verification-container' ref={panCardRef}>
        <div className='container-header'>PAN card</div>
        <div className='pan-form-container'>

          <div className='label mt-24'>Front<sup>*</sup></div>
          {
            documents.panCard ? (
                <div style={{display: 'flex', gap: '8px'}}>
                    <img src={documents.panCard} width={150} height={150}/>
                    <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>changeDocumentDetails('panCard',null)}>x</p>
                </div>
            ) : (
                <Upload
                    name="avatar"
                    listType="picture-card"
                    showUploadList={false}
                    accept="image/*"
                    onChange={(info)=>handleUpload(info, 'panCard')}
                    beforeUpload={handleBeforeUpload}
                    className={errorField==='panCard' ? 'input-error':''}
                    >
                    <div>
                        <Input type="file" accept="image/*" style={{ display: 'none' }} />
                        <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                    </div>
                </Upload>
            )
          }
        </div>
      </div>

      <div className='pan-verification-container' ref={dlRef}>
        <div className='container-header'>Driving Licence</div>
        <div className='pan-form-container'>

          <div className='label mt-24'>Front<sup></sup></div>
          {
            documents.dlFront ? (
                <div style={{display: 'flex', gap: '8px'}}>
                    <img src={documents.dlFront} width={150} height={150}/>
                    <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>changeDocumentDetails('dlFront',null)}>x</p>
                </div>
            ) : (
                <Upload
                    name="avatar"
                    listType="picture-card"
                    showUploadList={false}
                    accept="image/*"
                    onChange={(info)=>handleUpload(info, 'dlFront')}
                    beforeUpload={handleBeforeUpload}
                    className={errorField==='dlFront' ? 'input-error':''}
                    >
                    <div>
                        <Input type="file" accept="image/*" style={{ display: 'none' }} />
                        <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                    </div>
                </Upload>
            )
          }

          <div className='label mt-24'>Back<sup></sup></div>
          {
            documents.dlBack ? (
                <div style={{display: 'flex', gap: '8px'}}>
                    <img src={documents.dlBack} width={150} height={150}/>
                    <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>changeDocumentDetails('dlBack',null)}>x</p>
                </div>
            ) : (
                <Upload
                    name="avatar"
                    listType="picture-card"
                    showUploadList={false}
                    accept="image/*"
                    onChange={(info)=>handleUpload(info, 'dlBack')}
                    beforeUpload={handleBeforeUpload}
                    className={errorField==='dlBack' ? 'input-error':''}
                    >
                    <div>
                        <Input type="file" accept="image/*" style={{ display: 'none' }} />
                        <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                    </div>
                </Upload>
            )
          }
        </div>
      </div>

      <div className='pan-verification-container' ref={selfieRef}>
        <div className='container-header'>Rider selfie</div>
        <div className='pan-form-container'>

          <div className='label mt-24'>Selfie<sup>*</sup></div>
          {
            documents.selfie ? (
                <div style={{display: 'flex', gap: '8px'}}>
                    <img src={documents.selfie} width={150} height={150}/>
                    <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>changeDocumentDetails('selfie',null)}>x</p>
                </div>
            ) : (
                <Upload
                    name="avatar"
                    listType="picture-card"
                    showUploadList={false}
                    accept="image/*"
                    onChange={(info)=>handleUpload(info, 'selfie')}
                    beforeUpload={handleBeforeUpload}
                    className={errorField==='selfie' ? 'input-error':''}
                    >
                    <div>
                        <Input type="file" accept="image/*" style={{ display: 'none' }} />
                        <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                    </div>
                </Upload>
            )
          }
        </div>
      </div>
      <div className='button-container'>
          <Button className='submit-button' onClick={()=>onSubmitDocumentDetails()}>Next</Button>
      </div>
    </>
  )
}

export default SwiggyDocumentUpload
