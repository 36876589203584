import React, {useState, useEffect, useContext} from 'react';
import {useHistory} from 'react-router-dom';
// import useMoengage from '../../hooks/useMoengage';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';
import '../../css/help.scss';
import moment from "moment";
import * as helpService from '../../service/helpService';
import {getMitraTeamMembersList, getActiveClientsForFilter } from './../../service/commonService'

import PageHeaderComponent from '../common/PageHeader';
import {Card, Collapse, Select, Button, Table, Tag, Typography,Popover, Empty, Divider, Upload, Modal, Input, notification, Pagination} from 'antd';
import {FileAddOutlined, SearchOutlined, CloseOutlined, InfoCircleOutlined, ArrowRightOutlined,FilterOutlined,SendOutlined} from '@ant-design/icons';
import {AppContext} from "../../App";
import Ticket from './ticket.component';
import {getMitraPhoneNumberFromId} from '../../service/commonService';
import SideSheetComponent from '../common/SideSheet';
import { getAllConversations, reOpenTicket } from '../../service/ticketService';
import Chat from './chat.component';
import RaiseIssueSider from './RaiseIssueSider.component';
import { rollOutNewSupportForm } from '../../constants/utils';

const {Panel} = Collapse;
const {Meta} = Card;
const {Option} = Select;
const { Text} = Typography;
const { TextArea } = Input;

const HelpComponent = () => {
    const [visible, setVisible] = useState(false);
    const [statusId, setStatusId] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState('All Tickets');
    const [clientLogos, setClientLogos] = useState([]);
    const hide = (element) => {
        setSelectedFilter(element.name);
        setVisible(false);
        setStatusId(element.statusId);
      };
    const [filterData, setFilterData] = useState(CONSTANTS.HELP_FILTER_TAGS);
    const filterContent = (
        <div
          className="d-flex flex-column justify-content-start align-items-start"
          style={{ gap: '10px', fontFamily: 'Nunito Sans Bold', fontSize: '14px', lineHeight: '24px' }}
        >
          {filterData.map((item) => {
            return (
              <button
                key={item.id}
                onClick={() => hide(item)}
                style={{
                  gap: '10px',
                  fontFamily: 'Nunito Sans',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '22px ',
                }}
              >
                {item.name}
              </button>
            );
          })}
        </div>
      );
    const handleVisibleChange = (newVisible) => {
        trackEvent('click_filters_help',{},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    setVisible(newVisible);
    };
    const [showBanner, setShowBanner] = useState(true);
	const history = useHistory();
    const {setAlert, mitraReducer, setSpin} = useContext(AppContext);
    const [mitraList, setMitraList] = useState([])
    const [dataSource, setDataSource] = useState([])
    const [breadcrumb] = useState([{link: '/help', label: 'Help'}]);
    const [sidebarContent, setSidebarContent] = useState({});
    const [conversationHistory, setConversationHistory] = useState([]);
    const [chatModal, setChatModal] = useState(false);
    // const [createMoengageUser, trackEvent] = useMoengage();
    const trackMoengageEvent = (event,obj) => {
        trackEvent(event, obj || {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    }
    const [managerMitraPhoneNumber, setManagetMitraPhoneNumber] = useState();
    const [filterSider, setfilterSider] = useState(false);
    const [ticketReOpenModal, setTicketReOpenModal] = useState(false);
    const [ticketReOpeningReason, setTicketReOpeningReason] = useState('');
    const [raiseIssueSider, setRaiseIssueSider] = useState(false);
    const [isNewIssueRaised, setIsNewIssueRaised] = useState(false);
    const [ticketsCount, setTicketsCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)

    const getConversationHistory = (ticket) => {
        setSpin({
            loading: true,
            delay: null,
            tip: 'Loading...'
        });
        getAllConversations(ticket)
        .then(response => {
            setConversationHistory(response.data);
            setSpin({
                loading: false,
                delay: null,
                tip: 'Loading...'
            });
        }).catch(err => {
            console.log(err);
            setSpin({
                loading: false,
                delay: null,
                tip: 'Loading...'
            });
        }
            );
    };

    const openSider = (record) => {
        trackEvent('click_view_details_ticket_help',{},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        setSidebarContent(record);
        getConversationHistory(record);
        setfilterSider(true);
    };

    const returnStatusTags = (record) => {
        if (record.status === 'Open') {
           return <Tag style={{background: '#85A5FF', borderRadius:'10px',color:'#fff',fontSize:'12px',lineHeight:'20px',fontFamily:'Nunito Sans',fontWeight:'700'}}>Open</Tag>
        }
        if (record.status === 'Closed') {
            return <Tag style={{background: '#BFBFBF', borderRadius:'10px',color:'#fff',fontSize:'12px',lineHeight:'20px',fontFamily:'Nunito Sans',fontWeight:'700'}}>Closed</Tag>
        }
        if (record.status === 'Pending') {
            return <Tag style={{background: '#BFBFBF', borderRadius:'10px',color:'#fff',fontSize:'12px',lineHeight:'20px',fontFamily:'Nunito Sans',fontWeight:'700'}}>Pending</Tag>
        }
        if (record.status === 'Resolved') {
            return <Tag style={{background: '#52C41A', borderRadius:'10px',color:'#fff',fontSize:'12px',lineHeight:'20px',fontFamily:'Nunito Sans',fontWeight:'700'}}>Resolved</Tag>
        }
        if (record.status === 'Waiting for your reply') {
            return <Tag style={{background: '#F74931', borderRadius:'10px',color:'#fff',fontSize:'12px',lineHeight:'20px',fontFamily:'Nunito Sans',fontWeight:'700'}}>Waiting for your reply</Tag>
        }
        if (record.status === 'Raised to Client') {
            return <Tag style={{background: '#FAAD14', borderRadius:'10px',color:'#fff',fontSize:'12px',lineHeight:'20px',fontFamily:'Nunito Sans',fontWeight:'700'}}>Raised to Client</Tag>
        }
    };

    const openReOpenModal = (record) => {
        trackEvent('click_reopen_ticket_help',{},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        setTicketReOpenModal(true);
        setSidebarContent(record);
    };

    const openingChatModal = (record) => {
        trackEvent('click_reply_help',{},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        setSidebarContent(record);
        getConversationHistory(record);
        setChatModal(true);
    };

    const getClientLogo = (client) => {
        const clientData = clientLogos.find((data) => {
            return data?.title?.toLowerCase() == client?.toLowerCase()
        })

        return <img style={{width:'90px'}} alt={client} src={clientData?.logo}/>
    }

    const columns = [
        {
          title: 'Ticket No.',
          dataIndex: 'ticketId',
          key: 'ticketNumber',
          render: (text) => <b>{text}</b>,
        },
        {
          title: 'Candidate Phone Number',
          dataIndex: 'candidatePhoneNumber',
          key: 'phoneNumber',
          render: (text, record) => <div style={{display:'flex',flexDirection:'column'}}><Text>{record?.name}</Text><Text>{record.candidatePhoneNumber}</Text></div>,
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',
            render: (text) => getClientLogo(text)
          },
        {
          title: 'Issue Type',
          dataIndex: 'issueType',
          key: 'issueType',
          render: (text) => <div style={{color: 'red'}}>{text}</div>,
        },
        {
          title: 'Issue Status',
          dataIndex: 'status',
          key: 'issueStatus',
          render: (text, record) => returnStatusTags(record)
        },
        {
          title: 'Created Date',
          dataIndex: 'createdDate',
          key: 'date',
        },
        {
          title: '',
          dataIndex: 'id',
          key: 'id',
          render: (text, record) => <div>
            {
                record.status === 'Closed' ? (
                    <Button className='re-open-dash-button' onClick={()=>openReOpenModal(record)}>Re-Open</Button>
                ) : (
                    <Button className='re-open-dash-button reply' onClick={()=>openingChatModal(record)}>Reply <SendOutlined style={{rotate:'320deg'}} /></Button>
                )
            }
          </div>,
        },
        {
          title: '',
          dataIndex: 'id',
          key: 'id',
          fixed: 'right',
          width: 100,
          render: (text, record) => <Button onClick={()=>openSider(record)} className="view-details-icon"  type="primary" ghost shape="circle" icon={<ArrowRightOutlined />} />,
        }
      ];

    useEffect(() => {
        trackMoengageEvent('opened_help_section');
        (async () => {
            let clientList = await getActiveClientsForFilter();

            clientList = clientList?.data?.activeClients?.map(client => client?.label);

            const res = await helpService.getCompanyLogo(clientList);
            setClientLogos(res?.data?.logos)
        })();
    },[]);

    const getIssues = (managerPhoneNumber) => {
        fetchReportedIssues(mitraList, mitraReducer?.mitraInfo?.phoneNumber,managerPhoneNumber);
    };

    const getMitraTeamList = (managerPhoneNumber) => {
        getMitraTeamMembersList().then(response => {
            setMitraList(response.data.data);
            if(mitraReducer?.mitraInfo?.phoneNumber) {
                getIssues(managerPhoneNumber);
            }
        });
    };

    useEffect( () => {
        if(mitraReducer?.mitraInfo?.managerMitraID) {
            getMitraPhoneNumberFromId(mitraReducer?.mitraInfo?.managerMitraID).then(res => {
                setManagetMitraPhoneNumber(res.data.mitraPhoneNumber);
                getMitraTeamList(res.data.mitraPhoneNumber); 
            })
        }
        else {
            setManagetMitraPhoneNumber(mitraReducer?.mitraInfo?.phoneNumber);
            getMitraTeamList(mitraReducer?.mitraInfo?.phoneNumber); 
        }
     }, [mitraReducer?.mitraInfo?.phoneNumber,statusId]);


    useEffect( () => () => {
        window.FreshworksWidget('hide');
        window.FreshworksWidget('close');
     }, [] );

    const formatTicketData = (data, tableDataSource) => {
        data.forEach((element, index) => {
            const resolvedDate = moment(element.updated_at).format("DD-MM-YYYY hh:mm:ss");
            const createdDate = moment(element.created_at).format("DD MMM YYYY hh:mm A");

            if(element.status === 2) {
                element.status_text = 'Open';
            }
            else if(element.status === 3) {
                element.status_text = 'Pending';
            }
            else if(element.status === 4) {
                element.status_text = 'Resolved';
            }
            else if(element.status === 5) {
                element.status_text = 'Closed';
            }
            else if(element.status === 6) {
                element.status_text = 'Waiting for your reply';
            }
            else if(element.status === 7) {
                element.status_text = 'Raised to Client';
            }
            tableDataSource.push(
                {
                    key: element.id,
                    ticketId: element.id,
                    createdDate: createdDate,
                    resolvedDate: element.status === 5 || element.status === 4 ? resolvedDate : null,
                    resolutionSummary: element.custom_fields.cf_resolution_summary,
                    description: element.description_text,
                    phone: element.custom_fields.cf_your_registered_phone_number,
                    clientName: element.custom_fields.cf_client_name,
                    name: element.custom_fields.cf_candidate_name? element.custom_fields.cf_candidate_name : "",
                    actionNeeded: element.custom_fields.cf_action_to_be_taken_by_mitra_leader,
                    candidatePhoneNumber: element.custom_fields.cf_candidate_phone_number,
                    status: element.status_text,
                    issueType: element.custom_fields.cf_issue_type,
                    requester_id: element.requester_id,
                    responder_id: element.responder_id,
                    fd_status: element.status,
                }
            );

        });
        return tableDataSource;
    };

    const fetchReportedIssues = (mitraList, issuesStatus, managerPhoneNumber) => {
        setSpin({
            loading: true,
            delay: null,
            tip: 'Loading...'
        });
        helpService.fetchIssues({ mitraPhoneNumber: mitraReducer?.mitraInfo?.phoneNumber, managerPhoneNumber, statusId, currentPage }).then(async response => {
            if (response?.data) {
                let ticketCount = Math.min(300, response?.data?.total);
                setTicketsCount(ticketCount)
                let tableDataSource = [];

                tableDataSource = formatTicketData(response.data.results, tableDataSource);

                let pageCount = Math.ceil(ticketCount / 30);

                const promises = Array.from({ length: Math.min(10, pageCount) - 1 }, (_, i) =>
                    helpService.fetchIssues({
                        mitraPhoneNumber: mitraReducer?.mitraInfo?.phoneNumber,
                        managerPhoneNumber,
                        statusId,
                        currentPage: i + 2,
                    })
                );

                const results = await Promise.all(promises);

                results.forEach((res) => {
                    tableDataSource = formatTicketData(res.data.results, tableDataSource);
                });

                const ordering = {},
                sortOrder = [6, 7, 2, 5, 3, 4, 1];

                sortOrder.forEach((status, index) => {
                    ordering[status] = index;
                });

                tableDataSource.sort((a, b) => {
                    return ordering[a.fd_status] - ordering[b.fd_status];
                });

                setDataSource(tableDataSource)
            }
            setSpin({
                loading: false, //false or true when actived
                delay: null,
                tip: 'Loading...'  //Loading copy here
            });
        })
    }

    const openForm = async () => {
        window.FreshworksWidget('show');
        window.FreshworksWidget('open');
        window.FreshworksWidget('identify', 'ticketForm', {
            name: mitraReducer?.mitraInfo?.name,
            email: mitraReducer?.mitraInfo?.email,
            group_id: 84000268346,
            custom_fields: {
                "cf_your_registered_phone_number": mitraReducer?.mitraInfo?.phoneNumber,
                "cf_manager_phone_number": managerMitraPhoneNumber ? managerMitraPhoneNumber : ''
            }
        });
        window.FreshworksWidget('disable', 'ticketForm', [
            'name',
            'group_id',
            'custom_fields.cf_your_registered_phone_number',
            'custom_fields.cf_manager_phone_number'
        ]
        );
        if(mitraReducer?.mitraInfo?.role === "admin") {
            window.FreshworksWidget('hide', 'ticketForm', [
                'custom_fields.cf_manager_phone_number'
            ]
            );
        }
    };

    const openRaiseIssueSider = () => {
        setRaiseIssueSider(true);
    };

    const handleRaiseIssueOnClick = () => {
        trackMoengageEvent('clicked_on_raise_issue');

        if(rollOutNewSupportForm(mitraReducer?.mitraInfo))
            openRaiseIssueSider();
        else
            openForm();
    };

    const closeReOpenModal = () => {
        setTicketReOpenModal(false);
        setTicketReOpeningReason('');
    };

    const onTicketReOpeningReasonChange = (event) => {
        setTicketReOpeningReason(event.target.value);
    };

    const reOpenCandidateTicket = () => {
        setfilterSider(false);
        if (!ticketReOpeningReason) {
            notification['error']({
                message: 'Please mention a reason to re-open this ticket !!'
              });
            return;
        } else {
            reOpenTicket(sidebarContent, ticketReOpeningReason)
            .then(response => {
                setTicketReOpenModal(false);
                setTicketReOpeningReason('');
                console.log(response);
                getIssues();
                notification['success']({
                    message: 'Ticket Re-opened Successfully.'
                  });
            }).catch(err=> {
                setTicketReOpenModal(false);
            setTicketReOpeningReason('');
                notification['error']({
                    message: 'Issue in re-opening the ticket !!'
                  });
            })
        }
    };

    const chatModalCancel = () => {
        setChatModal(false);
      };

    return (
        <>
            <Modal
            className="help-reopen-modal-style"
            style={{ textAlign: 'left' }}
            title="Re-open Ticket"
            visible={ticketReOpenModal}
            onCancel={closeReOpenModal}
            footer={[
            <Button key="1" style={{borderColor:'#177CCD', color:'#177CCD', border:'#177CCD'}} className="ant-btn ant-btn-default" onClick={closeReOpenModal}>
                Cancel
            </Button>,
            <Button key="2" style={{background:'#177CCD',border:'#177CCD'}} className="ant-btn ant-btn-primary" onClick={reOpenCandidateTicket}>
            Re-open
            </Button>
            ]}
            >
                <div className='re-open-ticket-modal-container'>
                    <Text>Reason for re-opening ticket (*)</Text>
                    <TextArea
                        placeholder="Write your issue description here .."
                        // autoSize={{ minRows: 2, maxRows: 6 }}
                        rows={4}
                        onChange={onTicketReOpeningReasonChange}
                        value={ticketReOpeningReason}
                    />
                </div>
            </Modal>
            <Chat
                freshdeskData={conversationHistory}
                ticket={sidebarContent}
                getConversation={getConversationHistory}
                chatModal={chatModal}
                chatModalCancel={chatModalCancel}
            ></Chat>
            <div className='Home HelpComponent mainContentZ'>
                <div className="containerMiddle">
                    <div className='header'>
                    <PageHeaderComponent breadcrumb={breadcrumb} />
							<Button onClick={()=>{
                                history.push("/search-ticket")
							}} style={{color:'#D34612',display:'flex',alignItems:'center'}} icon={<SearchOutlined />}  danger ghost size={'large'} >Search Tickets</Button>
                    </div>
                    {
                        showBanner ?
                        <div style={{margin: '20px'}}>
                            <div className="warning-message-at-risk-container">
                                <div className="warning-message-atrisk">
                                    <InfoCircleOutlined  style={{color: '#D34612', margin: '5px 10px 0px 10px'}} />
                                    Recently raised issues may take upto 1 minute to update on the screen
                                    <CloseOutlined onClick={() => {setShowBanner(false)}} style={{margin: '5px 10px 0px auto'}} />
                                </div>
                            </div>
                        </div>
                        : null
                    }
                    {
                            isNewIssueRaised ? 
                            <div className="new-issue-created-message">
                                <Text>
                                    ✅ Your issue has been shared with our team.
                                </Text>
                            </div> : null
                    }
                        <Card bordered={true} style={{marginTop:'16px',marginLeft:'16px',marginRight:'16px'}}>
                            <div style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap', gap:'16px'}}>
                                <Meta
                                    style={{fontWeight: '900'}}
                                    title={'Issues raised' + (!mitraReducer?.mitraInfo?.managerMitraID ? (' by you & team members') : '')}
                                />
                                <div className='desktop-row-mobile-column'>
                                    <div className='ticket-table-header'>
                                        <Panel key="1" extra={<Button type='danger' onClick={handleRaiseIssueOnClick} ><FileAddOutlined /> Raise Issue</Button>}>
                                        </Panel>
                                        <Popover
                                            overlayStyle={{ width: '200px' }}
                                            placement="bottom"
                                            content={filterContent}
                                            trigger="click"
                                            visible={visible}
                                            onVisibleChange={handleVisibleChange}
                                        >
                                            <Button className="d-flex align-items-center filter-content" style={{margin: '0px 10px'}} icon={<FilterOutlined />}>
                                                {selectedFilter}
                                            </Button>
                                        </Popover>
                                        
                                    </div>
                                    {
                                        ticketsCount > 0 ? 
                                            <Pagination
                                            total={ticketsCount > 0 && ticketsCount}
                                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                            defaultPageSize={30}
                                            defaultCurrent={currentPage}
                                            // responsive={true}
                                            showLessItems={true}
                                            onChange={(page) => {setCurrentPage(page)}}
                                            showSizeChanger={false}
                                            />
                                        : null
                                    }
                                </div>
                            </div>
                            {dataSource.length > 0 ? (
                            //     dataSource.map((ticket, index) => (
							// <Ticket
							// 	key={index}
							// 	ticket={ticket}
                            //     getIssues={getIssues}
                            // />
                            // ))
                            <div className='table-section'>
                                <Table columns={columns} dataSource={dataSource.slice((currentPage-1)*30, currentPage*30)} scroll={{}} pagination={false}></Table>
                            </div>
                            
                            ) : <Empty />}
                        </Card>
                </div>
            </div>
            {filterSider && 
				<SideSheetComponent
				sidername={<Text className='help-sider-name'>Issue Details</Text>}
                hideBottomButtons={true}
				onDismiss={() => {setfilterSider(false)}}>
					<div className='main-ticket-container'>
                        <div className='ticket-details'>
                            <div className='ticket-detailed-elements'>
                                <Text className='ticket-detail-fieldname'>Ticket Number</Text>
                                <Text>:</Text>
                                <Text className='ticket-detail-fieldvalue'>{sidebarContent.ticketId}</Text>
                            </div>
                            <div className='ticket-detailed-elements' style={{display: 'flex', flexDirection:'row', justifyContent: 'space-around'}}>
                                <Text className='ticket-detail-fieldname'>Candidate Name</Text>
                                <Text>:</Text>
                                <Text className='ticket-detail-fieldvalue'>{sidebarContent.name}</Text>
                            </div>
                            <div className='ticket-detailed-elements' style={{display: 'flex', flexDirection:'row', justifyContent: 'space-around'}}>
                                <Text className='ticket-detail-fieldname'>Candidate Number</Text>
                                <Text>:</Text>
                                <Text className='ticket-detail-fieldvalue'>{sidebarContent.candidatePhoneNumber}</Text>
                            </div>
                            <div className='ticket-detailed-elements' style={{display: 'flex', flexDirection:'row', justifyContent: 'space-around'}}>
                                <Text className='ticket-detail-fieldname'>Issue Type</Text>
                                <Text>:</Text>
                                <Text className='ticket-detail-fieldvalue'>{sidebarContent.issueType}</Text>
                            </div>
                            <div className='ticket-detailed-elements' style={{display: 'flex', flexDirection:'row', justifyContent: 'space-around'}}>
                                <Text className='ticket-detail-fieldname'>Status</Text>
                                <Text>:</Text>
                                <Text className='ticket-detail-fieldvalue'>{returnStatusTags(sidebarContent)}</Text>
                            </div>
                            <div className='ticket-detailed-elements' style={{display: 'flex', flexDirection:'row', justifyContent: 'space-around'}}>
                                <Text className='ticket-detail-fieldname'>Date & Time</Text>
                                <Text>:</Text>
                                <Text className='ticket-detail-fieldvalue'>{sidebarContent.createdDate}</Text>
                            </div>
                        </div>

                        <div className='description-section'>
                            <div className='description-title'>
                                Issue Description
                            </div>
                            <div className='description-content'>
                                {sidebarContent.description}
                            </div>
                        </div>

                        <div>
                            <Divider orientation="left">
                                <Text className='conversation-header'>Conversation History</Text>
                            </Divider>

                            <div className='help-sider-conversation-history-container'>
                                {
                                    conversationHistory.length > 0 ? (
                                        conversationHistory.map((item, index) => {
                                            return (
                                                <div key={item.id}>
                                                <div className='single-message-container'>
                                                    <div className='message-header'>
                                                        <div 
                                                        className={sidebarContent.requester_id === item.user_id ? 'message-header-icon mitra-leader': 'message-header-icon support'}
                                                        >
                                                            {sidebarContent.requester_id === item.user_id ? 'M':'S'}
                                                        </div>
                                                        <div className='message-header-text'>
                                                            <Text className='client-name-text'>
                                                            {sidebarContent.requester_id === item.user_id ? 'Vahan Leader':'Support Team'}
                                                            </Text>
                                                            <Text className='date-text'>
                                                                {moment(item.created_at).format("DD MMM YYYY hh:mm A")}
                                                            </Text>
                                                        </div>
                                                    </div>
                                                    <div className='message-description'>
                                                        <Text>
                                                        <div dangerouslySetInnerHTML={{ __html: item.body }} />
                                                        </Text><br/>
                                                        {
                                                          item.attachments.length > 0 ? <Text>Attachments:</Text>: null  
                                                        }
                                                        {
                                                            item.attachments.length > 0 ? (
                                                                
                                                                item.attachments.map((attachment, index)=> {
                                                                    return (
                                                                        <div key={attachment.id}>
                                                                            <a 
                                                                            href={attachment.attachment_url} 
                                                                            style={{color:'blue',textDecoration:'underline',cursor:'pointer'}}
                                                                            rel="noreferrer" 
                                                                            target="_blank">
                                                                                {attachment.name}
                                                                            </a>
                                                                        </div>
                                                                    )
                                                                }
                                                                )
                                                            ) : null
                                                        }
                                                    </div>
                                                </div>

                                                <Divider />
                                                </div>
                                            )
                                        })
                                            
                                        
                                    ) : <Empty description="No conversation history."/>
                                }
                                

                            </div>
                        </div>
                        

                        <div className='reply-button'>
                            {
                                sidebarContent.status === 'Closed' ? (
                                    <Button className='button-style re-open' onClick={()=> openReOpenModal(sidebarContent)}>Re-Open</Button>
                                ) : (
                                    <Button className='button-style' onClick={()=> openingChatModal(sidebarContent)}>Reply <SendOutlined  style={{rotate:'320deg'}}/></Button>
                                )
                            }
                        </div>
                    </div>
				</SideSheetComponent>
			}
            {raiseIssueSider &&
                <RaiseIssueSider
                    closeSider={() => setRaiseIssueSider(false)}
                    mitraReducer={mitraReducer}
                    newIssueRaised={() => setIsNewIssueRaised(true)}
                />
            }
        </>
    );
};
export default HelpComponent;
