import React, { useEffect, useState } from 'react'
import '../styles/AppDownload.css';
import { Button, Input, Modal } from 'antd';
import { useHistory } from "react-router-dom";
import { getZomatoDocumentStatus } from '../../../../service/commonService';

const { TextArea } = Input;

const LAYOUTS = {
    ob_fee_not_paid: 'OB_FEE_NOT_PAID',
    ob_fee_paid_and_approved: 'OB_FEE_PAID_AND_APPROVED',
    ob_fee_paid_and_partially_approved: 'OB_FEE_PAID_AND_PARTIALLY_APPROVED',
    ob_fee_paid_and_rejected: 'OB_FEE_PAID_AND_REJECTED',
}

function AppDownload({goToNextScreen,lead, leadAdditionalDetails, getLeadInformation, setShowPrompt}) {

    const [currentLayout, setCurrentLayout] = useState();
    const [showWhatsappTemplateModal, setShowWhatsappTemplateModal] = useState(false);
    const [encodedWhatsappMessage, setEncodedWhatsappMessage] = useState('Hi!%20Your%20Zomato%20application%20is%20in%20progress.%20%0ADownload%20the%20Zomato%20App%20via%20this%20link%20-%20https%3A%2F%2Fwww.zomato.com%2Fdeliver-food%2F%3Fd%3Dt');
    const [decodedWhatsAppMessage, setDecodedWhatsAppMessage] = useState(null);
    const history = useHistory();

    const goToCandidatesPage = () => {
        history.replace({ pathname: "/recruiter-candidates" });
    }

    const onWhatsAppMessageChange = (event) => {
        console.log(event.target.value, encodeURI(event.target.value));
		setDecodedWhatsAppMessage(event.target.value ? event.target.value : " ");
		setEncodedWhatsappMessage(encodeURI(event.target.value));
	};

    const whatsappModal = () => {
        return (
            <>
                <Modal
                    className="whatsapp-modal-style"
                    style={{ textAlign: 'left' }}
                    title="Send WhatsApp Message"
                    visible={showWhatsappTemplateModal}
                    onCancel={()=>setShowWhatsappTemplateModal(false)}
                    footer={[
                        <Button key="1" className="ant-btn ant-btn-default" style={{borderRadius: '8px'}} onClick={()=>setShowWhatsappTemplateModal(false)}>
                            Cancel
                        </Button>,
                        <a
                            key="2"
                            href={`https://api.whatsapp.com/send?phone=+91${lead?.phoneNumber}&text=${encodedWhatsappMessage}`}
                            rel="noopener noreferrer"
                            target="_blank"
                            className="ant-btn ant-btn-primary submit-button-app-download"
                            onClick={()=>setShowWhatsappTemplateModal(false)}
                            style={{ border: 'none' }}
                        >
                            Send Whatsapp Message to Rider
                        </a>,
                    ]}
                >
                    <div style={{ paddingTop: '10px' }}>
                        <TextArea value={decodedWhatsAppMessage} rows={6} onChange={onWhatsAppMessageChange} />
                    </div>
                </Modal>
            </>
        )
    }

    useEffect(() => {
        setShowPrompt(false);
        setDecodedWhatsAppMessage(
			decodeURI(encodedWhatsappMessage)
				.replace(/%2C/g, ',')
				.replace(/%3A/g, ':')
				.replace(/%2F/g, '/')
				.replace(/%3F/g, '?')
				.replace(/%3D/g, '=')
		);
    }, []);

    const determineLayout = (data) => {
        if(data?.success) {
            if(data?.obFeePaid) {
                if(data?.documentStatus === 'APPROVED') setCurrentLayout(LAYOUTS.ob_fee_paid_and_approved);
                else if(data?.documentStatus === 'REJECTED') setCurrentLayout(LAYOUTS.ob_fee_paid_and_rejected);
                else if(data?.documentStatus === 'PARTIALLY_APPROVED') setCurrentLayout(LAYOUTS.ob_fee_paid_and_partially_approved);
            } else {
                setCurrentLayout(LAYOUTS.ob_fee_not_paid);
            }
        } else {
            setCurrentLayout(LAYOUTS.ob_fee_not_paid);
        }
    }

    useEffect(() => {
      if(lead?.scheduledInterviewId) {
        getZomatoDocumentStatus(lead.scheduledInterviewId)
        .then(res=> {
            determineLayout(res?.data);
        }).catch(err=> {
            console.log(err);
        })
      }
    }, [lead])
    
    

  return (
    <>
        <div className='upload-selfie-container'>
            <div className='container-header'>Zomato Red App Download</div>

            {
                currentLayout === LAYOUTS.ob_fee_not_paid ? (
                    <>
                        <div className='layout_message'>Congratulations! All Onboarding steps are now complete. <br/>
                        Please ask the candidate to download the <b>Zomato Red App</b> and pay the Zomato Joining Fee</div>

                        <div className='cta_button_container'>
                            <Button className='secondary-btn' onClick={()=>setShowWhatsappTemplateModal(true)}>Send Link to download Zomato app</Button>
                        </div>

                        {whatsappModal()}
                    </>
                ):null
            }

            {
                currentLayout === LAYOUTS.ob_fee_paid_and_approved ? (
                    <>
                        <div className='layout_message'>Congratulations! Your candidate <b>{lead?.name}</b> has paid the OB Fee and and their documents are also approved by Zomato! Encourage your candidate to start taking trips</div>
                    </>
                ): null
            }

            {
                currentLayout === LAYOUTS.ob_fee_paid_and_partially_approved ? (
                    <>
                        <div className='layout_message'>Your candidate <b>{lead?.name}</b> has paid the OB Fee but hasn’t uploaded their PAN/Bank details. Ask them to fill their details on the Zomato Red App, otherwise they won’t get their payments</div>
                    </>
                ): null
            }

            {
                currentLayout === LAYOUTS.ob_fee_paid_and_rejected ? (
                    <>
                        <div className='layout_message'>Your candidate <b>{lead?.name}</b> has paid the OB Fee but their documents were <b>rejected</b> by Zomato. Please help them in uploading correct documents in the Zomato Red App</div>
                    </>
                ): null
            }

        </div>

        <div className='button-container' style={{display: 'flex'}}>
            <Button className='secondary-btn' onClick={()=>goToCandidatesPage()}>Go to candidates page</Button>
        </div>
    </>
  )
}

export default AppDownload
