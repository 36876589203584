import React, { useEffect, useState, useContext } from 'react';
import { logEvent } from '../../../service/tracking';
import trackingConstants from '../../../constants/trackingConstants';
import '../../../css/lead.scss';
import useGAEventsTracker from '../../../service/useGSEventsTracker';
import moment from 'moment';
import { AdditionalFormsURLEnum } from '../../../utils/utility';
import { useHistory } from 'react-router-dom';
// import useMoengage from '../../hooks/useMoengage';
import { trackEvent } from '../../../service/engagementMonitoringService';
import CONSTANTS from '../../../constants/constants';
import {
  PhoneOutlined,
  WhatsAppOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import { Modal, Button, Typography, Tabs, Empty, notification } from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import { AppContext } from '../../../App';
import WhatsappTemplateModal from '../../whatsappTemplateModal/WhatsappTemplateModal';
import { rollOutCallHistory, rollOutNewSupportForm, rollOutRecruitmentMilestoneVisibility } from '../../../constants/utils';
import CallHistoryCard from './../candidateProfilePopupV2Components/callHistory';
import { getCallHistoryForWOReferral} from '../../../service/kaleyraCallService';
import CallerNumberSelectionModal from '../../kaleyraCallComponents/callerNumberSelectionModal';
import CustomCallNotes from '../../customCallNotes';

const { Text } = Typography;
const {TabPane} = Tabs;

const CandidateProfileWOReferral = ({ visible, setShowDetail, lead }) => {
    console.log(lead);
  const history = useHistory();
  const { mitraReducer,mitraDispatch } = useContext(AppContext);
  const [whatsAppMessageModal, setWhatsAppMessageModal] = useState(false);
  const [callHistory, setCallHistory] = useState([]);
  const [showNotificationToKaleyraUser, setShowNotificationToKaleyraUser] = useState(false);
  const [showCallerSelectionModal, setShowCallerSelectionModal] = useState(false);
  const [callerNumberList, setCallerNumberList] = useState([]);
  const [currentRhId, setCurrentRhId] = useState();
  const [showCallNoteSection, setShowCallNoteSection] = useState(false);
  const GAEventsTracker = useGAEventsTracker('Action buttons clicked');
    
  const closeCallerSelectionModal = () => {
    setShowCallerSelectionModal(false);
  }

  const fetchCallHistory = () => {
    let apiParams = {
      phoneNumber: lead?.phoneNumber,
      mitraId: mitraReducer?.mitraInfo?.id
    };
    getCallHistoryForWOReferral(apiParams).then((response)=> {
      setCallHistory([...response.data]);
    }).catch((error)=> {
      console.log(error);
    });
  }

  useEffect(() => {
    fetchCallHistory();
    }, [mitraReducer?.mitraInfo?.phoneNumber, lead]);

  const onCloseDetailsPopup = () => {
    setShowDetail(false);
  };
  const goToRecruiterDashboardNotification = () => {
    const placement= 'bottomRight';
    notification.open({
      message: 'Please visit the follow ups section to make this call.',
      duration: 0,
      placement,
      description: <Button type="primary" size="small" onClick={() => history.push(`/recruiter-candidates`)}>Take me</Button>
    })
  };

  const directCallFeature = (e) => {
    if (mitraReducer?.mitraInfo && showNotificationToKaleyraUser) {
      goToRecruiterDashboardNotification();
    }
    GAEventsTracker('CallCandidate');
    logEvent(
      'CallCandidate',
      trackingConstants.EVENT_GROUP_VIEW_LEAD,
      trackingConstants.EVENT_SCREEN_CANDIDATE
    );
    trackEvent(
      'call_now_option_clicked',
      {},
      CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS
    );
  };

  const whatsAppMessageModalCancel = () => {
    setWhatsAppMessageModal(false);
  };

  const onAddNotesClick = () => {
    trackEvent(
      'clicked_add_notes_decoupled_notes',
      {},
      CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS
    );
    const userName = lead && lead.name ? lead.name : '';
    setShowCallNoteSection(true);
  }

  const getModalTitle = () => {
    if(showCallNoteSection) {
      return (
        <div className='d-flex align-items-center' style={{gap: '12px'}}>
          <ArrowLeftOutlined onClick={()=>setShowCallNoteSection(false)}/> 
          Add Call Note
        </div>
      )
    } else {
      return "Candidate Profile"
    }
  }

  const closeCallNotePopup = () => {
    fetchCallHistory();
    setShowDetail(false);
  }
  

  return (
    <>
      {whatsAppMessageModal ? 
        <WhatsappTemplateModal
          lead={lead}
          leadDetails={null}
          whatsAppMessageModal={whatsAppMessageModal}
          whatsAppMessageModalCancel={whatsAppMessageModalCancel}
        ></WhatsappTemplateModal> 
      : null}
      <CallerNumberSelectionModal
      showModal={showCallerSelectionModal}
      closeModal={closeCallerSelectionModal}
      callerNumberList={callerNumberList}
      >
      </CallerNumberSelectionModal>

      
      <Modal
        title={getModalTitle()}
        centered
        visible={visible && lead}
        onCancel={() => onCloseDetailsPopup()}
        footer={null}
        bodyStyle={{ height: '600px', overflow: 'scroll', justifyContent: 'space-between', background: showCallNoteSection ? '#0000000A': '#FFF'}}
        width={620}
        className="modal-candidate-profile"
      >
            {
          showCallNoteSection ? (
            <CustomCallNotes 
            parentUserName={lead?.name}
            parentUserId={lead?.id}
            parentRhId={currentRhId}
            parentPhoneNumber={lead?.phoneNumber}
            showHeader={false}
            onSubmit={closeCallNotePopup}
            />
          ) : (
            <Tabs 
            defaultActiveKey="callHistory"
            size={'medium'}
            centered
            tabBarStyle={{display:'flex', justifyContent:'space-between'}}>
                <TabPane tab={<span className="d-flex align-items-center">
                  {
                    mitraReducer?.mitraInfo && rollOutCallHistory(mitraReducer.mitraInfo) ?
                     'Call History' : 'Call Notes'
                  }
                  </span>} key="callHistory">
                    <div className='details-component-container'>
                      <div className="Contact-details-header">
                        <div className="Candidate-details">
                          <div className="head-details">
                            <div className="name-number-container">
                              <div className="lead-name">
                                {lead.name}
                              </div>
                              <div className="lead-number">
                                {lead.phoneNumber}
                              </div>
                            </div>
                            <div className="contact-buttons">
                            { mitraReducer?.mitraInfo && 
                            <a href={`tel:+91-${ lead.phoneNumber ? lead.phoneNumber : ''}`} onClick={directCallFeature}>
                                <Button icon={<PhoneOutlined />} type="primary" className="d-flex align-items-center">
                                <Text style={{ color: '#fff' }}>Call Now</Text>
                                </Button>
                            </a>
                            }
                              <Button
                                onClick={(e) => {
                                //   GAEventsTracker('MessageCandidate');
                                  setWhatsAppMessageModal(true);
                                  logEvent(
                                    'WhatsAppCandidate',
                                    trackingConstants.EVENT_GROUP_VIEW_LEAD,
                                    trackingConstants.EVENT_SCREEN_CANDIDATE
                                  );
                                  trackEvent(
                                    'message_option_clicked',
                                    {},
                                    CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS
                                  );
                                }}
                                icon={<WhatsAppOutlined style={{ color: '#fff' }} />}
                                style={{ backgroundColor: '#52C41A' }}
                                className='d-flex align-items-center'
                                type="dashed"
                              >
                                <Text style={{ color: '#fff' }}>Message</Text>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div style={{ overflow:'scroll', height:'100%',marginTop:'20px'}}>
                        <Button onClick={()=>{onAddNotesClick()}} style={
                            {background:'#FFEAE0', border: '1px solid #D34612', display: 'flex',alignItems:'center',padding: '1px 8px', width:'125px', height:'24px'}}>
                            <PlusOutlined style={{color: '#D34612'}}/> 
                            <Text style={{color: '#D34612'}}>
                              Add Remarks
                            </Text>
                        </Button>
                        {
                          callHistory.length>0 ? callHistory.map((item,index)=> {
                              return (
                                <CallHistoryCard
                                history={item}
                                key={index} />
                              )
                          }): <Empty
                          description={
                            <span>
                              No Call History!!
                            </span>
                          }
                          />
                        }
                      </div>
                    </div>
                </TabPane>
            </Tabs>
          )
        }
      </Modal>
    </>
  );
};
export default CandidateProfileWOReferral;
