import React from 'react'
import '../../../css/bulkAction.scss'


function Step4({step}) {
  return (
    <div className="step-container">
        <div className="step-heading">
            <div className="step-button"> Step {step}</div>
            <div className="step-heading-text">Upload Documents and Submit</div>
        </div>
        <div style={{marginBottom: '20px'}}></div>
    </div>
  )
}

export default Step4