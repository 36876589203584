import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { TeamOutlined, PictureOutlined, ArrowRightOutlined, EnvironmentOutlined } from '@ant-design/icons';
import moment from 'moment';
import '../JobRequirementsSection/JobDemandCardStyle.scss'
import PosterModal from '../JobPoster/PosterModal';
import { getDisplayableClientName } from '../../../../utils/commonFunction';

const PosterViewCard = ({ posterLeadData = {
    "totalLeads": "",
    "city": "",
    "companyName": "",
    "companyId": "",
    "logo": "",
    "jobPostingId": "",
    "postingCreatedAt": "",
    "posterUrl": ""
},
    posterLeadInd }) => {
    const history = useHistory();
    const [showJobRequirementsListing, setShowJobRequirementsListing] = useState(true);
    const [showPosterModal, setShowPosterModal] = useState(false)

    const onViewPosterClicked = () => {
        setShowPosterModal(true)
    }

    const onViewLeadsClicked = () => {
        history.push(`/job-demands/leads-from-posters/${posterLeadData.jobPostingId}`)
    }

    return (
        <div className={`poster-view-card-parent`}>
            <div className='pvc-created-on-section'>
                Created on <span className='pvc-created-on-txt'> {moment(posterLeadData.postingCreatedAt).format("Do MMM YYYY")}</span>
            </div>

            <div className='pvc-company-logo-name d-flex align-items-center'>
                <img src={posterLeadData.logo} alt="comp_logo" className='pvc-company-logo-img' />
                <div className='pvc-company-name-txt'>
                    {getDisplayableClientName(posterLeadData.companyName)}
                </div>
            </div>

            <div className='pvc-location-leads d-flex align-items-center justify-content-between'>
                <div className='pvc-location d-flex'>
                    <EnvironmentOutlined className='pvc-location-icon d-flex align-items-center' />
                    <div className='pvc-location-txt'>
                        {posterLeadData.city}
                    </div>
                </div>

                <div className='pvc-leads d-flex'>
                    <TeamOutlined className='pvc-total-leads-icon d-flex align-items-center' />
                    <div className='pvc-total-leads-txt'>
                        {posterLeadData.totalLeads} leads
                    </div>
                </div>
            </div>

            <div className='pvc-btns d-flex justify-content-between'>
                <Button className='pvc-view-poster-btn pvc-btn d-flex align-items-center jp-cp justify-content-center' onClick={() => onViewPosterClicked()}>
                    <PictureOutlined className='pvc-view-poster-btn-img pvc-btn-img-orange' />
                    <div className='pvc-view-poster-btn-txt'>
                        View Poster
                    </div>
                </Button>

                <Button className='pvc-view-leads-btn pvc-btn d-flex align-items-center jp-cp justify-content-center' onClick={() => onViewLeadsClicked()}>
                    <div className='pvc-view-leads-btn-txt'>
                        View Leads
                    </div>
                    <ArrowRightOutlined className='pvc-view-leads-btn-img pvc-btn-img-white' />
                </Button>
            </div>

            {
                showPosterModal ?
                    <PosterModal client={{ label: posterLeadData.companyName, key: posterLeadData.companyName.toLocaleLowerCase(), value: true }} city={""} isModalOpen={showPosterModal} closeModal={() => setShowPosterModal(false)} posterURLFromParent={posterLeadData.posterUrl} posterId={posterLeadData.jobPostingId} />
                    :
                    null
            }

        </div>
    )
}

export default PosterViewCard; 