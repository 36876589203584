import * as React from "react";
const ShieldTick = ({fill='none',height=25,width=25,strokeColor='#1D5FFF'}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 25 25"
  >
    <path
      d="M10.9881 3.07375L5.99808 4.95375C4.84808 5.38375 3.90808 6.74375 3.90808 7.96375V15.3937C3.90808 16.5737 4.68808 18.1237 5.63808 18.8337L9.93808 22.0437C11.3481 23.1037 13.6681 23.1037 15.0781 22.0437L19.3781 18.8337C20.3281 18.1237 21.1081 16.5737 21.1081 15.3937V7.96375C21.1081 6.73375 20.1681 5.37375 19.0181 4.94375L14.0281 3.07375C13.1781 2.76375 11.8181 2.76375 10.9881 3.07375Z"
      stroke={strokeColor}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.5481 12.7137L11.1581 14.3237L15.4581 10.0237"
      stroke={strokeColor}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ShieldTick;
