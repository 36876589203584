import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../../App';
import { Button, Input, Modal, Result, notification } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { triggerZeptoOtp, verifyZeptoOtp } from '../../../../service/ZeptoRecAdditionalInfoService';
import { useHistory } from "react-router-dom";
import '../styles/style.css'
import { createApplication } from '../../../../service/additionalInfoService';

const OTPTimerConstant = 15;
const LAYOUTS = {
  'OTP_LAYOUT': 'OTP_LAYOUT',
  'VERIFIED_SESSION_LAYOUT': 'VERIFIED_SESSION_LAYOUT',
  'SINGLE_BUTTON_REQUEST_OTP_LAYOUT': 'SINGLE_BUTTON_REQUEST_OTP_LAYOUT'
}

function ZeptoOtpScreen({ lead, isOtpVerified, setIsOtpVerified, determineCurrentStep }) {
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const inputRef = [];
  const [showResendOtpTimer, setShowResendOtpTimer] = useState(false);
  const [timerToShow, setTimerToShow] = useState(OTPTimerConstant);
  const [currentLayout, setCurrentLayout] = useState();
  const { mitraReducer, mitraDispatch, setAlert, setSpin } = useContext(AppContext);
  const history = useHistory();
  const [showLeadReferredModal, setShowLeadReferredModal] = useState(false);
  let otpTimerInterval;
  let timerVal;

  const showLoader = (value) => {
    setSpin({
      loading: value, //false or true when actived
      delay: null,
      tip: 'Loading...'  //Loading copy here
    })
  }

  const handleOTPChange = (text, index) => {
    const otpArray = otp;

    // Update the OTP array with the new input
    otpArray[index] = text;

    // Move focus to the next input or the previous one if the text is deleted
    if (text.length === 1 && index < otp.length - 1) {
      inputRef[index + 1].focus();
    } else if (text.length === 0 && index > 0) {
      inputRef[index - 1].focus();
    }

    setOtp([...otpArray]);

    // When all inputs are filled, you can do something with the OTP (e.g., submit it)
    if (otpArray.join('').length === otp.length) {
      const otp = otpArray.join('');
      // Do something with the OTP, e.g., send it to a server for verification
    }
  };

  const sendOTPToLead = async () => {
    // function to trigger OTP
    if (lead?.phoneNumber) {
      const payload = {
        phoneNumber: lead?.phoneNumber
      }

      triggerZeptoOtp(payload)
        .then(res => {

          console.log(res);
          if (res?.data?.success) {
            notification['success']({
              message: 'OTP sent successfully.',
            });
          }

        })
        .catch(err => {
          console.log(err);
          notification['error']({
            message: 'Something went wrong, please try again after sometime.',
          });
        })
    }
  }

  const triggerOtp = () => {
    sendOTPToLead();

    timerVal = OTPTimerConstant;
    setShowResendOtpTimer(true);

    otpTimerInterval = setInterval(() => {
      if (timerVal <= 0) {
        clearInterval(otpTimerInterval);
        setShowResendOtpTimer(false);
      } else {
        timerVal = timerVal - 1;
        setTimerToShow(timerVal < 10 ? `0${timerVal}` : timerVal);
      }
    }, 1000);

  };

  const closeModalAndGoToNextStep = () => {
    setShowLeadReferredModal(false);
    setIsOtpVerified(true);
  }

  const submitWithoutOTP = () => {
    if (!lead?.scheduledInterviewId?.length || lead?.scheduledInterviewId === 'null') {
      createApplicationForZepto(true);
    }
    else {
      goToCandidatesScreen()
    }
  }

  const createApplicationForZepto = (isSubmittingWithoutOTP = false) => {
    // code to create SI
    if (isSubmittingWithoutOTP) {
      showLoader(true);
    }

    createApplication({}, mitraReducer?.mitraInfo, lead)
      .then(res => {
        console.log(res);
        if (isSubmittingWithoutOTP) {
          goToCandidatesScreen()
          showLoader(false);
        }
      }).catch(err => {
        console.log(err);
        notification['error']({
          message: 'Error referring the candidate!!'
        })
      })
  }

  const submitOtp = async () => {

    if (otp.join('').length !== otp.length) {
      notification['error']({
        message: 'Please enter the OTP to continue',
      });

      return;
    }

    showLoader(true);
    try {
      const payload = {
        otp: otp.join(''),
        phoneNumber: lead?.phoneNumber,
        city: lead?.companyCity
      }

      const response = await verifyZeptoOtp(payload);

      if (response?.data?.success) {
        if (!lead?.scheduledInterviewId?.length || lead?.scheduledInterviewId === 'null') {
          setShowLeadReferredModal(true);
          createApplicationForZepto();
        } else {
          closeModalAndGoToNextStep();
        }
      } else {
        notification['error']({
          message: 'Something went wrong, please try after sometime.',
        });
      }
      showLoader(false);
    } catch (e) {
      showLoader(false);
      notification['error']({
        message: 'Something went wrong, please try again after sometime.',
      });
    }
  };

  const enableOtpFormAndTriggetOtpLayout = () => {
    setCurrentLayout(LAYOUTS.OTP_LAYOUT);
    triggerOtp();
  }

  const goToCandidatesScreen = () => {
    history.replace({ pathname: "/recruiter-candidates" });
  }

  const otpLayout = () => {
    return (
      <>
        <div className="otp-verification-container">
          <div className='container-header'>OTP Verification</div>

          <div className="otp-form-container">
            <div className='otp-header'>The OTP will be sent to {lead?.name} on (+91)-{lead?.phoneNumber}</div>

            <div className='otp-input-section'>
              {
                otp.map((item, index) => {
                  return (
                    <Input
                      key={index}
                      className='otp-input'
                      maxLength={1}
                      value={item}
                      ref={(ref) => (inputRef[index] = ref)}
                      onChange={(e) => handleOTPChange(e.target.value, index)}
                    />
                  )
                })
              }
            </div>

            {
              showResendOtpTimer ? (
                <div style={{ marginTop: 24 }}>{`Resend OTP in (00:${timerToShow})`}</div>
              ) : (
                <div>
                  <Button className='button-styles-secondary submit-wo-otp-btn' onClick={() => submitWithoutOTP()}>Submit without OTP</Button>
                  <Button className='button-styles-secondary' onClick={() => triggerOtp()}>Request OTP</Button>
                </div>
              )
            }


          </div>

        </div>


        <div className='cta-container'>
          <Button className='button-styles-primary' onClick={() => submitOtp()}>Verify OTP and Continue</Button>
        </div>
      </>
    )
  };

  const singleButtonRequestOtpLayout = () => {
    return (
      <>

        <div className="otp-verification-container">
          <div className='container-header'>OTP Verification</div>

          <div className="otp-form-container">
            <Button className='button-styles-secondary submit-wo-otp-btn' onClick={() => submitWithoutOTP()}>Submit without OTP</Button>
            <Button className='button-styles-primary' onClick={() => enableOtpFormAndTriggetOtpLayout()}>Request new OTP</Button>
          </div>
        </div>

      </>
    )
  }

  useEffect(() => {
    if (isOtpVerified) {
      setCurrentLayout(LAYOUTS.VERIFIED_SESSION_LAYOUT);
    } else {
      setCurrentLayout(LAYOUTS.SINGLE_BUTTON_REQUEST_OTP_LAYOUT);
    }
  }, [isOtpVerified])

  const goToCurrentStep = () => {
    determineCurrentStep();
  }

  const verifiedLayout = () => {
    return (
      <>

        <div className="otp-verification-container">
          <div className='container-header'>OTP Verification</div>

          <div className="otp-form-container">
            <div className='otp-header' style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <CheckCircleOutlined style={{ color: '#4BB543' }} /> OTP already verified. Please complete the remaining steps
            </div>
          </div>
        </div>

        <div className='button-container'>
          <Button className='submit-button' onClick={() => goToCurrentStep()}>Next</Button>
        </div>

      </>
    )
  }

  return (
    <>
      <Modal title="Lead Referred"
        visible={showLeadReferredModal}
        onOk={closeModalAndGoToNextStep}
        onCancel={goToCandidatesScreen}
        okText={'Complete Additional Form'}
        cancelText={'Go To Candidates Page'}
        className='lead-referred-modal'
      >
        <Result
          status="success"
          title={<div style={{ fontSize: '22px' }}>{`${lead?.name} has been referred successfully!`}</div>}
        />
      </Modal>
      {
        currentLayout === LAYOUTS.OTP_LAYOUT ? otpLayout() : null
      }

      {
        currentLayout === LAYOUTS.SINGLE_BUTTON_REQUEST_OTP_LAYOUT ? singleButtonRequestOtpLayout() : null
      }

      {
        currentLayout === LAYOUTS.VERIFIED_SESSION_LAYOUT ? verifiedLayout() : null
      }

      <div className='note-wo-otp'>
        NOTE: If you're submitting the form without an OTP, please make sure to ask the rider to enter the following code in the referral code section: <span className='wo-otp-code'>A0351</span>
      </div>
    </>
  )
}

export default ZeptoOtpScreen