import { Card, Divider, Empty, Pagination, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import {useHistory} from 'react-router-dom';
import {CalendarOutlined, ClockCircleOutlined, LinkOutlined, RightCircleOutlined, EnvironmentOutlined, InfoCircleOutlined} from '@ant-design/icons';
import moment from 'moment';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';

const LAYOUTS = {
    "NO_CONTENT_LAYOUT": "NO_CONTENT_LAYOUT",
    "LAYOUT_WITH_DATA": "LAYOUT_WITH_DATA"
}

function UploadedFileList({noHeader=false, sessionList}) {
    const [currentLayout, setCurrentLayout] = useState();
    const history = useHistory();

    useEffect(() => {
      if(sessionList?.length) {
        setCurrentLayout(LAYOUTS.LAYOUT_WITH_DATA);
      } else {
        setCurrentLayout(LAYOUTS.NO_CONTENT_LAYOUT);
      }

      trackEvent('ml_samvadini_visited_uploaded_files_tab', {}, CONSTANTS.ENGAGEMENT_TYPES.MOENGAGE);
    }, [sessionList])
    
  return (
    <>
        {
            noHeader ? null: (
                <div className='samvadini-upload-header mt-40'>
                    <div style={{fontSize: '16px'}}>Uploaded Files</div>
                    <div className="desktop-pagination" style={{}}>
                        <Pagination
                        total={1}
                        showTotal={(total, range) => `${range[0]}-${range[1]} of 17 items`}
                        defaultPageSize={100}
                        defaultCurrent={1}
                        responsive={true}
                        showLessItems={true}
                        // hideOnSinglePage={true}
                        showSizeChanger={false}
                        />
                    </div>
                </div>
            )
        }

        {
            currentLayout === LAYOUTS.LAYOUT_WITH_DATA ? (
                <div className='card-list-container'>
                    {
                        sessionList.map((item,index) => {
                            return (
                                <Card
                                key={index}
                                onClick={()=>history.push(`/samvadini-processed-list?intentCallingSessionId=${item?.intentCallingSessionId}&sessionDetails=${JSON.stringify(item)}`)}
                                hoverable>
                                    <div className='upper-container'>
                                        <div className='left-section'>
                                            <div className='d-flex align-items-center flex-wrap' style={{gap:'6px', fontSize: '15px'}}>
                                                Submitted on <CalendarOutlined /> {item?.submittedAt ? moment(item?.submittedAt).format('Do MMMM YYYY'): ''} <ClockCircleOutlined /> {item?.submittedAt ? moment(item?.submittedAt).format('h:mm A'): ''} {item?.isProcessingCompleted == false && item?.isAllLeadsCalledAtleastOnce == true ? <div><InfoCircleOutlined style={{ marginRight: '5px' }} />Session is running to find more interested candidates <Spin size="small" style={{ marginLeft: '8px' }} /></div>: null}
                                            </div>
                                            {
                                                item?.fileName ? <div className='d-flex align-items-center file-name'><LinkOutlined /> {item?.fileName}</div>: null
                                            }
                                            
                                        </div>
                                        <div className='right-section'>
                                            <RightCircleOutlined style={{fontSize: '36px', color: '#d5bec0'}}/>
                                        </div>
                                    </div>
                                    <Divider style={{margin: '14px 8px'}}/>
                                    <div className='d-flex justify-content-between flex-wrap'>
                                        <div className='details-label'>Upload Type: {item?.type}</div>
                                        <div className='details-label'>Interested Candidates: {item?.interestedLeadCount}</div>
                                    </div>
                                </Card>
                            )
                        })
                    }
                </div>
            ): 
            currentLayout === LAYOUTS.NO_CONTENT_LAYOUT ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                    <span>
                      No data
                    </span>
                  }/>
            ): null
        }
        
    </>
  )
}

export default UploadedFileList