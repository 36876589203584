import React, { useEffect, useState } from 'react'
import PageHeaderComponent from '../common/PageHeader'
import { Card, Divider, Table, Tag, Spin } from 'antd';
import {CalendarOutlined, ClockCircleOutlined, LinkOutlined, RightCircleOutlined, EnvironmentOutlined, InfoCircleOutlined} from '@ant-design/icons';
import LatestProcessedCandidateCards from './LatestProcessedCandidateCards';
import commonFunction from '../../utils/commonFunction';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';

function IndividualFileResults({showBreadcrumb=true}) {
  const history = useHistory();
    const [breadcrumb] = useState([{ link: '/samvadini', label: 'Samvaadini' },{link: '', label: 'Samvaadini Processed Candidates'}]);
    const [queryParams] = useState(commonFunction.getAllsearchParams(history.location.search));
    const sessionDetails = JSON.parse(queryParams?.sessionDetails);
    const [sessionId, setSessionId] = useState(null);

    useEffect(() => {
      if(queryParams?.intentCallingSessionId?.length) {
        setSessionId(queryParams?.intentCallingSessionId);
      }

      trackEvent('ml_samvadini_visited_session_details_uploaded_files_tab', {}, CONSTANTS.ENGAGEMENT_TYPES.MOENGAGE);
    }, [queryParams])
    
  return (
    <>
    
      {
        showBreadcrumb ? (
          <div className="header">
            <PageHeaderComponent 
            breadcrumb={breadcrumb} 
            backBtnBool={true}
            />
          </div>
        ): null
      }

      <div className='card-list-container'>
        <Card>
            <div className='upper-container'>
                <div className='left-section'>
                    <div className='d-flex align-items-center flex-wrap' style={{gap:'6px', fontSize: '15px'}}>
                        Submitted on <CalendarOutlined /> {moment(sessionDetails?.submittedAt).format('Do MMMM YYYY')} <ClockCircleOutlined /> {moment(sessionDetails?.submittedAt).format('h:mm A')} {sessionDetails?.isProcessingCompleted == false && sessionDetails?.isAllLeadsCalledAtleastOnce == true ? <div> <InfoCircleOutlined style={{ marginRight: '5px' }} />Session is running to find more interested candidates <Spin size="small" style={{ marginLeft: '8px' }} /></div>: null}
                    </div>
                    {
                        sessionDetails?.fileName ? <div className='d-flex align-items-center file-name'><LinkOutlined /> {sessionDetails?.fileName}</div>: null
                    }
                    
                </div>
                {/* <div className='right-section'>
                    <RightCircleOutlined style={{fontSize: '36px', color: '#d5bec0'}}/>
                </div> */}
            </div>
            <Divider style={{margin: '14px 8px'}}/>
            <div className='d-flex justify-content-between flex-wrap'>
                <div className='details-label'>Upload Type: {sessionDetails?.type}</div>
                <div className='details-label'>Interested Candidates: {sessionDetails?.interestedLeadCount}</div>
            </div>
        </Card>
      </div>

      {
        sessionId ? (<LatestProcessedCandidateCards sessionDetailId={sessionId}/>): null
      }
  </>

  )
}

export default IndividualFileResults