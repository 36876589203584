import React, { useState, useEffect, useContext, useReducer, useRef } from 'react'
import '../style/BulkReferralStyle.scss'
import CONSTANTS from '../../../constants/constants';
import { ArrowRightOutlined} from '@ant-design/icons';
import { Select, Divider, Button, message, DatePicker, Pagination, Empty, Skeleton, Space, Tag } from 'antd';
import { getBulkUploadDataWOReferral} from '../../../service/bulkActionService';
import BulkUploadListing from './BulkUploadListing';
import BulkUploadListingMobile from './BulkUploadListingMobile';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../../App';
import moment from 'moment';
import '../../../css/globalStyles.scss';

const BulkUploadsContainer = ({ }) => {
    const [bulkUploadLeads, setBulkUploadLeads] = useState([]);
    const { mitraReducer, mitraDispatch, setAlert, setSpin } = useContext(AppContext);
    const { RangePicker } = DatePicker;
    const dateFormat = 'DD-MMMM YYYY';
    const [startDate, setStartDate] = useState(moment().subtract(7, "days"));
    const [endDate, setEndDate] = useState(moment());
    const [totalBulkReferralsCount, setTotalBulkReferralsCount] = useState(0);
    const [disableDatesBefore, setDisableDatesBefore] = useState(moment());
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState();
    const history = useHistory();
    const [loadingListData, setLoadingListData] = useState(false);

    useEffect(() => {
        getBulkUploadData(startDate, endDate);
    }, [])

    const getBulkUploadData = (startDate, endDate) => {
        setLoadingListData(true)
        const payload = {
            startDate: startDate,
            endDate: endDate
        }
        getBulkUploadDataWOReferral(payload).then((response) => {
            setLoadingListData(false)
            if (response?.data?.data?.length) {
                setTotalBulkReferralsCount(response?.data?.data?.length);
                console.log(response.data.data)
                setBulkUploadLeads(response.data.data);
            }
        }
        ).catch((error) => {
            console.log(error);
        })
    }

    const changeDateRange = (dateRange) => {
        setStartDate(dateRange[0])
        setEndDate(dateRange[1])
        getBulkUploadData(dateRange[0], dateRange[1]);
    }

    const disableDateForBulkReferrals = (current) => {
        if ((current && current > moment().endOf('day').valueOf()) || (current && current < disableDatesBefore.endOf("day"))) {
            return true;
        }
        else {
            return false;
        }
    }
    useEffect(() => {
        if (mitraReducer && mitraReducer.allowedFeatures) {
            let viewAccessRestricted = mitraReducer?.allowedFeatures[CONSTANTS.VIEW_DATA_ACCESS]?.isEnable
            if (viewAccessRestricted) {
                setDisableDatesBefore(moment().subtract(mitraReducer?.allowedFeatures[CONSTANTS.VIEW_DATA_ACCESS]?.numberOfDays, 'days'))
            }
        }

    }, [mitraReducer])


    return (
        <div className='bulk-ivr-parent'>
            <div className='bo-filters-parent'>
                <div className='d-flex justify-content-between'>

                    <div className='bo-date-range-picker'>
                        <RangePicker
                            defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                            format={dateFormat}
                            onChange={changeDateRange}
                            ranges={{
                                Today: [moment().startOf('day'), moment().endOf('day')],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                            }}
                            disabledDate={(current) => { return disableDateForBulkReferrals(current) }}
                            numberOfMonths={1}
                            value={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                            allowClear={false}
                        />
                    </div>

                    <div className='bo-pagination show-only-desktop'>
                        {totalBulkReferralsCount > 0 &&
                            <Pagination
                                total={totalBulkReferralsCount > 0 && totalBulkReferralsCount}
                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                defaultPageSize={10}
                                defaultCurrent={currentPage ? currentPage : 1}
                                responsive={true}
                                showLessItems={true}
                                onChange={(page, pagesize) => { setCurrentPage(page) }}
                                showSizeChanger={false}
                            />
                        }
                    </div>
                </div>
            </div>
            <div className='ba-uploads-refresh-download-section d-flex justify-content-between align-items-center'>
                <div className='ba-total-uploads-txt'>
                    {totalBulkReferralsCount} uploads
                </div>
                <div className='ba-listing-above-btns d-flex'>
                    <Button onClick={() =>  history.push('/pre-referral-leads-candidates')} className="ba-listing-above-download-btn d-flex align-items-center">
                        Go to Pre-Referral Leads
                        <ArrowRightOutlined />
                    </Button>
                </div>
            </div>
            {
                loadingListData ?
                    <div className='ba-list-loader'>
                        <Skeleton active avatar />
                    </div>
                : null
            }
            {
                !loadingListData && bulkUploadLeads && bulkUploadLeads.length > 0 ?
                    bulkUploadLeads.map((item, idx) => {
                        return (
                            <div key={idx}>
                                <div className='show-only-desktop'>
                                    <div className='ba-display-block w-100'>
                                        <BulkUploadListing listData={item} />
                                    </div>
                                </div>

                                <div className='show-only-mobile'>
                                    <BulkUploadListingMobile listData={item} />
                                </div>
                            </div>
                        )
                    })
                : null
            }
            {
                !loadingListData && bulkUploadLeads && bulkUploadLeads.length == 0 ?
                    <div className='ba-list-loader'>
                        <Empty />
                    </div>
                : null
            }
        </div>
    )
}

export default BulkUploadsContainer; 