import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons';
import BlinkitManualDocumentVerification from './BlinkitManualDocumentVerification';
import BlinkitDigilockerVerification from './BlinkitDigilockerVerification';

function BlinkitVerificationStep({goToNextScreen, lead, leadAdditionalDetails, getLeadInformation}) {
    const [selectedVerificationType, setSelectedVerificationType] = useState();
    const [docVerificationData, setDocVerificationData] = useState();
    const [showStepCompletedSection, setShowStepCompletedSection] = useState(false);
    const [verificationTypes, setVerificationTypes] = useState([
        {
            label: 'Document Upload',
            value: 'Document Upload'
        },
        {
            label: 'DigiLocker Verification',
            value: 'DigiLocker Verification'
        },
    ]);
    const [riderHasCompletedTheStep, setRiderHasCompletedTheStep] = useState(false);


    const handleChange = (item) => {
        console.log(item);
        setSelectedVerificationType(item);
    };

    useEffect(() => {
        // useeffect for autofill
        if(leadAdditionalDetails?.blinkitVerificationType?.length) {
            setSelectedVerificationType(leadAdditionalDetails.blinkitVerificationType);
        }
        
    }, [leadAdditionalDetails])
    
  return (
    <>

        <div className='verification-type-container'>
            <div className='label'>Select verification type<sup>*</sup></div>
            <Select
                style={{ width: '100%', textAlign: 'start', marginTop: '8px' }}
                placeholder="Select verification type"
                value={selectedVerificationType}
                onChange={handleChange}
                optionLabelProp="label"
                options={verificationTypes}
            />
        </div>

        {
            selectedVerificationType === 'Document Upload' ? (
                <BlinkitManualDocumentVerification 
                goToNextScreen={goToNextScreen} 
                lead={lead} 
                docVerificationData={docVerificationData}
                selectedVerificationType={selectedVerificationType}
                leadAdditionalDetails={leadAdditionalDetails}
                getLeadInformation={getLeadInformation}
                />
            ): null
        }

        {
            selectedVerificationType === 'DigiLocker Verification' ? (
                <BlinkitDigilockerVerification 
                docVerificationData={docVerificationData}
                lead={lead}
                goToNextScreen={goToNextScreen}
                selectedVerificationType={selectedVerificationType}
                getLeadInformation={getLeadInformation}
                />
            ): null
        }
    
    </>
  )
}

export default BlinkitVerificationStep
