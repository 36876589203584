import { Button, Card, Divider, Dropdown, Menu, Space, Empty, Pagination } from 'antd'
import React, { useEffect, useState } from 'react'
import { DownOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { getFilterListForTargetFeature, getPerformanceDashboardTabDetails } from '../../service/reportService';
import NewLoader from '../common/NewLoader/NewLoader';
import { getLast6MonthsData } from './../../utils/commonFunction'
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';

function TeamLeaderboardTabContents() {
    const history = useHistory();
    const [teamLeaderboardDetails, setTeamLeaderboardDetails] = useState();
    const [teamSelectionOptions, setTeamSelectionOptions] = useState([
        {
            key: 'targetAchieved',
            label: 'Target Achieved',
            type: 'team'
        },
        {
            key: 'name',
            label: 'Name',
            type: 'team'
        },
    ]);

    const [monthSelectionOptions, setMonthSelectionOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [selectedTeam, setSelectedTeam] = useState('Target Achieved');
    const [selectedMonth, setSelectedMonth] = useState();
    const [noDataFound, setNoDataFound] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const [slicedData, setSlicedData] = useState();
    const trackMoengageEvent = (event,obj) => {
		trackEvent(event, obj || {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
	}

    const onDropdownChange = (e, type) => {
        if(type === 'month') {
            trackMoengageEvent('month-filter-changed-team-leaderboard-tab', {month: e?.label});
            setStartDate(e.startDate);
            setEndDate(e.endDate);
            setSelectedMonth(e.label);
            getTeamLeaderboardDetails(e.startDate, e.endDate);
        } else if(type === 'sortBy') {
            trackMoengageEvent('sort-by-filter-changed-team-leaderboard-tab', {sortBy: e?.label});
            setSelectedTeam(e.label);
            getTeamLeaderboardDetails(startDate, endDate, e.key);
        }
    }


    const monthMenu = (
        <Menu>
            {
                monthSelectionOptions.map((month, idx) => {
                    return (
                        <>
                        <Menu.Item onClick={()=>onDropdownChange(month,'month')} key={month} style={{ padding: '10px 20px' }}>{month.label}</Menu.Item>
                        {
                        monthSelectionOptions.length - 1 === idx ? null: <Divider style={{margin: '0px'}}/>
                        }
                        </>
                    )
                })
            }
        </Menu>
    );

    const teamMenu = (
        <Menu>
            {
                teamSelectionOptions.map((team, idx) => {
                    return (
                        <>
                        <Menu.Item onClick={()=>onDropdownChange(team,'sortBy')} key={team.key} style={{ padding: '10px 20px' }}>{team.label}</Menu.Item>
                        {
                           teamSelectionOptions.length - 1 === idx ? null: <Divider style={{margin: '0px'}}/>
                        }
                        </>
                    )
                })
            }
        </Menu>
    );

    const getTeamLeaderboardDetails = (startDate, endDate, sortBy='targetAchieved') => {
        setLoading(true);
        setNoDataFound(false);
        const payload = {
            tab: 'teamLeader',
            startDate: startDate,
            endDate: endDate,
            sortBy: sortBy,
            milestones: [
                {
                    "key":"referral_date_si"
                },
                {
                    "key": "activation_date"
                },
                {
                    "key": "first_date_of_work"
                },
                {
                    "key": "20th_order_date"
                },
                {
                    "key": "50th_order_date"
                },
                {
                    "key": "80th_order_date"
                }
            ]
        }

        getPerformanceDashboardTabDetails(payload)
        .then(res=> {
            if(res?.data?.status) {
                setLoading(false);
                if(res?.data?.data) {
                    setTeamLeaderboardDetails(res?.data?.data);
                } else {
                    setNoDataFound(true);
                }
            }
        }).catch(err=> {
            console.log(err);
        })
    }
    const goToTeamDetailsScreen = (teamDetails) => {
        trackMoengageEvent('team-detailed-view-from-team-leaderboard', {teamDetails: teamDetails});
        // const encodedTeamDetails = encodeURIComponent(JSON.stringify(teamDetails));
        history.push(`/report/performance-dashboard/team-leaderboard`, {teamDetails});
    }

    useEffect(() => {
        const months = getLast6MonthsData();
        if(months) {
            getTeamLeaderboardDetails(months[5].startDate, months[5].endDate);
            setSelectedMonth(months[5].label)
            setStartDate(months[5].startDate)
            setEndDate(months[5].endDate)
            setMonthSelectionOptions(months);
        }
    }, []);

    useEffect(() => {
        setSlicedData(teamLeaderboardDetails?.slice(startIndex, startIndex + itemsPerPage));
    }, [teamLeaderboardDetails, startIndex]);

    const handlePageChange = (page) => {
        trackMoengageEvent('pagination-from-team-leaderboard');
        setCurrentPage(page);
    };

  return (
    <>
        <div className='d-flex justify-content-between flex-wrap' style={{padding: '24px', gap: '24px'}}>
            <div className='member-leaderboard-input-section'>
                <span>Show performance for</span>
                <Dropdown
                    trigger={['click']}
                    overlay={monthMenu}
                >
                    <div style={{cursor: 'pointer'}}>
                        <Space style={{color: '#D34612', fontWeight: '700'}}>
                            {selectedMonth}
                            <DownOutlined className='d-flex'/>
                        </Space>
                    </div>
                    
                </Dropdown>
                <span>sorted by</span>
                <Dropdown
                    trigger={['click']}
                    overlay={teamMenu}
                >
                    <div style={{cursor: 'pointer'}}>
                        <Space style={{color: '#D34612', fontWeight: '700'}}>
                            {selectedTeam}
                            <DownOutlined className='d-flex'/>
                        </Space>
                    </div>
                    
                </Dropdown>
            </div>
            { teamLeaderboardDetails?.length &&
            <Pagination
                current={currentPage}
                total={teamLeaderboardDetails.length}
				showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                pageSize={itemsPerPage}
                onChange={handlePageChange}
                responsive={true}
                showLessItems={true}
            />
            }
        </div>

        <div className='member-leaderboard-card-container' style={{paddingBottom: '30px'}}>
            {
                slicedData && !loading &&
                slicedData.map((team, index) => {
                    return (
                        <Card key={index} className='member-leaderboard-card' bodyStyle={{padding: '8px 18px'}}>
                            <div className='d-flex justify-content-between'>
                                <span className='team-name'>{team?.team_name}</span>
                                <span className='ft-count'>{team?.FTs} / {team?.target} <span className='ft-label'>FTs</span></span>
                            </div>
                            <Divider style={{margin: '12px'}}/>
                            <div className='team-leaderboard-content-container d-flex'>
                                <div className="milestones-container">
                                    <div className="milestones-inner-container">
                                        {
                                            team?.milestones?.length && team?.milestones?.map((milestone, index) => {
                                                return (
                                                    <div key={`milestone`+index} className='individual-milestone'>
                                                        <div className='dot-indicator' style={{backgroundColor: milestone?.color}}></div>
                                                        <span className='milestone-name'>{milestone?.label}</span>
                                                        <span className='milestone-count'>{milestone?.value}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                        
                                    </div>
                                </div>
                                {(team?.topPerformer?.first_date_of_work) &&
                                <div className="top-performer-container">
                                    <span className='top-performer-label'>Top Performer</span>
                                    <span className='top-performer-name'>{team?.topPerformer?.name} ({team?.topPerformer?.first_date_of_work} FTs) </span>
                                </div>
                                }
                                
                            </div>
                            <Divider style={{margin: '12px 0px'}}/>
                            <div className="team-leaderboard-footer-container">
                                <div className="team-details">
                                    <div className="team-leader-info">
                                        <span className='tl-label'>TL</span>
                                        <span className='tl-name'>{team?.teamLeader}</span>
                                    </div>
                                    <div className="tc-count">
                                        <span className='tc-label'>TCs</span>
                                        <span className='tc-count'>{team?.telecallerCount}</span>
                                    </div>
                                </div>
                                <Button className='view-details' onClick={()=>goToTeamDetailsScreen(team)}><span>View Details</span> <ArrowRightOutlined /> </Button>
                            </div>
                        </Card>
                    )
                })
                }
                {
                    loading &&
                    <div style={{ height: "65vh", width: "100%" }}>
                        <NewLoader comingFromPage={'performancePage'} />
                    </div>
                }
                {
                    noDataFound &&
                    <Empty />
                }
        </div>
    </>
  )
}

export default TeamLeaderboardTabContents