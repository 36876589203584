import React, {useEffect, useState} from 'react';
// import Modal from 'react-modal';
import '../../css/contact.css';
import { CloseOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import {getDisplayableClientName} from '../../utils/commonFunction';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    height: '480px',
    padding: '4px 20px'
  },
};

const FilterPopup = ({ heading, visible, setShowFilterPopup, setCategory, list, setShowBreadcrumb , type }) => {

  const [stateList, setStateList] = useState(list)
  const selected = (index) => {
    const new_list = [...stateList];
    if(type === 'singleSelect'){
      new_list.map(item=>item.value=false)
    }
    new_list[index].value = !new_list[index].value;

    setStateList(new_list);
  }

  const showBreadcrumb = () => {
      stateList.map(single_client => {
        if(single_client.value === true){
          if( setShowBreadcrumb !== undefined){
            setShowBreadcrumb(true)
          }
        }
      })
  }

  const onRequestClose = ()=>{
    setShowFilterPopup(false); 
    setCategory(stateList); 
    if( setShowBreadcrumb !== undefined){
      setShowBreadcrumb(true)
    }
  }

  return (
    
      <Modal
        visible={visible}
        onCancel={(e) => onRequestClose()}
        footer={null}
      >
        <div className="filter-pop-up-container">
          {/* <div className="filter-popup-heading-container">
            <div className="select-client">{heading}</div>
            <div className="filter-popup-cross-icon"> 
              <CloseOutlined  onClick={() => {setShowFilterPopup(false); setCategory(stateList); showBreadcrumb()}} />
            </div>
          </div> */}
          <div className="popup-list-container">
            {
              stateList.map((item, index) => {
                return <div key={index} className="single-filter-item">
                  <input type='checkbox' onChange={() => {selected(index)}} checked={item.value} />
                    <label style={{padding: '1px 10px'}}>{heading === 'Select Client' ? getDisplayableClientName(item.label) : item.label}</label>
                </div>
              })
            }
          </div>
        </div>   
      </Modal>
  )
}
export default FilterPopup;