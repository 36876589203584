import React from 'react';
import TagManager from 'react-gtm-module'

class GTMTracking extends React.Component {
  state = {
    event:''
  };

    render() {
      const tagManagerArgs = {
        gtmId: 'GTM-5LZ5NVZ',
      }
      TagManager.initialize(tagManagerArgs);
      return (
        <React.Fragment></React.Fragment>
      );
    }
}
export default GTMTracking;