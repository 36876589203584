import axios from 'axios';
import axiosInstance from './interceptorService';

export const addDetailsForMitra = async (data, mitra) => {
    const requestData = {
        submission: {
            data: {
                mitraName: mitra.name,
                mitraId: mitra.id,
                isMitra: true,
            },
            currentPageData: {}
        }
    }

    for (let key in data) {
        if (data[key] && !Array.isArray(data[key])) {
            requestData.submission.data[key] = data[key]
            requestData.submission.currentPageData[key] = data[key]
        }
        else {
            if (data[key].length > 0) {
                requestData.submission.data[key] = { url: data[key][0].data ? data[key][0].data.imageUrl : data[key][0].url, originalName: data[key][0].originalName, size: data[key][0].size }
                requestData.submission.currentPageData[key] = { url: data[key][0].data ? data[key][0].data.imageUrl : data[key][0].url, originalName: data[key][0].originalName, size: data[key][0].size }
            }
        }
    }

    delete requestData.submission.data['submit'];
    delete requestData.submission.currentPageData['submit'];

    const url = `${process.env.REACT_APP_BACKEND_URL}/form-io/`
    return axiosInstance.post(url, requestData, { withCredentials: true });
}

export const getMitraInfo = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/mitraData`
    return axiosInstance.get(url, { withCredentials: true });
}