import React, { useContext, useState } from 'react'
import {InfoCircleOutlined, UploadOutlined,DownloadOutlined} from '@ant-design/icons';
import PageHeaderComponent from '../common/PageHeader';
import {Card, Upload, message} from 'antd';
import './SamvadiniModuleStyles.scss';
import { Button } from 'antd';
import SamvadiniFileUpload from './SamvadiniFileUpload';
import UploadedFileList from './UploadedFileList';
import Instructions from './Instructions';
import TabContainer from './TabContainer';
import CONSTANTS from '../../constants/constants';
import { AppContext } from '../../App';

function SamvadiniMainContainer() {
  const {mitraReducer} = useContext(AppContext);
    const [breadcrumb] = useState([{link: '/samvadini', label: <div style={{fontSize: '16px'}}>Samvaadini Bot Intent Filtering</div>}]);
    
  return (
    <div className='mainContentZ main-container'>
        <div className="header">
            <PageHeaderComponent breadcrumb={breadcrumb} />
        </div>

        {
          (
            !CONSTANTS.samvadiniCallingEligibleIds.includes(mitraReducer?.mitraInfo?.id)
          ) ? null : <Instructions />
        }
        
        
        <TabContainer />
    </div>
  )
}

export default SamvadiniMainContainer