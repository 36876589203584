import * as React from "react"
const CopyIcon = ({fillColor='none',height=17,width=17,strokeColor="#252526"}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fillColor}
    viewBox="0 0 17 17"
  >
    <g
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      clipPath="url(#a)"
    >
      <path d="M10.38 4.848h-4.5a1 1 0 0 0-1 1v4.5a1 1 0 0 0 1 1h4.5a1 1 0 0 0 1-1v-4.5a1 1 0 0 0-1-1Z" />
      <path d="M2.554 7.848h-.5a1 1 0 0 1-1-1v-4.5a1 1 0 0 1 1-1h4.5a1 1 0 0 1 1 1v.5" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h17v17H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default CopyIcon
