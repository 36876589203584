import React, {useEffect, useState,useContext} from 'react';
import { Button , Avatar, Tooltip , Modal} from 'antd';
import { acceptanceOfTandC } from './../service/signupService';
import './../css/termsAndConditions.scss';
import { CopyOutlined } from '@ant-design/icons';
import { Typography, Space } from 'antd';
import * as loginService from '../service/loginService';
const { Text, Link } = Typography;
const tandCVersion = 1;

const CreateTandCPopUp = ({mitraReducer, dispatchMitra, isAdmin, termsVersion, mitraId}) => {
    const [showPopUp, setShowPopUp] = useState(true);
    const [termsVersionNew, setTermsVersionNew] = useState(null);

  useEffect(() => {
    if(termsVersion != true) {
      loginService
        .validateSession()
        .then((response) => {
          if(response.data.termsVersion > 0) {
            setTermsVersionNew(true);
          }else{
            setTermsVersionNew(false);
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
      }
  }, []);

    const acceptedTandC = async (e) => {
        const res = acceptanceOfTandC(mitraId, tandCVersion);
        setShowPopUp(false);
    };

  return (
  <>
  {showPopUp && isAdmin  && (termsVersionNew === false) 
  && !window.location.pathname.includes('/job-postings')
  && !window.location.pathname.includes('/upload-onboarding-data')
  && !window.location.pathname.includes('/upload-periscope-data')
  && !window.location.pathname.includes('/upload-marketing-data')
  && !window.location.pathname.includes('/file-uploader')
  && !window.location.pathname.includes('/signup')
  && !window.location.pathname.includes('/termsa-and-conditions')
  &&
    <Modal wrapClassName='tc-pop-up'
      title={<div><CopyOutlined className='copy-icon' /> <span className='title'>TERMS OF USE</span></div>}
      centered
      visible={true}
      footer={[
        <Button key="submit" type="primary" onClick={() => {acceptedTandC()}}>
          Accept & Continue
        </Button>,
        // eslint-disable-next-line
        <p>By proceeding I agree to the updated Terms & Conditions </p> ,
          ]}
    >
      <Text>This is an important message to notify you that Vahan
        has updated its Terms & Conditions. To read the updated Terms & Conditions,
        <Link href="/terms-and-conditions" target="_blank"> Click Here.</Link>
      </Text>
    </Modal>
  }
  </>
  )
}
export default CreateTandCPopUp;