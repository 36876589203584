import React from 'react'
import '../../../css/bulkAction.scss'
import { ArrowRightOutlined, PhoneFilled } from '@ant-design/icons';

function Step1() {
  return (
    <div className="step-container">
        <div className="step-heading">
            <div className="step-button"> Step 1</div>
            <div className="step-heading-text">Click on 'View Details' on the candidate card</div>
        </div>
        <div className="step-details">
            <div className="details-left">
                <div className="candidate-name">Candidate Name</div>
                <div className="phone-container">
                    <div className="phone-icon">
                        <PhoneFilled />
                    </div>
                    <div className="phone-no-placeholder"></div>
                </div>
                <div className="status-container">
                    <div className="client-placeholder"></div>
                    <div className="status-placeholder">
                        <div className="date">Date</div>
                        <div className="status">Status</div>
                    </div>
                </div>
                
            </div>
            <div className="details-right">
                <div className="image-placeholder"></div>
                <div className="view-details-placeholder">
                    <div className="arrow">
                        <ArrowRightOutlined />
                    </div>
                    <div className="view-detail-text">
                        View Details
                    </div>
                </div>
            </div>

        </div>
    </div>
  )
}

export default Step1