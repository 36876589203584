import React from 'react'
import { ArrowRightOutlined, EnvironmentOutlined } from '@ant-design/icons';
import './ClientDetailsContainerStyle.scss'
import '../../../style/JobsPageContainerStyle.scss'


const CDAllCitiesListing = ({ demandsData = {
    "city": "",
    "totalOpenings": "",
    "totalNumberOfLocalities": ""
}, demandInd = 0, onCitySelected }) => {

    return (
        <div className="cd-all-cities-listing-parent jp-cp d-flex justify-content-between align-items-center" onClick={() => onCitySelected(demandsData)}>
            <div className='cd-city-openings justify-content-between align-items-start'>
                <div className='cd-acl-city d-flex'>
                    <EnvironmentOutlined className='cd-acl-city-icon d-flex' />
                    <div className='cd-acl-city-name-txt'>
                        {demandsData?.city}
                    </div>
                </div>
                <div className='cd-all-cities-total-openings-txt'>
                    {demandsData?.totalOpenings} jobs
                </div>
            </div>

            <div className='cd-acl-view-localities d-flex align-items-center w-50 justify-content-end'>
                <div className='cd-acl-view-localities-txt'>
                    View {demandsData?.totalNumberOfLocalities} localities
                </div>
                <ArrowRightOutlined className='cd-acl-view-localities-icon' />
            </div>
        </div>
    )
}

export default CDAllCitiesListing; 