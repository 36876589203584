import * as React from "react";
const HumanCrossNonCircleIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M10.8928 14.2285C10.8928 12.7558 9.10196 11.5618 6.89282 11.5618C4.68368 11.5618 2.89282 12.7558 2.89282 14.2285M12.2262 10.8952L14.8928 8.22852M12.2262 8.22852L14.8928 10.8952M6.89282 9.56185C5.42006 9.56185 4.22616 8.36794 4.22616 6.89518C4.22616 5.42242 5.42006 4.22852 6.89282 4.22852C8.36558 4.22852 9.55949 5.42242 9.55949 6.89518C9.55949 8.36794 8.36558 9.56185 6.89282 9.56185Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default HumanCrossNonCircleIcon;
