import { notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import { RouterPrompt } from '../../RouterPrompt/RouterPrompt';
import {CheckOutlined} from '@ant-design/icons'
import BasicDetails from './components/BasicDetails';
import SwiggyDocumentUpload from './components/SwiggyDocumentUpload';
import BankDetails from './components/BankDetails';
import LocationDetails from './components/LocationDetails';
import WorkPreference from './components/WorkPreference';
import DeliveryKitDetails from './components/DeliveryKitDetails';
import { AppContext } from '../../../App';
import SwiggyOTP from './components/SwiggyOTP';
import { useHistory } from "react-router-dom";
import commonFunction from '../../../utils/commonFunction';
import { getSwiggyProfileData, getSwiggySubmissionDetails } from '../../../service/SwiggyAdditionalInfoService';

function SwiggyAdditionalInfoContainer() {
  const [currentTab, setCurrentTab] = useState('otpVerification');
  const [showPrompt, setShowPrompt] = useState(true);
  const {mitraReducer,mitraDispatch, setAlert,setSpin } = useContext(AppContext);
  const history = useHistory();
  const [lead] = useState(commonFunction.getAllsearchParams(history.location.search))
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [isInitialOtpVerificationCheck, setIsInitialOtpVerificationCheck] = useState(true);
  const [submissionDetails, setSubmissionDetails] = useState({});
  const [pageLevelLoader, setPageLevelLoader] = useState(true);
  const [isLocationScreenDetailsSubmitted, setIsLocationScreenDetailsSubmitted] = useState(false);

  const [tabInfo, setTabInfo] = useState([
    {
        id: 'otpVerification',
        label: 'OTP Verification',
        status: 'inProgress',
        isClickable: true // This will be used when otp expires
    },
    {
        id: 'documents',
        label: 'Document Upload',
        status: 'done',
        isClickable: true
    },
    // {
    //     id: 'bankDetails',
    //     label: 'Bank Details',
    //     status: 'done',
    //     isClickable: true
    // },
    {
        id: 'location',
        label: 'Location & Vehicle Preference',
        status: 'done',
        isClickable: true
    },
    {
        id: 'workPreference',
        label: 'Work Preference',
        status: 'done',
        isClickable: true
    },
    {
        id: 'personalDetails',
        label: 'Personal Details',
        status: 'done',
        isClickable: true
    },
    {
        id: 'deliveryKit',
        label: 'Delivery Kit',
        status: 'done',
        isClickable: true
    }
  ]);

    async function getSubmissionDetails() {
        if(lead.phoneNumber) {
            const payload = {
                phoneNumber: lead.phoneNumber,
                source: 'mitra-leader',
            }
            const submissionData = await getSwiggySubmissionDetails(payload);

            return submissionData.data;
        }
    }

    const goToNextScreen = async() => {

        const submissionDetails = await getSubmissionDetails();
        setSubmissionDetails(submissionDetails);

        const newTabInfo = [...tabInfo];

        for (let i = 0; i < newTabInfo.length; i++) {
            if(newTabInfo[i].status === 'inProgress' && (i+1 < newTabInfo.length)) {
                newTabInfo[i].status = 'done';
                newTabInfo[i+1].status = 'inProgress';
                newTabInfo[i+1].isClickable = 'true';
                setCurrentTab(newTabInfo[i+1].id);
                break;
            }
        }

        setTabInfo([...newTabInfo]);
    };

    const onTabClick = (item) => {
        if(item.status === 'pending') return;

        if(!item.isClickable) {
            notification['error'] ({
                message: 'Please verify the OTP to continue'
            });
            return;
        }

        if(currentTab === 'location' && !isLocationScreenDetailsSubmitted) {
            notification['error'] ({
                message: 'Submit location & vehicle preference to continue.'
            });
            return;
        }

        const newTabInfo = [...tabInfo];

        for (let i = 0; i < newTabInfo.length; i++) {
            if(newTabInfo[i].id === item.id) {
                newTabInfo[i].status = 'inProgress';
                setCurrentTab(newTabInfo[i].id);
            } else {
                if(newTabInfo[i].status === 'inProgress') {
                    newTabInfo[i].status = 'done';
                }
            }
        }

        setTabInfo([...newTabInfo]);
    };

    const showLoader = (value) => {
        setSpin({
            loading: value, //false or true when actived
            delay: null,
            tip: 'Loading...'  //Loading copy here
        })
    }

    const determineCurrentStep = async() => {
        showLoader(true);
        setPageLevelLoader(true);

        try {
            const submissionDetails = await getSubmissionDetails();
            setSubmissionDetails(submissionDetails);

            const currentStep = submissionDetails.currentStep;
                        
            let currentStepStatusIsInProgress = false;
            const updatedTabInfo = [...tabInfo];

            for (let i = 0; i < updatedTabInfo.length; i++) {
                if(updatedTabInfo[i].id === currentStep) {
                    updatedTabInfo[i].status = 'inProgress';
                    updatedTabInfo[i].isClickable = true;
                    currentStepStatusIsInProgress = true;
                    setCurrentTab(updatedTabInfo[i].id);
                } else {
                    if(currentStepStatusIsInProgress) {
                        // this means we are checking tabs which comes after current tab
                        // meaning they are still pending
                        updatedTabInfo[i].status = 'pending';
                        updatedTabInfo[i].isClickable = false;
                    } else {
                        // this means that the current item is not the current step
                        // and is completed because we havent yet encountered 'currentStepStatusIsInProgress'
                        updatedTabInfo[i].status = 'done';
                        updatedTabInfo[i].isClickable = true;
                    }
                }
            }
    
            setTabInfo([...updatedTabInfo]);
            showLoader(false);
            setPageLevelLoader(false);
        } catch(e) {
            showLoader(false);
            setPageLevelLoader(false);
        }
    }

    const otpVerificationFailedProcess = () => {
        setIsOtpVerified(false);

        // make current step as otp step, disable all the tab clicks
        const newTabInfo = [...tabInfo];

        for (let i = 0; i < newTabInfo.length; i++) {
            if(newTabInfo[i].id === 'otpVerification') {
                newTabInfo[i].status = 'inProgress';
                setCurrentTab(newTabInfo[i].id);
            } else {
                newTabInfo[i].status = 'pending';
                newTabInfo[i].isClickable = false;
            }
        }

        setTabInfo([...newTabInfo]);
    }

    const getOtpValidationStatus = async() => {
        setPageLevelLoader(true);
        showLoader(true);
        try {
            const payload = {
                phoneNumber: lead?.phoneNumber,
                dataToFetch: {
                "getProfileData":{}
                }
            }
        
            const response = await getSwiggyProfileData(payload);
            if(response.data?.data?.getProfileData?.success) {
                setIsOtpVerified(true);
            } else {
                otpVerificationFailedProcess();
            }

            setPageLevelLoader(false);
            showLoader(false);
        } catch(e) {
            otpVerificationFailedProcess();
            setPageLevelLoader(false);
            showLoader(false);
        }
    }

    useEffect(() => {
    if(lead) {
        getOtpValidationStatus();
    }
    }, [lead])

    useEffect(() => {
        if(isInitialOtpVerificationCheck && isOtpVerified) {
        // Here if OTP is verified and we have the details to check the current step then call determineCurrentStep()
        determineCurrentStep();
        setIsInitialOtpVerificationCheck(false);
        }
    }, [isOtpVerified])

  return (
    !pageLevelLoader ? (
    <div className="AdditionalInfoScreen mainContentZ">
        {
            <RouterPrompt
                when={showPrompt}
                title="Leave this page"
                cancelText="No"
                okText="Yes"
                onOK={() => true}
                onCancel={() => false}
                promptMessage= {"Details are not completed yet. Are you sure you want to leave the form?"}
            />
        }
        <div className='header-text'>Swiggy Additional Form</div>
        <ul>
            <li style={{display: 'flex', textAlign: 'start', gap: '6px'}}>
                <b>Note:</b>
                <p>Please upload your candidates details and documents in this section. These will be directly submitted to Swiggy. You can also upload the rider selfie image through this flow</p>
            </li>
        </ul>

        <div className="tab-container">
            {
                tabInfo.map((item, index) => {
                    return (
                        <div 
                        key={item.id} 
                        className={
                            `tab-item${item.status === 'inProgress' ? ' active': item.status === 'pending'? ' pending': ' done'}`
                        }
                        onClick={()=>onTabClick(item)}
                        >
                            {item.label} {item.status !== 'pending' ? <CheckOutlined style={{color: item.status === 'inProgress' ? '#fff':'#4BB543'}}/>: null}
                        </div>
                    )
                })
            }
        </div>

        <div className='form-content-container'>
            {/* Tabs content */}
            {
                currentTab === 'otpVerification' ? (
                <SwiggyOTP 
                lead={lead} 
                isOtpVerified={isOtpVerified}
                setIsOtpVerified={setIsOtpVerified}
                determineCurrentStep={determineCurrentStep}
                />) : null
            }
            {
              currentTab === 'personalDetails' ? <BasicDetails goToNextScreen={goToNextScreen} lead={lead} submissionDetails={submissionDetails}/>: null
            }
            {
              currentTab === 'documents' ? <SwiggyDocumentUpload goToNextScreen={goToNextScreen} lead={lead} submissionDetails={submissionDetails}/>: null
            }
            {/* {
              currentTab === 'bankDetails' ? <BankDetails goToNextScreen={goToNextScreen} lead={lead}/>: null
            } */}
            {
              currentTab === 'location' ? <LocationDetails goToNextScreen={goToNextScreen} lead={lead} submissionDetails={submissionDetails} setIsLocationScreenDetailsSubmitted={setIsLocationScreenDetailsSubmitted}/>: null
            }
            {
              currentTab === 'workPreference' ? <WorkPreference goToNextScreen={goToNextScreen} lead={lead} submissionDetails={submissionDetails}/>: null
            }
            {
              currentTab === 'deliveryKit' ? <DeliveryKitDetails setShowPrompt={setShowPrompt} lead={lead} goToNextScreen={goToNextScreen} submissionDetails={submissionDetails}/>: null
            }
        </div>
    </div>):<div className='AdditionalInfoScreen mainContentZ'></div>
  )
}

export default SwiggyAdditionalInfoContainer
