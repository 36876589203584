import React, { useState, useEffect } from "react"
import { CheckCircleFilled, ArrowLeftOutlined, ExclamationCircleOutlined, CloseOutlined, LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, InputNumber, Select, Input, notification, Divider, Spin, Tooltip } from 'antd';
import '../../../css/globalStyles.scss'
import './ReassignLeadsDialogStyle.scss'
import { reassignLeads } from "../../../service/leadService";
import animationData from '../../../static/lottie/greenTick.json'
import Lottie from 'react-lottie';
import { getMitraTeamMembersList } from "../../../service/commonService";
import moment from "moment";
import { trackEvent } from "../../../service/engagementMonitoringService";
import CONSTANTS from "../../../constants/constants";

const { Option } = Select;

const ReassignLeadsDialog = ({ isModalOpen = false, closeModal = () => { }, onBackClick = () => { }, showHeader = false, totalFilteredCandidates = 0, allFiltersFromParent = {}, allTeamMembersList = [], pageType = "", isBulkReassignment = false, rhIdForSingleReassignment = "", isLeadReassigned = () => { } }) => {
    const key = `open${moment()}`;
    const placement = 'topRight';
    const [allFilters, setAllFilters] = useState({})
    const [currentAssignedMembers, setCurrentAssignedMembers] = useState([])
    const [numOfCandidatesToReassign, setNumOfCandidatesToReassign] = useState(0)
    const [allMembers, setAllMembers] = useState([])
    const [membersToReassignTo, setMembersToReassignTo] = useState([])
    const [showSuccessfullyReassigned, setShowSuccessfullyReassigned] = useState(false);
    const [showCurrentlyAssigned, setShowCurrentlyAssigned] = useState(false);
    const [reAssigningLeads, setReAssigningLeads] = useState(false);
    const [headerTxt, setHeaderTxt] = useState("Re-Assign Candidates");

    const defaultLottieOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        },
    };

    useEffect(() => {
        if (!isBulkReassignment) {
            setNumOfCandidatesToReassign(1)
        }
    }, [])

    useEffect(() => {
        if (allTeamMembersList?.length > 0) {
            setAllMembers(allTeamMembersList)
            preSelectCurrentlyAssignedMember(allTeamMembersList)
        }
        else {
            getMitraTeamMembersList().then(response => {
                if (response?.data?.data) {
                    setAllMembers(response?.data?.data)
                    preSelectCurrentlyAssignedMember(response?.data?.data)
                }
                else {
                    notification.open({
                        message: "Failed to fetch team members list, please try again later",
                        key,
                        duration: 5,
                        placement,
                        icon: <ExclamationCircleOutlined className='red-negative' />,
                    });
                }
            });
        }
    }, [allFiltersFromParent?.teamMemberIds, allTeamMembersList])

    const preSelectCurrentlyAssignedMember = (allMemberArr) => {
        if (allMemberArr?.length > 0 && allFiltersFromParent?.teamMemberIds?.length > 0) {
            const resultArray = allMemberArr.filter(member => allFiltersFromParent?.teamMemberIds?.includes(member.id));
            if (resultArray.length > 0) {
                setCurrentAssignedMembers(resultArray)
            }
        }
    }

    const filterMitraTeamMembers = (input, option) => {
        return (option?.name ?? '').toLowerCase().includes(input.toLowerCase());
    }

    const onNumCandidatesToReassignChanged = (inp) => {
        if (inp) {
            if (inp > totalFilteredCandidates) {
                notification.open({
                    message: "Number of candidates to reassign cannot be greater than total filtered candidates",
                    key,
                    duration: 5,
                    placement,
                    icon: <ExclamationCircleOutlined className='red-negative' />,
                });
                setNumOfCandidatesToReassign(0)
            }
            else {
                setNumOfCandidatesToReassign(inp)
            }
        }
        else {
            setNumOfCandidatesToReassign(0)
        }
    }

    const onNewTeamMembersSelected = (membersSelected) => {
        if (isBulkReassignment) {
            setMembersToReassignTo(membersSelected)
        }
        else {
            setMembersToReassignTo([membersSelected])
        }
    }

    const showCurrentlyAssignedMembersOnly = () => {
        setShowCurrentlyAssigned(true);
        setHeaderTxt("Currently assigned members")
    }

    const goBack = () => {
        if (showCurrentlyAssigned) {
            setShowCurrentlyAssigned(false);
            setHeaderTxt("Re-Assign Candidates")
        }
    }

    const isConfirmBtnDisabled = () => {
        var isDisabled = false

        if (isBulkReassignment) {
            if (currentAssignedMembers.length == 0 || totalFilteredCandidates == 0 || numOfCandidatesToReassign == 0 || numOfCandidatesToReassign < 0 || membersToReassignTo.length == 0) {
                isDisabled = true
            }
            else {
                isDisabled = false
            }
        }
        else {
            if (currentAssignedMembers.length == 0 || totalFilteredCandidates == 0 || membersToReassignTo.length == 0) {
                isDisabled = true
            }
            else {
                isDisabled = false
            }
        }

        return isDisabled
    }

    const prepareForSingleOrBulkReassignment = () => {
        if (isBulkReassignment) {
            reassignBulkLeads()
        }
        else {
            reassignSingleLeads()
        }
    }

    const reassignBulkLeads = () => {
        const filtersPayload = allFiltersFromParent
        filtersPayload.featureName = pageType
        let payload = {
            filtersData: filtersPayload,
            mitraIdForReassignmentArray: membersToReassignTo,
            reassignmentCount: numOfCandidatesToReassign
        }

        setReAssigningLeads(true);
        trackEvent('reassign_bulk_leads_api_called', {
            filtersData: JSON.stringify(filtersPayload),
            mitraIdForReassignmentArray: membersToReassignTo,
            reassignmentCount: numOfCandidatesToReassign
        }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        reassignLeads(payload).then((reassignRes) => {
            if (reassignRes?.data?.success) {
                isLeadReassigned()
                setShowSuccessfullyReassigned(true);
                setHeaderTxt("Complete")
                setNumOfCandidatesToReassign(reassignRes?.data?.count)
                setReAssigningLeads(false);
                trackEvent('reassign_bulk_leads_api_success', {
                }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
            }
            else {
                setReAssigningLeads(false);
                setShowSuccessfullyReassigned(false);
                trackEvent('reassign_bulk_leads_api_failed', {
                }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                notification.open({
                    message: reassignRes?.data?.message,
                    key,
                    duration: 5,
                    placement,
                    icon: <ExclamationCircleOutlined className='red-negative' />,
                });
            }
        }).catch((err) => {
            trackEvent('reassign_bulk_leads_api_failed', {
                exception: `${err}`
            }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
            setReAssigningLeads(false);
            setShowSuccessfullyReassigned(false);
            notification.open({
                message: "Something went wrong, please try again later",
                key,
                duration: 5,
                placement,
                icon: <ExclamationCircleOutlined className='red-negative' />,
            });
        })
    }

    const reassignSingleLeads = () => {
        let payload = {
            recommendationHistoryId: rhIdForSingleReassignment,
            mitraIdForReassignmentArray: membersToReassignTo,
            reassignmentCount: numOfCandidatesToReassign
        }

        setReAssigningLeads(true);
        trackEvent('reassign_single_lead_api_called', {
            recommendationHistoryId: rhIdForSingleReassignment,
            mitraIdForReassignmentArray: JSON.stringify(membersToReassignTo),
            reassignmentCount: numOfCandidatesToReassign
        }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        reassignLeads(payload).then((reassignRes) => {
            if (reassignRes?.data?.success) {
                isLeadReassigned()
                setShowSuccessfullyReassigned(true);
                setNumOfCandidatesToReassign(reassignRes?.data?.count)
                setReAssigningLeads(false);
                trackEvent('reassign_single_lead_api_success', {
                }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
            }
            else {
                setReAssigningLeads(false);
                setShowSuccessfullyReassigned(false);
                trackEvent('reassign_single_lead_api_failed', {
                }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                notification.open({
                    message: reassignRes?.data?.message,
                    key,
                    duration: 5,
                    placement,
                    icon: <ExclamationCircleOutlined className='red-negative' />,
                });
            }
        }).catch((err) => {
            setReAssigningLeads(false);
            setShowSuccessfullyReassigned(false);
            trackEvent('reassign_single_lead_api_failed', {
                exception: err
            }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
            notification.open({
                message: "Something went wrong, please try again later",
                key,
                duration: 5,
                placement,
                icon: <ExclamationCircleOutlined className='red-negative' />,
            });
        })
    }

    return (
        <div className="reassign-leads-container h-100">
            {
                showHeader ?
                    <div className='rl-header d-flex justify-content-between align-items-center'>
                        <div className='rl-header-title d-flex justify-content-between align-items-center'>
                            <div className='d-flex align-items-center'>
                                {
                                    showCurrentlyAssigned ?
                                        <ArrowLeftOutlined onClick={goBack} className="rl-back-icon" />
                                        :
                                        null
                                }
                                <span className='rl-header-txt semibold20-28'>
                                    {headerTxt}
                                </span>
                            </div>
                        </div>
                        <div>

                        </div>
                        <div className='rl-header-close jp-cp disp-flex' onClick={closeModal}>
                            <CloseOutlined className='rl-header-close-icon' />
                        </div>
                    </div>
                    :
                    null
            }


            {
                showSuccessfullyReassigned ?
                    <div className="rl-successful-container">
                        <div className="rl-successful-lottie">
                            <Lottie
                                options={defaultLottieOptions}
                                height={200}
                                width={200}
                                speed={0.5}
                            />
                        </div>
                        <div className="rl-successful-txt semibold16-24">
                            {numOfCandidatesToReassign} {numOfCandidatesToReassign > 1 ? "candidates have" : "candidate has"} been re-assigned
                        </div>
                    </div>
                    :
                    showCurrentlyAssigned ?
                        <div className="rl-currently-assigned-members-view">
                            {
                                currentAssignedMembers?.length > 0 && currentAssignedMembers.map((member, memberInd) => {
                                    return (
                                        <span key={memberInd} className="rl-member-name-txt regular12-20">
                                            {member.name}
                                        </span>
                                    )
                                })
                            }
                        </div>
                        :
                        <div className="rl-body-parent h-100">
                            <div className={`rl-body h-100 ${isBulkReassignment ? "rl-body-padding" : ""}`}>
                                <div className="rl-content">
                                    {
                                        isBulkReassignment ?
                                            <div className="rl-content-section">
                                                <span className='rl-num-candidates-header-txt rl-common-header-txt semibold14-22'>
                                                    No. of filtered {totalFilteredCandidates > 1 ? "candidates" : "candidate"}
                                                </span>
                                                <Input
                                                    disabled={true}
                                                    className='rl-num-candidates-input rl-section-field'
                                                    value={totalFilteredCandidates}
                                                    // onChange={onBrandNameChange}
                                                    placeholder=""
                                                    allowClear
                                                />
                                            </div>
                                            :
                                            null
                                    }

                                    <div className="rl-content-section">
                                        <span className='rl-assigned-members-header-txt rl-common-header-txt semibold14-22'>
                                            Currently assigned {currentAssignedMembers?.length > 1 ? "members" : "member"}
                                        </span>
                                        <div className="rl-assigned-members-parent d-flex align-items-center">
                                            {
                                                currentAssignedMembers?.length > 0 && currentAssignedMembers.map((member, memberInd) => {
                                                    return (
                                                        memberInd < 11 ?
                                                            <span key={memberInd} className="rl-member-name-txt regular12-20">
                                                                {member.name}
                                                            </span>
                                                            :
                                                            null
                                                    )
                                                })
                                            }

                                            {
                                                currentAssignedMembers?.length > 10 ?
                                                    <span className="rl-extra-members-count-txt bold12-20 primary-orange2" onClick={showCurrentlyAssignedMembersOnly}>
                                                        + {currentAssignedMembers?.length - 10}
                                                    </span> : null
                                            }

                                        </div>
                                    </div>

                                    {
                                        isBulkReassignment ?
                                            <div className="rl-content-section">
                                                <div className="d-flex align-items-center">
                                                    <span className='rl-num-candidates-header-txt rl-common-header-txt semibold14-22'>
                                                        Select no. of candidates to re-assign <span className="red-negative">*</span>
                                                    </span>
                                                    <Tooltip title="Most recent will be selected." color="#FFF" className="rl-tooltip" overlayInnerStyle={{
                                                        color: "#111",
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                        lineHeight: "22px",
                                                    }}>
                                                        <InfoCircleOutlined className="black45" />
                                                    </Tooltip>
                                                </div>
                                                <InputNumber
                                                    className='rl-num-candidates-input rl-section-field w-100'
                                                    placeholder={totalFilteredCandidates}
                                                    type='number'
                                                    onChange={onNumCandidatesToReassignChanged}
                                                    value={numOfCandidatesToReassign}
                                                    defaultValue={numOfCandidatesToReassign} />
                                            </div>
                                            :
                                            null
                                    }

                                    <div className="rl-content-section">
                                        <div className="d-flex align-items-center">
                                            <span className='rl-num-candidates-header-txt rl-common-header-txt semibold14-22'>
                                                Select new members to re-assign to <span className="red-negative">*</span>
                                            </span>
                                            {
                                                totalFilteredCandidates > 1 ?
                                                    <Tooltip title="Candidates will be re-assigned in a round-robin way." color="#FFF" className="rl-tooltip" overlayInnerStyle={{
                                                        color: "#111",
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                        lineHeight: "22px",
                                                    }}>
                                                        <InfoCircleOutlined className="black45" />
                                                    </Tooltip>
                                                    :
                                                    null
                                            }

                                        </div>

                                        <Select
                                            className='rl-new-member-dropdown w-100'
                                            placeholder={`Search Team Member`}
                                            showSearch
                                            mode={isBulkReassignment ? "multiple" : ""}
                                            onChange={onNewTeamMembersSelected}
                                            optionFilterProp="children"
                                            filterOption={filterMitraTeamMembers}
                                            value={membersToReassignTo}
                                        >
                                            {
                                                allMembers?.map((member) => {
                                                    return (
                                                        <Option value={member.id} key={member.id} name={member.name} disabled={allFiltersFromParent?.teamMemberIds?.includes(member.id)}>
                                                            <div className='d-flex justify-content-between w-100 h-100 align-items-center'>
                                                                <span className={`regular14-22 ${allFiltersFromParent?.teamMemberIds?.includes(member.id) ? "black45" : "black85"} `}>{member.name}</span>
                                                                {
                                                                    allFiltersFromParent?.teamMemberIds?.includes(member.id) ? <span className='regular14-22 black45'>Currently Assigned</span> : ""
                                                                }
                                                            </div>
                                                        </Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </div>
                                </div>
                            </div>

                            <div className='rl-footer'>
                                <Divider />
                                <div className='rl-footer-btn-container d-flex justify-content-end h-100 w-100 align-items-center'>
                                    <Button onClick={prepareForSingleOrBulkReassignment} className='vl-primary-btn rl-footer-confirm-btn h-100 w-100' disabled={isConfirmBtnDisabled() || reAssigningLeads}>
                                        <span className='bold16-24 plain-white'>
                                            Confirm
                                        </span>
                                        {
                                            reAssigningLeads ?
                                                <span><Spin indicator={<LoadingOutlined className='rl-confirm-btn-loader' spin />} /></span>
                                                :
                                                null
                                        }
                                    </Button>
                                </div>

                            </div>
                        </div>
            }

        </div>
    )
}

export default ReassignLeadsDialog;