import axios from 'axios';
import axiosInstance from './interceptorService';

const tok = 'GZIIwas321UxE8oPnz';
const hash = btoa(tok);
const Basic = 'Basic ' + hash;
export const fetchIssues = async (data) => {
    let cf_phone = data.mitraPhoneNumber;
    let customQuery = '(cf_your_registered_phone_number:'+ cf_phone;
    customQuery += data.managerMitraPhoneNumber ? '%20OR%20cf_manager_phone_number:'+ data.managerMitraPhoneNumber + ')' : '%20OR%20cf_manager_phone_number:'+ cf_phone + ')';
    customQuery += !data.statusId ? '' : '%20AND%20status:'+ data.statusId;
    const pageQuery = data.currentPage ? '&page=' + data.currentPage : '';
    const url =  `https://vahan-mitraleadersupport.freshdesk.com/api/v2/search/tickets?query="${customQuery}"${pageQuery}`;
    const response = await axios.get(url, {headers : { 'Authorization' : Basic }});
    return response;
};

export const searchIssues = async (data) => {
    console.log(data);
    let cf_phone = data.mitraPhoneNumber;
    let search = 'cf_candidate_phone_number:'+ data.search + '%20AND%20';
    let customQuery = '(cf_your_registered_phone_number:'+ cf_phone;
    customQuery += data.managerMitraPhoneNumber ? '%20OR%20cf_manager_phone_number:'+ data.managerMitraPhoneNumber + ')' : '%20OR%20cf_manager_phone_number:'+ cf_phone + ')';
    const url =  'https://vahan-mitraleadersupport.freshdesk.com/api/v2/search/tickets?query="'+ search + '(' + customQuery +')"';
    const response = await axios.get(url, {headers : { 'Authorization' : Basic }});
    return response;
}

export const getCompanyLogo = async (data) =>
{   
    const requestData = {
        companies: data
    }

    const url = `${process.env.REACT_APP_BACKEND_URL}/images/companyLogos/`
    return axiosInstance.post(url, requestData, {withCredentials: true});
}