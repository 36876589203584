import { Button, Divider, Skeleton } from 'antd'
import React, { useEffect } from 'react'
import '../styles/SummaryScreen.css'

function SummaryScreen({summaryInfo=null, submitForm=()=>1, getSummaryInfo, submissionType, loadingSummaryInfo, pitchClient, loader}) {

  useEffect(() => {
    getSummaryInfo();
  }, [])

  useEffect(() => {
    console.log(summaryInfo);
  }, [summaryInfo])
  
  
  return (
    <>
      <div className="step-info-header">Start your session</div>
      <div className="step-info-sub-header">Please look at the details.</div>

      <div className="upload-container shadow-highlighter">
        <div className="upload-file-main-section summary-screen-content">

          <span className='inter-medium-18-18 color-252526 w-100 d-flex'>Details</span>
          <Divider style={{margin: '20px 0px'}}/>

          {
            loadingSummaryInfo ? (
              <Skeleton active />
            ): summaryInfo?.status ? (
              <div className="summary-container">

                {
                  submissionType === 'File Upload' ? (
                    <div className="summary-item d-flex align-items-center justify-content-between">
                      <div className='d-flex align-items-center summary-item-left' style={{gap:'12px'}}>
                        <img src='/image/uploaded-leads.png' className="summary-image" />
                        <img src='/image/uploaded-leads-mobile.png' className="summary-image-mobile" />
                        <span className='inter-semibold-16-24 color-595959 summary-value-mobile'>{summaryInfo?.leadsUploaded}</span>
                        <div className="summary-info d-flex flex-column align-items-start">
                          <span className='inter-regular-16-24 color-252526 summary-header'>Leads Uploaded</span>
                          <span className='inter-regular-12-16 color-838383 summary-sub-header'>Total Number</span>
                        </div>
                      </div>
                      <span className='inter-semibold-16-24 color-595959 summary-value-desktop'>{summaryInfo?.leadsUploaded}</span>
                    </div>
                  ): null
                }
              
                <div className="summary-item d-flex align-items-center justify-content-between">
                  <div className='d-flex align-items-center summary-item-left' style={{gap:'12px'}}>
                    <img src='/image/telecallers.png' className="summary-image" />
                    <img src='/image/telecallers-mobile.png' className="summary-image-mobile" />
                    <span className='inter-semibold-16-24 color-595959 summary-value-mobile'>{summaryInfo?.totalTCs}</span>
                    <div className="summary-info d-flex flex-column align-items-start">
                      <span className='inter-regular-16-24 color-252526 summary-header'>Telecallers</span>
                      <span className='inter-regular-12-16 color-838383 summary-sub-header'>Assigned Telecallers</span>
                    </div>
                  </div>
                  <span className='inter-semibold-16-24 color-595959 summary-value-desktop'>{summaryInfo?.totalTCs}</span>
                </div>

                <div className="summary-item d-flex align-items-center justify-content-between">
                  <div className='d-flex align-items-center summary-item-left' style={{gap:'12px'}}>
                    <img src='/image/eligible.png' className="summary-image" />
                    <img src='/image/eligible-mobile.png' className="summary-image-mobile" />
                    <span className='inter-semibold-16-24 color-595959 summary-value-mobile'>{summaryInfo?.leadsEligible}</span>
                    <div className="summary-info d-flex flex-column align-items-start">
                      <span className='inter-regular-16-24 color-252526 summary-header'>Eligible</span>
                      <span className='inter-regular-12-16 color-838383 summary-sub-header'>Number on Candidates</span>
                    </div>
                  </div>
                  <span className='inter-semibold-16-24 color-595959 summary-value-desktop'>{summaryInfo?.leadsEligible}</span>
                </div>

                <div className="summary-item d-flex align-items-center justify-content-between">
                  <div className='d-flex align-items-center summary-item-left' style={{gap:'12px'}}>
                    <img src='/image/session-time.png' className="summary-image" />
                    <img src='/image/session-time-mobile.png' className="summary-image-mobile" />
                    <span className='inter-semibold-16-24 color-595959 summary-value-mobile'>{summaryInfo?.sessionTime > 60 ? `${(summaryInfo?.sessionTime/60).toFixed(0)} hrs`: `${summaryInfo?.sessionTime} mins`} </span>
                    <div className="summary-info d-flex flex-column align-items-start">
                      <span className='inter-regular-16-24 color-252526 summary-header'>Session Time</span>
                      <span className='inter-regular-12-16 color-838383 summary-sub-header'>Total time</span>
                    </div>
                  </div>
                  <span className='inter-semibold-16-24 color-595959 summary-value-desktop'>{summaryInfo?.sessionTime > 60 ? `${(summaryInfo?.sessionTime/60).toFixed(0)} hrs`: `${summaryInfo?.sessionTime} mins`} </span>
                </div>

                <div className="summary-item d-flex align-items-center justify-content-between">
                  <div className='d-flex align-items-center summary-item-left' style={{gap:'12px'}}>
                    <img src='/image/script.png' className="summary-image" />
                    <img src='/image/script-mobile.png' className="summary-image-mobile" />
                    <span className='inter-semibold-16-24 color-595959 summary-value-mobile' style={{textTransform:'capitalize'}}>{pitchClient?.toLowerCase() !== 'default'? 'Referred Client': pitchClient}</span>
                    <div className="summary-info d-flex flex-column align-items-start">
                      <span className='inter-regular-16-24 color-252526 summary-header'>Script</span>
                      <span className='inter-regular-12-16 color-838383'>Selected</span>
                    </div>
                  </div>
                  <span className='inter-semibold-16-24 color-595959 summary-value-desktop' style={{textTransform:'capitalize'}}>{pitchClient?.toLowerCase() !== 'default'? 'Referred Client': pitchClient}</span>
                </div>

                <Button 
                loading={loader}
                onClick={submitForm}
                className='primary-btn-styles-v2 btn-44h btn-width-174 summary-screen-submission-btn' 
                style={submissionType === 'File Upload' ? {alignSelf:'flex-end',margin:'0 0 0 32%'}:{alignSelf:'flex-start'}}>
                  <span className='btn-text-semibold-12-16 plain-white'>Start Filtering</span>
                </Button>

              </div>
            ): <span className='inter-medium-16-24'>{summaryInfo?.message}</span>
          }
          

        </div>
      </div>

    </>
  )
}

export default SummaryScreen