import LogInPage from '../../components/login/LogInPage.component';
import LeadsPage from '../../components/leads/LeadsPage.component';
import Recruiter from '../../components/leads/recruiter.component';
import JobsPage from '../../components/jobs/AddJob.component';
import AdditionalInfoPage1 from '../../components/additionalInfo/AdditionalInfo1.component';
import JobDemandsPage from '../../components/JobDemandsPage.component';
import JobPostingPage from '../../components/common/JobPostingPage.component';
import ConvertedLeadsPage from '../../components/convertedLeads.component';
import UploadPeriscopeDataPage from '../../components/UploadPeriscopeData.component';
import UploadMarketingDataPage from '../../components/UploadMarketingData.component';
import UploadOnBoardingDataPage from '../../components/UploadOnBoardingData.component';
import FileUploader from '../../components/FileUploader.component';
import SignupPage from '../../components/signup/SignupPage.component';
import AccountPage from '../../components/account/AccountPage.component';
import UploadDocumentsPage from '../../components/account/UploadMitraDocsPage.compoent';
import TeamManagement from '../../components/teamManagement/TeamManagement.component';
import TermsAndConditions from '../../components/TermsAndConditions';
import TrainingSessionPage from '../../components/account/TrainingSessionPage.component';
import ReportPage from '../../components/report/ReportPage.component';
import Incentives from '../../components/Incentives/Incentive.component';
import IncentiveTermsAndConditions from '../../components/Incentives/TermsAndCondition.component';
import ReportLeadPage from '../../components/report/ReportLeadPage.component';
import TeamLeaderBoard from '../../components/report/TeamLeaderBoardPage.component';
import TeamLeadspage from '../../components/report/TeamLeadsPage.component';
import EarningPage from '../../components/report/EarningPage.component';
import LeadManagement from '../../components/bulk-action/Lead-management.component';
import BulkAction from '../../components/bulk-action/Bulk-action.component';
import JobPostingsManagement from '../../components/jobs/Job-postings.component';
import HelpComponent from '../../components/help/help.component';
import MorePage from '../../components/account/MoreMenu.component.js';
import HomePage from '../../components/Home';
import SearchPage from '../../components/SearchPage';
import SearchTicket from '../../components/SearchTicket';
import NotificationTabs from '../../components/notification/NotificationTabs';
import trainingHubView from '../../components/trainingHub/trainingHubView.component'
import ConnectingCallScreen from '../../components/kaleyraCallComponents/connectingCall';
import CallStatusScreen from '../../components/kaleyraCallComponents/callStatus';
import ResetPayoutDashboardPasswordScreen from '../../components/common/resetPayoutDashboardPassword.component';
import ReferAndEarn from '../../components/refer-and-earn/referAndEarn';
import CustomCallNotes from '../../components/customCallNotes';
import NewOnboardingQuestions from '../../components/onboarding/onboardingQuestions';
import MLOnboarding from '../mlOnboarding/mlOnboarding.component';
import BasicInfoForm from '../../components/BasicInfoForm';
import NotFound from '../../components/404Page';
import TelecallerDashboard from '../leads/TelecallerDashboard.component';
import MissingDocuments from '../leads/MissingDocumets.component';
import AdditionalInfoScreen from '../additionalInfo/ZomatoAdditionalInfo/AdditionalInfoScreen.jsx';
import HotLead from '../leads/hotleads.component';
import PreReferralLead from '../leads/PreReferralLeadsPage.component';
import BlinkitAdditionalInfoContainer from '../additionalInfo/BlinkitAdditionalInfo/BlinkitAdditionalInfoContainer.jsx';
import BlinkitDarkStoreAdditionalInfoContainer from '../additionalInfo/BlinkitDarkStoreAdditionalInfo/BlinkitDarkStoreAdditionalInfoContainer.jsx'
import SwiggyAdditionalInfoContainer from '../additionalInfo/SwiggyAdditionalInfo/SwiggyAdditionalInfoContainer.jsx';
import BulkActionsContainer from '../BulkActionsNew/presentational/BulkActionsContainer.js';
import BulkReferralContainer from '../BulkActionsNew/presentational/BulkReferral/BulkReferralContainer.js';
import JobsPageContainer from '../JobsPageNew/components/JobsPageContainer.js';
import ClientDetailsContainer from '../JobsPageNew/components/JobRequirementsSection/ClientDetails/ClientDetailsContainer.js';
import PosterLeadsListing from '../JobsPageNew/components/LeadsFromPostersSection/PosterLeadsListing.js';
import SamvadiniMainContainer from '../Samvadini/SamvadiniMainContainer.jsx';
import IndividualFileResults from '../Samvadini/IndividualFileResults.jsx';
import ZeptoRecruitmentAdditionalInfoContainer from '../additionalInfo/ZeptoRecruitmentAdditionalInfo/ZeptoRecruitmentAdditionalInfoContainer.jsx';
import AccountContainer from '../account/NewAccountPage/AccountContainer.js';
import RolePermissionsContainer from '../account/NewAccountPage/AccountSection/AdminView/RolePermissions/RolePermissionsContainer.js';
import TeamDetailsContainer from '../account/NewAccountPage/AccountSection/AdminView/TeamDetails/TeamDetailsContainer.js';
import SamvaadiniMainContainerV2 from '../SamvaadiniV2/SamvaadiniMainContainer.jsx';
import LeadListOfASession from '../SamvaadiniV2/LeadListOfASession.jsx';
import SessionListV2 from '../SamvaadiniV2/SessionListV2.jsx';
import SamvaadiniCallingConsent from '../extras/SamvaadiniCallingConsent.jsx';
import PerformanceDashboardTeamLeaderboardDetails from '../report/PerformanceDashboardTeamLeaderboardDetails.jsx';
import SamvaadiniFileUploadContainer from '../SamvaadiniV2/SamvaadiniFileUploadContainer.jsx';
import SamvaadiniSmartLogicContainer from '../SamvaadiniV2/SamvaadiniSmartLogicContainer.jsx';
import SamvaadiniAdvanceFilterContainer from '../SamvaadiniV2/SamvaadiniAdvanceFilterContainer.jsx';
import HotlineLandingScreen from '../HotlineLandingScreen/HotlineLandingScreen.jsx';
import SwiggySocAdditionalInfo from '../additionalInfo/SwiggySOC/SwiggySocAdditionalInfo.jsx';

export const ROLES = {
  DC: 'dc',
  ML: 'ml',
};

export const ROUTES = {
  [ROLES.ML]: [
    {
      path: '/',
      component: LogInPage,
      exact: true,
    },
    {
      path: '/download-hotline-app',
      component: HotlineLandingScreen,
      exact: true,
    },
    {
      path: '/home',
      component: HomePage,
      exact: true,
    },
    {
      path: '/home/:tab',
      component: HomePage,
      exact: true,
    },
    {
      path: '/bulk-actions',
      component: BulkActionsContainer,
      exact: true,
    },
    {
      path: '/bulk-referral',
      component: BulkReferralContainer,
      exact: true,
    },
    {
      path: '/add-single-candidate',
      component: BasicInfoForm,
      exact: true,
    },
    {
      path: '/leads/:tab',
      component: LeadsPage,
      exact: true,
    },
    {
      path: '/hot-leads-candidates',
      component: HotLead,
      exact: true,
    },
    {
      path: '/hot-leads',
      component: HotLead,
      exact: true,
    },
    {
      path: '/pre-referral-leads-candidates',
      component: PreReferralLead,
      exact: true,
    },
    {
      path: '/recruiter',
      component: Recruiter,
      exact: true,
    },
    {
      path: '/recruiter-candidates',
      component: Recruiter,
      exact: true,
    },
    {
      path: '/telecallerDashboard',
      component: TelecallerDashboard,
      exact: true,
    },
    {
      path: '/lead-management/:tab',
      component: LeadManagement,
      exact: true,
    },
    {
      path: '/search-page',
      component: SearchPage,
      exact: true,
    },
    {
      path: '/notification',
      component: NotificationTabs,
      exact: true,
    },
    {
      path: '/search-ticket',
      component: SearchTicket,
      exact: true,
    },
    {
      path: '/report/:tab',
      component: ReportPage,
      exact: true,
    },
    {
      path: '/report/:tab/team-leaderboard',
      component: PerformanceDashboardTeamLeaderboardDetails,
      exact: true,
    },
    {
      path: '/leads-page',
      component: ReportLeadPage,
      exact: true,
    },
    {
      path: '/account/team-management',
      component: TeamManagement,
      exact: true,
    },
    {
      path: '/earnings',
      component: EarningPage,
      exact: true,
    },
    {
      path: '/account',
      component: AccountContainer,
      exact: true,
    },
    {
      path: '/team-details/:teamId',
      component: TeamDetailsContainer,
      exact: true,
  },
    {
      path: '/more',
      component: MorePage,
      exact: true,
    },
    {
      path: '/jobs',
      component: JobsPage,
      exact: true,
    },
    // {
    //   path: '/jobs',
    //   component: JobsPageContainer,
    //   exact: true,
    // },
    {
      path: '/moreInfo',
      component: AdditionalInfoPage1,
      exact: true,
    },
    {
      path: '/blinkit-additional-form',
      component: BlinkitAdditionalInfoContainer,
      exact: true,
    },
    {
      path: '/blinkit-darkstore-additional-form',
      component: BlinkitDarkStoreAdditionalInfoContainer,
      exact: true,
    },
    {
      path: '/zomato-additional-form',
      component: AdditionalInfoScreen,
      exact: true,
    },
    {
      path: '/swiggy-soc-additional-form',
      component: SwiggySocAdditionalInfo,
      exact: true,
    },
    {
      path: '/zepto-additional-form',
      component: ZeptoRecruitmentAdditionalInfoContainer,
      exact: true,
    },
    {
      path: '/samvadini',
      component: SamvadiniMainContainer,
      exact: true,
    },
    {
      path: '/samvadini-v2',
      component: SamvaadiniMainContainerV2,
      exact: true,
    },
    {
      path: '/samvadini-file-upload',
      component: SamvaadiniFileUploadContainer,
      exact: true,
    },
    {
      path: '/samvadini-smart-logic',
      component: SamvaadiniSmartLogicContainer,
      exact: true,
    },
    {
      path: '/samvadini-advance-filter',
      component: SamvaadiniAdvanceFilterContainer,
      exact: true,
    },
    {
      path: '/samvadini-session-details',
      component: LeadListOfASession,
      exact: true,
    },
    {
      path: '/samvadini-session-list',
      component: SessionListV2,
      exact: true,
    },
    {
      path: '/samvadini-processed-list',
      component: IndividualFileResults,
      exact: true,
    },
    {
      path: '/swiggy-additional-form',
      component: SwiggyAdditionalInfoContainer,
      exact: true,
    },
    {
      path: '/converted-leads',
      component: ConvertedLeadsPage,
      exact: true,
    },
    // {
    //   path: '/job-demands/:tabType',
    //   component: JobDemandsPage,
    //   exact: true,
    // },
    {
        path: '/job-posting/:posterId',
        component: JobPostingPage,
        exact: true,
    },
    {
      path: '/job-demands/job-requirements/:jobId',
      component: ClientDetailsContainer,
      exact: true,
    },
    {
      path: '/job-demands/leads-from-posters/:posterId',
      component: PosterLeadsListing,
      exact: true,
    },
    {
      path: '/job-demands/:tabType',
      component: JobsPageContainer,
      exact: true,
    },
    {
      path: '/upload-periscope-data',
      component: UploadPeriscopeDataPage,
      exact: true,
    },
    {
      path: '/upload-marketing-data',
      component: UploadMarketingDataPage,
      exact: true,
    },
    {
      path: '/upload-onboarding-data',
      component: UploadOnBoardingDataPage,
      exact: true,
    },
    {
      path: '/file-uploader',
      component: FileUploader,
      exact: true,
    },
    {
      path: '/signup',
      component: SignupPage,
      exact: false,
    },
    {
      path: '/account/upload-documents',
      component: UploadDocumentsPage,
      exact: true,
    },
    {
      path: '/terms-and-conditions',
      component: TermsAndConditions,
      exact: true,
    },
    {
      path: '/account/training-session',
      component: TrainingSessionPage,
      exact: true,
    },
    {
      path: '/bulk-action',
      component: BulkAction,
      exact: true,
    },
    {
      path: '/job-postings/:client/:id',
      component: JobPostingsManagement,
      exact: true,
    },
    {
      path: '/job-postings/:mitraName/:city/:salary/:client/:id',
      component: JobPostingsManagement,
      exact: true,
    },
    {
      path: '/team-leader-board',
      component: TeamLeaderBoard,
      exact: true,
    },
    {
      path: '/team-leads-page',
      component: TeamLeadspage,
      exact: true,
    },
    {
      path: '/incentives',
      component: Incentives,
      exact: true,
    },
    {
      path: '/incentives/termsAndConditions',
      component: IncentiveTermsAndConditions,
      exact: true,
    },
    {
      path: '/training-hub',
      component: trainingHubView,
      exact: true,
    },
    {
      path: '/help',
      component: HelpComponent,
      exact: true,
    },
    {
      path: '/connecting-call',
      component: ConnectingCallScreen,
      exact: true,
    },
    {
      path: '/call-status',
      component: CallStatusScreen,
      exact: true,
    },
    {
      path: '/refer-and-earn',
      component: ReferAndEarn,
      exact: true,
    },
    {
      path: '/custom-call-notes',
      component: CustomCallNotes,
      exact: true,
    },
    {
      path: '/onboarding-questions',
      component: NewOnboardingQuestions,
      exact: true,
    },
    {
      path: '/ml-onboarding',
      component: MLOnboarding,
      exact: true,
    },
    {
      path: '/rp/:tab',
      component: ResetPayoutDashboardPasswordScreen,
      exact: true,
    },
    {
      path: '/ai-call-consent',
      component: SamvaadiniCallingConsent,
      exact: true,
    },
    {
      path: '/other-docs',
      component: MissingDocuments,
      exact: true,
    },
    {
      path: '',
      component: NotFound,
      exact: false,
    },
  ],
  [ROLES.DC]: [
    {
      path: '/',
      component: LogInPage,
      exact: true,
    },
    {
      path: '/download-hotline-app',
      component: HotlineLandingScreen,
      exact: true,
    },
    {
      path: '/search-page',
      component: SearchPage,
      exact: true,
    },
    {
      path: '/moreInfo',
      component: AdditionalInfoPage1,
      exact: true,
    },
    {
      path: '/blinkit-additional-form',
      component: BlinkitAdditionalInfoContainer,
      exact: true,
    },
    {
      path: '/blinkit-darkstore-additional-form',
      component: BlinkitDarkStoreAdditionalInfoContainer,
      exact: true,
    },
    {
      path: '/zomato-additional-form',
      component: AdditionalInfoScreen,
      exact: true,
    },
    {
      path: '/swiggy-soc-additional-form',
      component: SwiggySocAdditionalInfo,
      exact: true,
    },
    {
      path: '/zepto-additional-form',
      component: ZeptoRecruitmentAdditionalInfoContainer,
      exact: true,
    },
    {
      path: '/samvadini',
      component: SamvadiniMainContainer,
      exact: true,
    },
    {
      path: '/samvadini-v2',
      component: SamvaadiniMainContainerV2,
      exact: true,
    },
    {
      path: '/samvadini-file-upload',
      component: SamvaadiniFileUploadContainer,
      exact: true,
    },
    {
      path: '/samvadini-smart-logic',
      component: SamvaadiniSmartLogicContainer,
      exact: true,
    },
    {
      path: '/samvadini-advance-filter',
      component: SamvaadiniAdvanceFilterContainer,
      exact: true,
    },
    {
      path: '/samvadini-session-details',
      component: LeadListOfASession,
      exact: true,
    },
    {
      path: '/samvadini-session-list',
      component: SessionListV2,
      exact: true,
    },
    {
      path: '/samvadini-processed-list',
      component: IndividualFileResults,
      exact: true,
    },
    {
      path: '/swiggy-additional-form',
      component: SwiggyAdditionalInfoContainer,
      exact: true,
    },
    {
      path: '/other-docs',
      component: MissingDocuments,
      exact: true,
    },
    {
      path: '',
      component: SearchPage,
      exact: false,
    }
  ]
};
