import React, {useEffect, useState, useContext} from 'react';
import PageHeaderComponent from '../common/PageHeader';
import {Tabs, Typography, Skeleton, notification, Button, message, Modal, Input, Empty, Dropdown, Space, Menu, Tooltip} from 'antd';
import {useHistory} from 'react-router-dom';
import * as fetchTermsAndConditions from '../../service/incentiveService.js';
import commonFunc from "../../utils/commonFunction";
import {AppContext} from "../../App";
import '../../css/incentives.scss';

const Incentives = () => {
    const [breadcrumb] = useState([{link: '/incentives', label: 'Incentives'}, {link: '/incentives/termsAndConditions', label: 'Terms & Conditions'}]);
    const history = useHistory();
    const [termsAndConditionsId] = useState(commonFunc.getAllsearchParams(history.location.search))
    const [content, setContent] = useState('');

    useEffect(() => {
        fetchTermsAndConditions.fetchTermsAndConditions(termsAndConditionsId).then((res) => {
            if (res.data.status) {
                setContent(res.data.data)
            }
        }).catch((err) => {
            console.log("err", err)
        })
    }, [])

    return (
        <>
            <div className="incentive-page-container">
				<div className="header">
                    <PageHeaderComponent backBtnBool={true} breadcrumb={breadcrumb} />
				</div>
                <div className="content">
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                </div>
            </div>
            
        </>
    );
};
export default Incentives;
