import React from "react";
import { Typography, Input } from 'antd';

const { Text } = Typography;

const CustomInput = (props) => {

    const { value, onChange, title, placeholder, isRequired, inputType, className } = props;

    return (
        <div className={className}>
            <Text className='form-input-fieldname'>
                {isRequired && <span className="requiredField">*</span>}
                {title}
            </Text>{
                inputType === 'text' ?
                    <Input
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        required={isRequired ? true : false}
                    />
                    :
                    inputType === 'textArea' ?
                        <Input.TextArea
                            value={value}
                            onChange={onChange}
                            placeholder={placeholder}
                        />
                        :
                        null
            }
        </div>
    );
}

export default CustomInput;