import React,{useState} from "react";
import {Input} from 'antd';
import {SearchOutlined } from '@ant-design/icons';

import '../../css/searchPage.scss';

const { Search } = Input;

const SearchComponent = ({searchList,placeholder}) => {
    const [opensearch, setopenSearch] = useState(false);
    placeholder = placeholder ? placeholder : "Input to search...";
    return <div className="searchPage">
        <Search className='desktop-version' placeholder={placeholder} allowClear onSearch={searchList} style={{width:250}} enterButton />
        <div className='mobile-version'>
            { opensearch &&
                <Input bordered={false} placeholder={placeholder} allowClear onChange={(e)=>searchList(e.target.value)} />
            }
            <SearchOutlined onClick={()=>{
                setopenSearch(!opensearch)
            }} />
        </div>
	</div>
}

export default SearchComponent;