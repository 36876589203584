import React,{useState} from "react";
import { Input , message} from 'antd';
import {SearchOutlined } from '@ant-design/icons';

const { Search } = Input;

const GlobalSearchComponent = ({searchItem, placeholder, disabled}) => {
    placeholder = placeholder ? placeholder : "Input to search...";

    const onSearchFunc=(e)=>{
            searchItem(e);
    }
        
    return <div className="searchPage">
        <Search className='desktop-version' placeholder={placeholder} allowClear onSearch={onSearchFunc} enterButton disabled={disabled ? disabled : false} />

	</div>
}

export default GlobalSearchComponent;