import axios from 'axios';
import axiosInstance from './interceptorService';

export const getRoleWiseCounts = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/teamRolesCount`;
    return axiosInstance.get(url, { withCredentials: true });
};

export const getOverallTeamCount = async (params, cancelToken) => {
    const query = Object.keys(params)
        .filter(key => params[key] !== undefined && params[key] !== null && params[key] !== '')
        .map(key => `${encodeURIComponent(key)}=${params[key]}`)
        .join('&');
    let urlWithParams = `${process.env.REACT_APP_BACKEND_URL}/overAllTeamData?${query}`
    return axiosInstance.get(urlWithParams, { withCredentials: true, cancelToken });
};

export const getFeatureList = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/featureList`
    return axiosInstance.get(url, { withCredentials: true });
};

export const postFeatureControlChanges = async (data) => {
    let updatedFeatureList = {}
    if (data) {
        updatedFeatureList = { featureList: data }
    }
    let url = `${process.env.REACT_APP_BACKEND_URL}/featureControl`
    return axiosInstance.post(url, updatedFeatureList, { withCredentials: true });
};

export const postTeamDetails = async (data) => {
    let url = process.env.REACT_APP_BACKEND_URL + '/createTeam';
    return axiosInstance.post(url, data, { withCredentials: true });
};

export const updateTeamDetails = async (data) => {
    let url = process.env.REACT_APP_BACKEND_URL + '/updateTeamDetails';
    return axiosInstance.post(url, data, { withCredentials: true });
};

export const getTeamMemberAccountDetails = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getTeamMemberAccountDetails`
    return axiosInstance.get(url, { withCredentials: true });
};

export const createOrUpdate = async (data) => {
    const formData = new FormData();
    delete data.data.container.experienceLevel;
    delete data.data.container.englishLevels;
    delete data.data.container.educationLevel;
    delete data.data.container.salaryLevel;
    for (const [key, value] of Object.entries(data)) {
        formData.set(key, JSON.stringify(value));
    }
    let url = process.env.REACT_APP_BACKEND_URL + '/lead/additionalInfo';
    return axiosInstance.post(url, formData, { withCredentials: true });
};

export const getMlLogoAndBrandName = async () => {
    let url = process.env.REACT_APP_BACKEND_URL + '/getMlLogoAndBrandName';
    return axiosInstance.get(url, { withCredentials: true });
};

export const saveMlLogoAndBrandName = async (data) => {
    let url = process.env.REACT_APP_BACKEND_URL + '/saveMlLogoAndBrandName';
    return axiosInstance.post(url, data, { withCredentials: true });
};

export const getVLBrandDetails = async (params) => {
    const query = Object.keys(params)
        .filter(key => params[key] !== undefined && params[key] !== null && params[key] !== '')
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');
    let urlWithParams = `${process.env.REACT_APP_BACKEND_URL}/getMlLogoAndBrandName?${query}`
    return axios.get(urlWithParams);
};

export const getDataForTeamLeads = async (params) => {
    const query = Object.keys(params)
        .filter(key => params[key] !== undefined && params[key] !== null && params[key] !== '')
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');
    let urlWithParams = `${process.env.REACT_APP_BACKEND_URL}/getTeamDataForTeamLead?${query}`

    return axiosInstance.get(urlWithParams, { withCredentials: true });
};