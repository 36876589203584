import React, { useContext, useEffect, useState } from "react";
import '../../css/hotleads.scss';
import { Modal, Button,Radio,Space, Input,Form, notification, Typography } from "antd";
import { PhoneOutlined } from '@ant-design/icons';
import { AppContext } from "../../App";
import {useHistory} from 'react-router-dom';
import {updateLastSeenStatus} from './../../service/leadService';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';
const { Text } = Typography;

const HotLeadsModal = ({showModal, setShowHotLeadsModal, mitraId}) => {
    const [pathNameCandidates, setPathNameCandidates] = useState(window.location.href.indexOf("candidates") > -1)
    const history = useHistory();
    const {mitraReducer,mitraDispatch} = useContext(AppContext);

    const onClickActionButton = (subModuleName) => {
      setShowHotLeadsModal(false);
      if(subModuleName === 'try') {
        trackEvent('try-hot-leads', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        history.push(pathNameCandidates ? {pathname: '/hot-leads-candidates'} : {pathname: '/hot-leads-candidates'})
      }
      const payload = {
        featureName: 'hotLeads',
        subModuleName: subModuleName,
        userId: mitraId
      }
      updateLastSeenStatus(payload).then(result => {
        if(result && result.data) {
          console.log(result.data);
        }
      })
    }
      return (
        <Modal
        className="hot-leads-modal-style"
        style={{ textAlign: 'left' }}
        title={<span>Find leads who are <b>5x times</b> more likely to get converted! <img src="/image/hot-leads.png"/></span>}
        visible= {showModal}
        footer={[
        <div className="d-flex justify-content-between parent-footer" key='CTA'>
          <Button onClick={()=>{onClickActionButton('remind')}} className="remind-me-later"
          type="primary" ghost>
            <b>Remind Me Later</b>
          </Button>
          <Button onClick={()=>{onClickActionButton('try')}} className="try-it-now"
          type="primary">
            <Text style={{ color: '#fff' }}>Try It Now</Text>
          </Button>
        </div>
        ]}
      >
        <Space className="header-hot-leads-modal" direction='vertical' style={{width:'100%'}}>
        <div className="d-flex" >
          <img className="main-container-images" src="/image/main-hot-leads.png"/>
          <Text><b>Vahan</b> has developed this <b>AI filter which automatically tracks and tags leads</b> to follow up who are up to 5x time more likely to get converted. This is a <b>premium feature</b> to help you achieve your milestones faster and earn more incentives. <br></br><br></br> Would you like to try it now?</Text>
        </div>
            {/* <img className="main-container-images" src="/image/main-hot-leads.png"/> */}
        </Space>
        <div className="header-hot-leads-modal">
            <img className="priority-image" src="/image/list-of-hot-leads.png"/>
        </div>
      </Modal>
    )
};

export default HotLeadsModal;
