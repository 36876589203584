import React, { useState, useContext } from 'react';
import { InfoCircleOutlined, CheckCircleOutlined, MessageOutlined, ReloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, notification } from 'antd';
import '../../css/ticket.scss';
import Chat from './chat.component';
import * as ticketService from '../../service/ticketService';
import { rollOutConversationHistory } from '../../constants/utils';
import { AppContext } from '../../App';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';
const { confirm } = Modal;

const Ticket = ({ ticket, getIssues }) => {
  const [chatModal, setChatModal] = useState(false);
  const [freshdeskData, setfreshdeskData] = useState([]);
  const { mitraReducer } = useContext(AppContext);

  const chatModalCancel = () => {
    setChatModal(false);
  };

  const getConversation = () => {
    ticketService.getAllConversations(ticket).then((response) => {
      setfreshdeskData(response.data);
    });
  }

  const openNotification = () => {
    notification.info({
      message: `Ticket No. ${ticket.ticketId} Re-Opened`,
      style: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: 'bold'
      },
      placement: 'top',
    });
  };

  const showConfirmationPopup = (ticket) => {
    confirm({
      title: 'Are you sure Re-Open this Ticket?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      className: 're-open-ticket-popup',
      closable: true,
      bodyStyle: { fontWeight: 'bold', lineHeight: '1' },
      onOk() {
        ticketService.reOpenTicket(ticket).then(res => {
          if (res.status === 201) {
            getIssues();
            openNotification();
          }
        })
      },
      onCancel() {
      },
    });
  };

  return (
    <div className="detail-body-container">
      {chatModal && <Chat
        freshdeskData={freshdeskData}
        ticket={ticket}
        getConversation={getConversation}
        chatModal={chatModal}
        chatModalCancel={chatModalCancel}
      ></Chat>
      }
      <div className="card-container">
        <div className="issue-status">
          Issue Status
          {ticket.status === 'Resolved' ? <Button className='green'><CheckCircleOutlined /> {ticket.status}</Button>
            : ticket.status === 'Closed' ? <Button className='grey'><InfoCircleOutlined /> {ticket.status}</Button>
              : <Button><InfoCircleOutlined /> {ticket.status}</Button> }
          {
            rollOutConversationHistory(mitraReducer.mitraInfo) && ["Resolved", "Closed"].includes(ticket.status) &&
            <Button className='re-open' onClick={() => {trackEvent('click_reopen_ticket_help', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);showConfirmationPopup(ticket)}}>
              <ReloadOutlined /> Re - Open Ticket
            </Button>
          }
          <div className='ticket-details'>
            <span>Ticket No. {ticket.ticketId}</span>
            <span>Ticket Created on {ticket.createdDate}</span>
            <span>Ticket Created by {ticket.name}</span>
          </div>
        </div>
        <div className="candidate-details">
          <span>Candidate Phone Number <span className='ticket'> {ticket.candidatePhoneNumber}</span></span>
          <span>Client <span className='ticket'> <img className="imgResponse" alt={ticket.clientName} src={"/image/" + ticket.clientName + ".png"} /></span></span>
          <span className='more-width'>Issue Type <span className='ticket bold'> {ticket.issueType}</span></span>
          <span>Issue Details <span className='ticket'> {ticket.description}</span></span>
          <span className='more-width'>Resolution Summary <span className='ticket bold'> {ticket.resolutionSummary ? ticket.resolutionSummary : '--'}</span></span>
          <span>Action Needed <span className='ticket'> {ticket.actionNeeded ? ticket.actionNeeded : '--'}</span></span>
        </div>
        {
          rollOutConversationHistory(mitraReducer.mitraInfo) && <Button className='chat-button' onClick={() => { trackEvent('click_conversation_history_help', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);setChatModal(true);getConversation() }}><MessageOutlined /> Conversation History</Button>
        }
      </div>
    </div>
  );
};
export default Ticket;
