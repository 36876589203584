import React, { useEffect, useContext } from 'react'
import { Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import './TeamMemberViewStyle.scss'
import '../../../../../css/globalStyles.scss'
import { AppContext } from '../../../../../App';

const TeamMemberView = ({ source }) => {
    const { mitraReducer } = useContext(AppContext);

    useEffect(() => {
        if (mitraReducer)
            console.log(mitraReducer?.mitraInfo)
    }, [mitraReducer])

    const getRoleLabel = (roleKey) => {
        switch (roleKey) {
            case "admin":
                return "Admin"
            case "teamLeader":
                return "TL (Team Leader)"
            case "teamMember":
                return "TC (Telecaller)"
        }
    }

    return (
        <div className={`team-member-view-parent ${source !== "TeamLeaderView" ? "team-member-view-parent-padding" : null}`}>
            <div className='tmv-header d-flex'>
                <div className='d-flex align-items-center'>
                    <UserOutlined className='tmv-header-icon' />
                    <span className='tmv-header-txt semibold16-24 black85'>
                        {source == "TeamLeaderView" ? "Team Member Profile" : "Member Profile"}
                    </span>
                </div>
                <div className='tmv-sub-txt regular14-22 black45'>
                    Your registered personal details
                </div>
            </div>

            {
                mitraReducer && mitraReducer.mitraInfo ?
                    <div className='tmv-body'>
                        <div className='d-flex tmv-input-container'>
                            <span className='tmv-input-label d-flex justify-content-start align-items-center'>
                                Name
                            </span>
                            <Input
                                className='tmv-input tmv-name-input'
                                value={mitraReducer?.mitraInfo.name || 'NA'}
                                disabled
                            />
                        </div>

                        <div className='d-flex tmv-input-container'>
                            <span className='tmv-input-label d-flex justify-content-start align-items-center'>
                                Mobile
                            </span>
                            <Input
                                className='tmv-input tmv-name-input'
                                value={mitraReducer?.mitraInfo.phoneNumber || 'NA'}
                                disabled
                            />
                        </div>

                        <div className='d-flex tmv-input-container'>
                            <span className='tmv-input-label d-flex justify-content-start align-items-center'>
                                Email
                            </span>
                            <Input
                                className='tmv-input tmv-name-input'
                                value={mitraReducer?.mitraInfo.email || 'NA'}
                                disabled
                            />
                        </div>

                        <div className='d-flex tmv-input-container'>
                            <span className='tmv-input-label d-flex justify-content-start align-items-center'>
                                Role
                            </span>
                            <Input
                                className='tmv-input tmv-name-input'
                                value={getRoleLabel(mitraReducer?.mitraInfo.role) || 'NA'}
                                disabled
                            />
                        </div>

                        

                        <div className='d-flex tmv-input-container'>
                            <span className='tmv-input-label d-flex justify-content-start align-items-center'>
                                Team
                            </span>
                            <Input
                                className='tmv-input tmv-name-input'
                                value={mitraReducer?.mitraInfo.team || 'NA'}
                                disabled
                            />
                        </div>
                    </div>
                    :
                    null
            }

        </div>
    )
}

export default TeamMemberView; 