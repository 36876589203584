import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { Divider, Button, Dropdown, Menu, Pagination, Skeleton, Empty } from 'antd';
import { PlusOutlined, ArrowDownOutlined, ArrowUpOutlined, EditOutlined, DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import './TeamDetailsContainerStyle.scss'
import '../../../../../../css/globalStyles.scss'
import PageHeaderComponent from '../../../../../common/PageHeader';
import TeamListItem from '../TeamListItem';
import CreateEditTeamModal from '../CreateTeam/CreateEditTeamModal';
import AccountSidesheet from '../../../common/AccountSidesheet';
import { getOverallTeamCount } from '../../../../../../service/accountService';
import axios from 'axios';
import { trackEvent } from '../../../../../../service/engagementMonitoringService';
import CONSTANTS from '../../../../../../constants/constants';

const TeamDetailsContainer = ({ }) => {
    const location = useLocation()
    const [currentTeamDetails, setCurrentTeamDetails] = useState(
        {
            team: "",
            teamId: "",
            team_leader: "",
            tlCount: 0,
            tlName: "",
            tcCount: 0,
            teamLeaderId: "",
            createdOn: "",
        }
    )
    const [breadcrumb, setBreadcrumb] = useState([{ link: '/account', label: 'Account' }, { link: '/account', label: 'Branding And Team' }]);
    const [teamMembersListCopy, setTeamMembersListCopy] = useState([])
    const [selectedStatus, setSelectedStatus] = useState("Role")
    const [selectedStatusOrder, setSelectedStatusOrder] = useState("desc")
    const [totalTeamMembersList, setTotalTeamMembersList] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const statusMenu = (
        <Menu className='jdc-menu'>
            <Menu.Item key="1" className='jdc-menu-item' onClick={() => { onStatusFilterApplied("role", "desc") }}>
                <ArrowDownOutlined className='jdc-menu-item-icon' /> Roles
            </Menu.Item>
            <Menu.Item key="2" className='jdc-menu-item' onClick={() => { onStatusFilterApplied("role", "asc") }}>
                <ArrowUpOutlined className='jdc-menu-item-icon' /> Roles
            </Menu.Item>
            {/* <Menu.Item key="3" className='jdc-menu-item' onClick={() => { onStatusFilterApplied("status", "desc") }}>
                <ArrowDownOutlined className='jdc-menu-item-icon' /> Status
            </Menu.Item>
            <Menu.Item key="4" className='jdc-menu-item' onClick={() => { onStatusFilterApplied("status", "asc") }}>
                <ArrowUpOutlined className='jdc-menu-item-icon' /> Status
            </Menu.Item> */}
        </Menu>
    );
    const [showEditTeamModal, setShowEditTeamModal] = useState(false)
    const [currentTLDetails, setCurrentTLDetails] = useState({
        name: "",
        id: "",
        tcCount: 0
    })
    const [showAddMemberSidebar, setShowAddMemberSidebar] = useState(false);
    const [newTeamMemberDetails, setNewTeamMemberDetails] = useState(
        {
            "id": "",
            "name": "",
            "phoneNumber": "",
            "photoUrl": null,
            "status": "",
            "dateOfJoining": null,
            "createdAt": "",
            "updatedAt": "",
            "deletedAt": null,
            "type": "",
            "sourcingCities": null,
            "sourcingLocalities": null,
            "homeCity": null,
            "managerMitraID": "",
            "teamLeadMitraID": null,
            "role": "teamMember",
            "email": null,
            "optInInfo": null,
            "accountManagerId": null,
            "acquisitionSource": "",
            "password": null,
            "bridgeNumber": null,
            "placementCountDivision": null
        }
    )
    const [filtersList, setFiltersList] = useState({
        limit: 10,
        offset: 0,
        search: "",
        teamListOnly: true,
        sortBy: "role desc",
        teamId: location.pathname.split('/')[location.pathname.split('/').length - 1]
    })
    const [loading, setLoading] = useState(false);
    const [currentTeamId, setCurrentTeamId] = useState("");

    useEffect(() => {
        const teamId = location.pathname.split('/');
        setCurrentTeamId(teamId[teamId.length - 1])
        fetchMembersList()
    }, [])

    useEffect(() => {
        fetchMembersList();
    }, [filtersList])

    useEffect(() => {
        if (currentPage)
            fetchMembersList();
    }, [currentPage])

    const onEditTeamClicked = () => {
        setShowEditTeamModal(true);
    }

    const onAddTeamMemberClicked = () => {
        setNewTeamMemberDetails({
            "id": "",
            "name": "",
            "phoneNumber": "",
            "photoUrl": null,
            "status": "",
            "dateOfJoining": null,
            "createdAt": "",
            "updatedAt": "",
            "deletedAt": null,
            "type": "",
            "sourcingCities": null,
            "sourcingLocalities": null,
            "homeCity": null,
            "managerMitraID": "",
            "teamLeadMitraID": null,
            "role": "teamMember",
            "email": null,
            "optInInfo": null,
            "accountManagerId": null,
            "acquisitionSource": "",
            "password": null,
            "bridgeNumber": null,
            "placementCountDivision": null
        })
        setShowAddMemberSidebar(true);
    }

    const updateFiltersList = (updateKey, updateVal) => {
        let tempFilterList = filtersList;
        tempFilterList[updateKey] = updateVal;
        setFiltersList(JSON.parse(JSON.stringify(tempFilterList)))
    }

    const fetchMembersList = () => {
        setLoading(true)
        let offset = currentPage == 1 ? 0 : ((currentPage - 1) * 10)
        const queryParams = {
            limit: filtersList.limit,
            offset: offset,
            search: filtersList.search ? filtersList.search : "",
            teamListOnly: true,
            // type: "teams",
            sortBy: filtersList.sortBy ? filtersList.sortBy : "",
            // teamId: currentTeamId ? currentTeamId : location.pathname.split('/')[location.pathname.split('/').length - 1]
            teamId: filtersList.teamId
        };

        getOverallTeamCount(queryParams).then((teamListRes) => {
            if (teamListRes?.data?.teamList) {
                setLoading(false)
                setBreadcrumb([{ link: '/account', label: 'Account' }, { link: '/account', label: 'Branding And Team' }, { link: `/team-details/${filtersList.teamId}}`, label: `${teamListRes.data.teamDetails.team}` }])
                setCurrentTeamDetails(JSON.parse(JSON.stringify(teamListRes.data.teamDetails)))
                setTeamMembersListCopy(teamListRes.data.teamList)
                setTotalTeamMembersList(teamListRes.data.totalCount)
                if (teamListRes?.data?.teamDetails) {
                    setCurrentTLDetails({
                        name: teamListRes.data.teamDetails.tlName,
                        id: teamListRes.data.teamDetails.teamId,
                        tcCount: teamListRes.data.teamDetails.tcCount
                    })
                }
            }
        })
    }

    const onStatusFilterApplied = (status, order) => {
        trackEvent('team_details_sort_applied', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        setSelectedStatus(status)
        setSelectedStatusOrder(order)
        updateFiltersList("sortBy", `${status} ${order}`)
    }

    return (
        <div className='team-details-container-parent'>
            <div className='tdc-header'>
                <div className="containerTop">
                    <div className="tdc-breadcrumb-header header">
                        <>
                            <PageHeaderComponent backBtnBool={true} breadcrumb={breadcrumb} />
                        </>
                    </div>
                </div>
                <Divider />
            </div>

            {
                currentTeamDetails ?
                    <div>
                        <div className='tdc-team-details-container d-flex justify-content-between align-items-center'>
                            <div className='tdc-team-details-txt-container d-flex align-items-start'>
                                <span className='bold24-32 black85'>
                                    {currentTeamDetails.team ? currentTeamDetails.team : "No Name provided"}
                                </span>
                                <span className='bold12-20 black45'>
                                    {currentTeamDetails.createdOn ? `created on ${moment(currentTeamDetails.createdOn).format("Do MMM YYYY")}` : null}
                                </span>
                            </div>
                            <div className='tdc-team-details-btns-container d-flex justify-content-between'>
                                <Button onClick={() => onEditTeamClicked()} style={{ marginRight: 5 }} className='tdc-edit-btn vl-secondary-btn d-flex align-items-center justify-content-center' disabled={currentTeamDetails?.team == "No Team Assigned"}>
                                    <EditOutlined className='tdc-btn-delete-icon' />
                                    <span className='bold14-22 primary-orange2'>Edit Team</span>
                                </Button>
                                <Button onClick={() => onAddTeamMemberClicked()} className={"tdc-add-btn vl-primary-btn d-flex align-items-center justify-content-center"} type="primary">
                                    <PlusOutlined className='tds-btn-add-icon' />
                                    <span className='bold14-22 plain-white'>Add Team Member</span>
                                </Button>
                            </div>
                        </div>

                        <div className='tdc-tl-tc-info-container d-flex justify-content-between'>
                            <div>
                                {
                                    currentTeamDetails.tlName ?
                                        <div className='d-flex'>
                                            <span className='tdc-tl-name blue-secondary2 semibold14-22'>TL</span> <span className='tdc-tl-name-value regular14-22 black65'>{currentTeamDetails.tlName}</span>
                                        </div>
                                        :
                                        null
                                }

                            </div>
                            <div>
                                {
                                    currentTeamDetails.tcCount && teamMembersListCopy.length > 0 ?
                                        <div className='d-flex'>
                                            <span className='tdc-tc-count semibold14-22 green-positive'>TCs</span> <span className='tdc-tc-count-value regular14-22 black65'> {currentTeamDetails.tcCount}</span>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>

                    </div>
                    :
                    null
            }

            <div className='tdc-body-container'>
                {
                    !loading ?
                        teamMembersListCopy.length ?
                            <div>
                                <div className='tdc-sort-pagination-container d-flex align-items-center justify-content-between'>
                                    <div className='tdc-sort-parent d-flex'>
                                        <div className='regular 14-22 black85'>
                                            Sort by
                                        </div>
                                        <div className='tdc-sort-container d-flex align-items-center jp-cp'>
                                            <Dropdown overlay={statusMenu} trigger={['hover']}>
                                                <div className='tdc-sort d-flex align-items-center'>
                                                    {
                                                        selectedStatusOrder == "desc" ?
                                                            <ArrowDownOutlined  className='red-negative'/>
                                                            :
                                                            selectedStatusOrder == "asc" ?
                                                                <ArrowUpOutlined  className='red-negative'/>
                                                                :
                                                                null
                                                    }
                                                    <span className='bold14-22 primary-orange2 tdc-sort-txt'>
                                                        {selectedStatus}
                                                    </span>
                                                    <DownOutlined className='tdc-sort-action-small-icon red-negative' />
                                                </div>
                                            </Dropdown>
                                        </div>
                                    </div>

                                    <Pagination
                                        total={totalTeamMembersList}
                                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} members`}
                                        defaultPageSize={10}
                                        defaultCurrent={currentPage}
                                        responsive={true}
                                        showLessItems={true}
                                        onChange={(page) => { setCurrentPage(page) }}
                                        showSizeChanger={false}
                                    />
                                </div>

                                <div className='tdc-team-members-list-container'>
                                    {teamMembersListCopy && teamMembersListCopy.length > 0
                                        ?
                                        teamMembersListCopy.map((member, memberInd) => {
                                            return (
                                                <div key={memberInd}>
                                                    <TeamListItem listItem={member} updatedMemberDetails={fetchMembersList} role={"all"} />
                                                </div>
                                            )
                                        }) : null}
                                </div>
                            </div>
                            :
                            <Empty />
                        :
                        <Skeleton />
                }
            </div>
            {
                showEditTeamModal ?
                    <CreateEditTeamModal isModalOpen={showEditTeamModal} closeModal={() => setShowEditTeamModal(false)} mode={"edit"} currentTeamName={currentTeamDetails.team} currentTL={currentTLDetails} currentTCList={teamMembersListCopy} teamCreated={fetchMembersList} />
                    :
                    null
            }
            {showAddMemberSidebar &&
                <AccountSidesheet memberDetails={newTeamMemberDetails} isDrawerOpen={showAddMemberSidebar} closeDrawer={() => setShowAddMemberSidebar(false)} mode={"add"} memberDetailsUpdated={fetchMembersList} teamName={currentTeamDetails.team} teamId={currentTeamDetails.teamId} source={"TeamDetailsContainer"}/>
            }
        </div>

    )
}

export default TeamDetailsContainer; 