import React, { useContext, useEffect, useState } from 'react'
import '../styles/VerificationStep.css'
import { Button, Input, Upload, notification } from 'antd'
import { UploadOutlined,CheckCircleOutlined } from '@ant-design/icons';
import { AppContext } from '../../../../App';
import { postAdditionalInfo, updateZomatoData, uploadImageToS3 } from '../../../../service/additionalInfoService';
import { useHistory } from "react-router-dom";
import RiderCompletedBanner from './RiderCompletedBanner';

function UploadSelfie({goToNextScreen,lead, leadAdditionalDetails, getLeadInformation, setShowPrompt, showBannerForScreen}) {
  const [selfie, setSelfie] = useState();
  const {mitraReducer,mitraDispatch, setAlert,setSpin } = useContext(AppContext);
  const [showStepCompletedSection, setShowStepCompletedSection] = useState(false);
  const history = useHistory()
  const [riderHasCompletedTheStep, setRiderHasCompletedTheStep] = useState(false);

  const showLoader = (value) => {
    setSpin({
        loading: value, //false or true when actived
        delay: null,
        tip: 'Uploading...'  //Loading copy here
    })
}

const checkErrors = (res) => {
  const newErrors = [];
  for (let key in res.data) {
      if (res.data[key] !== "200") {
          newErrors.push(`${key}: ${res.data[key]}`)
      }
  }

  if(newErrors.length) {
      // setErrors([...newErrors]);
      alert(newErrors.join("\n"))
      return true;
  } 
  return false;
}

  const handleBeforeUpload = (file) => {

    // Return false to prevent upload
    return false;
  };

  const handleUpload =(info) => {
    showLoader(true);

    uploadImageToS3(info.file)
        .then(async(res)=> {
            showLoader(false);

            if(res?.data?.imageUrl) {
              const submissionData = {
                userSelfie: {
                    url: res.data.imageUrl,
                    originalName: info?.file?.name,
                    size: info?.file?.size
                }
              }
        
              const response = await postAdditionalInfo(submissionData, mitraReducer?.mitraInfo, lead);
        
              if(!checkErrors(response)) {
                setSelfie(res.data.imageUrl);
                setShowPrompt(false);
                setRiderHasCompletedTheStep(false);
              }
                
            }
        }).catch(e=> {
            showLoader(false);

            notification['error']({
                message: 'Image upload failed, please try again later.',
              });

            console.log(e);
        })
};

  const submitSelfie = async() => {
    if(riderHasCompletedTheStep) {
      goToNextScreen();
      return
    }

    if(!selfie) {
      notification['error']({
        message: 'Please add selfie to continue',
      });
    return
    } else {

      const response = await updateZomatoData({
        phoneNumber: lead.phoneNumber,
        dataToUpdate: {
            uploadSelfie: {
                imageUrl: selfie
            }
        }
    });

    setShowPrompt(false);

      getLeadInformation();
      goToNextScreen();
    }
  }

  useEffect(() => {
    setRiderHasCompletedTheStep(showBannerForScreen('selfieUpload'));
    if(leadAdditionalDetails?.userSelfie?.[0]?.url || leadAdditionalDetails?.passportPhoto?.[0]?.url) {
      // since we donot get the photo uploaded info from zomato, populating it from the latest uploaded info from out DB

      if(leadAdditionalDetails?.userSelfie?.[0]?.url) {
        setSelfie(leadAdditionalDetails?.userSelfie?.[0]?.url);
      } else {
        setSelfie(leadAdditionalDetails.passportPhoto[0].url);
      }
      setShowStepCompletedSection(true);
      setShowPrompt(false);
    }
  }, [leadAdditionalDetails])
  

  return (
    <>

      {
          showStepCompletedSection && !riderHasCompletedTheStep ? (
              <div style={{display: 'flex', alignItems: 'center', gap: '6px', marginTop: '16px', fontWeight: 700}}>
                  <CheckCircleOutlined style={{color: '#4BB543'}}/>
                  User selfie successfully submitted to Zomato.
              </div>
          ): null
      }

      {
          riderHasCompletedTheStep ? <RiderCompletedBanner />: null
      }

      <div className='upload-selfie-container'>
        <div className='container-header'>Selfie Verification</div>

        <div className='selfie-form'>
          <div className="aadhar-steps-header">Note</div>
          <ul>
            <li style={{textAlign: 'start'}}>Please upload the correct selfie of the rider as the same image will be used to for verification during Gig attendance</li>
          </ul>

          <img src='/image/zomato-ob-selfie.png' height={'auto'} width={'250px'} style={{resizeMode: 'contain'}} />

          <div className='label mt-24'>Selfie<sup>*</sup></div>
          
                {
                  selfie ? (
                      <div style={{display: 'flex', gap: '8px'}}>
                          <img src={selfie} width={150} height={150}/>
                          <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>setSelfie(null)}>x</p>
                      </div>
                  ) : (
                      <Upload
                          name="avatar"
                          listType="picture-card"
                          showUploadList={false}
                          accept="image/*"
                          onChange={handleUpload}
                          beforeUpload={handleBeforeUpload}
                          >
                          <div>
                              <Input type="file" accept="image/*" style={{ display: 'none' }} />
                              <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                          </div>
                      </Upload>
                  )
              }
        </div>

      </div>

      <div className='button-container'>
            <Button className='submit-button' onClick={()=>submitSelfie()}>Next</Button>
        </div>
    </>
  )
}

export default UploadSelfie
