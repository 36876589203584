import React, { useState, useEffect, useContext } from 'react';
import { TextField } from '@rmwc/textfield';
import '@rmwc/textfield/styles';
import { Button } from '@rmwc/button';
import '@rmwc/button/styles';
import axios from 'axios';
import Home from '../Home';
import '../../css/login.scss';
import '../../css/globalStyles.scss';
import * as loginService from '../../service/loginService';
import { logEvent } from '../../service/tracking';
import ClipLoader from 'react-spinners/ClipLoader';
import trackingConstants from '../../constants/trackingConstants';
import { NavLink, Redirect, useHistory, useLocation } from "react-router-dom";
// import useMoengage from '../../hooks/useMoengage';
import { createMoengageUser } from '../../service/engagementMonitoringService';
import { AppContext } from "../../App";
import { Skeleton, notification } from 'antd';
import * as queryString from 'query-string';
import { ROLES } from '../common/roles';
import { getVLBrandDetails } from '../../service/accountService';
import CONSTANTS from '../../constants/constants';
import axiosInstance from '../../service/interceptorService';

const LogInPage = (props) => {
	const { mitraDispatch, setUserRole, mitraReducer } = useContext(AppContext);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [phoneNumberVerified, setPhoneNumberVerified] = useState(null);
	const [otpSent, setOtpSent] = useState(null);
	const [otpVerified, setOtpVerified] = useState(null);
	const [isMitraAuthorized, setIsMitraAuthorized] = useState(false);
	const [otp, setOtp] = useState('');
	const [mitra, setMitra] = useState(null);
	const [validSession, setValidSession] = useState(false);
	const [loading, setLoading] = useState(true);
	const [phoneNumberTextErrorMsg, setPhoneNumberTextErrorMsg] = useState(null);
	const [otpTextErrorMsg, setOtpTextErrorMsg] = useState(null);
	const history = useHistory();
	const location = useLocation();
	const [brandName, setBrandName] = useState("");
	const [isCustomURL, setIsCustomURL] = useState(false);
	const [customBrandDetails, setCustomBrandDetails] = useState({
		brandName: "",
		logo: "",
		brandLink: ""
	});

	const goToOnboardingQuestionsScreen = () => {
		history.push('/onboarding-questions');
	}

	useEffect(() => {
		const parsedUrl = queryString.parse(window.location.search);
		if (parsedUrl && parsedUrl.accountManagerAccessToken) {
			loginService.validateAccountManagerLogin(parsedUrl.accountManagerAccessToken)
				.then((response) => {
					localStorage.setItem("payoutAccessToken", parsedUrl.accountManagerPayoutAccessToken)
					notification['warning']({
						message: 'This is a READ ONLY access !!'
					});
					sessionStorage.setItem('isGodMode', 'true');
					history.push('/home');
				})
				.catch((error) => 1);
		}
		if (parsedUrl && parsedUrl.dCAccessToken) {
			loginService.validateDCLogin(parsedUrl.dCAccessToken)
				.then((response) => {
					
					localStorage.setItem("isFromSuiteCrm", true);
					setUserRole(ROLES.DC);
					localStorage.setItem('currentPhoneNumber', response?.data?.user?.phoneNumber);
					localStorage.setItem('suiteCrmId', response?.data?.suiteCrmId);
					notification['warning']({
						message: 'This is a Candidate specific access !!'
					});
					history.push({
						pathname: '/search-page',
						state: {
							candidatePhoneNumber: response?.data?.user?.phoneNumber,
						}
					})
				})
				.catch((error) => 1);
		}
		logEvent('OpenLoginScreen', trackingConstants.EVENT_GROUP_LOGIN, trackingConstants.EVENT_SCREEN_LOGIN);
		loginService
			.validateSession()
			.then((response) => {
				localStorage.setItem("mitraId", response.data.mitra.id)
				setMitra(response.data.mitra);
				setValidSession(true);
				
				if (!response.data.mitra.isFeedBackCompleted) goToOnboardingQuestionsScreen();
			})
			.catch((error) => 1);

		//Added to show and hide nav bar
		// mitraDispatch({type:'navigationDisplay',value:false});
		// return () => {
		// 	mitraDispatch({type:'navigationDisplay',value:true}); // Show when component is unmounted
		// };

		setTimeout(() => {
			setLoading(false)
		}, 1000);
	}, []);

	// To determine whether VL has used a personalised URL - If so, hide sign up option and show VL's brand logo instead of Vahan's
	useEffect(() => {
		if (window.location.hostname) {
			setBrandName(window.location.hostname)
			const showBranding = CONSTANTS.NON_CUSTOM_DOMAINS.some(term => window.location.hostname.includes(term))
			if (!showBranding) {
				setIsCustomURL(true);
				fetchCustomBrandDetails()
			}
		}
	}, [])

	const sanitisePageURL = (urlStr) => {
		// To not send 'https://' and last '/' in getVLBrandDetails payload
		return urlStr.replace(/^https:\/\//, '').replace(/\/$/, '');
	}

	const fetchCustomBrandDetails = () => {
		let params = {
			brandLink: sanitisePageURL(window.location.href)
		}

		getVLBrandDetails(params).then((brandDetailsRes) => {
			if (brandDetailsRes?.data) {
				mitraDispatch({ type: 'CUSTOM_BRAND_DETAILS', value: brandDetailsRes?.data });
				setCustomBrandDetails(brandDetailsRes.data.data)
				setFavicon(brandDetailsRes?.data?.data?.logo)
				document.title = brandDetailsRes?.data?.data?.brandName
			}
		})
	}

	const setFavicon = (url) => {
		if (url) {
			let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
			link.type = 'image/x-icon';
			link.rel = 'shortcut icon';
			link.href = url;

			// Remove existing favicon link if any and append the new one
			const head = document.getElementsByTagName('head')[0];
			const existingLink = head.querySelector("link[rel*='icon']");
			if (existingLink) {
				head.removeChild(existingLink);
			}
			head.appendChild(link);
		}
	}

	useEffect(() => {
		if (mitraReducer?.mitraInfo?.id) {
			setValidSession(true);
		}
		
	}, [mitraReducer])

	// const [createMoengageUser, trackEvent] = useMoengage();

	const initializeThirdPartyEngagements = (response) => {
		// MoEngage
		createMoengageUser(response.data.mitra);
	};

	const verifyOTP = (e) => {
		logEvent('LoginSuccessful', trackingConstants.EVENT_GROUP_LOGIN, trackingConstants.EVENT_SCREEN_LOGIN);
		loginService
			.verifyOTP(phoneNumber, otp)
			.then((response) => {
				if (response.data.otpVerified) {
					console.log('mitraOTPLogin', response.data.mitra)
					setMitra(response.data.mitra);

					initializeThirdPartyEngagements(response);

					setOtpVerified(true);
					loginService
						.validateSession()
						.then((response) => {
							localStorage.setItem("mitraId", response.data.mitra.id)
							setIsMitraAuthorized(true);
							if (location.state && location.state.redirectTo) {
								history.push(location.state.redirectTo);
							}
						})
						.catch((error) => 1);
				} else if (!response.data.otpVerified) {
					setOtpTextErrorMsg('Wrong or Expired OTP');
				} else if (response.status === 404) {
					setOtpTextErrorMsg('Service Unavailable');
				} else {
					setOtpTextErrorMsg('OTP could not be verified');
				}
			})
			.catch((error) => {
				setOtpVerified(false);
				setOtpTextErrorMsg('Service Unavailable');
				console.log('Error verifying OTP for: ', phoneNumber, otp, error);
			});
	};

	const handleOTPChange = (event) => {
		setOtp(event.target.value);
	};

	const sendOTP = async (phoneNumber, sendOTPtoEmail = false) => {
		setPhoneNumberTextErrorMsg(null);
		if (phoneNumber.length === 10) {
			try {
				const data = new FormData();
				data.append('phoneNumber', phoneNumber);
				data.append('sendOTPtoEmail', sendOTPtoEmail);
				let url = process.env.REACT_APP_BACKEND_URL + '/sendOtp';
				const response = await axiosInstance.post(url, data);
				if (response.status === 404) {
					setPhoneNumberTextErrorMsg('Service Unavailable');
				} else if (response.status === 210) {
					setOtpSent(true);
					setPhoneNumberVerified(true);
				} else {
					setPhoneNumberTextErrorMsg('OTP could not be sent');
				}
			} catch (error) {
				setPhoneNumberTextErrorMsg('Service Unavailable');
				console.log('Error sending OTP for: ', phoneNumber, error);
			}
		}
	};

	const handleResendOTP = () => {
		sendOTP(phoneNumber);
	};

	const handleBackArrowClick = () => {
		setOtpSent(false);
		setOtp('');
		setOtpVerified(null);
	};

	const handleNumberChange = (event) => {
		setPhoneNumber(event.target.value);
	};

	const validateMitraPhoneNumber = async (phoneNumber) => {
		try {
			setPhoneNumberTextErrorMsg(null);
			let url = `${process.env.REACT_APP_BACKEND_URL}/validate?phoneNumber=${encodeURIComponent(phoneNumber)}`;
			const response = await axiosInstance.get(url);
			if (response.data.validMitra) {
				return true;
			} else if (!response.data.validMitra) {
				setPhoneNumberTextErrorMsg('This number is not registered');
			} else if (response.status === 404) {
				setPhoneNumberTextErrorMsg('Service Unavailable');
			} else {
				setPhoneNumberTextErrorMsg('Number could not be verified');
			}
		} catch (error) {
			setPhoneNumberTextErrorMsg('Service Unavailable');
			console.log(error);
		}
		return false;
	};

	const handleGetOTPClick = async () => {
		logEvent('RequestLoginOTP', trackingConstants.EVENT_GROUP_LOGIN, trackingConstants.EVENT_SCREEN_LOGIN);
		const validMitraPhoneNumer = await validateMitraPhoneNumber(phoneNumber);
		if (validMitraPhoneNumer) {
			let otpToMail = false;
			if (phoneNumber.length === 10 && trackingConstants.EmailOTPPhoneNumbers.includes(phoneNumber)) {
				otpToMail = true;
			}
			await sendOTP(phoneNumber, otpToMail);
		} else {
			setPhoneNumberVerified(false);
			logEvent('LoginNumberNotRegistered', trackingConstants.EVENT_GROUP_LOGIN, trackingConstants.EVENT_SCREEN_LOGIN);
		}
	};
	return loading ? (
		<Skeleton active />
	) : validSession || (otpVerified && isMitraAuthorized) ? (
		<Redirect to='/home' />
	) : (
		<div className="Login mainContentZ">
			{/* <img className="vahan-logo" alt="vahan" style={{ marginTop: '2.5rem' }} src="/image/mitra-logo.png" />
			<h3 className="subheading">by</h3> */}
			<div className='d-flex login-page-custom-branding align-items-center justify-content-center'>
				{
					isCustomURL && customBrandDetails?.logo ?
						<img className="vahan" alt="vahan" src={customBrandDetails?.logo} />
						:
						!isCustomURL ?
							<img className="vahan" alt="vahan" src={"/image/vahan-final-logo-black.png"} />
							:
							null
				}
				<span className={`bold24-32 black85 login-page-custom-branding-txt ${isCustomURL && !customBrandDetails?.logo ? "login-page-custom-branding-txt-margin-top" : ""}`}>
					{customBrandDetails?.brandName ? customBrandDetails.brandName : ""}
				</span>
			</div>
			{otpSent ? (
				<div>
					<Button className="arrow-back" icon={require('../../arrow_back.svg')} onClick={handleBackArrowClick} />
					<div className="main-login-container">
						<div className="cards-elements-surface-filled">
							{otpVerified === false ? (
								<h5 className="OtpMessage marginTopClass">OTP SMSed to {phoneNumber}</h5>
							) : (
								<h5 className="OtpMessage">OTP SMSed to {phoneNumber} {trackingConstants.EmailOTPPhoneNumbers.includes(phoneNumber) ? "and sent to registered e-mail" : ""}  </h5>
							)}
							<div>
								<TextField
									id="enter-otp"
									label="Enter OTP"
									outlined
									placeholder="Enter OTP"
									minLength={4}
									maxLength={4}
									pattern="[0-9]*"
									value={otp}
									onChange={handleOTPChange}
								/>
								<h5 className="errorMsg">{otpTextErrorMsg}</h5>
								<div className="buttons-contained-enabled-copy">
									<Button id="log-in" raised label="LOG IN" onClick={(e) => verifyOTP(e)} />
								</div>
							</div>
						</div>
					</div>
					<h5 style={{ margin: '0' }} className="OtpMessage">
						Didn&apos;t get SMS?
						<Button id="resend-otp" className="btn_tertiary" label="Resend OTP" onClick={handleResendOTP} />{' '}
					</h5>
				</div>
			) : (
				<div className="main-login-container">
					<div className="cards-elements-surface-filled">
						{phoneNumberVerified === false ? <h3 className="marginTopClass login-font">LOG IN</h3> : <h3 className="login-font">LOG IN</h3>}
						<div>
							<TextField
								id="enter-mobile"
								label="Enter Mobile Number"
								outlined
								className="surface"
								placeholder="+91"
								minLength={10}
								maxLength={10}
								pattern="[0-9]*"
								value={phoneNumber}
								onChange={handleNumberChange}
							/>
							<h5 className="errorMsg">{phoneNumberTextErrorMsg}</h5>
							<div className="buttons-contained-enabled-copy">
								<Button id="get-otp" raised label="Get OTP" onClick={handleGetOTPClick} />
							</div>
							<br />
							{
								!isCustomURL ?
									<p>Do not have an account? <div onClick={() => history.push('/signup')} className="signup_link">SIGN UP</div></p>
									:
									null
							}

						</div>
					</div>
				</div>
			)}
			{/* <img alt="vahan" style={otpSent ? { marginTop: '0rem' } : { marginTop: '4.5rem' }} src="/image/g16.png" /> */}
			<div className="illustration" />

			{
				isCustomURL ?
					<div className='login-page-powered-by-vahan d-flex align-items-center'>
						<span className='regular16-24 black45'>
							Powered By Vahan
						</span>
						<img className="powered-by-footer-img" alt="Powered By Vahan" src="/image/vahan-new-logo.png" />
					</div>
					:
					null
			}

		</div>
	);
};

export default LogInPage;
