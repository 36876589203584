import React, { useState, useContext, useEffect } from 'react';
import { Modal, Button, Radio, Space, Input } from 'antd';
import { AppContext } from "../../App";
import './whatsappModal.css'
import { rollOutRecruitmentMilestoneVisibility } from '../../constants/utils';
import { getViewDetailsData } from '../../service/leadService';

const { TextArea } = Input;

const validDocuments = [
	{
		"name": "Profile Photo",
		"invalid_clients": [],
	},
	{
		"name": "Driving License Front",
		"invalid_clients": [],
	},
	{
		"name": "Driving License Back",
		"invalid_clients": [],
	},
	{
		"name": "Aadhar Card Front",
		"invalid_clients": ['rapido', 'zomato'],
	},
	{
		"name": "Aadhar Card Back",
		"invalid_clients": ['rapido', 'zomato'],
	},
	{
		"name": "Vehicle RC",
		"invalid_clients": ['zomato'],
	},
	{
		"name": "PAN",
		"invalid_clients": [],
	},
	{
		"name": "Bank Passbook",
		"invalid_clients": ['rapido'],
	}
]

const WhatsappTemplateModal = ({ lead, leadDetails, whatsAppMessageModal, whatsAppMessageModalCancel, selectedApplicationIndex = 0 }) => {
	let defaultClientValue, defaultDecodedClientValue;
	const { mitraReducer } = useContext(AppContext);
	const [leadDetail, setLeadDetails] = useState(leadDetails);

	// setting the default template to 'Track Application'
	const [selectedWhatsAppTemplate, setSelectedWhatsAppTemplate] = useState(null);
	const [decodedWhatsAppMessage, setDecodedWhatsAppMessage] = useState(null);
	const [whatsAppTemplate, setWhatsAppTemplates] = useState([]);
	let whatsAppTemplates = [], clientWhatsAppTemplates = [], genericWhatsappTemplates = [];

	useEffect(() => {
		if (leadDetails && !leadDetails.length && (lead && lead.recommendationHistoriesId && lead.recommendationHistoriesId.length && lead.recommendationHistoriesId[selectedApplicationIndex] || lead?.scheduledInterviewId[selectedApplicationIndex])) {
			let data = {
				recommendationHistoryIds: [lead.recommendationHistoriesId[selectedApplicationIndex]],
				scheduledInterviewId: [lead?.scheduledInterviewId[selectedApplicationIndex]],
			};
			getViewDetailsData(data).then((result) => {
				setLeadDetails(result.data);
				loadTemplateData()
			});
		} else {
			loadTemplateData()
		}
	}, [])

	// the below templates are encoded using (https://meyerweb.com/eric/tools/dencoder/)
	const loadTemplateData = () => {
		whatsAppTemplates = [{
			id: 0,
			label: 'Track Application',
			encodedStringLiteral: `Dear%20${leadDetail && leadDetail.length > 0 ? leadDetail[0].firstName : 'Candidate'
				},%20for%20your%20${leadDetail && leadDetail.length > 0 ? leadDetail[0].companyName : 'client'
				}%20delivery%20job%20application%20created%20by%20Vahan%20Vahan%20Leader:%20${Array.isArray(lead.mitraName) ? lead.mitraName[0] : lead.mitraName
				},%20you%20can%20track%20realtime%20status%20updates%20by%20clicking%20here:%20https://mitra.vahan.co/status?userId=${lead.id
				}`,
		}];

		clientWhatsAppTemplates = [
			{
				id: 1,
				label: 'Zomato Next Steps',
				encodedStringLiteral: `Hi%2C%20${leadDetail && leadDetail.length > 0 ? leadDetail[0].firstName : 'Candidate'
					}%0A%0ASteps%20to%20complete%20the%20Zomato%20app%20registration%0A%0AStep%201%3A%20Download%20the%20Zomato%20application%20https%3A%2F%2Fwww.zomato.com%2Fdeliver-food%2F%3Fd%3Dt%0A%0AStep%202%3A%20Please%20enter%20the%20phone%20number%20which%20you%20shared.%20if%20you%20are%20using%20a%20different%20number%20please%20reach%20out%20to%20${Array.isArray(mitraReducer?.mitraInfo?.phoneNumber)
						? mitraReducer?.mitraInfo?.phoneNumber[0]
						: mitraReducer?.mitraInfo?.phoneNumber
					}%20before%20entering%20the%20new%20phone%20number%20and%20documents%20in-app.%0A%0AStep%203%3A%20Upload%20vehicle%20details%2C%20Area(you%20can%20change%20area%20only%20after%203%20months)%2C%20update%20profile%20information%2C%20and%20click%20the%20profile%20photo.%0A%0AStep%204%3A%20Add%20T%20shit%20size%20(it%20will%20be%20delivered%20to%20your%20address%20in%203%20to%205%20days%20)%0A%0AStep%205%3A%20Pay%20the%20onboarding%20fees%20(you%20can%20choose%20EMI%20or%20the%20Full%20amount)%0A%0AStep%206%3A%20Complete%20the%20training%20Video%20and%20your%20id%20will%20be%20activated%20within%2048%20hours.%0A%0AWatch%C2%A0the%20video%C2%A0on%20how%20to%20register%20in%20the%20app%20https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D5SpXmdITXDo%0A%0A%0AFor%20any%20assistance%2C%20please%20call%20at%20${Array.isArray(mitraReducer?.phoneNumber)
						? mitraReducer?.mitraInfo?.phoneNumber[0]
						: mitraReducer?.mitraInfo?.phoneNumber
					}`,
			},
			{
				id: 2,
				label: 'Dunzo Next Steps',
				encodedStringLiteral: `Hi%20${leadDetail && leadDetail.length > 0 ? leadDetail[0].firstName : 'Candidate'
					}%2C%0A%0AThank%20you%20for%20applying%20to%20Dunzo%2C%0A%0ASteps%20to%20complete%20the%20Dunzo%20registration.%0A%0AStep%201%3A%20Download%20the%20dunzo%20application%20https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Drunner.dunzo.com.dunzo_runner%20%0A%0AStep%202%3A%20Please%20enter%20the%20phone%20number%20which%20you%20shared.%20if%20you%20are%20using%20a%20different%20number%20please%20reach%20out%20to%20${Array.isArray(mitraReducer?.mitraInfo?.phoneNumber)
						? mitraReducer?.mitraInfo?.phoneNumber[0]
						: mitraReducer?.mitraInfo?.phoneNumber
					}%20before%20entering%20the%20new%20phone%20number%20and%20documents%20in-app.%0A%0AStep%203%3A%20Select%20the%20personal%20documents%20option%20and%20update%20the%20PAN%2C%20Adhar%20card%20and%20Driving%20license.%0A%0AStep%204%3A%20Enter%20the%20personal%20details%2C%20Emergency%20contact%2C%20Work%20details%2C%20Vehicle%20details%20and%20bank%20account%20details.%0A%0AStep%205%3A%20Watch%20all%20the%20training%20videos.%0A%0AStep%206%3A%20Visit%20the%20nearest%20onboarding%20center%20for%20ID%20activation%0A%0ANote%20%3A%20Please%20carry%20all%20original%20documents`,
			},
			{
				id: 3,
				label: 'Uber Next Steps',
				encodedStringLiteral: `Hi%20${leadDetail && leadDetail.length > 0 ? leadDetail[0].firstName : 'Candidate'
					}%2C%0A%0AThank%20you%20for%20applying%20to%20Uber%20moto.%0A%0ASteps%20to%20complete%20the%20Uber%20app%20registration.%0A%0AStep%201%3A%20Download%20the%20Uber%20app%20%20https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.ubercab.driver%26hl%3Den_IN%26gl%3DUS%20and%20sign%20in%20to%20driver%20app%20using%20the%20user%20id%20and%20password%20communicated.%0A%0AStep%202%3A%20Upload%20profile%20photo%2C%20driving%20license%2C%20pan%20card%2C%20%20adhar%20card%20and%20RC%20document.%0A%0AStep%203%3A%20Id%20will%20be%20activated%20in%2072%20hours%20post%20background%20verification.%0A%0AFor%20more%20queries%2C%20you%20can%20reach%20out%20to%20me%20on%20this%20number%2C%20or%20give%20us%20a%20call%20on%20${Array.isArray(mitraReducer?.mitraInfo?.phoneNumber)
						? mitraReducer?.mitraInfo?.phoneNumber[0]
						: mitraReducer?.mitraInfo?.phoneNumber
					}`,
			},
			{
				id: 4,
				label: 'Shadowfax Next Steps',
				encodedStringLiteral: `Hi%20${leadDetail && leadDetail.length > 0 ? leadDetail[0].firstName : 'Candidate'
					}%2C%0A%0AThank%20you%20for%20applying%20to%20Shadowfax%2C%0A%0ASteps%20to%20complete%20the%20Shadow%20app%20registration.%0A%0AStep%201%3A%20Download%20the%20Shadowfax%20application%20https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Din.shadowfax.gandalf%0A%0AStep%202%3A%20Please%20enter%20the%20phone%20number%20which%20you%20shared.%20if%20you%20are%20using%20a%20different%20number%20please%20reach%20out%20to%20${Array.isArray(mitraReducer?.mitraInfo?.phoneNumber)
						? mitraReducer?.mitraInfo?.phoneNumber[0]
						: mitraReducer?.mitraInfo?.phoneNumber
					}%20before%20entering%20the%20new%20phone%20number%20and%20documents%20in-app.%0A%0AStep%203%3A%20Select%20the%20work%20location%2C%20Profile%20details%2C%20click%20on%20the%20bike%20icon%2C%20and%20upload%20adhar%2C%20pan%20and%20selfie%20photos.%0A%0AStep%204%3A%20Visit%20the%20nearest%20onboarding%20center%20for%20ID%20activation%0A%0ANote%20%3A%20Please%20carry%20all%20original%20documents%0A%0AWatch%C2%A0the%20video%C2%A0for%20complete%20details%20https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DUQYeyO2Ofe0%0A%0AFor%20any%20assistance%2C%20please%20call%20us%20at%20${Array.isArray(mitraReducer?.mitraInfo?.phoneNumber)
						? mitraReducer?.mitraInfo?.phoneNumber[0]
						: mitraReducer?.mitraInfo?.phoneNumber
					}`,
			},
			{
				id: 5,
				label: 'Rapido Next Steps',
				encodedStringLiteral: `Hi%20${leadDetail && leadDetail.length > 0 ? leadDetail[0].firstName : 'Candidate'
					}%2C%0A%0AThank%20you%20for%20applying%20to%20Rapido.%0A%0A1.%20Download%20the%20Rapido%20app%20using%20*only%20the%20link%20below*%0ALink%3A%20https%3A%2F%2Fbit.ly%2Frapido_code%0AUse%20referral%20code%3A%20ML0AQ3D%0A%0A2.%20Please%20note%20that%20there%20are%20*no*%20onboarding%20and%20upfront%20charges.%0A%0A3.%20We%20have%20limited%20positions%20for%20hiring%2C%20request%20you%20to%20*complete%20the%20process%20quickly*%0A%0A4.%20Upload%20the%20documents%20on%20the%20application%0A%0A5.%20Complete%20a%20simple%205-min%20video%20training%20on%20the%20Rapido%20captain%20app%20and%20wait%20for%20your%20application%20to%20be%20approved%0A%0A6.%20You%20will%20get%20a%20call%20from%20the%20Rapido%20team%20to%20confirm%20your%20details%20and%20your%20account%20will%20be%20activated%20within%2024%20hours.%0A%0AFor%20more%20queries%2C%20you%20can%20reach%20out%20to%20me%20on%20this%20number%2C%20or%20you%20can%20give%20us%20a%20call%20on%20${Array.isArray(mitraReducer?.mitraInfo?.phoneNumber)
						? mitraReducer?.mitraInfo?.phoneNumber[0]
						: mitraReducer?.mitraInfo?.phoneNumber
					}%0A%0ANote%3A%20Please%20use%20the%20referral%20code%20ML0AQ3D%2C%20if%20you%20download%20the%20app%20from%20the%20play%20store.`
			},
			{
				id: 11,
				label: 'Swiggy Next Steps',
				encodedStringLiteral: `Hi%20${leadDetail && leadDetail.length > 0 ? leadDetail[0].firstName : 'Candidate'
					}%2C%0A%0AThank%20you%20for%20Showing%20interest%20in%20Swiggy%0A%0AStep%201%3A%20Please%20click%20on%20this%20Link%20to%20download%20the%20app%20%3A%20https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Din.swiggy.deliveryapp%0A%0AStep%202%3A%20Upload%20all%20the%20documents%20and%20Complete%20registration.%0A%0AStep%203%3A%20Complete%20Training%0A%0APlease%20watch%20this%20video%20on%20App%20usage%3A%20https%3A%2F%2Fdrive.google.com%2Ffile%2Fd%2F1S72K16Jw99hm7HQppBOjCuR2lLKLh492%2Fview%3Fusp%3Ddrivesdk%0A%0AIf%20you%20have%20any%20queries%20you%20can%20reach%20out%20to%20me%20on%20this%20number%20or%20you%20can%20give%20us%20a%20call%20on%20${Array.isArray(mitraReducer?.mitraInfo?.phoneNumber)
						? mitraReducer?.mitraInfo?.phoneNumber[0]
						: mitraReducer?.mitraInfo?.phoneNumber
					}%0A%0AThank%20You.`
			},
			{
				id: 12,
				label: 'Zepto_3PL Next Steps',
				encodedStringLiteral: `Hi%20${leadDetail && leadDetail.length > 0 ? leadDetail[0].firstName : 'Candidate'
					}%2C%0A%0ASteps%20to%20complete%20the%20Vahan%20app%20registration%0A%0AStep%201%3A%20Download%20the%20Vahan%20application%20https%3A%2F%2Fmitraapp.page.link%2Fdownload-ma%0A%0AStep%202%3A%20Please%20enter%20the%20phone%20number%20which%20you%20shared.%20%0A%0AStep%203%3A%20After%20entering%20the%20phone%20number%2C%20you%20will%20receive%20an%20OTP.%20Enter%20the%20OTP%20on%20to%20login%20to%20the%20Vahan%20App%0A%20%0AStep%204%3A%20Upload%20your%20profile%20picture%2C%20Aadhar%20Card%2C%20PAN%20Card%20and%20Driver's%20License%20%0A%0AStep%205%3A%20Verify%20your%20bank%20details%20by%20providing%20your%20bank%20account%20number%20and%20IFSC%20code%0A%0AStep%206%3A%20Complete%20the%20training%20Video%20and%20your%20Zepto%20id%20will%20be%20activated%20within%2048%20hours.%0A%0AFor%20any%20assistance%2C%20please%20call%20at%20${Array.isArray(mitraReducer?.mitraInfo?.phoneNumber)
						? mitraReducer?.mitraInfo?.phoneNumber[0]
						: mitraReducer?.mitraInfo?.phoneNumber
					}`
			},
			{
				id: 13,
				label: 'Rapido_3PL Next Steps',
				encodedStringLiteral: `Hi%20${leadDetail && leadDetail.length > 0 ? leadDetail[0].firstName : 'Candidate'
					}%2C%0A%0ASteps%20to%20complete%20the%20Vahan%20app%20registration%20%0A%0AStep%201%3A%20Download%20the%20Vahan%20application%20https%3A%2F%2Fmitraapp.page.link%2Fdownload-ma%20%20%0A%0AStep%202%3A%20Please%20enter%20the%20phone%20number%20which%20you%20shared.%20%20%20%0A%0AStep%203%3A%20After%20entering%20the%20phone%20number%2C%20you%20will%20receive%20an%20OTP.%20Enter%20the%20OTP%20on%20to%20login%20to%20the%20Vahan%20App%20%20%20%0A%0AStep%204%3A%20Upload%20your%20profile%20picture%2C%20Aadhar%20Card%2C%20PAN%20Card%2C%20RC%20and%20Driver%27s%20License%20%20%20%0A%0AStep%205%3A%20Verify%20your%20bank%20details%20by%20providing%20your%20bank%20account%20number%20and%20IFSC%20code%0A%0AFor%20any%20assistance%2C%20please%20call%20at%20${Array.isArray(mitraReducer?.mitraInfo?.phoneNumber)
						? mitraReducer?.mitraInfo?.phoneNumber[0]
						: mitraReducer?.mitraInfo?.phoneNumber
					}`
			},
			{
				id: 14,
				label: 'Delhivery_3PL Next Steps',
				encodedStringLiteral: encodeURI(`Hi ${leadDetail && leadDetail.length > 0 ? leadDetail[0].firstName : 'Candidate'
					},\n\nSteps to complete the Vahan app registration\n\nStep 1: Download the Vahan application https://mitraapp.page.link/download-ma \n\nStep 2: Please enter the phone number which you shared.\n\nStep 3: After entering the phone number, you will receive an OTP. Enter the OTP on to login to the Vahan App\n\nStep 4: Upload your profile picture, Aadhar Card, PAN Card and Driver's License\n\nStep 5: Verify your bank details by providing your bank account number and IFSC code\n\nStep 6: Complete address verification with our agent who will call you in the next 24-48 hours.\n\nFor any assistance, please call at ${Array.isArray(mitraReducer?.mitraInfo?.phoneNumber)
						? mitraReducer?.mitraInfo?.phoneNumber[0]
						: mitraReducer?.mitraInfo?.phoneNumber
					}`)
			},
			{
				id: 15,
				label: 'Bigbasket_3PL Next Steps',
				encodedStringLiteral: encodeURI(`Hi ${leadDetail && leadDetail.length > 0 ? leadDetail[0].firstName : 'Candidate'
					},\n\nSteps to complete the Vahan app registration\n\nStep 1: Download the Vahan application https://mitraapp.page.link/download-ma \n\nStep 2: Please enter the phone number which you shared.\n\nStep 3: After entering the phone number, you will receive an OTP. Enter the OTP on to login to the Vahan App\n\nStep 4: Finish by applying to Bigbasket\n\nFor any assistance, please call at ${Array.isArray(mitraReducer?.mitraInfo?.phoneNumber)
						? mitraReducer?.mitraInfo?.phoneNumber[0]
						: mitraReducer?.mitraInfo?.phoneNumber
					}`)
			},
		];
		clientWhatsAppTemplates.map(template => {
			if (lead && lead.companies) {
				lead.companies.map(companyName => {
					if (template.label.includes(companyName) || template.label.toLocaleLowerCase().includes(companyName.toLocaleLowerCase())) {
						if (!(companyName.toLowerCase() === 'rapido' && template.label.includes('Rapido_3PL Next Steps')))
							whatsAppTemplates.push(template);
					}
				})
			}

			if (leadDetail && leadDetail[0] && template.label.includes(leadDetail[0].companyName) && template.label?.split(' ')?.[0].toLowerCase() === leadDetail[0].companyName?.toLowerCase()) {
				defaultClientValue = template.encodedStringLiteral;
				defaultDecodedClientValue = decodeURI(template.encodedStringLiteral)
					.replace(/%2C/g, ',')
					.replace(/%3A/g, ':')
					.replace(/%2F/g, '/')
					.replace(/%3F/g, '?')
					.replace(/%3D/g, '=')
					.replace(/%26/g, '&')
			}
		})
		whatsAppTemplates = whatsAppTemplates.concat(rollOutRecruitmentMilestoneVisibility(mitraReducer?.mitraInfo) && leadDetail?.[0]?.companyName.toLowerCase().indexOf('3pl') === -1 ? {
			id: 16,
			label: 'Download Vahan App',
			encodedStringLiteral: encodeURI(`Dear ${leadDetail && leadDetail.length > 0 ? leadDetail[0].firstName : 'Candidate'
				},\nYou have been referred to ${leadDetail && leadDetail?.[0]?.companyName} through Vahan\nPlease download the Vahan app to complete your job application\ndwnld.vahan.co`)
		} : []);

		genericWhatsappTemplates = [
			{
				id: 6,
				label: 'Document Collection',
				encodedStringLiteral: `Hi%20${leadDetail && leadDetail.length > 0 ? leadDetail[0].firstName : 'Candidate'
					}.%20Thanks%20for%20showing%20interest%20in%20a%20job%20with%20${leadDetail && leadDetail.length > 0 ? leadDetail[0].companyName : 'client'}.%20%0APlease%20share%20the%20following%20documents%3A%0A${validDocuments.map((doc) => {
						const clientName = leadDetail && leadDetail.length > 0 ? leadDetail[0].companyName.toLowerCase() : 'client';
						if (doc?.invalid_clients?.length === 0 || doc.invalid_clients.filter((client) => clientName.includes(client)).length === 0) {
							return `%E2%80%A2%20${doc.name}%0A`
						}
					}).join('')}`
			}
			,
			{
				id: 7,
				label: 'Push for FT',
				encodedStringLiteral: `Hi%20${leadDetail && leadDetail.length > 0 ? leadDetail[0].firstName : 'Candidate'
					}%2C%20you%20account%20with%20${leadDetail && leadDetail.length > 0 ? leadDetail[0].companyName : 'client'}%20is%20activated!!! You%20can%20start%20taking%20trips%20and%20unlock%20your%20earning%20potential.`
			},
			{
				id: 8,
				label: 'Push for F10',
				encodedStringLiteral: `Hey%20${leadDetail && leadDetail.length > 0 ? leadDetail[0].firstName : 'Candidate'
					}%2C%20%0A%0ANew%20Joiners%20on%20${leadDetail && leadDetail.length > 0 ? leadDetail[0].companyName : 'client'}%20have%20already%20taken%20more%20than%2010%20orders%20%F0%9F%98%B2.%20Don%27t%20wait.%20Login%20now%20and%20earn%20as%20much%20as%20other%20delivery%20partners%20and%20hit%2010th%20trip%20milestone%20today.%0A%0ANeed%20help%3F%20Call%20this%20number%3A%20${Array.isArray(mitraReducer?.mitraInfo?.phoneNumber)
						? mitraReducer?.mitraInfo?.phoneNumber[0]
						: mitraReducer?.mitraInfo?.phoneNumber}`
			},
			{
				id: 9,
				label: 'Push for F25',
				encodedStringLiteral: `Hey%20${leadDetail && leadDetail.length > 0 ? leadDetail[0].firstName : 'Candidate'
					}%2C%20%0A%0ANew%20Joiners%20on%20${leadDetail && leadDetail.length > 0 ? leadDetail[0].companyName : 'client'}%20have%20already%20taken%20more%20than%2025%20orders%20%F0%9F%98%B2.%20Don%27t%20wait.%20Login%20now%20and%20earn%20as%20much%20as%20other%20delivery%20partners%20and%20hit%2025th%20trip%20milestone%20today.%0A%0ANeed%20help%3F%20Call%20this%20number%3A%20${Array.isArray(mitraReducer?.mitraInfo?.phoneNumber)
						? mitraReducer?.mitraInfo?.phoneNumber[0]
						: mitraReducer?.mitraInfo?.phoneNumber}`
			},
			{
				id: 10,
				label: 'Type your own message',
				encodedStringLiteral: `%20`
			}
		];
		whatsAppTemplates = whatsAppTemplates.concat(genericWhatsappTemplates);
		setWhatsAppTemplates(whatsAppTemplates);
	}

	const onTemplateChange = (event) => {
		setSelectedWhatsAppTemplate(event.target.value);
		setDecodedWhatsAppMessage(
			decodeURI(event.target.value)
				.replace(/%2C/g, ',')
				.replace(/%3A/g, ':')
				.replace(/%2F/g, '/')
				.replace(/%3F/g, '?')
				.replace(/%3D/g, '=')
		);
	};

	const onWhatsAppMessageChange = (event) => {
		setDecodedWhatsAppMessage(event.target.value ? event.target.value : " ");
		setSelectedWhatsAppTemplate(encodeURI(event.target.value));
	};

	return (
		<Modal
			className="whatsapp-modal-style"
			style={{ textAlign: 'left' }}
			title="Select WhatsApp Message"
			visible={whatsAppMessageModal}
			onCancel={whatsAppMessageModalCancel}
			footer={[
				<Button key="1" className="ant-btn ant-btn-default" onClick={whatsAppMessageModalCancel}>
					Cancel
				</Button>,
				<a
					key="2"
					href={`https://api.whatsapp.com/send?phone=+91${leadDetail && leadDetail.length > 0
						? leadDetail[0].parentPhoneNumber
							? leadDetail[0].parentPhoneNumber
							: leadDetail[0].phoneNumber
						: lead && lead.parentPhoneNumber && lead.parentPhoneNumber.length > 0
							? lead.parentPhoneNumber[0]
							: lead.phoneNumber
						}&text=${selectedWhatsAppTemplate ? selectedWhatsAppTemplate : defaultClientValue}`}
					rel="noopener noreferrer"
					target="_blank"
					className="ant-btn ant-btn-primary"
					onClick={whatsAppMessageModalCancel}
					style={{ background: '#52C41A' }}
				>
					Send on Whatsapp
				</a>,
			]}
		>
			<Radio.Group onChange={onTemplateChange} defaultValue={defaultClientValue}>
				<Space direction="vertical">
					{/* href={`https://api.whatsapp.com/send?phone=+91${leadDetail.length > 0 ? leadDetail[0].parentPhoneNumber ? leadDetail[0].parentPhoneNumber : leadDetail[0].phoneNumber : ''}&text=Dear%20${leadDetail.length > 0 ? leadDetail[0].firstName : 'Candidate'},%20for%20your%20${leadDetails.length > 0 ? leadDetails[0].companyName : 'client'}%20delivery%20job%20application%20created%20by%20Vahan%20Mitra%20Leader:%20${Array.isArray(lead.mitraName) ? lead.mitraName[0] : lead.mitraName},%20you%20can%20track%20realtime%20status%20updates%20by%20clicking%20here:%20https://mitra.vahan.co/status?userId=${lead.id}`} */}
					{whatsAppTemplate.map((template) => {
						return (
							<Radio key={template.id} value={template.encodedStringLiteral}>
								{template.label}
							</Radio>
						);
					})}
				</Space>
			</Radio.Group>
			{decodedWhatsAppMessage && decodedWhatsAppMessage.length ? (
				<div style={{ paddingTop: '10px' }}>
					<TextArea value={decodedWhatsAppMessage} rows={6} onChange={onWhatsAppMessageChange} />
				</div>
			)
				: (defaultDecodedClientValue ? <div style={{ paddingTop: '10px' }}>
					<TextArea value={defaultDecodedClientValue} rows={6} onChange={onWhatsAppMessageChange} />
				</div>
					: null)}
		</Modal>
	);

};

export default WhatsappTemplateModal
