import React, { useEffect, useState } from 'react';
import { Button, Input, notification } from 'antd';
import { sendZomatoOtp, submitZomatoOtp, verifyZomatoOtp } from '../../../../service/additionalInfoService';
import {CheckCircleOutlined} from '@ant-design/icons'
import { sendBlinkitOtp, submitBlinkitOtp } from '../../../../service/BlinkitAdditionalFormService';

const OTPTimerConstant = 30;
const LAYOUTS = {
  'OTP_LAYOUT': 'OTP_LAYOUT',
  'VERIFIED_SESSION_LAYOUT': 'VERIFIED_SESSION_LAYOUT',
  'SINGLE_BUTTON_REQUEST_OTP_LAYOUT': 'SINGLE_BUTTON_REQUEST_OTP_LAYOUT'
}

function BlinkitOTPScreen({goToNextScreen, isOtpVerified, setIsOtpVerified, lead, determineCurrentStep, getLeadInformation}) {
    const [otp, setOtp] = useState(['','','','','','']);
    const inputRef = [];
    const [showResendOtpTimer, setShowResendOtpTimer] = useState(false);
    const [timerToShow, setTimerToShow] = useState(OTPTimerConstant);
    const [currentLayout, setCurrentLayout] = useState();
    let otpTimerInterval;
    let timerVal;
  
    useEffect(() => {
      if(isOtpVerified) {
        setCurrentLayout(LAYOUTS.VERIFIED_SESSION_LAYOUT);
      } else {
        setCurrentLayout(LAYOUTS.SINGLE_BUTTON_REQUEST_OTP_LAYOUT);
      }
    }, [isOtpVerified])
    
  
    const handleOTPChange = (text, index) => {
      const otpArray = otp;
  
      // Update the OTP array with the new input
      otpArray[index] = text;
  
      // Move focus to the next input or the previous one if the text is deleted
      if (text.length === 1 && index < otp.length-1) {
        inputRef[index + 1].focus();
      } else if (text.length === 0 && index > 0) {
        inputRef[index - 1].focus();
      }
  
      setOtp([...otpArray]);
  
      // When all inputs are filled, you can do something with the OTP (e.g., submit it)
      if (otpArray.join('').length === otp.length) {
        const otp = otpArray.join('');
        // Do something with the OTP, e.g., send it to a server for verification
      }
    };
  
    const sendOTPToLead = async() => {
      // code to trigger OTP

      try {

        if(!lead?.scheduledInterviewId || !lead?.companyName || !lead?.phoneNumber) {
          notification['error']({
            message: 'Error triggering OTP, please try after sometime.',
          });
          return;
        }

        const otpResponse = await sendBlinkitOtp(lead);
  
        if (otpResponse?.data?.data?.status?.code === 200) {
          notification['success']({
            message: 'OTP sent successfully.',
          });
        } else if(otpResponse?.data?.data?.status?.code === 748) {
          notification['error']({
            message: 'User already exists with Zomato. Please try a different phone number',
          });
        } else if(otpResponse?.data?.data?.status?.code === 749) {
          notification['error']({
            message: 'Maximum OTP limit reached, please try after 10 minutes.',
          });
        } else {
          notification['error']({
            message: 'Something went wrong, please try after sometime.',
          });
        }
  
      } catch(e) {
        notification['error']({
          message: 'Something went wrong, please try again after sometime.',
        });
      }
      
    }
  
    const triggerOtp = () => {
      sendOTPToLead();
  
      timerVal = OTPTimerConstant;
      setShowResendOtpTimer(true);
  
      otpTimerInterval = setInterval(() => {
          if(timerVal <=0) {
              clearInterval(otpTimerInterval);
              setShowResendOtpTimer(false);
          } else {
              timerVal = timerVal -1;
              setTimerToShow(timerVal<10 ? `0${timerVal}`: timerVal);
          }
      }, 1000);
  
    };
  
    const submitOtp = async() => {
  
      if(otp.join('').length!==otp.length) {
        notification['error']({
          message: 'Please enter the OTP to continue',
        });
  
        return;
      }
  
      // API to submit the OTP
      try {
        const payload ={
          otp: otp.join(''),
          phoneNumber: lead?.phoneNumber
        }
  
        const response = await submitBlinkitOtp(payload);
  
        if(response?.data?.status?.code===200) {
          // goToNextScreen();
          getLeadInformation();
          setIsOtpVerified(true);
        } else if(response?.data?.status?.code === 749) {
          notification['error']({
            message: 'Maximum OTP submission limit reached, please try after 10 minutes.',
          });
        } else {
          notification['error']({
            message: 'Something went wrong, please try after sometime.',
          });
        }
  
      } catch(e) {
        // error handling
        notification['error']({
          message: 'Something went wrong, please try again after sometime.',
        });
      }
    };
  
    const enableOtpFormAndTriggetOtpLayout = () => {
      setCurrentLayout(LAYOUTS.OTP_LAYOUT);
      triggerOtp();
    }
  
    const otpLayout = () => {
      return (
        <>
          <div className="otp-verification-container">
            <div className='container-header'>OTP Verification</div>
  
            <div className="otp-form-container">
              <div className='otp-header'>The OTP will be sent to {lead?.name} on (+91)-{lead?.phoneNumber}</div>
  
              <div className='otp-input-section'>
                {
                    otp.map((item,index) => {
                        return (
                            <Input
                            key={index}
                            className='otp-input'
                            maxLength={1}
                            value={item}
                            ref={(ref) => (inputRef[index] = ref)}
                            onChange={(e) => handleOTPChange(e.target.value, index)}
                            />
                        )
                    })
                }
              </div>
  
              {
                showResendOtpTimer ? (
                    <div style={{marginTop: 24}}>{`Resend OTP in (00:${timerToShow})`}</div>
                ) : (
                  <Button className='button-styles-secondary' onClick={()=>triggerOtp()}>Request OTP</Button>
                )
              }
  
              
            </div>
  
          </div>
  
  
          <div className='cta-container'>
            <Button className='button-styles-primary' onClick={()=>submitOtp()}>Verify OTP and Continue</Button>
          </div>
        </>
      )
    };
  
    const goToCurrentStep = () => {
      determineCurrentStep();
    }
  
    const singleButtonRequestOtpLayout = () => {
      return (
        <>
  
          <div className="otp-verification-container">
              <div className='container-header'>OTP Verification</div>
  
              <div className="otp-form-container">
                <Button className='button-styles-primary' onClick={()=>enableOtpFormAndTriggetOtpLayout()}>Request new OTP</Button>
              </div>
          </div>
        
        </>
      )
    }
  
    const verifiedLayout = () => {
      return (
        <>
  
          <div className="otp-verification-container">
              <div className='container-header'>OTP Verification</div>
  
              <div className="otp-form-container">
                <div className='otp-header' style={{display: 'flex', alignItems:'center', gap:'8px'}}>
                  <CheckCircleOutlined style={{color: '#4BB543'}}/> OTP already verified. Please complete the remaining steps
                </div>
              </div>
          </div>
  
          <div className='button-container'>
              <Button className='submit-button' onClick={()=>goToCurrentStep()}>Next</Button>
          </div>
        
        </>
      )
    }
  
    return (
      <>
        {
          currentLayout === LAYOUTS.OTP_LAYOUT ? otpLayout(): null
        }
  
        {
          currentLayout === LAYOUTS.SINGLE_BUTTON_REQUEST_OTP_LAYOUT ? singleButtonRequestOtpLayout(): null
        }
  
        {
          currentLayout === LAYOUTS.VERIFIED_SESSION_LAYOUT ? verifiedLayout(): null
        }
      </>
    )
}

export default BlinkitOTPScreen
