import React from 'react'

function RiderCompletedBanner() {
    const containerStyle={
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        marginTop: '12px',
        border: '1px solid #ff9600',
        borderRadius: '8px',
        padding: '6px',
    }
  return (
    <>
        <div style={containerStyle}>
            <img src="/image/vahan-new-logo.png" height="24" width="24" />
            <div>Rider has already submitted these details</div>
        </div>
    </>
  )
}

export default RiderCompletedBanner
