import { Checkbox, Divider, Input } from 'antd'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import {CloseOutlined} from '@ant-design/icons'
import SearchIcon from '../../../static/svg/SearchIcon';
import useDebouncedValue from '../../../hooks/useDebouncedValue';

function CustomSearchDropdown(
    {
        showSearch=true,
        placeholder='Enter to search',
        dropdownOptions=[],
        onSelect=()=>1,
        onSearch=()=>1,
        checkboxGroupVisible=false,
        checkboxGroupItems=[],
        value=[]
    }
) {

    const [selectedItems, setSelectedItems] = useState([...value]);
    const [onFocus, setOnFocus] = useState(false);
    const [options, setOptions] = useState([]);
    const [searchText, setSearchText] = useState(null);
    const debouncedSearchText = useDebouncedValue(searchText, 500);
    const [checkboxGroupOptions, setCheckboxGroupOptions] = useState([...checkboxGroupItems]);
    const dropdownRef = useRef(null);

    const saveSearchText = (text) => {
        setSearchText(text);
    }

    useEffect(() => {
        onSearch(debouncedSearchText)
    }, [debouncedSearchText])
    
    
    useEffect(() => {
        if(dropdownOptions.length){
            setOnFocus(true);
            setOptions([...dropdownOptions]);
        }
        else setOnFocus(false)
    }, [dropdownOptions])

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef?.current && !dropdownRef?.current?.contains(event.target)) {
                setOnFocus(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);
    

    // Common operations
    const checkIfItemAlreadyExistInSelectedItemList = (item) => {
        // A utility function to check while insertion if item already exist, duplicate prevention
        if (!value?.length) return false;

        for (let i = 0; i < value.length; i++) {
            if(value[i].value.toLowerCase() === item.value.toLowerCase()) {
                return true;
            }
        }

        return false;
    }

    const addItemToSelectedItems = (itemArr) => {
        // A function which is used to add city in selected city list
        // when a city gets selected either from dropdown or popular cities checkbox
        for (let item = 0; item < itemArr.length; item++) {
            // check if city is new one or already existing
            const itemExist = checkIfItemAlreadyExistInSelectedItemList(itemArr[item])

            if(!itemExist) {
                onSelect([...value, itemArr[item]]);
            }
        }
    }

    const removeItemFromSelectedItemsList = (label) => {
        const newSelectedItems = value.filter((item)=>{
            if(item.label != label) return true;
            else return false;
        });

        onSelect([...newSelectedItems]);
    }

    const uncheckItemFromCheckboxGroup = (label) => {
        const modifiedCheckboxGroupItems = [...checkboxGroupOptions];

        for (let i = 0; i < modifiedCheckboxGroupItems.length; i++) {
            if (modifiedCheckboxGroupItems[i].label === label) {
                modifiedCheckboxGroupItems[i].isChecked = false
            }
        } 
        
        setCheckboxGroupOptions([...modifiedCheckboxGroupItems]);
    }

    const checkItemInCheckboxGroup = (label) => {
        const modifiedCheckboxGroupItems = [...checkboxGroupOptions];

        for (let i = 0; i < modifiedCheckboxGroupItems.length; i++) {
            if (modifiedCheckboxGroupItems[i].label === label) {
                modifiedCheckboxGroupItems[i].isChecked = true
            }
        } 
        
        setCheckboxGroupOptions([...modifiedCheckboxGroupItems]);
    }

    
    const onSearchItemSelect = (item) => {
        addItemToSelectedItems([item]);
        setOnFocus(false);

        if(checkboxGroupVisible) {
            checkItemInCheckboxGroup(item.label);
        }
    }

    const onCheckboxGroupChange = (e, item) => {
        const modifiedCheckboxGroupOptions = [...checkboxGroupOptions];

        
        for (let i = 0; i < modifiedCheckboxGroupOptions.length; i++) {
            if(modifiedCheckboxGroupOptions[i].id === item.id) {
                if(modifiedCheckboxGroupOptions[i].isChecked) {
                    removeItemFromSelectedItemsList(modifiedCheckboxGroupOptions[i].label);
                }
                modifiedCheckboxGroupOptions[i].isChecked = !modifiedCheckboxGroupOptions[i].isChecked
            }
        }
        

        // Block to add items to selected list for selected chips
        let newSelectedItemsList = []; 
        for (let i = 0; i < modifiedCheckboxGroupOptions.length; i++) {
            if(modifiedCheckboxGroupOptions[i].isChecked) {
                newSelectedItemsList.push({label: modifiedCheckboxGroupOptions[i].label, value: modifiedCheckboxGroupOptions[i].label});
            }
        }

        addItemToSelectedItems(newSelectedItemsList);

        setCheckboxGroupOptions([...modifiedCheckboxGroupOptions]);
    }

    
    const onRemovalOfItemUsingChips = (label) => {
        removeItemFromSelectedItemsList(label);

        uncheckItemFromCheckboxGroup(label);
    }

  return (
    <div ref={dropdownRef}>
        {
            showSearch ? (
                <>
                    <Input 
                        onChange={(e)=>saveSearchText(e.target.value)}
                        onFocus={()=>setOnFocus(true)}
                        className='samvaadini-search-candidate adv-filter-search' 
                        placeholder={placeholder} 
                        allowClear
                        prefix={<SearchIcon height={10} width={10} strokeColor='#C8C9CC'/>} />
                    
                    <div style={{position:'relative'}}>

                        {
                            onFocus && options.length ? (
                                <div className='custom-dropdown-container'>
                                    {
                                        options.map((option,idx) =>(
                                            <Fragment key={idx}>
                                            <div 
                                            className='color-252526 inter-regular-10-16 d-flex' 
                                            style={{padding: '12px 24px', cursor:'pointer'}}
                                            onClick={()=>onSearchItemSelect(option)}
                                            key={option.label}>
                                                {option.label}
                                            </div>
                                            <Divider style={{margin:'0px 0px'}}/>
                                            </Fragment>
                                        ))
                                    }
                                </div>
                            ):null
                        }
                    </div>
                </>
            ): null
        }
        
        {
            checkboxGroupVisible && checkboxGroupOptions.length ? (
                <div className="popular-cities-container">
                    {
                        checkboxGroupOptions.map((city, idx)=> (
                            <Fragment key={idx}>
                                <Checkbox checked={city.isChecked} onChange={(e)=>onCheckboxGroupChange(e,city)}>
                                    <span className='inter-regular-10-16 color-252526'>{city.label}</span>
                                </Checkbox>
                            </Fragment>
                        ))
                    }
                </div>
            ): null
        }
        

        <div className="selected-city-list">
            {
                value.map((item, idx) => (
                    <div className='new-custom-chip inter-regular-10-16 color-252526' key={item.label}>{item.label} <CloseOutlined onClick={()=>onRemovalOfItemUsingChips(item.label)} style={{fontSize:'8px',cursor:'pointer'}}/></div>
                ))
            }
        </div>
    </div>
  )
}

export default CustomSearchDropdown