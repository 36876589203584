import React, {useContext, useEffect, useState} from "react";
import { Typography, Row, Space,Input, Col, Divider,Tag, Button, notification } from 'antd';
import {CheckCircleFilled,EyeInvisibleOutlined, EyeTwoTone} from '@ant-design/icons';
import CONSTANTS from "../../constants/constants";
import * as queryString from 'query-string';
import { updatePayoutDashboardPassword } from "../../service/commonService";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../App";
import {useParams} from "react-router-dom";


const { Text} = Typography;

const ResetPayoutDashboardPasswordScreen = () => {
    const {tab} = useParams();
    const history = useHistory();
    const {mitraReducer} = useContext(AppContext);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [key, setKey] = useState('');
    

    useEffect(()=>{
        setKey(tab)
    return () => {
    }
    }, []);


    const onPasswordChange = (event) => {
        setPassword(event.target.value)
    }
    const onConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value)
    }


    const onSubmit = () => {
        const apiParams = {
            key,
            password
        };
        updatePayoutDashboardPassword(apiParams).then((response) => {
            console.log(response);
            notification[response.data.status ? 'success' : 'error']({
                message: response.data.message,
                duration: 5
              });
              setPassword('');
              setConfirmPassword('');
        }).catch((error)=> {
            console.log(error);
        });
    }


    return (
        <div className="reset-payout-password mainContentZ" style={{ height: '100vh', justifyContent: 'center', display: 'flex', marginTop: '8rem'}}>
            <div className="cards-elements-surface-filled" style={{ height: '400px', width: '500px', justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
                <div style={{display:'flex' ,flexDirection: 'column', gap: '16px'}}>
                    <Text className="feedback-header">Update Payout Dashboard Password</Text>
                    <Divider/>
                    <Input.Password
                        placeholder="Enter New Password"
                        onChange={onPasswordChange}
                        value={password}
                        size="large"
                        iconRender={(visible) => (visible ? '' : '')}
                        
                    />
                    {
                        (password.length > 0 && password.length<6) ? 
                        <Text style={{color: 'red', display: 'flex', fontSize: '10px'}}> Password must be of atleast 6 characters !</Text> : ''
                    }
                    <Input.Password
                        placeholder="Confirm New Password"
                        onChange={onConfirmPasswordChange}
                        value={confirmPassword}
                        size="large"
                        onPaste={(e)=>{
                            e.preventDefault()
                            return false;
                            }}
                        onCopy={(e)=>{
                            e.preventDefault()
                            return false;}}
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                    {
                        
                        (confirmPassword.length && password !== confirmPassword) ? 
                        <Text style={{color: 'red', display: 'flex', fontSize: '10px' }}> Password do not match !</Text> : ''
                    }
                    <div style={{justifyContent: 'center', display: 'flex'}}>
                    { 
                        password.length == 0 ||(password.length < 6) || (confirmPassword.length == 0 || password !== confirmPassword) ? 
                        <Button disabled
                        style={{color: '#fff', width: '60%', background: '#52C41A'}} 
                        className="custom-submit-button">
                            Submit
                        </Button> :
                        <Button 
                        style={{color: '#fff', width: '60%', background: '#52C41A'}} 
                        className="custom-submit-button"
                        onClick={onSubmit}>
                            Submit
                        </Button>

                    }           
                    </div>
  
                </div>
            </div>

        </div>

        
    );
};

export default ResetPayoutDashboardPasswordScreen
