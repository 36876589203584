import React, { useContext, useEffect, useState } from 'react'
import { Button, Select, notification } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { AppContext } from '../../../../App';
import { getBlinkitData, updateBlinkitData } from '../../../../service/BlinkitAdditionalFormService';

function BlinkitZoneSelection({goToNextScreen,lead,leadAdditionalDetails}) {
    const [selectedZone, setSelectedZone] = useState();
    const [selectedZoneDetails, setSelectedZoneDetails] = useState();
    const [zoneInfo, setZoneInfo] = useState([]);
    const [zoneStructuredData, setZoneStructuredData] = useState([]);
    const {mitraReducer,mitraDispatch, setAlert,setSpin } = useContext(AppContext);
    const [zomatoZoneApiResponse, setZomatoZoneApiResponse] = useState({});


    const handleChange = (item, zoneObject) => {
        console.log(item, zoneObject);
        setSelectedZone(item);
        setSelectedZoneDetails(zoneObject);
    };

    const checkErrors = (res) => {
        const newErrors = [];
        for (let key in res.data) {
            if (res.data[key] !== "200") {
                newErrors.push(`${key}: ${res.data[key]}`)
            }
        }
      
        if(newErrors.length) {
            // setErrors([...newErrors]);
            alert(newErrors.join("\n"))
            return true;
        } 
        return false;
      }

    const submitZone = async() => {

        if(!selectedZone) {
            notification['error']({
                message: 'Please select a zone to continue',
              });
            return
        } else {
            // logic to submit zone
            showLoader(true);
            try {
                const payload = {
                    phoneNumber: lead?.phoneNumber,
                    userId: lead?.userId,
                    mitraName: mitraReducer?.mitraInfo?.name,
                    mitraId: mitraReducer?.mitraInfo?.id,
                    dataToUpdate: {
                        updateStore: {
                            storeId: selectedZoneDetails.store_id,
                            blinkitWorkStore: selectedZone
                        }
                    }
                }

                const response = await updateBlinkitData(payload);

                if(response?.data?.updateStore?.data?.status?.code === 200) {
                    goToNextScreen();
                } else {
                    notification['error'] ({
                        message: 'Submission response error.'
                    })
                }
                showLoader(false);
            } catch(err) {
                showLoader(false);
                notification['error'] ({
                    message: 'Error submitting zone details.'
                })
            }
        }
    };

    const showLoader = (value) => {
        setSpin({
            loading: value, //false or true when actived
            delay: null,
            tip: 'Loading...'  //Loading copy here
        })
    }


    const getZoneData = async() => {
        // logic to get zone data
        showLoader(true);

        const payload = {
            phoneNumber: lead?.phoneNumber,
            dataToFetch: {
                getStore:{}
            }
        }

        const response = await getBlinkitData(payload);

        if(response?.data?.getStore?.allStores?.length) {
            const allStores = response?.data?.getStore?.allStores;
            const newStoreData = [];
            for (let i = 0; i < allStores.length; i++) {

                newStoreData.push({
                    ...allStores[i],
                    value: allStores[i].store, 
                    label: allStores[i].store,
                });

                if(allStores[i].is_selected) {
                    setSelectedZone(allStores[i].store);
                    setSelectedZoneDetails({
                        ...allStores[i],
                        value: allStores[i].store, 
                        label: allStores[i].store,
                    });
                }
                
            }
            setZoneInfo([...newStoreData]);
            setZoneStructuredData([...newStoreData]);
        }
        showLoader(false);
    }
    
    const filterOption = (input, option) =>(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const onItemClick = (item) => {

        setSelectedZone(item.store);
        setSelectedZoneDetails(item);
        
        const newZoneInfo = [...zoneInfo];
        for (let zone = 0; zone < newZoneInfo.length; zone++) {
            if (newZoneInfo[zone].label === item.label) {
                newZoneInfo[zone].is_selected = true;
            } else {
                newZoneInfo[zone].is_selected = false;
            }
        }

        setZoneStructuredData([...newZoneInfo]);

    }

    useEffect(() => {
        if(lead?.phoneNumber) {
            getZoneData();
        }
    }, [lead])
  return (
    <>

        <div className='vehicle-type-container'>
            <div className='container-header'>Zone Details</div>

            <div className='city-details-container'>
                <div className='label'>Select Work Zone<sup>*</sup></div>

                {
                    zoneStructuredData.map((item, index) => {
                        return (
                            <div key={index} className='option-item' onClick={()=>onItemClick(item)} style={item.is_selected ? {backgroundColor: '#F7F7F7'}:{}}>

                                <div className="radio-section">
                                    <div className='option-radio'>
                                        {
                                            item.is_selected ? <div className="radio-fill"></div>: null
                                        }
                                        
                                    </div>
                                </div>

                                <div className="option-details mobile">

                                    <div className='option-header' style={{gap: '8px'}}>
                                        <div className='option-header-zone'>{item.label}</div>
                                        <div className='option-header-bonus-details'>
                                            <div>Onboarding Fee: {`${item?.ob_fee ?? 'Not Available'}`}</div>
                                            {/* <div>Order Eligibility: {item?.feeData?.orderCountForJoiningBonus || 'Not Available'}</div> */}
                                        </div>
                                    </div>

                                    {/* <div className='custom-divider'></div>

                                    <div className='option-content' style={{gap: '8px'}}>
                                        <div className='manual-fee-details'>
                                            <div>Manual Upfront Fee: {`${item?.feeData?.upfrontFee ? `₹ ${item.feeData.upfrontFee}` : 'Not Available'}`}</div>
                                            <div>Manual Full Amount Fee: {`${item?.feeData?.totalFee ? `₹ ${item.feeData.totalFee}` : 'Not Available'}`}</div>
                                        </div>
                                        <div className='manual-fee-details'>
                                            <div>Digilocker Upfront Fee: {`${item?.feeData?.aadharUpfrontFee ? `₹ ${item.feeData.aadharUpfrontFee}` : 'Not Available'}`}</div>
                                            <div>Digilocker Full Amount Fee: {`${item?.feeData?.aadharTotalFee ? `₹ ${item.feeData.aadharTotalFee}` : 'Not Available'}`}</div>
                                        </div>
                                    </div> */}

                                </div>

                                <div className="option-details desktop">

                                    <div className='option-header'>
                                        <div className='option-header-zone'>{item.label}</div>
                                    </div>

                                    <div className='custom-divider'></div>

                                    <div className='option-content'>
                                        <div className='manual-fee-details'>
                                            <div>Onboarding Fee: {`${item?.ob_fee ?? 'Not Available'}`}</div>
                                            {/* <div>Manual Full Amount Fee: {`${item?.feeData?.totalFee ? `₹ ${item.feeData.totalFee}` : 'Not Available'}`}</div> */}
                                        </div>
                                        {/* <div className='vertical-line'/>
                                        <div className='aadhar-fee-details' style={{alignItems: 'center'}}>
                                            <div>Digilocker Upfront Fee: {`${item?.feeData?.aadharUpfrontFee ? `₹ ${item.feeData.aadharUpfrontFee}` : 'Not Available'}`}</div>
                                            <div>Digilocker Full Amount Fee: {`${item?.feeData?.aadharTotalFee ? `₹ ${item.feeData.aadharTotalFee}` : 'Not Available'}`}</div>
                                        </div>
                                        <div className='vertical-line'/>
                                        <div className='aadhar-fee-details'>
                                            <div>Joining Bonus: {`${item.feeData?.joiningBonus ? `₹ ${item.feeData.joiningBonus}` : 'Not Available'}`}</div>
                                            <div>Order Eligibility: {item?.feeData?.orderCountForJoiningBonus || 'Not Available'}</div>
                                        </div> */}
                                    </div>

                                </div>
                            </div>
                        )
                    })
                }
                
            </div>
            
        </div>

        <div className='button-container'>
            <Button className='submit-button' onClick={()=>submitZone()}>Next</Button>
        </div>
    </>
  )
}

export default BlinkitZoneSelection
