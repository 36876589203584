import axios from "axios";
import axiosInstance from './interceptorService';

export const fetchOnboardingQuestion = () => {
    const URL = process.env.REACT_APP_BACKEND_URL + '/feedbackQuestion';
    return axiosInstance.get(URL, { withCredentials: true });
};

export const submitOnboardingAnswer = ({answerId, questionId, mitraValue}) => {
    const URL = process.env.REACT_APP_BACKEND_URL + '/postMitraOnboardingData';

    const formData = new FormData();
    formData.append('answerId',answerId);
    formData.append('questionId',questionId);
    formData.append('answerValue', mitraValue);

    return axiosInstance.post(URL, formData, { withCredentials: true });
}
