import * as React from "react";
const HumanSearchIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={13}
    viewBox="0 0 13 13"
    fill="none"
    {...props}
  >
    <path
      d="M6.50008 6.50065C7.99585 6.50065 9.20841 5.28809 9.20841 3.79232C9.20841 2.29655 7.99585 1.08398 6.50008 1.08398C5.00431 1.08398 3.79175 2.29655 3.79175 3.79232C3.79175 5.28809 5.00431 6.50065 6.50008 6.50065Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.84717 11.9167C1.84717 9.82042 3.9326 8.125 6.5001 8.125"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.85834 11.5917C10.8156 11.5917 11.5917 10.8156 11.5917 9.85834C11.5917 8.90105 10.8156 8.125 9.85834 8.125C8.90105 8.125 8.125 8.90105 8.125 9.85834C8.125 10.8156 8.90105 11.5917 9.85834 11.5917Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9167 11.9167L11.375 11.375"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default HumanSearchIcon;
