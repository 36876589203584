import { Button, Card, Divider, Pagination } from 'antd'
import React, { useEffect, useState } from 'react'
import {UpCircleOutlined, DownCircleOutlined, PieChartOutlined} from '@ant-design/icons'
import SetTargetPopup from './SetTargetPopup';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';

function PerfDashSingleMemberCard({item, startDate, endDate, idx, onExpand, memberExpandedDetails=[], memberBoard=null}) {
    const [themeColor, setThemeColor] = useState();
    const [userRoleText, setUserRoleText] = useState();
    const [showTargetPopup, setShowTargetPopup] = useState(false);
    const [teamList, setTeamList] = useState([item]);
    const trackMoengageEvent = (event,obj) => {
		trackEvent(event, obj || {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
	}
    useEffect(() => {
      if(item?.roleName === 'Telecaller' || item?.roleName === 'Team Member') {
        setUserRoleText('TC');
        setThemeColor('#4BCF3F');
      } else if(item?.roleName === 'Team Leader') {
        setUserRoleText('TL');
        setThemeColor('#108EE9');
      } else if(item?.roleName?.toLowerCase() === 'admin') {
        setUserRoleText('Admin');
        setThemeColor('#D34613');
      }
    }, [item])

    const showSetTargetModalForSingleCandidate = (id) => {
        trackMoengageEvent('set-target-clicked-from-expanded-view', {user: id});
        setShowTargetPopup(true);
    }
    
  return (
    <>
    <SetTargetPopup 
        show={showTargetPopup}
        setShow={setShowTargetPopup}
        memberTarget={true}
        selectedMonth={null}
        monthlyTargetFTs={null}
        teamList={teamList}
        startDate={startDate}
        endDate={endDate}
    />
    <Card className='member-leaderboard-card' bodyStyle={{padding: '8px 18px'}} hoverable style={{cursor: 'pointer'}}>
        <div  style={{flexDirection: 'row', width: '100%'}} className='d-flex justify-content-between member-leaderboard-card-desktop' onClick={()=>onExpand(idx)}>
            <div style={{flexDirection: 'row', width: '100%'}} className='d-flex align-items-center'>
                <div className='d-flex align-items-center' style={{gap:'12px', width: '25%', textAlign: 'left'}}>
                    <div className='round-image-container' style={{backgroundColor: `${themeColor}0D`, color: themeColor, borderColor: themeColor}}>{item?.name?.[0]+item?.name?.[1]}</div>
                    <span style={{color: '#000000D9', fontSize:'16px', fontWeight: '600'}}>{item?.name}</span>
                </div>
                <div className='d-flex align-items-center' style={{gap:'8px', width: '40%'}}>
                    <div className='member-post-container'>
                        <div className="post-name" style={{backgroundColor: `${themeColor}0D`, color: themeColor, borderColor: themeColor}}>{userRoleText}</div>
                        {memberBoard &&
                        <div style={{fontSize:'12px'}}>{item?.team_name}</div>
                        }
                    </div>
                </div>
            </div>
            <div style={{gap: '48px', width: '20%'}} className='d-flex align-items-center justify-content-end'>
                {item.isExpanded && memberExpandedDetails?.length ? null :
                    <div className="ft-details" style={{color: '#00000073', fontSize: '14px'}}>
                        <span style={{fontWeight:'700',fontSize:'14px', color: '#000000A6'}}>
                        {item?.count ? item?.count : (item?.first_date_of_work || 0)} / {item?.target ? item?.target : 'Not Set'}
                        </span> FTs
                    </div>
                }
                {
                    item.isExpanded ? (
                        <UpCircleOutlined style={{fontSize: '22px', color: '#00000073'}}/>
                    ): (
                        <DownCircleOutlined style={{fontSize: '22px', color: '#00000073'}}/>
                    )
                }
                
            </div>
        </div>

        <div className='d-flex flex-column member-leaderboard-card-mobile' onClick={()=>onExpand(idx)} style={{gap:'12px'}}>
            <div style={{flex: 1}} className='d-flex justify-content-between align-items-center'>
                <div className='d-flex align-items-center' style={{gap:'8px'}}>
                    <div className='round-image-container' style={{backgroundColor: `${themeColor}0D`, color: themeColor, borderColor: themeColor}}>{item.name[0]+item.name[1]}</div>
                    <span style={{color: '#000000D9', fontSize:'16px', fontWeight: '600'}}>{item?.name}</span>
                </div>
                <div className="ft-details" style={{color: '#00000073', fontSize: '14px'}}><span style={{fontWeight:'700',fontSize:'14px', color: '#000000A6'}}>
                    {item?.count ? item?.count : (item?.first_date_of_work || 0)} / {item?.target ? item?.target : 'Not Set'}
                </span> FTs</div>
            </div>
            <div style={{flex: 2, gap: '48px'}} className='d-flex align-items-center justify-content-between'>
                <div className='member-post-container'>
                    <div className="post-name" style={{backgroundColor: `${themeColor}0D`, color: themeColor, borderColor: themeColor}}>{userRoleText}</div>
                    <span style={{fontSize:'12px'}}>{item?.team_name}</span>
                </div>
                {
                    item.isExpanded ? (
                        <UpCircleOutlined style={{fontSize: '22px', color: '#00000073'}}/>
                    ): (
                        <DownCircleOutlined style={{fontSize: '22px', color: '#00000073'}}/>
                    )
                }
                
            </div>
        </div>

        {
            item.isExpanded ?
            <>
                {memberExpandedDetails?.length ?
                <div className='member-leaderboard-card-details'>
                    {
                        memberExpandedDetails.map((member, index) => {
                            return (
                                <>
                                    <div style={{margin: '16px 0px'}}>
                                        <Divider/>
                                    </div>
                                    <div className='d-flex justify-content-between' >
                                        <span>{member?.month} {member?.year}</span>
                                        <div className="ft-details" style={{color: '#00000073', fontSize: '14px', marginRight: '48px'}}><span style={{fontWeight:'700',fontSize:'14px', color: '#000000A6'}}>
                                            {member?.count ? member?.count :(member?.first_date_of_work || 0)} / {member?.target ? member?.target : 'Not Set'}
                                        </span> FTs</div>
                                    </div>
                                </>
                            )
                        })
                    }
                </div> : null}
                <div style={{margin: '16px 0px'}}>
                    <Divider/>
                </div>
                {item?.roleName?.toLowerCase() !== 'admin' ?
                    <div className="d-flex align-items-center justify-content-end" style={{marginBottom: 8}}>
                        <Button 
                        onClick={()=>showSetTargetModalForSingleCandidate(item?.id)}
                        className='individual-settarget-btn d-flex align-items-center' icon={<PieChartOutlined />}>Set Target</Button>
                    </div>
                : null}
            </> : null
            }
    </Card>
    </>
  )
}

export default PerfDashSingleMemberCard