import { Button, Select } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import PlayIcon from '../../../static/svg/PlayIcon';
import PauseIcon from '../../../static/svg/PauseIcon';

function AudioSelectionCard({item, goToNextScreen, setPitchClient}) {
    const [audioURL, setAudioURL] = useState();
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const clientToAudioUrlMap = {
        'zomato': 'https://mesl-uploads-2.s3.ap-southeast-1.amazonaws.com/recordings_samvadini/zomato.mp3',
        'blinkit' : 'https://mesl-uploads-2.s3.ap-southeast-1.amazonaws.com/recordings_samvadini/blinkit.mp3',
        'swiggy': 'https://mesl-uploads-2.s3.ap-southeast-1.amazonaws.com/recordings_samvadini/swiggy.mp3'
    }
    const [selectedClientAudio, setSelectedClientAudio] = useState(null);
    const clientAudioOptions = [
        {
            label: 'Zomato',
            value: 'zomato'
        },
        {
            label: 'Swiggy',
            value: 'swiggy'
        },
        {
            label: 'Blinkit',
            value: 'blinkit'
        }
    ]

    const handlePlayPause = () => {
        if (isPlaying) {
        audioRef.current.pause();
        } else {
        audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleProgressChange = (event) => {
        const newProgress = event.target.value;
        audioRef.current.currentTime = (audioRef?.current?.duration * newProgress) / 100;
        setProgress(newProgress);
    };

    const getAudioUrl = () => {
        setAudioURL('https://uploads-a.vahan.co/mitra/1721381339025-MoM8pr-no-[audiotrimmer.com].mp3 ');
    }

    useEffect(() => {
        getAudioUrl();
    }, [])
    

    useEffect(() => {
        const audio = audioRef?.current;
        const updateProgress = () => {
            setProgress((audio.currentTime / audio.duration) * 100);
            if(((audio.currentTime / audio.duration) * 100) >=100) {
                setIsPlaying(false);
                setTimeout(() => {
                    setProgress(0); 
                }, 1000);
            }
        };
        
        if (audio) {
            audio.addEventListener('timeupdate', updateProgress);
            return () => {
                audio.removeEventListener('timeupdate', updateProgress);
            };
        }
    }, [audioURL])

    useEffect(() => {
      if(selectedClientAudio?.length) {
        setProgress(0);
        setAudioURL(clientToAudioUrlMap[selectedClientAudio]);
      }
    }, [selectedClientAudio])

    const nextScreen = () => {
        setPitchClient(item?.pitchType);
        goToNextScreen('preview');
    }
    

  return (
    <div className="shaded-tile-container audio-card shadow-highlighter shadow-highlighter" style={{height: '524px',maxHeight: '524px'}}>
        <div className="single-tile" style={{height: '524px'}}>

            <img src={item?.imageUrl} className='script-image-section' style={{width:'100%',height:"200px",objectFit: 'cover', border: '1px solid #000', borderRadius: '10px'}} />
            <div className="script-header mt-24 d-flex align-items-center justify-content-between">
                <span className='inter-bold-24-18 color-252526 text-align-start audio-script-header'>{item?.header}</span>
                {
                    item.isPreferred ? (
                        <div className="recommended-container d-flex align-items-center justify-content-center"><span className='inter-regular-8-16 plain-white'>Preferred</span></div>
                    ): null
                }
                
            </div>
            <span className='inter-regular-12-16 color-838383 mt-8'>{item?.subHeader}</span>

            <div className="audio-main-container mt-16">
                <div className="audio-details d-flex align-items-center" style={{gap:'12px'}}>
                    <img src='/image/Sound.png' style={{height:'18px',width:'18px'}} />
                    <div className="audio-text-static d-flex flex-column">
                        <span className='inter-semibold-16-24 color-252526 text-align-start'>Hear now</span>
                        <span className='inter-regular-12-16 color-838383 text-align-start'>You can hear script before choosing which one you need.</span>
                    </div>
                </div>

                {
                    item?.id === 'referredClient' ? (
                        <div className='audio-sub-container d-flex'>
                            <Select
                                style={{ width: '100%', textAlign: 'start', marginTop: '8px' }}
                                placeholder="Select a client"
                                value={selectedClientAudio}
                                onChange={(item) => setSelectedClientAudio(item)}
                                optionLabelProp="label"
                                options={clientAudioOptions}
                            />
                        </div>
                    ):(
                        <span className='inter-semibold-12-18 color-1D5FFF d-flex mt-12'>Script 1</span>
                    )
                }

                <div className="audio-player-container mt-16 d-flex flex-row align-items-center">
                    <audio ref={audioRef} src={audioURL} />
                    {
                        isPlaying ? <PauseIcon  onClick={handlePlayPause}/>: <div onClick={handlePlayPause}><PlayIcon /></div>
                    }
                    {/* <img src='/image/Pause.png' style={{height:'16px',width:'16px'}} onClick={handlePlayPause}/> */}
                    {/* <input
                        className="samvaadini-audio-progress-bar new-audio-bar"
                        type="range"
                        min="0"
                        max="100"
                        value={progress}
                        onChange={handleProgressChange}
                    /> */}

                    <div className="audio-bar">
                        <div className="track">
                            <div className="track-fill" style={{width: `${parseInt(progress)}%`, transition: 'width 0.3s ease-in-out'}}></div>
                            <div className='track-pointer' style={{left: `${parseInt(progress)}%`,transition: 'left 0.3s ease-in-out'}}>
                                <div className="pointer"></div>
                            </div>
                        </div>
                        <div className="time-indicator d-flex justify-content-between" style={{marginTop: '4px'}}>
                            <span className='inter-semibold-8-18 color-252526'>{audioRef?.current?.currentTime?.toFixed(1)}</span>
                            <span className='inter-semibold-8-18 color-252526'>{isNaN(audioRef?.current?.duration?.toFixed(1)) ? "": audioRef?.current?.duration?.toFixed(1)}</span>
                        </div>
                    </div>
                </div>
            </div>

            <Button onClick={()=>nextScreen()} className="primary-btn-styles-v2 mt-16 w-100 btn-44h"><span className='btn-text-semibold-16-20 plain-white'>Use this</span></Button>
        </div>
    </div>
  )
}

export default AudioSelectionCard