import React, { useEffect, useState, useContext } from 'react'
import { Divider, Button, Select, Dropdown, Menu, Pagination, Skeleton, Empty } from 'antd';
import { DownOutlined, ArrowUpOutlined, ArrowDownOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import './TeamLeaderViewStyles.scss'
import '../../../../../css/globalStyles.scss'
import AccountSidesheet from '../../common/AccountSidesheet';
import { getDataForTeamLeads } from '../../../../../service/accountService';
import TeamListItem from '../AdminView/TeamListItem';
import TeamMemberView from '../TeamMemberView/TeamMemberView';

const TeamLeaderView = ({ }) => {
    const { Option } = Select;
    const [showTeamSection, setShowTeamSection] = useState(true);
    const [showAccountDetailsSection, setShowAccountDetailsSection] = useState(false);
    const [teamSectionKeyValue, setTeamSectionKeyValue] = useState({
        key: "team",
        value: "Team",
    });
    const [accountDetailsKeyValue, setAccountDetailsKeyValue] = useState({
        key: "accountDetails",
        value: "Account Details",
    });
    const [loading, setLoading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState("Role")
    const [selectedStatusOrder, setSelectedStatusOrder] = useState("desc")
    const [teamMembersList, setTeamMembersList] = useState([])
    const [teamMembersListCopy, setTeamMembersListCopy] = useState([])
    const [totalTeamMembersList, setTotalTeamMembersList] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [filtersList, setFiltersList] = useState({
        limit: 10,
        offset: 0,
        search: "",
        teamListOnly: false,
        type: "",
        sortBy: "role desc",
        team: ""
    })
    const [currentTeamDetails, setCurrentTeamDetails] = useState(
        {
            team: "",
            teamId: "",
            team_leader: "",
            tlCount: 0,
            tlName: "",
            tcCount: 4,
            teamLeaderId: "",
            createdOn: "",
        }
    )
    const [showAddMemberSidesheet, setShowAddMemberSidesheet] = useState(false);
    const [newTeamMemberDetails, setNewTeamMemberDetails] = useState(
        {
            "id": "",
            "name": "",
            "phoneNumber": "",
            "photoUrl": null,
            "status": "",
            "dateOfJoining": null,
            "createdAt": "",
            "updatedAt": "",
            "deletedAt": null,
            "type": "",
            "sourcingCities": null,
            "sourcingLocalities": null,
            "homeCity": null,
            "managerMitraID": "",
            "teamLeadMitraID": null,
            "role": "teamMember",
            "email": null,
            "optInInfo": null,
            "accountManagerId": null,
            "acquisitionSource": "",
            "password": null,
            "bridgeNumber": null,
            "placementCountDivision": null
        }
    )

    useEffect(() => {
        fetchMembersList()
    }, [filtersList])

    const statusMenu = (
        <Menu className='jdc-menu'>
            <Menu.Item key="1" className='jdc-menu-item' onClick={() => { onStatusFilterApplied("role", "desc") }}>
                <ArrowDownOutlined className='jdc-menu-item-icon' /> Roles
            </Menu.Item>
            <Menu.Item key="2" className='jdc-menu-item' onClick={() => { onStatusFilterApplied("role", "asc") }}>
                <ArrowUpOutlined className='jdc-menu-item-icon' /> Roles
            </Menu.Item>
            {/* <Menu.Item key="3" className='jdc-menu-item' onClick={() => { onStatusFilterApplied("status", "desc") }}>
                <ArrowDownOutlined className='jdc-menu-item-icon' /> Status
            </Menu.Item>
            <Menu.Item key="4" className='jdc-menu-item' onClick={() => { onStatusFilterApplied("status", "asc") }}>
                <ArrowUpOutlined className='jdc-menu-item-icon' /> Status
            </Menu.Item> */}
        </Menu>
    );

    const fetchMembersList = () => {
        setLoading(true)
        let offset = currentPage == 1 ? 0 : ((currentPage - 1) * 10)
        const queryParams = {
            limit: filtersList.limit,
            offset: offset,
            sortBy: filtersList.sortBy ? filtersList.sortBy : "",
        };

        getDataForTeamLeads(queryParams).then((teamListRes) => {
            if (teamListRes?.data?.data?.teamList && teamListRes?.data?.data?.teamDetails) {
                setLoading(false)
                setCurrentTeamDetails(teamListRes.data.data.teamDetails)
                setTeamMembersList(teamListRes.data.data.teamList)
                setTeamMembersListCopy(teamListRes.data.data.teamList)
                setTotalTeamMembersList(teamListRes.data.data.totalCount)
            }
        }).catch((err) => {
            setLoading(false)
        })
    }

    const updateFiltersList = (updateKey, updateVal) => {
        let tempFilterList = filtersList;
        tempFilterList[updateKey] = updateVal;
        setFiltersList(JSON.parse(JSON.stringify(tempFilterList)))
    }

    const onStatusFilterApplied = (status, order) => {
        setSelectedStatus(status)
        setSelectedStatusOrder(order)
        updateFiltersList("sortBy", `${status} ${order}`)
    }

    const setTeamSection = () => {
        setShowTeamSection(true)
        setShowAccountDetailsSection(false)
    }

    const setAccountDetailsSection = () => {
        setShowTeamSection(false)
        setShowAccountDetailsSection(true)
    }

    const changeAccountSection = (key) => {
        switch (key) {
            case teamSectionKeyValue.key:
                setTeamSection();
                break;
            case accountDetailsKeyValue.key:
                setAccountDetailsSection();
                break;
        }
    }

    const onAddTeamMemberClicked = () => {
        setShowAddMemberSidesheet(true)
    }

    const updatedMemberDetails = () => {
        setNewTeamMemberDetails({
            "id": "",
            "name": "",
            "phoneNumber": "",
            "photoUrl": null,
            "status": "",
            "dateOfJoining": null,
            "createdAt": "",
            "updatedAt": "",
            "deletedAt": null,
            "type": "",
            "sourcingCities": null,
            "sourcingLocalities": null,
            "homeCity": null,
            "managerMitraID": "",
            "teamLeadMitraID": null,
            "role": "teamMember",
            "email": null,
            "optInInfo": null,
            "accountManagerId": null,
            "acquisitionSource": "",
            "password": null,
            "bridgeNumber": null,
            "placementCountDivision": null
        })
        fetchMembersList()
    }

    return (
        <div className='team-leader-view-parent'>
            <div className='tlv-tabs-section d-flex justify-content-between'>
                <div className='tlv-tabs d-flex show-only-desktop'>
                    <Button onClick={() => changeAccountSection(teamSectionKeyValue.key)} className={showTeamSection ? "tlv-selected-tab" : "tlv-unselected-tab"}>
                        {teamSectionKeyValue.value}
                    </Button>
                    <Button onClick={() => changeAccountSection(accountDetailsKeyValue.key)} className={showAccountDetailsSection ? "tlv-selected-tab" : "tlv-unselected-tab"}>
                        {accountDetailsKeyValue.value}
                    </Button>
                </div>

                <div className='show-only-mobile tlv-tabs-container-mobile'>
                    <div className='tlv-tabs d-flex '>
                        <Select
                            placeholder="Select Section"
                            style={{ width: '75%' }}
                            onChange={(jobType) => { changeAccountSection(jobType) }}
                            value={showTeamSection ? teamSectionKeyValue.key : accountDetailsKeyValue.key}
                        >
                            <Option value={teamSectionKeyValue.key} key={teamSectionKeyValue.key}>{teamSectionKeyValue.value}</Option>
                            <Option value={accountDetailsKeyValue.key} key={accountDetailsKeyValue.key}>{accountDetailsKeyValue.value}</Option>
                        </Select>
                    </div>
                </div>
            </div>

            <Divider />

            <div className='tlv-body-container'>
                {
                    showTeamSection ?
                        currentTeamDetails ?
                            <div>
                                <div className='tlv-team-details-container d-flex justify-content-between align-items-center'>
                                    <div className='tlv-team-details-txt-container d-flex align-items-start'>
                                        <span className='bold24-32 black85'>
                                            {currentTeamDetails.team ? currentTeamDetails.team : "No Name provided"}
                                        </span>
                                        <span className='bold12-20 black45'>
                                            {currentTeamDetails.createdOn ? <span>created on {moment(currentTeamDetails.createdOn).format("Do MMM YYYY")}</span> : null}
                                        </span>
                                    </div>
                                    <div className='tlv-team-details-btns-container d-flex justify-content-end'>
                                        {
                                            teamMembersListCopy && teamMembersListCopy.length ?
                                                <Button onClick={() => onAddTeamMemberClicked()} className={"tlv-add-btn vl-primary-btn d-flex align-items-center justify-content-center"} type="primary">
                                                    <PlusOutlined className='tds-btn-add-icon' />
                                                    <span className='bold14-22 plain-white'>Add Team Member</span>
                                                </Button>
                                                :
                                                null
                                        }

                                    </div>
                                </div>
                                {
                                    teamMembersListCopy && teamMembersListCopy.length > 0 ?
                                        <div className='tlv-tl-tc-info-container d-flex justify-content-between'>
                                            <div>
                                                <span className='tlv-tl-name blue-secondary2 semibold14-22'>TL</span> <span className='tlv-tl-name-value regular14-22 black65'>{currentTeamDetails.tlName}</span>
                                            </div>
                                            <div>
                                                <span className='tlv-tc-count semibold14-22 green-positive'>TCs</span> <span className='tlv-tc-count-value regular14-22 black65'> {currentTeamDetails.tcCount}</span>
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                            :
                            null
                        :
                        null
                }

                {
                    showTeamSection ?
                        !loading ?
                            teamMembersListCopy && teamMembersListCopy.length ?
                                <div>
                                    <div className='tlv-sort-pagination-container d-flex align-items-center justify-content-between'>
                                        <div className='tlv-sort-parent d-flex'>
                                            <div className='regular 14-22 black85'>
                                                Sort by
                                            </div>
                                            <div className='tlv-sort-container d-flex align-items-center jp-cp'>
                                                <Dropdown overlay={statusMenu} trigger={['hover']}>
                                                    <div className='tlv-sort d-flex align-items-center'>
                                                        {
                                                            selectedStatusOrder == "desc" ?
                                                                <ArrowDownOutlined className='red-negative'/>
                                                                :
                                                                selectedStatusOrder == "asc" ?
                                                                    <ArrowUpOutlined className='red-negative'/>
                                                                    :
                                                                    null
                                                        }
                                                        <span className='bold14-22 primary-orange2 tlv-sort-txt capital-case-txt'>
                                                            {selectedStatus}
                                                        </span>
                                                        <DownOutlined className='tlv-sort-action-small-icon' />
                                                    </div>
                                                </Dropdown>
                                            </div>
                                        </div>

                                        <Pagination
                                            total={totalTeamMembersList}
                                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} members`}
                                            defaultPageSize={10}
                                            defaultCurrent={currentPage}
                                            responsive={true}
                                            showLessItems={true}
                                            onChange={(page, pagesize) => { setCurrentPage(page) }}
                                            showSizeChanger={false}
                                        />
                                    </div>

                                    <div className='tlv-team-members-list-container'>
                                        {teamMembersListCopy && teamMembersListCopy.length > 0
                                            ?
                                            teamMembersListCopy.map((member, memberInd) => {
                                                return (
                                                    <div key={memberInd}>
                                                        <TeamListItem listItem={member} updatedMemberDetails={fetchMembersList} role={"all"} source={"TeamLeaderView"} />
                                                    </div>
                                                )
                                            }) : null}
                                    </div>
                                </div>
                                :
                                <Empty />
                            :
                            <Skeleton />
                        :
                        <TeamMemberView source={"TeamLeaderView"} />

                }

            </div>

            {showAddMemberSidesheet &&
                <AccountSidesheet memberDetails={newTeamMemberDetails} isDrawerOpen={showAddMemberSidesheet} closeDrawer={() => setShowAddMemberSidesheet(false)} memberDetailsUpdated={updatedMemberDetails} mode={"add"}
                    teamName={currentTeamDetails.team} teamId={currentTeamDetails.teamId} source={"TeamLeaderView"} />
            }

        </div>
    )
}

export default TeamLeaderView; 