import axios from 'axios';
import axiosInstance from './interceptorService';
import CONSTANTS from "../constants/constants.js";

export const verifyOTP = async (phoneNumber, otp) => {
    const data = new FormData();
    data.append('phoneNumber', phoneNumber);
    data.append('otp', otp);
    const url = process.env.REACT_APP_BACKEND_URL + '/login';
    return axiosInstance.post(url, data, { withCredentials: true });
};

export const validateSession = () => {
    const url = process.env.REACT_APP_BACKEND_URL + '/auth';
    return axiosInstance.get(url, { withCredentials: true });
};

export const inValidateSession = () => {
    const url = process.env.REACT_APP_BACKEND_URL + '/logout';
    return axiosInstance.get(url, { withCredentials: true });
};

export const getConversationNotification = (mitraPhoneNumber) => {
    const headers = {
        headers:
        {
            'Authorization': 'Basic ' + CONSTANTS.FD_TOKEN,
            'Accept-Encoding': 'gzip',
            'Content-Type': 'application/json; charset=utf-8',
        }
    };
    let customQuery = 'cf_your_registered_phone_number:' + mitraPhoneNumber;
    customQuery += '%20OR%20cf_manager_phone_number:'+ mitraPhoneNumber
    const url = 'https://vahan-mitraleadersupport.freshdesk.com/api/v2/search/tickets?query="' + customQuery + '"';
    return axios.get(url, headers);
}

export const validateAccountManagerLogin = (token) => {
    const data = new FormData();
    data.append('token', token);
    const url = process.env.REACT_APP_BACKEND_URL + '/accountManagerLogin';
    return axiosInstance.post(url, data, { withCredentials: true });
};

export const validateDCLogin = (token) => {
    const data = new FormData();
    data.append('token', token);
    const url = process.env.REACT_APP_BACKEND_URL + '/dCLogin';
    return axiosInstance.post(url, data, { withCredentials: true });
};
