import React from "react";

function ThunderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="33"
      fill="none"
      viewBox="0 0 34 33"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M8.657 18.26h4.249v9.9c0 2.31 1.251 2.777 2.777 1.045L26.092 17.38c1.279-1.444.742-2.64-1.196-2.64h-4.249v-9.9c0-2.31-1.251-2.777-2.777-1.045L7.46 15.62c-1.264 1.458-.728 2.64 1.197 2.64z"
      ></path>
    </svg>
  );
}

export default ThunderIcon;