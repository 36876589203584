import React, { useState } from 'react';
import '../styles/SelectScript.css'
import { Button } from 'antd';
import AudioSelectionCard from '../components/AudioSelectionCard';

function SelectScript({goToNextScreen, setPitchClient}) {
    const [audioTypes, setAudioTypes] = useState([
        {
            id: 'recommended',
            header: 'Recommended Script',
            isPreferred: true,
            subHeader: 'Most Used and Best Conversion.',
            imageUrl: '/image/recommended-script.png',
            pitchType: 'default'
        },
        {
            id: 'referredClient',
            header: 'Referred Client',
            isPreferred: false,
            subHeader: 'Pitch only the referred client.',
            imageUrl: '/image/referred-client.jpg',
            pitchType: 'sameAsReferred'
        }
    ]);
  return (
    <>
        <div className="step-info-header">Select the Script</div>
        <div className="step-info-sub-header">Choose the one that suits you.</div>

        <div className="multi-script-container d-flex">

            {
                audioTypes.map((item) => (
                    <AudioSelectionCard 
                    key={item.id} 
                    item={item} 
                    goToNextScreen={goToNextScreen} 
                    setPitchClient={setPitchClient}
                    />
                ))
            }
        </div>
    </>
  )
}

export default SelectScript