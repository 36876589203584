import React, { useState, useEffect, useRef } from 'react'
import '../../style/BulkReferralStyle.scss'
import SideSelector from "../../../common/siderSelector";
import { DeleteOutlined, ArrowRightOutlined, CheckCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { getpopularCities, getClientsForBulkReferral } from './../../../../service/bulkActionService'
import * as homeService from './../../../../service/homeService';
import { Divider, Button } from 'antd';
import CONSTANTS from '../../../../constants/constants';

const BulkReferralStepOne = ({
    mitra,
    selectedCityFromParent,
    selectedClientDataFromParent,
    returnStepOneCity,
    returnStepOneClientData,
    goToNextStep,
    isAiFilteringEnabled, // this is the variable used to tweak FE views
    setIsAiFilteringEnabled,
    enableAIFiltering, // this is the query param
    setWithoutReferral,
    clearedStep }) => {
    const siderSelectRef = useRef();
    const [cityList, setCityList] = useState([]);
    const [selectedCity, setSelectedCity] = useState(selectedCityFromParent)
    const [selectedClients, setSelectedClients] = useState([]);// Store selected clients for each dropdown
    const [selectedJobLocations, setSelectedJobLocations] = useState([]); // Store selected job locations for each dropdown
    const [clientList, setClientList] = useState([]);
    const [jobLocationList, setJobLocationList] = useState([])
    const [selectedClientExcludedFromAIFiltering, setSelectedClientExcludedFromAIFiltering] = useState(false);

    const [isNextDisabled, setIsNextDisabled] = useState(true);

    const [allClientData, setAllClientData] = useState([
        {
            companyName: "",
            selectedJobLocation: "",
            jobLocationList: [],
            jobIDList: [],
            jobDemandIDList: []
        }
    ])
    useEffect(() => {
        getpopularCities().then((response) => {
            setCityList(response.data.premiumCities);
        });

        // Prefill city and client data only when selectedCityFromParent is available
        if (selectedCityFromParent) {
            fetchClientsAndCategoriesForSelectedCity(selectedCityFromParent, true);
        }
        
    }, [selectedCityFromParent])
    
    useEffect(() => {
        if(Object.keys(allClientData).length > 0){
            returnStepOneClientData(allClientData)
        }
    }, [allClientData]);


    useEffect(() => {
        const checkIfNextDisabled = () => {
            // Check if city is selected
            if (!selectedCity) {
                return true;
            }
    
            // Check if the first client has name and location
            if (!allClientData[0]?.companyName || !allClientData[0]?.selectedJobLocation) {
                return true;
            }
    
            // Ensure all clients have both name and location
            if (!isCurrentClientDataComplete()) {
                return true;
            }
    
            // All checks passed, enable the button
            return false;
        };
    
        setIsNextDisabled(checkIfNextDisabled()); 
    }, [selectedCity, allClientData]); 
    
    
    useEffect(() => {
        getpopularCities().then((response) => {
            setCityList(response.data.premiumCities)
        })

        if (selectedCityFromParent) {
            fetchClientsAndCategoriesForSelectedCity(selectedCityFromParent, true)
        }

    }, [])

    const setAndReturnVal = (valFor = "", setVal) => {
        switch (valFor) {
            case "city":
                setSelectedCity(setVal);
                returnStepOneCity(setVal);
                break;
            case "clientData":
                setAllClientData(JSON.parse(JSON.stringify(setVal)))
                returnStepOneClientData(JSON.parse(JSON.stringify(setVal)))
        }
    }

    const handleCityChange = (city) => {
        setAndReturnVal("city", city);
        setJobLocationList([]);

         // Clear the allClientData list properly
        const emptyClientData = [
            {
                companyName: "",
                selectedJobLocation: "",
                jobLocationList: [],
                jobIDList: [],
                jobDemandIDList: []
            }
        ];
        setAllClientData(emptyClientData);

        // Send the empty client data to the parent component
        returnStepOneClientData(emptyClientData);
    
        fetchClientsAndCategoriesForSelectedCity(city, false);
        returnStepOneCity(city);
        return 
      };

    const fetchClientsAndCategoriesForSelectedCity = (city) => {
        if (city) {
            getClientsForBulkReferral(city,isAiFilteringEnabled, mitra.id).then((result) => {
                if (result && result.data.viewData.companies) {
                    const companyAndCategories = result.data.viewData.companies;

                    // Combine Delivery and Driver lists
                    let mergedClientList = [
                        ...(companyAndCategories.Delivery || []),
                        ...(companyAndCategories.Driver || [])
                    ];


                    // Set the combined client list
                    setClientList(mergedClientList);

                    if (selectedClientDataFromParent?.length) {
                        // preFillClientAndLocationFromParent(mergedClientList)
                        preFillClientAndLocationFromParent()
                    }
                }
            });
        }
    };

    // const preFillClientAndLocationFromParent = (allClientsListFromAPI) => {
    const preFillClientAndLocationFromParent = () => {
        if (selectedClientDataFromParent) {
            // fetchClientWiseJobLocations(selectedClientDataFromParent)
            setAllClientData(selectedClientDataFromParent)
        }

    }

    const handleClientChange = (client, index) => {
        // Update the company name for the current index
        const updatedClientLocationList = allClientData;
        updatedClientLocationList[index].companyName = client;
        // Clear the job location for this client
        updatedClientLocationList[index].selectedJobLocation = "";
        setAllClientData(updatedClientLocationList);
        getJobLocations(selectedCity, client, index);
    };
    
    

    const getFilteredClientList = (arr1, arr2) => {
        // Get a list of all company names from master array
        const companyNamesFromArray1 = allClientData.map(item => item.companyName);
      
        // Filter out items from clientList that are not present in master array's companyName
        return clientList.filter(company => !companyNamesFromArray1.includes(company));
      };

    const getJobLocations = (city, company, index) => {
        if (city === 'Work from Home') {
            setJobLocationList(['Work from Home']);
        } else {
            if (city && company) {
                homeService.getJobLocations(city, company).then((result) => {
                    if (result && result.data.viewData.jobLocations) {
                        let jobLocations = result.data.viewData.jobLocations;
                        jobLocations = jobLocations.map((c) => c.name);
                        if (allClientData?.length) {
                            let tempLocationArr = allClientData
                            tempLocationArr[index].jobLocationList = jobLocations
                            setAllClientData(JSON.parse(JSON.stringify(tempLocationArr)))
                        }
                    }
                });
            }
        }
    };

    const handleJobLocationChange = (jobLocation, index) => {
        const tempLocationArr = allClientData
        tempLocationArr[index].selectedJobLocation = jobLocation
        setAllClientData(JSON.parse(JSON.stringify(tempLocationArr)))
        getJobs( selectedCity, allClientData?.[index]?.companyName, jobLocation, index);
    };


    const getJobs = (city, companyName, jobLocation, index) => {
        const tempIDArr = allClientData
        if (city && companyName && jobLocation) {
            homeService.getJobs( null ,city, companyName, jobLocation).then((result) => {
                if (result && result.data.viewData.jobs) {
                    tempIDArr[index].jobIDList = result.data.viewData.jobs?.[0]?.jobId;
                    tempIDArr[index].jobDemandIDList = result.data.viewData.jobs?.[0]?.jobDemandId;
                    setAllClientData(JSON.parse(JSON.stringify(tempIDArr)))
                    setAndReturnVal("clientData", allClientData);
                }
            });
        }
    };

    const continueWOReferral = () => {
        setWithoutReferral(true);
        goToNextStep()
    }
    
    

    const hasEmptyField = () => {
        if (!selectedCity) {
            return true;
        }

        if (!selectedClients) {
            return true
        }

        if (!selectedJobLocations) {
            return true
        }
    }

    const onNextClick = () => {
        returnStepOneClientData(allClientData)
        if (!hasEmptyField()) {
            goToNextStep()
        }
    }

    const handleAddClient = () => {
        let newTempArrItem = {
            companyName: "",
            selectedJobLocation: "",
            jobLocationList: [],
            jobId: "",
            jobDemandId: ""
        }
        let tempArrItem = allClientData;
        tempArrItem.push(newTempArrItem);
    
        setAllClientData(tempArrItem);
        setSelectedJobLocations([...selectedJobLocations, null]); // Add a new entry for job locations
    };

    const handleRemoveClient = (index) => {
        setAllClientData(prevAllClientData => prevAllClientData.filter((_, i) => i !== index));
    };
    
    const isCurrentClientDataComplete = () => {
        // Check if every client has both name and location filled
        return allClientData.every(clientData => clientData.companyName && clientData.selectedJobLocation);
    };
    

    return (
        <div className='br-step-parent br-step-one-dropdowns-parent d-flex'>
            <div className='br-step-dropdown-fields justify-content-center'>
            
                <div className='br-step-one-section justify-content-center'>
                
                    <div className="br-step-one-dropdown">
                    <div className="br-step-one-city-name">Select City</div>
                        <SideSelector
                            ref={siderSelectRef}
                            selectorData={cityList}
                            placeholder="Select City"
                            required
                            onChange={(city) => { handleCityChange(city) }}
                            autoFocus
                            selectedValue={selectedCity}
                        />
                    </div>
                </div>

                <div className='br-step-one-section justify-content-center'>

                    <div className='parent-container'>
                        {allClientData.map((singleClientData, singleClientInd) => (
                            <div key={singleClientInd} className="parent-client-div">
                                <div className="remove-client-container">

                                    {!enableAIFiltering ? (
                                        <div className='client_index_text'>
                                            <span style={{ color: 'red' }}>*</span>
                                            Select Client
                                            {singleClientData?.companyName && singleClientData?.selectedJobLocation && (
                                                <CheckCircleOutlined className='green-tick-icon' />
                                            )}
                                        </div>
                                    ) : singleClientInd >= 0 ? (
                                        <div className='client_index_text'>
                                            <span style={{ color: 'red' }}>{singleClientInd === 0 ? '*' : ''}</span>
                                            Client {singleClientInd + 1}
                                            {singleClientData?.companyName && singleClientData?.selectedJobLocation && (
                                                <CheckCircleOutlined className='green-tick-icon' />
                                            )}
                                        </div>
                                    ) : null}
                                    {singleClientInd !== 0 && (
                                        <div className='remove-client-btn' onClick={() => handleRemoveClient(singleClientInd)}>
                                            <img src='/image/deleteOutlined.webp' alt='remove-image' className='remove-image-ai-filtering' style={{ objectFit: 'contain' }} />
                                            <div className='semibold14-22 red-negative'>Remove</div>
                                        </div>
                                    )}
                                </div>

                                <div className='d-flex flex-column br-step-one-dropdown'>
                                    <div>
                                        <SideSelector
                                            className="side-selector-container"
                                            selectorData={getFilteredClientList(singleClientInd)}  // Filtered client list
                                            placeholder="Select Client"
                                            required={singleClientInd === 0}
                                            onChange={(e) => handleClientChange(e, singleClientInd)} // Pass index here
                                            disabled={clientList && clientList.length === 0 && !selectedClients[singleClientInd]}
                                            selectedValue={singleClientData?.companyName || undefined}
                                            modifiedClassName={enableAIFiltering === 'true' && selectedClientExcludedFromAIFiltering ? 'error-border' : ''}
                                        />
                                    </div>
                                    {
                                        enableAIFiltering === 'true' && selectedClientExcludedFromAIFiltering ? (
                                            <span className='regular14-22 red-negative txt-left' style={{ marginTop: '-12px', marginBottom: '16px' }}>
                                                AI Filtering is currently not available for selected client
                                            </span>
                                        ) : null
                                    }
                                </div>
                                <div className="br-step-one-dropdown">
                                    <SideSelector
                                        selectorData={singleClientData?.jobLocationList}
                                        placeholder="Select Job Location"
                                        required
                                        onChange={(e) => handleJobLocationChange(e, singleClientInd)} // Pass index here
                                        disabled={!singleClientData?.companyName} // Disable if no client is selected
                                        selectedValue={singleClientData?.selectedJobLocation || undefined} // Get the selected value from the array
                                    />
                                </div>

                            </div>
                        ))}

                        {enableAIFiltering && allClientData.length < clientList.length && isCurrentClientDataComplete() && (
                            <div className='add-client-btn' onClick={handleAddClient}>
                                <PlusOutlined className='br-step-btn-img-orange' />
                                <div className='semibold14-22 primary-orange2'>Add Client</div>
                            </div>
                        )}

                    </div>
                </div>
            </div>

            <div className='br-step-action-fields d-flex mobile-bottom-fixed'>
                <Divider style={{ margin: '4px 0px 16px' }}></Divider>
                <div className='br-step-btns d-flex'>

                    {
                        enableAIFiltering ? null : (
                            <Button className='br-btn-container br-clear-all-btn d-flex align-items-center justify-content-center' onClick={() => continueWOReferral()}>
                                Continue Without Referral
                                <ArrowRightOutlined className='br-step-btn-img-orange' />
                            </Button>
                        )
                    }

                    <Button className='br-btn-container br-next-btn d-flex align-items-center justify-content-center text-ellipsis' onClick={() => onNextClick()} disabled={isNextDisabled}>
                        {
                            enableAIFiltering === 'true' && selectedClientExcludedFromAIFiltering ? <span className='text-ellipsis'>Continue Without AI Filtering</span> : 'Next'
                        }
                        <ArrowRightOutlined className='br-step-btn-img-white' />
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default BulkReferralStepOne; 