import { Button, Select, notification } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { getSwiggyProfileData, updateSwiggyData } from '../../../../service/SwiggyAdditionalInfoService';
import { AppContext } from '../../../../App';

function WorkPreference({goToNextScreen, lead, submissionDetails}) {
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const [selectedVehicleTypeObj, setSelectedVehicleTypeObj] = useState(null);
  const [selectedOrderDeliveryEntity, setSelectedOrderDeliveryEntity] = useState(null);
  const [selectedWorkTimings, setSelectedWorkTimings] = useState(null);

  const [workPreferenceOptions, setWorkPreferenceOptions] = useState([]);
  const [workTimingOptions, setWorkTimingOptions] = useState([]);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState([]);
  const [noWorkTimings, setNoWorkTimings] = useState(false);
  const {mitraReducer,mitraDispatch, setAlert,setSpin } = useContext(AppContext);

  const showLoader = (value) => {
    setSpin({
        loading: value, //false or true when actived
        delay: null,
        tip: 'Loading...'  //Loading copy here
    })
}

  // const populateWorkPreferenceOptions = () => {
  //   const data = {
  //     "data": {
  //         "getFleets": {
  //             "title": "Select the orders you would like to deliver",
  //             "description": "Your selection will be valid for 30 days. You can change your preferences after that.",
  //             "options": [
  //                 {
  //                     "type": "IM_DDE",
  //                     "title": "Instamart Grocery",
  //                     "description": "Quick orders within 4kms from same Swiggy store",
  //                     "disclaimer": null,
  //                     "videoId": "230805",
  //                     "joiningBonus": 6000,
  //                     "isSelected": true,
  //                     "isAvailable": true,
  //                     "recommended": true
  //                 },
  //                 {
  //                     "type": "SHARED",
  //                     "title": "Food, Grocery & Parcels",
  //                     "description": "All orders in your selected and nearby areas",
  //                     "disclaimer": null,
  //                     "videoId": "230806",
  //                     "joiningBonus": 7500,
  //                     "isSelected": false,
  //                     "isAvailable": true,
  //                     "recommended": false
  //                 }
  //             ],
  //             "success": true
  //         }
  //     },
  //     "success": true,
  //     "message": ""
  //   }

  //   const options = data?.data?.getFleets?.options;

  //   setWorkPreferenceOptions([...options]);
  // }

  // const populateWorkTimingOptions = () => {
  //   const data = {
  //     "data": {
  //         "getShiftTimings": {
  //             "title": "Select your preferred work timings",
  //             "description": "Your selection will be valid for 30 days. You can change your preferences after that.",
  //             "options": [
  //                 {
  //                     "title": "Morning shift",
  //                     "description": "6 days a week",
  //                     "isSelected": false,
  //                     "isAvailable": true,
  //                     "shiftType": "FULL_TIME",
  //                     "employmentType": "PDP",
  //                     "shiftTiming": "MORNING",
  //                     "expectedDutyHours": 8,
  //                     "maxExpectedAmount": 8350,
  //                     "period": "WEEKLY"
  //                 },
  //                 {
  //                     "title": "Evening shift",
  //                     "description": "6 days a week",
  //                     "isSelected": false,
  //                     "isAvailable": true,
  //                     "shiftType": "FULL_TIME",
  //                     "employmentType": "PDP",
  //                     "shiftTiming": "EVENING",
  //                     "expectedDutyHours": 8,
  //                     "maxExpectedAmount": 8350,
  //                     "period": "WEEKLY"
  //                 },
  //                 {
  //                     "title": "Part time | 4-6 hours",
  //                     "description": "6 days a week",
  //                     "isSelected": false,
  //                     "isAvailable": true,
  //                     "shiftType": "PART_TIME",
  //                     "employmentType": "PDP",
  //                     "shiftTiming": null,
  //                     "expectedDutyHours": 8,
  //                     "maxExpectedAmount": 6500,
  //                     "period": "WEEKLY"
  //                 }
  //             ],
  //             "success": true
  //         }
  //     },
  //     "success": true,
  //     "message": ""
  // }

  //   const options = data?.data?.getShiftTimings?.options;

  //   setWorkTimingOptions([...options]);
  // }

  const onWorkTimingOptionSelect = (item) => {
    setSelectedWorkTimings(item);
    
    const WorkTimings = [...workTimingOptions];
    for (let i = 0; i < WorkTimings.length; i++) {
        if (WorkTimings[i].title === item.title) {
            WorkTimings[i].isSelected = true;
        } else {
            WorkTimings[i].isSelected = false;
        }
    }

    setWorkTimingOptions([...WorkTimings]);
  }

  const getShiftTimingsData = async() => {
    showLoader(true);
    try {
      const payload = {
        "phoneNumber": lead?.phoneNumber,
        "dataToFetch" : {
            // "getFleets": {},
            "getShiftTimings": {},
        }
      }
  
      const response = await getSwiggyProfileData(payload);
      if(response?.data?.data?.getShiftTimings?.options) {
        let options = response?.data?.data?.getShiftTimings?.options;
        const availableOptions = options.filter((item) => item.isAvailable);
        if(availableOptions?.length) {
          setNoWorkTimings(false);
        } else {
          setNoWorkTimings(true);
        }
        setWorkTimingOptions([...options]);
      }
      showLoader(false);
    } catch(e) {
      showLoader(false);
      notification['error']({
        message: 'Error fetching form data.'
      })
    }
    
  }

  const submitWorkPreferenceInformation = async(item) => {
    showLoader(true);
      try {

        const fleetData = item;
        fleetData.isSelected = true;

        const payload= {
          phoneNumber: lead?.phoneNumber,
          dataToUpdate: {
            "postFleets": [fleetData],
          }
        }

        const vehicleTypeSubmissionResponse = await updateSwiggyData(payload);

        if(vehicleTypeSubmissionResponse?.data?.data?.[0]?.swiggyFleet?.statusMessage === 'SUCCESS') {
          getShiftTimingsData();
        }
        
      } catch(e) {
        showLoader(false);
        notification['error'] ({
          message: 'Error submitting work preference information.'
        })
      }
  }

  const onWorkpreferenceOptionSelect = (item) => {
    setSelectedOrderDeliveryEntity(item);

    // code to submit work preference
    submitWorkPreferenceInformation(item);
    
    const workPreference = [...workPreferenceOptions];
    for (let i = 0; i < workPreference.length; i++) {
        if (workPreference[i].type === item.type) {
            workPreference[i].isSelected = true;
        } else {
            workPreference[i].isSelected = false;
        }
    }

    setWorkPreferenceOptions([...workPreference]);
  }

  const allRequiredFieldsHaveValues = () => {
    if(!selectedOrderDeliveryEntity || !selectedWorkTimings) {
      if(!selectedOrderDeliveryEntity) {
        notification['error']({
          message: 'Choose work preference.',
        });
      } else if(!selectedWorkTimings) {
        notification['error']({
          message: 'Choose work timings.',
        });
      } else {
        notification['error']({
          message: 'Select all the fields to continue.',
        });
      }
      return false;
    } else return true;
  }

  const onSubmitWorkPreferenceDetails = async() => {
    showLoader(true);
    if(allRequiredFieldsHaveValues()) {
      try {

        const workTimings = selectedWorkTimings;
        workTimings.isSelected = true;

        const payload= {
          phoneNumber: lead?.phoneNumber,
          dataToUpdate: {
            "postShiftTimings": [workTimings],
          }
        }

        const shiftTimingsSubmissionResponse = await updateSwiggyData(payload);
        goToNextScreen();
        showLoader(false);
      } catch(e) {
        showLoader(false);
        notification['error'] ({
          message: 'Error submitting work preference details.'
        })
      }
    }
    showLoader(false);
  }

  const getFleetData = async() => {
    showLoader(true);
    try {
      const payload = {
        "phoneNumber": lead?.phoneNumber,
        "dataToFetch" : {
            "getFleets": {},
            // "getShiftTimings": {},
        }
      }
  
      const response = await getSwiggyProfileData(payload);
  
      if(response?.data?.data?.getShiftTimings?.options) {
        setWorkTimingOptions([...response?.data?.data?.getShiftTimings?.options]);
      }
  
      if(response?.data?.data?.getFleets?.data) {
        setWorkPreferenceOptions([...response?.data?.data?.getFleets?.data]);

        const fleetData = [...response?.data?.data?.getFleets?.data];

        for (let i = 0; i < fleetData.length; i++) {
          if(fleetData[i].isSelected) {
            getShiftTimingsData();
          }
        }
      }
      showLoader(false);
    } catch(e) {
      showLoader(false);
      notification['error']({
        message: 'Error fetching form data.'
      })
    }
    
  }

  useEffect(() => {
    getFleetData();
  }, [])


  const autoFillInformationIfAvailable = () => {
    if(submissionDetails?.swiggyElementMap?.swiggyFleet?.value) {
      const parsedFleet = JSON.parse(submissionDetails?.swiggyElementMap?.swiggyFleet?.value);
      setSelectedOrderDeliveryEntity(parsedFleet);
      // submitWorkPreferenceInformation(parsedFleet);
      getShiftTimingsData();
    }

    if(submissionDetails?.swiggyElementMap?.swiggyShiftTiming?.value) {
      const parsedFleet = JSON.parse(submissionDetails?.swiggyElementMap?.swiggyShiftTiming?.value);
      setSelectedWorkTimings(parsedFleet);
    }
  }

  useEffect(() => {
    autoFillInformationIfAvailable();
  }, [submissionDetails])
  

  // const getFleets = async() => {
  //   showLoader(true);
  //   try {
  //     const payload = {
  //       "phoneNumber": lead?.phoneNumber,
  //       "dataToFetch" : {
  //           "getFleets": {},
  //           "getShiftTimings": {},
  //       }
  //     }
  
  //     const response = await getSwiggyProfileData(payload);

  //     if(response?.data?.data?.getFleets?.options) {
  //       setWorkPreferenceOptions([...response?.data?.data?.getFleets?.options]);
  //     }
  //     showLoader(false);
  //   } catch(e) {
  //     showLoader(false);
  //     notification['error']({
  //       message: 'Error fetching work preference details.'
  //     })
  //   }
  // }
  
  // const onSelectVehicleType = async(vehicleType, vehicleTypeObject) => {
    
  //   setSelectedVehicleType(vehicleType);
  //   setSelectedVehicleTypeObj(vehicleTypeObject);

  //   showLoader(true);
  //   vehicleTypeObject.isSelected = true;
  //   try {

  //     const payload= {
  //       phoneNumber: lead?.phoneNumber,
  //       dataToUpdate: {
  //         "postVehicles": [vehicleTypeObject]
  //       }
  //     }
  //     const vehicleTypeSubmissionResponse = await updateSwiggyData(payload);
  //     showLoader(false);

  //     if(vehicleTypeSubmissionResponse?.data?.data?.[0]?.swiggyVehicleType?.statusMessage === 'SUCCESS') {
  //       getFleets();
  //     } else {
  //       notification['error'] ({
  //         message: 'Error submitting vehicle type details.'
  //       })
  //     }
  //   } catch(e) {
  //     showLoader(false);
  //     notification['error'] ({
  //       message: 'Error submitting vehicle type details.'
  //     })
  //   }
  // }

  return (
    <>
      {/* <div className='pan-verification-container'>
        <div className='container-header'>Vehicle type</div>
        <div className='pan-form-container'>
          <div className='label mt-24'>Vehicle type<sup>*</sup></div>
          <Select
              style={{ width: '100%', textAlign: 'start', marginTop: '8px' }}
              placeholder="Select vehicle type"
              value={selectedVehicleType}
              onChange={(v,o)=>onSelectVehicleType(v,o)}
              optionLabelProp="label"
              options={vehicleTypeOptions}
          />
        </div>
      </div> */}
      {noWorkTimings ?
        <div className='error-shift-timings'>
          Oops! There's no demand in this work area. Please select a different work area.
        </div>
      : null}

      <div className='pan-verification-container'>
        <div className='container-header'>Work Preference<sup>*</sup></div>
        <div className='pan-form-container'>
            {
              workPreferenceOptions.map((item, index) => {
                  return (
                      <div key={index} className='option-item' onClick={()=>onWorkpreferenceOptionSelect(item)} style={item.isSelected ? {backgroundColor: '#F7F7F7'}:{}}>

                          <div className="radio-section">
                              <div className='option-radio'>
                                  {
                                      item.isSelected ? <div className="radio-fill"></div>: null
                                  }
                                  
                              </div>
                          </div>

                          <div className="option-details mobile desktop">

                              <div className='option-header' style={{gap: '8px'}}>
                                  <div className='option-header-zone'>{item.title} - {item.description}</div>
                                  {/* <div className='option-header-bonus-details'>
                                      <div>Joining Bonus: ₹{item?.feeData?.joiningBonus || 0}</div>
                                      <div>Order Eligibility: {item?.feeData?.orderCountForJoiningBonus || 0}</div>
                                  </div> */}
                              </div>

                              <div className='custom-divider'></div>

                              <div className='option-content' style={{gap: '8px'}}>
                                  <div className='manual-fee-details'>
                                      <div>Joining Bonus: ₹{item?.joiningBonus || 0}</div>
                                      {/* <div>Manual Full Amount Fee: ₹{item?.feeData?.totalFee || 0}</div> */}
                                  </div>
                                  {/* <div className='manual-fee-details'>
                                      <div>Digilocker Upfront Fee: ₹{item?.feeData?.aadharUpfrontFee || 0}</div>
                                      <div>Digilocker Full Amount Fee: ₹{item?.feeData?.aadharTotalFee || 0}</div>
                                  </div> */}
                              </div>

                          </div>
                      </div>
                  )
              })
            }
        </div>
      </div>

      {
        selectedOrderDeliveryEntity ? (
          <div className='pan-verification-container'>
        <div className='container-header'>Work Timings<sup>*</sup></div>
        <div className='pan-form-container'>
            {
              workTimingOptions.map((item, index) => {
                  return (
                      <div key={index} className='option-item' onClick={()=> item.isAvailable ? onWorkTimingOptionSelect(item) : {}}
                      style={
                        {cursor: item.isAvailable ? 'pointer' : 'not-allowed',
                        backgroundColor: (item.isSelected || !item.isAvailable) ? '#F7F7F7': '#FFFFFF'}}>
                          <div className="radio-section">
                              <div className='option-radio'>
                                  {
                                      item.isSelected ? <div className="radio-fill"></div>: null
                                  }
                              </div>
                          </div>

                          <div className="option-details mobile desktop">

                              <div className='option-header' style={{gap: '8px', color: item.isAvailable ? '#000000b8' : '#9b9b9b'}}>
                                  <div className='option-header-zone'>{item.title} - {item.description}</div>
                              </div>

                              <div className='custom-divider'></div>

                              <div className='option-content' style={{gap: '8px', color: item.isAvailable ? '#000000b8' : '#9b9b9b'}}>
                                  <div className='manual-fee-details'>
                                      <div>Upto ₹{item?.maxExpectedAmount || 0} weekly earnings</div>
                                      {/* <div>Manual Full Amount Fee: ₹{item?.feeData?.totalFee || 0}</div> */}
                                  </div>
                              </div>

                          </div>
                      </div>
                  )
              })
            }
        </div>
      </div>
        ): null
      }

      

      <div className='button-container'>
          <Button className='submit-button' onClick={()=>onSubmitWorkPreferenceDetails()}>Next</Button>
      </div>
    </>
  )
}

export default WorkPreference
