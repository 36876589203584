import React, { useState, forwardRef, useImperativeHandle, useRef } from "react";
import { Select, Typography } from 'antd';
import '../../css/siderInput.scss';

const { Option } = Select;
const { Text } = Typography;

const SiderSelect = (props, ref) => {
    const { required, error, placeholder, labelname, autoFocus, onChange, selectorData, disabled, selectedValue, modifiedClassName } = props;
    const selectRef = useRef();

    useImperativeHandle(ref, () => ({
        clearSelection() {
            if (selectRef.current) {
                selectRef.current.values([]);
            }
        }
    }));


    function handleChange(value) {
        console.log(`selected ${value}`);
        onChange(value)
    }

    return (
        <React.Fragment>
            <div className="inputLabel inputSelect">
                {labelname && <div>
                    {required && <span className="requiredField">*</span>}
                    <label>{labelname}</label>
                </div>}
                <Select
                    ref={selectRef}
                    placeholder={placeholder}
                    showSearch
                    style={{ width: '100%' }}
                    autoFocus={autoFocus}
                    onChange={handleChange}
                    disabled={disabled}
                    value={selectedValue}
                    className={modifiedClassName}
                >
                    {selectorData?.map((item, index) => {
                        return <Option key={index} value={item}>{item}</Option>
                    })}
                    {/* <Option value="jack">Jack</Option> */}
                </Select>
                {error?.status && <Text type="danger">{error.text}</Text>}
            </div>

        </React.Fragment>
    )
}
const forwardedRef = React.forwardRef(SiderSelect);

export default forwardedRef;