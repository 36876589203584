import React, { useEffect, useState } from 'react';
import { formatLargeNumbers, largeNumberSuffix } from '../../../constants/utils';

function StatisticCard({value}) {
  
  return (
    <div className="shaded-tile-container shadow-highlighter">
        <div className="single-tile">
            <div className="icon-and-percentage-container d-flex flex-row justify-content-between w-100">
              <img src={value?.imageUrl} style={{width:'32px', objectFit: 'contain'}} />
              {
                value.percentage ||
                value?.label?.toLowerCase() === 'interested' ? (<span className='inter-semibold-16-24 color-252526'>{value?.percentage || '0'}%</span>): null
              }
            </div>
            <span className='number-text'>{value?.count ? formatLargeNumbers(parseInt(value?.count)) + '' + largeNumberSuffix(parseInt(value?.count)): '0'}</span>
            <span className='tile-title-text'>
              {value?.label}
            </span>
        </div>
    </div>
  )
}

export default StatisticCard