import { Checkbox, Divider, Input } from 'antd';
import React, { useEffect, useState } from 'react'
import SearchIcon from '../../../static/svg/SearchIcon.jsx'
import {CloseOutlined} from '@ant-design/icons'
import useDebouncedValue from '../../../hooks/useDebouncedValue.jsx';

function AdvanceFilterCitySelection({onCitySelect=()=>1}) {
    // City related vars
    const [citySearchText, setCitySearchText] = useState(null);
    const debouncedValue = useDebouncedValue(citySearchText, 500);
    const [cityDropdownOptions, setCityDropdownOptions] = useState([]);
    const [onFocusCityField, setOnFocusCityField] = useState(false);
    const [selectedCities, setSelectedCities] = useState([]);
    const [popularCities, setPopularCities] = useState([
        {
            id: 0,
            label: 'Delhi',
            isChecked: false
        },
        {
            id: 1,
            label: 'Mumbai',
            isChecked: false
        },
        {
            id: 2,
            label: 'Bangalore',
            isChecked: false
        },
        {
            id: 3,
            label: 'Pune',
            isChecked: false
        }
    ]);

    // Common operations
    const checkIfCityAlreadyExistInSelectedCityList = (city) => {
        // A utility function to check while insertion if city already exist, duplicate prevention
        if (!selectedCities?.length) return false;

        for (let i = 0; i < selectedCities.length; i++) {
            if(selectedCities[i].value.toLowerCase() === city.value.toLowerCase()) {
                return true;
            }
        }

        return false;
    }

    const addNewCitiesToSelectedCitiesList = (citiesArr) => {
        // A function which is used to add city in selected city list
        // when a city gets selected either from dropdown or popular cities checkbox
        for (let city = 0; city < citiesArr.length; city++) {
            // check if city is new one or already existing
            const cityExists = checkIfCityAlreadyExistInSelectedCityList(citiesArr[city])

            if(!cityExists) {
                setSelectedCities([...selectedCities, citiesArr[city]]);
            }
        }
    }

    const removeCityFromSelectedCitiesList = (label) => {
        const newSelectedCities = selectedCities.filter((item)=>{
            if(item.label != label) return true;
            else return false;
        });

        setSelectedCities([...newSelectedCities]);
    }

    const uncheckCityFromPopularCitiesCheckboxGroup = (label) => {
        const modifiedPopularCities = [...popularCities];

        for (let i = 0; i < modifiedPopularCities.length; i++) {
            if (modifiedPopularCities[i].label === label) {
                modifiedPopularCities[i].isChecked = false
            }
        } 
        
        setPopularCities([...modifiedPopularCities]);
    }

    const checkCityFromPopularCitiesCheckboxGroup = (label) => {
        const modifiedPopularCities = [...popularCities];

        for (let i = 0; i < modifiedPopularCities.length; i++) {
            if (modifiedPopularCities[i].label === label) {
                modifiedPopularCities[i].isChecked = true
            }
        } 
        
        setPopularCities([...modifiedPopularCities]);
    }

    // Operation when city gets selected using search
    const onCitySearch = (value) => {
        console.log(value);

        if(value === 'sagar') {
            setCityDropdownOptions([
                {
                    label: 'Delhi',
                    value: 'Delhi'
                },
                {
                    label: 'Mumbai',
                    value: 'Mumbai'
                },
                {
                    label: 'Pune',
                    value: 'Pune'
                },
                {
                    label: 'Bangalore',
                    value: 'Bangalore'
                },
                {
                    label: 'Chennai',
                    value: 'Chennai'
                }
            ]);
        } else {
            setCityDropdownOptions([]);
        }
    }

    const onSearchCitySelect = (city) => {
        addNewCitiesToSelectedCitiesList([city]);
        checkCityFromPopularCitiesCheckboxGroup(city.label);
        setOnFocusCityField(false);
    }

    useEffect(() => {
        onCitySearch(debouncedValue);
    }, [debouncedValue])
    

    // Operation when interacted with checkboxes
    const onPopularCityChange = (e, city) => {
        const modifiedPopularCities = [...popularCities];

        if (city.id === 4) {
            const boolValue = !city.isChecked;

            for (let i = 0; i < modifiedPopularCities.length; i++) {
                modifiedPopularCities[i].isChecked = boolValue
            }
        } else {
            for (let i = 0; i < modifiedPopularCities.length; i++) {
                if(modifiedPopularCities[i].id === city.id) {
                    if(modifiedPopularCities[i].isChecked) {
                        removeCityFromSelectedCitiesList(modifiedPopularCities[i].label);
                    }
                    modifiedPopularCities[i].isChecked = !modifiedPopularCities[i].isChecked
                }
            }
        }

        // Block to add cities to selected list for selected chips
        let newSelectedCities = []; 
        for (let i = 0; i < modifiedPopularCities.length; i++) {
            if(modifiedPopularCities[i].isChecked) {
                newSelectedCities.push({label: modifiedPopularCities[i].label, value: modifiedPopularCities[i].label});
            }
        }

        addNewCitiesToSelectedCitiesList(newSelectedCities);

        setPopularCities([...modifiedPopularCities]);
    }

    // Operation when deleted city using chips
    const onRemovalOfCityUsingChips = (label) => {
        removeCityFromSelectedCitiesList(label);

        uncheckCityFromPopularCitiesCheckboxGroup(label);
    }


    useEffect(() => {
      onCitySelect([...selectedCities]);
    }, [selectedCities])
    
  return (
    <>
        <Input 
            onChange={(e)=>setCitySearchText(e.target.value)}
            onFocus={()=>setOnFocusCityField(true)}
            className='samvaadini-search-candidate adv-filter-search' 
            placeholder="e.g Delhi" 
            allowClear
            prefix={<SearchIcon height={10} width={10} strokeColor='#C8C9CC'/>} />
        
        <div style={{position:'relative'}}>

            {
                onFocusCityField && cityDropdownOptions.length ? (
                    <div className='custom-dropdown-container'>
                        {
                            cityDropdownOptions.map(city =>(
                                <>
                                <div 
                                className='color-252526 inter-regular-10-16 d-flex' 
                                style={{padding: '12px 24px', cursor:'pointer'}}
                                onClick={()=>onSearchCitySelect(city)}
                                key={city.label}>
                                    {city.label}
                                </div>
                                <Divider style={{margin:'0px 0px'}}/>
                                </>
                            ))
                        }
                    </div>
                ):null
            }
        </div>
        
        
        <div className="popular-cities-container">
            {
                popularCities.map(city=> (
                    <>
                        <Checkbox checked={city.isChecked} onChange={(e)=>onPopularCityChange(e,city)}>
                            <span className='inter-regular-10-16 color-252526'>{city.label}</span>
                        </Checkbox>
                    </>
                ))
            }
        </div>

        <div className="selected-city-list">
            {
                selectedCities.map(city => (
                    <div className='new-custom-chip inter-regular-10-16 color-252526' key={city.label}>{city.label} <CloseOutlined onClick={()=>onRemovalOfCityUsingChips(city.label)} style={{fontSize:'8px',cursor:'pointer'}}/></div>
                ))
            }
        </div>
    </>
  )
}

export default AdvanceFilterCitySelection