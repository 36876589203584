import * as React from "react"
const FilterIcon = ({fillColor='none',height=17,width=17,strokeColor='#1D5FFF'}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fillColor}
    viewBox="0 0 17 17"
  >
    <path
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M11 4.023H8M3 4.023H1M5 5.773a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5ZM11 9.523H9M4 9.523H1M7 11.273a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5Z"
    />
  </svg>
)
export default FilterIcon
