import React, { useState, useEffect, useContext, useRef } from 'react';
import { TextField } from '@rmwc/textfield';
import '@rmwc/textfield/styles';
import { Button } from '@rmwc/button';
import '@rmwc/button/styles';
import '../../css/login.scss';
import '../../css/signup.scss';
import * as loginService from '../../service/loginService';
import { logEvent } from '../../service/tracking';
import ClipLoader from 'react-spinners/ClipLoader';
import trackingConstants from '../../constants/trackingConstants';
import { NavLink, Redirect } from "react-router-dom";
import { Formio, Form } from "react-formio"
import { checkMitraDuplicacy, mitraSignup, postNextQuestionId, postOnboardingQuestions, sendOTPToPhone } from '../../service/signupService';
import TraingSessionForm from './TraingSessionForm';
import GTMTracking from '../tracking/GTMTracking.component';
// import ReactPixel from 'react-facebook-pixel';
import OnboardingQuestions from './OnboardingQuestions';
import { AppContext } from "../../App";
import { Localization } from '../../utils/language';
import useGAEventsTracker from '../../service/useGSEventsTracker';
import CONSTANTS from '../../constants/constants';
import { trackEvent } from '../../service/engagementMonitoringService';
import SignupPoster from './SignupPoster.component';

const SignupPage = (props) => {
	const { mitraDispatch, mitraReducer } = useContext(AppContext);
	const [signupData, setSignupData] = useState('');
	const [selectedLanguage, setSelectedLanguage] = useState();
	const [otpSent, setOtpSent] = useState(null);
	const [otpVerified, setOtpVerified] = useState(null);
	const [otp, setOtp] = useState('');
	const [mitra, setMitra] = useState(null);
	const [validSession, setValidSession] = useState(false);
	const [loading, setLoading] = useState(true);
	const [otpTextErrorMsg, setOtpTextErrorMsg] = useState(null);
	// const [cookiesAccept, setCookiesAccept] = useState(true);
	const [random, setRandom] = useState('');
	const onboardingQuestions = useRef([])
	const formRef = useRef(null);
	const signUpEventTracker = useGAEventsTracker('signUp');
	const tandCVersion = 1;
	const pixelOption = {
		autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
		debug: false, // enable logs
	};
	// ReactPixel.init('903648060275990', {}, pixelOption);
	useEffect(() => {
		const randomNumber = (Math.floor(1000 + Math.random() * 9000)) % 2;
		setRandom(randomNumber);
		if (randomNumber) {
			trackEvent('opened_signup_screen', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		} else {
			trackEvent('opened_signup_screen_with_email', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		}
	}, []);

	useEffect(() => {
		if (mitraReducer?.mitraInfo?.id) {
			setValidSession(true)
			setLoading(false);
		}
		else {
			setLoading(false);
		}
	}, [mitraReducer])

	useEffect(() => {
		logEvent('OpenSignupScreen', trackingConstants.EVENT_GROUP_LOGIN, trackingConstants.EVENT_SCREEN_LOGIN);
		signUpEventTracker('OpenSignupScreen');
		//initially check if cookies are already accepted by user
			// ReactPixel.track('SingupPage');

		// checking acquisition source
		if (window.location.href.toLowerCase().includes('?source')) {
			const urlSearchParams = new URLSearchParams(window.location.search);
			const params = Object.fromEntries(urlSearchParams.entries());
			localStorage.setItem('acquisitionSource', params.source);
		}

		// checking google and fbclick ids
		if (window.location.href.toLowerCase().includes('fbclid')) {
			const urlSearchParams = new URLSearchParams(window.location.search);
			const params = Object.fromEntries(urlSearchParams.entries());
			localStorage.setItem('fbclid', params.fbclid);
		}
		if (window.location.href.toLowerCase().includes('gclid')) {
			const urlSearchParams = new URLSearchParams(window.location.search);
			const params = Object.fromEntries(urlSearchParams.entries());
			localStorage.setItem('gclid', params.gclid);
		}
	}, []);

	const verifyOTP = async (e) => {
		logEvent('Verifying OTP', trackingConstants.EVENT_GROUP_LOGIN, trackingConstants.EVENT_SCREEN_LOGIN);
		if (random) {
			trackEvent('signup_form_submission', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		} else {
			trackEvent('signup_email_form_submission', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		}
		signUpEventTracker('SignupFormSubmission');
		signUpEventTracker('OTPsubmission');
		signUpEventTracker('OpenMobileVerification');
		try {
			const localStorageAcquisitionSource = localStorage.getItem('acquisitionSource');
			signupData.acquisitionSource = localStorageAcquisitionSource ? localStorageAcquisitionSource : 'Self Signup';

			const localStorageFbclid = localStorage.getItem('fbclid');
			signupData.fbclid = localStorageFbclid ? localStorage.getItem('fbclid') : null;

			const localStorageGclid = localStorage.getItem('gclid');
			signupData.gclid = localStorageGclid ? localStorage.getItem('gclid') : null;

			const signupRes = await mitraSignup(otp, signupData, tandCVersion);
			if (signupRes.data.otpVerified) {
				postNextQuestionId(signupRes.data.mitra).then(res => {
					setTimeout(() => {
						setMitra(signupRes.data.mitra)
						setOtpVerified(true)
						signUpEventTracker('MobileVerificationSuccessful');
						// ReactPixel.trackCustom('successfulLeadCreation ', {});	
					}, 0);

				}).catch()

			}
			else {
				setOtpTextErrorMsg("Wrong or Expired OTP")
				setOtpVerified(false)
			}
		} catch (error) {
			setOtpTextErrorMsg("Something Went Wrong")
			setOtpVerified(false)
		}
	};

	const handleOTPChange = (event) => {
		setOtp(event.target.value);
	};

	const sendOTP = async (phoneNumber) => {
		return await sendOTPToPhone(phoneNumber)
	};

	const handleResendOTP = () => {
		sendOTP(signupData.phoneNumber);
	};

	const handleLanguageSelection = (event) => {
		const language = event.target.value;
		trackEvent('signup_screen_lang_change', { language: language }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		setSelectedLanguage(language);
		loadForm(language);
	};
	const loadForm = async (language) => {
		await Formio.createForm(document.getElementById("form-io"),
			random ? process.env.REACT_APP_FORM_IO_MITRA_LEADER_SIGNUP_URL : process.env.REACT_APP_FORM_IO_MITRA_LEADER_SIGNUP_URL_WITH_EMAIL_MANDATORY,
			signupFormOptions).then(form => {
				form.language = language;
			})
	}

	const handleBackArrowClick = () => {
		setOtpSent(false);
		setSelectedLanguage('');
		setOtp('');
		setOtpVerified(null);
		setOtpTextErrorMsg(null)
	};

	const signupFormOptions = {
		hooks: {
			beforeSubmit: async (submission, next) => {
				try {
					trackEvent('clicked_sign_up', { number: submission.data.phoneNumber }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
					const res = await checkMitraDuplicacy(submission.data.phoneNumber)
					if (res.data.isMitra) {
						return next([{ message: "Phone number is already registered!" }])
					}
					if (window.location.href.toLowerCase().includes('?source')) {
						const urlSearchParams = new URLSearchParams(window.location.search);
						const params = Object.fromEntries(urlSearchParams.entries());
						submission.data.acquisitionSource = params.source ? params.source : 'Self Signup';
					} else {
						submission.data.acquisitionSource = 'Self Signup';
					}
					setSignupData(submission.data)
					sendOTP(submission.data.phoneNumber).then(response =>
						{
							if(response.data.otpSent === 'true' || response.data.otpSent === true) {
								setOtpSent(true);
							}
							else {
								next([{message: response.data.message ? response.data.message : "Service Unavailable"}])
							}
						
							// ReactPixel.track('OTPVerifyPage');
							// ReactPixel.trackCustom('successfulSignUpFormSubmission', {});
						
					});
				} catch (error) {
					next([{ message: error.response ? error.response.data.message : "Service Unavailable" }])
				}
			}
		},
		i18n: Localization
	}


	//function for handling user consent response 
	// const handleCookieConsent = (consent) => {
	// 	if (consent) {
	// 		ReactPixel.grantConsent();
	// 		localStorage.setItem('cookiesAccepted', 'YES')
	// 		window.location.reload();
	// 		setCookiesAccept(true)
	// 	} else {
	// 		ReactPixel.revokeConsent();
	// 		localStorage.setItem('cookiesAccepted', 'NO')
	// 		setCookiesAccept(null)
	// 	}
	// }
	return <> {loading ? (
		<div className="loader_clip">
			<ClipLoader size={100} />
		</div>
	) : validSession ? (
		<Redirect to="/onboarding-questions"></Redirect>
	) : otpVerified ?
		<Redirect to="/onboarding-questions"></Redirect> : (
			<div className="Signup mainContentZ signup-main-container">
				<SignupPoster />
				{otpSent ? (
					// <div>
					<div className="main-login-container right-form-class">
						<div className="cars-elements-surface-filled" id="OpenMobileVerification">
							<GTMTracking />
							<div className="parentFormClass">
								<img className="vahan-logo mitra-logo new-logo-signup-page" alt="vahan" style={{ marginTop: '2.5rem' }} src="/image/vahan-new-logo.png" />
								{otpVerified === false ? (
									<h6 className="OtpMessage marginTopClass">OTP sent on <span style={{ color: '#D34612' }}>{signupData.phoneNumber}</span></h6>
								) : (
									<h6 className="OtpMessage">OTP sent on <span style={{ color: '#D34612' }}>{signupData.phoneNumber}</span></h6>
								)}
								<h5 style={{ margin: '0', textAlign: 'right', marginTop: '-9%' }} className="OtpMessage">
									<Button id="resend-otp" className="btn_tertiary" label="Resend OTP" onClick={handleResendOTP} />{' '}
								</h5>
								<div>
									<TextField
										id="enter-otp"
										label="Enter OTP"
										outlined
										placeholder="Enter OTP"
										minLength={4}
										maxLength={4}
										pattern="[0-9]*"
										value={otp}
										onChange={handleOTPChange}
									/>
									<h5 className="errorMsg">{otpTextErrorMsg}</h5>
									<div>
										<Button id="log-in" className="sendOtpAndBack" raised label="Submit" onClick={(e) => verifyOTP(e)} /> <br></br>
										<Button className="arrow-back sendOtpAndBack" style={{ color: 'black' }} icon={require('../../arrow_back.svg')} label="Back" onClick={handleBackArrowClick} />
									</div>
									{/* <p className="powered"><b>Powered by </b><img className="vahan-logo-png" alt="vahan" src="/image/vahan-final-logo-black.png" /> </p> */}
								</div>
							</div>
						</div>
					</div>
					// </div>
				) : (
					<div className="main-login-container right-form-class">
						<div className="signup_form" id="OpenSignupScreen">
							<div className="parentFormClass">
								<div className='header'>
									<img className="vahan-logo mitra-logo new-logo-signup-page" alt="vahan" style={{ marginTop: '2.5rem' }} src="/image/vahan-new-logo.png" />
									<select className="form-control" onChange={handleLanguageSelection}>
										<option value="en">English</option>
										<option value="hi">हिन्दी</option>
										<option value="kd">ಕನ್ನಡ</option>
										<option value="te">తెలుగు</option>
										<option value="ta">தமிழ்</option>
									</select>
								</div>
								<h3 className="mt-5 mt-md-0"><b>Bringing <br></br> opportunities to all!</b></h3>
								{!selectedLanguage ?
									(random ?
										<Form src="https://sphnlcqnxxthwup.form.io/mitrasignupformtesting" options={signupFormOptions} />
										:
										<Form src="https://sphnlcqnxxthwup.form.io/mitrasignupformemailmandatory" options={signupFormOptions} />
									)
									:
									<div ref={formRef} id="form-io"></div>
								}
							</div>
							<GTMTracking />
							{!signupData && <p>Existing user? <NavLink to="/" > Login here</NavLink></p>}
							{/* <p><b>Powered by </b><img className="vahan-logo-png" alt="vahan" src="/image/vahan-final-logo-black.png" /> </p> */}
						</div>
					</div>
				)}
			</div>
			// </div>
		)}
		{/* HTML code for user consent agreement  */}
		{/* {cookiesAccept === false ? <div id="myModal" className="modal-cookie">

			<div className="modal-cookie-content">
				<h4>Cookies Settings</h4>
				<p><b style={{textAlign: 'left'}}>We uses cookies to provide you with the experience.By proceeding , you consent to all cookies for purpose of enhancing website experience and analytics.</b></p>
				<div style={{textAlign: 'center'}}>
					<button type="button" onClick={() => {handleCookieConsent(true)}} className="btn btn-success" >Accept</button>
					<button type="button" onClick={() => {handleCookieConsent(false)}} className="btn btn-outline-danger" style={{margin: '10px'}}>Decline</button>
				</div>

			</div>

		</div> : null} */}
	</>
};

export default SignupPage;
