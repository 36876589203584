import React, { useEffect, useMemo , useState } from 'react';
// import Modal from 'react-modal';
import {Modal, Space} from 'antd';
import '../../css/submitPopup.css'

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: '150px',
      width: '400px',
      padding: '4px 20px'
    },
  };

  
  const SubmitPopup = ({visible, setShowPopup, text}) => {
    function info() {
      Modal.info({
        title: null,
        content: (
          <div>
            {text}
          </div>
        ),
        onOk() {setShowPopup(false)}
      });
    
    }
    
  
    return (
        // <Modal
        // isOpen={visible}
        // // onRequestClose={(e) => {setShowPopup(false)}}
        // shouldCloseOnOverlayClick={true}
        // style={customStyles}
        // ariaHideApp={false}
        // >
        //     <div>
        //         <div className="submit-popup-text">
        //         {text}
        //         </div>
        //     </div>
        // </Modal>
        // <Modal
        //   visible={visible}
        //   title={null}
        //   style={customStyles}
        //   footer={null}
        //   >
        //     <div>
        //       <div className="submit-popup-text">
        //         {text}
        //       </div>
        //     </div>
        // </Modal>
        <Space>
          {visible && info()}
        </Space>
        
    )
}

export default SubmitPopup;