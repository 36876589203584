import { Button, Input, Radio, notification } from 'antd'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { getSwiggyProfileData, updateSwiggyData } from '../../../../service/SwiggyAdditionalInfoService';
import { AppContext } from '../../../../App';

function BasicDetails({goToNextScreen, lead, submissionDetails}) {

  const addressDetailsRef = useRef(null);
  const personalDetailsRef = useRef(null);
  const [errorField, setErrorField] = useState('');
  const {mitraReducer,mitraDispatch, setAlert,setSpin } = useContext(AppContext);

  const showLoader = (value) => {
    setSpin({
        loading: value, //false or true when actived
        delay: null,
        tip: 'Loading...'  //Loading copy here
    })
}

  const [personalDetails, setPersonalDetails] = useState({
    firstname: null,
    lastname: null,
    gender: null
  });

  const [addressDetails, setAddressDetails] = useState({
    houseDetails: null,
    buildingDetails: null,
    landmark: null,
    city: null,
    pincode: null,
    state: null
  });

  const changePersonalDetails = (fieldName, value) => {
    setErrorField('');
    setPersonalDetails({...personalDetails, [fieldName]:value});
  }

  const checkForCityAndState = async(value) => {
    console.log('Checking for city and state', value);
    const payload = {
      "phoneNumber": lead?.phoneNumber,
      "dataToFetch" : {
          "getPincodeInfo": {
              "pincode": value
          }
      }
    }

    showLoader(true);
    const responseOnEnteringPincode = await getSwiggyProfileData(payload);
    const stateAndCity = {
      state: null,
      city: null
    }

    if(responseOnEnteringPincode?.data?.data?.getPincodeInfo?.data?.[0]) {
      const pincodeInfo = responseOnEnteringPincode?.data?.data?.getPincodeInfo?.data?.[0];

      if(pincodeInfo?.State) {
        stateAndCity.state = pincodeInfo?.State;
      }

      if(pincodeInfo?.Name) {
        stateAndCity.city = pincodeInfo?.Name;
      }
    }

    setAddressDetails({...addressDetails, state: stateAndCity.state, city: stateAndCity.city, pincode: value})
    showLoader(false);
  }

  const changeAddressDetails = (fieldName, value) => {
    setErrorField('');
    if(fieldName === 'pincode') {
      if(!isNaN(value)) {
        setAddressDetails({...addressDetails, [fieldName]:value});

        if(value.length === 6) {
          checkForCityAndState(value);
        }
      } else {
        return;
      }
    }
    setAddressDetails({...addressDetails, [fieldName]:value});
  }

  const handleScrollToPosition = (ref) => {
    if (ref?.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const allRequiredFieldsHaveValues = () => {
    if(
      !personalDetails.firstname || !personalDetails.lastname || !personalDetails.gender || !addressDetails.houseDetails || 
      !addressDetails.buildingDetails || !addressDetails.city || 
      (!addressDetails.pincode || addressDetails?.pincode?.length !== 6) || !addressDetails.state
       ) {
        if(!personalDetails.firstname) {
          notification['error']({
            message: 'Enter your first name.',
          });

          setErrorField('firstname');
          handleScrollToPosition(personalDetailsRef);
        } else if(!personalDetails.lastname) {
          notification['error']({
            message: 'Enter your last name.',
          });

          setErrorField('lastname');
          handleScrollToPosition(personalDetailsRef);
        } else if(!personalDetails.gender) {
          notification['error']({
            message: 'Choose your gender.',
          });

          setErrorField('gender');
          handleScrollToPosition(personalDetailsRef);
        } else if(!addressDetails.houseDetails) {
          notification['error']({
            message: 'Enter your house/flat number.',
          });

          setErrorField('houseDetails');
          handleScrollToPosition(addressDetailsRef);
        } else if(!addressDetails.buildingDetails) {
          notification['error']({
            message: 'Enter your building/locality name.',
          });

          setErrorField('buildingDetails');
          handleScrollToPosition(addressDetailsRef);
        } else if(!addressDetails.pincode || addressDetails?.pincode?.length !== 6) {
          if(addressDetails?.pincode?.length !== 6) {
            notification['error']({
              message: 'Enter correct pincode.',
            });
          } else {
            notification['error']({
              message: 'Enter your pincode.',
            });
          }

          setErrorField('pincode');
        } else if(!addressDetails.city) {
          notification['error']({
            message: 'Enter your city name.',
          });

          setErrorField('city');
        } else if(!addressDetails.state) {
          notification['error']({
            message: 'Enter your state name.',
          });

          setErrorField('state');
        } else {
          notification['error']({
            message: 'Enter all the required fields to continue.',
          });
        }
        return false
       } else {
        return true;
       }
  }

  const onSubmitPersonalDetails = async() => {
    showLoader(true);
    if(allRequiredFieldsHaveValues()) {
      // do something

      try {
        const payload = {
          "phoneNumber": lead?.phoneNumber,
          "dataToUpdate" : {
              "postName": [{
                  "firstName": personalDetails.firstname,
                  "lastName": personalDetails.lastname
              }],
              "postGender": [{
                "gender": personalDetails.gender
              }],
              "postInventoryShippingDetails": [{
                first_line: addressDetails.houseDetails,
                second_line: addressDetails.buildingDetails,
                landmark: addressDetails.landmark,
                city: addressDetails.city,
                postal: addressDetails.pincode,
                state: addressDetails.state
              }]
          }
        }

        const response = await updateSwiggyData(payload);
        showLoader(false);

        const respArr = response?.data?.data;

        let allInfoSuccessfullySubmitted = true;

        if(!respArr?.[0]?.["postName"]?.statusMessage === 'success') {
          allInfoSuccessfullySubmitted = false;
          notification['error']({
            message: 'Error submitting name, please try again..'
          })
        } else if(!respArr?.[1]?.["postGender"]?.statusMessage === 'SUCCESS') {
          allInfoSuccessfullySubmitted = false;
          notification['error']({
            message: 'Error submitting gender, please try again..'
          })
        } else if(!respArr?.[2]?.["swiggyAddressDetails"]?.statusMessage === 'SUCCESS') {
          allInfoSuccessfullySubmitted = false;
          notification['error']({
            message: 'Error submitting address details, please try again..'
          })
        }

        if(allInfoSuccessfullySubmitted) {
          goToNextScreen();
        }

      } catch(e) {
        showLoader(false);
        notification["error"] ({
          message: 'Something went wrong, please try again later.'
        })
      }
      
      
    } else {
      showLoader(false);
    }
  }

  const autoPopulateIfDataExists = () => {

    const personalInfo = {
      firstname: null,
      lastname: null,
      gender: null
    }

    const addressInfo = {
      houseDetails: null,
      buildingDetails: null,
      landmark: null,
      city: null,
      pincode: null,
      state: null
    }

    if(submissionDetails?.swiggyElementMap?.name?.value) {
      const firstname = submissionDetails?.swiggyElementMap?.name?.value.split(' ');
      personalInfo.firstname = firstname[0];
    }
    if(submissionDetails?.swiggyElementMap?.name?.value) {
      const lastname = submissionDetails?.swiggyElementMap?.name?.value.split(' ');
      personalInfo.lastname = lastname[1];
    }
    if(submissionDetails?.swiggyElementMap?.gender?.value) {
      personalInfo.gender = submissionDetails?.swiggyElementMap?.gender?.value;
    }
    if(submissionDetails?.swiggyElementMap?.swiggyAddressDetails?.value) {
      const parsedAddress = JSON.parse(submissionDetails?.swiggyElementMap?.swiggyAddressDetails?.value);

      if(parsedAddress?.first_line) addressInfo.houseDetails = parsedAddress?.first_line;
      if(parsedAddress?.second_line) addressInfo.buildingDetails = parsedAddress?.second_line;
      if(parsedAddress?.landmark) addressInfo.landmark = parsedAddress?.landmark;
      if(parsedAddress?.city) addressInfo.city = parsedAddress?.city;
      if(parsedAddress?.postal) addressInfo.pincode = parsedAddress?.postal;
      if(parsedAddress?.state) addressInfo.state = parsedAddress?.state;
    }

    setPersonalDetails(personalInfo);
    setAddressDetails(addressInfo);
  }

  useEffect(() => {
    autoPopulateIfDataExists();
  }, [submissionDetails])
  

  return (
    <>
      <div className='pan-verification-container'>
        <div className='container-header' ref={personalDetailsRef}>Basic Details</div>
        <div className='pan-form-container'>
          <div className='label mt-24'>{`First name`}<sup>*</sup></div>
          <Input
              className={`input ${errorField==='firstname'? 'input-error':''}`}
              placeholder={`Enter your first name`}
              value={personalDetails.firstname}
              onChange={(e) => changePersonalDetails('firstname', e.target.value)}
          />

          <div className='label mt-24'>{`Last name`}<sup>*</sup></div>
          <Input
              className={`input ${errorField==='lastname'? 'input-error':''}`}
              placeholder={`Enter your last name`}
              value={personalDetails.lastname}
              onChange={(e) => changePersonalDetails('lastname', e.target.value)}
          />

          <div className='label mt-24'>Gender<sup>*</sup></div>
          <Radio.Group onChange={(e)=>changePersonalDetails('gender', e.target.value)} value={personalDetails.gender}>
              <Radio value={'Male'}>Male</Radio>
              <Radio value={'Female'}>Female</Radio>
          </Radio.Group>
        </div>
      </div>

      <div className='pan-verification-container'>
        <div className='container-header' ref={addressDetailsRef}>Address Details</div>
        <div className='pan-form-container'>
          <div className='label mt-24'>{`House/flat number`}<sup>*</sup></div>
          <Input
              className={`input ${errorField==='houseDetails'? 'input-error':''}`}
              placeholder={`Enter your House/flat number`}
              value={addressDetails.houseDetails}
              onChange={(e) => changeAddressDetails('houseDetails', e.target.value)}
          />

          <div className='label mt-24'>{`Building/Locality name`}<sup>*</sup></div>
          <Input
              className={`input ${errorField==='buildingDetails'? 'input-error':''}`}
              placeholder={`Enter your building/locality name`}
              value={addressDetails.buildingDetails}
              onChange={(e) => changeAddressDetails('buildingDetails', e.target.value)}
          />

          <div className='label mt-24'>{`Landmark`}</div>
          <Input
              className={`input ${errorField==='landmark'? 'input-error':''}`}
              placeholder={`Enter the landmark`}
              value={addressDetails.landmark}
              onChange={(e) => changeAddressDetails('landmark', e.target.value)}
          />

          <div className='label mt-24'>{`Pincode`}<sup>*</sup></div>
          <Input
              className={`input ${errorField==='pincode'? 'input-error':''}`}
              placeholder={`Enter your pincode`}
              value={addressDetails.pincode}
              maxLength={6}
              onChange={(e) => changeAddressDetails('pincode', e.target.value)}
          />

          <div className='label mt-24'>{`City`}<sup>*</sup></div>
          <Input
              className={`input ${errorField==='city'? 'input-error':''}`}
              placeholder={`Enter your city`}
              value={addressDetails.city}
              onChange={(e) => changeAddressDetails('city', e.target.value)}
          />

          <div className='label mt-24'>{`State`}<sup>*</sup></div>
          <Input
              className={`input ${errorField==='state'? 'input-error':''}`}
              placeholder={`Enter your state`}
              value={addressDetails.state}
              onChange={(e) => changeAddressDetails('state', e.target.value)}
          />
        </div>
      </div>

      <div className='button-container'>
            <Button className='submit-button' onClick={()=>onSubmitPersonalDetails()}>Next</Button>
        </div>
    </>
  )
}

export default BasicDetails
