import { Button, Card, Space, Table, Typography, Tag,Collapse } from "antd";
import React, { useContext, useEffect, useState } from "react";
import PageHeaderComponent from "../common/PageHeader";
import './referAndEarn.scss';
import {DollarCircleOutlined} from '@ant-design/icons';
import ReferAndEarnCards from "./referAndEarnCards";
import ReferAndEarnForm from "./referAndEarnForm";
import { getAllReferredMitras } from "../../service/referAndEarnService";
import {AppContext} from './../../App';
import CONSTANTS from "../../constants/constants";
import LockingComponent from "../common/Locking.component";

const { Text } = Typography;
const { Panel } = Collapse;

const ReferAndEarn = () => {

  const [showForm, setShowForm] = useState(false);
  const {mitraReducer} = useContext(AppContext);
  const [referredMitras, setReferredMitras] = useState([]);
  const tncText = CONSTANTS.REFER_AND_EARN_TNC;
  const [visibleAuth, setVisibleAuth] = useState(false);

  const fetchAllReferredMitras = () => {
    getAllReferredMitras().then(res => {
      setReferredMitras([...res.data]);
    }).catch(err=> {
      setVisibleAuth(true);
      localStorage.removeItem("payoutAccessToken");
      console.log(err);
    });
  };

  useEffect(() => {
    fetchAllReferredMitras()
  }, [mitraReducer]);

  const returnAppropriateTag = (text) => {
    if (text === 'not_registered') {
      return <Tag color="red">Not Registered</Tag>
    } else if(text==='registered') {
      return <Tag color="green">Registered</Tag>
    } else if (text === 'pending') {
      return <Tag color="blue">Pending</Tag>
    }
  }

    const columns = [
        {
          title: 'Name of Friend',
          dataIndex: 'name',
          key: 'name',
          render: (text) => <a>{text}</a>,
        },
        {
          title: 'Mobile No.',
          dataIndex: 'phoneNumber',
          key: 'phoneNumber',
        },
        {
          title: 'Registration Status',
          dataIndex: 'status',
          key: 'status',
          render: (text) => returnAppropriateTag(text)
        }
      ];

    return (
        <div className="refer-and-earn mainContentZ">
            <LockingComponent path="refer-and-earn" pathName="Refer and Earn" visibleAuth={visibleAuth} setVisibleAuth={setVisibleAuth} />
          {
            !visibleAuth && localStorage.getItem('payoutAccessToken') ? 
          <>            
            <div className="header">
              <PageHeaderComponent breadcrumb={[{link: '/refer-and-earn', label: 'Refer and Earn'}]} />
                {/* <div style={{marginLeft: 'auto', display: 'flex', flexDirection: 'row'}}>
                </div> */}
            </div>

            <div className="page-description">
                Earn upto ₹10,000 by inviting your friend to join the Vahan Leader Program.
            </div>

            <ReferAndEarnCards />

            <Card style={{textAlign:'start', margin:'24px'}} bodyStyle={{padding:'0px'}}>
              <Collapse ghost>
                <Panel header="Additional T&Cs." key="1">
                  <ol>
                      {
                        tncText.map((terms, index) => {
                          return <li key={terms.id}><Text>{terms.value}</Text></li>
                        })
                      }
                      
                    </ol>
                </Panel>
              </Collapse>                
            </Card>

            <div className="refer-and-earn-button">
                <button onClick={()=> setShowForm(true)}><Text className="refer-earn-button-text"><DollarCircleOutlined style={{color: '#fff'}} />Refer and Earn</Text></button>
                <ReferAndEarnForm showForm={showForm} setShowForm={setShowForm}/>
            </div>

            <div className="refer-earn-table-container">
                <Table columns={columns} dataSource={referredMitras} scroll={{}}></Table>
            </div>
          </>
          : null
          }
        </div>
    )
};

export default ReferAndEarn;
