import React, { useEffect, useState } from 'react';
import { InfoCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Card, Tooltip, Skeleton, Typography } from 'antd';
import CONSTANTS from '../../constants/constants';
const { Text } = Typography;

const ClientPerformance = ({ cientPerformance, mitraDispatch }) => {
    const history = useHistory();
    const statusToMilestoneMaping = CONSTANTS.statusToMilestoneMaping;
    const routing = (client, title, secondarySearchKey) => {

        const { search } = history.location;
        const newSearch = new URLSearchParams(search);
        const secondaryKey = secondarySearchKey ? secondarySearchKey : false
        history.push({
            pathname: '/leads-page',
            search: `${title}&${secondaryKey}&${newSearch}`
        })
        mitraDispatch(client)
    }

    return (

        <div style={{ display: 'inline-flex', flexWrap: 'wrap' }}>
            {cientPerformance?.arrayFormat.map((item, index) => (
                <Card bodyStyle={{padding: '12px 24px'}} className='overall-card overall-card-clientperformance' hoverable key={index}>
                    <div className='card-header-section d-flex justify-content-between' style={{border: 0}}>
                        <img className="imgResponse" alt={item?.client} src={item?.logo} />

                        {
                            item?.latestInjestedStatus === 'injested' ? (
                                <div className='updated-on-information-container d-flex flex-column align-items-end'>
                                    <span>Updated on</span>
                                    <span>{item?.latestInjestedTime}</span>
                                </div>
                            ):
                            item?.latestInjestedStatus === 'processing' ? (
                                <div className='updated-on-information-container d-flex flex-column align-items-end'>
                                    <span>Data is being processed</span>
                                </div>
                            ): null
                        }
                        
                    </div>
                    <div className='card-view-tab'>
                        {item && item.statusList && item.statusList.length > 0 && item.statusList.map((itemStatus, index2) => (
                            <div className='card-items' key={index2}>
                                <div className='overall-header' style={{alignItems: 'flex-start'}}>
                                    <Text className='summary-header'>{itemStatus?.label}</Text>
                                </div>
                                {
                                    ['App Download Count','Approved at FT %','% Bank within 2 days from FT','% Pan within 2 days from FT'].includes(itemStatus.type) ? 
                                    <div className='overall-number' >
                                        <div style={{ display: 'flex', alignItems: 'center' }} >
                                            <Text className='number'>{itemStatus?.value}</Text>
                                        </div>
                                    </div>
                                    :
                                    <div className='overall-number' >
                                        <div style={{ display: 'flex', alignItems: 'center' }} onClick={(e) => routing(cientPerformance?.arrayFormat?.[index]?.client, cientPerformance?.arrayFormat?.[index]?.statusList?.[index2]?.label, itemStatus?.secondarySearchKey)}>
                                            <Text underline className='number'>{itemStatus?.value}</Text>
                                            <RightCircleOutlined style={{ fontSize: '12px', marginLeft: '10px' }} />
                                        </div>
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                </Card>
            ))}
        </div>
    )
}
export default ClientPerformance;