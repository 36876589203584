import * as React from "react";
const PhoneTickIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11}
    height={11}
    viewBox="0 0 11 11"
    fill="none"
    {...props}
  >
    <path
      d="M10.0697 8.4006C10.0697 8.5656 10.033 8.73518 9.95508 8.90018C9.87716 9.06518 9.77633 9.22102 9.64341 9.36768C9.41883 9.61518 9.17133 9.79393 8.89175 9.90852C8.61675 10.0231 8.31883 10.0827 7.998 10.0827C7.5305 10.0827 7.03091 9.97268 6.50383 9.7481C5.97675 9.52352 5.44966 9.22102 4.92716 8.8406C4.40008 8.4556 3.9005 8.02935 3.42383 7.55727C2.95175 7.0806 2.5255 6.58102 2.14508 6.05852C1.76925 5.53602 1.46675 5.01352 1.24675 4.4956C1.02675 3.9731 0.916748 3.47352 0.916748 2.99685C0.916748 2.68518 0.971748 2.38727 1.08175 2.11227C1.19175 1.83268 1.36591 1.57602 1.60883 1.34685C1.90216 1.0581 2.223 0.916016 2.56216 0.916016C2.6905 0.916016 2.81883 0.943516 2.93341 0.998516C3.05258 1.05352 3.158 1.13602 3.2405 1.25518L4.30383 2.75393C4.38633 2.86852 4.44591 2.97393 4.48716 3.07477C4.52841 3.17102 4.55133 3.26727 4.55133 3.35435C4.55133 3.46435 4.51925 3.57435 4.45508 3.67977C4.3955 3.78518 4.30841 3.89518 4.19841 4.00518L3.85008 4.36727C3.79966 4.41768 3.77675 4.47727 3.77675 4.5506C3.77675 4.58727 3.78133 4.61935 3.7905 4.65602C3.80425 4.69268 3.818 4.72018 3.82716 4.74768C3.90966 4.89893 4.05175 5.09602 4.25341 5.33435C4.45966 5.57268 4.67966 5.8156 4.918 6.05852C5.1655 6.30143 5.40383 6.52602 5.64675 6.73227C5.88508 6.93393 6.08216 7.07143 6.238 7.15393C6.26091 7.1631 6.28841 7.17685 6.3205 7.1906C6.35716 7.20435 6.39383 7.20893 6.43508 7.20893C6.513 7.20893 6.57258 7.18143 6.623 7.13102L6.97133 6.78727C7.08591 6.67268 7.19591 6.5856 7.30133 6.5306C7.40675 6.46643 7.51216 6.43435 7.62675 6.43435C7.71383 6.43435 7.8055 6.45268 7.90633 6.49393C8.00716 6.53518 8.11258 6.59477 8.22716 6.67268L9.74425 7.74977C9.86341 7.83227 9.94591 7.92851 9.99633 8.0431C10.0422 8.15768 10.0697 8.27227 10.0697 8.4006Z"
      stroke="black"
      strokeMiterlimit={10}
    />
    <path
      d="M6.875 2.73503L7.79167 3.65169L9.625 1.81836"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default PhoneTickIcon;
