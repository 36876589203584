import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal, notification } from 'antd'
import { AppContext } from '../../../../App';
import { CheckCircleOutlined, ExclamationCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { getBlinkitData } from '../../../../service/BlinkitAdditionalFormService';
import { postAdditionalInfo } from '../../../../service/additionalInfoService';

const LAYOUTS = {
  digilockerLayout: 'DIGILOCKER_LAYOUT',
  aadharVerifiedLayout: 'AADHAR_VERIFIED_LAYOUT',
  completeTheProcessLayout: 'COMPLETE_THE_PROCESS_LAYOUT',
  checkStatusLayout: 'CHECK_STATUS_LAYOUT'
}

function BlinkitDigilockerVerification({lead, goToNextScreen, selectedVerificationType, getLeadInformation}) {
    const [showModal, setShowModal] = useState(false);
    const [verificationUrl, setVerificationUrl] = useState();
    const [currentLayout, setCurrentLayout] = useState();
    const [aadharVerificationStatus, setAadharVerificationStatus] = useState(null);
    const [aadharVerificationStatusMessage, setAadharVerificationStatusMessage] = useState(null);
    const {mitraReducer,mitraDispatch, setAlert,setSpin } = useContext(AppContext);
    const [aadharVerifiedDetails, setAadharVerifiedDetails] = useState();
    let digilockerWindow;
    let digilockerStatusInterval;
    let digilockerWindowInterval;
    let iterator = 0;
  
    const showLoader = (value, message) => {
      setSpin({
          loading: value, //false or true when actived
          delay: null,
          tip: message  //Loading copy here
      })
  }
  
    const getDigilockerUrl = (reset) => {
      //getDigiLockerKycUrl: {},
      const payload = {
        phoneNumber: lead?.phoneNumber,
        dataToFetch: {
          getDigiLockerKycUrl: {},
        }
      }
      getBlinkitData(payload)
      .then(res=> {
        console.log(res?.data?.getDigiLockerKycUrl?.redirectUrl, 'Redirect URL');
        setVerificationUrl(res?.data?.getDigiLockerKycUrl?.redirectUrl);
        if (res?.data?.getDigiLockerKycUrl?.redirectUrl && (!reset || !reset.length)) {
          goToDigilockerUrl(res?.data?.getDigiLockerKycUrl?.redirectUrl);
          setCurrentLayout(LAYOUTS.completeTheProcessLayout);
        }
        if(reset && reset.length) getVerificationStatus('resetAadharForm');
      })
      .catch(err=> {
        console.log(err);
      })
    }
  
    const startIntervalToCheckStatus = () => {
  
      iterator = 0;
          if(digilockerStatusInterval){
              clearInterval(digilockerStatusInterval);
          }
  
      digilockerStatusInterval = setInterval(() => {
        if (iterator >= 4) {
          clearInterval(digilockerStatusInterval);
          showLoader(false);
        }
  
        showLoader(true, 'Please wait, checking digilocker verification status..');
        iterator = iterator + 1;
        getVerificationStatus('showContinuousLoader');
      }, 2000);
    }
  
    const goToDigilockerUrl = (url) => {
      digilockerWindow = window.open(url, '_blank');
  
      digilockerWindowInterval = setInterval(() => {
        if (digilockerWindow.closed) {
  
          // when the window is closed
          clearInterval(digilockerWindowInterval);
          startIntervalToCheckStatus();
        }
      }, 1000);
  
      console.log(digilockerWindow);
    };
  
    useEffect(() => {
      // Get the digilocker verification url, test the process
      // Upon completion come back to the app and check the status
  
      if(lead?.phoneNumber) {
        showLoader(true, 'Checking status');
        getVerificationStatus('initialCall');
      }
  
      return () => {
        if(digilockerWindowInterval) {
          clearInterval(digilockerWindowInterval);
        }
      }
    }, [lead]);
  
    const formatVerificationResponse = (res) => {
      return (
        <div style={{display: 'flex', flexDirection: 'column', marginTop: '10px'}}>
          <div>{res?.data?.getKycStatus?.aadharCardName}</div>
          <div>{res?.data?.getKycStatus?.maskedAadharNumber}</div>
        </div>
      )
    }
  
    const getVerificationStatus = async(calledFromLayout=null) => {
      const payload = {
        phoneNumber: lead?.phoneNumber,
        dataToFetch: {
          getKycStatus: {},
        }
      }
      getBlinkitData(payload)
      .then(res=> {
  
        if(res?.data?.getKycStatus?.aadharStatus === 'COMPLETED') {
          showLoader(false);
  
          // goToNextScreen();
          notification['success']({
            message: 'Aadhar verification successful.',
          });
          setAadharVerifiedDetails(res);
          setAadharVerificationStatus('verified');
          setAadharVerificationStatusMessage(`Aadhar Verified -> ${res?.data?.getKycStatus?.aadharCardName} | ${res?.data?.getKycStatus?.maskedAadharNumber}`);
  
          setCurrentLayout(LAYOUTS.aadharVerifiedLayout);
  
          clearInterval(digilockerStatusInterval);
  
        } else if(res?.data?.getKycStatus?.aadharStatus === 'DUPLICATE') {
          showLoader(false);
  
          notification['error']({
            message: 'Aadhar card already in use, please try a different Aadhar Card.',
          });
          setAadharVerificationStatus('duplicate');
          setAadharVerificationStatusMessage(`Duplicate Aadhar, please try a different Aadhar card`);
  
          setCurrentLayout(LAYOUTS.digilockerLayout);
  
          clearInterval(digilockerStatusInterval);
  
        } else if(res?.data?.getKycStatus?.aadharStatus === 'FAILED'){
          showLoader(false);
  
          notification['error']({
            message: 'Aadhar verification failed.',
          });
          setAadharVerificationStatus('failed');
          setAadharVerificationStatusMessage(`Verification failed. Please try again`);
  
          setCurrentLayout(LAYOUTS.digilockerLayout);
  
          clearInterval(digilockerStatusInterval);
  
        }
        else {
          if(calledFromLayout?.length && calledFromLayout!=='showContinuousLoader') showLoader(false);
          if(iterator >4) showLoader(false);
  
          if(
            calledFromLayout === 'completeTheProcessForm' || 
            calledFromLayout === 'checkStatusForm' || 
            (calledFromLayout === 'showContinuousLoader' && iterator > 4)
            ) {
            // if the user wants to know the status while the process is still going on in other tab or status has been left incomplete and closed the tab
            // then take the user to the 'CHECK_STATUS_LAYOUT' layout
  
            showLoader(false);
            setCurrentLayout(LAYOUTS.checkStatusLayout);
  
            notification['error']({
              message: 'Verification in process, please refresh after some time or try again.',
            });
          } else {
            // for rest all the cases where this function is being called from and there is no specific status
            // move the user to default digilocker layout
            setAadharVerificationStatus('pending');
            setAadharVerificationStatusMessage(`Aadhar verification pending.`);
            setCurrentLayout(LAYOUTS.digilockerLayout);
          }
        }
      })
      .catch(err=> {
        showLoader(false);
        console.log(err);
      })
    }
  
    const submitDiglockerVerificationDetails = async() => {
      if (currentLayout === LAYOUTS.aadharVerifiedLayout) {
  
        const submissionData = {
          blinkitVerificationType: selectedVerificationType
        }
  
        // Updating info in our DB
        const saveDataInOurDB = await postAdditionalInfo(submissionData, mitraReducer?.mitraInfo, lead);
        
        getLeadInformation();
        goToNextScreen();
      } else {
        notification['error'] ({
          message: 'Please complete the verification to proceed'
        })
      }
    }
  
    const getStatusWithIcon = () => {
      
      let color = '#000';
      let icon = <ExclamationCircleOutlined />;
  
      if(aadharVerificationStatus === 'pending') {
        color = '#000';
        icon = <ExclamationCircleOutlined/>;
      } else if (aadharVerificationStatus === 'verified') {
        color = '#4BB543';
        icon = <CheckCircleOutlined/>;
      } else if (aadharVerificationStatus === 'duplicate') {
        color = '#f7710aad';
        icon = <ExclamationCircleOutlined/>;
      } else if (aadharVerificationStatus === 'failed') {
        color = '#ff000099';
        icon = <CloseCircleOutlined/>
      }
  
      return (
        <div style={{display: 'flex', alignItems: 'center', gap:'4px', color}}>
          {icon} {aadharVerificationStatus.toUpperCase()}
        </div>
      )
    }
  
    const addLoaderAndCheckStatus = () => {
      showLoader(true);
      setTimeout(() => {
        getVerificationStatus('checkStatusForm')
      }, 2000);
    }
  
    const digilockerForm = () => {
      return (
          <div className='digilocker-verification-container'>
            <div className='container-header'>Digilocker Verification</div>
  
            <div className='digilocker-form-container'>
  
              <div className="aadhar-steps-header">Steps for Aadhar KYC</div>
              <ul>
                <li style={{textAlign: 'start'}}>Click on Goto Digilocker Verification button.</li>
                <li style={{textAlign: 'start'}}>Complete the steps in the new tab.</li>
                <li style={{textAlign: 'start'}}>Once done, come to this page to check the verification status.</li>
              </ul>
  
              <Button className='submit-button' onClick={()=>getDigilockerUrl()}>Goto Digilocker Verification</Button>
  
              {
                aadharVerificationStatus && aadharVerificationStatus!=='pending' ? (
                  <>
                    <div className="aadhar-steps-header status-styles">Aadhar KYC Status: {getStatusWithIcon()}</div>
                    <div 
                    style={{textAlign: 'start'}}
                    className={
                      `status-bg ${
                        aadharVerificationStatus === 'verified' ? 'verified-bg': 
                        aadharVerificationStatus === 'failed' ? 'failed-bg':
                        aadharVerificationStatus === 'duplicate' ? 'duplicate-bg': ' pending-bg'
                      }`
                  }
                    >
                      {aadharVerificationStatusMessage}
                    </div>
                  </>
                ): null
              }
            </div>
          </div>
      )
    }
  
    const aadharVerifiedForm = () => {
      return (
        <div className='digilocker-verification-container'>
            <div className='container-header'>Digilocker Verification</div>
  
            <div className='digilocker-form-container' style={{textAlign: 'start'}}>
                {
                  aadharVerificationStatus ? (
                    <>
                      <div className="aadhar-steps-header" style={{display: 'flex',alignItems: 'center', gap: '6px', marginTop: '12px'}}>Aadhar KYC Status: Verified <CheckCircleOutlined style={{color: '#4BB543'}}/></div>
                      {formatVerificationResponse(aadharVerifiedDetails)}
                    </>
                  ): null
                }
  
                <div className='anchor-styles' onClick={()=>getDigilockerUrl('reset')}>Remove aadhar verification</div>
            </div>
        </div>
      )
    }
  
    const completeTheProcessForm = () => {
      return (
        <div className='digilocker-verification-container'>
            <div className='container-header'>Digilocker Verification</div>
  
            <div className='digilocker-form-container' style={{textAlign: 'start', display: 'flex', flexDirection: 'column'}}>
                <div style={{marginBottom: '12px'}}>Complete the digilocker verification in other tab.</div>
  
                <Button className='submit-button' onClick={()=>getVerificationStatus('completeTheProcessForm')}>Check Status Now</Button>
            </div>
        </div>
      )
    }
  
    const checkStatusForm = () => {
      return (
        <div className='digilocker-verification-container'>
            <div className='container-header'>Digilocker Verification</div>
  
            <div className='digilocker-form-container' style={{textAlign: 'start', display: 'flex', flexDirection: 'column'}}>
                <div style={{marginBottom: '12px'}}>We could not verify your digilocker status, you can refresh the status after some time or start new verification process. </div>
  
                <div style={{display: 'flex', alignItems: 'center', gap: '10px', width:'fit-content'}}>
                  <Button className='submit-button' onClick={()=>addLoaderAndCheckStatus()}>Refresh</Button>
                  <Button className='submit-button' onClick={()=>getDigilockerUrl()}>Generate New Digilocker Link</Button>
                </div>
            
            </div>
        </div>
      )
    }
    
    return (
      <>
        
        {
          currentLayout === LAYOUTS.digilockerLayout ? digilockerForm(): null
        }
  
        {
          currentLayout === LAYOUTS.aadharVerifiedLayout ? aadharVerifiedForm(): null
        }
  
        {
          currentLayout === LAYOUTS.completeTheProcessLayout ? completeTheProcessForm(): null
        }
  
        {
          currentLayout === LAYOUTS.checkStatusLayout ? checkStatusForm(): null
        }
        <Button className='submit-button mt-16-imp' onClick={()=>submitDiglockerVerificationDetails()}>Next</Button>
      </>
    )
}

export default BlinkitDigilockerVerification
