import React, { useEffect, useState } from 'react';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';
import { InfoCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Card, Tooltip, Skeleton, Typography, Divider, Row } from 'antd';
const { Text } = Typography;

const OverallSummary = ({ performanceDashboardData, mitraDispatch }) => {
    const history = useHistory();
    const routing = (title) => {
        trackEvent("viewed_candidates_list_overall_summary", {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)

        const { search } = history.location;
        const newSearch = new URLSearchParams(search);
        // history.push(`/leads-page?${newSearch.toString().replaceAll("+", "%20")}`);

        history.push({
            pathname: '/leads-page',
            search: `${title}&${newSearch}`
        })
        mitraDispatch()
    }
    let uniqueCandidatesChild = performanceDashboardData?.totalReferrals?.children
        .map((child, index) => ({ child, index })) // Map each element to an object containing both child and index
        .filter(({ child }) => child.label === 'Unique Candidates');
    return (
        <>
            {
                performanceDashboardData ?
                    <div className='overall-summary-box' >
                        <div className='total-referral-box'>
                            <div className='d-flex vahan-app-pre-ob'>
                                <div className='pre-ob-box-container'>
                                    <div className='total-referral-container'>
                                        <div className='vahan-download-container d-flex align-items-center'>
                                            <Text style={{ marginRight: '5px' }}>{performanceDashboardData.totalReferrals?.label}</Text>
                                            <Tooltip title={performanceDashboardData.totalReferrals?.description}>
                                                <InfoCircleOutlined />
                                            </Tooltip>
                                        </div>
                                        <div className='d-flex align-items-center' style={{ marginTop: '8px' }}>
                                            <Text className='total-referral-number'>{performanceDashboardData.totalReferrals?.count}</Text>
                                        </div>
                                    </div>
                                </div>
                                {uniqueCandidatesChild.length > 0 ?
                                    <div className='pre-ob-box-container' style={{ margin: '8px 0', border: 'none' }} key={uniqueCandidatesChild[0].child.index}>
                                        <div className='total-referral-container'>
                                            <div className='d-flex align-items-center pre-ob-text'>
                                                <Text style={{ marginRight: '5px' }}>{uniqueCandidatesChild[0].child.label}</Text>
                                                <Tooltip title={uniqueCandidatesChild[0].child.description}>
                                                    <InfoCircleOutlined />
                                                </Tooltip>
                                            </div>
                                            <div className='d-flex align-items-center' style={{ marginTop: '10px' }} onClick={() => routing(uniqueCandidatesChild[0].child.label)}>
                                                <Text className='total-referral-number'>{uniqueCandidatesChild[0].child.count}</Text>
                                                <RightCircleOutlined className='right-circle-icon' />
                                            </div>
                                        </div>
                                    </div> : <div> </div>}
                            </div>
                            <Divider />

                            <div className='d-flex vahan-app-pre-ob'>
                                {
                                    performanceDashboardData.totalReferrals && performanceDashboardData.totalReferrals.children && performanceDashboardData.totalReferrals.children.map((child, index) => {
                                        if (child.label === 'Unique Candidates') return;
                                        return (
                                            <div className='pre-ob-box-container-bottom' style={{borderRight: child.label === 'Action Needed Referrals' ? '1px solid #f0f0f0' : '' }} key={index}>
                                                <div className='total-referral-container-bottom d-flex align-items-center'>
                                                    <div className='d-flex align-items-center pre-ob-text'>
                                                        <Text style={{ marginRight: '5px' }}>{child.label}</Text>
                                                        <Tooltip title={child.description}>
                                                            <InfoCircleOutlined />
                                                        </Tooltip>
                                                    </div>
                                                    <div className='d-flex align-items-center' style={{ marginTop: '10px' }} onClick={() => routing(child.label)}>
                                                        <Text className='total-referral-number'>{child.count}</Text>
                                                        <RightCircleOutlined className='right-circle-icon' />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className='remaining-milestone-container' >
                            {
                                performanceDashboardData.data && performanceDashboardData.data.length ? performanceDashboardData.data.map((item, index) => (
                                    item.value === 'activation_without_app' ? null :
                                        <Card className='overall-card' key={index}>
                                            <div className='overall-header'>
                                                <div style={{ backgroundColor: item.color }} className='summary-color'></div>
                                                <Text className='summary-header'>{item.label}</Text>
                                                <Tooltip title={item.description}>
                                                    <InfoCircleOutlined />
                                                </Tooltip>
                                            </div>
                                            <div className='overall-number' onClick={!item.label.includes('10 days') ? () => routing(item.label) : null}>
                                                <Text className='number'>{item.count} {item.label.includes('10 days') ? '%' : null}  </Text>
                                                {!item.label.includes('10 days') ?
                                                    <RightCircleOutlined className='right-circle-icon' /> : null}
                                            </div>
                                            {item.label.includes('10 days') && Object.prototype.hasOwnProperty.call(item, 'noOfLeads') ? <p style={{ fontSize: '12px', fontWeight: 'bold', display: 'block' }}>(Leads Count: {item.noOfLeads})</p> : null}
                                        </Card>
                                )) : null
                            }
                        </div>
                    </div>
                    :
                    <Skeleton active />
            }
        </>
    )
}

export default OverallSummary;
