import React, { useContext, useRef, useState, useEffect } from 'react';
import { Button, Upload, notification, Modal, Select, Spin, Divider } from 'antd';
import { CameraOutlined, DeleteOutlined, DeleteFilled, LoadingOutlined, CloseOutlined, ExclamationCircleOutlined, CheckCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import './AccountSidesheetStyle.scss'
import { uploadImageToS3 } from '../../../../service/additionalInfoService';
import { updateMitrasById, postUserInfoForm } from '../../../../service/logoutService';
import '../../../../css/globalStyles.scss'
import { AppContext } from '../../../../App';
import { getOverallTeamCount, removeTL } from '../../../../service/accountService';
import CommonFieldInput from './CommonFieldInput'
import CreateEditTeamModal from '../AccountSection/AdminView/CreateTeam/CreateEditTeamModal';
import { trackEvent } from '../../../../service/engagementMonitoringService';
import CONSTANTS from '../../../../constants/constants';

const { Option } = Select;

const AccountSidesheet = ({ memberDetails = {
    "id": "",
    "name": "",
    "team": "",
    "teamId": "",
    "team_id": "",
    "team_lead": "",
    "phoneNumber": "",
    "photoUrl": "",
    "status": "",
    "dateOfJoining": null,
    "createdAt": "",
    "updatedAt": "",
    "deletedAt": null,
    "type": "",
    "sourcingCities": null,
    "sourcingLocalities": null,
    "homeCity": null,
    "managerMitraID": "",
    "teamLeadMitraID": null,
    "role": "teamMember",
    "email": null,
    "optInInfo": null,
    "accountManagerId": null,
    "acquisitionSource": "",
    "password": null,
    "bridgeNumber": null,
    "placementCountDivision": null
}, closeDrawer, memberDetailsUpdated, mode = "", teamName = "", teamId = "", source }) => {
    const [currentMemberDetails, setCurrentMemberDetails] = useState(memberDetails)
    const [isAccessModalOpen, setIsAccessModalOpen] = useState(false);
    const { mitraReducer } = useContext(AppContext);
    const memberName = useRef(null);
    const memberPhoneNo = useRef(null);
    const memberEmail = useRef(null);

    const key = `open${Date.now()}`;
    const placement = 'topRight';
    const [nameError, setNameError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)
    const [phoneFormatError, setPhoneFormatError] = useState(false)
    const [roleError, setRoleError] = useState(false)
    const nameKey = "name"
    const phoneKey = "phoneNumber"
    const roleKey = "role"
    const [teamList, setTeamList] = useState([])
    const [roleList, setRoleList] = useState([
        {
            label: "Team Leader",
            key: "teamLeader"
        },
        {
            label: "Telecaller",
            key: "teamMember"
        }
    ])
    const [selectedTeam, setSelectedTeam] = useState({})
    const [selectedTeamError, setSelectedTeamError] = useState(false)
    const [savingDetails, setSavingDetails] = useState(false)
    const [showConfirmChangesModal, setShowConfirmChangesModal] = useState(false)
    const [selectedRole, setSelectedRole] = useState("teamMember")
    const [showCreateTeamModal, setShowCreateTeamModal] = useState(false)
    const [currentTLDetails, setCurrentTLDetails] = useState({
        name: "",
        id: "",
    })
    const [showRemoveTLFromTeamText, setShowRemoveTLFromTeamText] = useState(false)
    const [roleChangedFromTCToTL, setRoleChangedFromTCToTL] = useState(false)

    useEffect(() => {
        fetchAllTeams()
    }, [])

    useEffect(() => {
        console.log("CTMD", currentMemberDetails)
    }, [currentMemberDetails])

    const props = {
        name: 'image',
        multiple: false,
        beforeUpload(file) {
            return false;
        },
        customRequest: async ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        },
        onChange(fileList) {
            profilePicUpload(fileList);
        },
        maxCount: 1,
        accept: 'image/*',
    };

    const fetchAllTeams = () => {
        const queryParams = {
            limit: 1000,
            offset: 0,
            search: "",
            teamListOnly: true,
            type: "teams",
            sortBy: "",
            team: ""
        };
        getOverallTeamCount(queryParams, null).then((teamListRes) => {
            if (teamListRes?.data?.teamList) {
                setTeamList(teamListRes.data.teamList);
            }
            if (teamId) {
                setSelectedTeam({
                    teamId: teamId,
                    teamName: teamName
                })
            }
        })
    }

    const profilePicUpload = (imageFile) => {
        let tempMemberDetails = currentMemberDetails
        uploadImageToS3(imageFile.file).then((imageRes) => {
            if (imageRes?.data?.imageUrl) {
                tempMemberDetails.photoUrl = imageRes?.data?.imageUrl
                setCurrentMemberDetails(JSON.parse(JSON.stringify(tempMemberDetails)))
            }
        })
    }

    const removeProfilePic = () => {
        let tempMemberDetails = currentMemberDetails
        tempMemberDetails.photoUrl = null
        setCurrentMemberDetails(JSON.parse(JSON.stringify(tempMemberDetails)))
    }

    const onChangeInput = (inp, key, type = "", value = "") => {
        if (key == roleKey) {
            if (currentMemberDetails.role == "teamMember" && value == "teamLeader") {
                setRoleChangedFromTCToTL(true);
            }
            else {
                setRoleChangedFromTCToTL(false);
            }
        }
        if (mode == "add" && key == roleKey && value == "teamLeader") {
            setSelectedTeam({})
        }
        let tempMemberDetails = currentMemberDetails
        tempMemberDetails[`${key}`] = type == "input" ? inp?.target?.value : value
        setCurrentMemberDetails(JSON.parse(JSON.stringify(tempMemberDetails)))
        resetErrors(key)
    }

    const accessModalOk = () => {
        if (currentMemberDetails.status == "Active") {
            onChangeInput(null, 'status', "button", "Inactive")
            trackEvent('removed_access_for_role', { role: currentMemberDetails.role }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
            updateMemberDetails()
        }
        else {
            onChangeInput(null, 'status', "button", "Active")
            updateMemberDetails()
        }
    }

    const onTeamSelected = (team) => {
        onChangeInput(null, "teamId", "dropdown", team.key)
        setSelectedTeamError(false);
        setSelectedTeam({ ...selectedTeam, teamName: team.label, teamId: team })
    }

    const resetErrors = (key) => {
        switch (key) {
            case nameKey:
                setNameError(false)
                break;
            case phoneKey:
                setPhoneError(false);
                setPhoneFormatError(false);
                break;
            case roleKey:
                setRoleError(false);
                break;
        }
    }

    const checkIfFormIsValid = (stateUpdate = false) => {
        var regex = new RegExp("^[0-9]{10}$");
        let isValid = true
        if (memberName?.current?.input?.value.trim() == "") {
            if (stateUpdate)
                setNameError(true)
            isValid = false
        }
        if (!regex.test(memberPhoneNo?.current?.input?.value)) {
            if (stateUpdate)
                setPhoneFormatError(true)
            isValid = false
        }
        if (memberPhoneNo?.current?.input?.value.trim() == "") {
            if (stateUpdate)
                setPhoneError(true)
            isValid = false
        }
        if (!currentMemberDetails?.role) {
            if (stateUpdate)
                setRoleError(true)
            isValid = false
        }

        return isValid;
    }

    const addMemberDetails = () => {
        if (!checkIfFormIsValid(true)) {
            notification.open({
                message: "Please fix the errors",
                key,
                duration: 5,
                placement,
                icon: <ExclamationCircleOutlined className='green-positive' />,
            });
        }

        else {
            setSavingDetails(true)
            postUserInfoForm(memberName.current.input.value.trim(), memberPhoneNo.current.input.value.trim(), "Active", currentMemberDetails.role, currentMemberDetails.photoUrl || '', memberEmail.current.input.value.trim(), mitraReducer.mitraInfo.id, selectedTeam?.teamId?.key).then((response) => {
                trackEvent('added_new_member_success', { role: currentMemberDetails.role }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                let tempMemberDetails = currentMemberDetails
                tempMemberDetails.name = ""
                setCurrentMemberDetails(JSON.parse(JSON.stringify(tempMemberDetails)))
                setSavingDetails(false)
                if (response?.data?.validLead) {
                    notification.open({
                        message: response.data.message,
                        key,
                        duration: 5,
                        placement,
                        icon: <CheckCircleOutlined className='green-positive' />,
                    });
                    closeDrawer()
                    memberDetailsUpdated()
                } else {
                    trackEvent('added_new_member_failed', { role: currentMemberDetails.role }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                    notification.open({
                        message: response.data.message,
                        key,
                        duration: 5,
                        placement,
                        icon: <ExclamationCircleOutlined className='red-negative' />,
                    });
                }
            })
                .catch((err) => {
                    setSavingDetails(false)
                    notification.open({
                        message: "We're having trouble updating member details right now. Please try again later",
                        key,
                        duration: 5,
                        placement,
                        icon: <ExclamationCircleOutlined className='green-positive' />,
                    });
                })
        }
    }

    const updateMemberDetails = async () => {
        if (!checkIfFormIsValid(true)) {
            notification.open({
                message: "Please fix the errors before proceeding",
                key,
                duration: 5,
                placement,
                icon: <ExclamationCircleOutlined className='green-positive' />,
            });
        }

        else {
            setSavingDetails(true)
            if (currentMemberDetails.team && currentMemberDetails.team == "No Team Assigned") {
                currentMemberDetails.team = null
            }
            if (!currentMemberDetails.team_id) {
                delete currentMemberDetails.team_id
            }
            const update = await updateMitrasById(currentMemberDetails)
                .catch(error => {
                    notification.open({
                        message: error.response.data ? error.response.data.message : error.message,
                        key,
                        duration: 5,
                        placement,
                        icon: <ExclamationCircleOutlined className='red-negative' />,
                    });
                })

            setSavingDetails(false)
            if (update?.data.status) {
                trackEvent('updated_member_details_successfully', { role: currentMemberDetails.role }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                memberDetailsUpdated()
                notification.open({
                    message: update.data.message,
                    key,
                    duration: 5,
                    placement,
                    icon: <CheckCircleOutlined className='green-positive' />,
                });
                closeDrawer()
            }
            else {
                trackEvent('updated_member_details_failed', { role: currentMemberDetails.role }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
            }
        }

    }

    const createTeamWithTL = () => {
        if (roleChangedFromTCToTL) {
            notification.open({
                message: "Please save the user as TL first before creating their team.",
                key,
                duration: 5,
                placement,
                icon: <ExclamationCircleOutlined className='red-negative' />,
            });
            return
        }
        if (currentMemberDetails.status == "Active") {
            setCurrentTLDetails({ ...currentTLDetails, name: currentMemberDetails.name, id: currentMemberDetails.id })
            setShowCreateTeamModal(true);
        }
        else {
            notification.open({
                message: "Please activate the member first before creating a team.",
                key,
                duration: 5,
                placement,
                icon: <ExclamationCircleOutlined className='red-negative' />,
            });
        }
    }

    const removeTLFromCurrentTeam = () => {
        trackEvent('removed_tl_from_team', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        setShowConfirmChangesModal(true)
        setShowRemoveTLFromTeamText(true)
    }

    const renderTeamSectionForTL = () => {
        return (
            mode == "add" && currentMemberDetails.role == "teamLeader" ?
                <div className='as-tl-add-txt semibold14-22 black45 txt-left'>
                    You can create a Team with the Team Lead once they have been added.
                </div>
                :
                mode == "update" && currentMemberDetails.role == "teamLeader" && !currentMemberDetails.team_id ?
                    <div className='as-tl-create-team-txt semibold14-22 primary-orange2 jp-cp' onClick={() => createTeamWithTL()}>
                        + Create Team with Team Lead
                    </div>
                    :
                    mode == "update" && currentMemberDetails.role == "teamLeader" && currentMemberDetails.team_id ?
                        <div className='as-existing-team w-100 d-flex'>
                            <div className='as-tl-team'>
                                {currentMemberDetails.team}
                            </div>

                            {
                                mitraReducer?.mitraInfo?.role == "teamLeader" ?
                                    currentMemberDetails.role !== "teamLeader" ?
                                        <div className='as-remove-team-txt semibold14-22 primary-orange2 jp-cp' onClick={() => removeTLFromCurrentTeam()}>
                                            Remove from Team
                                        </div>
                                        :
                                        null
                                    :
                                    <div className='as-remove-team-txt semibold14-22 primary-orange2 jp-cp' onClick={() => removeTLFromCurrentTeam()}>
                                        Remove from Team
                                    </div>
                            }

                        </div>
                        :
                        null
        )
    }

    const onSaveClicked = () => {
        if (mode == "add") {
            addMemberDetails();
        }
        else {
            setShowConfirmChangesModal(true)
        }
    }

    const confirmChangeClicked = () => {
        if (mode == "add") {
            setShowConfirmChangesModal(false);
            addMemberDetails();
        }
        else if (showRemoveTLFromTeamText) {
            let tempMemberDetails = currentMemberDetails
            tempMemberDetails.team = null
            tempMemberDetails.teamId = null
            setSelectedTeam({})
            setCurrentMemberDetails(JSON.parse(JSON.stringify(tempMemberDetails)))
            updateMemberDetails();
        }

        else {
            setShowConfirmChangesModal(false);
            updateMemberDetails();
        }
    }

    return (
        <>
            <div className='account-sidesheet-parent parentContainer'>
                {showCreateTeamModal && <CreateEditTeamModal isModalOpen={showCreateTeamModal} closeModal={() => setShowCreateTeamModal(false)} currentTL={currentTLDetails} teamCreated={memberDetailsUpdated} />}
                <Modal
                    title={null}
                    centered
                    visible={isAccessModalOpen}
                    footer={null}
                    width={600}
                    className="as-confirm-changes-modal-parent"
                    onCancel={() => setIsAccessModalOpen(false)}
                >
                    <div className='as-confirm-changes-modal-content'>

                        <div className='asccm-header-body d-flex justify-content-between'>
                            <div className='asccm-header d-flex justify-content-between align-items-center'>
                                <div className='asccm-header-title d-flex justify-content-between align-items-center'>
                                    <div className='d-flex align-items-center'>
                                        <span className='asccm-header-txt semibold16-24 black85'>
                                            Confirm Changes
                                        </span>
                                    </div>
                                </div>
                                <div className='asccm-header-close jp-cp' onClick={() => setIsAccessModalOpen(false)}>
                                    <CloseOutlined className='asccm-header-close-icon' />
                                </div>
                            </div>

                            <Divider className='as-divider'/>

                            <div className='asccm-body regular14-22 black85'>
                                <div className='regular16-24 txt-left'>
                                    {currentMemberDetails.status == "Active" ?
                                        `Are you sure you want to remove access for ${currentMemberDetails.name}?`
                                        :
                                        `Are you sure you want to allow access for ${currentMemberDetails.name}?`}
                                </div>
                            </div>
                        </div>


                        <div className='asccm-footer'>
                            <Divider className='as-divider'/>
                            <div className='asccm-footer-btns-container d-flex justify-content-between h-100 w-100 align-items-center'>
                                <Button className='asccm-footer-cancel-btn h-100' onClick={() => setIsAccessModalOpen(false)}>
                                    <span className='regular14-22 black85'>
                                        Cancel
                                    </span>
                                </Button>

                                <Button className='vl-primary-btn asccm-footer-confirm-btn h-100' onClick={accessModalOk}>
                                    <span className='bold16-24 plain-white'>
                                        Confirm
                                    </span>
                                </Button>
                            </div>

                        </div>
                    </div>
                </Modal>

                <Modal
                    title={null}
                    centered
                    visible={showConfirmChangesModal}
                    footer={null}
                    width={600}
                    className="as-confirm-changes-modal-parent"
                    onCancel={() => setShowConfirmChangesModal(false)}
                >
                    <div className='as-confirm-changes-modal-content'>

                        <div className='asccm-header-body d-flex justify-content-between'>
                            <div className='asccm-header d-flex justify-content-between align-items-center'>
                                <div className='asccm-header-title d-flex justify-content-between align-items-center'>
                                    <div className='d-flex align-items-center'>
                                        <span className='asccm-header-txt semibold16-24 black85'>
                                            Confirm Changes
                                        </span>
                                    </div>
                                </div>
                                <div className='asccm-header-close jp-cp' onClick={() => setShowConfirmChangesModal(false)}>
                                    <CloseOutlined className='asccm-header-close-icon' />
                                </div>
                            </div>

                            <Divider className='as-divider'/>

                            <div className='asccm-body regular14-22 black85'>
                                {
                                    showRemoveTLFromTeamText ?
                                        <span className='regular14-22 black85 txt-left'>
                                            Are you sure you want to remove the Team Lead from the Team?
                                            <br />
                                            <br />
                                            The Team will be deleted and all Team Members will be moved to 'No Team Assigned'.
                                        </span>
                                        :
                                        <span className='regular14-22 black85 txt-left'>{`Are you sure you want to make the changes to the ${currentMemberDetails.role == "admin" ? "Admin" : currentMemberDetails.role == "teamLeader" ? "Team Leader" : "Team Member"} profile?`}</span>
                                }
                            </div>
                        </div>


                        <div className='asccm-footer'>
                            <Divider className='as-divider'/>
                            <div className='asccm-footer-btns-container d-flex justify-content-between h-100 w-100 align-items-center'>
                                <Button className='asccm-footer-cancel-btn h-100' onClick={() => setShowConfirmChangesModal(false)}>
                                    <span className='regular14-22 black85'>
                                        Cancel
                                    </span>
                                </Button>

                                <Button className='vl-primary-btn asccm-footer-confirm-btn h-100' onClick={() => confirmChangeClicked()}>
                                    <span className='bold16-24 plain-white'>
                                        Confirm
                                    </span>
                                </Button>
                            </div>

                        </div>
                    </div>
                </Modal >

                <div onClick={closeDrawer} className='emptyContainer'>
                </div>
                <div className='containerSS'>
                    <div className='navigator'>
                        <div className='nav1'>
                            {/* <ArrowLeftOutlined className='fs-filter-icon' onClick={closeDrawer}/> */}
                            <div className='sidername'>{mode == "add" ? "Add Team Member" : currentMemberDetails.name ? currentMemberDetails.name : "Add Team Member"}</div>
                        </div>
                        <div className='fs-header-close jp-cp d-flex' onClick={closeDrawer}>
                            <CloseOutlined className='fs-header-close-icon' />
                        </div>
                    </div>
                    <div className='as-body childContainer'>
                        {
                            mode == "update" && currentMemberDetails && currentMemberDetails.status !== "Active" ?
                                <div className='as-inactive-txt bold16-24 black45'>
                                    ACCOUNT INACTIVE
                                </div> : null
                        }
                        {
                            currentMemberDetails && currentMemberDetails.photoUrl ?
                                <div className='d-flex justify-content-center'>
                                    <div className='as-profile-pic-image-container'>
                                        <img src={currentMemberDetails.photoUrl} alt="" className='as-profile-pic-image' />
                                        <DeleteFilled className='as-profile-pic-delete-icon jp-cp red-negative' onClick={removeProfilePic} />
                                    </div>
                                </div>
                                :
                                <Upload {...props} className='as-profile-pic-upload' disabled={mode == "update" && currentMemberDetails.status !== "Active"}>
                                    <div className='as-profile-pic-icon-container'>
                                        <CameraOutlined className="as-profile-pic-upload-icon" />
                                    </div>
                                </Upload>
                        }


                        <div className='as-input-parent'>
                            <CommonFieldInput
                                ref={memberName}
                                onChange={(e) => onChangeInput(e, nameKey, "input", null)}
                                value={memberDetails?.name}
                                placeholder={"Enter the name"}
                                imgsrc={'black-user.svg'}
                                labelname={"Team Member Name"}
                                required={true}
                                error={{ status: nameError, text: 'Name is required' }}
                                disabled={currentMemberDetails.role == "admin"} />
                            <CommonFieldInput
                                ref={memberPhoneNo}
                                disabled={mode == "update" && memberDetails?.phoneNumber}
                                onChange={(e) => onChangeInput(e, phoneKey, "input", null)}
                                value={memberDetails?.phoneNumber}
                                placeholder={"Ex : 8884228005"}
                                imgsrc={'phone.svg'}
                                labelname={"Mobile Number"}
                                required={true}
                                error={{
                                    status: phoneError || phoneFormatError,
                                    text: phoneError ? 'Phone Number is required'
                                        : phoneFormatError ? "Please enter only 10 digit mobile no. without any prefix (+91 or 0) and without any spaces between the digits."
                                            : ""
                                }}
                                inputType={"number"}
                                maxAllowed={10}
                            />
                            <CommonFieldInput
                                ref={memberEmail}
                                onChange={(e) => onChangeInput(e, 'email', "input", null)}
                                value={memberDetails?.email}
                                placeholder={"Example@gmail.com"}
                                imgsrc={'mail.svg'}
                                labelname={"Email ID"}
                                disabled={currentMemberDetails.role == "admin"} />
                            <div className='as-team-select-container d-flex align-items-start w-100'>
                                <span className='ctm-lead-select-label ctm-field-label regular14-22'>
                                    Role <span className='red-negative'>*</span>
                                </span>
                                <Select
                                    className='as-team-dropdown w-100'
                                    placeholder={`Select Role`}
                                    onChange={(roleSelected) => onChangeInput(null, roleKey, "dropdown", roleSelected)}
                                    value={currentMemberDetails.role ? currentMemberDetails.role : selectedRole}
                                    disabled={currentMemberDetails.role == "admin" || mitraReducer?.mitraInfo.role == "teamLeader" || source == "TeamDetailsContainer"}
                                >
                                    {
                                        roleList?.map((role) => {
                                            return (
                                                <Option value={role.key} key={role.key}>
                                                    <div className='w-100'>
                                                        <span className='semibold14-22 black45'> {role.label}</span>
                                                    </div>
                                                </Option>
                                            )
                                        })
                                    }

                                </Select>
                                {
                                    roleError ?
                                        <span className='as-team-select-error red-negative regular14-22'>
                                            Please select a role *
                                        </span>
                                        :
                                        null
                                }
                            </div>
                            <div className='as-team-select-container d-flex align-items-start w-100'>
                                {
                                    currentMemberDetails.role == "teamLeader" || currentMemberDetails.role == "teamMember" ?
                                        <span className='ctm-lead-select-label ctm-field-label regular14-22'>
                                            Team
                                        </span>
                                        :
                                        null
                                }
                                {
                                    currentMemberDetails.role == "teamLeader" ?
                                        renderTeamSectionForTL()
                                        :
                                        currentMemberDetails.role == "teamMember" ?
                                            <Select
                                                className='as-team-dropdown w-100'
                                                placeholder={`No Team Assigned`}
                                                labelInValue
                                                onChange={onTeamSelected}
                                                value={selectedTeam ? selectedTeam.teamName : undefined}
                                                disabled={source == "TeamLeaderView" || source == "TeamDetailsContainer"}
                                            >
                                                {
                                                    teamList?.map((team) => {
                                                        return (
                                                            <Option value={team.teamId} key={team.teamId}>
                                                                {team.team}
                                                            </Option>
                                                        )
                                                    })
                                                }

                                            </Select>
                                            :
                                            null
                                }
                                {
                                    selectedTeamError ?
                                        <span className='as-team-select-error red-negative regular14-22'>
                                            Please select a team *
                                        </span>
                                        :
                                        null
                                }
                            </div>

                        </div>
                    </div>

                    <div className='as-btns-container d-flex justify-content-between btns'>
                        {
                            mode == "update" ?
                                <Button onClick={() => setIsAccessModalOpen(true)} className={"vl-secondary-btn as-remove-btn d-flex align-items-center justify-content-center"} type="primary" disabled={mitraReducer?.mitraInfo?.role == currentMemberDetails.role}>
                                    {mode == "update" && currentMemberDetails.status == "Active" ? <DeleteOutlined className='as-remove-btn-icon' /> : null}
                                    <span className='fs-btn-txt primary-orange2'>{currentMemberDetails.status == "Active" ? "Remove Access" : "Re-activate Access"}</span>
                                </Button>
                                :
                                null
                        }

                        {
                            <Button className={`as-submit-btn btn commonlayoutbtn d-flex align-items-center justify-content-center ${mode == "update" ? "" : "as-btn-w-90"}`} type="primary" onClick={() => onSaveClicked()} disabled={!checkIfFormIsValid(false)}>
                                <span className='fs-btn-txt fs-btn-txt-white'>{mode == "add" ? savingDetails ? "Adding" : "Add" : savingDetails ? "Saving" : "Save"}</span>
                                {
                                    savingDetails ?
                                        <span><Spin indicator={<LoadingOutlined className='as-submit-btn-loader' spin />} /></span>
                                        :
                                        null
                                }
                            </Button>
                        }
                    </div>
                </div>
            </div>

        </>
    );
};

export default AccountSidesheet;
