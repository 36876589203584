import React from "react";
import { Input} from 'antd';

const { Search } = Input;

const GlobalSearchMobileAndDesktop = ({searchItem,placeholder}) => {
    placeholder = placeholder ? placeholder : "Input to search...";

    const onSearchFunc=(e)=>{
            searchItem(e);
    }
        
    return <div className="searchPage">
        <Search placeholder={placeholder} allowClear onSearch={onSearchFunc} enterButton />

	</div>
}

export default GlobalSearchMobileAndDesktop;
