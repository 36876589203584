import React, {useContext, useEffect, useState} from "react";
import './newOnboardingQuestions.scss';
import { Typography,Divider,Radio, Space, Button, Slider,Col, InputNumber, Row,Card, notification } from "antd";
import {QuestionCircleOutlined,ArrowRightOutlined,CheckCircleFilled} from '@ant-design/icons';
import { fetchOnboardingQuestion, submitOnboardingAnswer } from "../../service/onboardingService";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../App";
import * as loginService from './../../service/loginService'; 
import { trackEvent } from "../../service/engagementMonitoringService";
import CONSTANTS from "../../constants/constants";

const { Text} = Typography;

const NewOnboardingQuestions = () => {

    const history = useHistory();
    const {mitraReducer} = useContext(AppContext);
    const [inputValue, setInputValue] = useState(1);
    const [currentQuestion, setCurrentQuestion] = useState({});
    const [answerType, setAnswerType] = useState();
    const [currentQuestionsAnswerArray, setCurrentQuestionsAnswerArray] = useState([]);
    const [answerText, setAnswerText] = useState();
    const [chosenAnswerObject, setChosenAnswerObject] = useState();
    const [isLoading, setLoader] = useState(false);

    // variables used for progress bar
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState();
    const [totalQuestions, setTotalQuestions] = useState([{id:1}, {id:2},{id:3}]);

    const setData = (response) => {
        if (!response.data.viewData) {
            history.push('/home');
            localStorage.removeItem('currentOnboardingQuestion');
        } else {
            if (response.data?.viewData?.question?.length) {
                // setting up question data
                setCurrentQuestion(response.data.viewData.question[0]);
            }
            if (response.data?.viewData?.answerOptions?.length) {
                // setting up answer options and answer type [slider,text,radio etc]
                setAnswerType(response.data.viewData.answerOptions[0].answerType);
                setCurrentQuestionsAnswerArray(response.data.viewData.answerOptions);
            }
            if (response.data?.viewData?.answerOptions?.[0]?.answerType !== 'radio') {
                // setting values for when answer type is other than radio[e.g slider and text]
                // since for such types, answer array will have only single object
                // that itself will be considered as chosen answer
                // and default answer text will be set in such case
                setChosenAnswerObject(response.data.viewData.answerOptions[0]);
                setAnswerText('1');
            }
            if (response.data?.viewData?.answerOptions?.[0]?.answerType === 'text') {
                // checking if user doesnt have any prior recruitment exp, then revising total questions
                // mainly done to adjust progress bar
                setTotalQuestions([{id:1}, {id:2}]);
            } else {
                setTotalQuestions([{id:1}, {id:2},{id:3}]);
            }
        }
    };

    const fetchQuestion = () => {
        fetchOnboardingQuestion().then((response) => {
            setData(response);
        }).catch((error) => {
            console.log(error);
        });
    };

    const submitAnswer = () => {
    if(chosenAnswerObject && chosenAnswerObject.questionId === currentQuestion.id) {
        setLoader(true);
        const submissionData = {
            answerId: chosenAnswerObject.id,
            questionId: currentQuestion.id,
            mitraValue: answerText
        };
        submitOnboardingAnswer(submissionData).then(response => {
            setData(response);

            // setting up progress bar values once a question has been submitted
            trackEvent("onboarding_question_number", {questionNumber: parseInt(localStorage.getItem('currentOnboardingQuestion'))}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)
            setCurrentQuestionNumber(parseInt(localStorage.getItem('currentOnboardingQuestion')) + 1);
            localStorage.setItem('currentOnboardingQuestion', JSON.stringify(parseInt(localStorage.getItem('currentOnboardingQuestion')) + 1));
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            console.log(error);
        })
    }
    };

    useEffect(() => {
        if (!localStorage.getItem('currentOnboardingQuestion') || !parseInt(localStorage.getItem('currentOnboardingQuestion'))) {
            // if localstorage doesnt have 'currentOnboardingQuestion' property stored
            localStorage.setItem('currentOnboardingQuestion', '1');
            setCurrentQuestionNumber(1);
        } else {
            setCurrentQuestionNumber(parseInt(localStorage.getItem('currentOnboardingQuestion')));
        }
        loginService
            .validateSession()
            .then((response) => {
                if (response.data.mitra.isFeedBackCompleted) {
                    if(!response.data.mitra.isBankInfoFilled) {
                        history.push('/ml-onboarding');
                        notification.info({
                            message: 'Please fill required details to get onboarded with VAHAN',
                            placement: 'topRight',
                            duration: 5
                        });
                    }
                    else{
                        history.push('/home');
                    }
                    localStorage.removeItem('currentOnboardingQuestion');
                }
            }).catch(error => {
                console.log(error);
            })
        fetchQuestion();
    }, []);

    const onSliderChange = (newValue) => {
        setInputValue(newValue);
        setAnswerText(newValue);
    }

    const onRadioChange = (event) => {
        console.log(event.target.value);
        setChosenAnswerObject(event.target.value);
        setAnswerText(event.target.value.answerText);
    };

    return (
        <div className="spare-main-container">
            <div className="positioning-container">
                <div className="onboarding-questions-main-container">
                    <div className="welcome-header">
                        <img className="vahan-logo" style={{height:'7em', width:'auto'}} alt="vahan" src="/image/vahan-new-logo.png" />
                        <div className="text-content-area">
                            <Text className="welcome-text">Welcome, {mitraReducer?.mitraInfo?.name}</Text>
                            <Text className="setup-account-text">Let&apos;s setup you account</Text>
                        </div>
                    </div>
                    <div className="questions-container">
                        <div className="question-number-indicator">
                        {
                            totalQuestions.map((item,index)=> {
                                return (
                                    <div 
                                    key={index} 
                                    className={item.id < currentQuestionNumber ? 'level-indicators level-completed': 
                                    item.id === currentQuestionNumber ? 'level-indicators level-in-progress': 'level-indicators level-yet-to-begin'}
                                    >
                                    </div>
                                )
                            })
                        }
                        </div>
                        <div className="onboarding-questions-container">
                            
                            {
                                answerType === 'radio' ? (
                                    <>
                                    <Text className="question-text"><QuestionCircleOutlined style={{marginTop:'6px'}}/> {currentQuestion.questionText}</Text>
                                        <Radio.Group onChange={onRadioChange}>
                                            <Space direction="vertical">
                                                {
                                                    currentQuestionsAnswerArray.map((answer,index)=> {
                                                        return (
                                                            <Radio key={answer.id} value={answer}>{answer.answerText}</Radio>
                                                        )
                                                    })
                                                }
                                            </Space>
                                        </Radio.Group>
                                        </>
                                    
                                    
                                ) : null
                            
                            }
                            {
                                answerType === 'slider' ? (
                                    <>
                                    <Text className="question-text"><QuestionCircleOutlined style={{marginTop:'6px'}}/> {currentQuestion.questionText}</Text>
                                        <Row>
                                            <Col span={16}>
                                                <Slider
                                                min={1}
                                                onChange={onSliderChange}
                                                value={typeof inputValue === 'number' ? inputValue : 0}
                                                />
                                            </Col>
                                            <Col span={2}>
                                                <InputNumber
                                                min={1}
                                                style={{ margin: '0 16px' }}
                                                value={inputValue}
                                                onChange={onSliderChange}
                                                />
                                            </Col>
                                        </Row>
                                        </>
                                    
                                ) : null
                            }
                            {
                                answerType === 'text' ? (
                                <>
                                <Text className="question-text"><CheckCircleFilled style={{color:'#52C41A', marginTop:'6px'}}/>
                                    No experience, no worries.<br />
                                    Our Team of dedicated account managers will help you start and grow your business.
                                </Text>

                                <Card bodyStyle={{padding:'8px'}}>
                                    <Text>
                                    Your account manager will get in touch with you in the next 24-48 hours on your registered mobile number.
                                    </Text>
                                </Card>
                                </>
                                ): null
                            }
                             
                        </div>
                        <div>
                            {
                                answerType === 'radio' ? (
                                    <Button loading={isLoading} className="onboarding-questions-submit-button" onClick={submitAnswer}>Next <ArrowRightOutlined /></Button>
                                ) : (
                                    <Button loading={isLoading} className="last-question" onClick={submitAnswer}>Get Started <ArrowRightOutlined /></Button>
                                )
                            }
                            
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="powered-by-content">
                <Divider />
                <div className="footer-text">
                    <Text className="powered-by-text">Powered by</Text>
                    <img className="vahan-logo custom-ob-logo" style={{height:'1.2em'}} alt="vahan" src="/image/vahan-final-logo-black.png" />
                </div>
                
            </div>
        </div>
    )
};

export default NewOnboardingQuestions;
