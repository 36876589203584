import axios from 'axios';
import CONSTANTS from "../constants/constants.js";

function getFDHeader() {
    return {
        headers:
        {
            'Authorization': 'Basic ' + CONSTANTS.FD_TOKEN,
            'Accept-Encoding': 'gzip',
            'Content-Type': 'application/json; charset=utf-8',
        }
    };
}

const updateStatus = async (ticket) => {
    const ticketId = ticket.ticketId;
    const status = ticket.status;

    const url = `https://vahan-mitraleadersupport.freshdesk.com/api/v2/tickets/${ticketId}`;
    const body = {
        status: status,
    };
    const headers = getFDHeader();
    const response = await axios.put(url, JSON.stringify(body), headers);
    return response;
}

const getAgentInfo = async (ticket) => {
    const agentId = ticket.responder_id;

    if (!agentId) return null;

    const url = `https://vahan-mitraleadersupport.freshdesk.com/api/v2/agents/${agentId}`;
    const headers = getFDHeader();
    const response = await axios.get(url, headers);
    return response;
}

export const sendChat = async (ticket, message, attachments) => {
    const ticketId = ticket.ticketId;
    const userId = ticket.requester_id;

    const agentInfo = await getAgentInfo(ticket);

    const url = `https://vahan-mitraleadersupport.freshdesk.com/api/v2/tickets/${ticketId}/notes`;

    const form = new FormData();
    form.append('body', message);
    form.append('private', false);
    if(agentInfo && agentInfo.data && agentInfo.data.contact && agentInfo.data.contact.email) {
        form.append('notify_emails[]', agentInfo.data.contact.email);
    }
    if (attachments) {
        attachments.forEach(attachment => {
            form.append('attachments[]', attachment);
        });
    }
    form.append('user_id', userId);

    const headers = getFDHeader();
    headers.headers['Content-Type'] = 'multipart/form-data; boundary=' + form._boundary;

    const response = await axios.post(url, form, headers);
    return response;
}

export const reOpenTicket = async (ticket, reason) => {
    const response = await sendChat(ticket, reason);
    return response;
};

export const getAllConversations = async (ticket) => {
    const ticketId = ticket.ticketId;

    const url = `https://vahan-mitraleadersupport.freshdesk.com/api/v2/tickets/${ticketId}/conversations`;
    const headers = getFDHeader();
    const response = await axios.get(url, headers);

    // filtering the conversations that are public only
    const conversations = {
        data: response?.data?.filter(conversation => conversation.private === false) || [],
    };
    return conversations;
};
