import React, { useState } from 'react';
import { Button } from '@rmwc/button';

const UploadMarketingData = () => {
  const [facebookLeads, setFacebookLeads] = useState(null);
  const [facebookExperiments, setFacebookExperiments] = useState(null);
  const [googleLeads, setGoogleLeads] = useState(null);
  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    const xhr = new XMLHttpRequest();
    formData.append('files[]', facebookLeads, facebookLeads.name);
    formData.append('files[]', facebookExperiments, facebookExperiments.name);
    formData.append('files[]', googleLeads, googleLeads.name);
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          alert(xhr.response);
        } else {
          alert(xhr.response);
        }
      }
    };
    let url = process.env.REACT_APP_BACKEND_URL + '/postMarketingData';
    xhr.open('POST', url);
    xhr.send(formData);
  };

  return (
    <div className="Home mainContentZ">
      <h2 className="heading_1 add-job">Vahan Jobs</h2>
      <h4 className="heading_1 form-sub-heading">Upload Data</h4>
      <form onSubmit={onSubmit}>
        <div className="form-control">
          <div className="input-text-title field-required">Facebook Leads</div>
          <input type="file" accept=".xlsx" onChange={(e) => setFacebookLeads(e.target.files[0])} />
        </div>
        <div className="form-control">
          <div className="input-text-title field-required">Facebook Experiments</div>
          <input
            type="file"
            accept=".xlsx"
            onChange={(e) => setFacebookExperiments(e.target.files[0])}
          />
        </div>
        <div className="form-control">
          <div className="input-text-title field-required">Google Leads</div>
          <input type="file" accept=".xlsx" onChange={(e) => setGoogleLeads(e.target.files[0])} />
        </div>
        <div className="form-control">
          <Button raised label="Submit" type="submit" />
        </div>
      </form>
    </div>
  );
};

export default UploadMarketingData;
