import React from "react"
import { useHistory } from "react-router"
import "./../../css/mainLogoHeader.css"
import { inValidateSession,validateSession } from "../../service/loginService"
import { Button } from "@rmwc/button"
// import useMoengage from '../../hooks/useMoengage';
import { trackEvent } from "../../service/engagementMonitoringService";
import CONSTANTS from "../../constants/constants"

const MainLogoHeader = props => {

    const history = useHistory()
    // const [createMoengageUser, trackEvent] = useMoengage();
    const logout = async () => {
        let valid =  await validateSession()
        let res =  await inValidateSession()
        trackEvent('sign_out_clicked', {mitraId:valid.data.mitra.id},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        history.replace("/")

    }

    return (
        <div className="training_session_main" >
            {!props.backButton && <img className="vahan_logo" alt="vahan" src="/image/vahan-new-logo.png" />}
            {props.backButton && <button onClick={() => {history.goBack()}}> <i className="fa fa-arrow-left back_icon"></i> </button>}
            {/* <button className="signout_button" onClick={logout}>Sign out</button> */}
        </div>
    )
}

export default MainLogoHeader;
