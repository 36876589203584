import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Divider, Button, Select } from 'antd';
import { UsergroupAddOutlined, ArrowRightOutlined, EnvironmentOutlined } from '@ant-design/icons';
import moment from 'moment';
import './TeamSectionContainerStyle.scss'
import '../../../../../css/globalStyles.scss'
import AccountSidesheet from '../../common/AccountSidesheet';
import LockingComponent from '../../../../common/Locking.component';
import { trackEvent } from '../../../../../service/engagementMonitoringService';
import CONSTANTS from '../../../../../constants/constants';
import AccountLocking from '../../common/AccountLocking';


const TeamListItem = ({ listItem = {
    "id": "",
    "name": "",
    "phoneNumber": "",
    "photoUrl": null,
    "status": "",
    "dateOfJoining": null,
    "createdAt": "",
    "updatedAt": "",
    "deletedAt": null,
    "type": "",
    "sourcingCities": null,
    "sourcingLocalities": null,
    "homeCity": null,
    "managerMitraID": "",
    "teamLeadMitraID": null,
    "role": "",
    "email": null,
    "optInInfo": null,
    "accountManagerId": null,
    "acquisitionSource": "",
    "password": null,
    "bridgeNumber": null,
    "placementCountDivision": null
}, updatedMemberDetails, role, source }) => {
    const history = useHistory();
    const [filterSidebar, setFilterSidebar] = useState(false);
    const [showAuthModal, setShowAuthModal] = useState(false);

    const returnRoleLabel = (role) => {
        switch (role) {
            case "admin":
                return "Admin";
            case "teamLeader":
                return "TL";
            case "teamMember":
                return "TC"
        }
    }

    const checkForAuth = () => {
        let isAllowed = false
        if (!localStorage.getItem('payoutAccessToken')) {
            setShowAuthModal(true)
            isAllowed = false
        }
        else {
            setShowAuthModal(false)
            isAllowed = true
        }
        return isAllowed
    }

    const showMemberDetails = () => {
        trackEvent('viewed_member_details', {role: listItem?.role}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);

        if (source !== "TeamLeaderView") {
            if (checkForAuth()) {
                setFilterSidebar(true)
            }
        }
        else {
            setFilterSidebar(true)
        }
    }

    const showTeamDetails = () => {
        history.push({
            pathname: `/team-details/${listItem.teamId}`,
        })
    }

    return (
        <div className='team-list-parent'>
            <AccountLocking path="account" pathName="Account" visibleAuth={showAuthModal} setVisibleAuth={setShowAuthModal} dontGoBack={true} onCancelClick={() => setShowAuthModal(false)}/>
            {role.toLowerCase() !== "teams" ?
                <div className='non-team-role'>
                    <div className={`team-list-container d-flex justify-content-between align-items-center show-only-desktop ${listItem.status !== "Active" ? "tl-disabled-list-item" : ""}`}>
                        {listItem ?
                            <div className='tl-left-parent'>
                                <div className='tl-left d-flex align-items-center'>

                                    {
                                        listItem.photoUrl ?
                                            <div className='tl-member-profile-pic d-flex justify-content-center'>
                                                <img src={listItem.photoUrl} alt="" className='tl-member-profile-pic-image' />
                                            </div>
                                            :
                                            <div className={`tl-member-name-icon d-flex justify-content-center ${listItem?.role == "admin" ? "tl-member-name-icon-admin" : listItem.role == "teamLeader" ? "tl-member-name-icon-tl" : listItem?.role == "teamMember" ? "tl-member-name-icon-tc" : null}`}>
                                                {
                                                    listItem.name ? listItem.name.substring(0, 2).toUpperCase() : ""
                                                }
                                            </div>
                                    }
                                    <div className='tl-name semibold16-24' title={listItem.name}>
                                        {listItem.name}
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }

                        <div className={`tl-middle d-flex`}>
                            <span className={`tl-role-tag semibold14-22 d-flex align-items-center justify-content-center ${listItem?.role == "admin" ? "tl-admin-bg" : listItem.role == "teamLeader" ? "tl-team-lead-bg" : listItem?.role == "teamMember" ? "tl-team-member-bg" : null}`}>
                                {returnRoleLabel(listItem.role)}
                            </span>
                            <span className={`tl-member-team black45`}>
                                {listItem?.team ? listItem?.team : " "}
                            </span>
                            <span className={`tl-member-status semibold14-22 ${listItem?.status?.toLowerCase() == "active" ? "green-positive" : "black45"}`}>
                                {listItem?.status == "Inactive" ? listItem?.status : ""}
                            </span>
                        </div>

                        <div className='tl-right d-flex align-items-center justify-content-end'>
                            <div className='d-flex align-items-center jp-cp' onClick={() => showMemberDetails()}>
                                <span className='bold14-22 primary-orange2'>
                                    View Details
                                </span>
                                <ArrowRightOutlined className='tl-right-icon primary-orange2' />
                            </div>
                        </div>
                    </div>

                    <div className='team-list-container show-only-mobile-disp-flex d-flex align-items-center w-100'>
                        <div className='tl-left-parent'>
                            <div className='tl-left tl-left-mobile d-flex align-items-center'>
                                {
                                    listItem.photoUrl ?
                                        <div className='tl-member-profile-pic d-flex justify-content-center'>
                                            <img src={listItem.photoUrl} alt="pic" className='tl-member-profile-pic-image' />
                                        </div>
                                        :
                                        <div className='tl-member-name-icon d-flex justify-content-center'>
                                            {
                                                listItem.name ? listItem.name.substring(0, 2).toUpperCase() : ""
                                            }
                                        </div>
                                }
                                <div className='tl-name semibold16-24'>
                                    {listItem?.name}
                                </div>
                            </div>
                            <div className={`tl-middle tl-middle-mobile d-flex align-items-center justify-content-center `}>
                                <span className={`tl-role-tag semibold14-22 d-flex align-items-center justify-content-center ${listItem?.role == "admin" ? "tl-admin-bg" : listItem?.role == "teamLeader" ? "tl-team-lead-bg" : listItem?.role == "teamMember" ? "tl-team-member-bg" : null}`}>
                                    {returnRoleLabel(listItem?.role)}
                                </span>
                                <span className={`tl-member-team regular14-22 black45`}>
                                    {listItem?.team ? listItem?.team : " "}
                                </span>
                                <span className={`tl-member-status semibold14-22 ${listItem?.status?.toLowerCase() == "active" ? "green-positive" : "black45"}`}>
                                    {listItem?.status}
                                </span>
                            </div>
                        </div>
                        <div className='d-flex justify-content-end jp-cp w-25 ' onClick={() => showMemberDetails()}>
                            <ArrowRightOutlined className='tl-right-icon primary-orange2' />
                        </div>
                    </div>
                </div>
                :
                <div className='team-role'>
                    <div className={`team-list-container d-flex justify-content-between align-items-center ${listItem.team == "No Team Assigned" ? "tl-disabled-list-item" : ""}`}>
                        {
                            listItem ?
                                <div className='tr-tl-left-middle d-flex justify-content-between'>
                                    <div className='tr-tl-left tl-left d-flex align-items-center'>
                                        <div className='tr-tl-name semibold16-24'>
                                            {listItem.team}
                                        </div>
                                    </div>
                                    <div className={`tr-tl-middle tl-middle d-flex align-items-center justify-content-between `}>
                                        <div className='d-flex align-items-center'>
                                            <span className='semibold14-22 blue-secondary2 tl-title-txt'>
                                                {listItem.team_leader ? "TL" : ""}
                                            </span>
                                            <span className={`tl-name regular14-22 black45 tl-name-txt`}>
                                                {listItem.team_leader}
                                            </span>
                                        </div>

                                        <div className='d-flex align-items-center'>
                                            <span className='tc-title-txt semibold14-22 green-positive'>
                                                TCs
                                            </span>
                                            <span className={`tc-count-txt regular14-22 black45`}>
                                                {listItem.tcCount}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                        <div className='tl-right d-flex align-items-center justify-content-end'>
                            <div className='d-flex align-items-center jp-cp' onClick={() => showTeamDetails()}>
                                <span className='bold14-22 primary-orange2'>
                                    View Details
                                </span>
                                <ArrowRightOutlined className='tl-right-icon primary-orange2' />
                            </div>
                        </div>
                    </div>
                </div>
            }


            {filterSidebar &&
                <AccountSidesheet memberDetails={listItem} isDrawerOpen={filterSidebar} closeDrawer={() => setFilterSidebar(false)} memberDetailsUpdated={updatedMemberDetails} mode={"update"} teamName={listItem.team} teamId={listItem.team_id} source={source} />
            }
        </div>

    )
}

export default TeamListItem; 