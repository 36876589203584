import React from "react";

function PlusIcon({fillColor='none',height=53,width=52,strokeColor="#1D5FFF",clicked=false}) {
  return (
    <svg
        className={`plus-icon ${clicked ? 'rotate' : ''}`}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
    height={height}
    fill={fillColor}
      viewBox="0 0 52 53"
    >
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M13 26.247h26M26 39.247v-26"
      ></path>
    </svg>
  );
}

export default PlusIcon;