import React from 'react'
import '../../style/BulkReferralStyle.scss'
import { ArrowRightOutlined, CheckCircleOutlined, UserOutlined, ExclamationCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { trackEvent } from '../../../../service/engagementMonitoringService';
import CONSTANTS from '../../../../constants/constants';

const BulkUploadSummary = ({summaryData}) => {
    const history = useHistory();

    const downloadCandidateStatusClicked = () => {
        trackEvent('bulk_upload_download_candidate_status_from_summary_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        const link = document.createElement('a');
        link.href = summaryData.candidateResponse;
        link.download = 'leadsReferredStatus.xlsx'; // Specify the desired filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const goToBulkUploads = () => {
        history.push({
            pathname: '/bulk-actions',
            route: 'bulkUploadSavedPage'
        })
    }

    return (
        <div className='br-step-parent br-summary-parent d-flex justify-content-between'>
            <div className='br-summary-content-container d-flex justify-content-center align-items-center'>
                <div className='br-summary-content d-flex'>
                    <CheckCircleOutlined className='br-summary-processed-icon' />
                    <div className='br-summary-processed-txt'>
                        Your file has been processed
                    </div>
                </div>

                <div className='br-summary-info'>
                    <div className='br-summary-total-container br-summary-section d-flex justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <UserOutlined className='br-summary-total-icon d-flex' />
                            <div className='br-summary-total-txt br-summary-txt'>
                                Total Leads
                            </div>
                        </div>

                        <div className='br-summary-processed-number br-summary-number-txt'>
                            {summaryData?.uploadedCount}
                        </div>
                    </div>

                    <div className='br-summary-processing-container br-summary-section d-flex justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <CheckCircleOutlined className='br-summary-processing-icon' style={{color: '#31C824'}}/>
                            <div className='br-summary-processing-txt-parent d-flex br-summary-txt'>
                                <div className='br-summary-processing-txt'>
                                    Uploaded Successfully
                                </div>
                            </div>
                        </div>

                        <div className='br-summary-processing-number br-summary-number-txt'>
                            {summaryData?.uploadedCount - summaryData?.errorCount}
                        </div>
                    </div>

                    <div className='br-summary-error-container br-summary-section d-flex justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <ExclamationCircleOutlined className='br-summary-error-icon d-flex' />
                            <div className='br-summary-error-txt br-summary-txt'>
                                Format Errors
                            </div>
                        </div>

                        <div className='br-summary-error-number br-summary-number-txt'>
                            {summaryData?.errorCount}
                        </div>
                    </div>
                </div>
            </div>
            

            <div className='br-summary-btn-container d-flex align-items-center'>

                <Button 
                style={{border: '1px solid #D34612'}}
                className='br-summary-goto-btn br-summary-btn-section d-flex align-items-center justify-content-center' 
                onClick={() => downloadCandidateStatusClicked()}>
                    <DownloadOutlined className='br-summary-btn-img-orange' />
                    <div className='br-summary-goto-btn-txt'>
                        Download File
                    </div>
                </Button>

                <Button className='br-summary-download-btn br-summary-btn-section d-flex align-items-center justify-content-center' onClick={() => goToBulkUploads()}>
                    <div className='br-summary-download-btn-txt'>
                        Go To Bulk Uploads
                    </div>
                    <ArrowRightOutlined className='br-summary-btn-img-white' />
                </Button>
            </div>
        </div>
    )
}

export default BulkUploadSummary;