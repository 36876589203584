import React, { useEffect, useState } from 'react'
import {ArrowLeftOutlined,CloudDownloadOutlined,SlidersOutlined, UserOutlined, CopyOutlined,PhoneOutlined} from '@ant-design/icons'
import { Avatar, Button } from 'antd';
import '../styles/SessionCard.css'
import { useHistory } from 'react-router-dom';
import moment from 'moment';

function SessionCard({item}) {
    const history = useHistory();
    const sessionTypeToImageMap = {
        "Smart Select": '/image/ai-smart-logic.png',
        "File Upload": '/image/file-upload-samvaadini.png'
    };

    const [sessionIcon, setSessionIcon] = useState('');

    useEffect(() => {
      if(item?.type && sessionTypeToImageMap[item?.type]) {
        setSessionIcon(sessionTypeToImageMap[item?.type]);
      } else {
        setSessionIcon('/image/ai-smart-logic.png');
      }
    }, [item])

    const goToSessionDetailsPage = () => {
        const url = `/samvadini-session-details?hideTabs=true&sessionDetails=${JSON.stringify(item)}`
        history.push(url);
    }
    
  return (
    <div 
    className="session-card-container"
    style={{
        background: 
        item?.type?.includes('Smart') ? '#F7FAFF':
        item?.type === 'File Upload' ? '#FFFCF6': '#FAFFFC'
    }}
    >
        <div className="session-info-section">
            <div className="session-type-info">
                <img src={sessionIcon} className='session-type-image' />
                <span className='session-type'>{item?.type?.includes('Smart') ? 'Smart Logic': item?.type}</span>
            </div>
            <div className={`session-type-execution-status ${item?.isProcessingCompleted ? "":"session-type-execution-status-incomplete"}`}>
                {item.isProcessingCompleted ? 'Complete': 'In-progress'}
            </div>
        </div>

        {/* <div className="client-grp-container">
            <img src='/image/client-grp.png' style={{width:'24px', objectFit:'contain'}} />
            {
                item?.clientList?.length > 3 ? <span className='inter-medium-8-20' style={{color: '#0E2248'}}>+{item?.clientList?.length-3} more</span>: null
            }
        </div> */}

        <div className="session-summary">
            <div className="info-item">
                <span className='session-info-item-header'>Session Date</span>
                <span className='session-info-item-sub-header'>{item?.submittedAt ? moment(item?.submittedAt).format("DD/MM/YYYY"): null}</span>
            </div>

            <div className="info-item">
                <span className='session-info-item-header'>Uploaded</span>
                <span className='session-info-item-sub-header'>{item?.leadsUploaded}</span>
            </div>

            <div className="info-item">
                <span className='session-info-item-header'>Time</span>
                <span className='session-info-item-sub-header'>{item?.submittedAt ? moment(item?.submittedAt).format("hh:mm a"): null}</span>
            </div>
        </div>

        <div className="session-summary">
            <div className="info-item">
                <span className='session-info-item-header'>Interested</span>
                <span className='session-info-item-sub-header'>{item?.interestedLeadCount}</span>
            </div>

            <div className="info-item">
                <span className='session-info-item-header'>TCs Selected</span>
                <span className='session-info-item-sub-header'>{item?.totalTCs}</span>
            </div>

            <div className="info-item">
                <span className='session-info-item-header'>FTs</span>
                <span className='session-info-item-sub-header'>{item?.totalFTs}</span>
            </div>
        </div>

        <Button className='view-session-btn' onClick={()=>goToSessionDetailsPage()}>View</Button>
    </div>
  )
}

export default SessionCard