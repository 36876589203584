import React from "react";

function StarIcon({fillColor='#FFF',height=18,width=19,strokeColor="#252526"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
    height={height}
    fill='none'
      viewBox="0 0 19 18"
    >
      <g clipPath="url(#clip0_494_3663)">
        <path
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M9.584 1.508l2.318 4.695 5.182.757-3.75 3.653.885 5.16-4.635-2.438-4.635 2.438.885-5.16-3.75-3.653 5.182-.757 2.318-4.695z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_494_3663">
          <path
            fill={fillColor}
            d="M0 0H18V18H0z"
            transform="translate(.584 .008)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default StarIcon;