import { Button, Divider, Spin, Switch } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import '../../style/AIFilteringStep.scss';
import {ArrowRightOutlined, PlayCircleFilled, PauseCircleFilled, LoadingOutlined, CaretRightOutlined, PauseOutlined} from '@ant-design/icons';
import { getAudioFile } from '../../../../service/bulkActionService';
import { getUploadedListOfFiles } from '../../../../service/SamvadiniApiService';

function AIFilteringStep({isAiFilteringEnabled, setIsAiFilteringEnabled,goToNextStep, isUploading, mitraReducer}) {

    const [audioURL, setAudioURL] = useState();
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);

    const handlePlayPause = () => {
        if (isPlaying) {
        audioRef.current.pause();
        } else {
        audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
    };

    const handleProgressChange = (event) => {
        const newProgress = event.target.value;
        audioRef.current.currentTime = (audioRef?.current?.duration * newProgress) / 100;
        setProgress(newProgress);
    };

    const getAudioUrl = () => {
        setAudioURL('https://uploads-a.vahan.co/mitra/1721381339025-MoM8pr-no-[audiotrimmer.com].mp3 ');
    }

    useEffect(() => {
        getAudioUrl();

        const audio = audioRef.current;
        const updateProgress = () => {
            setProgress((audio.currentTime / audio.duration) * 100);
            if(((audio.currentTime / audio.duration) * 100) >=100) {
                setIsPlaying(false);
            }
        };
        
        if (audio) {
            audio.addEventListener('timeupdate', updateProgress);
            return () => {
                audio.removeEventListener('timeupdate', updateProgress);
            };
        }
    }, [])
    
    
  return (
    <div className='d-flex flex-column desktop-height-100 mobile-scroll'>
        <div className='d-flex main-container-ai-filter-step'>
            <div className='img-container-ai-br' style={{flex: 2}}>
                <img src='/image/hero-image.webp' alt='hero-image' className='hero-image-ai-filtering' style={{objectFit:'contain'}}/>
                <div className='audio-container d-flex align-items-center flex-column'>
                    <audio ref={audioRef} src={audioURL} />

                    <button onClick={handlePlayPause} className='play-pause-btn'>
                        {!isPlaying ? <CaretRightOutlined style={{color: '#1890FF', fontSize: '18px'}}/>: <PauseOutlined  style={{color: '#1890FF', fontSize: '18px'}}/>}
                        
                        <span className='bold16-24 blue-secondary2'>{isPlaying ? 'Pause Audio': 'Play Audio'}</span>
                    </button>

                    <input
                        className="samvaadini-audio-progress-bar"
                        type="range"
                        min="0"
                        max="100"
                        value={progress}
                        onChange={handleProgressChange}
                    />
                </div>
            </div>
            <div style={{flex: 3}}>
                <div className='instructions-container d-flex flex-column'>
                    <span className='instruction-header'>Now you can filter interested candidates with AI!</span>

                    <span className='instruction-ai-filtering'>
                        1. Once candidates are <b>referred</b>, they would get a <b>call from our AI systems</b> for checking interest <br />
                    </span>

                    <span className='instruction-ai-filtering'>
                        2. You can see candidates marked as <b>‘Interested’ in ‘AI Hot Leads’ section</b>
                    </span>

                    {/* <div className='audio-container d-flex align-items-center flex-column mobile-audio-section' style={{marginTop: '24px', paddingBottom: '12px'}}>
                        <audio ref={audioRef} src={audioURL} />

                        <button onClick={handlePlayPause} className='play-pause-btn'>
                            {!isPlaying ? <CaretRightOutlined style={{color: '#1890FF', fontSize: '18px'}}/>: <PauseOutlined  style={{color: '#1890FF', fontSize: '18px'}}/>}
                            
                            <span className='bold14-22 blue-secondary2'>{isPlaying ? 'Pause Audio': 'Play Audio'}</span>
                        </button>

                        <input
                            className="samvaadini-audio-progress-bar"
                            type="range"
                            min="0"
                            max="100"
                            value={progress}
                            onChange={handleProgressChange}
                        />
                    </div> */}

                    <div className="client-inclusion-indicator">
                        <span className='semibold16-24 blue-secondary2'>Currently available for</span>
                        <div className="applicable-clients-container">
                            <img src='/image/client/zomato.png' style={{width:'40px'}} />
                            <img src='/image/client/swiggy.png' style={{width:'40px'}} />
                            <img src='/image/client/blinkit.png' style={{width:'40px'}} />
                            <img src='/image/client/sfx.png' style={{width:'40px'}} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='d-flex justify-content-center mobile-bottom-fixed ai-step-action-fields'>
            <Divider style={{margin: '4px 0px 16px'}}></Divider>
            <Button 
            className='ai-filtering-next-btn d-flex align-items-center justify-content-center vl-primary-btn' 
            disabled={isUploading}
            onClick={() => goToNextStep()}>
                {
                    isUploading ?
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "white", marginLeft: "8px" }} spin />} />
                        :
                        null
                }
                Next
                <ArrowRightOutlined className='br-step-btn-img-white' />
            </Button>
        </div>
    </div>
  )
}

export default AIFilteringStep