import React, { useContext, useEffect, useState } from 'react'
import '../styles/VehicleTypeSelection.css';
import { Button, Select, notification } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { getCandidateInfo, getZomatoZonesData, postAdditionalInfo, updateZomatoData } from '../../../../service/additionalInfoService';
import { AppContext } from '../../../../App';
import RiderCompletedBanner from './RiderCompletedBanner';

function ZoneSelection({goToNextScreen, lead, leadAdditionalDetails, getLeadInformation,showBannerForScreen}) {

    const [selectedZone, setSelectedZone] = useState();
    const [selectedZoneDetails, setSelectedZoneDetails] = useState();
    const [zoneInfo, setZoneInfo] = useState([]);
    const [zoneStructuredData, setZoneStructuredData] = useState([]);
    const {mitraReducer,mitraDispatch, setAlert,setSpin } = useContext(AppContext);
    const [zomatoZoneApiResponse, setZomatoZoneApiResponse] = useState({});
    const [showStepCompletedSection, setShowStepCompletedSection] = useState(false);
    const [riderHasCompletedTheStep, setRiderHasCompletedTheStep] = useState(false);


    const handleChange = (item, zoneObject) => {
        console.log(item, zoneObject);
        setSelectedZone(item);
        setSelectedZoneDetails(zoneObject);
    };

    const checkErrors = (res) => {
        const newErrors = [];
        for (let key in res.data) {
            if (res.data[key] !== "200") {
                newErrors.push(`${key}: ${res.data[key]}`)
            }
        }
      
        if(newErrors.length) {
            // setErrors([...newErrors]);
            alert(newErrors.join("\n"))
            return true;
        } 
        return false;
      }

    const submitZone = async() => {
        if(riderHasCompletedTheStep) {
            goToNextScreen();
            return
        }

        if(!selectedZone) {
            notification['error']({
                message: 'Please select a zone to continue',
              });
            return
        } else {
            try{
                const submissionData = {
                    zomatoWorkZone: selectedZoneDetails?.locality_name
                }
                const res = await postAdditionalInfo(submissionData, mitraReducer?.mitraInfo, lead);

                if(!checkErrors(res)) {
                const response = await updateZomatoData({
                    phoneNumber: lead.phoneNumber,
                    dataToUpdate: {
                        updateZone: {
                            zoneId: selectedZoneDetails?.zone_id,
                            localityId: selectedZoneDetails?.locality_id
                        }
                    }
                });

                if(response?.data?.updateZone?.status?.code === 200) {
                    getLeadInformation();
                    goToNextScreen();
                } else {
                    notification['error']({
                        message: response?.data?.updateZone?.message || 'Something went wrong, please try again in some time.',
                      });
                }
            }
            } catch (e) {
                // do something
            }
        }
    };

    const showLoader = (value) => {
        setSpin({
            loading: value, //false or true when actived
            delay: null,
            tip: 'Loading...'  //Loading copy here
        })
    }


    const getZoneData = async() => {
        showLoader(true);

        const reqData = {
            phoneNumber: lead.phoneNumber,
            city: leadAdditionalDetails.zomatoWorkCity
        };

        const response = await getZomatoZonesData(reqData);

        setZomatoZoneApiResponse(response?.data);

        if(response?.data?.allLocalities?.length) {
            const allZones = response.data.allLocalities;
            setZoneInfo([...allZones]);
        }
        showLoader(false);
    }

    useEffect(() => {
    setRiderHasCompletedTheStep(showBannerForScreen('zone'));
      if(leadAdditionalDetails.zomatoWorkCity?.length) {
        getZoneData();
      }
    }, [leadAdditionalDetails])

    const codeToPrefillZone = (zoneName) => {
        let selectedZone ;

        const newZoneInfo = [...zoneInfo];
        console.log(newZoneInfo);
        for (let zone = 0; zone < newZoneInfo.length; zone++) {
            if (newZoneInfo[zone].locality_name === zoneName) {
                selectedZone = newZoneInfo[zone];
                newZoneInfo[zone].isSelected = true;
            }
        }

        setZoneStructuredData([...newZoneInfo]);

        if(selectedZone?.locality_name) {
            console.log(zoneName);
            setSelectedZone(zoneName);
            setSelectedZoneDetails(selectedZone);

            setShowStepCompletedSection(true);
        } else {
            setSelectedZone();
            setSelectedZoneDetails();

            setShowStepCompletedSection(false);
        }
    }

    const prePopulateZoneInfo = () => {
        if(!zomatoZoneApiResponse?.selected && leadAdditionalDetails?.zomatoWorkZone?.length) {
            // if data is not present in zomato zone api response but is present in our DB then..
            codeToPrefillZone(leadAdditionalDetails?.zomatoWorkZone);
            
        } else if(zomatoZoneApiResponse?.selected?.locality_name?.length) {
            codeToPrefillZone(zomatoZoneApiResponse?.selected?.locality_name);
        } else {
            setSelectedZone();
            setSelectedZoneDetails();
            setZoneStructuredData([...zoneInfo]);

            setShowStepCompletedSection(false);
        }
    }

    useEffect(() => {
      // whenever zoneInfo is populated, check if there was any preselected zone
      prePopulateZoneInfo();
    }, [zoneInfo])
    
    

    const filterOption = (input, option) =>(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const onItemClick = (item) => {
        setRiderHasCompletedTheStep(false);

        setSelectedZone(item.locality_name);
        setSelectedZoneDetails(item);
        
        const newZoneInfo = [...zoneInfo];
        for (let zone = 0; zone < newZoneInfo.length; zone++) {
            if (newZoneInfo[zone].locality_name === item.locality_name) {
                newZoneInfo[zone].isSelected = true;
            } else {
                newZoneInfo[zone].isSelected = false;
            }
        }
        setZoneStructuredData([...newZoneInfo]);

    }
  return (
    <>
        {
            showStepCompletedSection && !riderHasCompletedTheStep ? (
                <div style={{display: 'flex', alignItems: 'center', gap: '6px', marginTop: '16px', fontWeight: 700}}>
                    <CheckCircleOutlined style={{color: '#4BB543'}}/>
                    Zone details successfully submitted to Zomato.
                </div>
            ): null
        }

        {
            riderHasCompletedTheStep ? <RiderCompletedBanner />: null
        }

        <div className='vehicle-type-container'>
            <div className='container-header'>Zone Details</div>

            <div className='city-details-container'>
                <div className='label'>Select Work Zone<sup>*</sup></div>
                {/* <Select
                    style={{ width: '100%', textAlign: 'start', marginTop: '8px' }}
                    placeholder="Select one of the zones.."
                    value={selectedZone}
                    onChange={(item,zoneObj)=>handleChange(item,zoneObj)}
                    optionLabelProp="label"
                    options={zoneInfo}
                    showSearch
                    filterOption={filterOption}
                /> */}

                {/*
                earning_potential:"₹36000"
                label: Yelahanka - Yelahanka"
                value: "Yelahanka - Yelahanka"
                zone: "Yelahanka"
                zone_id: 805
                */}

                {
                    zoneStructuredData.map((item, index) => {
                        return (
                            <div key={index} className='option-item' onClick={()=>onItemClick(item)} style={item.isSelected ? {backgroundColor: '#F7F7F7'}:{}}>

                                <div className="radio-section">
                                    <div className='option-radio'>
                                        {
                                            item.isSelected ? <div className="radio-fill"></div>: null
                                        }
                                        
                                    </div>
                                </div>

                                <div className="option-details mobile">

                                    <div className='option-header' style={{gap: '8px'}}>
                                        <div className='option-header-zone'>{item?.locality_name}</div>
                                        <div className='option-header-bonus-details'>
                                            <div>Joining Bonus: {`${item.feeData?.joiningBonus ? `₹ ${item.feeData.joiningBonus}` : 'Not Available'}`}</div>
                                            <div>Order Eligibility: {item?.feeData?.orderCountForJoiningBonus || 'Not Available'}</div>
                                        </div>
                                    </div>

                                    <div className='custom-divider'></div>

                                    <div className='option-content' style={{gap: '8px'}}>
                                        <div className='manual-fee-details'>
                                            <div>Manual Upfront Fee: {`${item?.feeData?.upfrontFee ? `₹ ${item.feeData.upfrontFee}` : 'Not Available'}`}</div>
                                            <div>Manual Full Amount Fee: {`${item?.feeData?.totalFee ? `₹ ${item.feeData.totalFee}` : 'Not Available'}`}</div>
                                        </div>
                                        <div className='manual-fee-details'>
                                            <div>Digilocker Upfront Fee: {`${item?.feeData?.aadharUpfrontFee ? `₹ ${item.feeData.aadharUpfrontFee}` : 'Not Available'}`}</div>
                                            <div>Digilocker Full Amount Fee: {`${item?.feeData?.aadharTotalFee ? `₹ ${item.feeData.aadharTotalFee}` : 'Not Available'}`}</div>
                                        </div>
                                    </div>

                                </div>

                                <div className="option-details desktop">

                                    <div className='option-header'>
                                        <div className='option-header-zone'>{item?.locality_name}</div>
                                    </div>

                                    <div className='custom-divider'></div>

                                    <div className='option-content'>
                                        <div className='manual-fee-details'>
                                            <div>Manual Upfront Fee: {`${item?.feeData?.upfrontFee ? `₹ ${item.feeData.upfrontFee}` : 'Not Available'}`}</div>
                                            <div>Manual Full Amount Fee: {`${item?.feeData?.totalFee ? `₹ ${item.feeData.totalFee}` : 'Not Available'}`}</div>
                                        </div>
                                        <div className='vertical-line'/>
                                        <div className='aadhar-fee-details' style={{alignItems: 'center'}}>
                                            <div>Digilocker Upfront Fee: {`${item?.feeData?.aadharUpfrontFee ? `₹ ${item.feeData.aadharUpfrontFee}` : 'Not Available'}`}</div>
                                            <div>Digilocker Full Amount Fee: {`${item?.feeData?.aadharTotalFee ? `₹ ${item.feeData.aadharTotalFee}` : 'Not Available'}`}</div>
                                        </div>
                                        <div className='vertical-line'/>
                                        <div className='aadhar-fee-details'>
                                            <div>Joining Bonus: {`${item.feeData?.joiningBonus ? `₹ ${item.feeData.joiningBonus}` : 'Not Available'}`}</div>
                                            <div>Order Eligibility: {item?.feeData?.orderCountForJoiningBonus || 'Not Available'}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )
                    })
                }
                
            </div>
            
        </div>

        <div className='button-container'>
        <Button className='submit-button' onClick={()=>submitZone()}>Next</Button>
        </div>
    </>
  )
}

export default ZoneSelection
