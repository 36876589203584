import * as React from "react";
const HumanCrossIcon = ({fillColor='none',height=26,width=31,strokeColor='#1D5FFF'}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fillColor}
    viewBox="0 0 31 26"
  >
    <g clipPath="url(#clip0_835_8044)">
      <path
        d="M13.8385 12.8236C17.023 12.8236 19.6047 10.3657 19.6047 7.33366C19.6047 4.30167 17.023 1.84375 13.8385 1.84375C10.6539 1.84375 8.07227 4.30167 8.07227 7.33366C8.07227 10.3657 10.6539 12.8236 13.8385 12.8236Z"
        stroke={strokeColor}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.93359 23.805C3.93359 19.5558 8.37356 16.1191 13.8399 16.1191C14.947 16.1191 16.0195 16.2619 17.0229 16.5254"
        stroke={strokeColor}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.3704 19.4134C25.3704 19.7648 25.3243 20.1051 25.2321 20.4345C25.1283 20.8737 24.9438 21.3019 24.7016 21.6753C23.9058 22.9489 22.4412 23.8053 20.7575 23.8053C19.5697 23.8053 18.4971 23.3771 17.6899 22.6744C17.3439 22.3889 17.044 22.0486 16.8134 21.6753C16.3867 21.0165 16.1445 20.2369 16.1445 19.4134C16.1445 18.2276 16.6404 17.1406 17.4477 16.3501C18.2896 15.5266 19.4659 15.0215 20.7575 15.0215C22.1183 15.0215 23.3523 15.5815 24.1826 16.4818C24.9207 17.2614 25.3704 18.2935 25.3704 19.4134Z"
        stroke={strokeColor}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.947 18.2461L19.5137 20.5628"
        stroke={strokeColor}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5391 18.2676L21.9839 20.5843"
        stroke={strokeColor}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_835_8044">
        <rect width={31} height={26} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default HumanCrossIcon;
