import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Divider, Tag, Button, Dropdown, Menu } from 'antd';
import { CheckCircleFilled, EllipsisOutlined, FileTextOutlined, ArrowRightOutlined, EnvironmentOutlined, PushpinOutlined, PlusOutlined, PictureOutlined, PushpinFilled } from '@ant-design/icons';
import './JobDemandCardStyle.scss'
import { pinClient } from '../../../../service/jobDemandsService';
import { getDisplayableClientName } from '../../../../utils/commonFunction';
import PitchModal from '../ClientPitch/PitchModal';
import { trackEvent } from '../../../../service/engagementMonitoringService';
import CONSTANTS from '../../../../constants/constants';

const JobDemandCard = ({ jobData = {
    "vertical": "",
    "jobType": "",
    "maxSalary": 0,
    "cityCount": "",
    "companyName": "",
    "companyKey": "",
    "isPinned": false,
    "openings": "",
    "localities": "",
    "companyId": "",
    "companyLogo": "",
    "requirements": {
        "education": [],
        "jobPreference": ""
    },
    "jobLocations": "",
    "jobs": "",
    "salaryText": ""
}, jobInd, onPinClicked, onUnpinClicked, onCreatePosterClicked, onViewPostersClicked, selectedCity }) => {
    const history = useHistory()
    const [showJobRequirementsListing, setShowJobRequirementsListing] = useState(true);
    const [showPitchModal, setShowPitchModal] = useState(false);

    const menu = (
        <Menu className='jdc-menu'>
            <Menu.Item key="1" className='jdc-menu-item' onClick={() => { jobData.isPinned ? onUnpinClicked() : onPinClicked(jobData.companyKey) }}>
                {
                    jobData.isPinned ?
                        <div className='d-flex jdc-unpin-option'>
                            <span className='jdc-unpin-option-icon-parent'>
                                <span className='jdc-unpin-option-cancel-icon'>|</span>
                                <PushpinFilled className='jdc-menu-item-icon' />
                            </span>
                            Unpin Client
                        </div>
                        :
                        <div className='d-flex'>
                            <PushpinOutlined className='jdc-menu-item-icon' />
                            Pin Client
                        </div>
                }
            </Menu.Item>
            <Menu.Item key="2" className='jdc-menu-item' onClick={() => createPoster()}><PlusOutlined className='jdc-menu-item-icon' /> Create Poster</Menu.Item>
            <Menu.Item key="3" className='jdc-menu-item' onClick={() => viewPosters()}><PictureOutlined className='jdc-menu-item-icon' /> View Posters</Menu.Item>
        </Menu>
    );

    const createPoster = () => {
        let clientDetails = {
            key: jobData.companyKey,
            label: jobData.companyName,
            value: true
        }
        trackEvent('clicked_create_poster', {client: jobData.companyName}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        onCreatePosterClicked(clientDetails)
    }

    const viewPosters = () => {
        let clientDetails = {
            key: jobData.companyKey,
            label: jobData.companyName,
            value: true
        }
        trackEvent('clicked_view_posters', {client: jobData.companyName}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        onViewPostersClicked(clientDetails)
    }

    const onViewPitchClicked = () => {
        setShowPitchModal(true);
        trackEvent('clicked_view_pitch', {client: jobData.companyName}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    }

    const onViewDetailsClicked = () => {
        trackEvent('clicked_view_details', {client: jobData.companyName}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        history.push({
            pathname: `/job-demands/job-requirements/${jobData.companyId}`,
            state: {jobData: jobData, city: selectedCity}
        })
    }

    return (
        <div className={`job-demand-card-parent ${jobInd % 2 == 0 ? "job-demand-card-right-margin" : ""}`}>
            <div className='jdc-header d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                    <img className='jdc-company-logo' src={jobData.companyLogo} alt="comp_logo" />

                    <div className='jdc-company-name-type d-flex'>
                        <div className='d-flex'>
                            <div className='jdc-company-name-txt'>
                                {getDisplayableClientName(jobData.companyName)}
                            </div>
                            {
                                jobData.companyName.toLocaleLowerCase() == "blinkit" ?
                                    <div className='jdc-recommended-tag'>
                                        <div className='d-flex align-items-center'>
                                            <CheckCircleFilled className='jdc-recommended-icon' />
                                            <span className='jdc-recommended-txt'>
                                                Recommended
                                            </span>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        <span className='jdc-job-type-txt'>
                            {jobData.jobType}
                        </span>
                    </div>
                </div>

                <div className='d-flex align-items-center'>
                    {
                        jobData.isPinned ?
                            <PushpinFilled className='jdc-pin-icon' />
                            :
                            null
                    }
                    <div className='jdc-menu-container'>
                        <Dropdown overlay={menu} trigger={['click']}>
                            <EllipsisOutlined style={{ fontSize: '24px', cursor: 'pointer' }} />
                        </Dropdown>
                    </div>
                </div>
            </div>

            <Divider />

            <div className='jdc-mid-section'>
                <div className='jdc-mid-section-top d-flex align-items-center'>
                    <div className='jdc-mid-section-top-txt-bold'>
                        {jobData.openings} {jobData.openings > 1 ? "jobs" : "job"}
                    </div>
                    <span className='jdc-mid-section-top-txt-light'>
                        &nbsp; in
                    </span>
                    <div className='jdc-mid-section-top-txt d-flex align-items-center'>
                        <EnvironmentOutlined className='jdc-city-localities-icon' />
                        <span className='jdc-mid-section-top-txt-bold'>
                            {(!selectedCity || selectedCity == "All Cities" || jobData.cityCount > 1) ? jobData.cityCount : ""}
                            {
                                !selectedCity ?
                                    jobData.cityCount > 1 ? " cities" : " city" : selectedCity
                            }, &nbsp;
                            {jobData.localities} {jobData.localities > 1 ? "localities" : "locality"}
                        </span>
                    </div>
                </div>

                <div className='jdc-mid-section-mid d-flex'>
                    <div className='jdc-mid-section-mid-left d-flex align-items-start'>
                        <div className='jdc-mid-section-mid-header'>
                            BASIC REQUIREMENTS
                        </div>
                        <div className='jdc-mid-section-mid-requirements'>
                            {
                                jobData.requirements.education.map((requirement) => {
                                    return (
                                        <div className='jdc-mid-section-mid-requirements-txt'>
                                            {requirement}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className='jdc-mid-section-mid-mid jdc-vertical-divider'>

                    </div>


                    {/* <Divider className='jdc-vertical-divider' /> */}

                    <div className='jdc-mid-section-mid-right'>
                        <div className='jdc-mid-section-mid-header'>
                            SALARY
                        </div>
                        <div className='jdc-mid-section-mid-salary'>
                            Upto ₹{jobData.maxSalary} per month
                        </div>
                    </div>
                </div>
            </div>

            <div className='jdc-bottom-section d-flex align-items-center justify-content-between'>
                <Button className='jdc-view-pitch-btn d-flex align-items-center ba-cpn justify-content-center' onClick={() => onViewPitchClicked()}>
                    <FileTextOutlined className='jdc-view-pitch-btn-img' />
                    <div className='jdc-view-pitch-btn-txt'>
                        View Pitch
                    </div>
                </Button>
                <Button className='jdc-view-details-btn d-flex align-items-center ba-cp justify-content-center' onClick={() => onViewDetailsClicked()}>
                    <div className='jdc-view-details-btn-txt'>
                        View Details
                    </div>
                    <ArrowRightOutlined className='jdc-view-details-btn-img' />
                </Button>
            </div>

            {
                showPitchModal ?
                    <PitchModal companyDetails={{ companyName: jobData.companyName, logo: jobData.companyLogo, companyKey: jobData.companyKey }} isModalOpen={showPitchModal} closeModal={() => setShowPitchModal(false)} /> : null
            }
        </div>
    )
}

export default JobDemandCard; 