import axios from "axios"
import axiosInstance from './interceptorService';

export const checkMitraDuplicacy = (phoneNumber) => {
    return axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/duplicateCheck?phoneNumber=${encodeURIComponent(phoneNumber)}`)
}

export const sendOTPToPhone = (phoneNumber) => {
    return axiosInstance.post(`${process.env.REACT_APP_BACKEND_URL}/sendOtp`, {phoneNumber})
}

export const mitraSignup = (otp, mitraInfo, tandCVersion) => {
    return axiosInstance.post(`${process.env.REACT_APP_BACKEND_URL}/signup`, {otp, mitraInfo, tandCVersion}, {withCredentials: true})
}
export const acceptanceOfTandC = (mitraId, tandCVersion) => {
    return axiosInstance.post(`${process.env.REACT_APP_BACKEND_URL}/acceptedTandC`, {mitraId, tandCVersion}, {withCredentials: true})
}

export const postTrainingSessionDates = (data, mitra) => {
    const requestData = {
        submission: {
            data: {
                phoneNumber: mitra.phoneNumber,
                name: mitra.name,
                sourceName: mitra.name,
                sourceId: mitra.id,
            },
            currentPageData: {

            }
        }
    }

    for(let key in data){
        if(key === "submit") continue;
        if(data[key] && key.toLowerCase().includes('training')){
            requestData.submission.currentPageData[key] = data[key]
            requestData.submission.data[key] = data[key]
        }
    }

    const url = `${process.env.REACT_APP_BACKEND_URL}/addProfileData`
    return axiosInstance.post(url, requestData, {withCredentials: true});
}

export const postOnboardingQuestions = (data, mitra) => {
    const requestData = {
        submission: {
            data: {
                phoneNumber: mitra.phoneNumber,
                name: mitra.name,
                sourceName: mitra.name,
                sourceId: mitra.id,
                onboardingQuestions: JSON.stringify(data)
            },
            currentPageData: {
                onboardingQuestions: JSON.stringify(data)
            }
        }
    }


    const url = `${process.env.REACT_APP_BACKEND_URL}/addProfileData`
    return axiosInstance.post(url, requestData, {withCredentials: true});
}

export const postNextQuestionId = (mitra) => {
    const requestData = {
        submission: {
            data: {
                isFeedBackCompleted: 'false',
                nextQuestionId:  `${process.env.REACT_APP_FIRST_ONBOARDING_QUESTION_ID}`,
                phoneNumber: mitra.phoneNumber,
                name: mitra.name,
                sourceName: mitra.name,
                sourceId: mitra.id,
            },
            currentPageData: {
                nextQuestionId:  `${process.env.REACT_APP_FIRST_ONBOARDING_QUESTION_ID}`,
                isFeedBackCompleted: 'false'
            }
        }
    }
    const url = `${process.env.REACT_APP_BACKEND_URL}/addProfileData`
    return axiosInstance.post(url, requestData, {withCredentials: true});
}
