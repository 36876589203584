import * as React from "react";
const DownArrow = ({fillColor='none',height=10,width=10,strokeColor="black"}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fillColor}
    viewBox="0 0 10 10"
  >
    <path
      d="M7.9433 3.85547L5.4983 6.30047C5.20955 6.58922 4.73705 6.58922 4.4483 6.30047L2.0033 3.85547"
      stroke={strokeColor}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DownArrow;