import axios from 'axios';
import axiosInstance from './interceptorService';

const replaceAmpersand = (str) => {
    return str.replace('&', '_and_');
}

export const getJobCategories = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/jobTypes`;
    return axiosInstance.get(url, { withCredentials: true });
};

export const getJobCities = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/allCitiesJobsPage`;
    return axiosInstance.get(url, { withCredentials: true });
};

export const getJobLocalities = async (city) => {
    city = replaceAmpersand(city);
    let url = `${process.env.REACT_APP_BACKEND_URL}/localitiesByCity?city=${city}`;
    return axiosInstance.get(url, { withCredentials: true });
};

export const getJobRequirementsListing = async (pageNo, category, city, clients, localities, cancelToken) => {
    let urlWithParams = `/jobListings`
    if (category) {
        urlWithParams.includes('?') ? urlWithParams += `&category=${encodeURIComponent(category)}` : urlWithParams += `?category=${encodeURIComponent(category)}`;
    }
    if (city) {
        city = replaceAmpersand(city);
        urlWithParams.includes('?') ? urlWithParams += `&city=${city}` : urlWithParams += `?city=${city}`;
    }
    if (localities && localities !== "[]") {
        urlWithParams.includes('?') ? urlWithParams += `&locality=${localities}` : urlWithParams += `?locality=${localities}`;
    }
    if (clients && clients !== "[]") {
        urlWithParams.includes('?') ? urlWithParams += `&client=${clients}` : urlWithParams += `?client=${clients}`;
    }
    let url = `${process.env.REACT_APP_BACKEND_URL}${urlWithParams}`;
    return axiosInstance.get(url, { withCredentials: true, cancelToken });
};

export const getJobRequirementDetails = async (pageNo, companyId, city, localities, cancelToken) => {
    let urlWithParams = `/jobListingsDetails?pageNo=${pageNo}`
    if (companyId) {
        urlWithParams += `&companyId=${companyId}`
    }
    if (city) {
        city = replaceAmpersand(city);
        urlWithParams += `&city=${city}`
    }
    if (localities && localities !== "[]") {
        urlWithParams += `&locality=${localities}`
    }
    let url = `${process.env.REACT_APP_BACKEND_URL}${urlWithParams}`;
    return axiosInstance.get(decodeURI(url), { withCredentials: true });
};

export const pinClient = async (company) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/pinCompany?company=${company}`;
    return axiosInstance.post(url, {}, { withCredentials: true });
};

export const unPinClient = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/unPinCompany`;
    return axiosInstance.post(url, {}, { withCredentials: true });
};

export const createPoster = async (data, cancelToken) => {
    let url = process.env.REACT_APP_BACKEND_URL + '/createJobPosting';
    return axiosInstance.post(url, data, { withCredentials: true, cancelToken });
};

export const getLeadsByLeads = async (pageNo, startDate, endDate, clients, cancelToken) => {
    let urlWithParams = `/getJobPostingResponses?pageNo=${pageNo}`
    if (startDate && endDate) {
        urlWithParams += `&startDate=${startDate}&endDate=${endDate}`
    }
    if (clients && clients !== "[]") {
        urlWithParams += `&client=${clients}`
    }
    let url = `${process.env.REACT_APP_BACKEND_URL}${urlWithParams}`;
    return axiosInstance.get(decodeURI(url), { withCredentials: true, cancelToken });
};

export const getLeadsByPoster = async (pageNo, startDate, endDate, clients, cancelToken) => {
    let urlWithParams = `/leadSummaryGroupedByPoster?pageNo=${pageNo}`
    if (startDate && endDate) {
        urlWithParams += `&startDate=${startDate}&endDate=${endDate}`
    }
    if (clients && clients !== "[]") {
        urlWithParams += `&client=${clients}`
    }
    let url = `${process.env.REACT_APP_BACKEND_URL}${urlWithParams}`;
    return axiosInstance.get(decodeURI(url), { withCredentials: true, cancelToken });
};

export const getPitchByClient = async (companyKey) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/pitchDetails?companyKey=${companyKey}`;
    return axiosInstance.get(decodeURI(url), { withCredentials: true });
};

export const getJobDemandsData = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getJobDemandsData`;
    return axiosInstance.get(url, { withCredentials: true });
};

export const getJobDemandsOnFiltersChange = async (minSalary, maxSalary, city, client, vertical) => {
    const data = {
        minSalary: minSalary,
        maxSalary: maxSalary,
        client: JSON.stringify(client),
        city: JSON.stringify(city),
        category: JSON.stringify(vertical)
    }
    let url = process.env.REACT_APP_BACKEND_URL + '/getJobDemandsOnFiltersChange';
    return axiosInstance.get(url, { params: data, withCredentials: true });
};

// export const getJobDemandsOnCityChange = async (city) => {
//     const data = new FormData();
//     data.append('city', city);
//     let url = process.env.REACT_APP_BACKEND_URL + '/getJobDemandsOnCityChange';
//     return axiosInstance.post(url, data, { withCredentials: true });
// };

// export const getJobDemandsOnCategoryChange = async (category) => {
//     const data = new FormData();
//     data.append('category', category);
//     let url = process.env.REACT_APP_BACKEND_URL + '/getJobDemandsOnCategoryChange';
//     return axiosInstance.post(url, data, { withCredentials: true });
// };

export const getJobPostingByClientName = async (client) => {
    const data = { client: client }
    let url = `${process.env.REACT_APP_BACKEND_URL}/getJobPostingByClientName`;
    return axiosInstance.get(url, { params: data, withCredentials: true });
};

export const getCreateJobPostingDropdown = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getCreateJobPostingDropdown`;
    return axiosInstance.get(url, { withCredentials: true });
};

export const createJobPosting = async (data) => {
    let url = process.env.REACT_APP_BACKEND_URL + '/createJobPosting';
    return axiosInstance.post(url, data, { withCredentials: true });
};
export const createJobPostingResponse = async (jobPostingId, name, phoneNumber) => {
    const data = new FormData();
    data.append('jobPostingId', jobPostingId);
    data.append('name', name);
    data.append('phoneNumber', phoneNumber);
    let url = process.env.REACT_APP_BACKEND_URL + '/createJobPostingResponse';
    return axiosInstance.post(url, data, { withCredentials: true });
};

export const getJobPostingResponses = async (dateSelected) => {
    const data = {
        startDate: dateSelected + ' 00:00:01',
        endDate: dateSelected + ' 23:59:59'
    }
    let url = `${process.env.REACT_APP_BACKEND_URL}/getJobPostingResponses`;
    return axiosInstance.get(url, { params: data, withCredentials: true });
};
export const getJobPostingResponseById = async (jobPostingId) => {
    const data = {
        jobPostingId: jobPostingId
    }
    let url = `${process.env.REACT_APP_BACKEND_URL}/getJobPostingResponseById`;
    return axiosInstance.get(url, { params: data, withCredentials: true });
};
export const getJobPostingResponsesOnFilterChange = async (startDate, endDate, city, client) => {
    const data = {
        startDate: startDate,
        endDate: endDate,
        city: JSON.stringify(city),
        client: JSON.stringify(client)
    }
    let url = process.env.REACT_APP_BACKEND_URL + '/getJobPostingResponsesOnFilterChange';
    return axiosInstance.get(url, { params: data, withCredentials: true });
};

export const exportJobPostingResponseSheetByFilters = async (startDate, endDate, city, client) => {
    const data = {
        startDate: startDate,
        endDate: endDate,
        city: JSON.stringify(city),
        client: JSON.stringify(client)
    }
    let url = process.env.REACT_APP_BACKEND_URL + '/exportJobPostingResponseSheetByFilters';
    return axiosInstance.get(url, { params: data, withCredentials: true });
};

export const exportJobPostingResponseSheetById = async (jobPostingResponseById) => {
    const data = {
        jobPostingResponseById
    }
    let url = process.env.REACT_APP_BACKEND_URL + '/exportJobPostingResponseSheetById';
    return axiosInstance.get(url, { params: data, withCredentials: true });
};


export const getJobPosterById = async (jobPostingId) => {
    const data = {
        jobPostingId
    }
    console.log('data :>> ', data);
    let url = process.env.REACT_APP_BACKEND_URL + '/getJobPostingImage';
    return axiosInstance.get(url, { params: data, withCredentials: true });
};