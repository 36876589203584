import * as React from "react";
const PauseIcon = (props) => (
  <svg
    width={17}
    height={17}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.2501 4.22721V12.8939C10.2501 13.2037 10.2501 13.3585 10.2757 13.4873C10.3809 14.0163 10.7941 14.4297 11.323 14.5349C11.4518 14.5605 11.6067 14.5605 11.9165 14.5605C12.2262 14.5605 12.3814 14.5605 12.5102 14.5349C13.0391 14.4297 13.4528 14.0163 13.558 13.4873C13.5834 13.3597 13.5834 13.2064 13.5834 12.902V12.8939V4.22721V4.21906C13.5834 3.91472 13.5834 3.76145 13.558 3.63379C13.4528 3.10487 13.0391 2.69138 12.5102 2.58617C12.3814 2.56055 12.2265 2.56055 11.9167 2.56055C11.607 2.56055 11.4518 2.56055 11.323 2.58617C10.7941 2.69138 10.3809 3.10487 10.2757 3.63379C10.2501 3.76259 10.2501 3.91744 10.2501 4.22721Z"
      stroke="#4BDA58"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.91675 4.22721V12.8939C2.91675 13.2037 2.91675 13.3585 2.94237 13.4873C3.04758 14.0163 3.46074 14.4297 3.98966 14.5349C4.11846 14.5605 4.27335 14.5605 4.58312 14.5605C4.89288 14.5605 5.04807 14.5605 5.17687 14.5349C5.70579 14.4297 6.11948 14.0163 6.22469 13.4873C6.25008 13.3597 6.25008 13.2064 6.25008 12.902V12.8939V4.22721V4.21906C6.25008 3.91472 6.25008 3.76145 6.22469 3.63379C6.11948 3.10487 5.70579 2.69138 5.17687 2.58617C5.04807 2.56055 4.89318 2.56055 4.58341 2.56055C4.27364 2.56055 4.11846 2.56055 3.98966 2.58617C3.46074 2.69138 3.04758 3.10487 2.94237 3.63379C2.91675 3.76259 2.91675 3.91744 2.91675 4.22721Z"
      stroke="#4BDA58"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default PauseIcon;
