import React, { useState, useEffect, useReducer, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { Tag, Button, DatePicker, Pagination, Skeleton, Empty, message, Select } from 'antd';
import { CloseOutlined, FilterOutlined } from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios';
import '../style/JobsPageContainerStyle.scss'
import LeadViewCard from './LeadsFromPostersSection/LeadViewCard';
import PosterViewCard from './LeadsFromPostersSection/PosterViewCard';
import { getLeadsByPoster, getLeadsByLeads } from '../../../service/jobDemandsService';
import SideSheet from '../../common/SideSheet'
import { getActiveClientsForFilter } from '../../../service/commonService';
import { getDisplayableClientName } from '../../../utils/commonFunction';
import FilterSideSheetComponent from './common/FilterSideSheet';
import CONSTANTS from '../../../constants/constants';
import { AppContext } from '../../../App';

const { Option } = Select;

const LeadsPageContainer = ({ viewByLeads, viewByPosters, openFilter, clientFilterListFromParent, viewPosterClicked }) => {
    const history = useHistory()
    const location = useLocation()
    const { RangePicker } = DatePicker;
    const dateFormat = 'DD-MMMM YYYY';

    const [lfpViewByLeadsList, setLFPViewByLeadsList] = useState([
        {
            companyLogo: "",
            candidateName: "",
            jobLocation: "",
            registeredOn: "",
            candidatePhone: 0,
            candidateId: ""
        }
    ])

    const [lfpViewByPostersList, setLFPViewByPostersList] = useState([
        {
            "totalLeads": "",
            "city": "",
            "companyName": "",
            "companyId": "",
            "logo": "",
            "jobPostingId": "",
            "postingCreatedAt": ""
        },
    ])

    const [filterSidebar, setFilterSidebar] = useState(false);
    const [lfpStartDate, setLFPStartDate] = useState(null);
    const [lfpEndDate, setLFPEndDate] = useState(null);
    const [totalLFPCount, setTotalLFPCount] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [clientFilterList, setClientFilterList] = useState([])
    const [cancelToken, setCancelToken] = useState(null); // State to store the cancel token
    const [loadingListing, setLoadingListing] = useState(false);
    var [viewPostersForClientFromDetailsPage, setViewPostersForClientFromDetailsPage] = useState({
        key: "",
        label: "",
        value: false
    });

    const [filtersList, setFiltersList] = useState({
        startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        city: "",
        clients: [],
    })
    const [showLeadsByLeads, setShowLeadsByLeads] = useState(false);
    const [showLeadsByPosters, setShowLeadsByPosters] = useState(false);
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);
    const { mitraReducer } = useContext(AppContext);
    const [disableDatesBefore, setDisableDatesBefore] = useState(moment());


    useEffect(() => {
        if (mitraReducer && mitraReducer.allowedFeatures) {
            const viewAccessRestricted = mitraReducer?.allowedFeatures[CONSTANTS.VIEW_DATA_ACCESS]?.isEnable
            if (viewAccessRestricted) {
                let temp_var = moment().subtract(mitraReducer?.allowedFeatures[CONSTANTS.VIEW_DATA_ACCESS]?.numberOfDays, 'days')
                console.log("DD", temp_var.format("YYYY-MM-DD"));
                setDisableDatesBefore(moment().subtract(mitraReducer?.allowedFeatures[CONSTANTS.VIEW_DATA_ACCESS]?.numberOfDays, 'days'))
            }
        }
    }, [mitraReducer])

    useEffect(() => {
        setFilterSidebar(openFilter)
    }, [openFilter])

    useEffect(() => {
        if (viewPosterClicked && viewPosterClicked.key) {
            setViewPostersForClientFromDetailsPage(viewPosterClicked)
        }
    }, [])

    useEffect(() => {
        if (viewByLeads) {
            setShowLeadsByLeads(true)
            setShowLeadsByPosters(false)
            fetchLeadsByLeads()
        }
        if (viewByPosters) {
            setShowLeadsByPosters(true)
            setShowLeadsByLeads(false)
            fetchLeadsByPosters()
        }
    }, [viewByLeads, viewByPosters])

    useEffect(() => {
        if (clientFilterListFromParent && clientFilterListFromParent.length > 0) {
            setClientFilterList(clientFilterListFromParent)
        }
        else {
            fetchClientsFilter()
        }
    }, [clientFilterListFromParent])

    useEffect(() => {
        if (clientFilterList.length > 0) {
            if (viewPostersForClientFromDetailsPage && viewPostersForClientFromDetailsPage.key) {
                viewPostersForClient(viewPostersForClientFromDetailsPage)
            }
        }
    }, [clientFilterList])

    const fetchDisabledDates = (current) => {
        //Disable all dates before allowed number of days mentioned in View Data Access 
        return current && current < disableDatesBefore.endOf("day");
    }

    const fetchClientsFilter = () => {
        getActiveClientsForFilter().then(data => {
            if (data && data.data) {
                setClientFilterList(data.data.activeClients)
            }
            else {
                setClientFilterList([])
            }
        })
            .catch((err) => {
                setClientFilterList([])
            })
    }

    const viewPostersForClient = (clientDetails) => {
        for (let i = 0; i < clientFilterList.length; i++) {
            clientFilterList[i].value = false
            if (clientFilterList[i].key == clientDetails.key) {
                onClickTags(clientFilterList[i], "", i)
            }
        }

        if (viewByPosters) {
            setShowLeadsByPosters(true)
            setShowLeadsByLeads(false)
        }
        else {
            setShowLeadsByPosters(false)
            setShowLeadsByLeads(true)
        }
    }

    const changeDateRange = (dateRange) => {
        if (dateRange[0] && dateRange[1]) {
            let tempFiltersList = filtersList
            tempFiltersList.startDate = dateRange[0].format("YYYY-MM-DD")
            tempFiltersList.endDate = dateRange[1].format("YYYY-MM-DD")
            setFiltersList(JSON.parse(JSON.stringify(tempFiltersList)))
            if (viewByPosters) {
                fetchLeadsByPosters()
            }
            else {
                fetchLeadsByLeads()
            }
        }
    }

    const fetchLeadsByLeads = () => {
        setLoadingListing(true)
        if (cancelToken) {
            cancelToken.cancel(); // Used to cancel previous request to prevent data discrepancy
        }
        const newCancelToken = axios.CancelToken.source(); // Create a new CancelToken
        setCancelToken(newCancelToken); // Set the new cancel token
        try {
            // Make API Call with all filters, only releveant ones get added to the URL
            getLeadsByLeads(currentPage, filtersList.startDate, filtersList.endDate, JSON.stringify(filtersList.clients), newCancelToken.token).then((leadsForLeadsRes) => {
                if (leadsForLeadsRes && leadsForLeadsRes.data && leadsForLeadsRes.data.viewData && leadsForLeadsRes.data.viewData.jobPostingResponse) {
                    setTotalLFPCount(leadsForLeadsRes.data.viewData.jobPostingResponse.length)
                    setLoadingListing(false)
                    if (leadsForLeadsRes.data.viewData.jobPostingResponse.length > 0) {
                        setLFPViewByLeadsList(leadsForLeadsRes.data.viewData.jobPostingResponse)
                    }
                    else if (leadsForLeadsRes.data.viewData.jobPostingResponse.length == 0) {
                        setLFPViewByLeadsList([])
                    }
                }
                else {
                    setLoadingListing(false)
                    setLFPViewByLeadsList([])
                    message.error("Something went wrong, please try again later", 4)
                }

            })
        }
        catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled');
                setLoadingListing(false)
            } else {
                console.log('Request err', error);
                setLoadingListing(false)
            }
        }
    }

    const fetchLeadsByPosters = () => {
        // Set loader
        setLoadingListing(true)
        if (cancelToken) {
            cancelToken.cancel(); // Used to cancel previous request to prevent data discrepancy
        }
        const newCancelToken = axios.CancelToken.source(); // Create a new CancelToken
        setCancelToken(newCancelToken); // Set the new cancel token

        try {
            // Make API Call with all filters, only releveant ones get added to the URL
            getLeadsByPoster(currentPage ? currentPage : 1, filtersList.startDate, filtersList.endDate, JSON.stringify(filtersList.clients), newCancelToken.token).then((leadsByPosterRes) => {
                if (leadsByPosterRes && leadsByPosterRes.data && leadsByPosterRes.data.groupedJobs) {
                    setLoadingListing(false)
                    setLFPViewByPostersList(leadsByPosterRes.data.groupedJobs)
                    setTotalLFPCount(leadsByPosterRes.data.groupedJobs.length)
                    // setTotalBulkReferralsCount(listRes.data.totalCount)
                }
                else {
                    setLoadingListing(false)
                    setLFPViewByPostersList([])
                    message.error("Something went wrong, please try again later", 4)
                }
            })
        }
        catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled');
            } else {
                console.log('Request err', error);
                setLoadingListing(false)
            }
        }
    }

    const applyFilterCondition = () => {
        let result = false;

        if (filtersList.clients.length > 0) {
            result = true
        }
        return result;
    }

    const returnFilterLabel = (label, arrObj) => {
        return <div className='tag-containers'>
            <div>{label.toUpperCase()}</div>
            <div className='tag-wrapper-container'>
                {arrObj.map((item, index) => {
                    return <Tag className={item && item.value ? 'tags tag-active' : 'tags'}
                        key={index}
                        onClick={() => { onClickTags(item, label, index) }}
                    >
                        {getDisplayableClientName(item.label)}
                    </Tag>
                })}
            </div>
        </div>
    }

    const onClickTags = (item, label, index) => {
        let allFilterListCopy = filtersList
        setCurrentPage(1)
        clientFilterList[index].value = !clientFilterList[index].value;
        allFilterListCopy.clients = []

        for (let i = 0; i < clientFilterList.length; i++) {
            if (clientFilterList[i].value) {
                allFilterListCopy.clients.push(clientFilterList[i].key)
            }
        }
        setFiltersList(JSON.parse(JSON.stringify(allFilterListCopy)))
        if (viewByPosters) {
            fetchLeadsByPosters()
        }
        else {
            fetchLeadsByLeads()
        }
        // if (showJobRequirementsListing)
        //     fetchJobRequirementsListing()
        forceUpdate()
    }

    const removeSingleClientFromFilter = (client, clientInd) => {
        let allFilterListCopy = filtersList

        clientFilterList[clientInd].value = !clientFilterList[clientInd].value;

        allFilterListCopy.clients = []
        for (let i = 0; i < clientFilterList.length; i++) {
            if (clientFilterList[i].value) {
                allFilterListCopy.clients.push(clientFilterList[i].key)
            }
        }

        setFiltersList(JSON.parse(JSON.stringify(allFilterListCopy)))
        if (showLeadsByLeads)
            fetchLeadsByLeads()
        else
            fetchLeadsByPosters()

    }

    const clearFilters = (whichFilter) => {
        let existingClientFilterList = clientFilterList
        let existingFilters = filtersList
        existingFilters.clients = []

        for (let i = 0; i < existingClientFilterList.length; i++) {
            existingClientFilterList[i].value = false
        }
        setViewPostersForClientFromDetailsPage(JSON.parse(JSON.stringify({
            key: "",
            label: "",
            value: false
        })))
        setClientFilterList(JSON.parse(JSON.stringify(existingClientFilterList)))
        setFiltersList(JSON.parse(JSON.stringify(existingFilters)));
        setFilterSidebar(false)
        forceUpdate()
        if (showLeadsByLeads)
            fetchLeadsByLeads()
        else
            fetchLeadsByPosters()
    }

    return (
        <div className='mainContentZ'>
            <div className='jp-filters-section jp-leads-from-posters-listing'>
                <div className='jp-filters-top-section d-flex align-items-center justify-content-between'>
                    <div className='jp-filters-leads-registered-during'>
                        <span className='jp-filters-top-section-txt-1'>
                            Leads registered during
                        </span>

                        <div className='jp-filters-top-section-date-range'>
                            <RangePicker
                                format={dateFormat}
                                onChange={changeDateRange}
                                ranges={{
                                    Today: [moment().startOf('day'), moment().endOf('day')],
                                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                                }}
                                numberOfMonths={1}
                                value={[moment(filtersList.startDate), moment(filtersList.endDate)]}
                                allowClear={false}
                                disabledDate={fetchDisabledDates}
                            />
                        </div>
                    </div>

                    {
                        totalLFPCount && totalLFPCount > 0 ?
                            <div className='show-only-desktop-disp-block'>
                                <Pagination
                                    total={totalLFPCount}
                                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                    defaultPageSize={10}
                                    defaultCurrent={currentPage ? currentPage : 1}
                                    responsive={true}
                                    showLessItems={true}
                                    onChange={(page, pagesize) => { setCurrentPage(page) }}
                                    showSizeChanger={false}
                                />
                            </div>
                            :
                            null
                    }


                </div>

                <div className='jp-filters-bottom-section d-flex align-items-center show-only-desktop'>
                    <Button style={applyFilterCondition() ? { color: '#D34612', backgroundColor: '#FFEAE0', border: '1px solid #D34612' } : null} className='jp-apply-filter-btn d-flex align-items-center' onClick={() => { setFilterSidebar(true) }} icon={<FilterOutlined />}>Filter</Button>
                    {
                        applyFilterCondition() ?
                            clientFilterList && clientFilterList.length > 0 ?
                                clientFilterList.map((client, clientInd) => {
                                    return (
                                        client.value ?
                                            <div className='d-flex jp-single-filter-item justify-content-center align-items-center' key={clientInd}>
                                                <div className='single-filter-txt'>
                                                    {getDisplayableClientName(client.label)}
                                                </div>
                                                <CloseOutlined style={{ color: "rgba(0, 0, 0, 0.45)", marginLeft: "8px" }} onClick={() => removeSingleClientFromFilter(client, clientInd)} />
                                            </div>
                                            : null

                                    )
                                })
                                : null
                            : null
                    }
                </div>
            </div>

            <div className='jp-leads-by-posters-listing-section'>
                <div className='jp-leads-by-posters-count d-flex justify-content-between'>
                    {
                        showLeadsByLeads ?
                            lfpViewByLeadsList && lfpViewByLeadsList.length > 0 ? `${lfpViewByLeadsList.length} leads` : ""
                            :
                            lfpViewByPostersList && lfpViewByPostersList.length > 0 ? `${lfpViewByPostersList.length} posters` : ""
                    }
                    <Button style={applyFilterCondition() ? { color: '#D34612', backgroundColor: '#FFEAE0', border: '1px solid #D34612' } : null} className='jp-apply-filter-btn show-only-mobile d-flex align-items-center' onClick={() => { setFilterSidebar(true) }} icon={<FilterOutlined />}>Filter</Button>
                </div>

                <div className='d-flex jp-filter-chips-mobile show-only-mobile-disp-flex'>
                    {
                        applyFilterCondition() ?
                            clientFilterList && clientFilterList.length > 0 ?
                                clientFilterList.map((client, clientInd) => {
                                    return (
                                        client.value ?
                                            <div className='d-flex jp-single-filter-item justify-content-center align-items-center' key={clientInd}>
                                                <div className='single-filter-txt'>
                                                    {getDisplayableClientName(client.label)}
                                                </div>
                                                <CloseOutlined style={{ color: "rgba(0, 0, 0, 0.45)", marginLeft: "8px" }} onClick={() => removeSingleClientFromFilter(client, clientInd)} />
                                            </div>
                                            : null

                                    )
                                })
                                : null
                            : null
                    }
                </div>

                <div className={`jp-leads-by-posters-cards ${viewByLeads ? "jp-lvc-parent" : "jp-pvc-parent"}`}>
                    {
                        loadingListing ?
                            <Skeleton />
                            :
                            showLeadsByLeads ?
                                lfpViewByLeadsList && lfpViewByLeadsList.length > 0 ?
                                    lfpViewByLeadsList.map((leadFromPoster, leadFromPosterInd) => {
                                        return (
                                            <LeadViewCard leadData={leadFromPoster} leadInd={leadFromPosterInd} showCompanyLogo={true} />
                                        )
                                    })
                                    :
                                    <Empty className='w-100' />
                                :
                                lfpViewByPostersList && lfpViewByPostersList.length > 0 ?
                                    lfpViewByPostersList.map((posterLead, posterLeadInd) => {
                                        return (
                                            <PosterViewCard posterLeadData={posterLead} posterLeadInd={posterLeadInd} />
                                        )
                                    })
                                    :
                                    <Empty className='w-100' />
                    }
                </div>
            </div>

            {filterSidebar && clientFilterList &&
                <FilterSideSheetComponent submit={{
                    disabled: false
                    , onClick: () => setFilterSidebar(false)
                }}
                    clearAll={{ disabled: false, onClick: () => clearFilters("all") }}
                    sidername={`Filter ${viewByLeads ? "Leads" : "Posters"}`}
                    onDismiss={() => { setFilterSidebar(false) }}>
                    <div className='filter-home-wrapper'>
                        {returnFilterLabel("Clients", clientFilterList)}
                    </div>
                </FilterSideSheetComponent>
            }
        </div>
    )
}

export default LeadsPageContainer; 