import axios from 'axios';

function getTrueKeys(selectBoxData) {
    const trueKeys = [];
    Object.entries(selectBoxData).forEach((keyValue) => {
        if(keyValue[1]) {
            trueKeys.push(keyValue[0]);
        }});
    return trueKeys;
}

export const getJobCategories = async () => {
    const url = process.env.REACT_APP_MITRA_API_URL + '/jobs/jobCategories/types';
    const result = await axios.get(url);
    return result.data;
}

export const validateJob = async (job) => {
    const data = {
        role: job.role,
        jobCategory: job.jobCategory,
        city: job.city,
        companyName: job.companyName
    };

    const url = process.env.REACT_APP_MITRA_API_URL + '/jobs/validate';
    const result = await axios.post(url, data, {headers: {
        'x-api-key': process.env.REACT_APP_JOBS_API_KEY
    }});
    return result.data;
}

export const addJob = async (formData) => {
    const job = formData.data;

    const data = {
        jobs: [{
            jobCategory: job.jobCategory,
            role: job.role,
            companyName: job.companyName,
            city: job.city,
            locality: job.locality,
            contactName: job.contactName,
            contactPhoneNumber: job.contactPhoneNumber,
            contactEmailId: job.contactEmailId,
            baseSalary: job.baseSalary,
            maxEarnings: job.maxEarnings,
            joiningBonus: job.joiningBonus,
            referralBonus: job.referralBonus,
            isFullTimeAvailable: job.survey.isFullTimeAvailable,
            isPartTimeAvailable: job.survey.isPartTimeAvailable,
            isWfhAvailable: job.survey.isWfhAvailable,
            description: job.description,
            earnings: job.earnings,
            benefits: job.benefits,
            requirements: {
                documents: getTrueKeys(job.documents),
                languages: getTrueKeys(job.languages),
                educations: getTrueKeys(job.educations),
                skills: getTrueKeys(job.skills),
                ownerships: getTrueKeys(job.ownerships),
                gender: getTrueKeys(job.gender),
                age_limit: job.age_limit,
                experience: job.experience,
            },
            timings: job.timings,
            source: 'mitra-leader',
            createdByMitra: job.mitraPhoneNumber,
            assignedToMitra: job.mitraPhoneNumber,
        }]
    }
    const url = process.env.REACT_APP_MITRA_API_URL + '/jobs/insert';
    const result = await axios.post(url, data, {headers: {
        'x-api-key': process.env.REACT_APP_JOBS_API_KEY
    }});
    return result.data;
}