import React, { useEffect, useState } from 'react'
import {Button, Input, notification} from 'antd';
import { postAdditionalInfo } from '../../../../service/additionalInfoService';

function ZeptoFormPart2({lead, mitraReducer,  leadAdditionalDetails, goToNextScreen}) {
  const [bankAccountNumber, setBankAccountNumber] = useState(null);
  const [ifsc, setIfsc] = useState(null);
  const [errorField, setErrorField] = useState(null);

  const handleAccountNumberChange = (event) => {
    setErrorField(null);
    const inputValue = event.target.value;
    // Check if the input value is numeric using a simple regex
    if (/^\d*$/.test(inputValue)) {
      setBankAccountNumber(inputValue);
    }
  }

  const handleIfscChange = (e) => {
    setErrorField(null);

    let inputValue = e.target.value.toUpperCase();  // Convert to uppercase
    // Construct the input based on the current value length
    if (inputValue.length <= 4) {
        // First four characters, must be alphabets
        inputValue = inputValue.replace(/[^A-Z]/g, '');
    } else if (inputValue.length === 5) {
        // Fifth character, must be '0'
        inputValue = inputValue.slice(0, 4) + inputValue.charAt(4).replace(/[^0]/g, '');
    } else if (inputValue.length > 5 && inputValue.length <= 11) {
        // Characters after '0' up to six characters, must be alphabets
        inputValue = inputValue.slice(0, 5) + inputValue.slice(5).replace(/[^A-Z0-9]/g, '');
    }

    setIfsc(inputValue);
  };

  const checkIfAllInputFieldsAreValidated = () => {
    if(!bankAccountNumber || !ifsc) {
      if(!bankAccountNumber) {
        setErrorField('accNum');
      } else {
        setErrorField('ifsc');
      }
      notification['error'] ({
        message: 'Please fill all the details to continue.'
      })

      return false;
    }

    if(!(/^[A-Z]{4}0[A-Z0-9]{6}$/).test(ifsc)) {

      notification['error'] ({
        message: 'Please enter complete IFSC code.'
      });

      setErrorField('ifsc');

      return false;
    } 

    return true;
  }

  const checkErrors = (res) => {
    const newErrors = [];
    for (let key in res.data) {
        if (res.data[key] !== "200") {
            newErrors.push(`${key}: ${res.data[key]}`)
        }
    }

    if(newErrors.length) {
        // setErrors([...newErrors]);
        alert(newErrors.join("\n"))
        return true;
    } 
    return false;
  }

  const onSubmit = async() => {
    if(checkIfAllInputFieldsAreValidated()) {
      // some code

      const submissionData = {
        bankAccountNumber: bankAccountNumber,
        bankIfscCode: ifsc
      }

      const response = await postAdditionalInfo(submissionData, mitraReducer?.mitraInfo, lead);

      if(!checkErrors(response)) {
          notification['success']({
              message: 'Details submitted successfully'
          });

          goToNextScreen();
      }
    }
  }

  const autoFillInformation = () => {
    if (leadAdditionalDetails?.bankAccountNumber) {
      setBankAccountNumber(leadAdditionalDetails?.bankAccountNumber);
    }

    if(leadAdditionalDetails?.bankIfscCode) {
      setIfsc(leadAdditionalDetails?.bankIfscCode);
    }
  }

  useEffect(() => {
    autoFillInformation();
  }, [leadAdditionalDetails])
  

  return (
    <>
    <div className='vehicle-type-container'>
            <div className='container-header'>Bank Details</div>

            <div style={{margin: '16px'}}>
              <div className='label mt-24'>Bank Account Number<sup>*</sup></div>
              <Input
                  className={errorField==='accNum' ? 'input input-error':'input'}
                  placeholder={`Enter your Bank Account Number`}
                  value={bankAccountNumber}
                  onChange={handleAccountNumberChange}
              />

              <div className='label mt-24'>IFSC Code<sup>*</sup></div>
              <Input
                  className={errorField==='ifsc' ? 'input input-error':'input'}
                  maxLength={11}
                  placeholder={`Enter your IFSC Code`}
                  value={ifsc}
                  onChange={handleIfscChange}
              />
              <div className="d-flex" style={{color: errorField === 'ifsc'? '#D34B18':'#0000005c', marginTop: '2px', textAlign: 'start'}}>First 4 alphabets & fifth character is 0 (zero) & last 6 characters</div>
            </div>
        </div>

        <div className='button-container'>
            <Button className='submit-button' onClick={onSubmit}>Next</Button>
        </div>
    </>
  )
}

export default ZeptoFormPart2