import React, {useState, useEffect , useContext} from 'react'
import {useHistory} from 'react-router-dom';
import FilterPopup from '../leads/Filter-popup.component';
import {getBulkUniquenessList, getScheduledIVRList, displayBulkUniqueStatus} from '../../service/bulkActionService'
import Excel from 'exceljs';
import {saveAs} from 'file-saver';
import Moment from 'react-moment';
import useGAEventsTracker from '../../service/useGSEventsTracker';
import {AppContext} from '../../App';
import { useParams } from "react-router-dom";
// import useMoengage from '../../hooks/useMoengage';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';
import '../../css/bulkAction.scss'
import '../../css/lead.scss'
import { DownOutlined, SearchOutlined, DownloadOutlined, CloseOutlined, ArrowLeftOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { DatePicker, Button } from 'antd';
import {getActiveClientsForFilter, disabledDate} from './../../service/commonService'
import moment from 'moment';




const LeadManagement = () => {
    const {mitraReducer,mitraDispatch} = useContext(AppContext);
    const { tab } = useParams();
    const [isAdmin, setIsAdmin] = useState(mitraReducer?.mitraInfo?.role === 'admin' ? true : false);
    const [mitra, setMitra] = useState({})
    const [selection, setSelection] = useState(tab)
    const [dateSelected, setDateSelected] = useState(mitraReducer?.paramPass?.date ? mitraReducer?.paramPass.date : moment())
    const [openSearch, setOpenSearch] = useState(false)
    const [prevLeads, setPrevLeads] = useState([]);
    const [showCategoryFilterPopup, setCategoryShowFilterPopup] = useState(false);
    const [showStatusFilterPopup, setStatusShowFilterPopup] = useState(false);
    const [showIVRStatusFilterPopup, setIVRStatusShowFilterPopup] = useState(false);
    const [showResponseFilterPopup, setResponseShowFilterPopup] = useState(false);
    const [ivrLeads, setIVRLeads] = useState([])
    const [showFilterChpis, setShowFilterChips] = useState()
    const [showDateChip, setShowDateChip] = useState(false)
	const [showBanner, setShowBanner] = useState(true);
    const DEFAULT = 'DEFAULT';
    const [clientSelected, setClientSelcted] = useState(mitraReducer?.paramPass?.client ? mitraReducer?.paramPass.client : [])
    const [bulkStatusesSelected, setBulkStatusesSelected] = useState(mitraReducer?.paramPass?.status ? mitraReducer?.paramPass.status : [
        {label: 'Pending', value: false, backendValue: 'pendingStatus'},
        {label: 'Unique', value: false, backendValue: 'uniqueStatus'},
        {label: 'Not Unique', value: false, backendValue: 'nonUniqueStatus'},
        {label: 'Application Created', value: false, backendValue: 'completeApplication'},
        {label: 'Incomplete Application', value: false, backendValue: 'incompleteApplication'}
    ])
    const [bulkIVRStatusesSelected, setBulkIVRStatusesSelected] = useState(mitraReducer?.paramPass?.bulkIVRStatus ? mitraReducer?.paramPass.bulkIVRStatus : [
        {label: 'Call Connected', value: false, backendValue: 'Completed'},
        {label: 'Call Disconnected(Hangup)', value: false, backendValue: 'Hangup'},
        {label: 'IVR Scheduled(Queued)', value: false, backendValue: 'Initiated'},
        {label: 'IVR Scheduled', value: false, backendValue: 'Created'},
        {label: 'Call Disconnected(DND)', value: false, backendValue: 'DND User'},
        {label: 'Call Disconnected(Failed)', value: false, backendValue: 'Failed'},
		{label: 'No Answer', value: false, backendValue: 'No Answer'},
		{label: 'Rejected', value: false, backendValue: 'Rejected'},
		{label: 'Other', value: false, backendValue: 'Other'},
		{label: 'Busy', value: false, backendValue: 'Busy'},
    ])
    const [bulkResponseSelected, setBulkResponseSelected] = useState(mitraReducer?.paramPass?.bulkResponse ? mitraReducer?.paramPass.bulkResponse : [
        {label: 'No Bike', value: false, backendValue: 'No Bike'},
        {label: 'Has Bike', value: false, backendValue: 'Has Bike'},
        {label: 'Interested', value: false, backendValue: 'Interested'},
        {label: 'Not Interested', value: false, backendValue: 'Not Interested'},
        {label: 'Have Driving License', value: false, backendValue: 'Have Driving License'},
        {label: 'No Driving License', value: false, backendValue: 'No Driving License'}
    ])
    const statusColorMapping = {
		"Call Connected": "rgb(75 170 90)",
		"Call Disconnected(Hangup)": "rgb(168 35 35)",
		"IVR Scheduled(Queued)": "rgb(32 184 170)",
		"IVR Scheduled": '#000',
		"Call Disconnected(DND)": 'rgb(255 0 0)',
		"Call Disconnected(Failed)": 'rgb(244 116 55)',
		"No Answer": 'rgb(146 124 247)',
		"Rejected": 'rgb(251 18 18);',
		"Busy": 'red'
	};
    const [leads, setLeads] = useState([])
    const history = useHistory();
    const BulkActionGAEventsTracker = useGAEventsTracker("bulkActions")
    // const [createMoengageUser, trackEvent] = useMoengage()

    useEffect(() => {
        if(mitraReducer?.mitraInfo){
            setIsAdmin(mitraReducer?.mitraInfo?.role === 'admin' ? true : false)
            setMitra(mitraReducer?.mitraInfo)
            getActiveClientsForFilter().then(data => {
				setClientSelcted(data.data.activeClients)
			})
        }  
    }, [mitraReducer])
    useEffect(() => {
        if(mitra?.id){
            if (selection) {
                let clientArray = [], statusArray = [];
                clientSelected.map(ele => {
                    if (ele.value) {
                        clientArray.push(ele.key.toLowerCase())
                    }
                })
                bulkStatusesSelected.map(ele => {
                    if (ele.value) {
                        statusArray.push(ele.backendValue)
                    }
                })
                const data = {
                    startDate: moment(dateSelected).format("YYYY-MM-DD") + ' 00:00:01',
                    endDate: moment(dateSelected).format("YYYY-MM-DD") + ' 23:59:59',
                    client: JSON.stringify(clientArray),
                    status: JSON.stringify(statusArray),
                    mitraManagerId: mitra.id,
                }
                    getBulkUniquenessList(data).then((response) => {
                        if (response.data.data)
                            setLeads(response.data.data)
                            setPrevLeads(response.data.data)
                        // setShowBanner(response.data.displayBanner)

                    }).catch(e => {
                        console.log(e);
                    })
            }
            else {
                let IVRStatusArray = [], resposeArray = [];

                bulkIVRStatusesSelected.map(ele => {
                    if (ele.value) {
                        IVRStatusArray.push(ele.label)
                    }
                })

                bulkResponseSelected.map(ele => {
                    if (ele.value) {
                        resposeArray.push(ele.backendValue)
                    }
                })
                const data = {
                    startDate: moment(dateSelected).format("YYYY-MM-DD") + ' 00:00:01',
                    endDate: moment(dateSelected).format("YYYY-MM-DD") + ' 23:59:59',
                    status: JSON.stringify(IVRStatusArray),
                    response: JSON.stringify(resposeArray)
                }
                getScheduledIVRList(data).then((response) => {
                    if (response.data.data)
                        setIVRLeads(response.data.data)
                    setPrevLeads(response.data.data)
                }).catch(e => {
                    console.log(e);
                })
            }
        }
        if(moment(dateSelected).format("DD-MM-YYYY") !== moment().format("DD-MM-YYYY")) {
            setShowDateChip(true)
        } else {
            setShowDateChip(false)
        }
    }, [dateSelected, clientSelected, bulkStatusesSelected, bulkResponseSelected, bulkIVRStatusesSelected, selection ,mitraReducer])


    const changeSelection = () => {
        clientSelected.map(ele => {
            ele.value = false
        })
        bulkStatusesSelected.map(ele => {
            ele.value = false;
        })
        bulkIVRStatusesSelected.map(ele => {
            ele.value = false;
        })
        bulkResponseSelected.map(ele => {
            ele.value = false;
        })
    }

    const deleteChip = (name, item) => {
        if (name === 'response') {
            const currenArray = [...bulkResponseSelected]
            currenArray.map(ele => {
                if (ele.label === item) {
                    ele.value = false;
                }
            })
            setBulkResponseSelected(currenArray)
        }
        else if (name === 'IVRStatus') {
            const currenArray = [...bulkIVRStatusesSelected]
            currenArray.map(ele => {
                if (ele.label === item) {
                    ele.value = false;
                }
            })
            setBulkIVRStatusesSelected(currenArray)
        }
        else if (name === 'status') {
            const currenArray = [...bulkStatusesSelected]
            currenArray.map(ele => {
                if (ele.label === item) {
                    ele.value = false;
                }
            })
            setBulkStatusesSelected(currenArray)
        }
        else if (name === 'client') {
            const currenArray = [...clientSelected]
            currenArray.map(ele => {
                if (ele.label === item) {
                    console.log(name, item)
                    ele.value = false;
                }
            })
            setClientSelcted(currenArray)
        }
        else if (name === 'date') {
            setDateSelected(moment())
        }
    }

    const searchCandidate = (e) => {
        if (selection) {
            if (prevLeads.length === 0) {
                setPrevLeads(leads)
            }
            if (e.target.value.length === 0) {
                setLeads(prevLeads);
            }
            const searchResult = prevLeads.filter(ele => {
                return ele.candidatePhoneNumber.includes(e.target.value) || ele.candidateName.toLowerCase().includes(e.target.value.toLowerCase());
            })
            setLeads(searchResult);
        }
        else {
            if (prevLeads.length === 0) {
                setPrevLeads(leads)
            }
            if (e.target.value.length === 0) {
                setIVRLeads(prevLeads);
            }
            const searchResult = prevLeads.filter(ele => {
                return ele.phoneNumber.includes(e.target.value) || ele.firstName.toLowerCase().includes(e.target.value.toLowerCase());
            })
            setIVRLeads(searchResult);
        }

    }

    const mapIVRStatus = (status) => {
        let return_value;
        bulkIVRStatusesSelected.map(ele => {
            if (status == ele.backendValue) {
                return_value = ele.label
            }
        })
        return return_value
    }


    const downloadSheet = async () => {
		let data = [];
		let columns;

		if (selection) {
			columns = [
				{header: 'Name', key: 'name'},
				{header: 'Phone Number', key: 'phoneNumber'},
				{header: 'Status', key: 'status'},
				{header: 'Client', key: 'client'}
			];


			leads.map(ele => {
				data.push({name: ele.candidateName, phoneNumber: ele.candidatePhoneNumber, status: displayBulkUniqueStatus(ele.candidateStatus, ele.preferredClient, ele.scheduledInterviewId), client: ele.preferredClient})
			})

            trackEvent('bulk_uniqueness_file_downloaded', {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		}
		else {
			columns = [
				{header: 'Name', key: 'name'},
				{header: 'Phone Number', key: 'phoneNumber'},
				{header: 'IVRStatus', key: 'ivrStatus'},
				{header: 'Interest', key: 'interest'},
				{header: 'Driving License', key: 'dl'},
				{header: 'Bike', key: 'bike'}
			];


			ivrLeads.map(ele => {
				data.push({name: ele.firstName, phoneNumber: ele.phoneNumber, ivrStatus: mapIVRStatus(ele.status), interest: ele.answer[0], dl: ele.answer[1], bike: ele.answer[2]})
			})
            trackEvent('bulk_ivr_file_downloaded', {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
			
		}


		const workSheetName = 'Worksheet-1';
			const workBookName = 'MyWorkBook';
			const myInputId = 'myInput';
			const fileName = 'Report'
			const workbook = new Excel.Workbook();

			try {
				const worksheet = workbook.addWorksheet(workSheetName);
				worksheet.columns = columns;
				worksheet.getRow(1).font = {bold: true};

				data.forEach(singleData => {
					worksheet.addRow(singleData);
				});

				// loop through all of the columns and set the alignment with width.
				worksheet.columns.forEach(column => {
					column.width = column.header.length + 5;
					column.alignment = {horizontal: 'center'};
				});

				worksheet.eachRow({includeEmpty: false}, row => {
					// store each cell to currentCell
					const currentCell = row._cells;

					// loop through currentCell to apply border only for the non-empty cell of excel
					currentCell.forEach(singleCell => {
						// store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
						const cellAddress = singleCell._address;

						// apply border
						worksheet.getCell(cellAddress).border = {
							top: {style: 'thin'},
							left: {style: 'thin'},
							bottom: {style: 'thin'},
							right: {style: 'thin'}
						};
					});
				});
				// write the content using writeBuffer
				const buf = await workbook.xlsx.writeBuffer();

				// download the processed file
				saveAs(new Blob([buf]), `${fileName}.xlsx`);
			} catch (error) {
				console.error('<<<ERRROR>>>', error);
				console.error('Something Went Wrong', error.message);
			} finally {
				// removing worksheet's instance to create new one
				workbook.removeWorksheet(workSheetName);
			}
	}

    const handleDateChange = (date) => {
        if(date === null){
            setDateSelected(moment())
        }
        else{
            setDateSelected(moment(date))
        }
        setShowDateChip(true)
    } 

    return (
        <>
            <div className="Home mainContentZ">
                <div className="header">
                    <div className="header-text">
                        <span className="small-size-screen" style={{cursor: 'pointer'}} onClick={() => history.push("/")}>
                        <ArrowLeftOutlined />
                        </span>
                        <span className="welcome">Lead Management</span>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row', textAlign: 'right', marginLeft: 'auto'}}>
                        <div style={{display: 'flex', marginRight: '5px'}}>
                            <div className="header-search">
                                <input className="search-input" onChange={searchCandidate} type="text" placeholder="Search Candidates" />
                            </div>
                            <div className="search-icon" onClick={() => {if (window.innerWidth < 840) setOpenSearch(!openSearch)}}>
                                <SearchOutlined />
                            </div>
                        </div>
                        {isAdmin ?
                            <Button icon={<DownloadOutlined />} danger style={{display:'flex',alignItems:'center'}} onClick={() => {downloadSheet(); BulkActionGAEventsTracker("DownloadBulkActionSheet");}}>
                                Download File
                            </Button>
                            : null}
                    </div>

                </div>


                {
                    openSearch ?
                        <div style={{padding: '3px 5px'}}>
                            <div className="header-search">
                                <input className="search-input-box" type="text" onInput={searchCandidate} placeholder="Search Candidates" />
                            </div>
                        </div>
                        : null
                }

                <div className="filter-container">
                    <div className="filter-set-container">
                    <DatePicker picker="date" disabledDate={disabledDate} onChange={handleDateChange} defaultValue={dateSelected} value={dateSelected} allowClear={false} />
                        {
                            selection ?
                                <>
                                    <div id="category-filter"
                                        onClick={() => {
                                            setCategoryShowFilterPopup(true)
                                        }}
                                        className="Dropdown-control filter-label"
                                    >
                                        <div style={{margin: 'auto 0px'}}>Clients</div>
                                        <DownOutlined style={{lineHeight: 'inherit',margin: 'auto 0px'}}/>
                                    </div>

                                    <div id="status-filter"
                                        onClick={() => {
                                            setStatusShowFilterPopup(true);
                                        }}
                                        className="Dropdown-control filter-label">
                                        <div style={{margin: 'auto 0px'}}>Status</div>
                                        <DownOutlined style={{lineHeight: 'inherit',margin: 'auto 0px'}}/>
                                    </div>
                                </>
                                :
                                <>
                                    <div id="category-filter"
                                        onClick={() => {
                                            setIVRStatusShowFilterPopup(true)
                                        }}
                                        className=" Dropdown-control filter-label"
                                    >
                                        <div style={{margin: 'auto 0px'}}>IVR Status</div>
                                        <DownOutlined style={{lineHeight: 'inherit',margin: 'auto 0px'}}/>
                                    </div>

                                    <div id="status-filter"
                                        onClick={() => {
                                            setResponseShowFilterPopup(true);
                                        }}
                                        className="Dropdown-control filter-label">
                                        <div style={{margin: 'auto 0px'}}>Response</div>
                                        <DownOutlined style={{lineHeight: 'inherit',margin: 'auto 0px'}}/>
                                    </div>
                                </>
                        }

                    </div>
                    <div className="Chips-container" style={{padding: '0px 10px'}}>
                        {
                            showDateChip ?
                                <div className="single-chip">
                                    <span className="chip-text">
                                        <Moment format="DD MMM YYYY" withTitle>{dateSelected}</Moment>
                                    </span>
                                    <span className="chip-cross" onClick={() => {deleteChip('date', dateSelected); setShowDateChip(false)}}> <CloseOutlined /> </span>
                                </div>
                                : null
                        }
                        {
                            selection ?
                                <>
                                    {
                                        clientSelected.map((ele, index) => {
                                            if (ele.value) {
                                                return <div className="single-chip" key={index}>
                                                    <span className="chip-text">{ele.label}</span>
                                                    <span className="chip-cross" onClick={() => {deleteChip('client', ele.label)}}> <CloseOutlined /> </span>
                                                </div>
                                            }
                                        })
                                    }

                                    {
                                        bulkStatusesSelected.map((ele, index) => {
                                            if (ele.value) {
                                                return <div className="single-chip" key={index}>
                                                    <span className="chip-text">{ele.label}</span>
                                                    <span className="chip-cross" onClick={() => {deleteChip('status', ele.label)}}> <CloseOutlined /> </span>
                                                </div>
                                            }
                                        })
                                    }
                                </>
                                : null
                        }
                        {
                            !selection ?
                                <>
                                    {
                                        bulkIVRStatusesSelected.map((ele, index) => {
                                            if (ele.value) {
                                                return <div className="single-chip" key={index}>
                                                    <span className="chip-text">{ele.label}</span>
                                                    <span className="chip-cross" onClick={() => {deleteChip('IVRStatus', ele.label)}}> <CloseOutlined /> </span>
                                                </div>
                                            }
                                        })
                                    }

                                    {
                                        bulkResponseSelected.map((ele, index) => {
                                            if (ele.value) {
                                                return <div className="single-chip" key={index}>
                                                    <span className="chip-text">{ele.label}</span>
                                                    <span className="chip-cross" onClick={() => {deleteChip('response', ele.label)}}> <CloseOutlined /> </span>
                                                </div>
                                            }
                                        })
                                    }
                                </>
                                : null
                        }
                    </div>
                </div>
                <div className="bg-white" id="exTab2" style={{margin: '0px', width: '100%'}}>
                    <div className="row bg-white" style={{margin: '0px'}}>
                        <div className="col-md-6 col-6"><span style={selection == 'bulk_referral' ? {
                            paddingBottom: '8px',
                            borderBottom: '1px solid rgb(211, 70, 18)',
                            color: '#D34612',
                            cursor: 'pointer'} : {cursor: 'pointer', color: '#8C8C8C'}} onClick={() => {setSelection('bulk_referral'); changeSelection(); history.push("/lead-management/bulk-uniqueness")}}>Bulk Referrals</span></div>
                        <div className="col-md-6 col-6"><span style={selection == 'bulk_ivr'? {
                            paddingBottom: '8px',
                            borderBottom: '1px solid rgb(211, 70, 18)',
                            color: '#D34612',
                            cursor: 'pointer'
                        } : {cursor: 'pointer', color: '#8C8C8C'}} onClick={() => {setSelection('bulk-IVR'); changeSelection(); history.push("/lead-management/bulk-IVR")}}>Bulk IVR calls</span></div>

                        <div className="col-md-6 col-6"><span style={selection == 'bulk_whatsapp'? {
                            paddingBottom: '8px',
                            borderBottom: '1px solid rgb(211, 70, 18)',
                            color: '#D34612',
                            cursor: 'pointer'
                        } : {cursor: 'pointer', color: '#8C8C8C'}} onClick={() => {setSelection('bulk_whatsapp'); changeSelection(); history.push("/lead-management/bulk-Whatsapp-message")}}>Bulk Whatsapp Message</span></div>
                    </div>
                        
                        {
                            selection && (showBanner ?
                                    <div style={{margin: '20px'}}>
                                        <div className="warning-message-at-risk-container">
                                            <div className="warning-message-atrisk">
                                                    <InfoCircleOutlined  style={{color: '#D34612', margin: '5px 10px 0px 10px'}} />
                                                    It may take upto 2 hours for the uniqueness status to get updated for bulk referred leads.
                                                    <CloseOutlined onClick={() => {setShowBanner(false)}} style={{margin: '5px 10px 0px auto'}} />
                                            </div>
                                        </div>
                                    </div>
                                : null)
                        }

                        <div className="col-12 col-md-12 lead-managment-list-container">
                            <div className="list-scroll-x">
                                <table className="table table-responsive">
                                    <thead>
                                        <tr style={{backgroundColor: '#FAFAFA'}}>
                                            <th scope="col">Candidate Name</th>
                                            <th scope="col">Mobile No.</th>
                                            <th scope="col">{selection ? 'Candidate Status' : 'IVR status'}</th>
                                            <th scope="col">{selection ? 'Client Preference' : 'Candidate Response'}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selection ?
                                            leads.map((lead, index) =>
                                                <>
                                                    <tr key={index}>
                                                        <td>{lead.candidateName}</td>
                                                        <td>{lead.candidatePhoneNumber}</td>
														<td style={{color: displayBulkUniqueStatus(lead.candidateStatus, lead.preferredClient, lead.scheduledInterviewId) === 'Unique' ? '#D34612' : displayBulkUniqueStatus(lead.candidateStatus, lead.preferredClient, lead.scheduledInterviewId) === 'Not Unique' ? '#F5222D' : displayBulkUniqueStatus(lead.candidateStatus, lead.preferredClient, lead.scheduledInterviewId) === 'Pending' ? '#2F54EB' : '#262626'}}>{displayBulkUniqueStatus(lead.candidateStatus, lead.preferredClient, lead.scheduledInterviewId)}</td>
                                                        <td>{lead.preferredClient}</td>
                                                    </tr>
                                                </>
                                            )
                                            :
                                            ivrLeads.map((lead, index) =>
                                                <>
                                                    <tr key={index}>
                                                        <td>{lead.firstName}</td>
                                                        <td>{lead.phoneNumber}</td>
                                                        <td style={{color: statusColorMapping[mapIVRStatus(lead.status)] ? statusColorMapping[mapIVRStatus(lead.status)] : '#000'}}>{mapIVRStatus(lead.status)}</td>
                                                        <td>
                                                            {
                                                                lead.questions.map((q, i) => (
                                                                    <span key={i}>
                                                                        {q.toLowerCase().search('interest') !== -1 ? <b> Interested  -{lead.answer[i] ? lead.answer[i] : ''}<br /></b> : null}
                                                                        {q.toLowerCase().search('driving') !== -1 ? <b>  Driving License  -{lead.answer[i] ? lead.answer[i] : ''}<br /></b> : null}
                                                                        {q.toLowerCase().search('bike') !== -1 ? <b> Bike  -{lead.answer[i] ? lead.answer[i] : ''}<br /></b> : null}
                                                                    </span>
                                                                ))
                                                            }
                                                        </td>
                                                    </tr>
                                                </>
                                            )
                                        }
                                    </tbody>
                                </table>
                                {
                                    selection && leads.length === 0 ? <div className="leads-error">No leads found</div> : null
                                }
                                {
                                    !selection && ivrLeads.length === 0 ? <div className="leads-error">No leads found</div> : null
                                }
                            </div>
                        </div>
                </div>
                <div className="bottom-detail-wrapper">
                    <FilterPopup heading="Select Status" visible={showStatusFilterPopup} setShowFilterPopup={setStatusShowFilterPopup} setCategory={setBulkStatusesSelected} list={bulkStatusesSelected} />
                    {
                        clientSelected.length > 0 &&
                            <FilterPopup heading="Select Client" visible={showCategoryFilterPopup} setShowFilterPopup={setCategoryShowFilterPopup} setCategory={setClientSelcted} list={clientSelected} />
                    }
                    <FilterPopup heading="Select IVR Status" visible={showIVRStatusFilterPopup} setShowFilterPopup={setIVRStatusShowFilterPopup} setCategory={setBulkIVRStatusesSelected} list={bulkIVRStatusesSelected} />
                    <FilterPopup heading="Select Response" visible={showResponseFilterPopup} setShowFilterPopup={setResponseShowFilterPopup} setCategory={setBulkResponseSelected} list={bulkResponseSelected} />
                </div>
            </div>
            {/* <div className="navigation-container">
                <NavigationTab currentTab={0} mitra={mitraReducer?.mitraInfo} />
            </div> */}
        </>
    )
}

export default LeadManagement;