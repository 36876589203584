import React, {useEffect, useState} from 'react';
import '../../css/shareOnPlatform.scss';
import {Steps,Modal} from 'antd';
import {CloudDownloadOutlined, FileDoneOutlined, LoadingOutlined, CloseOutlined} from '@ant-design/icons';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '4px 20px',
        width: `${window.innerWidth > 786 ? '50%' : '90%'}`
    },
};
const {Step} = Steps;

const ShareOnPlatform = ({visible, setShowPopup, webLink, jobPostingData}) => {
    const [copyText, setCopyText] = useState('Copy URL')
    const [stepperBool, setstepperBool] = useState(false);
    const [socialHandlelabel, setSHlabel] = useState(false);
    const [stepperProgress, setstepperProgress] = useState(false);

    const onClickSocialHandles = (link, label) => {
        setSHlabel(label);
        if (link === "copyText") {
            navigator.clipboard.writeText(encodeURI(webLink));
            setCopyText("URL Copied!");
        } else {
            if (stepperBool !== link) {
                setstepperProgress(false)
            }
        }
        if (stepperBool !== link) {
            setstepperBool(link);
        }
    }

    const onCancelModal = () => {
        setShowPopup(false);
        setstepperProgress(false)
        setstepperBool(false)
    }

    useEffect(() => {
        let timer = null;
        if (stepperBool) {
            timer = setTimeout(() => {
                window.open(jobPostingData.imageUrl);
                setstepperProgress(1);
                if (stepperBool !== 'copyText') {
                    window.open(stepperBool);
                }
            }, 1000);
        }
        return () => clearTimeout(timer);
    }, [stepperBool]);

    return (<>
        <Modal className='share-popup-comtainer' title="Share on Social Media" visible={visible} footer={null} onCancel={onCancelModal}>
        <div className="platform-container">
                    <div className="share-element">
                        <a
                            rel="noopener noreferrer"
                            onClick={() => onClickSocialHandles(`https://wa.me?text=${encodeURIComponent(encodeURI(webLink))}`, 'whatsapp')}
                            target="_blank"
                            data-action="share/whatsapp/share"
                        >
                            <div>
                                <img alt="Home Icon Active" src="/image/whatsapp.svg" />
                            </div>
                            <div>
                                Whatsapp
                            </div>
                        </a>
                    </div>
                    <div className="share-element">
                        <a onClick={() => onClickSocialHandles(`https://www.facebook.com/sharer/sharer.php?u=${webLink}`, 'facebook')}
                            target="_blank">
                            <div>
                                <img alt="Home Icon Active" src="/image/facebook.svg" />
                            </div>
                            <div>
                                Facebook
                            </div>
                        </a>
                    </div>
                    <div className="share-element">
                        <a onClick={() => onClickSocialHandles(`https://telegram.me/share/url?url=${encodeURIComponent(encodeURI(webLink))}`, 'telegram')}
                            target="_blank">
                            <div>
                                <img alt="Home Icon Active" src="/image/telegram.svg" />
                            </div>
                            <div>
                                Telegram
                            </div>
                        </a>
                    </div>
                    <div className="share-element hover-blue" onClick={() => onClickSocialHandles('copyText', 'simpleTextCopy')}>
                        <div>
                            <img alt="Home Icon Active" src="/image/copylink.svg" />
                        </div>
                        <div>
                            {copyText}
                        </div>
                    </div>
                </div>
                {stepperBool &&
                    <div className='stepper-modal'>
                        <Steps>
                            <Step status={stepperProgress == 0 ? "process" : "done"} title={stepperProgress == 0 ? "Waiting for Download" : "Download completed"} icon={stepperProgress == 0 ? < LoadingOutlined /> : <CloudDownloadOutlined />} description="Download the poster to your system for further sharing on social media." />
                            {stepperBool !== 'copyText' && <Step style={{cursor: 'pointer'}} onClick={() => window.open(stepperBool)} status={stepperProgress < 1 ? "wait" : "done"} title={stepperProgress == 0 ? "Wait" : `Open with ${socialHandlelabel}`} icon={<FileDoneOutlined />} description={`Click here to redirect`} />}
                        </Steps>
                    </div>
                }
        </Modal>
        </>
    )
}

export default ShareOnPlatform;
