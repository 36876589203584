import React, { useCallback, useEffect, useState, useContext } from "react";
import { Formio } from "react-formio"
import { useHistory } from "react-router-dom";
import { AppContext } from '../../App';
import { genericPostToFormIO, getCandidateInfo, postCandidateDocuments } from "../../service/additionalInfoService";
import commonFunc from "../../utils/commonFunction";
import CONSTANTS from "../../constants/constants";
import { trackEvent } from "../../service/engagementMonitoringService";
import { message, notification } from "antd";

let docsSubmitCount = 0, verifyOtpCount = 0, isOtpVerified = false;

const MissingDocuments = props => {
    const { mitraReducer, setSpin } = useContext(AppContext);
    const history = useHistory()
    const [mitra, setMitra] = useState()
    const [sub, setSub] = useState({
        data: {}
    })
    const [lead] = useState(commonFunc.getAllsearchParams(history.location.search))
    const [viaNext, setViaNext] = useState(false)

    const goToLeadsPage = () => {
        setTimeout(() => {
            setSpin({
                loading: false,
                delay: null,
                tip: 'Uploading Documents Please Wait...'
            });
            history.replace({ pathname: "/recruiter-candidates" });
        }, 1000);
    };

    const options = {
        noAlerts: true,
        hooks: {
            beforeNext: async (currentPage, submission, next) => {
                // await updateOrInsertEntryForTCAccess(mitra, lead);
                try {
                    await genericPostToFormIO(
                        {
                            phoneNumber: lead.phoneNumber,
                            adhoc: true
                        },
                        {
                            sendOtpForZeptoSsu: true
                        }
                    )

                    setViaNext(true);
                } catch (error) {
                    notification.error({
                        message: "Error",
                        description: "Unable to send OTP"
                    })
                }
            },
        },
        breadcrumbSettings: {
            clickable: false
        }
    }
    const fetchForm = useCallback(async (formURL) => {
        Formio.createForm(document.getElementById("form-io"), formURL, options)
            .then(async function (form) {
                form.on("change", async (submission) => {
                    const key = submission.changed ? submission.changed.component.key : undefined
                    if (key && submission.data[key] && Array.isArray(submission.data[key]) && submission.data[key].length > 0 && submission.data[key][0].data) {
                        submission.data[key][0].url = submission.data[key][0].data.imageUrl
                        form.submission = {
                            data: submission.data
                        }
                        let dummyData = {};
                        dummyData[key] = submission.data[key];
                        if (document.querySelector('.btn-wizard-nav-submit'))
                            document.querySelector('.btn-wizard-nav-submit').disabled = true;
                        let errorStatus = await validateFile({ data: dummyData });
                        if (errorStatus) {
                            const div = document.querySelector('.formio-component-' + key);
                            div.querySelector('.fa-remove').click()
                        }
                        if (document.querySelector('.btn-wizard-nav-submit'))
                            document.querySelector('.btn-wizard-nav-submit').disabled = false;
                    }
                })
                form.on("verifyOtp", async (submission) => {
                    const verifyOtpButton = document.getElementsByName("data[verifyOtp]")?.[0]

                    let response = {};
                    if (verifyOtpButton) {
                        verifyOtpButton.disabled = true;
                        verifyOtpButton.insertAdjacentHTML("beforeend", `<i class="fa fa-refresh fa-spin button-icon-right"></i>`)
                    }
                    if (verifyOtpCount >= 3) {
                        message.warn("You have exceeded the maximum number of attempts.", 6);
                        return goToLeadsPage();
                    }
                    if (!submission.otp || submission.otp.toString().indexOf('_') !== -1) {
                        message.warn("Please enter the OTP first", 4);
                        verifyOtpButton.disabled = false;
                    }
                    else {
                        response = await genericPostToFormIO(
                        {
                            phoneNumber: lead.phoneNumber,
                            otp: submission.otp,
                            adhoc: true
                        },
                        {
                            verifyOtpForZeptoSsu: true
                        }
                    )}

                    if (response?.data?.verifyOtpForZeptoSsu === "200") {                              
                        message.success("Your OTP has been verified. Please Submit form to upload the documents.", 8);
                        isOtpVerified = true;
                    }
                    else
                    {
                        message.error('Please Enter correct OTP',4);
                        verifyOtpButton.disabled = true;
                        verifyOtpCount++;
                    }
                    verifyOtpButton.removeChild(verifyOtpButton.lastChild)
                })
                form.on("submit", async (submission) => {
                    if (!isOtpVerified) {
                        message.warn('Submit button clicked without verifying OTP', 5);
                        return;
                    }
                    setSpin({
                        loading: true,
                        delay: null,
                        tip: 'Uploading Documents Please Wait...'
                    });
                    const submitButtomEl = document.querySelector(".btn-wizard-nav-submit")
                    if (submitButtomEl) {
                        submitButtomEl.disabled = true;
                        submitButtomEl.insertAdjacentHTML("beforeend", `<i class="fa fa-refresh fa-spin button-icon-right"></i>`)
                    }
                    let errors = [];
                    // const res = await addDetailsForMitra(submission.data, mitra);
                    await genericPostToFormIO(
                        {
                            phoneNumber: lead.phoneNumber,
                        },
                        {
                            updateRiderInformationOnZeptoSsu: true
                        }
                    )
                    if (submitButtomEl) {
                        submitButtomEl.disabled = false;
                        submitButtomEl.removeChild(submitButtomEl.lastChild)
                    }
                    return goToLeadsPage();
                })
                form.submission = sub

                setTimeout(() => {
                    if(viaNext) {
                        form.setPage(1);
                    }
                }, 10)
                setTimeout(() => {
                    document.querySelectorAll("img").forEach(el => {
                        el.addEventListener("click", (e) => {
                            window.open(e.target.getAttribute("src"))
                        })
                    })
                }, 1000)
            })
    }, [options, props, history, mitra, viaNext])

    useEffect(() => {
        docsSubmitCount = 0;
        verifyOtpCount = 0;
        isOtpVerified = false;
        setTimeout(async () => {
            await fetchInfo()
        }, 400);

        if (lead?.meta) {
            lead.meta = JSON.parse(lead.meta);
        }
    }, [])

    const fetchInfo = async () => {
        const populatedRes = await getCandidateInfo(lead?.phoneNumber)
        const populatedData = {}
        populatedRes.data.forEach(el => {
            try {
                const parsedValue = JSON.parse(el.value)
                if (typeof parsedValue === "number")
                    populatedData[el.type] = `${el.value}`
                else
                    populatedData[el.type] = [{ ...parsedValue, originalName: parsedValue.url, storage: "url" }]
            } catch (error) {
                populatedData[el.type] = el.value
            }
        })
        if (populatedData && !Array.isArray(populatedData.panCard)) {
            delete populatedData.panCard;
        }

        setSub({ data: populatedData })
    }

    const validateFile = async (data, allowDocUpload = false) => {
        const res = await postCandidateDocuments(data.data, mitra, lead, allowDocUpload);
        let error = false;
        for (let key in res.data) {
            if (res.data[key] !== "200") {
                let errMsg = res.data[key].split("Error: ").join("").split("500: ").join("")
                const attemptsIndex = errMsg.indexOf("Attempt: ")
                if (attemptsIndex > -1) {
                    errMsg = errMsg.split("Attempt: ").join("")
                    const attempts = errMsg.split(" ")[0]
                    if (!(!isNaN(attempts * 1) && attempts > 3 && docsSubmitCount >= 3)) {
                        errMsg = errMsg.slice(errMsg.indexOf(" ") + 1)
                        if (lead.companyName?.toLowerCase().indexOf('zepto_3pl') !== -1)
                        {
                            await trackEvent(`Document_Rejected_Warning_Shown`, {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                            await askPermissionForReupload(errMsg, key, data)
                        }
                        else {
                            alert(`${key}: ${errMsg}`);
                            error = true;
                        }
                    }
                }
                else {
                    if (lead.companyName?.toLowerCase().indexOf('zepto_3pl') !== -1)
                    {
                        await trackEvent(`Document_Rejected_Warning_Shown`, {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                        await askPermissionForReupload(errMsg, key, data)
                    }
                    else {
                        alert(`${key}: ${errMsg}`);
                        error = true;
                    }
                }
            }
        }
        return error;
    }

    const askPermissionForReupload = async (errMsg, key, data) => {
        const fileName = key ? key.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/\b\w/g, function(match) { return match.toUpperCase(); }) : null;
        const reuploadFile = window.confirm(`${fileName ? fileName : ''} : Uh - Oh! High chances of rejection from client - Please upload the correct document to activate rider\n\nDo you want to proceed with the upload?`);
        if (reuploadFile) {
            await trackEvent(`Warning_document_proceeded_by_Recruiter`, {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
            await validateFile(data, true);
        }
        else {
            const findIndex = Object.keys(data?.data).findIndex(ele => Array.isArray(data?.data[ele]));
            const div = document?.querySelector('.formio-component-' + Object.keys(data?.data)?.[findIndex]);
            if (div)
                div.querySelector('.fa-remove').click()
        }
    }

    useEffect(() => {
        fetchForm(process.env.REACT_APP_MISSING_DOCS_FORM)
        setMitra(mitraReducer?.mitraInfo)
    }, [fetchForm, history])

    return (
        <div className="AdditionalInfo1 mainContentZ">
            <h2 className="heading_1 add-job">
                Upload Missing Documents for Zepto
            </h2>
            <div>
                {mitra && <div id="form-io"></div>}
            </div>
        </div>
    )
}


export default MissingDocuments;
