import React, {useContext, useEffect, useState} from "react";
import { Typography, Radio, Space,Input, Checkbox, Divider,Tag, Button, notification } from 'antd';
import {CheckCircleFilled,InfoCircleFilled, CheckOutlined, CloseOutlined, PlusOutlined} from '@ant-design/icons';
import './kaleyraCallComponents/callStatus.scss';
import PageHeaderComponent from "./common/PageHeader";
import CONSTANTS from "../constants/constants";
import * as queryString from 'query-string';
import { addCallRemark, addCustomCallNotes, getCallStatus } from "../service/kaleyraCallService";
import { useHistory } from "react-router-dom";
import { AppContext } from "../App";
import moment from "moment";
import { RouterPrompt } from "./RouterPrompt/RouterPrompt";

const { Text} = Typography;
const { TextArea } = Input;

const CustomCallNotes = ({parentUserName=null,parentUserId=null,parentRhId=null,parentPhoneNumber=null, showHeader=true, onSubmit=null}) => {
    const [breadcrumb] = useState([{link: '/custom-call-notes', label: 'Add Call Notes'}]);
    const [callConnectedTags, setCallConnectedTags] = useState(CONSTANTS.CALL_TAGS.CONNECTED_TAGS);
    const [callNotConnectedTags, setCallNotConnectedTags] = useState(CONSTANTS.CALL_TAGS.NOT_CONNECTED_TAGS);
    const [ifCallConnected, setIfCallConnected] = useState(true);
    const [checkboxTicked, setCheckboxTicked] = useState(false);
    const [comment, setComment] = useState('');
    const [callId, setCallId] = useState('');
    const history = useHistory();
    const {mitraReducer} = useContext(AppContext);
    const [callStatus, setCallStatus] = useState({});
    const [loading, setLoading] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [showPrompt, setShowPrompt] = useState(true);
    const [userName, setUserName] = useState({});
    const [userId, setUserId] = useState({});
    const [recommendationHistoryId, setRecommendationHistoryId] = useState({});
    const [phoneNumber, setPhoneNumber] = useState({});
    const [tagsInfo, setTagsInfo] = useState([]);
    
    useEffect(()=>{
        // Adding these two lines to drop previous component values.
        setCallConnectedTags([...CONSTANTS.CALL_TAGS.CONNECTED_TAGS]);
        setCallNotConnectedTags([...CONSTANTS.CALL_TAGS.NOT_CONNECTED_TAGS]);
        const modifiedTagsInfo = CONSTANTS.CALL_NOTE.map((tag, index) => ({
            ...tag,
            isSelected: index === 0
        }));
        setTagsInfo(modifiedTagsInfo);

        const parsedUrl = queryString.parse(window.location.search);

        if(parsedUrl?.userName && parsedUrl?.userId && parsedUrl.phoneNumber && parsedUrl.rhId) {
            setUserName(parsedUrl.userName);
            setUserId(parsedUrl.userId);
            setPhoneNumber(parsedUrl.phoneNumber);
            setRecommendationHistoryId(parsedUrl.rhId);
        } else {
            setUserName(parentUserName);
            setUserId(parentUserId);
            setPhoneNumber(parentPhoneNumber);
            setRecommendationHistoryId(parentRhId);
        }

    // return () => {
    //     for (let i=0;i<callConnectedTags.length;i++) {
    //         if (callConnectedTags[i].isChecked) callConnectedTags[i].isChecked=false;
    //     }
    //     for (let i=0;i<callNotConnectedTags.length;i++) {
    //         if (callNotConnectedTags[i].isChecked) callNotConnectedTags[i].isChecked=false;
    //     }
    //     setCallConnectedTags([...callConnectedTags]);
    //     setCallConnectedTags([...callNotConnectedTags]);
    // }
    }, []);

    const onTagChange = (tagList, tagId, tagType) => {
        setDisableSubmit(false);
        for (let i=0;i<tagList.length; i++) {
            if (tagList[i].id === tagId) {
                tagList[i].isChecked = true;

                if(tagList[i].enableCallback) {
                    setCheckboxTicked(true);
                } else {
                    setCheckboxTicked(false);
                }
            } else {
                tagList[i].isChecked = false;
            }
        }
        if (tagType === 'CONNECTED') {
            setCallConnectedTags([...tagList])
            const newTagValue = [...callNotConnectedTags];
            for (let i=0;i<newTagValue.length; i++) {
                newTagValue[i].isChecked = false;
            }
        } else {
            setCallNotConnectedTags([...tagList]);
            const newTagValue = [...callConnectedTags];
            for (let i=0;i<newTagValue.length; i++) {
                newTagValue[i].isChecked = false;
            }
        }
    };

    const onStatusChange = (key) => {
        // code to change status
        const modifiedTagsInfo = [...tagsInfo];

        for (let i = 0; i < modifiedTagsInfo.length; i++) {
            if(modifiedTagsInfo[i].key === key) {
                modifiedTagsInfo[i].isSelected = true;
            } else {
                modifiedTagsInfo[i].isSelected = false;
            }
        }

        setTagsInfo([...modifiedTagsInfo]);

        if(key === 'connected') {
            setIfCallConnected(true);
        } else {
            setIfCallConnected(false);
        }

        setDisableSubmit(true);
        setCheckboxTicked(false);
    }

    const onRadioChange = (event) => {
        setDisableSubmit(true);
        setCheckboxTicked(false);
        setComment('');
       setIfCallConnected(!ifCallConnected);
    };

    const onCheckboxValueChange = () => {
        setCheckboxTicked(!checkboxTicked);
    }

    const onCommentChange = (event) => {
        setComment(event.target.value)
    }

    const openNotification = () => {
        const key = `open${Date.now()}`;
        const placement= 'bottomRight';
        notification.open({
          message: `Call log successfully recorded for: ${userName}`,
          key,
          duration: 5,
          placement,
          icon: <CheckCircleFilled style={{ color: '#52C41A' }} /> ,
        });
      };

    const submitRemarks = () => {
        if(disableSubmit) return;

        setLoading(true);
        const tagGroup = ifCallConnected ? callConnectedTags: callNotConnectedTags;
        const selectedTag = tagGroup.filter((tag) => tag.isChecked);
        if (!selectedTag.length) {
            setLoading(false);
            notification['error']({
                message: 'Please select a status tag to continue !!'
              });
            return;
        }
        const apiParams = {
            mitraId: mitraReducer && mitraReducer.mitraInfo && mitraReducer.mitraInfo.id || null,
            managerMitraId: mitraReducer && mitraReducer.mitraInfo && mitraReducer.mitraInfo.managerMitraID || null,
            userId: userId,
            recommendationHistoryId: recommendationHistoryId || null,
            callerNumber: mitraReducer && mitraReducer.mitraInfo && mitraReducer.mitraInfo.phoneNumber || null,
            receiverNumber: phoneNumber,
            isCallConnected: ifCallConnected,
            isFollowUpNeeded: checkboxTicked,
            tag: selectedTag?.[0]?.value || "",
            comment: comment,
        };

        console.log(apiParams);
        addCustomCallNotes(apiParams).then((response) => {
            setLoading(false);
            setShowPrompt(false);
            openNotification();
            setTimeout(()=> {
                if(onSubmit) {
                    onSubmit();
                } else {
                    history.goBack();
                }
            }, 1000);
        }).catch((error)=> {
            setLoading(false);
            console.log(error);
        });
    }


    return (
        <div>
            {
                showHeader ? (
                    <div className="header">
                        <PageHeaderComponent backBtnBool={true} breadcrumb={breadcrumb}></PageHeaderComponent>
                    </div>
                ):null
            }

        <div className="new-call-notes-main-container">
            <div className="call-status-container">

                {
                    tagsInfo.map((item,index) => {
                        return (
                            <div onClick={()=>onStatusChange(item.key)} key={item.key} className={`individual-call-status ${item.isSelected ? item.activeClass: ''}`}>
                                {item.isSelected ? item.icon: null} {item.label}
                            </div>
                        )
                    })
                }
            </div>
            <Divider />
            <div className="call-tags-container">
                <div className="tag-label">
                    Select Status <sup>*</sup>
                </div>
                <div className="tags">
                    {
                        ifCallConnected ? (
                            callConnectedTags?.map((item,index) => {
                                return (
                                    <div 
                                    onClick={()=> onTagChange(callConnectedTags, item.id, 'CONNECTED')}
                                    key={item.id} 
                                    className={`individual-tag ${item.isChecked ? 'active-tag':''}`}
                                    >
                                        {item.value}
                                    </div>
                                )
                            })
                        ): (
                            callNotConnectedTags?.map((item,index) => {
                                return (
                                    <div 
                                    onClick={()=> onTagChange(callNotConnectedTags, item.id, 'NOT_CONNECTED')}
                                    key={item.id} 
                                    className={`individual-tag ${item.isChecked ? 'active-tag':''}`}
                                    >
                                        {item.value}
                                    </div>
                                )
                            })
                        )
                    }
                </div>
            </div>
            <div className="call-back-comment-container">
                <Checkbox onChange={onCheckboxValueChange} checked={checkboxTicked}>Call back again</Checkbox>
                <TextArea
                    placeholder="Add Comment"
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    onChange={onCommentChange}
                    value={comment}
                    style={{marginTop: '12px'}}
                />
            </div>
        
        </div>
        <div className="bottom-section">
            <Divider />
            <div className="button-container">
                <Button className={`add-note-button ${disableSubmit ? 'disable-submission': ''}`} onClick={submitRemarks}><PlusOutlined />Add</Button>
            </div>
        </div>
    </div>
    );
};

export default CustomCallNotes
