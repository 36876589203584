import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Button, Spin, Modal, message, Select, Input, Upload, AutoComplete } from 'antd';
import {
    CheckCircleFilled, DownloadOutlined, ShareAltOutlined, CloseOutlined, RightOutlined, UploadOutlined, PaperClipOutlined, DownOutlined, DeleteOutlined, PictureOutlined, CheckOutlined, CopyOutlined, SearchOutlined
} from '@ant-design/icons';
import axios from 'axios';
import './PosterModalStyle.scss'
import { getMitraTeamMembersList } from './../../../../service/commonService'
import * as loginService from '../../../../service/loginService';
import { getCreateJobPostingDropdown, createPoster } from '../../../../service/jobDemandsService';
import { getActiveClientsForFilter } from './../../../../service/commonService';
import { trackEvent } from '../../../../service/engagementMonitoringService';
import CONSTANTS from '../../../../constants/constants';

const { Option } = Select;

const PosterModal = ({ isModalOpen = false, client = { label: "", key: "", value: false }, city = "", closeModal, posterURLFromParent = "", posterId="" }) => {
    const history = useHistory();
    const [clientList, setClientList] = useState([]);
    const [clientLoading, setClientLoading] = useState(false);
    const [selectedClient, setSelectedClient] = useState({
        label: "",
        key: "",
        value: false
    });
    const [cityList, setCityList] = useState([]);
    const [selectedCity, setSelectedCity] = useState(city);
    const [cityLoading, setCityLoading] = useState(false);
    const [languageList, setLanguageList] = useState(['English', 'Hindi', 'Telugu', 'Tamil', 'Kannada']);
    const [selectedLanguage, setSelectedLanguage] = useState("English");

    const [showMore, setShowMore] = useState(false);
    const [mitra, setMitra] = useState();
    const [teamList, setTeamList] = useState([]);

    const [contactName, setContactName] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [contactLogo, setContactLogo] = useState();
    const [jobDemandId, setJobDemandId] = useState("");
    const [posterURL, setPosterURL] = useState(posterURLFromParent)
    const [cancelToken, setCancelToken] = useState(null); // State to store the cancel token
    const [sharePosterClicked, setSharePosterClicked] = useState(false);
    const [isCopyClicked, setIsCopyClicked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [copyCityFilterList, setCopyCityFilterList] = useState([]);
    const [citySearch, setCitySearch] = useState("");
    const [salary, setSalary] = useState("");
    const [publishPoster, setPublishPoster] = useState(false);
    const [publishPosterId, setPublishPosterId] = useState("");
    useEffect(() => {
        getAllClients()

        if (posterURLFromParent) {
            setSharePosterClicked(true)
        }
    }, [])

    useEffect(() => {
        if (selectedCity && cityList.length > 0) {
            handleCityChange(selectedCity)
        }
    }, [selectedCity, cityList])

    useEffect(() => {
        loginService
            .validateSession()
            .then((response) => {
                setMitra(response.data.mitra);
                getMitraTeamMembersList(true).then(res => {
                    let mitraTeamNameNumberArray = res.data.data.map(ele => {
                        return {
                            value: ele.id,
                            label: `${ele.name}`,
                            phone: ele.phoneNumber
                        }
                    })
                    mitraTeamNameNumberArray = [{ value: response.data.mitra.id, label: `${response.data.mitra.name}`, phone: response.data.mitra.phoneNumber }, ...mitraTeamNameNumberArray]
                    setTeamList(mitraTeamNameNumberArray)
                });

            })
            .catch((error) => history.push('/'));
    }, [isModalOpen])

    useEffect(() => {
        createPosterAndGetURL()
    }, [jobDemandId, selectedLanguage, contactName, contactNumber, salary, contactLogo])

    const getAllClients = () => {
        setClientLoading(true)
        getActiveClientsForFilter().then(data => {
            if (data && data.data) {
                setClientLoading(false)
                setClientList(data.data.activeClients)
                if (client) {
                    for (let i = 0; i < data.data.activeClients.length; i++) {
                        if (data.data.activeClients[i].key == client.key) {
                            setSelectedClient(JSON.parse(JSON.stringify(data.data.activeClients[i])));
                            getCitiesAccToSelectedClient(data.data.activeClients[i]);
                            break;
                        }
                    }
                }
            }
        })
    }

    const handleClientChange = (client) => {
        setSelectedCity(null)
        for (let i = 0; i < clientList.length; i++) {
            if (clientList[i].label == client) {
                setSelectedClient(clientList[i])
                getCitiesAccToSelectedClient(clientList[i])
                break
            }
        }
    }

    const getCitiesAccToSelectedClient = (client) => {
        setLoading(true);
        setCityLoading(true)
        getCreateJobPostingDropdown().then((clientCityRes) => {
            if (clientCityRes && clientCityRes.data && clientCityRes.data.viewData && clientCityRes.data.viewData.length > 0) {
                setCityLoading(false)
                setLoading(false);
                let allClientsCity = clientCityRes.data.viewData
                for (let i = 0; i < allClientsCity.length; i++) {
                    if (allClientsCity[i].name == client.key) {
                        setCityList(allClientsCity[i].cities)
                        setCopyCityFilterList(allClientsCity[i].cities)
                        if (city) {
                            preFillCityAccToClient(allClientsCity[i].cities)
                        }
                        break;
                    }
                }
            }
        })
    }

    const preFillCityAccToClient = (allCities) => {
        if (city && allCities && allCities.length) {
            for (let i = 0; i < allCities.length; i++) {
                if (allCities[i].city == city) {
                    setSelectedCity(allCities[i].city)
                    break;
                }
            }
        }
    }

    const onCityInpChange = (inp) => {
        inp.preventDefault();
        if (inp?.target?.value) {
            setCitySearch(inp.target.value)
            let tempCityFilterList = cityList.filter(filteredCity => filteredCity.city.toLowerCase().includes(inp.target.value.toLowerCase()))
            setCopyCityFilterList(JSON.parse(JSON.stringify(tempCityFilterList)))
        }
        else if (inp?.target?.value == "") {
            setCitySearch("")
            setCopyCityFilterList(JSON.parse(JSON.stringify(cityList)))
        }
    }

    const handleCityChange = (city) => {
        if (city) {
            trackEvent('poster_city_selected', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
            setCitySearch("")
            setCopyCityFilterList(JSON.parse(JSON.stringify(cityList)))
            setSelectedCity(city)
            for (let i = 0; i < cityList.length; i++) {
                if (cityList[i].city == city) {
                    setJobDemandId(cityList[i].id[0])
                    break;
                }
            }
        }
    }

    const handleLanguageChange = (lang) => {
        setSelectedLanguage(lang)
        trackEvent('poster_langauge_selected', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    }

    const onContactNameChange = (inp) => {
        if (inp) {
            setContactName(inp)
        }
        else {
            setContactName("")
        }
    }

    const handleContactNameChange = (contact) => {
        trackEvent('poster_contact_name_added', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        for (let i = 0; i < teamList.length; i++) {
            if (teamList[i].value == contact) {
                setContactName(teamList[i].label)
                setContactNumber(teamList[i].phone)
                break;
            }
        }
    }

    const filterMitraTeamMembers = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const filterClients = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const filterCities = (input, option) =>
        (option?.city ?? '').toLowerCase().includes(input.toLowerCase());

    const updateContactNumber = (inp) => {
        if (inp && inp.target && inp.target.value) {
            setContactNumber(inp.target.value)
        }
        else {
            if (inp?.target?.value == "") {
                setContactNumber("")
            }
        }
    }

    const updateSalary = (inp) => {
        if (inp && inp.target && inp.target.value) {
            setSalary(inp.target.value)
        }
        else {
            if (inp?.target?.value == "") {
                setSalary("")
            }
        }
    }

    const props = {
        name: 'image',
        multiple: false,
        customRequest: async ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        },
        beforeUpload(file) {
            const fileSize = file.size / 1024 / 1024; // Convert file size to MB
            if (fileSize > 1) {
                message.error('Image must be smaller than 1MB!');
                return false; // Cancel upload
            }
            return true; // Proceed with upload
        },
        onChange(fileList) {
            if (fileList.file.status === 'done') {
                trackEvent('poster_contact_image_added', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                message.success(`${fileList.file.name} file uploaded successfully`);
                handleLogoUpload(fileList);
            } else if (fileList.file.status === 'error') {
                message.error(`${fileList.file.name} file uploading failed`);
            } else if (fileList.file.status === "removed") {
                setContactLogo([])
            }
        },
        maxCount: 1,
        accept: 'image/png, image/jpeg',
    };

    const handleLogoUpload = (fileObj) => {
        if (fileObj.fileList.length > 0) {
            setContactLogo(fileObj?.fileList[0]?.originFileObj)
        }
        // createPosterAndGetURL()
    }

    const createPosterAndGetURL = (jobId = "", publishPoster= false) => {
        setPublishPoster(publishPoster);
        let selectedJobId = jobDemandId ? jobDemandId : jobId
        if (selectedJobId) {
            const posterAPIData = new FormData();
            posterAPIData.append('jobId', selectedJobId);
            posterAPIData.append('language', selectedLanguage);
            contactName ? posterAPIData.append('name', contactName) : posterAPIData.append('name', "");
            contactNumber ? posterAPIData.append('phoneNumber', contactNumber) : posterAPIData.append('phoneNumber', "");
            salary ? posterAPIData.append('salary', salary) : posterAPIData.append('salary', "");
            contactLogo ? posterAPIData.append('mlLogo', contactLogo) : posterAPIData.append('mlLogo', "");
            publishPoster ? posterAPIData.append('publishPoster', true) : posterAPIData.append('publishPoster', false);
            if (cancelToken) {
                cancelToken.cancel(); // Used to cancel previous request to prevent data discrepancy
            }
            const newCancelToken = axios.CancelToken.source(); // Create a new CancelToken
            setCancelToken(newCancelToken); // Set the new cancel token
            if(!publishPoster) { setLoading(true) }
            trackEvent('poster_create', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
            createPoster(posterAPIData, newCancelToken.token).then((posterRes) => {
                if (posterRes && posterRes.data && posterRes.data.data) {
                    if(posterRes.data.id && publishPoster) {
                        setPublishPosterId(posterRes.data.id);
                        onSharePosterClicked();
                    }
                    setLoading(false)
                    setPosterURL(posterRes.data.data.imageUrl)
                }
                else {
                    setLoading(false)
                    message.error("Something went wrong, please try again in some time.")
                }
            })
                .catch((err) => {
                    if (axios.isCancel(err)) {
                        console.log('Request canceled');
                    } else {
                        setLoading(false)
                        message.error("Something went wrong, please try again in some time.")
                    }
                })
        }
    }

    const onDownloadPosterClicked = () => {
        trackEvent('poster_downloaded', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        const link = document.createElement('a');
        link.href = posterURL;
        link.download = `${selectedClient.label}-Poster`; // Specify the desired filename

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    }

    const onSharePosterClicked = () => {
        // copyToClipboard()
        setSharePosterClicked(true)
    }

    const checkIfShareBtnDisabled = () => {
        let isDisabled = false
        if (!selectedClient.key || !selectedCity || !selectedLanguage) {
            isDisabled = true
        }

        return isDisabled;
    }

    const copyToClipboard = () => {
        let posterLink = process.env.REACT_APP_PUBLIC_URL + `/job-posting/` + (publishPosterId ? publishPosterId : posterId);
        if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(posterLink)
                .then(() => {
                    message.success("Poster link copied!")
                    setIsCopyClicked(true);
                })
                .catch(err => {
                    console.error('Error copying to clipboard:', err);
                    message.error('Failed to copy link');
                });
        }
        else {
            var textArea = document.createElement("textarea");
            textArea.value = posterLink;

            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);

            textArea.focus();
            textArea.select();

            try {
                var successful = document.execCommand('copy');
                var copyMessage = successful ? message.success("Poster link copied!") : message.error('Failed to copy link');;
            } catch (err) {
                console.error('Error copying to clipboard:', err);
                message.error('Failed to copy link');
            }
            document.body.removeChild(textArea);
        }
    };

    const shareOnSocialMedia = (platform = "") => {
        let posterLink = process.env.REACT_APP_PUBLIC_URL + `/job-posting/` + (publishPosterId ? publishPosterId : posterId);
        let shareURL = ""
        switch (platform) {
            case "whatsapp":
                shareURL = `https://web.whatsapp.com/send?text=${encodeURIComponent(posterLink)}`;
                break;
            case "facebook":
                shareURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(posterLink)}`;
                break;
            case "telegram":
                shareURL = `https://t.me/share/url?url=${encodeURIComponent(posterLink)}`;
                break;
        }

        window.open(shareURL, '_blank');
    }

    return (
        <Modal
            title={null}
            centered
            visible={isModalOpen}
            footer={null}
            width={1000}
            className="poster-modal-parent"
            onCancel={closeModal}
        >
            <div className='poster-modal-content'>
                <div className='pm-header d-flex justify-content-between align-items-center'>
                    <div className='pm-header-title d-flex justify-content-between align-items-center'>
                        {
                            sharePosterClicked ?
                                <div className='d-flex align-items-center'>
                                    <ShareAltOutlined className='pm-header-icon' />
                                    <span className='pm-header-txt'>
                                        Share Poster
                                    </span>
                                </div>
                                :
                                <div className='d-flex align-items-center'>
                                    <PictureOutlined className='pm-header-icon' />
                                    <span className='pm-header-txt'>
                                        Create Poster
                                    </span>
                                </div>

                        }
                    </div>
                    <div className='pm-header-close jp-cp' onClick={closeModal}>
                        <CloseOutlined className='pm-header-close-icon' />
                    </div>
                </div>
                <div className='pm-body'>
                    <div className='pm-left-container'>
                        {
                            sharePosterClicked ?
                                <div>
                                    {
                                        isCopyClicked ?
                                            <Button className='pm-download-btn show-only-mobile-disp-flex d-flex align-items-center jp-cp justify-content-center w-100' onClick={() => copyToClipboard()} disabled={!posterURL}>
                                                <CheckOutlined className='pm-orange-icon' />
                                                <div className='pm-download-btn-txt'>
                                                    Link Copied
                                                </div>
                                            </Button>
                                            :
                                            <Button className='pm-download-btn show-only-mobile-disp-flex d-flex align-items-center jp-cp justify-content-center w-100' onClick={() => copyToClipboard()} disabled={!posterURL}>
                                                <CopyOutlined className='pm-orange-icon' />
                                                <div className='pm-download-btn-txt'>
                                                    Copy Link
                                                </div>
                                            </Button>
                                    }
                                    <div className='pm-share-steps'>
                                        <div className='pm-poster-created'>
                                            <CheckCircleFilled className='pm-green-icon'/>
                                            <span className='pm-poster-created-txt'>Poster Created</span>
                                        </div>
                                        <ol className='pm-share-steps-list'>
                                            <li className='pm-share-steps-list-item'>Share the link with leads</li>
                                            <li className='pm-share-steps-list-item'>Leads can view the poster and register with their <span className='pm-share-step-orange-txt'>name</span> and <span className='pm-share-step-orange-txt'>phone number</span></li>
                                        </ol>
                                    </div>
                                </div>

                                :
                                <div className=''>
                                    <div className='pm-select-container d-flex'>
                                        <span className='pm-select-label'>
                                            Client *
                                        </span>
                                        <div className='pm-city-select pm-select'>
                                            <Select
                                                placeholder="Select Client"
                                                style={{ width: '100%' }}
                                                onChange={(client) => { handleClientChange(client) }}
                                                value={selectedClient && selectedClient.label ? selectedClient.label : ""}
                                                disabled={true}
                                            >
                                                {
                                                    clientLoading ? (
                                                        <Option value="loading" disabled>
                                                            <Spin size="small" />
                                                            {' Loading...'}
                                                        </Option>
                                                    ) : (
                                                        copyCityFilterList?.map((item, index) => {
                                                            return <Option value={item.label} key={index}>{item.label}</Option>
                                                        }))
                                                }
                                            </Select>
                                        </div>
                                    </div>

                                    <div className='pm-select-container d-flex'>
                                        <span className='pm-select-label'>
                                            City *
                                        </span>
                                        <div className='pm-city-select pm-select'>
                                            <Select
                                                placeholder={"Search City"}
                                                style={{ width: '100%' }}
                                                className='w-100'
                                                onChange={(city) => { setSelectedCity(city) }}
                                                value={selectedCity ? selectedCity : ""}
                                                dropdownRender={(menu) => (
                                                    <div className='w-100'>
                                                        <Input
                                                            prefix={<SearchOutlined style={{ color: "rgba(0, 0, 0, 0.45)" }} />}
                                                            value={citySearch}
                                                            onChange={onCityInpChange}
                                                            placeholder="Search for a city"
                                                            style={{ marginBottom: 8, maxWidth: "90%" }}
                                                            allowClear
                                                        />
                                                        <div style={{ maxHeight: 200, overflowY: 'auto' }}>
                                                            {menu}
                                                        </div>
                                                    </div>
                                                )}
                                            >
                                                {cityLoading ? (
                                                    <Option value="loading" disabled>
                                                        <Spin size="small" />
                                                        {' Loading...'}
                                                    </Option>
                                                ) : (
                                                    copyCityFilterList?.map((item, index) => {
                                                        return <Option value={item.city} key={index}>{item.city}</Option>
                                                    }))}
                                            </Select>
                                            {/* <Select
                                                placeholder="Select City"
                                                style={{ width: '100%' }}
                                                onChange={(city) => {
                                                    setSelectedCity(city)
                                                    // handleCityChange(city)
                                                }}
                                                value={selectedCity ? selectedCity : ""}
                                            >
                                                {
                                                    cityLoading ? (
                                                        <Option value="loading" disabled>
                                                            <Spin size="small" />
                                                            {' Loading...'}
                                                        </Option>
                                                    ) : (
                                                        cityList?.map((item, index) => {
                                                            return <Option value={item.city} key={index}>{item.city}</Option>
                                                        }))
                                                }
                                            </Select> */}
                                        </div>
                                    </div>

                                    <div className='pm-select-container d-flex'>
                                        <span className='pm-select-label'>
                                            Language *
                                        </span>
                                        <div className='pm-city-select pm-select'>
                                            <Select
                                                placeholder="Select Language"
                                                style={{ width: '100%' }}
                                                onChange={(lang) => { handleLanguageChange(lang) }}
                                                value={selectedLanguage}
                                            >
                                                {languageList?.map((item, index) => {
                                                    return <Option value={item} key={index}>{item}</Option>
                                                })}
                                            </Select>
                                        </div>
                                    </div>

                                    <div className='pm-view-hide-more-container'>
                                        {
                                            !showMore ?
                                                <div className='pm-view-more pm-view-hide-more d-flex align-items-center jp-cp' onClick={() => setShowMore(true)}>
                                                    <RightOutlined className='pm-view-more-icon' />
                                                    <span className='pm-view-more-txt'>
                                                        &nbsp;View More Options
                                                    </span>
                                                </div>
                                                :
                                                <div className='pm-hide-more pm-view-hide-more d-flex align-items-center jp-cp' onClick={() => setShowMore(false)}>
                                                    <DownOutlined className='pm-view-more-icon' />
                                                    <span className='pm-view-more-txt'>
                                                        &nbsp;Hide More Options
                                                    </span>
                                                </div>
                                        }

                                        {
                                            showMore
                                                ?
                                                <div className=''>
                                                    <div className='pm-select-container d-flex'>
                                                        <span className='pm-select-label'>
                                                            Name
                                                        </span>
                                                        <div className='pm-city-select pm-select'>
                                                            <AutoComplete
                                                                options={teamList}
                                                                style={{ width: '100%' }}
                                                                onSelect={handleContactNameChange}
                                                                onSearch={(text) => teamList.filter(member => member.label.toLowerCase().includes(text.toLowerCase()))}
                                                                placeholder="Select Member"
                                                                value={contactName}
                                                                optionFilterProp="children"
                                                                filterOption={filterMitraTeamMembers}
                                                                onChange={(inp) => onContactNameChange(inp)}
                                                                className='pm-contact-autocomplete'
                                                            />
                                                            {/* <Select
                                                                placeholder="Select Member"
                                                                style={{ width: '100%' }}
                                                                onChange={(lang) => { handleContactNameChange(lang) }}
                                                                value={contactName}
                                                                options={teamList}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={filterMitraTeamMembers}
                                                            /> */}
                                                        </div>
                                                    </div>

                                                    <div className='pm-input-container d-flex align-items-center'>
                                                        <span className='pm-input-label'>
                                                            Number
                                                        </span>
                                                        <div className='pm-number-input pm-input'>
                                                            <Input defaultValue={""} value={contactNumber} className='w-100' onChange={(inp) => updateContactNumber(inp)} />
                                                        </div>
                                                    </div>
                                                    <div className='pm-input-container d-flex align-items-center'>
                                                        <span className='pm-input-label'>
                                                            Salary
                                                        </span>
                                                        <div className='pm-number-input pm-input'>
                                                            <Input defaultValue={""} value={salary} className='w-100' onChange={(inp) => updateSalary(inp)} />
                                                        </div>
                                                    </div>

                                                    {

                                                        !contactLogo || (contactLogo && contactLogo.length == 0) ?
                                                            <Upload {...props} className='pm-logo-upload d-flex w-100 justify-content-start'>
                                                                <Button className='pm-logo-upload-btn d-flex w-100 align-items-center justify-content-center'>
                                                                    <UploadOutlined className='pm-logo-upload-icon' />
                                                                    <div className='pm-logo-upload-txt'>
                                                                        Upload Logo
                                                                    </div>
                                                                </Button>
                                                            </Upload>
                                                            :
                                                            <div className='pm-uploaded-logo-container d-flex justify-content-between'>
                                                                <div className='pm-uploaded-logo d-flex align-items-center justify-content-center'>
                                                                    <PaperClipOutlined className='pm-uploaded-logo-icon' />
                                                                    <span className='pm-uploaded-logo-file-name-txt'>
                                                                        {contactLogo && contactLogo.name ? contactLogo.name : ""}
                                                                    </span>
                                                                </div>

                                                                <div className='pm-uploaded-logo-delete d-flex align-items-center justify-content-center jp-cp' onClick={() => setContactLogo([])}>
                                                                    <DeleteOutlined className='pm-uploaded-logo-delete-icon' />
                                                                </div>
                                                            </div>
                                                    }

                                                    <div className='pm-logo-instructions-txt'>
                                                        Logo should be square. File should be in .png / .jpg format and less than 1MB.
                                                    </div>

                                                </div>
                                                :
                                                null
                                        }


                                    </div>
                                </div>
                        }
                    </div>
                    <div className='pm-poster-img-section'>
                        <img src={posterURL ? posterURL : "/image/defaultPoster.webp"} alt="job_poster" className='pm-poster-img' />
                        {loading && (
                            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                                <Spin size="large" />
                            </div>
                        )}
                    </div>
                </div>
                <div className='pm-footer d-flex justify-content-between'>
                    {
                        sharePosterClicked ?
                            <div className='d-flex justify-content-between align-items-center w-100'>
                                <div className='d-flex align-items-center'>
                                    <span className='pm-share-on-txt'>
                                        Share on
                                    </span>
                                    <img src="/image/whatsapp_icon.svg" alt="whatsapp_icon" className='pm-share-option-icon jp-cp' onClick={() => shareOnSocialMedia("whatsapp")} />
                                    <img src="/image/fb_icon.svg" alt="fb_icon" className='pm-share-option-icon jp-cp' onClick={() => shareOnSocialMedia("facebook")} />
                                    <img src="/image/telegram_icon.svg" alt="telegram_icon" className='pm-share-option-icon jp-cp' onClick={() => shareOnSocialMedia("telegram")} />
                                    <DownloadOutlined className='pm-share-option-icon pm-share-download jp-cp' onClick={() => onDownloadPosterClicked()} />
                                </div>
                                {
                                    isCopyClicked ?
                                        <Button className='pm-download-btn d-flex align-items-center jp-cp justify-content-center show-only-desktop' onClick={() => copyToClipboard()} disabled={!posterURL}>
                                            <CheckOutlined className='pm-orange-icon' />
                                            <div className='pm-download-btn-txt'>
                                                Link Copied
                                            </div>
                                        </Button>
                                        :
                                        <Button className='pm-share-btn d-flex align-items-center jp-cp justify-content-center show-only-desktop' onClick={() => copyToClipboard()} disabled={!posterURL}>
                                            <ShareAltOutlined className='pm-white-icon' />
                                            <div className='pm-share-btn-txt d-flex'>
                                                Share And Get Leads
                                            </div>
                                        </Button> 
                                        // <Button className='pm-download-btn d-flex align-items-center jp-cp justify-content-center show-only-desktop' onClick={() => copyToClipboard()} disabled={!posterURL}>
                                        //     <CopyOutlined className='pm-orange-icon' />
                                        //     <div className='pm-download-btn-txt'>
                                        //         Copy Link
                                        //     </div>
                                        // </Button>
                                }
                            </div>
                            :
                            <div className='d-flex w-100' style={{justifyContent: 'flex-end'}}>
                                
                                {publishPoster ?
                                <Button className='pm-share-btn d-flex align-items-center jp-cp justify-content-center' type="primary" loading>
                                    <div className='pm-share-btn-txt d-flex'>
                                        Creating
                                    </div>
                                </Button>
                                :
                                <Button className='pm-share-btn d-flex align-items-center jp-cp justify-content-center' onClick={() => createPosterAndGetURL('', true)} disabled={(!posterURL || loading)}>
                                    <div className='pm-share-btn-txt d-flex'>
                                        Create
                                    </div>
                                </Button>
                                }
                            </div>
                    }
                </div>
            </div>
        </Modal>
    )
}

export default PosterModal; 