import React from "react";

function Dustbin({fillColor='none',height=21,width=20,strokeColor='#2D2D2D'}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
    height={height}
    fill={fillColor}
      viewBox="0 0 20 21"
    >
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M11.667 8.984v5.833M8.333 8.984v5.833m-5-9.167h13.334M15 5.65v9.834c0 .933 0 1.4-.181 1.756-.16.314-.416.569-.73.729-.356.181-.822.181-1.755.181H7.667c-.934 0-1.4 0-1.757-.181a1.666 1.666 0 01-.728-.729C5 16.884 5 16.417 5 15.484V5.65h10zm-1.667 0H6.667c0-.776 0-1.164.127-1.47.169-.41.493-.734.901-.903.307-.127.695-.127 1.472-.127h1.666c.777 0 1.165 0 1.472.127.408.17.732.494.901.902.127.307.127.695.127 1.471z"
      ></path>
    </svg>
  );
}

export default Dustbin;