import React, {useState, useEffect, useContext} from 'react';
import useGAEventsTracker from '../service/useGSEventsTracker';
import * as leadsConvertedService from '../service/leadsConvertedService';
import CONSTANTS from '../constants/constants';
import {useHistory} from 'react-router-dom';
import PageHeaderComponent from './common/PageHeader';
import { Space, Table, Tag } from 'antd';
import {AppContext} from '../App';

const DEFAULT = 'DEFAULT';

const ConvertedLeadsPage = () => {
	const {mitraReducer,mitraDispatch, setAlert} = useContext(AppContext);
	const [mitra, setMitra] = useState();
	const [isAdmin, setIsAdmin] = useState(false);
    const [dataSource, setDataSource] = useState([])
	const history = useHistory();
    const [breadcrumb] = useState([{link: '/convertedLeads', label: 'Non Unique Users Converted By Vahan'}]);
    const [columns] = useState(CONSTANTS.COLUMNS_FOR_CONVERTED_LEADS);

	useEffect(() => {   
		console.log("mitraReducer");
		if (mitraReducer?.mitraInfo) {
			setIsAdmin(mitraReducer?.mitraInfo?.role === 'admin' ? true : false)
			setMitra(mitraReducer?.mitraInfo ? mitraReducer?.mitraInfo : {})
			const getLeadsConvertedByVahan = async (e) => {
				try {
					leadsConvertedService.getLeadsConvertedByVahan().then((result) => {
						if (result && result.data) {
                            let tableDataSource = [];
                            result.data.forEach((element, index) => {
                                tableDataSource.push(
                                    {
                                        key: element.id,
                                        candidateName: element.name,
                                        candidatePhoneNumber: element.phoneNumber,
                                        client: element.companyName,
                                        first_date_of_work: element.first_date_of_work,
                                        referralDate: element.referralDate,
                                        tenth_date_of_work: element.tenth_date_of_work,
                                        tripCount: element.tripCount,
                                        twentyFifth_date_of_work: element.twentyFifth_date_of_work
                                    }
                                )
						    })
                            setDataSource(tableDataSource)
                        }
					});
				} catch (error) {
					console.log(error);
				}
			};
			getLeadsConvertedByVahan();
		}
	}, [mitraReducer]);

	useEffect(() => {
		history.push('/converted-leads')
	}, []);

	return (
		<>
            <div className='trainingHubComponent mainContentZ'>
                <div className="containerTop">
                    <PageHeaderComponent backBtnBool={true} breadcrumb={breadcrumb}/>
                </div>
                <Table dataSource={dataSource} columns={columns} />;
            </div>
		</>
	);
};

export default ConvertedLeadsPage;
