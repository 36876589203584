import React, { forwardRef, useImperativeHandle, useState, useEffect, useContext } from 'react'
import { Button, Upload, message, Input, notification, Modal, Divider } from 'antd';
import { LinkOutlined, PictureOutlined, EditOutlined, CrownOutlined, UploadOutlined, ExclamationCircleOutlined, CopyOutlined, CheckCircleOutlined, CloseOutlined } from '@ant-design/icons';
import './BrandingSectionContainerStyle.scss'
import '../../../../../../css/globalStyles.scss'
import { getVLBrandDetails, saveMlLogoAndBrandName } from '../../../../../../service/accountService';
import { uploadImageToS3 } from '../../../../../../service/additionalInfoService';
import { AppContext } from '../../../../../../App';
import { useHistory, useLocation, useNavigate } from 'react-router-dom/cjs/react-router-dom';
import UnsavedChangesModal from '../../../common/UnsavedChangesModal';
import { trackEvent } from '../../../../../../service/engagementMonitoringService';
import CONSTANTS from '../../../../../../constants/constants';

const BrandingSectionContainer = forwardRef(({ onEditClick, isEditing, apiCalling, cancelEditing }, ref) => {
    const [logo, setLogo] = useState("");
    const [mlName, setMLName] = useState("");
    const [loginLink, setLoginLink] = useState("");
    const key = `open${Date.now()}`;
    const placement = 'topRight';
    const { mitraReducer } = useContext(AppContext);
    const location = useLocation()
    const history = useHistory()
    const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false)
    const [goToRoute, setGoToRoute] = useState(false)
    const [proceedWithUnsavedChanges, setProceedWithUnsavedChanges] = useState(true)
    const [unblock, setUnblock] = useState(null);

    const props = {
        name: 'image',
        multiple: false,
        beforeUpload(file) {
            return false;
        },
        customRequest: async ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        },
        onChange(fileList) {
            handleLogoUpload(fileList);
        },
        maxCount: 1,
        accept: 'image/*',
    };

    useEffect(() => {
        const unblockFn = history.block((location, action) => {
            // Show unsaved changes popup if editing = true and user is trying to navigate away
            if (!proceedWithUnsavedChanges) {
                setGoToRoute(location.pathname);
                setShowUnsavedChangesModal(true)
                return false
            }
        });

        setUnblock(() => unblockFn);

        return () => unblockFn();

    }, [proceedWithUnsavedChanges])

    useEffect(() => {
        setProceedWithUnsavedChanges(!isEditing)
    }, [isEditing])

    useEffect(() => {
        if (mitraReducer?.mitraInfo) {
            const queryParams = {
                mitraId: mitraReducer?.mitraInfo?.id
            }
            getVLBrandDetails(queryParams).then((vlDetailsRes) => {
                if (vlDetailsRes.data) {
                    if (vlDetailsRes.data.data.brandName) {
                        setMLName(vlDetailsRes.data.data.brandName)
                        createLoginLink(vlDetailsRes.data.data.brandName)
                    }
                    if (vlDetailsRes.data.data.logo) {
                        setLogo(vlDetailsRes.data.data.logo)
                    }
                }
            })
        }
    }, [mitraReducer])

    const handleLogoUpload = (imageFile) => {
        if (imageFile?.file) {
            uploadImageToS3(imageFile.file).then((imageRes) => {
                if (imageRes?.data?.imageUrl) {
                    // message.success(`${imageFile.file.name} added. Click "Save" to save changes.`);
                    message.success(`Logo added. Click "Save" to save changes.`);
                    trackEvent('uploaded_custom_logo', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                    setLogo(imageRes?.data?.imageUrl)
                }
            })
                .catch((err) => {
                    message.error(`We're having trouble uploading that file, please try again in sometime.`);
                })
        }
    }

    const onBrandNameChange = (inp) => {
        // Don't allow 2 consecutive hyphens at the end
        if (mlName && mlName.charAt(mlName.length - 1) == "-" && mlName.charAt(mlName.length - 2) == "-") {
            setMLName(mlName.replace(/-+$/, ''))
        }
        if (inp?.target?.value) {
            // Sanitize input string
            let sanitized = inp.target.value.replace(/[^a-zA-Z0-9\s-]/g, '-');

            // Replace all spaces with hyphens
            sanitized = sanitized.replace(/\s+/g, '-');

            // Ensure no more than one hyphen at the end
            sanitized = sanitized.replace(/-+$/, '-');
            setMLName(sanitized)
            createLoginLink(sanitized)
        }
        else {
            setMLName("")
            setLoginLink("");
        }
    }

    const createLoginLink = (inp) => {
        // Remove all special characters and numbers
        let result = inp.replace(/[^a-zA-Z0-9-]/g, '');
        // Convert all letters to lower case
        result = result.toLowerCase();
        // Replace spaces with hyphens
        result = result.replace(/\s+/g, '-');
        setLoginLink(result + ".gigshare.in");
    }

    // Can be refactored to not use DOM
    const copyToClipboard = () => {
        if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(loginLink)
                .then(() => {
                    message.success("Login link copied!")
                })
                .catch(err => {
                    console.error('Error copying to clipboard:', err);
                    message.error('Failed to copy link');
                });
        }
        else {
            var textArea = document.createElement("textarea");
            textArea.value = loginLink;

            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);

            textArea.focus();
            textArea.select();

            try {
                var successful = document.execCommand('copy');
                trackEvent('copied_login_link', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                successful ? message.success("Login link copied!") : message.error('Failed to copy link');;
            } catch (err) {
                console.error('Error copying to clipboard:', err);
                message.error('Failed to copy link');
            }
            document.body.removeChild(textArea);
        }
    };

    const areDetailsValid = () => {
        let isValid = true

        // Check if name (with no hyphen at end) has been entered
        if ((!logo && !mlName) || (mlName && mlName.charAt(mlName.length - 1) == "-")) {
            isValid = false
        }
        return isValid;
    }

    const onSubmitBrandingDetails = () => {
        if (areDetailsValid()) {
            const mlBrandingData = {
                brandName: mlName,
                logo: logo,
                brandLink: loginLink
            }

            // To show loader on button
            apiCalling("started");
            saveMlLogoAndBrandName(mlBrandingData).then((mlBrandingDataRes) => {
                if (mlBrandingDataRes?.data?.accountDetails?.success) {
                    // To hide loader on button
                    apiCalling("ended")
                    notification.open({
                        message: "Brand details updated successfully",
                        key,
                        duration: 5,
                        placement,
                        icon: <CheckCircleOutlined className='green-positive' />,
                    });
                }
                else {
                    notification.open({
                        message: `${mlBrandingDataRes?.data?.accountDetails?.message}`,
                        key,
                        duration: 5,
                        placement,
                        icon: <ExclamationCircleOutlined className='green-positive' />,
                    });
                    apiCalling("ended")
                }
            })
        }
        else {
            if (!logo) {
                notification.open({
                    message: "Please upload a logo",
                    key,
                    duration: 5,
                    placement,
                    icon: <ExclamationCircleOutlined className='red-negative' />,
                });
            }
            if (!mlName) {
                notification.open({
                    message: "Please add your brand name",
                    key,
                    duration: 5,
                    placement,
                    icon: <ExclamationCircleOutlined className='red-negative' />,
                });
            }
        }
    }

    const proceedWithoutSaving = () => {
        if (unblock) unblock();
        cancelEditing()
        setShowUnsavedChangesModal(false)
        setProceedWithUnsavedChanges(true)
        if (goToRoute) {
            history.push({ pathname: goToRoute });
        }
    }

    useImperativeHandle(ref, () => ({
        onSubmitBrandingDetails
    }));

    return (
        <div className='branding-section-parent'>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='bs-header d-flex align-items-start'>
                    <div className='d-flex align-items-center'>
                        <CrownOutlined className='bs-header-icon' />
                        <span className='bs-header-txt'>
                            Branding
                        </span>
                    </div>
                    <span className='bs-header-sub-txt semibold14-22 black45'>
                        Personalize the portal for your team
                    </span>
                </div>

                {
                    !isEditing ?
                        <div className='bs-edit-btn-container d-flex justify-content-center align-items-center'>
                            <Button onClick={onEditClick} className='vl-secondary-btn bs-edit-btn w-100 d-flex align-items-center justify-content-center'>
                                <EditOutlined className='bs-edit-btn-icon primary-orange2' />
                                <span className='bold14-22 primary-orange2'>
                                    Edit
                                </span>
                            </Button>
                        </div>
                        :
                        null
                }

            </div>

            <div className='bs-body d-flex'>
                <div className='bs-logo-upload-and-txt-container d-flex'>
                    <div className='bs-logo-upload-parent'>
                        {
                            logo ?
                                <div className='bs-logo-img-container'>
                                    <img src={logo} alt="" className='bs-logo-img' />
                                </div>
                                :
                                <Upload {...props} className={`${isEditing ? "bs-logo-upload-pre" : ""}`} disabled={!isEditing}>
                                    {
                                        <PictureOutlined className='bs-logo-upload-bg w-100' />
                                    }
                                </Upload>

                        }
                        <div className='bs-logo-upload-section'>
                            <Upload {...props} className={`${isEditing ? "bs-logo-upload-post" : ""}`} disabled={!isEditing}>
                                {
                                    isEditing ?
                                        <UploadOutlined className="bs-logo-upload-icon" />
                                        :
                                        null
                                }
                            </Upload>
                        </div>
                    </div>
                    <span className='bs-logo-upload-txt regular14-22'>Logo</span>
                </div>

                <div className='w-100'>
                    <div className='bs-ml-input-container bs-ml-name-inp d-flex'>
                        <span className='bs-ml-input-label semibold14-22 black65'>
                            Name
                        </span>
                        <Input
                            disabled={!isEditing}
                            className='bs-ml-input'
                            value={mlName}
                            onChange={onBrandNameChange}
                            placeholder=""
                            allowClear
                        />
                    </div>

                    <div className='bs-ml-input-container bs-ml-link-inp-container d-flex'>
                        <div className='d-flex bs-link-inp-container bs-ml-link-inp'>
                            <span className='bs-ml-input-label semibold14-22 black65'>
                                Login Link
                            </span>
                            <Input
                                disabled
                                className='bs-ml-input bs-ml-input-link'
                                value={loginLink}
                                placeholder=""
                                style={{ maxWidth: "90%" }}
                                allowClear
                                prefix={<LinkOutlined />}
                            />
                        </div>
                        <div className='bs-copy-link-btn-container d-flex justify-content-center align-items-center'>
                            <Button className='vl-secondary-btn bs-copy-link-btn w-100 d-flex align-items-center justify-content-center' onClick={copyToClipboard} disabled={!loginLink}>
                                <CopyOutlined className='bs-copy-link-btn-icon primary-orange2' />
                                <span className='bold14-22 primary-orange2 d-flex'>
                                    Copy<span className='show-only-desktop-no-font bold14-22 primary-orange2'>&nbsp;Link</span>
                                </span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <UnsavedChangesModal isModalOpen={showUnsavedChangesModal} closeModal={() => setShowUnsavedChangesModal(false)} onStayClicked={() => setShowUnsavedChangesModal(false)} onLeaveClicked={() => proceedWithoutSaving()} />
        </div>
    )
})

export default BrandingSectionContainer; 