import React from "react";
import { Card, Timeline, Typography, Collapse, Button } from "antd";
import {CaretUpOutlined} from '@ant-design/icons'
import './ProgressBar.scss'
import LevelDetail from "./LevelDetail";
import { trackEventForProgressBar } from "../../service/progressBarService";
import { trackEvent } from "../../service/engagementMonitoringService";
import CONSTANTS from "../../constants/constants";

const { Text }    = Typography;
const { Panel } = Collapse;

const ProgressBar = ({setProgressBarState}) => {

    const getStarted = () => {
        trackEvent('Clicked_get_started_progress_bar', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        trackEventForProgressBar('get_started').then( response => {
            setProgressBarState('initiaited');
        }).catch(error=> {
            console.log(error);
        });
    };

    return (
        <Card className='progress-bar'>
            <div className='progress-bar-header'>Your Vahan Leader Journey</div>
            <div className="progress-bar-main-content">
                <div className='progress-bar-images'>
                    <img src='/image/progress-bar-initial.png' alt='starting-point' />
                    <div className="road current">
                        <div className="current-position-pointer">
                            <CaretUpOutlined style={{marginTop:'5px'}}/>
                            <Text className="pointer-text">You</Text>
                        </div>
                        </div>
                    <img src='/image/progress-bar-bronze.png' alt='starting-point' />
                    <div className="road"></div>
                    <img src='/image/progress-bar-silver.png' alt='starting-point' />
                </div>
                <div className='progress-bar-content'>
                <Timeline>
                    <Timeline.Item>
                        <div style={{display:"flex", flexDirection:'column', flexGrow:'1', flexShrink:'0'}}>
                            <strong className='d-flex'>Bronze Vahan Leader</strong>
                            <Text className='d-flex'>Earned on 1st activation</Text>
                            {/* <Text style={{display:'flex',justifyContent:'flex-start', marginTop:'1.5em', textDecoration:'underline',color:'red'}}><PlayCircleFilled />Let&apos;s get started</Text> */}
                            {/* <Collapse>
                                <Panel style={{color:'red'}} header="Let&apos;s get started" key="1">
                                    <LevelDetail />
                                </Panel>
                            </Collapse> */}
                        </div>
                    </Timeline.Item>
                    <Timeline.Item color="gray" style={{opacity: '0.5'}}>
                        <div style={{display:"flex", flexDirection:'column'}}>
                            <strong className='d-flex'>Silver Vahan Leader</strong>
                            <Text className='d-flex'>Earned on 50 Placements/Month</Text>
                        </div>
                    </Timeline.Item>
                    <Timeline.Item color="gray" style={{opacity: '0.5'}}>
                        <div style={{display:"flex", flexDirection:'column'}}>
                            <strong className='d-flex'>Gold Vahan Leader</strong>
                            <Text className='d-flex'>Earned on 200 Placements/Month</Text>
                        </div>
                    </Timeline.Item>
                </Timeline>
                </div>
            </div>
            <Button className="progress-bar-get-started" onClick={getStarted}>Get Started</Button>
        </Card>
    )
};

export default ProgressBar;
