import { Skeleton } from 'antd'
import React from 'react'

function StatisticCardSkeleton() {
  return (
    <>
        <div className="shaded-tile-container shadow-highlighter">
            <div className="single-tile">
                <div className="icon-and-percentage-container d-flex flex-row justify-content-between w-100">
                    <Skeleton.Button active size={'default'} shape={'default'} block={false} />
                </div>
                <Skeleton.Input active size={'large'} />
                <span className='tile-title-text'>
                    <Skeleton.Input active size={'large'} />
                </span>
            </div>
        </div>
        <div className="shaded-tile-container shadow-highlighter">
            <div className="single-tile">
                <div className="icon-and-percentage-container d-flex flex-row justify-content-between w-100">
                    <Skeleton.Button active size={'default'} shape={'default'} block={false} />
                </div>
                <Skeleton.Input active size={'large'} />
                <span className='tile-title-text'>
                    <Skeleton.Input active size={'large'} />
                </span>
            </div>
        </div>
        <div className="shaded-tile-container shadow-highlighter">
            <div className="single-tile">
                <div className="icon-and-percentage-container d-flex flex-row justify-content-between w-100">
                    <Skeleton.Button active size={'default'} shape={'default'} block={false} />
                </div>
                <Skeleton.Input active size={'large'} />
                <span className='tile-title-text'>
                    <Skeleton.Input active size={'large'} />
                </span>
            </div>
        </div>
    </>
  )
}

export default StatisticCardSkeleton