import React from 'react';
import './FilterSideSheet.scss';
import { Button } from 'antd';
import { DeleteOutlined, ArrowRightOutlined, FilterOutlined, CloseOutlined } from '@ant-design/icons';

const FilterSideSheetComponent = (props) => {
    const { onDismiss, removeAccess, addAccess, containerStyle, submit, sidername, clearAll, hideDismissIcon, hideBottomButtons = false } = props;

    return (
        <>
            <div className='parentContainer'>
                <div onClick={onDismiss} className='emptyContainer'>
                </div>
                <div style={containerStyle} className='containerSS'>
                    <div className='navigator'>
                        <div className='nav1'>
                            <FilterOutlined className='fs-filter-icon' />
                            <div className='sidername'>{sidername}</div>
                        </div>
                        {!hideDismissIcon && <div className='fs-header-close jp-cp d-flex' onClick={onDismiss}>
                            <CloseOutlined className='fs-header-close-icon' />
                        </div>}
                    </div>
                    <div className='childContainer'>
                        {props.children}
                    </div>
                    {
                        hideBottomButtons ? null : (
                            <div className='btns'>
                                {
                                    clearAll &&
                                    <Button style={{ marginRight: 5 }} disabled={clearAll.disabled} className='commonlayoutbtn fs-clear-btn d-flex align-items-center' onClick={() => clearAll.onClick('clearAll')}>
                                        <DeleteOutlined className='fs-delete-icon' />
                                        <span className='fs-btn-txt fs-btn-txt-orange'>Clear All</span>
                                    </Button>
                                }
                                {
                                    submit &&
                                    <Button onClick={() => { submit.onClick(); onDismiss() }} disabled={submit.disabled} className={submit.disabled ? "disabledSubmit d-flex align-items-center" : "submit d-flex align-items-center"} type="primary">
                                        <span className='fs-btn-txt fs-btn-txt-white'>Apply</span>
                                        <ArrowRightOutlined className='fs-apply-icon' />
                                    </Button>
                                }
                            </div>
                        )
                    }

                </div>
            </div>

        </>
    );
};

export default FilterSideSheetComponent;
