import * as React from "react";
const PhoneCrossIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={13}
    viewBox="0 0 12 13"
    fill="none"
    {...props}
  >
    <path
      d="M10.985 9.665C10.985 9.845 10.945 10.03 10.86 10.21C10.775 10.39 10.665 10.56 10.52 10.72C10.275 10.99 10.005 11.185 9.7 11.31C9.4 11.435 9.075 11.5 8.725 11.5C8.215 11.5 7.67 11.38 7.095 11.135C6.52 10.89 5.945 10.56 5.375 10.145C4.8 9.725 4.255 9.26 3.735 8.745C3.22 8.225 2.755 7.68 2.34 7.11C1.93 6.54 1.6 5.97 1.36 5.405C1.12 4.835 1 4.29 1 3.77C1 3.43 1.06 3.105 1.18 2.805C1.3 2.5 1.49 2.22 1.755 1.97C2.075 1.655 2.425 1.5 2.795 1.5C2.935 1.5 3.075 1.53 3.2 1.59C3.33 1.65 3.445 1.74 3.535 1.87L4.695 3.505C4.785 3.63 4.85 3.745 4.895 3.855C4.94 3.96 4.965 4.065 4.965 4.16C4.965 4.28 4.93 4.4 4.86 4.515C4.795 4.63 4.7 4.75 4.58 4.87L4.2 5.265C4.145 5.32 4.12 5.385 4.12 5.465C4.12 5.505 4.125 5.54 4.135 5.58C4.15 5.62 4.165 5.65 4.175 5.68C4.265 5.845 4.42 6.06 4.64 6.32C4.865 6.58 5.105 6.845 5.365 7.11C5.635 7.375 5.895 7.62 6.16 7.845C6.42 8.065 6.635 8.215 6.805 8.305C6.83 8.315 6.86 8.33 6.895 8.345C6.935 8.36 6.975 8.365 7.02 8.365C7.105 8.365 7.17 8.335 7.225 8.28L7.605 7.905C7.73 7.78 7.85 7.685 7.965 7.625C8.08 7.555 8.195 7.52 8.32 7.52C8.415 7.52 8.515 7.54 8.625 7.585C8.735 7.63 8.85 7.695 8.975 7.78L10.63 8.955C10.76 9.045 10.85 9.15 10.905 9.275C10.955 9.4 10.985 9.525 10.985 9.665Z"
      stroke="black"
      strokeMiterlimit={10}
    />
    <path
      d="M8.11499 4.38523L9.88499 2.61523"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.88499 4.38523L8.11499 2.61523"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default PhoneCrossIcon;
