import * as React from "react"
const DownloadIcon = ({fillColor='none',height=17,width=17,strokeColor='#1D5FFF'}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fillColor}
    viewBox="0 0 17 17"
  >
    <path
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.5 1.69v4.25l1.417-1.417M8.5 5.94 7.083 4.523"
    />
    <path
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M4.958 8.773c-2.833 0-2.833 1.268-2.833 2.834v.708c0 1.955 0 3.542 3.542 3.542h5.666c2.834 0 3.542-1.587 3.542-3.542v-.708c0-1.566 0-2.834-2.833-2.834-.709 0-.907.15-1.275.425l-.723.765a2.124 2.124 0 0 1-3.095 0l-.716-.765c-.368-.276-.566-.425-1.275-.425ZM3.542 8.773V5.94c0-1.424 0-2.6 2.125-2.805M13.458 8.773V5.94c0-1.424 0-2.6-2.125-2.805"
    />
  </svg>
)
export default DownloadIcon
