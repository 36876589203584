import React from "react";

function TeamMemberAddIcon({fillColor='none',height=20,width=21,strokeColor="#252526"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
    height={height}
    fill={fillColor}
      viewBox="0 0 21 20"
    >
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10.05 11.628a2.342 2.342 0 100-4.684 2.342 2.342 0 000 4.684zM14.375 17.02c0-1.942-1.933-3.526-4.325-3.526-2.392 0-4.325 1.575-4.325 3.525"
      ></path>
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M18 10.602a7.913 7.913 0 01-7.917 7.917 7.913 7.913 0 01-7.916-7.917 7.913 7.913 0 017.916-7.916c1.092 0 2.134.216 3.084.616A3.379 3.379 0 0013 4.352c0 .625.175 1.217.483 1.717.167.283.384.542.634.758a3.268 3.268 0 003.258.684c.4.95.625 2 .625 3.091z"
      ></path>
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M19.667 4.352c0 .267-.034.525-.1.775a3.112 3.112 0 01-.384.942c-.4.675-1.041 1.2-1.808 1.441a3.267 3.267 0 01-3.258-.683 3.07 3.07 0 01-.634-.758A3.268 3.268 0 0113 4.352c0-.367.058-.717.167-1.05a3.329 3.329 0 013.167-2.283c.983 0 1.874.425 2.474 1.108a3.32 3.32 0 01.859 2.225zM17.575 4.336h-2.483M16.333 3.12v2.49"
      ></path>
    </svg>
  );
}

export default TeamMemberAddIcon;