import { Divider, Dropdown, Menu, Space, Empty, Pagination } from 'antd'
import React, { useEffect, useState } from 'react'
import {DownOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'
import PerfDashSingleMemberCard from './PerfDashSingleMemberCard';
import { getFilterListForTargetFeature, getMitraMilestoneCount, getPerformanceDashboardTabDetails } from '../../service/reportService';
import NewLoader from '../common/NewLoader/NewLoader';
import { getLast6MonthsData } from './../../utils/commonFunction'
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';

function MemberLeaderboardTabContents() {

    const [memberLeaderboardDetails, setMemberLeaderboardDetails] = useState();
    const [memberExpandedDetails, setMemberExpandedDetails] = useState();
    const [teamSelectionOptions, setTeamSelectionOptions] = useState([
        {
            key: 'targetAchieved',
            label: 'Target Achieved',
            type: 'team'
        },
        {
            key: 'role desc',
            label: 'Role Desc',
            type: 'team'
        },
        {
            key: 'role asc',
            label: 'Role Asc',
            type: 'team'
        }
    ]);

    const [monthSelectionOptions, setMonthSelectionOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const [slicedData, setSlicedData] = useState();
    const [selectedTeam, setSelectedTeam] = useState('Target Achieved');
    const [selectedMonth, setSelectedMonth] = useState();
    const [noDataFound, setNoDataFound] = useState(false);
    const trackMoengageEvent = (event,obj) => {
		trackEvent(event, obj || {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
	}

    const getMemberLeaderboardDetails = (startDate, endDate, sortBy='targetAchieved') => {
        setLoading(true);
        setNoDataFound(false);
        const payload = {
            tab: 'teamMember',
            startDate: startDate,
            endDate: endDate,
            sortBy: sortBy,
        }

        getPerformanceDashboardTabDetails(payload)
        .then(res=> {
            if(res?.data?.status) {
                setLoading(false);
                if(res?.data?.data) {
                    setMemberLeaderboardDetails(res?.data?.data);
                } else {
                    setNoDataFound(true);
                }
            }
        }).catch(err=> {
            console.log(err);
        })
    }

    useEffect(() => {
        const months = getLast6MonthsData();
        if(months) {
            getMemberLeaderboardDetails(months[5].startDate, months[5].endDate);
            setSelectedMonth(months[5].label)
            setStartDate(months[5].startDate)
            setEndDate(months[5].endDate)
            setMonthSelectionOptions(months);
        }
    }, []);

    useEffect(() => {
        setSlicedData(memberLeaderboardDetails?.slice(startIndex, startIndex + itemsPerPage));
    }, [memberLeaderboardDetails, startIndex]);


    const onDropdownChange = (e, type) => {
        if(type === 'month') {
            trackMoengageEvent('month-filter-changed-member-leaderboard-tab', {month: e?.label});
            setStartDate(e.startDate);
            setEndDate(e.endDate);
            setSelectedMonth(e.label);
            getMemberLeaderboardDetails(e.startDate, e.endDate);
        } else if(type === 'sortBy') {
            trackMoengageEvent('sort-by-filter-changed-member-leaderboard-tab', {sortBy: e?.label});
            if(e.label === 'Role Desc') {
                setSelectedTeam(
                    <div style={{gap: '4px'}} className='d-flex align-items-center'>
                        <ArrowDownOutlined /> Roles
                    </div>
                );
            } else if(e.label === 'Role Asc') {
                setSelectedTeam(
                    <div style={{gap: '4px'}} className='d-flex align-items-center'>
                        <ArrowUpOutlined /> Roles
                    </div>
                );
            } else {
                setSelectedTeam(e.label);
            }
            getMemberLeaderboardDetails(startDate, endDate, e.key);
        }
    }

    const monthMenu = (
        <Menu>
            {
                monthSelectionOptions &&
                monthSelectionOptions.map((month, idx) => {
                    return (
                        <>
                        <Menu.Item  onClick={()=>onDropdownChange(month,'month')} key={month} style={{ padding: '10px 20px' }}>{month.label}</Menu.Item>
                        {
                           monthSelectionOptions.length - 1 === idx ? null: <Divider style={{margin: '0px'}}/>
                        }
                        </>
                    )
                })
            }
        </Menu>
    );

    const teamMenu = (
        <Menu>
            {
                teamSelectionOptions.map((team, idx) => {
                    return (
                        <>
                        <Menu.Item onClick={()=>onDropdownChange(team,'sortBy')} key={team.key} style={{ padding: '10px 20px' }}>
                            {team.label === 'Role Desc' ?
                                <div style={{gap: '4px'}} className='d-flex align-items-center'>
                                    <ArrowDownOutlined /> Roles
                                </div>
                            : team.label === 'Role Asc' ?
                                <div style={{gap: '4px'}} className='d-flex align-items-center'>
                                    <ArrowUpOutlined /> Roles
                                </div>
                            : team?.label
                            }
                            
                            </Menu.Item>
                        {
                           teamSelectionOptions.length - 1 === idx ? null: <Divider style={{margin: '0px'}}/>
                        }
                        </>
                    )
                })
            }
        </Menu>
    );

    const getMemberMonthwiseDetails = (member) => {
        const payload = {
            mitraId: member?.id
        };

        getMitraMilestoneCount(payload)
        .then(res=> {
            if(res?.data?.status) {
                setMemberExpandedDetails(res?.data?.data);
            }
        }).catch(err=> {
            console.log(err);
        })
    }

   const handlePageChange = (page) => {
    trackMoengageEvent('pagination-from-member-leaderboard');
     setCurrentPage(page);
   };

    const onExpand = (id) => {
        trackMoengageEvent('expand-option-clicked-member-leaderboard', {user: id});
        setMemberExpandedDetails();
        const newData = [...memberLeaderboardDetails];
        for (let i = 0; i < newData.length; i++) {
            if(newData[i].id === id) {
                if(newData[i].isExpanded) {
                    newData[i].isExpanded = false;
                } else {
                    newData[i].isExpanded = true;
                    getMemberMonthwiseDetails(newData[i]);
                }
            } else {
                newData[i].isExpanded = false;
            }
        }
        setMemberLeaderboardDetails([...newData]);
    }
  return (
    <>
        <div className='d-flex justify-content-between flex-wrap' style={{padding: '24px' ,gap: '24px'}}>
            <div className='member-leaderboard-input-section'>
                <span>Show performance for</span>
                <Dropdown
                    trigger={['click']}
                    overlay={monthMenu}
                >
                    <div style={{cursor: 'pointer'}}>
                        <Space style={{color: '#D34612', fontWeight: '700'}}>
                            {selectedMonth}
                            <DownOutlined className='d-flex'/>
                        </Space>
                    </div>
                    
                </Dropdown>
                <span>sorted by</span>
                <Dropdown
                    trigger={['click']}
                    overlay={teamMenu}
                >
                    <div style={{cursor: 'pointer'}}>
                        <Space style={{color: '#D34612', fontWeight: '700'}}>
                            {selectedTeam}
                            <DownOutlined className='d-flex'/>
                        </Space>
                    </div>
                    
                </Dropdown>
            </div>
            { memberLeaderboardDetails?.length &&
            <Pagination
                current={currentPage}
                total={memberLeaderboardDetails.length}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                pageSize={itemsPerPage}
                onChange={handlePageChange}
                responsive={true}
                showLessItems={true}
            />
            }
        </div>
        
        <div className='member-leaderboard-card-container' style={{paddingBottom: '30px'}}>

            {
                slicedData && !loading &&
                slicedData.map((item, idx)=> (
                    <>
                    <PerfDashSingleMemberCard item={item} startDate={startDate} endDate={endDate} key={item?.id} idx={item?.id} onExpand={onExpand} memberExpandedDetails={memberExpandedDetails} memberBoard = {true}/>
                    </>
                ))
            }
            {
                loading &&
                <div style={{ height: "65vh", width: "100%" }}>
                    <NewLoader comingFromPage={'performancePage'} />
                </div>
            }
            {
                noDataFound &&
                <Empty />
            }
        </div>
    </>
  )
}

export default MemberLeaderboardTabContents