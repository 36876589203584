import { Button, Card, Space, Typography } from "antd";
import React from "react";
import './referAndEarn.scss';

const { Text } = Typography;

const ReferAndEarnCards = () => {

    const cardContents = [
        {
            id: 1,
            stepNumber: 'Step 1',
            stepDescription: 'When your friend completes one placement in first month',
            stepReward: 'You Earn ₹500',
            stepImage:'/image/refer1.png'
        },
        {
            id: 2,
            stepNumber: 'Step 2',
            stepDescription: 'When your friend completes 5 placements in first month',
            stepReward: 'You Earn ₹2,000 extra',
            stepImage:'/image/refer2.png'
        },
        {
            id: 3,
            stepNumber: 'Step 3',
            stepDescription: 'Also earn 50% of your friends first month\'s earning',
            stepReward: 'You Earn Upto ₹7,500 extra',
            stepImage:'/image/refer3.png'
        }
    ];
    
    return (
        <>
        <div className="refer-earn-card-container desktop-version">
                    {
                        cardContents.map((item) => {
                            return (
                                <Card key={item.id} bodyStyle={{padding:'16px'}} style={{marginBottom:'5px'}}>
                                    <Space direction="horizontal" size={8} style={{ width:"100%", justifyContent:'space-between'}}>
                                        <div className="card-description-container">
                                            <div className="step-number">{item.stepNumber}</div>
                                            <div className="step-description">{item.stepDescription}</div>
                                            <div className="step-reward">{item.stepReward}</div>
                                        </div>

                                        <img src={item.stepImage} alt="refer-image"></img>
                                    </Space>
                                </Card>
                            )
                        })
                    }
                </div>

                <div className="refer-earn-card-container mobile-version">
                    {
                        cardContents.map((item) => {
                            return (
                                <Card key={item.id} bodyStyle={{padding:'8px'}} style={{marginBottom:'5px'}}>
                                    <Space direction="horizontal" size={8} style={{ width:"100%", justifyContent:'space-between'}}>
                                        <div className="card-description-container">
                                            <div className="step-number">{item.stepNumber}</div>
                                            <div className="step-description">{item.stepDescription}</div>
                                        </div>

                                        <div className="step-reward">{item.stepReward}</div>
                                    </Space>
                                </Card>
                            )
                        })
                    }
                </div>
        </>
    )
};

export default ReferAndEarnCards;
