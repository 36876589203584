import React from "react";

function LeftArrow({fillColor='none',height=25,width=24,strokeColor='#2A2A2A'}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
    height={height}
    fill={fillColor}
    viewBox="0 0 24 25"
    >
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M9.57 6.43L3.5 12.5l6.07 6.07M20.5 12.5H3.67"
      ></path>
    </svg>
  );
}

export default LeftArrow;