import React, { useContext, useEffect, useState } from 'react'
import { Button, Input, Upload, notification } from 'antd'
import { UploadOutlined,CheckCircleOutlined } from '@ant-design/icons';
import { AppContext } from '../../../../App';
import { postAdditionalInfo, updateZomatoData, uploadImageToS3 } from '../../../../service/additionalInfoService';
import { useHistory } from "react-router-dom";
import { updateBlinkitData } from '../../../../service/BlinkitAdditionalFormService';

function BlinkitSelfieUpload({goToNextScreen,lead,leadAdditionalDetails}) {
    const [selfie, setSelfie] = useState();
    const {mitraReducer,mitraDispatch, setAlert,setSpin } = useContext(AppContext);
    const history = useHistory()
  
    const showLoader = (value) => {
      setSpin({
          loading: value, //false or true when actived
          delay: null,
          tip: 'Uploading...'  //Loading copy here
      })
  }
  
  const checkErrors = (res) => {
    const newErrors = [];
    for (let key in res.data) {
        if (res.data[key] !== "200") {
            newErrors.push(`${key}: ${res.data[key]}`)
        }
    }
  
    if(newErrors.length) {
        // setErrors([...newErrors]);
        alert(newErrors.join("\n"))
        return true;
    } 
    return false;
  }
  
    const handleBeforeUpload = (file) => {
  
      // Return false to prevent upload
      return false;
    };
  
    const handleUpload =(info) => {
      showLoader(true);
  
      uploadImageToS3(info.file)
          .then(async(res)=> {
              showLoader(false);
  
              // choose what to do with the uploaded file
              if(res?.data?.imageUrl) {
                const submissionData = {
                  userSelfie: {
                      url: res.data.imageUrl,
                      originalName: info?.file?.name,
                      size: info?.file?.size
                  }
                }
          
                const response = await postAdditionalInfo(submissionData, mitraReducer?.mitraInfo, lead);
          
                if(!checkErrors(response)) {
                  setSelfie(res.data.imageUrl);
                }
                  
              }
          }).catch(e=> {
              showLoader(false);
  
              notification['error']({
                  message: 'Image upload failed, please try again later.',
                });
  
              console.log(e);
          })
  };
  
    const submitSelfie = async() => {
  
      showLoader(true);
      if(!selfie) {
        showLoader(false);
        notification['error']({
          message: 'Please add selfie to continue',
        });
      return
      } else {
  
        try {
          // submission operation
          const payload = {
            phoneNumber: lead?.phoneNumber,
            userId: lead?.userId,
            mitraName: mitraReducer?.mitraInfo?.name,
            mitraId: mitraReducer?.mitraInfo?.id,
            dataToUpdate: {
              uploadSelfie: {
                blinkitSelfieImageUrl: selfie
              }
            }
          }

          const response = await updateBlinkitData(payload);

          if(response?.data?.uploadSelfie?.status?.code === 200) {
            history.replace({pathname: "/recruiter-candidates"});
          } else {
            notification['error']({
              message: 'Error submitting user selfie.'
            })
          }

          showLoader(false);
        } catch(err) {
          notification['error']({
            message: 'Error submitting user selfie.'
          })
          showLoader(false);
        }
      }
    }

    useEffect(() => {
      if(leadAdditionalDetails?.userSelfie?.[0]?.url || leadAdditionalDetails?.passportPhoto?.[0]?.url) {
        // since we donot get the photo uploaded info from zomato, populating it from the latest uploaded info from out DB
  
        if(leadAdditionalDetails?.userSelfie?.[0]?.url) {
          setSelfie(leadAdditionalDetails?.userSelfie?.[0]?.url);
        } else {
          setSelfie(leadAdditionalDetails.passportPhoto[0].url);
        }
      }
    }, [leadAdditionalDetails])
    
    
  
    return (
      <>
  
        <div className='upload-selfie-container'>
          <div className='container-header'>Selfie Verification</div>
  
          <div className='selfie-form'>
            <div className="aadhar-steps-header">Note</div>
            <ul>
              <li style={{textAlign: 'start'}}>Please upload the correct selfie of the rider as the same image will be used to for verification during Gig attendance</li>
            </ul>
  
            <img src='/image/zomato-ob-selfie.png' height={'auto'} width={'250px'} style={{resizeMode: 'contain'}} />
  
            <div className='label mt-24'>Selfie<sup>*</sup></div>
            
                  {
                    selfie ? (
                        <div style={{display: 'flex', gap: '8px'}}>
                            <img src={selfie} width={150} height={150}/>
                            <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>setSelfie(null)}>x</p>
                        </div>
                    ) : (
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            showUploadList={false}
                            accept="image/*"
                            onChange={handleUpload}
                            beforeUpload={handleBeforeUpload}
                            >
                            <div>
                                <Input type="file" accept="image/*" style={{ display: 'none' }} />
                                <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                            </div>
                        </Upload>
                    )
                }
          </div>
  
        </div>
  
        <div className='button-container'>
              <Button className='submit-button' onClick={()=>submitSelfie()}>Next</Button>
          </div>
      </>
    )
}

export default BlinkitSelfieUpload
