import { Button, Select, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import { getSwiggyProfileData, updateSwiggyData } from '../../../../service/SwiggyAdditionalInfoService';
import { AppContext } from '../../../../App';

function LocationDetails({goToNextScreen, lead, submissionDetails, setIsLocationScreenDetailsSubmitted}) {
  const {mitraReducer,mitraDispatch, setAlert,setSpin } = useContext(AppContext);
  const [locationDetails, setLocationDetails] = useState({
    city: null,
    zone: null,
    cityObject: null,
    zoneObject: null
  });
  const [errorField, setErrorField] = useState('');

  const [cityOptions, setCityOptions] = useState([]);
  const [zoneOptions, setZoneOptions] = useState([]);

  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const [selectedVehicleTypeObj, setSelectedVehicleTypeObj] = useState(null);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState([]);

  const onChangeLocationDetails = (field, value) => {
    setLocationDetails({...locationDetails, [field]: value});
  }

  const showLoader = (value) => {
    setSpin({
        loading: value, //false or true when actived
        delay: null,
        tip: 'Loading...'  //Loading copy here
    })
}

  const filterOption = (input, option) =>(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const populateZones = async(cityId) => {
    showLoader(true);
    try {
      const payload = {
        phoneNumber: lead?.phoneNumber,
        "dataToFetch" : {
          "getZones": {
              "cityId": cityId
          }
        }
      }
      const workAreasResponse = await getSwiggyProfileData(payload);

      if(workAreasResponse?.data?.data?.getZones?.zonesArr) {

        let zones = [...workAreasResponse?.data?.data?.getZones?.zonesArr];
        
        for (let i = 0; i < zones.length; i++) {
          if (!zones[i].isAvailable) {
            zones.splice(i, 1);
            continue
          }
          zones[i].label = zones[i].title + ' | ' + `Weekly Earnings: Upto ₹${zones[i].maxExpectedAmount}` + ' | ' +  `Joining Bonus: Upto ₹${zones[i].joiningBonus}`;
          zones[i].value = zones[i].zoneId;
        }
    
        console.log(zones);
        setZoneOptions([...zones]);
      } else {
        notification['error']({
          message: 'Error fetching work areas.'
        })
      }
      showLoader(false);
    } catch(e) {
      showLoader(false);
      notification['error']({
        message: 'Error fetching work areas.'
      })
    }
  }

  const submitCity = async(info) => {
    showLoader(true);
    try {
      const payload = {
        "phoneNumber": lead?.phoneNumber,
        "dataToUpdate": {
            "postCities": [
                {
                    "value": info.value,
                    "label": info.label
                }
            ]
        }
      }

      const locationDetailsSubmissionResponse = await updateSwiggyData(payload);

      if(locationDetailsSubmissionResponse?.data?.data?.[0]?.['swiggyCity']?.statusMessage === 'success') {
        populateZones(info.value);
      } else {
        showLoader(false);
        notification['error']({
          message: 'Error submitting the city.'
        })
      }
    } catch(e) {
      showLoader(false);
      notification['error']({
        message: 'Error submitting submission details, please try again later.'
      })
    }
  }

  const submitWorkArea = async(zoneId) => {
    showLoader(true);
    try {
      const payload = {
        "phoneNumber": lead?.phoneNumber,
        "dataToUpdate": {
            "postZones": [
              {
                  "zoneId": zoneId
              }
            ]
        }
      }

      const locationDetailsSubmissionResponse = await updateSwiggyData(payload);

      if(locationDetailsSubmissionResponse?.data?.data?.[0]?.['swiggyZone']?.statusMessage === 'SUCCESS') {
        // call vehicle options
        getVehicleOptionData();
      } else {
        showLoader(false);
        notification['error']({
          message: 'Error submitting the zone information.'
        })
      }
    } catch(e) {
      showLoader(false);
      notification['error']({
        message: 'Error submitting submission details, please try again later.'
      })
    }
  }

  const getVehicleOptionData = async() => {
    showLoader(true);

    try {
      const payload = {
        "phoneNumber": lead?.phoneNumber,
        "dataToFetch" : {
            "getVehicles": {},
        }
      }
  
      const response = await getSwiggyProfileData(payload);
      if(response?.data?.data?.getVehicles?.options) {
        let vehicleOptions = response?.data?.data?.getVehicles?.options;

        if(vehicleOptions.length) {
          vehicleOptions = vehicleOptions.filter(vehicle=> !vehicle.isDisabled);
          setVehicleTypeOptions([...vehicleOptions]);
        }
      }
      showLoader(false);
    } catch(e) {
      showLoader(false);
      notification['error']({
        message: 'Error fetching vehicle options.'
      })
    }
  }

  const handleChange = (item, fieldObject, fieldName) => {
    setErrorField('');
    // if(fieldName === 'city') {
    //   setSelectedVehicleType(null);
    //   submitCity(fieldObject);
    // } else {
    //   // call for vehicle type
    //   getVehicleOptionData();
    //   setSelectedVehicleType(null);
    // }

    setIsLocationScreenDetailsSubmitted(false);

    const data = {
      ...locationDetails,
    }

    if(fieldName === 'city') {
      data.city = fieldObject.label;
      data.cityObject = fieldObject
      data.zone = null;
      data.zoneObject = null;

      setSelectedVehicleType(null);
      submitCity(fieldObject);
    } else {
      data.zone = fieldObject.label;
      data.zoneObject = fieldObject

      submitWorkArea(fieldObject.zoneId);
      setSelectedVehicleType(null);
    }
    setLocationDetails({...data});
};

const populateCityValues = async() => {
  showLoader(true);
  try{
    const payload = {
      "phoneNumber": lead?.phoneNumber,
      "dataToFetch" : {
        "getCitiesFromLatLng": {
            "latLng": "37.421998333333335,-122.084"
        }
    }
    }
    const citiesResponse = await getSwiggyProfileData(payload);

    if(citiesResponse?.data?.data?.getCitiesFromLatLng?.citiesArr) {
      setCityOptions([...citiesResponse.data.data.getCitiesFromLatLng.citiesArr]);
    }
    showLoader(false);
  }catch (e) {
    showLoader(false);
    notification['error']({
      message: 'Something went wrong while fetching cities.'
    })
  }
}

const autoPopulateIfDataExists = () => {

  const locationData = {
    city: null,
    zone: null,
    cityObject: null,
    zoneObject: null
  }

  if(submissionDetails?.swiggyElementMap?.swiggyCity?.value) {
    const parsedCityValue = JSON.parse(submissionDetails?.swiggyElementMap?.swiggyCity?.value);
    locationData.city = parsedCityValue?.label;
    locationData.cityObject = parsedCityValue;
    populateZones(parsedCityValue.value);
  }

  if(submissionDetails?.swiggyElementMap?.swiggyZone?.value) {
    const parsedZoneValue = JSON.parse(submissionDetails?.swiggyElementMap?.swiggyZone?.value);
    locationData.zone = parsedZoneValue?.zoneId;
    locationData.zoneObject = parsedZoneValue;

    getVehicleOptionData();
  }

  if(submissionDetails?.swiggyElementMap?.swiggyVehicleType?.value) {
    const parsedVehicleValue = JSON.parse(submissionDetails?.swiggyElementMap?.swiggyVehicleType?.value);
    setSelectedVehicleType(parsedVehicleValue?.value);
    setSelectedVehicleTypeObj(parsedVehicleValue);
  }

  setLocationDetails(locationData);

  // if all the details are prefilled then enable step hopping using tab clicks
  if(
    submissionDetails?.swiggyElementMap?.swiggyCity?.value &&
    submissionDetails?.swiggyElementMap?.swiggyZone?.value &&
    submissionDetails?.swiggyElementMap?.swiggyVehicleType?.value
  ) {
    setIsLocationScreenDetailsSubmitted(true);
  }
  
}

  useEffect(() => {
    populateCityValues();
  }, [])

  useEffect(() => {
    autoPopulateIfDataExists();
  }, [submissionDetails])
  

  const allRequiredFieldsHaveValues = () => {
    if(!locationDetails.city || !locationDetails.zone || !selectedVehicleType) {
      if(!locationDetails.city) {
        notification['error']({
          message: 'Select a city.',
        });
        setErrorField('city');
      } else if(!locationDetails.zone) {
        notification['error']({
          message: 'Select a work area.',
        });
        setErrorField('zone');
      } else if(!selectedVehicleType) {
        notification['error']({
          message: 'Select a vehicle type.',
        });
        setErrorField('vehicle');
      } else {
        notification['error']({
          message: 'Select all the fields to continue.',
        });
      }
      return false;
    } else return true
  }

  const onSubmitLocationPreference = async() => {
    showLoader(true);
    if(allRequiredFieldsHaveValues()) {

      try {
        const payload = {
          "phoneNumber": lead?.phoneNumber,
          "dataToUpdate": {
              "postZones": [
                {
                    "zoneId": locationDetails?.zoneObject?.zoneId
                }
              ]
          }
        }

        const locationDetailsSubmissionResponse = await updateSwiggyData(payload);

        if(locationDetailsSubmissionResponse?.data?.data?.[0]?.['swiggyZone']?.statusMessage === 'SUCCESS') {
          goToNextScreen();
        } else {
          notification['error']({
            message: 'Error submitting the zone information.'
          })
        }
        showLoader(false);
      } catch(e) {
        showLoader(false);
        notification['error']({
          message: 'Error submitting submission details, please try again later.'
        })
      }
    } else {
      showLoader(false);
    }
  }

  const onSelectVehicleType = async(vehicleType, vehicleTypeObject) => {

    setIsLocationScreenDetailsSubmitted(false);
    
    setSelectedVehicleType(vehicleType);
    setSelectedVehicleTypeObj(vehicleTypeObject);

    showLoader(true);
    vehicleTypeObject.isSelected = true;
    try {

      const payload= {
        phoneNumber: lead?.phoneNumber,
        dataToUpdate: {
          "postVehicles": [vehicleTypeObject]
        }
      }
      const vehicleTypeSubmissionResponse = await updateSwiggyData(payload);
      showLoader(false);

      if(vehicleTypeSubmissionResponse?.data?.data?.[0]?.swiggyVehicleType?.statusMessage === 'SUCCESS') {
        // do something
      } else {
        notification['error'] ({
          message: 'Error submitting vehicle type details.'
        })
      }
    } catch(e) {
      showLoader(false);
      notification['error'] ({
        message: 'Error submitting vehicle type details.'
      })
    }
  }
  
  return (
    <>
      <div className='pan-verification-container'>
        <div className='container-header'>Location Preference</div>
        <div className='pan-form-container'>

          <div className='label mt-24'>Select city<sup>*</sup></div>
          <Select
              style={{ width: '100%', textAlign: 'start', marginTop: '8px' }}
              placeholder="Select one of the cities.."
              value={locationDetails.city}
              onChange={(v,o)=>handleChange(v,o, 'city')}
              optionLabelProp="label"
              options={cityOptions}
              showSearch
              filterOption={filterOption}
              className={`${errorField==='city'? 'input-error':''}`}
          />

          {
            locationDetails.city ? (
              <>
                <div className='label mt-24'>Select preferred work area<sup>*</sup></div>
                <Select
                    style={{ width: '100%', textAlign: 'start', marginTop: '8px' }}
                    placeholder="Select work area.."
                    value={locationDetails.zone}
                    onChange={(v,o)=>handleChange(v,o, 'zone')}
                    optionLabelProp="label"
                    options={zoneOptions}
                    showSearch
                    filterOption={filterOption}
                    className={`${errorField==='zone'? 'input-error':''}`}
                />
              </>
            ): null
          }
        </div>
      </div>

      {
        locationDetails?.zone ? (
          <div className='pan-verification-container'>
            <div className='container-header'>Vehicle type</div>
            <div className='pan-form-container'>

              <div className='label mt-24'>Vehicle type<sup>*</sup></div>
              <Select
                  style={{ width: '100%', textAlign: 'start', marginTop: '8px' }}
                  placeholder="Select vehicle type"
                  value={selectedVehicleType}
                  onChange={(v,o)=>onSelectVehicleType(v,o)}
                  optionLabelProp="label"
                  options={vehicleTypeOptions}
                  className={`${errorField==='vehicle'? 'input-error':''}`}
              />
            </div>
          </div>
        ): null
      }

      <div className='button-container'>
          <Button className='submit-button' onClick={()=>onSubmitLocationPreference()}>Next</Button>
      </div>
    </>
  )
}

export default LocationDetails
