import axios from 'axios';
import moment from 'moment';
import axiosInstance from './interceptorService';

export const sendBlinkitOtp = (payload) => {
    const {scheduledInterviewId, phoneNumber, companyName} = payload;

    const requestPayload = {
        arg: {
            scheduledInterviewId,
            clientName: companyName
        },
        user: {
            phoneNumber
        }
    }

    const url = `${process.env.REACT_APP_BACKEND_URL}/blinkit/sendOtp`
    return axiosInstance.post(url, requestPayload, { withCredentials: true });
}

export const submitBlinkitOtp = async (data) => {
    const requestData = {
        data: {
            phoneNumber: data.phoneNumber,
            otp: data.otp
        }
    }

    const url = `${process.env.REACT_APP_BACKEND_URL}/blinkit/verifyOtp`
    return axiosInstance.post(url, requestData, { withCredentials: true });
}

export const getBlinkitData = async (payload) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/blinkit/getBlinkitData`
    return axiosInstance.post(url, payload, { withCredentials: true });
}

export const updateBlinkitData = async (payload) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/blinkit/updateBlinkitData`
    return axiosInstance.post(url, payload, { withCredentials: true });
}