import React from 'react';
import { Typography } from 'antd';
import { PhoneOutlined, EnvironmentOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Text } = Typography;

const DummyCandidateCard = (props) => {

    const { candidateCardData } = props;

    return (
        <div className='candidate-card-container'>
            <div className='candidate-card-header'>
                <Text>{candidateCardData.firstName}{candidateCardData.lastName !== 'Unknown' && " " + candidateCardData.lastName}</Text>
                <Text className='mobile-number-content'><PhoneOutlined /> {candidateCardData.phoneNumber}</Text>
            </div>
            <div className='candidate-card-content'>
                <img className='client-logo' alt={candidateCardData.companyName} src={candidateCardData.logo} />
                {candidateCardData.latestMilestone && candidateCardData.ujfmCreatedAt ?
                    <div className='milestone-content'>
                        <Text className='milestone-date'>{moment(candidateCardData.ujfmCreatedAt).format('DD MMM YYYY')}</Text>
                        <Text className='milestone-name'>{candidateCardData.latestMilestone}</Text>
                    </div>
                    : null
                }
            </div>
            <div className='candidate-card-footer'>
                <Text>Added by <Text style={{
                    fontWeight: "700",
                    fontSize: "12px",
                    lineHeight: "20px"
                }}>{candidateCardData.mitraName}</Text></Text>
                <Text className='city-content'><EnvironmentOutlined /> {candidateCardData.companyCity}</Text>
            </div>
        </div>
    );
}

export default DummyCandidateCard;