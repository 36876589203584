import React, { useContext, useEffect, useState } from 'react'
import PlusIcon from '../../../static/svg/PlusIcon'
import { Popover } from 'antd'
import CONSTANTS from '../../../constants/constants';
import { AppContext } from '../../../App';
import { useHistory } from 'react-router-dom';

function SessionCreationFloaterButton({isSessionCreationAllowed, showButton=true, showMenu=false, setShowFloaterMenu=()=>1}) {

    const [clicked, setClicked] = useState(false);
    const [rotate, setRotate] = useState(showMenu);
    const {mitraReducer} = useContext(AppContext);
    const sessionCreationOptions = [...CONSTANTS.SAMVAADINI_SESSION_CREATION_OPTIONS];
    const history = useHistory();

    const handleClick = () => {
        setClicked(true);
        // Remove the 'clicked' class after the animation duration
        setTimeout(() => setClicked(false), 300);

        setRotate(!rotate);

        if(setShowFloaterMenu) setShowFloaterMenu(!rotate)
    };

    useEffect(() => {
        setRotate(showMenu);
    }, [showMenu])
    
  return (
    <>
        {
            mitraReducer?.mitraInfo?.managerMitraID ? null: (
                <>
                    {rotate && <div className={`backdrop ${rotate ? 'show' : ''}`} onClick={handleClick} />}

                    <div className="floater-container">
                        {
                            isSessionCreationAllowed ? (
                                    rotate ? null: 
                                    showButton ? (
                                            <div 
                                                onClick={handleClick}
                                                className={`floater-container-btn ${clicked ? 'clicked' : ''}`}>
                                                <PlusIcon strokeColor='#FFF' clicked={rotate}/>
                                            </div>
                                        ): null
                                    
                            ): (
                                <Popover
                                    content={<span className='inter-regular-10-16'>A session is already in progress</span>} 
                                    // title={<div className='bold14-22'>{allowAIFilter ? 'AI Filtering': 'Not Available'}</div>} 
                                    trigger={'click'}
                                    // className='ai-filtering-popup'
                                    // getPopupContainer={(trigger) => trigger.parentNode}
                                    placement='bottom'
                                    >
                                    <div 
                                        onClick={()=>1}
                                        className={`floater-container-btn ${!isSessionCreationAllowed ? 'floater-container-btn-disabled':''} ${clicked ? 'clicked' : ''}`}>
                                            <PlusIcon strokeColor='#FFF' clicked={rotate}/>
                                    </div>
                                </Popover>
                            )
                        }
                        

                        <div className={`menu ${rotate ? 'show' : 'hide'}`}>

                            {
                                sessionCreationOptions.map(item =>(
                                    <div 
                                    className="single-menu-container" 
                                    onClick={()=>item?.onButtonClick?.(history)}
                                    key={item.id}>
                                        <span className='inter-medium-14-20 plain-white'>{item.header}</span>
                                        <div className={`menu-item ${item.header === 'Smart Logic' ? 'blue-bg':'right-margin'}`}>{item.icon}</div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </>
            )
        }
    </>
  )
}

export default SessionCreationFloaterButton