import React from "react";
import { PageHeader , Breadcrumb, Select} from 'antd';
import { useHistory } from 'react-router-dom';

import '../../css/pageHeader.scss';

const { Option } = Select;


const PageHeaderComponent = (props) => {
    const {breadcrumb,extra}=props;
    const selector=props.selector || ">";
    const history = useHistory();

    const handleDropDrown =(text,item)=>{
        history.push({
            pathname: props.dropDownObj.dropDownArr[item.key]?.route
        })
    }

    return (
        <React.Fragment>
        {breadcrumb && <>
            <div className='bread-crumb-desktop'>
                <Breadcrumb separator={selector}>
                    {
                        breadcrumb.map((item,index)=>{
                            return <Breadcrumb.Item key={index} href={item.link}>{item.label}</Breadcrumb.Item>
                        })
                    }
                </Breadcrumb>
             </div>
             {!props.dropDownObj ? <PageHeader
             className="pageheader"
             onBack={props.backBtnBool ? () => window.history.back() : null}
             title={breadcrumb[breadcrumb.length-1].label}
             extra={extra}
            /> : 
            <Select
                className="pageheader-dropdown"
                bordered={false}
                onChange={handleDropDrown}
                defaultValue={props.dropDownObj.defaultValue}
                id="selector_pageheader"
                >
                   {props.dropDownObj.dropDownArr.map((item,index)=>{
                       if(!item.disabled)return <Option disabled={item.disabled} id={item.value+'_index_'+index} key={index} value={item.value}>
                       {item.label}
                    </Option>
                   })} 
                
            </Select>}
            </>
        }
        {!breadcrumb && 
        <PageHeader
            ghost={false}
            className="pageheader-title"
            title={props.title}
            onBack={!props.backBtnBool ? () => window.history.back() : null}
            extra={extra}
        />}
        </React.Fragment>
    )
}

export default PageHeaderComponent
