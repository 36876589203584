import * as React from "react";
const PlayIcon = (props) => (
  <svg
    width={14}
    height={13}
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.75 10.2776V2.72204C1.75 2.09901 1.75 1.7875 1.88882 1.60316C2.00998 1.44227 2.19673 1.33647 2.40405 1.31121C2.64137 1.2823 2.93179 1.42859 3.51277 1.72123L3.5144 1.72205L11.0144 5.49983C11.659 5.82452 11.9813 5.9869 12.0869 6.20307C12.179 6.39139 12.179 6.60828 12.0869 6.7966C11.9813 7.01277 11.659 7.17513 11.0144 7.49983L3.5144 11.2776L3.51234 11.2786C2.93165 11.5711 2.64132 11.7174 2.40405 11.6885C2.19673 11.6632 2.00998 11.5574 1.88882 11.3965C1.75 11.2122 1.75 10.9006 1.75 10.2776Z"
      stroke="#4BDA58"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default PlayIcon;
