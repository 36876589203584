import React, { useState, useEffect } from "react";
import { Select, Button, Typography, Upload, Modal, Input, notification, Spin, Card } from 'antd';
import { PlusOutlined, AudioFilled, DeleteOutlined, PauseCircleTwoTone, StopTwoTone, PlayCircleTwoTone, InfoCircleOutlined } from '@ant-design/icons';
import CustomInput from "../common/CustomInput.component";
import SideSheetComponent from "../common/SideSheet";
import { getCandidateData, createTicket } from "../../../src/service/raiseIssueSiderService"
import DummyCandidateCard from "../common/DummyCandidateCard.component";
import SideSelector from "../common/siderSelector";
import CONSTANTS from "../../../src/constants/constants";
import useRecorder from "../common/useRecorder";
import { getCandidatesAndLeads, getMitraPhoneNumberFromId } from "../../service/commonService";
import { trackEvent } from "../../service/engagementMonitoringService";
import {useHistory} from 'react-router-dom';

const { Text } = Typography;

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const clientSpecificMandatoryFieldMap = {
    "rapido_3pl": [],
    "uber": ["candidateRegisteredName", "candidateDrivingLicenseNumber"],
    "uber_3pl": [],
    "zomato": ["riderId"],
    "rapido": []
}

const RaiseIssueSider = (props) => {
    const { closeSider, mitraReducer, newIssueRaised, selectedLead } = props;
    const [candidateValue, setCandidateValue] = useState('');
    const [candidateData, setCandidateData] = useState([]);
    const [fetchingCandidateData, setFetchingCandidateData] = useState(false);
    const [candidateCardData, setCandidateCardData] = useState({});
    const [issueTypeValue, setIssueTypeValue] = useState('');
    const [issueDescriptionValue, setIssueDescriptionValue] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);
    const [additionalMandatoryFields, setAdditionalMandatoryFields] = useState({});
    const [playing, setPlaying] = useState(false);
    const [deleteAudio, setDeleteAudio] = useState(false);
    const [managerMitraPhoneNumber, setManagerMitraPhoneNumber] = useState('');
    const [submitClicked, setSubmitClicked] = useState(false);
    let [audioBlob, audioURL, isRecording, startRecording, stopRecording, deleteRecording] = useRecorder();
    const history = useHistory();

    let timeoutForCandidateData, currentCandidateValue;

    const createCandidateData = (item = {}) => {
        if (selectedLead && Object.keys(selectedLead).length) {
            item = selectedLead;
        }
        return {
            value: item.recommendationHistoryId,
            text: <div className='search-item-container'>
                <div className='search-item-headline'>
                    <Text>{item.phoneNumber} - {item.firstName}{item.lastName !== 'Unknown' && " " + item.lastName}, {item.companyName},</Text>
                    <Text style={{ color: "#BFBFBF" }}>{item.companyCity}</Text>
                </div>
                <div className='search-item-subheadline'>
                    <Text style={{ color: "#BFBFBF" }}>Added on {item.rhCreatedAt && new Date(item.rhCreatedAt).toISOString().split('T')[0].split('-').reverse().join('-')}</Text>
                </div>
            </div>,
            metaData: item
        }
    }

    useEffect(() => {
        if (selectedLead && Object.keys(selectedLead).length) {
            const singleCandidateData = createCandidateData();
            setCandidateData([singleCandidateData]);
            setCandidateValue(singleCandidateData.value);
            setCandidateCardData(singleCandidateData.metaData);
        }
    }, [selectedLead && Object.keys(selectedLead).length])

    useEffect(() => {
        if (!mitraReducer?.mitraInfo?.managerMitraID) {
            setManagerMitraPhoneNumber(mitraReducer?.mitraInfo?.phoneNumber)
        }
        else {
            getMitraPhoneNumberFromId(mitraReducer?.mitraInfo?.managerMitraID).then(res => {
                setManagerMitraPhoneNumber(res?.data?.mitraPhoneNumber)
            }).catch(err => {
                setManagerMitraPhoneNumber(mitraReducer?.mitraInfo?.phoneNumber)
            })
        }
    }, [mitraReducer?.mitraInfo]);

    const fetchCandidateData = (candidateValue, callback) => {
        if (timeoutForCandidateData) {
            clearTimeout(timeoutForCandidateData);
            timeoutForCandidateData = null;
        }
        currentCandidateValue = candidateValue;
        const debounceDataForCandidate = () => {
            getCandidateData(candidateValue)
                .then((response) => {
                    if (currentCandidateValue === candidateValue) {
                        const { candidatesData } = response.data;
                        const data = candidatesData.map((item) => createCandidateData(item));
                        callback(data);
                        setFetchingCandidateData(false);
                    }
                });
        };
        timeoutForCandidateData = setTimeout(debounceDataForCandidate, 300);
    };

    const handleCandidateSearch = (newValue) => {
        setFetchingCandidateData(true);
        if (newValue && newValue.length > 3) {
            fetchCandidateData(newValue, setCandidateData);
        } else {
            setCandidateData([]);
        }
    };

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    }

    const uploadButton = (
        <div>
            <PlusOutlined style={{ fontSize: "22px" }} />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const playPauseAudio = (flag) => {
        const x = document.getElementById("myAudio");
        if (x.getAttribute('listener') !== 'true') {
            x.addEventListener('timeupdate', handleAudioProgress);
        }
        if (flag) {
            x.play()
        } else {
            x.pause();
        }
    }
    const audioPlay = () => { setPlaying(!playing); playPauseAudio(!playing) }
    const handleAudioProgress = () => {
        const player = document.getElementById("myAudio");
        var currentTime = player.currentTime;
        var duration = player.duration;
        const slider = document.getElementById("hp_range");
        if (slider)
            slider.style.width = (currentTime + .25) / duration * 100 + '%';
    }


    const raiseIssueToFD = async () => {
        if (mitraReducer?.mitraInfo) {
            setSubmitClicked(true);
            const mitraInfo = mitraReducer.mitraInfo;
            mitraInfo.managerMitraPhoneNumber = managerMitraPhoneNumber;

            const candidateInfo = {
                candidateValue: candidateValue,
                issueType: issueTypeValue,
                issueDescription: issueDescriptionValue,
                riderId: additionalMandatoryFields?.riderId,
                vehicleNumber: additionalMandatoryFields?.vehicleNumber,
                candidateRegisteredName: additionalMandatoryFields?.candidateRegisteredName,
                candidateDrivingLicenseNumber: additionalMandatoryFields?.candidateDrivingLicenseNumber,
                extra: candidateCardData
            }

            const attachments = fileList.map((item) => {
                return item?.originFileObj;
            });

            if (audioBlob && audioBlob.size > 0) {
                const file = new File([audioBlob], "Voice Note", { lastModified: new Date().getTime(), type: audioBlob.type })
                attachments.push(file);
            }
            try{
                const ticketCreationResponse = await createTicket(mitraInfo, candidateInfo, attachments);
                if(ticketCreationResponse?.status === 200){
                    if (!(selectedLead && Object.keys(selectedLead).length))
                            newIssueRaised();

                        notification['success']({
                            message: 'New Issue Raised Successfully.'
                        });
                        closeSider();
                }
                else if(ticketCreationResponse?.status === 409){
                    closeSider();
                    notification['info']({
                        message: <div>
                            <p>Issue already raised for this candidate.</p>
                            <Button type="primary" onClick={() => {
                                history.push({
                                    pathname: '/search-ticket',
                                    state: {
                                        candidatePhoneNumber: ticketCreationResponse?.data?.custom_fields?.cf_candidate_phone_number,
                                        ticketId: ticketCreationResponse?.data?.id
                                    }
                                })
                                notification.destroy();
                            }}>View Issue</Button>
                        </div>,
                        duration: 0
                    });
                }
                else {
                    notification['error']({
                        message: 'Failed Raising New Issue!'
                    });
                }
                }
                catch(error){
                    notification['error']({
                        message: 'Failed Raising New Issue!'
                    });
                }
                setSubmitClicked(false);
        }
        return null;
    }

    return (
        <SideSheetComponent
            sidername={<Text className='help-sider-name'>Raise New Issue</Text>}
            hideBottomButtons={true}
            onDismiss={closeSider}>
            <div className='raise-issue-container'>
                <div className='form-input-field'>
                    <CustomInput
                        title="Select Candidate"
                        placeholder="Search by Name or Mobile Number"
                        isRequired={true}
                        inputType="null"
                    />
                    <Select
                        disabled={selectedLead && Object.keys(selectedLead).length}
                        showSearch
                        value={candidateValue}
                        placeholder="Search by Name or Mobile Number"
                        defaultActiveFirstOption={false}
                        onClear={() => {
                            setCandidateData([]);
                            setCandidateCardData({});
                            setCandidateValue('');
                            setAdditionalMandatoryFields({})
                        }}
                        allowClear={true}
                        showArrow={false}
                        filterOption={false}
                        onSearch={handleCandidateSearch}
                        onChange={(newValue) => {
                            const index = candidateData.findIndex((d) => d?.metaData?.recommendationHistoryId === newValue);
                            if (index !== -1) {
                                if (candidateData?.[index]?.['metaData']) {
                                    setCandidateCardData(candidateData[index]['metaData']);
                                    const data = {
                                        search: newValue,
                                        widgetsToInclude: JSON.stringify([{ "type": "candidates", "limit": 5, "offset": 0 }]),
                                        source: 'raiseIssue'
                                    }
                                    getCandidatesAndLeads(data).then((response) => {
                                        const candidate = response?.data?.candidates?.data;
                                        if (candidate?.length) {
                                            setCandidateCardData({ ...candidateData[index]['metaData'], latestMilestone: candidate?.[0]?.latestMilestone?.[0], ujfmCreatedAt: candidate?.[0]?.ujfmCreatedAt?.[0] });
                                        }
                                    })
                                }
                            }
                            setCandidateValue(newValue);
                        }}
                        notFoundContent={fetchingCandidateData ? <Spin size="small" /> : null}
                        options={(candidateData || []).map((d) => ({
                            value: d.value,
                            label: d.text,
                        }))}
                    />
                </div>
                {candidateCardData && Object.keys(candidateCardData).length ?
                    <>
                        <DummyCandidateCard candidateCardData={candidateCardData} />

                        {
                            candidateCardData?.companyName?.toLowerCase() === 'zomato' ?
                                <CustomInput
                                    className='form-input-field'
                                    title="Rider Id"
                                    placeholder="Enter Rider Id"
                                    isRequired={true}
                                    inputType="text"
                                    value={additionalMandatoryFields?.riderId}
                                    onChange={(e) => {
                                        if (e.target.value?.trim())
                                            setAdditionalMandatoryFields({ ...additionalMandatoryFields, riderId: e.target.value });
                                        else
                                            setAdditionalMandatoryFields({ ...additionalMandatoryFields, riderId: '' });
                                    }}
                                />
                                : null
                        }
                        {
                            ['uber_3pl', 'rapido', 'rapido_3pl'].includes(candidateCardData?.companyName?.toLowerCase()) ?
                                <CustomInput
                                    className='form-input-field'
                                    title="Vehicle Number"
                                    placeholder="Enter Vehicle Number"
                                    isRequired={false}
                                    inputType="text"
                                    value={additionalMandatoryFields?.vehicleNumber}
                                    onChange={(e) => {
                                        if (e.target.value?.trim())
                                            setAdditionalMandatoryFields({ ...additionalMandatoryFields, vehicleNumber: e.target.value });
                                        else
                                            setAdditionalMandatoryFields({ ...additionalMandatoryFields, vehicleNumber: '' });
                                    }}
                                />
                                : null
                        }
                        {
                            ['uber'].includes(candidateCardData?.companyName?.toLowerCase()) ?
                                <>
                                    <CustomInput
                                        className='form-input-field'
                                        title="Candidate Registered Name"
                                        placeholder="Enter Candidate Registered Name"
                                        isRequired={true}
                                        inputType="text"
                                        value={additionalMandatoryFields?.candidateRegisteredName}
                                        onChange={(e) => {
                                            if (e.target.value?.trim())
                                                setAdditionalMandatoryFields({ ...additionalMandatoryFields, candidateRegisteredName: e.target.value });
                                            else
                                                setAdditionalMandatoryFields({ ...additionalMandatoryFields, candidateRegisteredName: '' });
                                        }}
                                    />
                                    <CustomInput
                                        className='form-input-field'
                                        title="Candidate Driving License Number"
                                        placeholder="Enter Candidate Driving License Number"
                                        isRequired={true}
                                        inputType="text"
                                        value={additionalMandatoryFields?.candidateDrivingLicenseNumber}
                                        onChange={(e) => {
                                            if (e.target.value?.trim())
                                                setAdditionalMandatoryFields({ ...additionalMandatoryFields, candidateDrivingLicenseNumber: e.target.value });
                                            else
                                                setAdditionalMandatoryFields({ ...additionalMandatoryFields, candidateDrivingLicenseNumber: '' });
                                        }}
                                    />
                                </>
                                : null
                        }
                    </>
                    : null}

                <SideSelector
                    labelname="Issue Type"
                    selectorData={CONSTANTS.RAISE_ISSUE_TYPES}
                    error={{ status: false, text: 'Issue Type is required' }}
                    placeholder="Select Issue"
                    required
                    onChange={(e) => {
                        setIssueTypeValue(e);
                    }}
                />

                <div className='form-input-field'>
                    <div className='form-input-fieldname voice-note-description'>
                        <Text><span className="requiredField">*</span>Describe Issue Here</Text>
                        {!isRecording ?
                            audioURL ?
                                <Button className='voice-note-button stop-button' onClick={() => {
                                    setDeleteAudio(true);
                                }}>
                                    Replace
                                </Button>
                                :
                                <Button className='voice-note-button' onClick={() => {
                                    startRecording();
                                    trackEvent('clicked_voice_note_help',{}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                                    }}>
                                    <AudioFilled style={{ color: "#D34612" }} />
                                    Add Voice Note
                                </Button>
                            :
                            <Button className='voice-note-button stop-button' onClick={stopRecording}>
                                <StopTwoTone twoToneColor="#595959" />
                                Stop
                            </Button>
                        }
                    </div>
                    <Input.TextArea
                        placeholder="Explain your issue ...."
                        rows={4}
                        onChange={(e) => {
                            setIssueDescriptionValue(e.target.value);
                        }}
                        value={issueDescriptionValue}
                    />
                    {
                        deleteAudio ?
                            <div className='voice-note-delete'>
                                <div className='voice-note-delete-text'>
                                    <InfoCircleOutlined style={{ color: "#D34612" }} /><Text>Do you want to delete it?</Text>
                                </div>
                                <div className='voice-note-delete-buttons'>
                                    <Button className='voice-note-delete-button' style={{ background: "none" }} onClick={() => setDeleteAudio(false)}>
                                        No
                                    </Button>
                                    <Button className='voice-note-delete-button' type="primary" onClick={() => {
                                        setDeleteAudio(false);
                                        deleteRecording();
                                    }}>
                                        Yes
                                    </Button>
                                </div>
                            </div> : null
                    }
                    {
                        isRecording || audioURL ?
                            <div className='voice-record-container'>
                                {isRecording ? <>
                                    <div className='voice-record-buttons'>
                                        {/* <Button className='voice-record-button'>
                                    <PauseCircleTwoTone twoToneColor="#F5222D" />
                                </Button> */}
                                        <Button className='voice-record-button' onClick={stopRecording}>
                                            <StopTwoTone twoToneColor="#F5222D" />
                                        </Button>
                                    </div>
                                    <div className='voice-record-timer'>
                                        <Text style={{ color: "#F5222D" }}>Recording</Text>
                                    </div>
                                </> : null}
                                {audioURL && !isRecording ?
                                    <>
                                        <div className="voice-record-buttons">
                                            <Button className='voice-record-button' onClick={audioPlay}>
                                                {playing ?
                                                    <PauseCircleTwoTone twoToneColor="#F5222D" />
                                                    :
                                                    <PlayCircleTwoTone twoToneColor="#F5222D" />
                                                }
                                            </Button>
                                            <audio id="myAudio">
                                                <source src={audioURL} type="audio/mpeg" />
                                            </audio>
                                        </div>
                                        {playing ? <div className="play-content">
                                            <div className="hp_range" id="hp_range"></div>
                                        </div> : null}
                                    </> : null}
                                <div className='voice-record-buttons'>
                                    <Button className='voice-record-button' onClick={() => {
                                        stopRecording();
                                        setDeleteAudio(true);
                                    }}>
                                        <DeleteOutlined style={{ color: "#BFBFBF" }} />
                                    </Button>
                                </div>
                            </div> : null
                    }
                </div>

                <div className='form-input-field'>
                    <Text className='form-input-fieldname'>Upload Proof<Text style={{ color: "#8C8C8C" }}>(optional)</Text></Text>
                    <Upload
                        customRequest={({ file, onSuccess }) => {
                            setTimeout(() => {
                                onSuccess("ok");
                            }, 0);
                        }}
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        className='form-input-fieldvalue upload-button-container'
                    >
                        {fileList.length >= 4 ? null : uploadButton}
                    </Upload>
                    {previewOpen && <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                        <img
                            alt="example"
                            style={{
                                width: '100%',
                            }}
                            src={previewImage}
                        />
                    </Modal>}
                </div>
                <Button className='submit-button'
                    onClick={raiseIssueToFD}
                    disabled={ submitClicked || !candidateValue?.trim() || isRecording || !issueTypeValue?.trim() || !issueDescriptionValue?.trim() || (candidateCardData?.companyName && clientSpecificMandatoryFieldMap[candidateCardData?.companyName?.toLowerCase()]?.filter((field) => !additionalMandatoryFields[field])?.length)}
                >Submit</Button>
            </div>
        </SideSheetComponent>)
};

export default RaiseIssueSider