import * as React from "react";
const CopyTextCheckMark = ({fillColor='none',height=24,width=24,strokeColor="#40B746"}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 9V19.4C3 19.9601 3 20.2401 3.10899 20.454C3.20487 20.6422 3.35774 20.7951 3.5459 20.891C3.75981 21 4.04005 21 4.6001 21H15.0001M17 8L13 12L11 10M7 13.8V6.20001C7 5.07991 7 4.51986 7.21799 4.09204C7.40973 3.71572 7.71547 3.40973 8.0918 3.21799C8.51962 3 9.08009 3 10.2002 3H17.8002C18.9203 3 19.48 3 19.9078 3.21799C20.2841 3.40973 20.5905 3.71572 20.7822 4.09204C21.0002 4.51986 21.0002 5.07992 21.0002 6.20002V13.8C21.0002 14.9201 21.0002 15.4801 20.7822 15.908C20.5905 16.2843 20.2841 16.5902 19.9078 16.782C19.48 17 18.9203 17 17.8002 17H10.2002C9.08009 17 8.51962 17 8.0918 16.782C7.71547 16.5902 7.40973 16.2843 7.21799 15.908C7 15.4801 7 14.9201 7 13.8Z"
      stroke={strokeColor}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CopyTextCheckMark;
