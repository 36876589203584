import React, {useState} from 'react';
import { Tag, Select } from 'antd';
import { getLocationForFilter } from './../../service/commonService'
import commonFunc from "../../utils/commonFunction";

const LocationFilterComponent = (props) => {

    const { onClickTags, label, locationList, setLocationList } = props;

    const [searchLocationList, setSearchLocationList] = useState([]);
    const { Option } = Select;
    let timeoutForLocation, currentLocationValue;

    const handleChangeSelectLoction = (valueArr) => {
        let arrObj = [];
        if (valueArr.length === 0) {
            arrObj = []
        } else {
            valueArr.map(item => {
                arrObj.push({ label: item, value: true })
            })
            let result = [...arrObj, ...locationList];
            setLocationList(commonFunc.uniquefilteredArrObj(result, "label"))
        }
    }

    const fetchLocation = (location, callback) => {
        if (timeoutForLocation) {
            clearTimeout(timeoutForLocation);
            timeoutForLocation = null;
        }
        currentLocationValue = location;
        const debounceDataForLocation = () => {
            let data = {
                search: location
            }
            getLocationForFilter(data).then(response => {
                if (currentLocationValue === location) {
                    callback(response.data.locationList);
                }
            })
        };
        timeoutForLocation = setTimeout(debounceDataForLocation, 250);
    };

    const handleLocationSearch = (newValue) => {
        if (newValue && newValue.length >= 2) {
            fetchLocation(newValue, setSearchLocationList);
        } else {
            setSearchLocationList([]);
        }
    }

    return (
        <>
            <Select
                mode="multiple"
                style={{
                    width: '100%',
                }}
                placeholder="Search Location(s)"
                onChange={handleChangeSelectLoction}
                onSearch={handleLocationSearch}
                allowClear
            >
                {searchLocationList.map(item => (
                    <Option key={`option_location_search_${item}`} value={item}>
                    </Option>
                ))}
            </Select>
            <div className='tag-wrapper-container'>
                {locationList.map((item, index) => {
                    return <Tag className={!(item.value) ? 'tags' : 'tags tag-active'}
                        key={index}
                        // closable={item.value}
                        onClick={() => { onClickTags(label, index) }}
                    >
                        {item.label || item.name}
                    </Tag>
                })}
            </div>
        </>
    )
}

export default LocationFilterComponent