import React, { useContext, useEffect, useState } from 'react'
import UploadedFileList from './UploadedFileList';
import IndividualFileResults from './IndividualFileResults';
import LatestProcessedCandidateCards from './LatestProcessedCandidateCards';
import { notification } from 'antd';
import { AppContext } from '../../App';
import { getUploadedListOfFiles } from '../../service/SamvadiniApiService';
import SamvadiniFileUpload from './SamvadiniFileUpload';
import CONSTANTS from '../../constants/constants';

function TabContainer() {
    const {mitraReducer} = useContext(AppContext);
    const [mitraId, setMitraId] = useState(mitraReducer?.mitraInfo?.id);
    const [activeTab, setActiveTab] = useState('latest-processed-candidates');
    const [sessionList, setSessionList] = useState([]);
    const [sessionDetailId, setSessionDetailId] = useState(null);
    const [allowToCreateNewSession, setAllowToCreateNewSession] = useState(false);
    const [tabsInfo, setTabsInfo] = useState([
        {
            label: 'Recent Candidates',
            isActive: true,
            id: 'latest-processed-candidates'
        },
        {
            label: 'Uploaded Sessions',
            isActive: false,
            id: 'uploaded-files'
        }
    ]);

    const onTabClick = (tabId) => {
        const newTabsInfo = [...tabsInfo];

        for (let i = 0; i < newTabsInfo.length; i++) {
            if(newTabsInfo[i].id === tabId) {
                newTabsInfo[i].isActive = true;
                setActiveTab(tabId);
            } else {
                newTabsInfo[i].isActive = false;
            }
        }

        setTabsInfo([...newTabsInfo]);
    }

    const getAllSessionList = async() => {
        try{
            const payload = {
                mitraId: mitraReducer?.mitraInfo?.id
            }

            const response = await getUploadedListOfFiles(payload);

            if(response?.data?.sessions?.length) {
                setAllowToCreateNewSession(response.data.allowToCreateNewSession);
                setSessionList([...response.data.sessions]);
                setSessionDetailId(response?.data?.sessions[0]?.intentCallingSessionId);
            } else {
                setAllowToCreateNewSession(response.data.allowToCreateNewSession);
                setSessionList([]);
                setSessionDetailId(null);
            }
        } catch (e) {
            setSessionList([]);
            setSessionDetailId(null);
            setAllowToCreateNewSession(false);
            notification['error'] ({
                message: 'Something went wrong while fetching list of files.'
            })
        }
    }

    useEffect(() => {
        if(mitraId) {
            getAllSessionList()
        }
    }, [mitraId])

    useEffect(() => {
      if(mitraReducer?.mitraInfo?.id) {
        setMitraId(mitraReducer.mitraInfo.id);
      }
    }, [mitraReducer])
    
    

  return (
    <>
        {/* <div className='samvadini-upload-header mt-24'>
            Some Header
        </div> */}

        {
            (
                !CONSTANTS.samvadiniCallingEligibleIds.includes(mitraReducer?.mitraInfo?.id)
            ) ? null : (
                <SamvadiniFileUpload getAllSessionList={getAllSessionList} sessionList={sessionList} allowToCreateNewSession={allowToCreateNewSession}/>
            )
        }

        <div className="tabs-container" style={{margin: '1rem 24px 0', width: 'auto', background: '#f1f1f1', borderRadius: '12px'}}>
            {
                tabsInfo.map((item, index) => {
                    return (
                        <button onClick={()=>onTabClick(item.id)} key={item.id} className={`${item?.isActive ? 'tab active-tab': 'tab border-bottom-white'}`} >
                            {item.label}
                        </button>
                    )
                })
            }
        </div>

        <div className='samvadini-tabs-content-container'>
            {
                activeTab === 'latest-processed-candidates' ? (
                    <>
                        <LatestProcessedCandidateCards sessionDetailId={sessionDetailId}/>
                    </>
                ): (
                    <UploadedFileList noHeader={true} sessionList={sessionList}/>
                )
            }
        </div>
    </>
  )
}

export default TabContainer