import { Button, Popover } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom';

function SessionCreationCard({props,isSessionCreationAllowed}) {
    const history = useHistory();
    const {
        image,
        isHighlighted,
        header,
        subHeader,
        buttonText,
        onButtonClick,
        showFlag,
        flagLabel,
        flagBgColor
        } = props;

    const returnButton = () => (
        <Button 
        onClick={()=>onButtonClick(history)} 
        disabled={!isSessionCreationAllowed}
        className={`option-btn ${!isSessionCreationAllowed ? 'disabled-btn-v2':'new-btn'}`}> 
            <span className='option-btn-text'>
                {buttonText}
            </span>
        </Button>
    )

    
    const returnCardContents = () => {
        return (
            <div className="single-option">
                <img src={image} className='option-img' />

                <div className="option-header-container">
                    <span className='option-header'>{header}</span>
                    {
                        showFlag ? (
                            <div className='option-flag' style={{background: flagBgColor}}>{flagLabel}</div>
                        ): null
                    }
                </div>
                
                <span className='option-sub-header'>{subHeader}</span>

                {
                    !isSessionCreationAllowed ? (
                        <Popover
                        content={<span className='inter-regular-10-16'>Wait for the current session to finish before starting another.</span>} 
                        // title={<div className='bold14-22'>{allowAIFilter ? 'AI Filtering': 'Not Available'}</div>} 
                        trigger={'hover'}
                        // className='ai-filtering-popup'
                        // getPopupContainer={(trigger) => trigger.parentNode}
                        placement='bottom'
                        >
                            {returnButton()}
                        </Popover>
                    ): returnButton()
                }
                
            </div>
        )
    }
  return (
    <>
        {
            isHighlighted ? (
                <div className="highlighted-option shadow-highlighter">
                    {returnCardContents()}
                </div>
            ): (
                returnCardContents()
            )
        }
    </>
  )
}

export default SessionCreationCard