import React, { useEffect, useState } from 'react'

function useDebouncedValue(value, delay=500) {
    const [debouncedValue, setDebouncedValue] = useState('');

    useEffect(() => {
      const debounceTimeout = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return ()=> {
        if(debounceTimeout) {
            clearTimeout(debounceTimeout)
        }
      }
    }, [value, delay])
    

    return debouncedValue
}

export default useDebouncedValue;
