import React from "react";
import './LevelDetails.scss';
import { Timeline,Typography, Collapse } from "antd";

const {Text} = Typography;
const {Panel} = Collapse;


const LevelDetail = () => {
    return (
        <div style={{display:'flex', flexDirection:'column'}}>
            <div className='d-flex justify-content-between align-items-center' style={{gap:'5px'}}>
                <img src='/image/progress-bar-initial.png' alt='starting-point' />
                <div className="level-indicators"></div>
                <div className="level-indicators"></div>
                <div className="level-indicators"></div>
                <div className="level-indicators"></div>
                <div className="level-indicators"></div>
                <img src='/image/progress-bar-bronze.png' alt='starting-point' />
            </div>
            <Timeline style={{marginTop:'15px'}}>
                <Timeline.Item>
                    <div style={{display:"flex", flexDirection:'column'}}>
                        <strong className='d-flex'>Bronze Vahan Leader</strong>
                        <Text className='d-flex'>Earned on 50 Placements/Month</Text>
                        {/* <Text style={{display:'flex',justifyContent:'flex-start', marginTop:'1.5em', textDecoration:'underline',color:'red'}}><PlayCircleFilled />Let&apos;s get started</Text> */}
                    </div>
                </Timeline.Item>
                <Timeline.Item color="gray" style={{opacity: '0.5'}}>
                    <div style={{display:"flex", flexDirection:'column'}}>
                        <strong className='d-flex'>Silver Vahan Leader</strong>
                        <Text>Earned on 50 Placements/Month</Text>
                    </div>
                </Timeline.Item>
                <Timeline.Item color="gray" style={{opacity: '0.5'}}>
                    <div style={{display:"flex", flexDirection:'column'}}>
                        <strong className='d-flex'>Gold Vahan Leader</strong>
                        <Text>Earned on 50 Placements/Month</Text>
                    </div>
                </Timeline.Item>
            </Timeline>
        </div>
    )
};

export default LevelDetail;
