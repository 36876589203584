import React, { useContext, useState } from 'react'
import { Button, Divider } from 'antd';
import { ArrowRightOutlined, EnvironmentOutlined, ClockCircleOutlined, CalendarOutlined, LinkOutlined, CheckCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import '../style/BulkActionsContainerStyle.scss'
import '../style/BulkActionsListingStyle.scss'
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../App';

const BulkActionsListing = ({ listData =
    {
        "bulkUploadId": "",
        "uploadedBy": "",
        "totalLeads": 0,
        "uploadedURL": "",
        "errorReportUrl": "",
        "processStatus": "",
        "bulkUploadUniquenessLeadId": "",
        "metaData": {
            "companyName": "",
            "jobLocation": "",
            "category": ""
        },
        "city": "",
        "uploadedAt": "",
        "processedLeads": 0,
        "nonUnique": 0,
        "unique": 0,
        "failedCount": 0,
    }
}) => {
    const [currentTab, setCurrentTab] = useState('bulk-referrals');
    const history = useHistory();
    const { mitraDispatch } = useContext(AppContext);
    const getLinkToFile = () => {
        if (listData) {
            const str = listData.uploadedURL;
            const delimiter = '/';

            // Find the index of the last occurrence of the delimiter
            const delimiterIndex = str.lastIndexOf(delimiter);

            // Extract the substring after the last delimiter
            return delimiterIndex !== -1 ? str.substring(delimiterIndex + 1) : str;
        }
    }

    const onDownloadStatusClicked = () => {
        const link = document.createElement('a');
        link.href = listData.errorReportUrl;
        link.download = 'response.xlsx'; // Specify the desired filename

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    }

    const onDownloadErrorFileClicked = () => {
        const link = document.createElement('a');
        link.href = listData.validationReportUrl;
        link.download = 'validation-error.xlsx'; // Specify the desired filename

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    }

    const goToSamvaadiniSessionDetails = (aiFilteringSession) => {
        mitraDispatch({type: 'UTILITIES', value: {currentMenu: 'SAMVADINI'}});
        history.push(`/samvadini-session-details?intentCallingSessionId=${aiFilteringSession?.intentCallingSessionId}&sessionDetails=${JSON.stringify(aiFilteringSession)}`);
    }

    return (
        <div className='bulk-actions-listing-parent'>
            {
                listData && Object.keys(listData).length > 0 ?
                    <div className='d-flex' style={{ flexDirection: "column" }}>
                        <div className='ba-above-divider d-flex justify-content-between'>
                            <div className='d-flex' style={{ flexDirection: "column" }}>
                                <div className='d-flex align-items-center'>
                                    <div className='ba-uploaded-on-txt'>
                                        Uploaded on
                                    </div>
                                    <div className='ba-uploaded-on-date d-flex align-items-center'>
                                        <CalendarOutlined className='ba-uploaded-on-img ba-card-icon-margin' />
                                        <div className='ba-card-txt'>
                                            {moment(listData.uploadedAt).format("Do MMM YYYY")}
                                        </div>
                                    </div>
                                    <div className='ba-uploaded-on-time d-flex align-items-center'>
                                        <ClockCircleOutlined className='ba-uploaded-on-img ba-card-icon-margin' />
                                        <div className='ba-card-txt'>
                                            {moment(listData.uploadedAt).format("hh:mm A")}
                                        </div>
                                    </div>
                                </div>
                                <div className='ba-file-link d-flex align-items-center'>
                                    <LinkOutlined className='ba-file-link-img' />
                                    <div className='ba-file-name-txt'>
                                        {getLinkToFile()}
                                    </div>
                                </div>
                            </div>

                            <div className="ba-process-status-parent d-flex align-items-start flex-wrap">
                                {/* {
                                    listData && listData.processStatus && (listData.processStatus.toLocaleLowerCase() == "completed" || listData.processStatus.toLocaleLowerCase() == "created" || listData.processStatus.toLocaleLowerCase() == "processed") ?
                                        <div className='ba-process-status-completed d-flex align-items-center'>
                                            <CheckCircleOutlined />
                                            <div className='ba-process-status-txt'>
                                                {listData.processStatus[0].toUpperCase() + listData.processStatus.slice(1)}
                                            </div>
                                        </div> :

                                        listData && listData.processStatus && listData.processStatus.length > 0 ?
                                            <div className='ba-process-status-not-completed d-flex align-items-start'>
                                                <div className='ba-process-status-processing d-flex align-items-center'>
                                                    <ClockCircleOutlined />
                                                    <div className='ba-process-status-txt'>
                                                        {listData.processStatus[0].toUpperCase() + listData.processStatus.slice(1)}
                                                    </div>
                                                </div>
                                                <div className='ba-processing-txt'>
                                                    Updated a few minutes ago
                                                </div>
                                            </div>
                                            :
                                            null
                                } */}

                                {
                                    listData?.bulkReferralStatus ? (
                                        <>
                                            {
                                                listData?.bulkReferralStatus?.subHeading?.length ? (
                                                    <div className='ba-process-status-not-completed d-flex align-items-start'>
                                                        <div className='ba-process-status-processing d-flex align-items-center'>
                                                            <ClockCircleOutlined />
                                                            <div className='ba-process-status-txt' style={{color: listData?.bulkReferralStatus?.headingColorCode || '#D48806'}}>
                                                                {listData?.bulkReferralStatus?.heading}
                                                            </div>
                                                        </div>
                                                        <div className='ba-processing-txt' style={{color: listData?.bulkReferralStatus?.subHeadingColorCode || '#00000073'}}>
                                                            {listData?.bulkReferralStatus?.subHeading}
                                                        </div>
                                                    </div>
                                                ):(
                                                    <div className='ba-process-status-completed d-flex align-items-center'>
                                                        <CheckCircleOutlined />
                                                        <div className='ba-process-status-txt' style={{color: listData?.bulkReferralStatus?.headingColorCode || '#D48806'}}>
                                                            {listData?.bulkReferralStatus?.heading}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </>
                                    ):null
                                }

                                {
                                    // Samvaadini processing status

                                    listData?.aiFilteringStatus ? (
                                        listData?.aiFilteringStatus?.subHeading ? (
                                        <>
                                            <div className='ba-process-status-not-completed d-flex align-items-start'>
                                                <div className='ba-process-status-processing d-flex align-items-center'>
                                                    <ClockCircleOutlined style={{color: listData?.aiFilteringStatus?.headingColorCode || '#000000A6'}}/>
                                                    <div className='ba-process-status-txt' style={{color: listData?.aiFilteringStatus?.headingColorCode || '#000000A6'}}>
                                                        {listData?.aiFilteringStatus?.heading}
                                                    </div>
                                                </div>
                                                <div className='ba-processing-txt' style={{color: listData?.bulkReferralStatus?.subHeadingColorCode || '#00000073'}}>
                                                    {listData?.aiFilteringStatus?.subHeading}
                                                </div>
                                            </div>
                                        </>
                                    ):(
                                        <>
                                            <div className='ba-process-status-completed d-flex align-items-center'> 
                                                {
                                                  listData?.aiFilteringStatus?.heading?.toLowerCase() === 'ai filtering session ongoing' ? (
                                                    <ClockCircleOutlined style={{color: listData?.aiFilteringStatus?.headingColorCode || '#000000A6'}}/>
                                                  ) : (
                                                    <CheckCircleOutlined style={{color: listData?.aiFilteringStatus?.headingColorCode || '#D48806'}}/>
                                                  ) 
                                                }
                                                
                                                <div className='ba-process-status-txt' style={{color: listData?.aiFilteringStatus?.headingColorCode || '#D48806'}}>
                                                    {listData?.aiFilteringStatus?.heading}
                                                </div>
                                            </div>
                                        </>
                                    )
                                    ):null
                                }
                            </div>
                        </div>

                        <Divider className='ba-card-divider' />

                        <div className='ba-below-divider d-flex'>
                            <div className='ba-below-divider-left'>
                                <div className='d-flex align-items-center'>
                                    <div className='ba-company-img-parent'>
                                        <img src={listData.logo ? listData.logo : "/image/vahan-new-logo.png"} alt='level' className='ba-company-img'></img>
                                    </div>
                                    <div className='ba-delivery-txt'>
                                        Delivery
                                    </div>
                                </div>

                                <div className='ba-location-txt-parent d-flex align-items-center'>
                                    <div className='ba-location-txt d-flex align-items-center'>
                                        <EnvironmentOutlined className='ba-location-img' />
                                        {listData.city ? listData.city : ""}
                                    </div>

                                    {listData.metaData && listData.metaData.jobLocation ?
                                        <div className='ba-round-circle' /> : null
                                    }

                                    <div className='ba-location-txt ba-area-txt'>
                                        {listData.metaData && listData.metaData.jobLocation ? listData.metaData.jobLocation : ""}
                                    </div>
                                </div>
                            </div>

                            <div className='ba-vertical-divider'>

                            </div>

                            <div className='ba-below-divider-right'>
                                <div className='ba-number-of-leads d-flex align-items-center'>
                                    {listData.totalLeads} Leads
                                </div>

                                <div className='ba-leads-breakdown-parent'>
                                    {
                                        listData.processStatus == "processing" ?
                                            <div className='ba-leads-breakdown-processing'>
                                                <div className='ba-leads-processing-container'>
                                                </div>
                                            </div>
                                            :
                                            <div style={{ width: "100%", display: "flex", alignItems: "center", borderRadius: "12px" }}>
                                                {
                                                    (listData.totalLeads - listData.processedLeads - listData.failedCount) > 0 ?
                                                        <div className='ba-leads-processing ba-leads-breakdown-border-left' style={{ width: `${(listData.totalLeads - listData.processedLeads - listData.failedCount) / listData.totalLeads * 100}%` }}>
                                                        </div>
                                                        :
                                                        null
                                                }
                                                <div className={`ba-leads-unique ${(listData.totalLeads - listData.processedLeads - listData.failedCount) > 0 ? "" : "ba-leads-breakdown-border-left"}`} style={{ width: `${(listData.unique / listData.totalLeads) * 100}%` }}>
                                                </div>
                                                <div className={`ba-leads-non-unique ${listData.unique == 0 && (listData.totalLeads - listData.processedLeads - listData.failedCount) == 0 ? "ba-leads-breakdown-border-left" : ""} ${listData.failedCount == 0 ? "ba-leads-breakdown-border-right" : ""}`} style={{ width: `${(listData.nonUnique / listData.totalLeads) * 100}%` }}>
                                                </div>
                                                <div className={`ba-leads-error ba-leads-breakdown-border-right ${listData.unique == 0 && (listData.totalLeads - listData.processedLeads - listData.failedCount) == 0 ? "ba-leads-breakdown-border-left" : ""}`} style={{ width: `${(listData.failedCount / listData.totalLeads) * 100}%` }}>
                                                </div>
                                            </div>
                                    }

                                </div>

                                <div className='ba-leads-breakdown-chips-parent d-flex align-items-center'>
                                    {
                                        (listData.totalLeads - listData.processedLeads - listData.failedCount) > 0 ?
                                            <div className='ba-leads-breakdown-chip d-flex align-items-center'>
                                                <div className='ba-leads-breakdown-processing-circle'>

                                                </div>
                                                <div className='ba-chip-txt-parent'>
                                                    <span className='ba-chip-txt'>
                                                        Processing
                                                    </span>
                                                    <span className='ba-chip-value-txt'>
                                                        {listData.totalLeads - listData.processedLeads - listData.failedCount}
                                                    </span>
                                                </div>
                                            </div> : null
                                    }

                                    {
                                        listData.unique > 0 ?
                                            <div className='ba-leads-breakdown-chip d-flex align-items-center'>
                                                <div className='ba-leads-breakdown-unique-circle'>

                                                </div>
                                                <div className='ba-chip-txt-parent'>
                                                    <span className='ba-chip-txt'>
                                                        Unique
                                                    </span>
                                                    <span className='ba-chip-value-txt'>
                                                        {listData.unique}
                                                    </span>
                                                </div>
                                            </div> : null
                                    }


                                    {listData.nonUnique > 0 ?
                                        <div className='ba-leads-breakdown-chip d-flex align-items-center'>
                                            <div className='ba-leads-breakdown-non-unique-circle'>

                                            </div>
                                            <div className='ba-chip-txt-parent'>
                                                <span className='ba-chip-txt'>
                                                    Non Unique
                                                </span>
                                                <span className='ba-chip-value-txt'>
                                                    {listData.nonUnique}
                                                </span>
                                            </div>
                                        </div> : null}


                                    {listData.failedCount > 0 ?
                                        <div className='ba-leads-breakdown-chip d-flex align-items-center'>
                                            <div className='ba-leads-breakdown-errors-circle'>

                                            </div>
                                            <div className='ba-chip-txt-parent'>
                                                <span className='ba-chip-txt'>
                                                    Errors
                                                </span>
                                                <span className='ba-chip-value-txt'>
                                                    {listData.failedCount}
                                                </span>
                                            </div>
                                        </div> : null}

                                </div>
                            </div>
                        </div>

                        <Divider className='ba-card-divider' />

                        <div className='ba-below-second-divider'>
                            {
                                listData?.validationReportUrl ? (
                                        <Button className='ba-validation-error-cta' onClick={() => onDownloadErrorFileClicked()}>
                                            <DownloadOutlined color='#D34B18' />
                                            <div className='ba-download-candidate-status-btn-txt-color-red'>
                                                Download Error File
                                            </div>
                                        </Button>
                                ) : null
                            }

                            <Button className='ba-download-candidate-status-btn d-flex align-items-center ba-cp secondary-btn-styles' disabled={!listData?.errorReportUrl} onClick={() => onDownloadStatusClicked()}>
                                <DownloadOutlined className='ba-download-candidate-status-btn-img secondary-btn-text' />
                                <div className='ba-download-candidate-status-btn-txt secondary-btn-text'>
                                    Download Candidate Status
                                </div>
                            </Button>

                            {
                                listData?.aiFilteringInfo ? (
                                    <Button style={{opacity: listData?.processStatus?.toLowerCase() === "processed" ? 1: 0.5}} className='ba-download-candidate-status-btn d-flex align-items-center ba-cp' disabled={listData?.metaData?.samvaadhniSessionStatus?.toLowerCase() === "pending"} onClick={() => goToSamvaadiniSessionDetails(listData?.aiFilteringInfo)}>
                                        <div className='ba-download-candidate-status-btn-txt'>
                                            Go to AI Filtering Session
                                        </div>
                                        <ArrowRightOutlined  className='ba-download-candidate-status-btn-img margin-left-12' />
                                    </Button>
                                ): null
                            }

                            
                            
                        </div>

                    </div> : null
            }
        </div>
    )
}

export default BulkActionsListing; 
