import axios from 'axios';
import axiosInstance from './interceptorService';
axiosInstance.defaults.timeout = 600000;

export const getDashboardData = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/getPerformanceDashboardData` ;
    return axiosInstance.get(url, {
        params: data ,
        withCredentials: true
    }, );
}

export const getCandidatesData = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/getCandidates` ;
    return axiosInstance.get(url, {
        params: data ,
        withCredentials: true
    }, );
}

export const getOverallTeamCount = async (params, cancelToken) => {
    const query = Object.keys(params)
        .filter(key => params[key] !== undefined && params[key] !== null && params[key] !== '')
        .map(key => `${encodeURIComponent(key)}=${params[key]}`)
        .join('&');
    let urlWithParams = `${process.env.REACT_APP_BACKEND_URL}/overAllTeamData?${query}`
    return axiosInstance.get(urlWithParams, { withCredentials: true, cancelToken });
};

export const getAllStatuses = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getAllStatusOfLead` ;
    return axiosInstance.post(url, data ,{ withCredentials: true });
}

export const downloadPerformanceSheet = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/download/performanceDashboardData` ;
    return axiosInstance.get(url, { params: data, withCredentials: true });
}

export const downloadLeadStatusSheet = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/download/getCandidatesData` ;
    return axiosInstance.get(url, { params: data, withCredentials: true });
}

export const getPerformanceDashboardData = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/getOverallSummaryData` ;
    return axiosInstance.get(url, { params: data, withCredentials: true });
}

export const getCLientPeferenceData = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/clientWisePerformanceData` ;
    return axiosInstance.get(url, { params: data, withCredentials: true });
}

export const getFlipkartAttendanceData = ( data ) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/getFlipkartPerformanceDashboardAttendanceData`;
    return axiosInstance.get(url, { params: data, withCredentials: true });
}

export const getTeamLeaderBoardData = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/getTeamLeaderBoardData` ;
    return axiosInstance.get(url, { params: data, withCredentials: true });
}

export const getPerformanceHistory = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/getPerformanceHistory` ;
    return axiosInstance.get(url, { params: data, withCredentials: true });
}

export const DownloadTeamLeaderBoardData = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/download/leaderBoardPageData` ;
    return axiosInstance.get(url, { params: data, withCredentials: true });
}

export const DownloadPerformanceDashboardCandidatePageData = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/download/performanceDashboardCandidatePageData` ;
    return axiosInstance.get(url, { params: data, withCredentials: true }, {timeout: 2});
}

export const getPerformanceDashboardCandidateList = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/getPerformanceDashboardCandidateList` ;
    return axiosInstance.get(url, { params: data, withCredentials: true });
}

export const DownloadReportsPageData = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/download/PerformanceDashboardData` ;
    return axiosInstance.get(url, { params: data, withCredentials: true });
}

export const getMonthlyPayout = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/payout/monthlyPayout` ;
    return axiosInstance.get(url, { params: data, withCredentials: true,headers:{
        payoutAccessToken:localStorage.getItem('payoutAccessToken')
    } });
}

export const getMonthlyPayoutBreakup = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/payout/monthlyPayoutBreakup` ;
    return axiosInstance.get(url, { params: data, withCredentials: true,headers:{
        payoutAccessToken:localStorage.getItem('payoutAccessToken')
    }  });
}

export const downloadMonthlyPayoutBreakup = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/download/payoutPageData` ;
    return axiosInstance.get(url, { params: data, withCredentials: true ,headers:{
        payoutAccessToken:localStorage.getItem('payoutAccessToken')
    } });
}

export const downloadPayoutLeads = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/download/payout/leads` ;
    return axiosInstance.get(url, { params: data, withCredentials: true ,headers:{
        payoutAccessToken:localStorage.getItem('payoutAccessToken')
    } });
}

export const getReportPayoutLeadsList = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/payout/leads`;
    return axiosInstance.get(url, {withCredentials: true, params: data,headers:{
        payoutAccessToken:localStorage.getItem('payoutAccessToken')
    } });
};

export const showPayoutDashboard = (mitra) => {
    if(mitra && mitra.role === 'admin') {
        return true;
    }
    return false;
}

export const getFilterListForTargetFeature = () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getFilterListForTargetFeature`;
    return axiosInstance.get(url, { withCredentials: true });
}

export const getRunRateCardData = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/fetchLeadReferralRatesForML`;
    return axiosInstance.get(url, { params: data, withCredentials: true });
}

export const getPerformanceDashboardTabDetails = (payload) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/getPerfDashOverallList`
    return axiosInstance.post(url, payload, {withCredentials: true});
}

export const updateTargetForMls = (payload) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/updateTargetForMls`
    return axiosInstance.post(url, payload, {withCredentials: true});
}

export const getMitraMilestoneCount = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getMitraMilestoneCount?mitraId=${data.mitraId}&milestone=first_date_of_work`;
    return axiosInstance.get(url, {withCredentials: true });
}