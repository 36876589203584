import React, { useContext, useState } from 'react'
import { Button, Divider} from 'antd';
import { ArrowRightOutlined, EnvironmentOutlined, ClockCircleOutlined, FilterOutlined, CalendarOutlined, LinkOutlined, CheckCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import '../style/BulkActionsContainerStyle.scss'
import '../style/BulkActionsListingStyle.scss'
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../../App';

const BulkUploadListing = ({ listData }) => {
    const [currentTab, setCurrentTab] = useState('bulk-referrals');
    const { mitraDispatch } = useContext(AppContext);
    const getLinkToFile = () => {
        if (listData) {
            const str = listData.uploadedURL;
            const delimiter = '/';

            // Find the index of the last occurrence of the delimiter
            const delimiterIndex = str.lastIndexOf(delimiter);

            // Extract the substring after the last delimiter
            return delimiterIndex !== -1 ? str.substring(delimiterIndex + 1) : str;
        }
    }

    const onDownloadErrorFileClicked = () => {
        const link = document.createElement('a');
        link.href = listData.errorReportUrl;
        link.download = 'format-error.xlsx'; // Specify the desired filename

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    }

    return (
        <div className='bulk-actions-listing-parent'>
            {
                listData && Object.keys(listData).length > 0 ?
                    <div className='d-flex' style={{ flexDirection: "column" }}>
                        <div className='ba-above-divider d-flex justify-content-between'>
                            <div className='d-flex' style={{ flexDirection: "column" }}>
                                <div className='d-flex align-items-center'>
                                    <div className='ba-uploaded-on-txt' style={{color: '#000000A6'}}>
                                        Uploaded on
                                    </div>
                                    <div className='ba-uploaded-on-date d-flex align-items-center'>
                                        <CalendarOutlined className='ba-uploaded-on-img ba-card-icon-margin' />
                                        <div className='ba-card-txt'>
                                            {moment(listData.uploadedAt).format("Do MMM YYYY")}
                                        </div>
                                    </div>
                                    <div className='ba-uploaded-on-time d-flex align-items-center'>
                                        <ClockCircleOutlined className='ba-uploaded-on-img ba-card-icon-margin' />
                                        <div className='ba-card-txt'>
                                            {moment(listData.uploadedAt).format("hh:mm A")}
                                        </div>
                                    </div>
                                </div>
                                <div className='ba-file-link d-flex align-items-center'>
                                    <div className='ba-upload-txt-parent'>
                                        <span className='ba-upload-txt'>
                                            Upload
                                        </span>
                                    </div>
                                    <LinkOutlined className='ba-file-link-img' />
                                    <div className='ba-file-name-txt'>
                                        {getLinkToFile()}
                                    </div>
                                </div>
                            </div>

                            <div className="ba-process-status-parent d-flex align-items-start flex-wrap">

                                {
                                    listData?.processState ? (
                                        <>
                                            {
                                                listData?.processState != 'completed' ? (
                                                    <div className='ba-process-status-not-completed d-flex align-items-start'>
                                                        <ClockCircleOutlined />
                                                        <div className='ba-process-status-txt' style={{color: '#D48806'}}>
                                                            {listData?.processState}
                                                        </div>
                                                    </div>
                                                ):(
                                                    <div className='ba-process-status-completed d-flex align-items-center'>
                                                        <CheckCircleOutlined />
                                                        <div className='ba-process-status-txt' style={{color: '#31C824'}}>
                                                            {listData?.processState}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </>
                                    ):null
                                }
                            </div>
                        </div>

                        <Divider className='ba-card-divider' />

                        <div className='ba-below-divider d-flex'>
                            <div className='ba-below-divider-right'>
                                <div className='ba-number-of-leads d-flex align-items-center' style={{color: '#000000A6'}}>
                                {listData?.metaData?.totalCount} Leads
                                </div>

                                <div className='ba-leads-breakdown-parent'>
                                    {
                                        listData.processState === "processing" ?
                                            <div className='ba-leads-breakdown-processing'>
                                                <div className='ba-leads-processing-container'>
                                                </div>
                                            </div>
                                            :
                                            <div style={{ width: "100%", display: "flex", alignItems: "center", borderRadius: "12px" }}>
                                                <div className={`ba-leads-unique ba-leads-breakdown-border-left ${listData?.metaData?.errorCount == 0 ? "ba-leads-breakdown-border-right" : ""}`} style={{ width: `${((listData?.metaData?.totalCount - listData?.metaData?.errorCount) / listData?.metaData?.totalCount) * 100}%` }}>
                                                </div>
                                                <div className={`ba-upload-leads-error ba-leads-breakdown-border-right ${(listData?.metaData?.totalCount - listData?.metaData?.errorCount) == 0 ? "ba-leads-breakdown-border-left" : ""}`} style={{ width: `${(listData?.metaData?.errorCount / (listData?.metaData?.totalCount)) * 100}%` }}>
                                                </div>
                                            </div>
                                    }

                                </div>

                                <div className='ba-leads-breakdown-chips-parent d-flex align-items-center'>
                                    {
                                        (listData?.metaData?.totalCount - listData?.metaData?.errorCount) > 0 ?
                                            <div className='ba-leads-breakdown-chip d-flex align-items-center'>
                                                <div className='ba-leads-breakdown-processing-circle' style={{backgroundColor: '#31C824'}}>

                                                </div>
                                                <div className='ba-chip-txt-parent'>
                                                    <span className='ba-chip-txt'>
                                                        Successful
                                                    </span>
                                                    <span className='ba-chip-value-txt'>
                                                        {listData?.metaData?.totalCount - listData?.metaData?.errorCount}
                                                    </span>
                                                </div>
                                            </div> : null
                                    }



                                    {listData?.metaData?.errorCount > 0 ?
                                        <div className='ba-leads-breakdown-chip d-flex align-items-center'>
                                            <div className='ba-leads-breakdown-errors-circle' style={{backgroundColor: '#FF712D'}}>

                                            </div>
                                            <div className='ba-chip-txt-parent'>
                                                <span className='ba-chip-txt'>
                                                    Format Errors
                                                </span>
                                                <span className='ba-chip-value-txt'>
                                                    {listData?.metaData?.errorCount}
                                                </span>
                                            </div>
                                        </div> : null}

                                </div>
                            </div>
                        </div>

                        <Divider className='ba-card-divider' />

                        <div className='ba-below-second-divider'>
                            <Button className='ba-download-candidate-status-btn d-flex align-items-center ba-cp btn-styles' disabled={!listData?.errorReportUrl} onClick={() => onDownloadErrorFileClicked()}>
                                <DownloadOutlined className='ba-download-candidate-status-btn-img btn-text' />
                                <div className='ba-download-candidate-status-btn-txt btn-text'>
                                    Download Candidate Status
                                </div>
                            </Button>
                        </div>

                    </div> : null
            }
        </div>
    )
}

export default BulkUploadListing; 