import * as React from "react"
const CallIcon = ({fillColor='none',height=17,width=17,strokeColor="#252526"}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fillColor}
    viewBox="0 0 17 17"
  >
    <path
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.91 2.299a.917.917 0 0 0-.852-.576h-1.26a.868.868 0 0 0-.87.868A7.382 7.382 0 0 0 9.31 9.973c.48 0 .869-.39.869-.869v-1.26a.917.917 0 0 0-.577-.852L8.394 6.51a.916.916 0 0 0-.927.147l-.312.26A.917.917 0 0 1 5.92 6.86l-.879-.879a.917.917 0 0 1-.055-1.235l.26-.312a.917.917 0 0 0 .146-.927L4.91 2.299Z"
    />
  </svg>
)
export default CallIcon
