import React, { useContext } from 'react'
import { Select, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import './AccountDetailsContainerStyle.scss'
import { AppContext } from '../../../../../App';
import '../../../../../css/globalStyles.scss'

const AccountDetailsContainer = ({ }) => {
    const { Option } = Select;
    const { mitraReducer, mitraDispatch, setAlert } = useContext(AppContext);


    return (
        <div className='account-details-container-parent'>
            <div className='ad-vl-profile-header d-flex'>
                <div className='d-flex align-items-center'>
                    <UserOutlined className='ad-vl-profile-header-icon' />
                    <span className='ad-vl-profile-header-txt semibold16-24 black85'>
                        Vahan Leader Profile
                    </span>
                </div>
                <div className='ad-vl-profile-sub-txt regular14-22 black45'>
                    Your registered personal details
                </div>
            </div>

            <div className='ad-vl-profile-body'>
                <div className='d-flex ad-vl-input-container'>
                    <span className='ad-vl-input-label d-flex justify-content-start align-items-center'>
                        Registered Name
                    </span>
                    <Input
                        className='ad-vl-input ad-vl-name-input'
                        value={mitraReducer?.mitraInfo.name || 'NA'}
                        disabled
                    />
                </div>

                <div className='d-flex ad-vl-input-container'>
                    <span className='ad-vl-input-label d-flex justify-content-start align-items-center'>
                        Registered Mobile
                    </span>
                    <Input
                        className='ad-vl-input ad-vl-number-input'
                        value={mitraReducer?.mitraInfo.phoneNumber || 'NA'}
                        disabled
                    />
                </div>

                <div className='d-flex ad-vl-input-container'>
                    <span className='ad-vl-input-label d-flex justify-content-start align-items-center'>
                        Registered Address
                    </span>
                    <Input
                        className='ad-vl-input ad-vl-address-input'
                        value={mitraReducer?.mitraInfo.homeCity || 'NA'}
                        disabled
                    />
                </div>
            </div>
        </div>
    )
}

export default AccountDetailsContainer; 