const Localization = {
    hi: {
        'Full name': 'पूरा नाम',
        'Enter Full Name': 'पूरा नाम दर्ज करें',
        'Phone Number': 'फ़ोन नंबर',
        'Email Id': 'ईमेल आईडी',
        'City you will operate in': 'जिस शहर में आप काम करेंगे',
        'Enter Email Id': 'ईमेल आईडी दर्ज करें',
        'Full Time': 'पूरा समय',
        'Part Time': 'पार्ट टाईम',
        "By proceeding, you accept Vahan’s <a href='/terms-and-conditions' target='_blank'>Terms and Conditions </a>": "आगे बढ़ने पर, आप वाहन के <a href='/terms-and-conditions' target='_blank'>नियम और शर्तें</a> स्वीकार करते हैं",
        'How old are you?':	'आपकी उम्र क्या है?',
        'Do you have experience in providing jobs to delivery boys/partners?': 'क्या आपके पास डिलीवरी बॉय/पार्टनर को नौकरी देने का अनुभव है?',
        'How many members do you have in your team who can help you with generating/following up with delivery boys/partners?': 'आपकी टीम में कितने सदस्य हैं जो डिलीवरी बॉय/पार्टनर के साथ उत्पन्न/अनुसरण करने में आपकी सहायता कर सकते हैं?',
        'Are you working with any delivery/bike-taxi clients directly?': 'क्या आप किसी डिलीवरी/बाइक टैक्सी क्लाइंट के साथ सीधे काम कर रहे हैं?',
        'Which clients are you working with?': 'आप किन क्लाइंट्स के साथ काम कर रहे हैं?',
        'What is your current occupation?':	'आपका वर्तमान पेशा क्या है?',
        'Is your occupation Full time or part time?': 'आपका पेशा फुल टाइम है या पार्ट टाइम?'
    },
    kd: {
        'Full name': 'ಪೂರ್ಣ ಹೆಸರು',
        'Enter Full Name': 'ಪೂರ್ಣ ಹೆಸರನ್ನು ನಮೂದಿಸಿ',
        'Phone Number': 'ದೂರವಾಣಿ ಸಂಖ್ಯೆ',
        'Email Id': 'ಇಮೇಲ್ ಐಡಿ',
        'City you will operate in': 'ನೀವು ಕಾರ್ಯನಿರ್ವಹಿಸುವ ನಗರ',
        'Enter Email Id': 'ಇಮೇಲ್ ಐಡಿ ನಮೂದಿಸಿ',
        'Full Time': 'ಪೂರ್ಣ ಸಮಯ',
        'Part Time': 'ಅರೆಕಾಲಿಕ',
        "By proceeding, you accept Vahan’s <a href='/terms-and-conditions' target='_blank'>Terms and Conditions </a>": "ಮುಂದುವರಿಯುವ ಮೂಲಕ, ನೀವು ವಾಹನ್ ಅವರ <a href='/terms-and-conditions' target='_blank'>ನಿಯಮಗಳು ಮತ್ತು ಷರತ್ತುಗಳನ್ನು</a> ಒಪ್ಪಿಕೊಳ್ಳುತ್ತೀರಿ",
        'How old are you?':	'ನಿಮ್ಮ ವಯಸ್ಸು ಎಷ್ಟು?',
        'Do you have experience in providing jobs to delivery boys/partners?': 'ಡೆಲಿವರಿ ಹುಡುಗರು/ಪಾಲುದಾರರಿಗೆ ಉದ್ಯೋಗ ಒದಗಿಸುವಲ್ಲಿ ನಿಮಗೆ ಅನುಭವವಿದೆಯೇ?',
        'How many members do you have in your team who can help you with generating/following up with delivery boys/partners?': 'ಡೆಲಿವರಿ ಬಾಯ್‌ಗಳು/ಪಾರ್ಟ್‌ನರ್‌ಗಳನ್ನು ರಚಿಸಲು/ಅನುಸರಿಸಲು ನಿಮಗೆ ಸಹಾಯ ಮಾಡಲು ನಿಮ್ಮ ತಂಡದಲ್ಲಿ ನೀವು ಎಷ್ಟು ಸದಸ್ಯರನ್ನು ಹೊಂದಿದ್ದೀರಿ?',
        'Are you working with any delivery/bike-taxi clients directly?': 'ನೀವು ನೇರವಾಗಿ ಯಾವುದೇ ಡೆಲಿವರಿ/ಬೈಕ್ ಟ್ಯಾಕ್ಸಿ ಕ್ಲೈಂಟ್‌ಗಳೊಂದಿಗೆ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದೀರಾ?',
        'Which clients are you working with?': 'ನೀವು ಯಾವ ಗ್ರಾಹಕರೊಂದಿಗೆ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದೀರಿ?',
        'What is your current occupation?':	'ನಿಮ್ಮ ಪ್ರಸ್ತುತ ಉದ್ಯೋಗ ಏನು?',
        'Is your occupation Full time or part time?': 'ನಿಮ್ಮ ಉದ್ಯೋಗವು ಪೂರ್ಣ ಸಮಯ ಅಥವಾ ಅರೆಕಾಲಿಕವೇ?'
    },
    te: {
        'Full name': 'పూర్తి పేరు',
        'Enter Full Name': 'పూర్తి పేరును నమోదు చేయండి',
        'Phone Number': 'ఫోనె నంబర్',
        'Email Id': 'ఈమెయిల్ ఐడీ',
        'City you will operate in': 'మీరు పనిచేయలనుకునె నగరం',
        'Enter Email Id': 'ఈమెయిల్ ఐడీ ని నమోదు చేయండి',
        'Full Time': 'ఫుల్ టైమె',
        'Part Time': 'పార్ట్ టైమ్',
        "By proceeding, you accept Vahan’s <a href='/terms-and-conditions' target='_blank'>Terms and Conditions </a>": "కొనసాగడం ద్వారా, మీరు వాహనం యొక్క <a href='/terms-and-conditions' target='_blank'> నిబంధనలు మరియు షరతులను </a> అంగీకరిస్తారు",
        'How old are you?':	'మీ వయస్సు ఎంత?',
        'Do you have experience in providing jobs to delivery boys/partners?': 'డెలివరీ బాయ్స్/పార్ట్‌నర్‌లకు ఉద్యోగాలు కల్పించడంలో మీకు అనుభవం ఉందా?',
        'How many members do you have in your team who can help you with generating/following up with delivery boys/partners?': 'డెలివరీ బాయ్స్/పార్ట్‌నర్‌లను రూపొందించడంలో/ఫాలో అప్ చేయడంలో మీకు సహాయపడే మీ బృందంలో మీకు ఎంత మంది సభ్యులు ఉన్నారు?',
        'Are you working with any delivery/bike-taxi clients directly?': 'మీరు ఏదైనా డెలివరీ/బైక్-టాక్సీ క్లయింట్‌లతో నేరుగా పని చేస్తున్నారా?',
        'Which clients are you working with?': 'మీరు ఏ క్లయింట్‌లతో పని చేస్తున్నారు?',
        'What is your current occupation?':	'మీ ప్రస్తుత వృత్తి ఏమిటి?',
        'Is your occupation Full time or part time?': 'మీ వృత్తి ఫుల్ టైమె అ లేదా పార్ట్ టైమ్?'
    },
    ta: {
        'Full name': 'முழு பெயர்',
        'Enter Full Name': 'முழுப் பெயரை பதிவிடவும்',
        'Phone Number': 'தொலைபேசி எண்',
        'Email Id': 'மின்னஞ்சல் முகவரி',
        'City you will operate in': 'நீங்கள் செயல்படும் நகரம்',
        'Enter Email Id': 'மின்னஞ்சல் ஐடியை பதிவிடவும்',
        'Full Time': 'முழு நேரம்',
        'Part Time': 'பகுதி நேரம்',
        "By proceeding, you accept Vahan’s <a href='/terms-and-conditions' target='_blank'>Terms and Conditions </a>": "தொடர்வதன் மூலம், வாகனின் <a href='/terms-and-conditions' target='_blank'> விதிமுறைகள் மற்றும் நிபந்தனைகளை </a> ஏற்கிறீர்கள்",
        'How old are you?':	'உங்களுடைய வயது என்ன?',
        'Do you have experience in providing jobs to delivery boys/partners?': 'டெலிவரி பாய்ஸ்/பார்ட்னர்களுக்கு வேலை வழங்குவதில் உங்களுக்கு அனுபவம் உள்ளதா?',
        'How many members do you have in your team who can help you with generating/following up with delivery boys/partners?': 'டெலிவரி பாய்ஸ்/பார்ட்னர்களை தேட/பின்தொடர எத்தனை உறுப்பினர்கள் உங்கள் குழுவில் உள்ளனர்?',
        'Are you working with any delivery/bike-taxi clients directly?': 'நீங்கள் டெலிவரி/பைக்டாக்ஸி வாடிக்கையாளர்களுடன் நேரடியாக வேலை செய்கிறீர்களா?',
        'Which clients are you working with?': 'நீங்கள் எந்த வாடிக்கையாளர்களுடன் பணிபுரிகிறீர்கள்?',
        'What is your current occupation?':	'உங்களுடைய தற்போதைய தொழில் என்ன?',
        'Is your occupation Full time or part time?': 'உங்களுடைய தொழில் முழு நேரமா அல்லது பகுதி நேரமா?'
    }
}

export {Localization};
