import React, { Fragment, useContext, useEffect, useState } from 'react'
import '../styles/FilterSelection.css'
import {UpOutlined, DownOutlined, CloseOutlined} from '@ant-design/icons'
import CalendarIcon from '../../../static/svg/CalendarIcon';
import CityIcon from '../../../static/svg/CityIcon';
import TeamMemberAddIcon from '../../../static/svg/TeamMemberAddIcon';
import StarIcon from '../../../static/svg/StarIcon';
import { Button, Checkbox, DatePicker, Divider, Input, Skeleton } from 'antd';
import moment from 'moment';
import { disabledDateLessThan25daysForHotLeads, enableLast30DaysForSelection, getActiveClientsForFilter, getLocationForFilter } from '../../../service/commonService';
import AdvanceFilterCitySelection from './AdvanceFilterCitySelection.jsx';
import CustomSearchDropdown from '../components/CustomSearchDropdown.jsx';
import useDebouncedValue from '../../../hooks/useDebouncedValue.jsx';
import PlusIcon from '../../../static/svg/PlusIcon.jsx';
import SideSheetComponent from '../../common/SideSheet.js';
import { getOverallTeamCount } from '../../../service/reportService.js';
import { AppContext } from '../../../App.js';
import FilterSearch from '../../../static/images/filter-search.gif';
import InfoIcon from '../../../static/svg/InfoIcon.jsx';
import { formatLargeNumbers, largeNumberSuffix } from '../../../constants/utils.js';
import CrossIcon from '../../../static/svg/CrossIcon.jsx';

const { RangePicker } = DatePicker;

function FilterSelectionScreen({
    goToNextScreen,
    selectedCities,
    setSelectedCities,
    selectedClients,
    setSelectedClients,
    selectedTeamMembers, 
    setSelectedTeamMembers,
    setStartDateParent,
    setEndDateParent,
    getCandidateCount,
    summaryInfo,
    loadingSummaryInfo
}) {
    const [startDate, setStartDate] = useState(moment().subtract(7, 'days'));
	const [endDate, setEndDate] = useState(moment());
    const dateFormat = 'YYYY/MM/DD';
    const [showSideSheet, setShowSideSheet] = useState(false);
    const {mitraReducer} = useContext(AppContext);

    const [cityOptions, setCityOptions] = useState([]);
    const [citySearchText, setCitySearchText] = useState('');
    const cityDebouncedSearchText = useDebouncedValue(citySearchText);
    const [popularCities, setPopularCities] = useState([
        {
            id: 0,
            label: 'Delhi',
            isChecked: false
        },
        {
            id: 1,
            label: 'Mumbai',
            isChecked: false
        },
        {
            id: 2,
            label: 'Bangalore',
            isChecked: false
        },
        {
            id: 3,
            label: 'Pune',
            isChecked: false
        }
    ]);

    const [teamMemberOptions, setTeamMemberOptions] = useState([]);
    const [teamMemberSearchText, setTeamMemberSearchText] = useState('');
    const teamMemberDebouncedSearchText = useDebouncedValue(teamMemberSearchText);
    const [expandedFilterId, setExpandedFilterId] = useState(null);
    

    // All Filters related var
    const [filterList, setFilterList] = useState([
        {
            id: 0,
            header: 'Referral dates',
            icon: <CalendarIcon />,
            isExpanded: true
        },
        {
            id: 1,
            header: 'City',
            icon: <CityIcon />,
            isExpanded: false
        },
        {
            id: 2,
            header: 'Team members',
            icon: <TeamMemberAddIcon
             />,
            isExpanded: false
        },
        {
            id: 3,
            header: 'Clients',
            icon: <StarIcon />,
            isExpanded: false
        }
    ]);

    const [clientList, setClientList] = useState([
        {
            id: 0,
            label: 'Zomato',
            isChecked: false
        },
        {
            id: 1,
            label: 'Blinkit',
            isChecked: false
        },
        {
            id: 2,
            label: 'Swiggy',
            isChecked: false
        },
        {
            id: 3,
            label: 'Zepto',
            isChecked: false
        }
    ])

    const onExpandFilter = (id) => {
        const modifiedFilter = [...filterList];

        setCityOptions([]);
        setTeamMemberOptions([]);

        for (let item = 0; item < modifiedFilter.length; item++) {
            if(modifiedFilter[item].id === id) {
                if(id === expandedFilterId) {
                    modifiedFilter[item].isExpanded = false;
                    setExpandedFilterId(null);
                }
                else {
                    modifiedFilter[item].isExpanded = true;
                    setExpandedFilterId(id);
                }

            } else {
                modifiedFilter[item].isExpanded = false;
            }
        }

        setFilterList([...modifiedFilter]);
    }

	const handleDateRange = (dates) => {
        console.log(dates);
		if (dates === null) {
			setStartDate(null);
			setEndDate(null);
            setStartDateParent(null);
            setEndDateParent(null);
		}
		else {
			setStartDate(dates[0]);
			setEndDate(dates[1]);
            setStartDateParent(dates[0]);
            setEndDateParent(dates[1]);
		}
	}

    const onSearchTeamMembers = (label) => {
        // if(label === 'sagar') {
        //     setTeamMemberOptions([
        //         {
        //             id: 0,
        //             label: 'Sagar',
        //             value: 'Sagar'
        //         },
        //         {
        //             id: 1,
        //             label: 'Chauhan',
        //             value: 'Chauhan'
        //         }
        //     ]);
        // } else {
        //     setTeamMemberOptions([]);
        // }

        // if(label?.length>=3) {
            const queryParams = {
                search: label
            }
    
            getOverallTeamCount(queryParams).then((teamListRes) => {
                console.log(teamListRes.data);
                if(teamListRes?.data?.teamList?.length) {
                    const teamLength = teamListRes?.data?.teamList?.length;
                    const teamList = teamListRes?.data?.teamList;
                    const teamData = [];
                    for (let index = 0; index < teamLength; index++) {
                        teamData.push({...teamList[index], label: teamList[index].name, value: teamList[index].id});
                    }

                    setTeamMemberOptions([...teamData]);
                } else {
                    setTeamMemberOptions([]);
                }
            }).catch(err=> {
                console.log(err);
            })
        // }
    }

    const onSearchCities = (label) => {

        if(!label) {
            setCityOptions([]);
            return
        }

        let data = {
            search: label
        }
        getLocationForFilter(data).then(response => {
            if(response?.data?.locationList.length) {
                const locationData = [];

                for(let i=0;i<response?.data?.locationList?.length; i++) {
                    locationData.push({label:response?.data?.locationList[i], value: response?.data?.locationList[i]});
                }

                setCityOptions([...locationData]);
            } else {
                setCityOptions([]);
            }
        }).catch(err=> {
            console.log(err);
            setCityOptions([]);
        })
    }

    useEffect(() => {
        onSearchTeamMembers(teamMemberSearchText)
    }, [teamMemberSearchText])

    useEffect(() => {
        onSearchCities(citySearchText)
    }, [citySearchText])

    const getActiveClientList = () => {
        getActiveClientsForFilter().then(data => {
			console.log(data.data.activeClients)

            if(data?.data?.activeClients?.length) {
                const activeClients = [...data.data.activeClients];

                for (let i = 0; i < activeClients.length; i++) {
                    activeClients[i].isExpanded = false;
                    activeClients[i].id = i;
                }

                setClientList([...activeClients]);
            }
		});
    }

    useEffect(() => {
        getActiveClientList();
        setStartDate(moment().subtract(7, 'days'));
        setEndDate(moment());
        setStartDateParent(moment().subtract(7, 'days'));
        setEndDateParent(moment());
    }, [])
    

    const resetFilters = () => {
        setSelectedCities([]);
        setSelectedClients([]);
        setSelectedTeamMembers([]);
        setStartDate(null);
        setEndDate(null);
        setStartDateParent(null);
        setEndDateParent(null);
        setPopularCities([
            {
                id: 0,
                label: 'Delhi',
                isChecked: false
            },
            {
                id: 1,
                label: 'Mumbai',
                isChecked: false
            },
            {
                id: 2,
                label: 'Bangalore',
                isChecked: false
            },
            {
                id: 3,
                label: 'Pune',
                isChecked: false
            }
        ]);

        

        setFilterList([
            {
                id: 0,
                header: 'Referral dates',
                icon: <CalendarIcon />,
                isExpanded: true
            },
            {
                id: 1,
                header: 'City',
                icon: <CityIcon />,
                isExpanded: false
            },
            {
                id: 2,
                header: 'Team members',
                icon: <TeamMemberAddIcon
                 />,
                isExpanded: false
            },
            {
                id: 3,
                header: 'Clients',
                icon: <StarIcon />,
                isExpanded: false
            }
        ])

        getActiveClientList();
    }

    const getFilterInformation = () => {
        return (
            <>
                <Divider style={{margin:'16px 0px'}}/>

                {
                    filterList.map((filterItem, idx)=>(
                        <Fragment key={idx}>
                            <div className="filter-item" onClick={()=>onExpandFilter(filterItem.id)}>
                                <div className='filter-details'>
                                    {filterItem.icon}
                                    <span className='inter-medium-14-20 color-252526' style={{userSelect:'none'}}>{filterItem.header}</span>
                                </div>
                                {
                                    filterItem?.isExpanded ? <UpOutlined />: <DownOutlined />
                                }
                                
                            </div>

                            {
                                filterItem.id === 0 && filterItem.isExpanded ? (
                                    <>
                                        <RangePicker 
                                        className="range-picker-leads adv-filter-selection"
                                        onChange={handleDateRange}
                                        value={
                                            startDate && endDate ? 
                                            [moment(startDate, dateFormat), moment(endDate, dateFormat)] : [null, null]
                                        }
                                        disabledDate={enableLast30DaysForSelection}
                                        style={{marginLeft:'28px'}}
                                        format={dateFormat}
                                        />
                                    </>
                                ):null
                            }

                            {
                                filterItem.id === 1 && filterItem.isExpanded ? (
                                    // <AdvanceFilterCitySelection onCitySelect={(data)=>setSelectedCities([...data])}/>
                                    <CustomSearchDropdown 
                                    placeholder={'e.g Delhi'}
                                    onSearch={(value)=>setCitySearchText(value)}
                                    dropdownOptions={cityOptions}
                                    onSelect={(data)=>setSelectedCities([...data])}
                                    value={selectedCities}
                                    checkboxGroupVisible={true}
                                    checkboxGroupItems={popularCities}
                                    />
                                ): null
                            }

                            {
                                filterItem.id === 2 && filterItem.isExpanded ? (
                                    <CustomSearchDropdown 
                                    placeholder={'e.g Ashok'}
                                    onSearch={(value)=>setTeamMemberSearchText(value)}
                                    dropdownOptions={teamMemberOptions}
                                    onSelect={(data)=>setSelectedTeamMembers([...data])}
                                    value={selectedTeamMembers}
                                    />
                                ): null
                            }

                            {
                                filterItem.id === 3 && filterItem.isExpanded ? (
                                    <CustomSearchDropdown 
                                        showSearch={false}
                                        checkboxGroupVisible={true}
                                        checkboxGroupItems={clientList}
                                        onSelect={(data)=>setSelectedClients([...data])}
                                        value={selectedClients}
                                    />
                                ): null
                            }
                            <Divider style={{margin:'16px 0px'}}/>
                        </Fragment>
                    ))
                }

                <div className="clear-filter-container" onClick={resetFilters}><span className='inter-regular-12-16 color-252526'>Remove all filters</span></div>
                <Button className='primary-btn-styles-v2 side-adv-filter-close-btn' onClick={()=>setShowSideSheet(false)}>
                    <span className='inter-regular-14-20 plain-white'>Apply</span>
                </Button>
            </>
        )
    }

    

    useEffect(() => {
        if(mitraReducer?.mitraInfo?.id) {
            getCandidateCount();
        }
    }, [startDate, endDate, selectedClients, selectedCities, selectedTeamMembers, mitraReducer?.mitraInfo?.id])
    

  return (
    <>
        <div className="step-info-header">Select from Filters</div>
        <div className="step-info-sub-header">Select the filters you want.</div>

        <Button className='mobile-filter-button-adv-filter primary-btn-styles-v2 btn-width-285 btn-36h' onClick={()=>setShowSideSheet(true)}>
            <PlusIcon strokeColor='#FFF' height={19} width={19}/>
            <span className='plain-white inter-regular-14-20'>Add filter</span>
        </Button>

        {
            showSideSheet ? (
                <SideSheetComponent 
                sidername={'Filters'} 
                hideDismissIcon={false}
                hideBottomButtons={true}
                containerStyle={{width:"85%"}}
                showNavigator={false}
                parentContainerStyles={{background: 'rgba(0, 0, 0, 0.74)'}}
                onDismiss={() => { setShowSideSheet(false)}}>
                    <div className='mobile-adv-filter-sidesheet'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <span className='inter-bold-16-18 color-252526 text-align-start'>
                                Filters
                            </span>
                            <div onClick={()=>setShowSideSheet(false)}>
                                <CrossIcon />
                            </div>
                        </div>
                        {getFilterInformation()}
                    </div>
                </SideSheetComponent>
            ): null
        }

        <div className="filter-and-selection-details-container">

            <div className="adv-filter-container">
                <span className='inter-bold-16-18 color-252526 text-align-start'>
                    Filters
                </span>
                {getFilterInformation()}
            </div>

            <div className="upload-container adv-filter-preview-container shadow-highlighter">
                <div className="upload-file-main-section align-items-start justify-content-start" style={{padding: '18px 32px'}}>
                    <span className='inter-bold-16-18 color-252526'>Preview Details</span>

                    <div className="adv-filter-top-section mt-12">
                        
                        <div 
                        className={
                            `
                            candidate-count 
                            d-flex 
                            align-items-center 
                            justify-content-center 
                            flex-column 
                            ${summaryInfo?.leadsEligible < 5 ? 'candidate-count-ineligible': ''}
                            `}>
                            {
                                loadingSummaryInfo ? (
                                    <>
                                    <img src={FilterSearch} style={{width: '64px', height:'64px'}} />
                                    <span className='color-1D5FFF count-sub-text mt-8'>Calculating...</span>
                                    </>
                                ):
                                (
                                    <>
                                    <span className='color-323232 candidate-count-text'>{formatLargeNumbers(summaryInfo?.leadsEligible)+ '' + largeNumberSuffix(parseInt(summaryInfo?.leadsEligible))}</span>
                                    <span className='color-323232 count-sub-text'>Number of candidates</span>
                                    </>
                                    
                                )
                            }
                
                        </div>

                        {
                            summaryInfo?.leadsEligible < 5 ? (
                                <div className='adv-filter-error-container leads-ineligible-container'>
                            
                                    <div className="successful-upload-and-button-container w-100 min-leads-content-container">
                                        <div className="successful-upload-text-container">
                                            <img src="/image/error-image.png" className='min-leads-err-image' />
                                            <div className="successful-upload-text-details">
                                                <span className='min-required-leads-text color-252526'>
                                                    At least 5 candidates are required to proceed.
                                                </span>
                                                <span className='inter-regular-12-16 color-838383 successful-text error-container-sub-text'>
                                                    Adjust filters or refer more leads.
                                                </span>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            ):(
                                <div className="selection-indicator d-flex justify-content-between align-items-center">
                                    <div className="selection-indicator-text">
                                        <span className='inter-bold-20-20 color-1D5FFF your-selections'>Your Selections</span>
                                        <span className='inter-regular-10-16 your-selections-subtext' style={{color: '#9185E8'}}>Are listed as below. Please check</span>
                                    </div>
                                    <img src='/image/adv-filter-banner.jpg' className='adv-filter-banner-img' />
                                </div>
                            )
                        }
                        
                    </div>

                    <div className="selected-values-display-section">

                        {
                            !startDate && !endDate && !selectedCities.length && !selectedTeamMembers.length && !selectedClients.length ? (
                                <div className='d-flex flex-column align-items-center justify-content-center w-100'>
                                    <img src='/image/no-session.png' style={{width:'120px', height:'94px',objectFit:'contain'}} />
                                    <span className='inter-medium-20-18 color-252526 mt-12'>Select Filters</span>
                                    <span className='inter-regular-14-20' style={{color: '#A2A0A0'}}>You have no filters. Please select.</span>
                                </div>
                            ): null
                        }

                        {
                            startDate && endDate ? (
                                <div className="single-section">
                                    <img src='/image/referral-dates.png' className='adv-filter-selection-images'/>
                                    <div className="selected-values d-flex flex-column align-items-start">
                                        <span className='btn-text-semibold-14-16 color-323232 text-align-start'>Referral Dates</span>
                                        <span className='inter-medium-12-18 color-838383'>{moment(startDate).format("DD/MM/YYYY")}</span>
                                        <span className='inter-medium-12-18 color-838383' style={{margin:'-8px 0px',alignSelf: 'center'}}>-</span>
                                        <span className='inter-medium-12-18 color-838383'>{moment(endDate).format("DD/MM/YYYY")}</span>
                                    </div>
                                </div>
                            ): null
                        }

                        {
                            selectedCities.length ? (
                                <div className="single-section">
                                    <img src='/image/city-indicator.png' className='adv-filter-selection-images'/>
                                    <div className="selected-values d-flex flex-column align-items-start">
                                        <span className='btn-text-semibold-14-16 color-323232 text-align-start'>City</span>
                                        <span className='inter-medium-12-18 color-838383 text-align-start'>
                                            {
                                                selectedCities.map((item,idx)=>(
                                                    <Fragment key={idx}>
                                                        {idx!==0 ? ',': ''} {item?.label}
                                                    </Fragment>
                                                ))
                                            }
                                        </span>
                                    </div>
                                </div>
                            ): null
                        }

                        {
                            selectedTeamMembers.length ? (
                                <div className="single-section">
                                    <img src='/image/team-member-indicator.png' className='adv-filter-selection-images'/>
                                    <div className="selected-values d-flex flex-column align-items-start">
                                        <span className='btn-text-semibold-14-16 color-323232 text-align-start'>Team Members</span>
                                        <span className='inter-medium-12-18 color-838383'>{selectedTeamMembers.length}</span>
                                    </div>
                                </div>
                            ):null
                        }

                        {
                            selectedClients.length ? (
                                <div className="single-section">
                                    <img src='/image/selected-client-indicator.png' className='adv-filter-selection-images'/>
                                    <div className="selected-values d-flex flex-column align-items-start">
                                        <span className='btn-text-semibold-14-16 color-323232 text-align-start'>Clients</span>
                                        <span className='inter-medium-12-18 color-838383 text-align-start'>
                                            {
                                                selectedClients.map((item,idx)=>(
                                                    <Fragment key={idx}>
                                                        {idx!==0 ? ',': ''} {item?.label}
                                                    </Fragment>
                                                ))
                                            }
                                        </span>
                                    </div>
                                </div>
                            ): null
                        }
                    </div>

                    <div className="filter-section-button-container d-flex justify-content-end w-100">
                        <Button 
                        className={`btn-width-285 btn-36h filter-screen-btn-width ${summaryInfo?.leadsEligible < 5 || loadingSummaryInfo ? 'disabled-btn-v2': 'primary-btn-styles-v2'}`}
                        onClick={()=>goToNextScreen('script')}
                        disabled={summaryInfo?.leadsEligible < 5 || loadingSummaryInfo}
                        >
                            <span className={`inter-regular-14-20 plain-white`}>
                                {
                                    summaryInfo?.leadsEligible < 5 ? 'Next':
                                    !startDate && !endDate && !selectedCities.length && !selectedTeamMembers.length && !selectedClients.length ? 'Continue without filter': 
                                    'Next'
                                }
                            </span>
                        </Button>
                    </div>

                </div>
            </div>
        </div>
    </>
  )
}

export default FilterSelectionScreen