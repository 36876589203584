import { Divider, Skeleton } from 'antd'
import React from 'react'

function SessionListSkeleton() {
  return (
    <div className='gray-main-container'>
        {/* <Navbar searchTerm={searchTerm} setSearchTerm={setSearchTerm}/> */}

        <>
            <div className="back-navigation d-flex align-items-center">
                <Skeleton.Button active size={'default'} shape={'default'} block={false} />
            </div>

            <div className="d-flex justify-content-between align-items-center flex-wrap" style={{margin:'9px 32px 0px', gap: '16px'}}>
                <span className='lead-list-hero-text d-flex' style={{margin: 0}}>
                    <Skeleton.Input active size={'default'} />
                </span>
                <Skeleton.Input active size={'large'} />
            </div>

            <div className="tab-and-btn-container d-flex justify-content-between">
                <div className="session-tab-container">
                    <Skeleton.Button active size={'default'} shape={'round'} block={false} />

                    <Skeleton.Button active size={'default'} shape={'round'} block={false} />
                </div>

                {/* <Button className='create-session-btn'><span className='create-session-btn-text'><PlusOutlined /> Create New Session</span></Button> */}
            </div>

            <div className='d-flex align-items-start justify-content-between' style={{margin: '20px 32px'}}>
                <Skeleton.Input active size={'large'} />

                <Skeleton.Button active size={'large'} shape={'round'} block={false} />

            </div>

            <Divider />

            <div className="card-tile-container margin-horizontal-32">
                <Skeleton active/>
            </div>

            <div className="filtered-candidate-card-container margin-horizontal-32 session-list-container">

                <div className='sticky-filter-and-pagination'>
                    <div className="filtered-candidate-top-section d-flex justify-content-between align-items-center">
                        <div className="filtered-candidate-tab-container d-flex">
                            <Skeleton.Button active size={'default'} shape={'default'} block={false} />
                        </div>
                        <div className="filtered-candidate-options d-flex">
                            <Skeleton.Button active size={'default'} shape={'default'} block={false} />
                        </div>
                    </div>

                    <div className="session-list-pagination-container d-flex justify-content-end mt-16">
                        <Skeleton.Input active size={'large'} />
                    </div>
                </div>
                    
                <div className="filtered-candidate-cards d-flex">
                    <Skeleton active/>
                </div>
                

            </div>
        </>
    </div>
  )
}

export default SessionListSkeleton