import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import PageHeaderComponent from '../../../common/PageHeader';
import { Divider, Button, Skeleton, Empty, message } from 'antd';
import { DownloadOutlined, ShareAltOutlined, PictureOutlined, EnvironmentOutlined } from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios';
import '../JobRequirementsSection/JobDemandCardStyle.scss'
import './LeadsFromPostersListingStyle.scss'
import LeadViewCard from './LeadViewCard';
import { getJobPostingResponseById } from '../../../../service/jobDemandsService';
import PosterModal from '../JobPoster/PosterModal';
import axiosInstance from '../../../../service/interceptorService';

const PosterLeadsListing = ({ }) => {
    const history = useHistory();
    const location = useLocation()
    const [breadcrumb, setBreadcrumb] = useState([{ link: '/job-demands/job-requirements', label: 'Jobs' }, { link: '/job-demands/leads-from-posters', label: "Leads From Posters" }]);
    const [posterId, setPosterID] = useState("")
    const [loadingListing, setLoadingListing] = useState(false)
    const [posterLeadData, setPosterLeadData] = useState([
        {
            "name": "",
            "phoneNumber": "",
            "createdAt": "",
            "city": "",
            "companyName": ""
        },
    ],
    )

    const [posterJobPostingDetails, setPosterJobPostingDetails] = useState(
        {
            "totalLeads": "",
            "city": "",
            "companyName": "",
            "companyId": "",
            "logo": "",
            "jobPostingId": "",
            "postingCreatedAt": ""
        }
    )
    const [showPosterModal, setShowPosterModal] = useState(false);

    useEffect(() => {
        extractPosterIDFromURL()
    }, [])

    useEffect(() => {
        if (posterId !== "")
            fetchLeadsForPoster()
    }, [posterId])

    const extractPosterIDFromURL = () => {
        const path = location.pathname;
        const parts = path.split('/');
        setPosterID(parts[parts.length - 1])
    }

    const fetchLeadsForPoster = () => {
        setLoadingListing(true)
        try {
            // Make API Call with all filters, only releveant ones get added to the URL
            getJobPostingResponseById(posterId).then((leadsForPosterRes) => {
                if (leadsForPosterRes && leadsForPosterRes.data && leadsForPosterRes.data.jobPostingDetails && leadsForPosterRes.data.jobPostingResponse) {
                    setLoadingListing(false)
                    setPosterLeadData(leadsForPosterRes.data.jobPostingResponse)
                    if (leadsForPosterRes.data.jobPostingDetails && leadsForPosterRes.data.jobPostingDetails.length > 0) {
                        setPosterJobPostingDetails(leadsForPosterRes.data.jobPostingDetails[0])
                    }
                    if (leadsForPosterRes?.data?.jobPostingDetails[0]?.jobPostingId)
                        setBreadcrumb([{ link: '/job-demands/job-requirements', label: 'Jobs' }, { link: '/job-demands/leads-from-posters', label: "Leads From Posters" }, { link: `/job-demands/${leadsForPosterRes.data.jobPostingDetails[0].jobPostingId}`, label: "Poster" }])
                }
                else {
                    setLoadingListing(false)
                    setPosterLeadData([])
                    message.error("Something went wrong, please try again later", 4)
                }
            })
        }
        catch (error) {
            if (axiosInstance.isCancel(error)) {
                console.log('Request canceled');
                setLoadingListing(false)
            } else {
                console.log('Request err', error);
                setLoadingListing(false)
            }
        }
    }

    const onViewPosterClicked = () => {
        setShowPosterModal(true)
    }

    const onDownloadPosterClicked = () => {
        if (posterJobPostingDetails && posterJobPostingDetails.posterUrl) {
            const link = document.createElement('a');
            link.href = posterJobPostingDetails.posterUrl;
            link.download = `${posterJobPostingDetails.companyName}-Poster`; // Specify the desired filename

            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
        }
        else{
            message.error('No Poster found.');
        }
    }

    const onSharePosterClicked = () => {
        const posterLink = process.env.REACT_APP_PUBLIC_URL + `/job-posting/` + posterId;
        if (posterId && posterLink) {
            navigator.clipboard.writeText(posterLink)
                .then(() => {
                    message.success("Poster link copied!")
                })
                .catch(err => {
                    console.error('Error copying to clipboard:', err);
                    message.error('Failed to copy link');
                });
        }
        else {
            message.error('Couldn\'t find the poster URL, please try again in some time');
        }
    }

    return (
        <div className={`poster-leads-listing-parent h-100`}>
            <div className="containerTop">
                <div className="pll-new-breadcrumb-header header">
                    <>
                        <PageHeaderComponent backBtnBool={true} breadcrumb={breadcrumb} />
                    </>
                </div>
            </div>

            <Divider></Divider>

            {
                loadingListing ?
                    <Skeleton />
                    :
                    <div className='h-100'>
                        {
                            posterJobPostingDetails ?
                                <div className='pll-job-details-header'>
                                    <div className='pll-created-on d-flex'>
                                        Poster created on <span className='pll-created-on-date'>&nbsp;{moment(posterJobPostingDetails.postingCreatedAt).format("Do MMM YYYY")}</span>
                                    </div>
                                    <div className='pll-company-info-btns'>
                                        <div className='d-flex align-items-center w-100 justify-content-between'>
                                            <div className='d-flex align-items-center'>
                                                <img src={posterJobPostingDetails.logo} alt="comp_logo" className='pll-company-logo-img' />
                                                <div className='pll-company-name-type'>
                                                    <div className='pll-company-name'>
                                                        {posterJobPostingDetails.companyName}
                                                    </div>
                                                    <div className='pll-company-type'>
                                                        {posterJobPostingDetails.companyName}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='pll-location-details d-flex align-items-center show-only-mobile'>
                                                <div className='d-flex'>
                                                    <EnvironmentOutlined className='pll-location-icon d-flex align-items-center' />
                                                    <span className='pll-location-txt'>{posterJobPostingDetails.city}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='pll-job-details-header-btns d-flex align-items-center'>
                                            <div className='pll-view-download-poster-btns d-flex align-items-center'>
                                                <Button className='jp-secondary-btn pll-job-details-header-btn pll-create-poster-btn pll-job-details-btn pll-job-details-btn-margin d-flex align-items-center jp-cp justify-content-center' onClick={() => onViewPosterClicked()}>
                                                    <PictureOutlined className='pll-create-poster-btn-img pll-job-details-header-btn-img-orange jp-orange-txt' />
                                                    <div className='jp-secondary-btn-txt pll-create-poster-btn-txt jp-orange-txt'>
                                                        View
                                                    </div>
                                                </Button>

                                                <Button className='jp-secondary-btn pll-job-details-header-btn pll-view-posters-btn pll-job-details-btn pll-job-details-btn-margin d-flex align-items-center jp-cp justify-content-center' onClick={() => onDownloadPosterClicked()}>
                                                    <DownloadOutlined className='pll-view-posters-btn-img pll-job-details-btn-img-orange jp-orange-txt' />
                                                    <div className='jp-secondary-btn-txt pll-view-posters-btn-txt jp-orange-txt d-flex'>
                                                        Download
                                                    </div>
                                                </Button>
                                            </div>

                                            <Button className='jp-primary-btn pll-share-poster-btn pll-job-details-btn pll-job-details-btn-margin d-flex align-items-center jp-cp justify-content-center' onClick={() => onSharePosterClicked()}>
                                                <ShareAltOutlined className='pll-share-poster-btn-img pll-job-details-btn-img-white jp-white-txt' />
                                                <div className='jp-primary-btn-txt pll-view-pitch-btn-txt jp-white-txt d-flex'>
                                                    <span className='show-only-desktop'>Share and&nbsp;</span>Get Leads
                                                </div>
                                            </Button>
                                        </div>
                                    </div>

                                    <div className='pll-location-details d-flex align-items-center show-only-desktop'>
                                        <EnvironmentOutlined className='pll-location-icon d-flex align-items-center' />
                                        <span className='pll-location-txt'>{posterJobPostingDetails.city}</span>
                                    </div>
                                </div>
                                :
                                null
                        }

                        <div className='pll-leads-listing'>
                            {
                                loadingListing ?
                                    <Skeleton />
                                    :
                                    posterLeadData && posterLeadData.length > 0 ?
                                        posterLeadData.map((posterLead, posterLeadInd) => {
                                            return (
                                                posterLeadData && posterLeadData.length > 0 ?
                                                    <LeadViewCard leadData={posterLead} leadInd={posterLeadInd} showCompanyLogo={false} />
                                                    :
                                                    <Empty />
                                            )
                                        })
                                        :
                                        <Empty />
                            }
                        </div>
                    </div>
            }

            {
                showPosterModal ?
                    <PosterModal client={{ label: posterJobPostingDetails.companyName, key: posterJobPostingDetails.companyName.toLowerCase(), value: true }} city={posterJobPostingDetails.city ? posterJobPostingDetails.city : ""} isModalOpen={showPosterModal} closeModal={() => setShowPosterModal(false)} posterURLFromParent={posterJobPostingDetails.posterUrl ? posterJobPostingDetails.posterUrl : ""} posterId={posterId} />
                    :
                    null
            }


        </div>
    )
}

export default PosterLeadsListing; 