import React, { useState } from 'react'
import '../../style/BulkReferralStyle.scss'
import CONSTANTS from '../../../../constants/constants';
import { Select, Divider, Button, Spin } from 'antd';
import { SearchOutlined, DeleteOutlined, ArrowRightOutlined, LoadingOutlined } from '@ant-design/icons';

const BulkReferralStepTwo = ({ mitra, mitraTeamList, selectedMitraTeamMembersFromParent, returnStepTwoMitraTeamMemberList, goToNextStep, clearedStep, isUploading }) => {
    const [mitraTeamListSelected, setMitraTeamListSelected] = useState(selectedMitraTeamMembersFromParent)

    const onMitraTeamMemberSelected = (membersSelected) => {
        setMitraTeamListSelected(membersSelected)
        returnStepTwoMitraTeamMemberList(membersSelected)
    }

    const filterMitraTeamMembers = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const clearAllFields = () => {
        setMitraTeamListSelected(undefined)
        returnStepTwoMitraTeamMemberList(undefined)
        clearedStep()
    }

    const checkIfNextDisabled = () => {
        let isDisabled = false
        if (!mitraTeamListSelected) {
            return true;
        }
        if (mitraTeamListSelected && mitraTeamListSelected.length == 0) {
            isDisabled = true;
        }
        else {
            isDisabled = false;
        }
        return isDisabled;
    }

    const onNextClick = () => {
        goToNextStep()
    }

    return (
        <div className='br-step-parent br-step-two-dropdown-parent d-flex align-items-center'>
            <div className='br-team-member-search-container d-flex align-items-center w-100 justify-content-between desktop-additional-margin-top'>
                <div className='br-team-member-search-txt-select d-flex justify-content-center align-items-center'>
                    <div className='br-team-member-search-txt'>
                        You can select team member(s) for assigning the candidates
                    </div>
                    <div className='br-mitra-team-search-select d-flex' style={{position:'relative'}}>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            placeholder={`Search Team Member`}
                            onChange={onMitraTeamMemberSelected}
                            options={mitraTeamList}
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterMitraTeamMembers}
                            value={mitraTeamListSelected}
                        />

                        <div className='br-mitra-team-search-select-icon'>
                            <SearchOutlined className='br-mitra-team-search-select-icon-val' />
                        </div>

                    </div>
                </div>

                <div className='br-step-action-fields d-flex desktop-additional-margin-top mobile-bottom-fixed w-100'>
                    <Divider style={{margin: '4px 0px 16px'}}></Divider>
                    <div className='br-step-btns d-flex'>
                        <Button className='br-btn-container br-clear-all-btn d-flex align-items-center justify-content-center' onClick={() => clearAllFields()}>
                            <DeleteOutlined className='br-step-btn-img-orange' />
                            Clear All
                        </Button>

                        <Button 
                        disabled={isUploading}
                        className='br-btn-container br-next-btn d-flex align-items-center justify-content-center' 
                        onClick={() => onNextClick()}>
                            Next
                            <ArrowRightOutlined className='br-step-btn-img-white' />
                            {
                            isUploading ?
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "white", marginLeft: "8px" }} spin />} />
                                :
                                null
                            }
                        </Button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default BulkReferralStepTwo; 