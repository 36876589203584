import { Button, Divider, Input, Modal, notification } from 'antd'
import React, { useEffect, useState, useContext } from 'react'
import { CheckCircleOutlined, CloseOutlined } from '@ant-design/icons';
import CONSTANTS from '../../constants/constants';
import { updateTargetForMls } from '../../service/reportService';
import CallerNumberSelectionModal from '../kaleyraCallComponents/callerNumberSelectionModal';
import {AppContext} from '../../App';
import { trackEvent } from '../../service/engagementMonitoringService';

function SetTargetPopup({show, setShow, memberTarget=null, selectedMonth, monthlyTargetFTs, teamList, startDate, endDate, overallTeamTarget=null}) {
    const {mitraReducer, setAlert, mitraDispatch} = useContext(AppContext);
    const [modalTitle, setModalTitle] = useState();
    const [targetMonth, setTargetMonth] = useState();
    const [targetFt, setTargetFt] = useState(monthlyTargetFTs);
    const [fieldInfo, setFieldInfo] = useState([]);
    const [inputValues, setInputValues] = useState();
    const [errorMessage, setErrorMessage] = useState(false);
    const [saveButtonDisable, setSaveButtonDisable] = useState(true);
    const key = `open${Date.now()}`;
    const placement = 'topRight';
    const trackMoengageEvent = (event,obj) => {
		trackEvent(event, obj || {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
	}

    useEffect(() => {
        setTargetMonth(selectedMonth);
        setTargetFt(monthlyTargetFTs);
        let initialInputValues;
        if(overallTeamTarget) {
            setModalTitle('Set Targets for Teams');
            initialInputValues = teamList?.filter(item => item?.key !== 'overall').map(item => ({
                id: item?.teamId,
                target: item?.target !== undefined && item?.target !== null ? item?.target : 0
            }));
        } else {
            setModalTitle('Targets for Team Members');
            initialInputValues = teamList?.filter(item => item?.role !== 'admin' && item?.status !== 'Inactive')
            .map(item => ({
            id: item?.id,
            target: item?.target !== null ? item?.target : 0
            }));
        }
        setInputValues(initialInputValues);
      }, [selectedMonth, monthlyTargetFTs, teamList]);


    const handleInputChange = (teamId, value) => {
        const existingIndex = inputValues.findIndex(item => item.id === teamId);
        if (existingIndex !== -1) {
          const updatedInputValues = [...inputValues];
          updatedInputValues[existingIndex].target = value === '' ? 0 : parseInt(value);
          setInputValues(updatedInputValues);
        } else {
          setInputValues(prevInputValues => [
            ...prevInputValues,
            { id: teamId, target: parseInt(value) }
          ]);
        }

        const totalTargetSet = inputValues.reduce((acc, curr) => acc + curr.target, 0);
        setSaveButtonDisable(totalTargetSet > 0 && false);
      };

    const handleSave = () => {
        trackMoengageEvent('set-target-save-button-clicked');
            const payload = {
                list: inputValues,
                startDate:startDate,
                endDate:endDate,
            }
            console.log(payload);

            updateTargetForMls(payload).then((res) => {
            if(res?.data?.status) {
                setShow(false);
                notification.open({
                    message: "Target updated successfully!",
                    key,
                    duration: 5,
                    placement,
                    icon: <CheckCircleOutlined className='green-positive' />,
                });
            }
        });
    }

  return (
    <Modal 
        title={null}
        visible={show}
        onCancel={()=>setShow(false)}
        closable={false}
        bodyStyle={{padding: '0px'}}
        className="samvadini-modal"
        footer={[
        <>
        <div key={1} className='d-flex justify-content-end'>
            <Button disabled={saveButtonDisable} onClick={()=>handleSave()} style={saveButtonDisable ? {opacity: '0.4', fontWeight: 700}:{fontWeight: 700}} className='file-upload-button'> Save</Button>
        </div>
        </>
        ]}
    >
        <div style={{height:'65vh'}}>
            <div className='d-flex justify-content-between align-items-center' style={{paddingRight: '24px', borderBottom: '1px solid rgba(0, 0, 0, 0.06)'}}>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex align-items-center'>
                        <span style={{fontWeight: '600', fontSize: 18}} className='ant-modal-header'>
                            {modalTitle}
                        </span>
                    </div>
                </div>
                <CloseOutlined style={{color: '#FF4D4F', padding: '5px', borderRadius: '2px', border: '1px solid rgba(0, 0, 0, 0.15)', background: '#FFF'}}
                onClick={()=>setShow(false)}
                />
            </div>
            {memberTarget ? null :
            <div className='d-flex align-items-center justify-content-between target-header'>
                <span className='team-header'>Overall {!overallTeamTarget && 'Team'} Target for {selectedMonth}</span>
                <span className='ft-header'>{monthlyTargetFTs > 0 ? monthlyTargetFTs + ' FTs' : 'Not set'}</span>
            </div>
            }
            <Divider style={{margin: '12px 0px'}}/>
            <div className='d-flex align-items-center justify-content-between target-table-header'>
                <span className='team-header'>{overallTeamTarget ? 'TEAM': 'TEAM MEMBER'}</span>
                <span className='ft-header'>Target FTs</span>
            </div>
            <Divider  style={{margin: '12px 0px'}}/>
            <div style={{overflow: 'scroll', paddingBottom: '40px',height: '60%'}}>
                {
                    teamList ?
                    teamList.map((team, index) => {
                        return (
                            <div key={team?.id ? team.id : team.teamId }>
                                {team?.role != 'admin' && team?.status != 'Inactive' && team?.key !== 'overall' ?
                                (mitraReducer?.mitraInfo?.role === 'teamLeader' && team?.role === 'teamLeader' ? null :
                                <div className='d-flex individual-target'>
                                    <span className='team-header' style={{marginLeft: '0px'}}>{team.name ? team.name : team?.label}</span>
                                    <div className='ft-header'>
                                    <Input
                                        type="number"
                                        value={
                                            overallTeamTarget ?
                                            (inputValues?.find(item => item.id === team.teamId) || {}).target || 0 :
                                            (inputValues?.find(item => item.id === team.id) || {}).target || 0
                                            }
                                        onChange={e => handleInputChange(team?.id ? team.id : team.teamId, e.target.value)}
                                    />
                                    </div>
                                </div>)
                                : null}
                            </div>
                        )
                    }) : null
                }
    
            </div>
            
        </div>
    </Modal>
  )
}

export default SetTargetPopup