import React, { useState } from 'react'
import '../../css/bulkAction.scss'
import { Checkbox , Typography} from 'antd';
const { Text } = Typography;

function JobDetail({rows, onJobChange, client,error}) {
	const [selected, setSelected] = useState({});

  return (
    <>
    {
      rows.map((row, i) => {
        return ( 
          <>
          <div className="job-detail-container"  key={i} selected={selected[i]}>
              <div className="job-details-top">
                <div className="checkbox">
                  <Checkbox checked={selected === i ? true : false}
                    onChange={() => {
                    setSelected(i);
                    onJobChange(row);}}>
                  </Checkbox></div>
                <div className="client-logo">
                  <img className="imgResponse" src={"/image/" + client + ".png"} />
                </div>
                <div className="job-type-container">
                  <div className="job-type">{row.role}</div>
                  <div className="verticle">{row.vertical}</div>
                </div>
              </div>
              <div className="salary-container">
                <div className="type">Base Salary</div>
                <div className="salary">Upto {row.baseSalary} - {row.requirements ? row.requirements.jobPreference : ''}</div>
              </div>
          </div>
          {error && <Text type="danger">{'* Job is required'}</Text>}
          </>
        )
      })
    }
    </>
  )
}

export default JobDetail