import React, { useState, useEffect } from 'react'
import { Modal, Select, Input, Divider, Button, notification } from 'antd';
import { CloseOutlined, CheckCircleOutlined, PlusOutlined, ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import './CreateEditTeamModalStyle.scss'
import '../../../../../../css/globalStyles.scss'
import { getOverallTeamCount, postTeamDetails, updateTeamDetails } from '../../../../../../service/accountService';
import { trackEvent } from '../../../../../../service/engagementMonitoringService';
import CONSTANTS from '../../../../../../constants/constants';

const { Option } = Select;

const CreateEditTeamModal = ({ isModalOpen, closeModal, mode = "create", currentTeamName = "", currentTL = {}, currentTCList = [], teamCreated }) => {
    const [teamName, setTeamName] = useState("");
    const [teamNameError, setTeamNameError] = useState(false);
    const [teamLeaderList, setTeamLeaderList] = useState([]);
    const [selectedTeamLeader, setSelectedTeamLeader] = useState(undefined);
    const [telecallersList, setTelecallersList] = useState([]);
    const [telecallersListCopy, setTelecallersListCopy] = useState([]);
    const [selectedTelecallers, setSelectedTelecallers] = useState([]);
    const [telecallerSearch, setTelecallerSearch] = useState("");
    const key = `open${Date.now()}`;
    const placement = 'topRight';
    const editMode = "edit"
    const createMode = "create"

    useEffect(() => {
        fetchAllTeamLeads()
        fetchAllTelecallers()
        if (mode == editMode) {
            setTeamName(currentTeamName)
        }
    }, [])

    const fetchAllTeamLeads = () => {
        const queryParams = {
            limit: 100,
            offset: 0,
            search: "",
            teamListOnly: true,
            type: "teamLeader",
            sortBy: "",
            team: ""
        };

        getOverallTeamCount(queryParams).then((teamListRes) => {
            if (teamListRes?.data?.teamList) {
                allowOnlyActiveTLs(teamListRes?.data?.teamList)
                // setTeamLeaderList(teamListRes.data.teamList)
                if (mode == editMode || Object.keys(currentTL).length > 0) {
                    prefillTL(teamListRes.data.teamList)
                }
            }
        })
    }

    const fetchAllTelecallers = () => {
        const queryParams = {
            limit: 100,
            offset: 0,
            search: "",
            teamListOnly: true,
            type: "teamMember",
            sortBy: "",
            team: ""
        };

        getOverallTeamCount(queryParams).then((teamListRes) => {
            if (teamListRes?.data?.teamList) {
                allowOnlyActiveTCs(teamListRes.data.teamList)
                if (mode == editMode) {
                    prefillTCs(teamListRes.data.teamList)
                }
            }
        })
    }

    const allowOnlyActiveTLs = (ogTLArr) => {
        const activeTLs = ogTLArr.filter(item => item.status === "Active");
        setTeamLeaderList(activeTLs)
    }

    const allowOnlyActiveTCs = (ogTCArr) => {
        const activeTCs = ogTCArr.filter(item => item.status === "Active");
        setTelecallersList(activeTCs)
        setTelecallersListCopy(activeTCs)
    }

    const prefillTL = (tlArr) => {
        for (let i = 0; i < tlArr.length; i++) {
            if (tlArr[i].id == currentTL.id) {
                setSelectedTeamLeader(tlArr[i])
                break;
            }
        }
    }

    const prefillTCs = (tcArr) => {
        let tempTCArr = []
        if (currentTCList && currentTCList.length) {
            for (let i = 0; i < currentTCList.length; i++) {
                for (let j = 0; j < tcArr.length; j++) {
                    if (currentTCList[i].id == tcArr[j].id) {
                        tempTCArr.push(tcArr[j])
                    }
                }
            }
            setSelectedTelecallers(JSON.parse(JSON.stringify(tempTCArr)))
        }
    }

    function hasSpecialCharacters(str) {
        // Regular expression to make sure Team name does not contain any special character
        const regex = /[^a-zA-Z0-9\s]/;
        return regex.test(str);
    }

    const onTeamNameChange = (inp) => {
        if (inp?.target?.value) {
            setTeamName(inp.target.value)
            if (hasSpecialCharacters(inp.target.value)) {
                setTeamNameError(true)
            }
            else {
                setTeamNameError(false)
            }
        }
        else {
            setTeamName("")
        }
    }

    const onTeamLeaderSelected = (teamLeader) => {
        const selectedTL = teamLeaderList.find(tl => tl.id === teamLeader)
        setSelectedTeamLeader(selectedTL)
    }

    const onTelecallersSelected = (telecallers) => {
        const selectedTCs = telecallers.map(({ value }) =>
            telecallersList.find(tc => tc.id === value)
        );
        setSelectedTelecallers(selectedTCs);
    }

    const removeSelectedTC = (tc) => {
        for (let i = 0; i < selectedTelecallers.length; i++) {
            if (tc.id == selectedTelecallers[i].id) {
                const updatedTCList = selectedTelecallers.filter(item => item.id !== selectedTelecallers[i].id);
                setSelectedTelecallers(updatedTCList);
                break;
            }
        }
    }

    const handleInputKeyDown = (e) => {
        if (e.key === 'Backspace') {
            e.stopPropagation(); // Stop the event from bubbling up
        }
    };

    const onTelecallerInpChange = (inp) => {
        inp.stopPropagation()
        inp.preventDefault();
        if (inp?.target?.value) {
            setTelecallerSearch(inp.target.value)
            let tempTCList = telecallersList.filter(telecaller => telecaller.name.toLowerCase().includes(inp.target.value.toLowerCase()))
            setTelecallersListCopy(JSON.parse(JSON.stringify(tempTCList)))
        }
        else if (inp?.target?.value == "") {
            setTelecallerSearch("")
            setTelecallersListCopy(JSON.parse(JSON.stringify(telecallersList)))
        }
    }

    const checkIfDetailsAreValid = () => {
        let isValid = true
        if (teamNameError) {
            isValid = false
        }
        return isValid;
    }

    const saveTeamDetails = () => {
        if (checkIfDetailsAreValid()) {
            let memberIDs = []
            if (selectedTelecallers && selectedTelecallers.length) {
                for (let j = 0; j < selectedTelecallers.length; j++) {
                    memberIDs.push(selectedTelecallers[j].id)
                }
            }

            let teamParams = {
                teamName: teamName
            }

            if(memberIDs && memberIDs.length){
                teamParams["memberIds"] = memberIDs
            }

            if (selectedTeamLeader && selectedTeamLeader.id) {
                teamParams["leadId"] = selectedTeamLeader.id
            }
            if (mode == createMode) {
                createTeam(teamParams)
            }
            else {
                updateTeam(teamParams)
            }
        }
        else {
            notification.open({
                message: "Please fix all errors before proceeding",
                key,
                duration: 5,
                placement,
                icon: <ExclamationCircleOutlined className='red-negative' />,
            });
        }
    }

    const createTeam = (teamParams) => {

        postTeamDetails(teamParams).then((teamCreatedRes) => {
            if (teamCreatedRes && teamCreatedRes.data && teamCreatedRes.data.status) {
                trackEvent('new_team_created_successfully', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                notification.open({
                    message: "Team created successfully",
                    key,
                    duration: 5,
                    placement,
                    icon: <CheckCircleOutlined className='green-positive' />,
                });
                teamCreated()
                closeModal()
            }
            else if (teamCreatedRes && teamCreatedRes.data && !teamCreatedRes.data.status) {
                trackEvent('new_team_create_failed', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                notification.open({
                    message: `${teamCreatedRes?.data?.message}`,
                    key,
                    duration: 5,
                    placement,
                    icon: <ExclamationCircleOutlined className='red-negative' />,
                });
            }
        })
            .catch((err) => {
                notification.open({
                    message: `${"We're not able to create a team right now. Please try again later."}`,
                    key,
                    duration: 5,
                    placement,
                    icon: <ExclamationCircleOutlined className='red-negative' />,
                });
            })
    }

    const updateTeam = (teamParams) => {
        updateTeamDetails(teamParams).then((teamCreatedRes) => {
            if (teamCreatedRes && teamCreatedRes.data && teamCreatedRes.data.status) {
                trackEvent('updated_team_successfully', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                notification.open({
                    message: "Team updated successfully",
                    key,
                    duration: 5,
                    placement,
                    icon: <CheckCircleOutlined className='green-positive' />,
                });
                teamCreated()
                closeModal()
            }
            else if (teamCreatedRes && teamCreatedRes.data && !teamCreatedRes.data.status) {
                trackEvent('updated_team_failed', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                notification.open({
                    message: `${teamCreatedRes?.data?.message}`,
                    key,
                    duration: 5,
                    placement,
                    icon: <ExclamationCircleOutlined className='red-negative' />,
                });
            }
        })
            .catch((err) => {
                notification.open({
                    message: "We're not able to edit team details right now. Please try again later.",
                    key,
                    duration: 5,
                    placement,
                    icon: <ExclamationCircleOutlined className='red-negative' />,
                });
            })
    }

    const tagRender = () => null;

    const isCreateBtnDisabled = () => {
        let isDisabled = false
        if (!teamName || teamNameError || !selectedTeamLeader) {
            isDisabled = true
        }
        else {
            isDisabled = false
        }
        return isDisabled;
    }

    return (
        <Modal
            title={null}
            centered
            visible={isModalOpen}
            footer={null}
            width={600}
            className="create-team-modal-parent"
            onCancel={closeModal}
        >
            <div className='ct-modal-content'>
                <div className='ctm-header d-flex justify-content-between align-items-center'>
                    <div className='ctm-header-title d-flex justify-content-between align-items-center'>
                        <div className='d-flex align-items-center'>
                            <span className='ctm-header-txt'>
                                {mode == editMode ? "Edit" : "Create"} Team
                            </span>
                        </div>
                    </div>
                    <div className='ctm-header-close jp-cp' onClick={closeModal}>
                        <CloseOutlined className='ctm-header-close-icon' />
                    </div>
                </div>

                <Divider />

                <div className='ctm-body'>
                    <div className='w-100'>
                        <div className='ctm-name-input-container ctm-field'>
                            <div className='ctm-name-input-parent d-flex align-items-center'>
                                <span className='ctm-name-input-label ctm-field-label regular14-22'>
                                    Name <span className='red-negative'>&nbsp;*</span>
                                </span>
                                <Input
                                    className='ctm-name-input'
                                    value={teamName}
                                    onChange={onTeamNameChange}
                                    placeholder="Enter Team Name"
                                    allowClear
                                />
                            </div>
                            {
                                teamNameError ?
                                    <span className='ctm-team-name-error regular14-22 red-negative'>Please remove all special characters from team name</span>
                                    :
                                    null
                            }
                        </div>

                        <div className='ctm-lead-dropdown-container ctm-dropdown-container d-flex align-items-center ctm-field'>
                            <span className='ctm-lead-select-label ctm-field-label regular14-22'>
                                Lead <span className='red-negative'>&nbsp;*</span>
                            </span>
                            <Select
                                className='ctm-tl-dropdown ctm-dropdown'
                                placeholder={`Select TL`}
                                onChange={(teamLeader) => { onTeamLeaderSelected(teamLeader) }}
                                value={selectedTeamLeader ? selectedTeamLeader.id : undefined}
                                disabled={currentTL && Object.keys(currentTL).length > 0}
                            >
                                {
                                    teamLeaderList?.map((option) => {
                                        return (
                                            <Option value={option.id} key={option.id}>
                                                <div className='d-flex justify-content-between w-100'>
                                                    <span className='regular14-22 black85'> {option.name}</span>
                                                    <span className='regular14-22 black45'> {option.team}</span>
                                                </div>
                                            </Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>

                        <div className='ctm-tc-dropdown-container ctm-field'>
                            <div className='d-flex ctm-dropdown-container align-items-center'>
                                <span className='ctm-tc-select-label ctm-field-label regular14-22'>
                                    Telecallers 
                                    {/* <span className='red-negative'>&nbsp;*</span> */}
                                </span>
                                <Select
                                    mode="multiple"
                                    placeholder={"Add Telecallers"}
                                    className='ctm-tc-dropdown ctm-dropdown'
                                    onChange={(telecaller) => { onTelecallersSelected(telecaller) }}
                                    value={selectedTelecallers.map(item => `${item.id}`)}
                                    optionLabelProp="name"
                                    labelInValue
                                    tagRender={tagRender}
                                    showSearch={false}
                                    suffixIcon={<PlusOutlined />}
                                    maxTagPlaceholder={(omittedValues) => <>{omittedValues.length + 1}</>}
                                    maxTagCount={0}
                                    dropdownRender={(menu) => (
                                        <div className='w-100'>
                                            <Input
                                                prefix={<SearchOutlined style={{ color: "rgba(0, 0, 0, 0.45)" }} />}
                                                value={telecallerSearch}
                                                onChange={(e) => { onTelecallerInpChange(e) }}
                                                placeholder="Search Telecallers"
                                                style={{ marginBottom: 8, maxWidth: "100%" }}
                                                allowClear
                                                onKeyDown={handleInputKeyDown}
                                            />
                                            <div style={{ maxHeight: 200, overflowY: 'auto' }}>
                                                {menu}
                                            </div>
                                        </div>
                                    )}
                                >
                                    {
                                        telecallersListCopy?.map((option) => {
                                            return (
                                                <Option key={option.id}>
                                                    <div className='d-flex justify-content-between w-100'>
                                                        <span className='regular14-22 black85'> {option.name}</span>
                                                        <span className='regular14-22 black45'> {option.team}</span>
                                                    </div>
                                                </Option>
                                            )
                                        })
                                    }
                                </Select>
                            </div>

                            <div className='ctm-selected-tc-list-parent d-flex align-items-center justify-content-end w-100'>
                                <div className='ctm-selected-tc-list d-flex' style={{ width: "80%" }}>
                                    {
                                        selectedTelecallers && selectedTelecallers.length ?
                                            selectedTelecallers.map((tc, tcInd) => {
                                                return (
                                                    <div className='ctm-selected-tc d-flex align-items-center' key={tcInd}>
                                                        <div className='regular12-20 primary-orange2'>
                                                            {tc.name}
                                                        </div>
                                                        <CloseOutlined className='ctm-selected-tc-icon black45 jp-cp' onClick={() => removeSelectedTC(tc)} />
                                                    </div>
                                                )
                                            })
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='ctm-footer'>
                    <Divider />
                    <div className='ctm-footer-btns-container d-flex justify-content-end h-100 align-items-center'>
                        <Button onClick={saveTeamDetails} className='vl-primary-btn ctm-footer-create-btn h-100' disabled={isCreateBtnDisabled()}>
                            <span className='bold16-24 plain-white'>
                                {mode == createMode ? "Create" : "Save"}
                            </span>
                        </Button>
                    </div>

                </div>
            </div>
        </Modal >
    )
}

export default CreateEditTeamModal; 