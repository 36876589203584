import React, { useState } from 'react'
import '../../style/BulkReferralStyle.scss'
import { UploadOutlined, DownloadOutlined, InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Divider, Button, Upload, message, Spin } from 'antd';
import { trackEvent } from '../../../../service/engagementMonitoringService';
import CONSTANTS from '../../../../constants/constants';

const BulkReferralStepThree = ({ selectedFileFromParent, returnStepThreeUploadedFile, onUploadClicked, isUploading }) => {
    const [fileUploaded, setFilUploaded] = useState(selectedFileFromParent)


    const onDownloadTemplateClicked = () => {
        const link = document.createElement('a');
        link.href = "https://uploads-a.vahan.co/uBUqb8-bulk-action-template.xlsx";
        link.download = 'response.xlsx'; // Specify the desired filename

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    }

    const props = {
        name: 'file',
        multiple: false,
        customRequest: async ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                trackEvent('bulk_referral_file_upload_result', { result: "success" }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                message.success(`${info.file.name} file uploaded successfully`);
                handleFileUpload(info);
            } else if (info.file.status === 'error') {
                trackEvent('bulk_referral_file_upload_result', { result: "failure" }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                message.error(`${info.file.name} file uploading failed`);
            } else if (info.file.status === "removed") {
                setFilUploaded([])
            }
        },
        maxCount: 1,
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
    };

    const handleFileUpload = (e) => {
        if (e.fileList.length > 0) {
            trackEvent('bulk_referral_file_uploaded', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
            setFilUploaded(e.fileList[0].originFileObj)
            returnStepThreeUploadedFile(e.fileList[0].originFileObj)
        }
    }

    return (
        <div className='br-step-parent br-step-three-upload-parent d-flex w-100 justify-content-between'>
            <div className='br-step-three-above-divider'>
                <div className='br-step-three-info-txt-container d-flex align-items-center justify-content-center'>
                    <InfoCircleOutlined className='br-step-three-info-icon'/>
                    <div className='br-step-three-info-txt' style={{marginLeft: '1vw'}}>
                        You need to upload a file of candidate names and their phone numbers.
                    </div>
                </div>

                <div className='br-step-three-upload-btn-container d-flex justify-content-center'>
                    {
                        fileUploaded && fileUploaded.length !== 0 ?
                            <Upload {...props} className='br-step-three-antd-upload d-flex w-100 justify-content-center'>
                                <Button className='br-step-three-reupload-btn d-flex w-100 align-items-center justify-content-center' onClick={() => trackEvent('bulk_referral_file_upload_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)}>
                                    <UploadOutlined className='br-step-three-btn-img-orange' />
                                    <div className='br-step-three-reupload-btn-txt'>
                                        Select A Different File
                                    </div>
                                </Button>
                            </Upload>
                            :
                            <Upload {...props} className='br-step-three-antd-upload d-flex w-100 justify-content-center'>
                                <Button className='br-step-three-upload-btn d-flex w-100 align-items-center justify-content-center' onClick={() => trackEvent('bulk_referral_file_upload_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)}>
                                    <UploadOutlined className='br-step-three-btn-img-white' />
                                    <div className='br-step-three-upload-btn-txt'>
                                        Select File
                                    </div>
                                </Button>
                            </Upload>
                    }

                </div>

                <div className='br-step-three-note-txt'>
                    Headers of the file must be <b>candidateName</b> and <b>candidatePhoneNumber</b>
                </div>

                <div className='br-step-three-dont-know-txt'>
                    <b>{`Don't know how the file should be?`}</b>
                </div>

                <div className='br-step-three-download-btn-container d-flex justify-content-center'>
                    <Button className='br-step-three-download-btn d-flex align-items-center justify-content-center' onClick={() => onDownloadTemplateClicked()}>
                        <DownloadOutlined className='br-step-btn-img-orange' />
                        <div className='br-step-three-download-btn-txt'>
                            Download Template
                        </div>
                    </Button>
                </div>
            </div>

            <div className='br-step-action-fields d-flex mobile-bottom-fixed'>
                <Divider style={{margin: '4px 0px 16px'}}></Divider>
                <div className='br-step-btns d-flex'>
                    <Button className='br-upload-action-btn d-flex align-items-center justify-content-center' onClick={() => onUploadClicked()} disabled={!fileUploaded || fileUploaded.length == 0 || isUploading}>
                        <UploadOutlined className='br-upload-action-btn-img-white' />
                        <div className='br-upload-action-btn-txt'>
                            Upload
                        </div>
                        {
                            isUploading ?
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "white", marginLeft: "8px" }} spin />} />
                                :
                                null
                        }
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default BulkReferralStepThree; 