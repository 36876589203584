import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom';
import { getpopularCities, bulkIVRUpload, getAudioFile, getClientsForBulkReferral, uploadReferralList, bulkWhatsappUpload } from './../../service/bulkActionService'
import * as loginService from '../../service/loginService';
import '../../css/bulkAction.scss'
import ClipLoader from 'react-spinners/ClipLoader';
import useGAEventsTracker from './../../service/useGSEventsTracker';
// import useMoengage from '../../hooks/useMoengage';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';
import * as homeService from './../../service/homeService';
import { UsergroupAddOutlined, FileExcelOutlined, RightCircleOutlined, BulbOutlined, InfoCircleOutlined, RightCircleFilled, UserAddOutlined, PhoneOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { Button, Result, Upload, message, Typography, Select, Card } from 'antd';
import Step1 from './steps/Step1.component'
import Step2 from './steps/Step2.component'
import Step3 from './steps/Step3.component'
import Step4 from './steps/Step4.component'
import JobDetail from './JobDetail.component'
import SideSelector from "../common/siderSelector";
import { getMitraTeamMembersList } from './../../service/commonService'
import { whatsappFeatureEnabledMitras } from '../../../src/constants/utils';
import { AppContext } from '../../App';


const { Text } = Typography;

const BulkAction = ({ setParentHeader, fileUploaded, setFilUploaded, uploadSuccess, setUploadSuccess, langSelected, setLangSelected, citySelected, setCitySelected, clientSelected, setClientSelcted, jobCategorySelected, setJobCategorySelected, jobLocationSelected, setJobLocationSelected, jobSelected, setJobSelcted, jobDemandSelected, setJobDemandSelected, bulkActionType, setBulkActionType, jobs, setJobs, openPage, openPageKey, submitClickedInSideSheet }) => {
    const { mitraReducer, mitraDispatch } = useContext(AppContext);
    const [header, setHeader] = useState('Bulk Actions')
    const [mitra, setMitra] = useState({})
    const [loading, setLoading] = useState(false);
    const [clientError, setClientError] = useState(false)
    const [cityError, setCityError] = useState(false)
    const [jobCategoriesError, setJobCategoriesError] = useState(false)
    const [jobLocationError, setJobLocationError] = useState(false)
    const [jobError, setJobError] = useState(false)
    const [fileError, setFileError] = useState(false);
    const [languageError, setLanguageError] = useState(false)
    const [uploadedLeadsData, setUploadedLeadsData] = useState('')
    const DEFAULT = 'default'
    const history = useHistory();
    const [audioURL, setAudioURL] = useState('');
    const [playing, setPlaying] = useState(false);
    const [uniquenessError, setUniquenessError] = useState('')
    const [IVRError, setIVRError] = useState('')
    // const [createMoengageUser, trackEvent] = useMoengage();
    const BulkActionGAEventsTracker = useGAEventsTracker("bulkActions")
    const [cities, setCities] = useState([]);
    const [clients, setClients] = useState([]);
    const [availableClients, setAvailableClients] = useState([])
    const [jobCategories, setJobCategories] = useState([])
    const [jobLocations, setJobLocations] = useState([])
    const [downloadFileLink, setDownloadFileLink] = useState('')
    const [mitraList, setMitraList] = useState([])
    const [teammemberIdSelected, setTeammemberIdSelected] = useState([])
    const [whtsappCampaign, setWhtsappCampaign] = useState('');

    const textHeading = {
        shadowfax: 'To upload documents for these leads',
        shadowfax_3pl: 'To upload documents for these leads',
        uber: 'To check uniqueness for Uber leads through the Vahan Leader app',
        uber_3pl: 'To check uniqueness for Uber leads through the Vahan Leader app'

    }

    const steps = (client) => {
        if (clientSelected.toLocaleLowerCase().indexOf('shadowfax') !== -1) {
            return (
                <>
                    <Step1 />
                    <Step2 />
                    <Step3 />
                    <Step4 step={4} />
                </>
            )
        }
        if (clientSelected.toLocaleLowerCase().indexOf('uber') !== -1) {
            return (
                <>
                    <Step1 />
                    <Step2 />
                    <Step4 step={3} />
                </>
            )
        }
    }


    useEffect(() => {
        loginService
            .validateSession()
            .then((response) => {
                setMitra(response.data.mitra);
                getMitraTeamMembersList().then(res => {
                    let mitraNameNumberArray = res.data.data.map(ele => {
                        return {
                            value: ele.id,
                            label: `${ele.name} - ${ele.phoneNumber}`
                        }
                    })
                    mitraNameNumberArray = [{ value: response.data.mitra.id, label: `${response.data.mitra.name} - ${response.data.mitra.phoneNumber}` }, ...mitraNameNumberArray]
                    setMitraList(mitraNameNumberArray)
                });

            })
            .catch((error) => history.push('/'));

        getpopularCities().then((response) => {
            setCities(response.data.premiumCities)
        })

    }, [])

    useEffect(() => {
        if (setParentHeader && openPage && openPage !== "") {
            if (openPage == "Bulk Referrals") {
                openRunBulkReferrals()
            }
            if (openPage == "Bulk IVR Calls") {
                openRunIVRSection()
            }
            if (openPage == "Bulk WhatsApp") {
                openRunWhatsappMessage()
            }
        }
    }, [openPage])

    const handleClientChange = (e) => {
        setClientError(false)
        setClientSelcted(e)
        getJobLocations(citySelected, e);
    }

    const handleFileUpload = (e) => {
        if (e.fileList.length > 0) {
            setFileError(false)
            setFilUploaded(e.fileList[0].originFileObj)
        }
    }
    // const handleFileUpload = (e) => {
    //     if(e.target.files.length > 0){
    //         setFileError(false)
    //         setFilUploaded(e.target.files[0])
    //     } 
    // }

    const getClientsAndCompaniesForBulkReferral = (city) => {
        if (city) {
            getClientsForBulkReferral(city, false, mitra.id).then((result) => {
                if (result && result.data.viewData.companies) {
                    const companyAndCategories = result.data.viewData.companies;
                    console.log(companyAndCategories)
                    setJobCategories(Object.keys(companyAndCategories));
                    setAvailableClients(companyAndCategories);
                }
            });
        }
    };
    const handleWhatsappSubmit = () => {
        console.log('whtsappCampaign :>> ', whtsappCampaign);
        if (whtsappCampaign == 'null' || !whtsappCampaign) {
            return;
        }
        if (!fileUploaded || fileUploaded.length === 0) {
            setFileError(true)
            return;
        }
        const formData = new FormData();
        formData.append('file', fileUploaded);
        formData.append('campaign', whtsappCampaign);
        setLoading(true)
        bulkWhatsappUpload(formData).then(response => {
            if (response.data) {
                setLoading(false)
                setUploadedLeadsData(response.data.data.bulkUploadUniquenessLeadId)
                setUploadSuccess(true)
                if (response.data.data.file) {
                    window.open(response.data.data.file, '_blank')
                }
            }
        })
            .catch((err) => {
                if (err.response && err.response.data) {
                    setIVRError(err.response.data.message)
                }
                setLoading(false)
            })
    }
    const handleCityChange = (e) => {
        setCitySelected(e)
        setJobCategories([]);
        setJobLocations([]);
        setJobLocationSelected(DEFAULT);
        setJobCategorySelected(DEFAULT);
        setClientSelcted(DEFAULT);
        setJobs([]);
        setJobSelcted(DEFAULT)
        getClientsAndCompaniesForBulkReferral(e);
        setCityError(false)
    }

    const handleJobCategoryChange = (e) => {
        setJobLocations([]);
        setJobs([]);
        setJobCategorySelected(e)
        setClients(availableClients[e]);
        setJobCategoriesError(false)
    }

    const getJobLocations = (city, company) => {
        if (city === 'Work from Home') {
            setJobLocations(['Work from Home']);
        } else {
            if (city && company) {
                homeService.getJobLocations(city, company).then((result) => {
                    if (result && result.data.viewData.jobLocations) {
                        let jobLocations = result.data.viewData.jobLocations;
                        jobLocations = jobLocations.map((c) => c.name);
                        setJobLocations(jobLocations);
                    }
                });
            }
        }
    };

    const handleJobLocationChange = (e) => {
        setJobLocationSelected(e)
        getJobs(jobCategorySelected, citySelected, clientSelected, e);
        setJobLocationError(false)
    }

    const handleTeammemberChange = (e) => {
        setTeammemberIdSelected(e)
    }

    const getJobs = (category, city, companyName, jobLocation) => {
        if (city && category && companyName && jobLocation) {
            homeService.getJobs(category, city, companyName, jobLocation).then((result) => {
                if (result && result.data.viewData.jobs) {
                    setJobs(result.data.viewData.jobs);
                }
            });
        }
    };

    const handleJobChange = (jobId, jobDemandId) => {
        setJobSelcted(jobId);
        setJobDemandSelected(jobDemandId);
        setJobError(false)
    };

    const handleLanguageChnage = (e) => {
        setLangSelected(e);
        if (e != '') {
            getAudioFile({ type: 'Delivery Workers Web', language: e }).then(response => {
                if (response.data.data) {
                    setAudioURL(response.data.data)
                }
            })
                .catch(err => {
                    setLoading(false)
                })
        }
    }
    const handleSubmit = () => {
        if (citySelected === DEFAULT) {
            setCityError(true);
            return;
        }
        if (clientSelected === DEFAULT) {
            setClientError(true)
            return;
        }

        if (jobCategorySelected === DEFAULT) {
            setJobCategoriesError(true);
            return;
        }

        if (jobLocationSelected === DEFAULT) {
            setJobLocationError(true);
            return;
        }

        if (jobSelected === DEFAULT) {
            setJobError(true);
            return;
        }

        if (!fileUploaded || fileUploaded.length === 0) {
            setFileError(true)
            return;
        }

        setLoading(true);

        const data = new FormData();
        data.append('files', fileUploaded);
        data.append('city', citySelected);
        data.append('category', jobCategorySelected);
        data.append('companyName', clientSelected);
        data.append('jobId', jobSelected);
        data.append('jobLocation', jobLocationSelected);
        data.append('jobDemandId', jobDemandSelected);
        data.append('mitraManagerId', mitra.id);
        data.append('mitraListSelected', teammemberIdSelected.length > 0 ? JSON.stringify(teammemberIdSelected) : JSON.stringify([mitra.id]));

        console.log("OLD_BULK_API_DATA", data)
        uploadReferralList(data).then(response => {
            console.log(response)
            if (response.data.status) {
                setLoading(false)
                setUploadSuccess(response.data.data.bulkUploadUniquenessLeadId)
                if (response.data.candidateResponse) {
                    setDownloadFileLink(response.data.candidateResponse)
                    downloadFileWithUrl(response.data.candidateResponse)
                }
            }
        })
            .catch(err => {
                if (err.response && err.response.data) {
                    setUniquenessError(err.response.data?.error !== undefined ? err.response.data?.error : err.response.data?.errors[0])
                }
                setLoading(false)
            })
    }
    const playPauseAudio = (flag) => {
        const x = document.getElementById("myAudio");
        if (x.getAttribute('listener') !== 'true') {
            x.addEventListener('timeupdate', handleAudioProgress);
        }
        if (flag) {
            x.play()
        } else {
            x.pause();
        }

    }
    const audioPlay = () => { setPlaying(!playing); playPauseAudio(!playing) }
    const handleAudioProgress = () => {
        const player = document.getElementById("myAudio");
        var currentTime = player.currentTime;
        var duration = player.duration;
        const slider = document.getElementById("hp_range");
        if (slider)
            slider.style.width = (currentTime + .25) / duration * 100 + '%';
    }
    const handleSubmitIVR = () => {
        if (langSelected == 'null' || !langSelected) {
            setLanguageError(true)
            return;
        }
        if (!fileUploaded || fileUploaded.length === 0) {
            setFileError(true)
            return;
        }
        const formData = new FormData();
        formData.append('file', fileUploaded);
        formData.append('language', langSelected);
        setLoading(true)
        bulkIVRUpload(formData).then(response => {
            if (response.data) {
                setLoading(false)
                setUploadedLeadsData(response.data.data.bulkUploadUniquenessLeadId)
                setUploadSuccess(true)
                if (response.data.data.file) {
                    setDownloadFileLink(response.data.data.file)
                    downloadFileWithUrl(response.data.data.file)
                }
            }
        })
            .catch((err) => {
                if (err.response && err.response.data) {
                    setIVRError(err.response.data.message)
                }
                setLoading(false)
            })
    }

    const props = {
        name: 'file',
        multiple: false,
        customRequest: async ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                // message.success(`${info.file.name} file uploaded successfully`);
                handleFileUpload(info);
                let eventName = bulkActionType == 'Bulk IVR calls' ? "ClickOnUploadLeadsBulkIVR" : (bulkActionType == 'Bulk Whatsapp Messages' ? 'ClickOnUploadLeadsWhatsapp' : 'ClickOnUploadLeadsBulkUniqueness')
                BulkActionGAEventsTracker(eventName)
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file uploading failed`);
            } else if (info.file.status === "removed") {
                setFilUploaded([])
            }
        },
        maxCount: 1,
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',

    };

    const downloadTemplate = (url, value) => {
        BulkActionGAEventsTracker(value);
        downloadFileWithUrl(url)
    }

    const downloadFileWithUrl = (url) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = 'response.xlsx'; // Specify the desired filename

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    }

    const openRunBulkReferrals = () => {
        setBulkActionType('Bulk Referral');
        setHeader('Bulk Referral');
        BulkActionGAEventsTracker("ClickOnBulkUniqueness");
        if (setParentHeader && setParentHeader !== undefined) {
            setParentHeader = 'Bulk Referral'
        }
        trackEvent('bulk_referral_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    }

    const openRunIVRSection = () => {
        setBulkActionType('Bulk IVR calls');
        setHeader('Run Bulk IVR Calls');
        BulkActionGAEventsTracker("ClickOnBulkIVR");
        if (setParentHeader && setParentHeader !== undefined) {
            setParentHeader = 'Run Bulk IVR Calls'
        }
        trackEvent('bulk_ivr_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    }

    const openRunWhatsappMessage = () => {
        setBulkActionType('Bulk Whatsapp Messages');
        setHeader('Run Bulk Whatsapp Message');
        BulkActionGAEventsTracker("ClickOnBulkWhatsppMessage");
        if (setParentHeader && setParentHeader !== undefined) {
            setParentHeader = 'Run Bulk Whatsapp Message'
        }
        trackEvent('bulk_whatsapp_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    }

    return (
        <div className="bulk-action-container">
            {
                bulkActionType == '' && !loading ?
                    <div>
                        {/* <button type="button" className="btn btn-outline-danger buttons-bulk-actions" onClick={() => {setBulkActionType('Bulk Referral'); setHeader('Bulk Referral'); BulkActionGAEventsTracker("ClickOnBulkUniqueness"); if (setParentHeader !== undefined) {setParentHeader('Bulk Referral')}; trackEvent('bulk_referral_clicked', {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);}}>
                            <UsergroupAddOutlined style={{width: '10%', marginRight: '4px'}} />
                            <span style={{width: '75%', textAlign: 'left'}} className="vertical-middle">Bulk Referrals</span>
                                <RightCircleOutlined style={{fontSize: '20px'}} />
                        </button> */}
                        <Button className='btn-container' onClick={() => {
                            setBulkActionType('Bulk Referral');
                            setHeader('Bulk Referral');
                            BulkActionGAEventsTracker("ClickOnBulkUniqueness");
                            if (setParentHeader && setParentHeader !== undefined) {
                                setParentHeader('Bulk Referral')
                            }
                            trackEvent('bulk_referral_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                        }}>
                            <div className='CTA-context'>
                                <UserAddOutlined />
                                <span>
                                    Bulk Referrals
                                </span>
                            </div>
                            <RightCircleFilled />
                        </Button>

                        <Button className='btn-container' onClick={() => {
                            setBulkActionType('Bulk IVR calls');
                            setHeader('Run Bulk IVR Calls');
                            BulkActionGAEventsTracker("ClickOnBulkIVR");
                            if (setParentHeader && setParentHeader !== undefined) {
                                setParentHeader('Run Bulk IVR Calls')
                            }
                            trackEvent('bulk_ivr_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                        }}>
                            <div className='CTA-context'>
                                <PhoneOutlined />
                                <span>
                                    Run Bulk IVR calls
                                </span>
                            </div>
                            <RightCircleFilled />
                        </Button>
                        {
                            whatsappFeatureEnabledMitras(mitraReducer?.mitraInfo) && <Button className='btn-container' onClick={() => {
                                setBulkActionType('Bulk Whatsapp Messages');
                                setHeader('Run Bulk Whatsapp Message');
                                BulkActionGAEventsTracker("ClickOnBulkWhatsppMessage");
                                if (setParentHeader && setParentHeader !== undefined) {
                                    setParentHeader('Run Bulk Whatsapp Message')
                                }
                                trackEvent('bulk_whatsapp_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                            }}>
                                <div className='CTA-context'>
                                    <WhatsAppOutlined />
                                    <span>
                                        Run Bulk Whatsapp Message
                                    </span>
                                </div>
                                <RightCircleFilled />
                            </Button>
                        }
                        {/* <button type="button" className="btn btn-outline-danger buttons-bulk-actions" onClick={() => {setBulkActionType('Bulk IVR calls'); setHeader('Run Bulk IVR Calls'); BulkActionGAEventsTracker("ClickOnBulkIVR"); if (setParentHeader !== undefined) {setParentHeader('Run Bulk IVR Calls')}; trackEvent('bulk_ivr_clicked', {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);}}>
                            <UsergroupAddOutlined style={{width: '10%', marginRight: '4px'}} />
                            <span style={{width: '75%', textAlign: 'left'}} className="vertical-middle">Run Bulk IVR calls</span>
                                <RightCircleOutlined style={{fontSize: '20px'}} />
                        </button>
                        <br /> */}
                        {/* <div style={{padding: '0px 30px', display: 'flex', flexDirection: 'row'}}>
                            <div>
                                <span style={{border: 'solid 1px', borderRadius: '100px', padding: '3px 8px', fontSize: '12px', marginRight: '5px'}}>
                                    i
                                </span>
                            </div>
                            <span style={{textAlign: 'justify'}}>
                                Interactive Voice Response(IVR) is an automated phone system technology that allows incoming callers to access information via a voice response system of pre recorded messages.
                            </span>
                        </div> */}
                        <Text style={{ display: 'flex', alignItems: 'baseline', marginTop: 10 }} type="secondary">
                            <InfoCircleOutlined />
                            Interactive Voice Response(IVR) is an automated phone system technology that allows incoming callers to access information via a voice response system of pre recorded messages.
                        </Text>
                    </div>
                    : null
            }
            <div>
                {
                    bulkActionType === 'Bulk IVR calls' && !loading && !uploadSuccess ?
                        <div>
                            <SideSelector
                                labelname="Select Language"
                                selectorData={['Hindi']}
                                error={{ status: languageError, text: 'Language is required' }}
                                placeholder="Select the Language"
                                required
                                onChange={(e) => { handleLanguageChnage(e); BulkActionGAEventsTracker("ClickOnLanguageSelectorBulkIVR") }}
                            />
                            {/* <div className="col-12 col-md-12"  >
                                <select style={{width: '90%'}} name="language" placeholder="language" value={langSelected} onChange={(e) => {handleLanguageChnage(e); BulkActionGAEventsTracker("ClickOnLanguageSelectorBulkIVR")}}>
                                    <option value={''}>
                                        
                                    </option>
                                    {['Hindi'].map((lang) => (
                                        <option className="Dropdown-option" key={lang} value={lang}>
                                            {lang}
                                        </option>
                                    ))}
                                </select>
                            </div> */}

                            {/* {
                                languageError ?
                                    <div className="error-message">
                                        * Language is required
                                    </div>
                                    : null
                            } */}

                            {
                                audioURL ? <div className="row">
                                    <div className="col-2 col-md-2"  >
                                        <button style={{ fontSize: '40px', color: 'rgb(211, 70, 18)' }} onClick={audioPlay}><i className={playing ? 'fas fa-pause-circle' : 'fas fa-play-circle'}></i></button>
                                        <audio id="myAudio">
                                            <source src={audioURL} type="audio/mpeg" />
                                        </audio>
                                    </div>
                                    <div className="col-8 col-md-8">
                                        {playing ? <div className="hp_slide">
                                            <div className="hp_range" id="hp_range"></div>
                                        </div> : null}
                                    </div>
                                </div> : null
                            }
                            <div className="noteHeading">
                                <div className="i-icon">
                                    <InfoCircleOutlined />
                                </div>
                                <div className="text">
                                    Note: Headers of the file must be candidateName and candidatePhoneNumber
                                </div>
                            </div>

                            <div className='upload-documents'>
                                {/* <div style={{}} type="file" className="upload-leads">
                                    <label htmlFor="file-upload" className="btn upload-leads-label" style={{color: '#D34612', textAlign: 'center'}}>
                                        <FileExcelOutlined style={{verticalAlign: 'baseline'}} />&nbsp;&nbsp;
                                        Upload Leads
                                    </label>
                                    <input id="file-upload" style={{visibility: "hidden"}} type="file" onChange={(e) => {handleFileUpload(e); BulkActionGAEventsTracker("ClickOnUploadLeadsBulkIVR")}} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                </div> */}
                                <Upload {...props}>
                                    <Button type='primary' className='button-primary' icon={<FileExcelOutlined block />}>Upload Leads</Button>
                                </Upload>
                                {/* {
                                    fileUploaded.length !== 0 ?
                                        <div onClick={() => {setFilUploaded([]); document.getElementById('file-upload').value = []}} className="file-uploaded">
                                            <div style={{width: '90%', textAlign: 'left'}}>
                                                <FileExcelOutlined style={{verticalAlign: 'baseline'}} />&nbsp;&nbsp;
                                                {fileUploaded.name}
                                            </div>

                                            <i className="far fa-times-circle vertical-middle" style={{width: '10%', textAlign: 'right'}}></i>
                                        </div>
                                        : null
                                } */}

                                {
                                    fileError && <Text type="danger">* Upload the Leads document.</Text>
                                }
                            </div>
                            {/* <a type="button" className="download-template" target="_blank" href="https://docs.google.com/spreadsheets/d/1O_vq_3ngvPlgkwFVypMvRVIn_2_ukIcTrpwNQYG6jb4/edit?usp=gmail_thread&ts=61a75458" onClick={() => {BulkActionGAEventsTracker("ClickOnDownloadTemplateBulkIVR")}}>
                                <FileExcelOutlined style={{verticalAlign: 'baseline'}} />&nbsp;&nbsp;
                                Download Template
                            </a> */}
                            <Button onClick={() => downloadTemplate("https://uploads-a.vahan.co/uBUqb8-bulk-action-template.xlsx", 'ClickOnDownloadTemplateBulkIVR')} className='button-primary' icon={<FileExcelOutlined />} type="dashed" danger>
                                Download Template
                            </Button>

                            {
                                IVRError !== '' && <Text type="danger">*{IVRError}</Text>
                            }
                            <Button className="submit-button" type='primary' onClick={() => { handleSubmitIVR(); BulkActionGAEventsTracker("SsubmitBulkIVRForm") }}>Submit</Button>
                        </div>
                        : null
                }


                {
                    bulkActionType !== '' && bulkActionType === 'Bulk Referral' && !loading && !uploadSuccess ?
                        <div>
                            <SideSelector
                                labelname="City"
                                selectorData={cities}
                                error={{ status: cityError, text: 'City is required' }}
                                placeholder="Select City"
                                required
                                onChange={(e) => { handleCityChange(e) }}
                                autoFocus
                            />
                            {/* City */}
                            {/* <div style={{textAlign: 'left', padding: '5px 30px'}}>
                                <span>City</span>
                                <span style={{color: 'red', marginRight: '2px'}}> *</span>
                            </div>
                            <div className="col-12 col-md-12">
                                        <select style={{width: '90%'}} name="City" placeholder="City" value={citySelected} onChange={(e) => {handleCityChange(e);}}>
                                            <option defaultValue={DEFAULT} >
                                                Select City
                                            </option>
                                            {
                                                cities.map(single_city => {
                                                    return <option value={single_city} >
                                                        {single_city}
                                                    </option>
                                                })
                                            }
                                        </select>
                                    </div>

                            {
                                cityError ?
                                    <div className="error-message">
                                        * City is required
                                    </div>
                                    : null
                            } */}

                            <SideSelector
                                labelname="Job Category"
                                selectorData={jobCategories}
                                error={{ status: jobCategoriesError, text: 'Job Category is required' }}
                                placeholder="Select Job Category"
                                required
                                onChange={(e) => { handleJobCategoryChange(e) }}
                                disabled={jobCategories.length == 0}
                            />

                            {/* Company / Client */}

                            {/* <div style={{textAlign: 'left', padding: '5px 30px'}}>
                                <span>Client Name</span>
                                <span style={{color: 'red', marginRight: '2px'}}> *</span>
                            </div>
                            <div className="col-12 col-md-12"  >
                                <select style={{width: '90%'}} name="Client" placeholder="Client" value={clientSelected} onChange={(e) => {handleClientChange(e);}}>
                                    <option defaultValue={DEFAULT} >
                                        Select Client
                                    </option>
                                    {
                                        clients.map(single_client => {
                                            return <option value={single_client} >
                                                {single_client}
                                            </option>
                                        })
                                    }
                                </select>
                            </div>

                            {
                                clientError ?
                                    <div className="error-message">
                                        * Client name is required
                                    </div>
                                    : null
                            } */}
                            <SideSelector
                                labelname="Client Name"
                                selectorData={clients}
                                error={{ status: clientError, text: 'Client name is required' }}
                                placeholder="Select Client"
                                required
                                onChange={(e) => { handleClientChange(e) }}
                                disabled={clients.length == 0}
                            />
                            {/* Job Category */}

                            {/* <div style={{textAlign: 'left', padding: '5px 30px'}}>
                                <span>Job Category</span>
                                <span style={{color: 'red', marginRight: '2px'}}> *</span>
                            </div>
                            <div className="col-12 col-md-12"  >
                                <select style={{width: '90%'}} name="jobCategory" placeholder="Job Category" value={jobCategorySelected} onChange={(e) => {handleJobCategoryChange(e); }}>
                                    <option defaultValue={DEFAULT} >
                                        Select Job Category
                                    </option>
                                    {
                                        jobCategories.map(ele => {
                                            return <option value={ele} >
                                                {ele}
                                            </option>
                                        })
                                    }
                                </select>
                            </div>

                            {
                                jobCategoriesError ?
                                    <div className="error-message">
                                        * Job Category is required
                                    </div>
                                    : null
                            } */}

                            <SideSelector
                                labelname="Job Location"
                                selectorData={jobLocations}
                                error={{ status: jobLocationError, text: 'Job Location is required' }}
                                placeholder="Select Job Location"
                                required
                                onChange={(e) => { handleJobLocationChange(e) }}
                                disabled={jobLocations.length == 0}
                            />
                            {/* Job Location */}

                            {/* <div style={{textAlign: 'left', padding: '5px 30px'}}>
                                <span>Job Location</span>
                                <span style={{color: 'red', marginRight: '2px'}}> *</span>
                            </div>
                            <div className="col-12 col-md-12"  >
                                <select style={{width: '90%'}} name="jobLocation" placeholder="Job Location" value={jobLocationSelected} onChange={(e) => {handleJobLocationChange(e); }}>
                                    <option defaultValue={DEFAULT} >
                                        Select Job Location
                                    </option>
                                    {
                                        jobLocations.map(ele => {
                                            return <option value={ele} >
                                                {ele}
                                            </option>
                                        })
                                    }
                                </select>
                            </div>

                            {
                                jobLocationError ?
                                    <div className="error-message">
                                        * Job Location is required
                                    </div>
                                    : null
                            } */}

                            {
                                jobs && (jobs.length ? (
                                    <div className="">
                                        <JobDetail error={jobError} rows={jobs} onJobChange={job => handleJobChange(job.id, job.jobDemandId)} client={clientSelected}></JobDetail>
                                    </div>)
                                    : null)
                            }
                            {
                                CONSTANTS.crmExceptionMitras.includes(mitra.id) &&
                                <div>
                                    <label style={{
                                        display: 'flex',
                                        width: '100%',
                                        marginBottom: '5px',
                                        marginLeft: '8px',
                                    }}>Select Team member</label>
                                    {/* <Select
                                        showSearch
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Search Team Member"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                        filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options = {mitraList}
                                        onChange = {handleTeammemberChange}
                                    /> */}

                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Search Team Member"
                                        onChange={handleTeammemberChange}
                                        options={mitraList}
                                        filterOption={(inputValue, option) =>
                                            option.label.toLowerCase().includes(inputValue.toLowerCase())
                                        }
                                    />
                                </div>
                            }


                            <div className="noteHeading">
                                <div className="i-icon">
                                    <InfoCircleOutlined />
                                </div>
                                <div className="text">
                                    Note: Headers of the file must be candidateName and candidatePhoneNumber
                                </div>
                            </div>

                            <div className='upload-documents'>
                                {/* <div style={{}} type="file" className="upload-leads">
                                    <label htmlFor="file-upload" className="btn upload-leads-label" style={{color: '#D34612', textAlign: 'center'}}>
                                        <FileExcelOutlined style={{verticalAlign: 'baseline'}} />
                                        Upload Leads
                                    </label>
                                    <input id="file-upload" style={{visibility: "hidden"}} type="file" onChange={(e) => {handleFileUpload(e); BulkActionGAEventsTracker("ClickOnUploadLeadsBulkUniqueness")}} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                </div> */}
                                <Upload  {...props}>
                                    <Button type='primary' className='button-primary' icon={<FileExcelOutlined block />}>Upload Leads</Button>
                                </Upload>
                                {/* {
                                    fileUploaded.length !== 0 ?
                                        <div onClick={() => {setFilUploaded([]); document.getElementById('file-upload').value = []}} className="file-uploaded">
                                            <div style={{width: '90%', textAlign: 'left'}}>
                                                <FileExcelOutlined style={{verticalAlign: 'baseline'}} />
                                                {fileUploaded.name}
                                            </div>

                                            <i className="far fa-times-circle vertical-middle" style={{width: '10%', textAlign: 'right'}}></i>
                                        </div>
                                        : null
                                } */}

                                {
                                    fileError && <Text type="danger">* Upload the Leads document.</Text>
                                }

                            </div>
                            {/* <a type="button" className="download-template" target="_blank" href="https://docs.google.com/spreadsheets/d/1O_vq_3ngvPlgkwFVypMvRVIn_2_ukIcTrpwNQYG6jb4/edit?usp=gmail_thread&ts=61a75458" onClick={() => {BulkActionGAEventsTracker("ClickOnDownloadTemplateBulkUniqueness")}}>
                                <FileExcelOutlined style={{verticalAlign: 'baseline'}} />&nbsp;&nbsp;
                                Download Template
                            </a> */}
                            <Button onClick={() => downloadTemplate("https://uploads-a.vahan.co/uBUqb8-bulk-action-template.xlsx", 'ClickOnDownloadTemplateBulkUniqueness')} className='button-primary' icon={<FileExcelOutlined />} type="dashed" danger>
                                Download Template
                            </Button>
                            {
                                uniquenessError !== '' ?
                                    <div style={{ color: 'red', padding: '5px 35px', textAlign: 'justify' }}>* {uniquenessError}</div>
                                    : null
                            }
                            <Button className="submit-button" type='primary' onClick={() => { handleSubmit(); BulkActionGAEventsTracker("submitBulkUniquenessForm") }}>Submit</Button>
                        </div>
                        : null
                }
                {
                    bulkActionType === 'Bulk Whatsapp Messages' && !loading && !uploadSuccess ?
                        <div>
                            <SideSelector
                                labelname="Select Campaign"
                                selectorData={['Delivery Interested Leads']}
                                error={{ status: languageError, text: 'Campaign is required' }}
                                placeholder="Select the Campaign"
                                required
                                onChange={(e) => { setWhtsappCampaign(e); BulkActionGAEventsTracker("ClickOnCampaignSelectorBulkWhatsapp") }}
                            />
                            {
                                whtsappCampaign && <Card size="small" title="Message Content">
                                    <p> Hi {'{candidateName}'}, <br />
                                        Get a delivery job with Zomato, Zepto, Swiggy and other companies!
                                        <br />
                                        You can earn upto Rs.35,000 per month!
                                        <br />
                                        Documents Needed - Aadhar card, PAN Card and Driving License.
                                        <br />
                                        Are you interested in in Delivery Jobs?
                                        <br />
                                        [Interested] [Not Interested]
                                    </p>



                                </Card>
                            }

                            <div className="noteHeading">
                                <div className="i-icon">
                                    <InfoCircleOutlined />
                                </div>
                                <div className="text">
                                    Note: Headers of the file must be candidateName and candidatePhoneNumber
                                </div>
                            </div>

                            <div className='upload-documents'>
                                <Upload {...props}>
                                    <Button type='primary' className='button-primary' icon={<FileExcelOutlined block />}>Upload Leads</Button>
                                </Upload>

                                {
                                    fileError && <Text type="danger">* Upload the Leads document.</Text>
                                }
                            </div>
                            <Button onClick={() => downloadTemplate("https://uploads-a.vahan.co/uBUqb8-bulk-action-template.xlsx", 'ClickOnDownloadTemplateBulkIVR')} className='button-primary' icon={<FileExcelOutlined />} type="dashed" danger>
                                Download Template
                            </Button>

                            {
                                IVRError !== '' && <Text type="danger">*{IVRError}</Text>
                            }
                            <Button className="submit-button" type='primary' onClick={() => { handleWhatsappSubmit(); BulkActionGAEventsTracker("SsubmitBulkIVRForm") }}>Submit</Button>
                        </div>
                        : null
                }
                {
                    loading ?
                        <div className="loader_clip">
                            <ClipLoader size={100} />
                            <div>Please do not refresh or Press back button file is uploading! </div>
                        </div>
                        : null
                }

                {
                    uploadSuccess ?
                        <div>
                            <div style={{ textAlign: 'center' }} className="upload-completed">
                                <Result
                                    status="success"
                                    title="File Successfully Uploaded!"
                                    style={{ padding: '0px' }}
                                />
                                <div>
                                    <Button type='primary' onClick={() => { downloadFileWithUrl(downloadFileLink) }}>Download Response</Button>
                                </div>
                                <hr style={{ color: '#d1d0d0' }} />
                                {
                                    clientSelected.toLocaleLowerCase().indexOf('shadowfax') !== -1 || clientSelected.toLocaleLowerCase().indexOf('uber') !== -1 ?
                                        <>
                                            <div className="after-upload-details">
                                                <div className="text-banner">
                                                    <div className="bulb-icon">
                                                        <BulbOutlined />
                                                    </div>
                                                    <div className="text">
                                                        <div className="text-heading">{textHeading[clientSelected.toLocaleLowerCase()]}</div>
                                                        <div className="text-details">Please take the following steps: </div>
                                                    </div>
                                                </div>
                                                <div className="steps">
                                                    {
                                                        steps(clientSelected.toLocaleLowerCase())
                                                    }
                                                </div>
                                            </div>
                                            <div className="got-it-button">
                                                <Button className="got-it" onClick={() => history.push('/leads/all-candidates')}> Okay, Got It! </Button>
                                            </div>
                                        </>
                                        :
                                        <div className="got-it-button">
                                            <Button className="got-it" onClick={() => !openPageKey ? window.location.reload() : submitClickedInSideSheet(openPageKey)}> View Leads </Button>
                                        </div>
                                }
                            </div>
                        </div>
                        : null
                }
            </div>
        </div>
    )
}

export default BulkAction;
