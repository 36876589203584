import React, {useEffect, useState, useContext} from 'react';
import PageHeaderComponent from '../common/PageHeader';
import {Tabs, Typography, Skeleton, notification, Button, message, Modal, Input, Empty, Dropdown, Space, Menu, Tooltip} from 'antd';
import {useHistory} from 'react-router-dom';
import * as incentiveService from '../../service/incentiveService.js';
import { loginForPayoutDashboard, sendPayoutDashboardPasswordResetUrl } from './../../service/commonService'
import { InfoCircleOutlined, EyeTwoTone, EyeInvisibleOutlined, CaretDownOutlined, DownOutlined } from '@ant-design/icons';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';

import {AppContext} from "../../App";

import '../../css/incentives.scss';

const {TabPane} = Tabs;
const {Text, Paragraph, Link} = Typography;

const Incentives = () => {
    const {setAlert, mitraReducer} = useContext(AppContext);
    const [breadcrumb] = useState([{link: '/incentives', label: 'Incentives'}]);
    const [visibleAuth, setVisible] = useState(false);
    const [accessPasswordUsr, setAccessPasswordUsr] = useState('');
    const history = useHistory();
    const [mitraType, setMitraType] = useState('sg');
    const [period, setPeriod] = useState('');
    const [incentiveType, setIncentiveType] = useState('');
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [reloadAfterLogin, setReloadAfterLogin] = useState(false);
    const [monthsList, setMonthsList] = useState([]);
    const [monthSelected, setMonthSelected] = useState('');
    const [tncId, setTncId] = useState('');


    useEffect(() => {
        setLoading(true)
        if(monthsList.length === 0) {
            incentiveService.fetchMonthItemsForIncentive().then(response => {
                if(response.data.status === true) {
                    setMonthsList(response.data.data)
                    setMonthSelected(response.data.data[1].label)
                }
                else{
                    setMonthsList([])
                }
            }).catch(error => {
                console.log(error);
                localStorage.removeItem('payoutAccessToken');
                setVisible(true);
            });
        }
        if(monthsList.length > 0) {
            const startDateFoIncentive = monthsList.filter(item => item.label === monthSelected)[0]?.startDate
            incentiveService.fetchIncentive({mitraType, month: monthSelected, startDate: startDateFoIncentive}).then(response => {
                
                if(response.data.status === true) {
                    setPeriod(response.data.data.period)
                    setIncentiveType(response.data.data.incentiveType)
                    setList(response.data.data.incentiveList)
                    setTncId(response.data.data.tncId)
                }
                else{
                    setList([])
                }
                setLoading(false)
            }).catch(error => {
                console.log(error);
                setLoading(false)
                localStorage.removeItem('payoutAccessToken');
                setVisible(true);
            });
        }
    }, [mitraType, reloadAfterLogin, monthSelected]);

    const onResetPasswordClick = () => {

        const key = `open${Date.now()}`;
        const btn = (
            <Button type="primary" size="small" onClick={() => history.push(`/incentives`)}>
              Go to Incentives
            </Button>
        );

        notification.open({
            message: `Notification`,
            description: `A password ${mitraReducer?.mitraInfo?.password ? 'reset' : 'creation'} SMS has been sent to your phone number ${mitraReducer?.mitraInfo?.phoneNumber} and email ${mitraReducer?.mitraInfo?.email}`,
            btn,
            key,
          });
        let isNewPassword = mitraReducer?.mitraInfo?.password ? false : true
        sendPayoutDashboardPasswordResetUrl(isNewPassword);
        history.push('/home')
    }

    const handleOkClick = () => {
        loginForPayoutDashboard({ password: accessPasswordUsr }).then(res => {
            if (res.data.success) {
                localStorage.setItem("payoutAccessToken", res.headers.payoutaccesstoken)
                setVisible(false);
                setReloadAfterLogin(true);
            } else {
                message.error("Invalid credentials!")
            }
        }).catch((e) => {
            message.error("Invalid credentials!")
        })
    }

    const getButtons = () => {
        let buttonsArr = [];
        if (mitraReducer?.mitraInfo?.password) {
            buttonsArr.push(
                <Button
                    onClick={onResetPasswordClick}
                    type="secondary">
                    <Text >Reset Password</Text>
                </Button>
            );
            buttonsArr.push(
                <Button
                    onClick={handleOkClick}
                    type="primary">
                    <Text style={{ color: '#fff' }}> OK </Text>
                </Button>
            )
        }
        return buttonsArr;
    }

    const onClickExpand = (clientIndex, locationIndex) => {
        const newList = [...list];
        newList[clientIndex].locationList[locationIndex].isExpanded = !newList[clientIndex].locationList[locationIndex].isExpanded;
        setList(newList);
		trackEvent('Click_accordian_expand_incentive', {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);

    }

    const onChange = (key) => {
        setMitraType(key === '1' ? `sg` : `bronze`)
    };

    const downloadMenu = (
        <Menu onClick={(e) => {
            setMonthSelected(monthsList[e.key].label)
        }}>
            {monthsList.map((item, index) => {
                return <Menu.Item key={item.key}>
                    {item.label}
                </Menu.Item>
            })}
        </Menu>
	);

    const onClickTnC = (termsAndConditionsId) => {
		trackEvent('Click_T&C_incentive', {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        history.push({
            pathname: '/incentives/termsAndConditions',
            search: new URLSearchParams({termsAndConditionsId}).toString()
        })
    }


    return (
        <>
            <div className="incentive-page-container">
				<div className="header">
					<PageHeaderComponent breadcrumb={breadcrumb} />
				</div>
                <div style={{margin: '10px 20px 0px 20px'}}>
                    <div className="incentive-type-container">
                        <Tabs defaultActiveKey="1" onChange={onChange} >
                            <TabPane tab="Silver / Gold Vahan Leaders" key="1">
                            </TabPane>
                            <TabPane tab="Bronze Vahan Leaders" key="2">
                            </TabPane>
                        </Tabs>
                    </div>
                    <div className="filters">
                        <div className='period'>
                            <strong>Period: </strong> 
                            <Dropdown overlay={downloadMenu} >
                                <Button>
                                    <Space>
                                        {monthSelected}
                                    <DownOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                        </div>
                        <div>
                            
                        </div>
                        <div className='incentive-type'><strong>Incentive Type: </strong> {incentiveType}</div>
                    </div>
                </div>
                {
                    loading ? 
                        <Skeleton active style={{padding: '20px'}} />
                    :
                        list.length > 0 ? 
                            <div className='incentive-list'>
                                {
                                    list.map((item, clientIndex) => {
                                        return (
                                            <div className="incentive-cards-container" key={clientIndex}>
                                                <div className='incentive-card'>
                                                    <div className='incentive-card-header'>
                                                        <img src={item.clientLogo} style={{width:'90px',height:'40px', textAlign: 'left'}} alt={item.clientName}/>
                                                    </div>
                                                    <div className='locations-list-container'>
                                                        {
                                                            item.locationList.length > 0 && item.locationList.map((location, locationIndex) => {
                                                                return (
                                                                    <div className='single-location-container' key={locationIndex}>
                                                                        <div className='location-list'>
                                                                            <div className='location-container-left'>
                                                                                <div className='location-name'>
                                                                                    <strong>Location : </strong>
                                                                                    {location.location}
                                                                                </div>
                                                                                <div className='location-sub-heading'>{location.subHeading}</div>
                                                                            </div>
                                                                            <div className='location-container-right' onClick={() => onClickExpand(clientIndex, locationIndex)}>
                                                                                <div className='location-earn-upto'>{location.earnUpto}</div>
                                                                                <CaretDownOutlined style={{marginTop: '5px'}}/>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            location.isExpanded && (
                                                                                <div className='milestone-data'>
                                                                                    {
                                                                                        location.incentivesList.length > 0 && location.incentivesList.map((milestone, index) => {
                                                                                            return (
                                                                                                <div className='milestone-container' key={index}>
                                                                                                    <div className='milestone-heading'>
                                                                                                        <strong> {milestone.heading} </strong>
                                                                                                        {
                                                                                                            milestone.tooltip && (
                                                                                                                <Tooltip title={milestone.tooltip}>
                                                                                                                    <InfoCircleOutlined style={{marginLeft: '3px', verticalAlign: '-0em'}} />
                                                                                                                </Tooltip>
                                                                                                            )
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className='milestone-sub-heading'>
                                                                                                        {
                                                                                                            milestone.heading.includes('Bonus') ? 'You earn extra ' : 'You earn '
                                                                                                        }
                                                                                                        <strong style={{marginLeft: '5px'}}>{ milestone.subHeading}</strong>
                                                                                                    </div>
                                                                                                    {
                                                                                                        milestone.termsAndConditions && (
                                                                                                            <div className='milestone-terms-and-conditions'>
                                                                                                                    <a onClick={() => {onClickTnC(milestone.termsAndConditionsId)}}
                                                                                                                        style={{color: '#d34512', cursor: 'pointer'}}
                                                                                                                    >
                                                                                                                        {milestone.termsAndConditions}
                                                                                                                    </a>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>    
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        :
                            <Empty style={{padding: '80px 0px'}}/>
                }
                {
                    tncId && (
                        <div className='incentive-footer' onClick={() => {onClickTnC(tncId)}}>
                            <div className='incentive-footer-box'>
                                <div style={{color: 'red'}}>*</div>
                                <div>Terms and Conditions : These are the terms and conditions.</div>
                            </div>
                        </div>
                    )
                }
                    
                
            </div>
            <Modal
                title={<Text style={{ display: 'flex', alignItems: 'center' }} strong>Authentication</Text>}
                visible={visibleAuth && !localStorage.getItem("payoutAccessToken")}
                onOk={() => {
                }}
                onCancel={() => {
                    history.goBack();
                }}
                footer={getButtons()}
            >
                {
                    mitraReducer?.mitraInfo?.password ?
                        <Input.Password
                            placeholder="Input password to access Incentives"
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            onChange={(e) => {
                                setAccessPasswordUsr(e.target.value)
                            }}
                            defaultValue={accessPasswordUsr}
                        /> :
                        <Button
                            onClick={onResetPasswordClick}
                            type="secondary">
                            <Text > Create New Password</Text>
                        </Button>
                }
            </Modal>
        </>
    );
};
export default Incentives;
