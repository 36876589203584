import * as React from "react";
const DoubleChevronUp = (props) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.5 17L12.5 13L16.5 17M8.5 11L12.5 7L16.5 11"
      stroke="#3A3D3E"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DoubleChevronUp;
