const eventGroups = {
    EVENT_GROUP_HOME: 'home',
    EVENT_GROUP_LOGIN: 'logIn',
    EVENT_GROUP_PROFILE: 'profile',
    EVENT_GROUP_ADD_LEAD: 'addLead',
    EVENT_GROUP_VIEW_LEAD: 'viewLead',
    EVENT_GROUP_JOB: 'job'
};

const eventScreens = {
    EVENT_SCREEN_LOGIN: 'logIn',
    EVENT_SCREEN_SEARCH: 'search',
    EVENT_SCREEN_BOT_NAV: 'botNav',
    EVENT_SCREEN_HOME_TAB: 'homeTab',
    EVENT_SCREEN_ADD_LEAD: 'addLead',
    EVENT_SCREEN_CANDIDATE: 'candidates',
    EVENT_SCREEN_DOCUMENT_PAGE: 'documentPage',
    EVENT_SCREEN_ADD_JOB_BASIC: 'addJobBasic',
    EVENT_SCREEN_ADD_JOB_DETAIL: 'addJobDetail'
};

const EmailOTPPhoneNumbers = [
    "9884271140",
    "9057448545",
    "8860286827",
    "9643896865",
    '9004217866',
    '9066666605',
    '8860723852',
    '7982252115',
    '8291681568',
    '9405042812',
    '7065589723'
]

export default { ...eventGroups, ...eventScreens , EmailOTPPhoneNumbers};