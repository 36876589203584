import * as React from "react";

const InfoIcon = ({ fillColor = 'none', height = 29, width = 29, strokeColor = '#9D9D9D' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 29 29"
    fill={fillColor}
    preserveAspectRatio="xMidYMid meet"
  >
    <path
      d="M14.5 14.3711V19.8489M14.5 25.3267C9.09454 25.3267 4.71252 20.9122 4.71252 15.4667C4.71252 10.0212 9.09454 5.60669 14.5 5.60669C19.9055 5.60669 24.2875 10.0212 24.2875 15.4667C24.2875 20.9122 19.9055 25.3267 14.5 25.3267ZM14.5542 11.0845V11.194L14.4459 11.194V11.0845H14.5542Z"
      stroke={strokeColor}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default InfoIcon;
