import React, { useEffect, useState } from 'react'
import PageHeaderComponent from '../common/PageHeader';
import { Button, Divider, Dropdown, Menu, Pagination, Space, Empty } from 'antd';
import {PieChartOutlined, DownOutlined, ArrowUpOutlined, ArrowDownOutlined} from '@ant-design/icons';
import PerfDashSingleMemberCard from './PerfDashSingleMemberCard';
import commonFunction from '../../utils/commonFunction';
import { useHistory, useParams, useLocation } from "react-router-dom";
import { getPerformanceDashboardTabDetails, getMitraMilestoneCount } from '../../service/reportService';
import SetTargetPopup from './SetTargetPopup';
import NewLoader from '../common/NewLoader/NewLoader';
import { getLast6MonthsData } from './../../utils/commonFunction'
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';

function PerformanceDashboardTeamLeaderboardDetails() {
    const location = useLocation();
    const { teamDetails } = location.state || {};
    const [showTargetPopup, setShowTargetPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [noDataFound, setNoDataFound] = useState(false);
    const [selectedSort, setSelectedSort] = useState('targetAchieved');
    const [teamLevelDetails, setTeamLevelDetails] = useState();
    const [teamList, setTeamList] = useState([]);
    const [memberExpandedDetails, setMemberExpandedDetails] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const [slicedData, setSlicedData] = useState();
    const trackMoengageEvent = (event,obj) => {
		trackEvent(event, obj || {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
	}

    const handlePageChange = (page) => {
        trackMoengageEvent('pagination-from-team-details-section');
        setCurrentPage(page);
    };

    useEffect(() => {
        setSlicedData(teamList?.slice(startIndex, startIndex + itemsPerPage));
    }, [teamList, startIndex]);

    useEffect(() => {
    const months = getLast6MonthsData();
    if(months) {
        setSelectedMonth(months[5].label)
        setStartDate(months[5].startDate)
        setEndDate(months[5].endDate)
        setMonthSelectionOptions(months);
    }
    }, []);

    const getMemberMonthwiseDetails = (member) => {
        const payload = {
            mitraId: member?.id
        };

        getMitraMilestoneCount(payload)
        .then(res=> {
            if(res?.data?.status) {
                setMemberExpandedDetails(res?.data?.data);
            }
        }).catch(err=> {
            console.log(err);
        })
    }

    const [breadcrumb, setBreadcrumb] = useState([
        { link: `/report/performance-dashboard`, label: 'Report' }, 
        { link: `/report/performance-dashboard`, label: `Performance` },
        { link: `/report/performance-dashboard`, label: `Team Leaderboard` },
        { link: `/report/performance-dashboard`, label: teamDetails?.team_name },
    ]);

    const [teamSelectionOptions, setTeamSelectionOptions] = useState([
        {
            key: 'targetAchieved',
            label: 'Target Achieved',
            type: 'team'
        },
        {
            key: 'role desc',
            label: 'Role Desc',
            type: 'team'
        },
        {
            key: 'role asc',
            label: 'Role Asc',
            type: 'team'
        }
    ]);

    const [monthSelectionOptions, setMonthSelectionOptions] = useState([]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [selectedTeam, setSelectedTeam] = useState('Target Achieved');
    const [selectedMonth, setSelectedMonth] = useState();
    const onExpand = (id) => {
        trackMoengageEvent('expand-option-clicked-team-leaderboard', {user: id});
        setMemberExpandedDetails();
        const newData = [...teamList];
        for (let i = 0; i < newData.length; i++) {
            if(newData[i].id === id) {
                if(newData[i].isExpanded) {
                    newData[i].isExpanded = false;
                } else {
                    newData[i].isExpanded = true;
                    getMemberMonthwiseDetails(newData[i]);
                }
            } else {
                newData[i].isExpanded = false;
            }
        }
        setTeamList([...newData]);
    }

    const getTeamLeaderboardDetails = (startDate, endDate, selectedSort) => {
        setLoading(true);
        setNoDataFound(false);
        const payload = {
            tab: 'teamLeader',
            team: teamDetails?.team_name,
            startDate: startDate,
            endDate: endDate,
            sortBy: selectedSort
        }

        getPerformanceDashboardTabDetails(payload)
        .then(res=> {
            if(res?.data?.status) {
                setLoading(false);
                if(res?.data?.data) {
                    setTeamList(res?.data?.data[0].teamList);
                } else {
                    setNoDataFound(true);
                }
            }
        }).catch(err=> {
            console.log(err);
        })
    }

    const onDropdownChange = (e, type) => {
        if(type === 'month') {
            trackMoengageEvent('month-filter-changed-team-details-section', {month: e?.label});
            setStartDate(e.startDate);
            setEndDate(e.endDate);
            setSelectedMonth(e.label);
            getTeamLeaderboardDetails(e.startDate, e.endDate, selectedSort);
        } else if(type === 'sortBy') {
            trackMoengageEvent('sort-by-filter-changed-team-details-section', {sortBy: e?.label});
            setSelectedSort(e.key);
            if(e.label === 'Role Desc') {
                setSelectedTeam(
                    <div style={{gap: '4px'}} className='d-flex align-items-center'>
                        <ArrowDownOutlined /> Roles
                    </div>
                );
                if(slicedData?.length > 1) {
                    slicedData.sort((a, b) => b.rank - a.rank);
                }
            } else if(e.label === 'Role Asc') {
                setSelectedTeam(
                    <div style={{gap: '4px'}} className='d-flex align-items-center'>
                        <ArrowUpOutlined /> Roles
                    </div>
                );
                if(slicedData?.length > 1) {
                    slicedData.sort((a, b) => a.rank - b.rank);
                }
            } else {
                if(slicedData?.teamList?.length) {
                    setSlicedData(slicedData?.teamList)
                }
                setSelectedTeam(e.label);
                getTeamLeaderboardDetails(startDate, endDate, e.key);
            }
        }
    }

    const monthMenu = (
        <Menu>
            {
                monthSelectionOptions &&
                monthSelectionOptions.map((month, idx) => {
                    return (
                        <>
                        <Menu.Item  onClick={()=>onDropdownChange(month,'month')} key={month} style={{ padding: '10px 20px' }}>{month.label}</Menu.Item>
                        {
                            monthSelectionOptions &&
                            monthSelectionOptions.length - 1 === idx ? null: <Divider style={{margin: '0px'}}/>
                        }
                        </>
                    )
                })
            }
        </Menu>
    );

    const teamMenu = (
        <Menu>
            {
                teamSelectionOptions && teamSelectionOptions.map((team, idx) => {
                    return (
                        <>
                        <Menu.Item onClick={()=>onDropdownChange(team,'sortBy')} key={team.key} style={{ padding: '10px 20px' }}>
                            {team.label === 'Role Desc' ?
                                <div style={{gap: '4px'}} className='d-flex align-items-center'>
                                    <ArrowDownOutlined /> Roles
                                </div>
                            : team.label === 'Role Asc' ?
                                <div style={{gap: '4px'}} className='d-flex align-items-center'>
                                    <ArrowUpOutlined /> Roles
                                </div>
                            : team?.label
                            }
                            
                            </Menu.Item>
                        {
                            teamSelectionOptions &&
                            teamSelectionOptions.length - 1 === idx ? null: <Divider style={{margin: '0px'}}/>
                        }
                        </>
                    )
                })
            }
        </Menu>
    );

    useEffect(() => {
      if (teamDetails) {
        setTeamLevelDetails(teamDetails);

        if(teamDetails?.teamList?.length) {
            setTeamList([...teamDetails?.teamList]);
        } else {
            setTeamList([]);
        }
      }
    }, [teamDetails])
    
    const showSetTargetModal = () => {
        trackMoengageEvent('set-target-clicked-from-team-leaderboard-details');
        setShowTargetPopup(true);
    }

  return (
    <div className="ReportPage mainContentZ">
        
        <SetTargetPopup 
            show={showTargetPopup}
            setShow={setShowTargetPopup}
            selectedMonth={selectedMonth}
            monthlyTargetFTs={teamDetails?.target}
            teamList={teamList}
            startDate={startDate}
            endDate={endDate}
        />
        <div className="header">
            <>
                <PageHeaderComponent dropDownObj={{
                    dropDownArr: [
                        {
                            value: 'performanceDashboard',
                            route: '/report/performance-dashboard',
                            label: 'Performance',
                            disabled: false

                        },
                    ],
                    defaultValue: 'Team Leaderboard'
                }} breadcrumb={breadcrumb} />
            </>
        </div>

        <div className="content-area" style={{flex: 1, background: '#f5f5f5'}}>
            <div className="team-header d-flex justify-content-between align-items-center" style={{padding: '12px 24px'}}>
                <span className='team-name'>{teamDetails?.team_name}</span>
                <Button 
                onClick={()=>showSetTargetModal()}
                style={{alignItems: 'center', display: 'flex', width: '240px',border: '1px solid #D34612',color: '#D34612', justifyContent: 'center', fontWeight:'700', fontSize: '14px', lineHeight: '22px', background: 'rgb(245, 245, 245)'}} icon={<PieChartOutlined />}>Set Targets</Button>
            </div>

            <div className='misc-details'>
                <span className='tl-label'>TL <span className='tl-name'>{teamDetails?.teamLeader}</span></span>
                <span className='tc-label'>TCs <span className='tc-count'>{teamDetails?.telecallerCount}</span></span>
            </div>

            <div className='d-flex justify-content-between flex-wrap' style={{padding: '24px', gap: '12px'}}>
                    <div className='member-leaderboard-input-section'>
                        <span>Show performance for</span>
                        <Dropdown
                            trigger={['click']}
                            overlay={monthMenu}
                        >
                            <div style={{cursor: 'pointer'}}>
                                <Space style={{color: '#D34612', fontWeight: '700'}}>
                                    {selectedMonth}
                                    <DownOutlined className='d-flex'/>
                                </Space>
                            </div>
                            
                        </Dropdown>
                        <span>sorted by</span>
                        <Dropdown
                            trigger={['click']}
                            overlay={teamMenu}
                        >
                            <div style={{cursor: 'pointer'}}>
                                <Space style={{color: '#D34612', fontWeight: '700'}}>
                                    {selectedTeam}
                                    <DownOutlined className='d-flex'/>
                                </Space>
                            </div>
                            
                        </Dropdown>
                    </div>
                <span style={{color: '#D34612', fontWeight: 400, fontSize: '20px'}}>{teamDetails?.FTs} / {teamDetails?.target} <span style={{color: '#000000A6'}}>FTs</span></span>
            </div>

            <div className='milestone-container'>
                {
                    teamDetails?.milestones?.map((milestone, index) => {
                        return (
                            <div key={`team-details-milestone`+index} className='individual-milestone'>
                                <div className='dot-indicator' style={{backgroundColor: milestone?.color}}></div>
                                <span className='milestone-name'>{milestone?.label}</span>
                                <span className='milestone-count'>{milestone?.value}</span>
                            </div>
                        )
                    })
                }
                
            </div>

            <div className='member-leaderboard-card-container' style={{marginTop: '32px'}}>
                    {
                        slicedData && !loading &&
                        slicedData?.map((item, idx)=> (
                            <PerfDashSingleMemberCard item={item} startDate={startDate} endDate={endDate} key={item?.id} idx={item?.id} onExpand={onExpand} memberExpandedDetails={memberExpandedDetails}/>
                        ))
                    }
                    {
                        loading &&
                        <div style={{ height: "65vh", width: "100%" }}>
                            <NewLoader comingFromPage={'performancePage'} />
                        </div>
                    }
                    {
                        noDataFound &&
                        <Empty />
                    }
                    {
                        teamList?.length &&
                        <Pagination
                            style={{textAlign: 'left', padding: '24px', paddingTop: '10px'}}
                            current={currentPage}
                            total={teamList.length}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                            pageSize={itemsPerPage}
                            onChange={handlePageChange}
                            responsive={true}
                            showLessItems={true}
                        />
                    }
                </div>
        </div>
    </div>
  )
}

export default PerformanceDashboardTeamLeaderboardDetails