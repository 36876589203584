import axios from 'axios';
import moment from 'moment';
import axiosInstance from './interceptorService';

export const getActiveClientsForFilter = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getCommonClientListForFilter`;
    return axiosInstance.get(url, { withCredentials: true });
};

export const getMitraTeamMembersList = async (includeManager = false) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getMitraByManagerId`;
    if (includeManager) {
        url += `?includeManager=true`
    }
    return axiosInstance.get(url, { withCredentials: true });
};

export function disabledDate(current) {
    // Can not select days after today and today
    return current && current > moment().endOf('day');
}
export function disabledDateLessThan25daysForHotLeads(current) {
    // Can not select days after today and today
    return current && current > moment().endOf('day') || current < moment().add(-25, 'days');
}

export function disabledDateForBulkFeature(current, availableDateList) {
    // Check if the current date is in the availableDateList
    // formating the dates on the list to format
    availableDateList = availableDateList.map(date => moment(date).format('YYYY-MM-DD'));
    // addding the current date to the list
    const isCurrentDateAvailable = availableDateList.includes(current.format('YYYY-MM-DD'));
    return current && !isCurrentDateAvailable;
}

export const enableLast30DaysForSelection = (current) => {
    // Disable dates after today and before 30 days from today
    return current && (current > moment().endOf('day') || current < moment().subtract(30, 'days').startOf('day'));
  };

export function disabledDateForPerformanceDashboard(current, mitraJoiningDate) {
    // Can not select days after today and today
    if (current && current > moment().endOf('day').valueOf()) {
        return true;
    }
    // Can not select days before joining date
    else if (current && current < moment(mitraJoiningDate).startOf('day').valueOf()) {
        return true;
    }
    else {
        return false;
    }
}

export function disabledDateForRecuiterDashboard(current) {
    // Can  select in 30 days
    return current && (current > moment().endOf('day') || current <= moment().subtract(31, 'days'));
}

export const getStatusListForFilters = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getCommonStatusListForFilters`;
    return axiosInstance.get(url, { withCredentials: true });
};
export const getLocationForFilter = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getCommonLocationListForFilter`;
    return axiosInstance.get(url, { withCredentials: true, params: data });
};

export const getMilestoneForFilter = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/commonMilestoneListForFilter`;
    return axiosInstance.get(url, { withCredentials: true });
};

export const getCandidatesAndLeads = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getCandidatesAndLeads`;
    return axiosInstance.get(url, { params: data, withCredentials: true });
};
export const postReferAgain = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/referAgain`;
    return axiosInstance.post(url, data, { withCredentials: true });
};

export const getMitraPhoneNumberFromId = async (mitraId) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getMitraPhoneNumberFromId`;
    return axiosInstance.get(url, { params: { mitraId }, withCredentials: true });
};

export const loginForPayoutDashboard = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/loginForPayoutDashboard`;
    return axiosInstance.post(url, data, { withCredentials: true, headers: { 'Access-Control-Expose-Headers': 'custom-header' } });
};

export const sendPayoutDashboardPasswordResetUrl = async (isNewPassword) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/sendPayoutDashboardPasswordResetUrl`;
    const formData = new FormData();
    formData.append('isNewPassword', isNewPassword);
    return axiosInstance.post(url, formData, { withCredentials: true, headers: {} });
};
export const updatePayoutDashboardPassword = ({ key, password }) => {
    const URL = process.env.REACT_APP_BACKEND_URL + `/updatePayoutDashboardPassword`;
    const formData = new FormData();
    formData.append('key', key);
    formData.append('password', password);
    return axiosInstance.post(URL, formData, { withCredentials: true });
};

export function disabledDateForPayoutDash(current) {
    let dissableMonth = false;
    if ((Number(current.year()) < 2022) || (Number(current.year()) === 2022 && Number(current.month()) < 11) || (current > moment().endOf('day'))) {
        dissableMonth = true
    }
    else if (current.month() === moment().month() && current.date() < 10) {
        dissableMonth = true
    }
    return dissableMonth
}

export const expiredLeadsDashboardInfo = (monthYear) => {
    const URL = process.env.REACT_APP_BACKEND_URL + `/reports/payout/ExcludedLeadsDash?monthYear=${monthYear}`;
    return axiosInstance.get(URL, { withCredentials: true });
}

export const fetchActivatedAndReferredLeads = () => {
    const URL = process.env.REACT_APP_BACKEND_URL + `/fetchReferredAndActivatedLeads`;
    return axiosInstance.get(URL, { withCredentials: true });
}

export const updateLeadsBasedEvents = (eventName, eventValue) => {
    const payload = new FormData();
    payload.append('eventName', eventName);
    payload.append('eventValue', eventValue);

    const URL = process.env.REACT_APP_BACKEND_URL + `/updateLeadsBasedEvents`;
    return axiosInstance.post(URL, payload, { withCredentials: true })
}

export const getRapidoDocumentStatuses = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/rapido/fetch-document-status`;
    return axiosInstance.post(url, data, { withCredentials: true });
};

export const getCompanyLogo = async (data) => {
    const requestData = {
        companies: data
    }

    const url = `${process.env.REACT_APP_BACKEND_URL}/images/companyLogos/`
    return axiosInstance.post(url, requestData, { withCredentials: true });
}

export const additionalMitraInfo = async () => {
    return axiosInstance.get(process.env.REACT_APP_BACKEND_URL + '/additionalMitraInfo', { withCredentials: true });
}

export const checkBronzeML = async (mitraInfo) => {
    const payload = mitraInfo;
    return axiosInstance.post(process.env.REACT_APP_BACKEND_URL + '/checkMitraLeaderBronze', payload, { withCredentials: true });
}

export const getZomatoDocumentStatus = async (scheduledInterviewId) => {
    return axiosInstance.get(process.env.REACT_APP_BACKEND_URL + `/getQCStatusForZomatoDocuments?scheduledInterviewId=${scheduledInterviewId}`, { withCredentials: true });
}

export const samvaadiniCallingConsent = async (phoneNumber) => {
    const payload = phoneNumber;
    return axiosInstance.post(process.env.REACT_APP_BACKEND_URL + '/user/consent/opt', payload, { withCredentials: true, headers: {
        'x-api-key': 'PkbTkj61xbOca8kAGBIeL42puik7dm1fh8afg6547eldb2q123'
    }
});
}

export const getHotlineAppUrl = async () => {
    const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`  };
    let url = `${process.env.REACT_APP_STRAPI_URL}/auto-dialer-updates`;
    return axiosInstance.get(url, { headers });
};
