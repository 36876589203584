import React, { useEffect, useState } from 'react'
import '../styles/VerificationStep.css'
import { Select } from 'antd'
import ManualDocumentVerification from './ManualDocumentVerification';
import DigilockerVerification from './DigilockerVerification';
import { getDocVerificationData } from '../../../../service/additionalInfoService';
import { CheckCircleOutlined } from '@ant-design/icons';
import RiderCompletedBanner from './RiderCompletedBanner';

function VerificationStep({goToNextScreen, lead, getLeadInformation, leadAdditionalDetails, showBannerForScreen}) {
    const [selectedVerificationType, setSelectedVerificationType] = useState();
    const [docVerificationData, setDocVerificationData] = useState();
    const [showStepCompletedSection, setShowStepCompletedSection] = useState(false);
    const [verificationTypes, setVerificationTypes] = useState([
        {
            label: 'Document Upload',
            value: 'Document Upload'
        },
        {
            label: 'DigiLocker Verification',
            value: 'DigiLocker Verification'
        },
    ]);
    const [riderHasCompletedTheStep, setRiderHasCompletedTheStep] = useState(false);


    const handleChange = (item) => {
        console.log(item);
        setSelectedVerificationType(item);
    };

    const getDocVerificationInfo = async() => {
        const docVerificationData = await getDocVerificationData({phoneNumber: lead.phoneNumber});

        setDocVerificationData(docVerificationData.data);
    }

    useEffect(() => {
        if(lead) {
            getDocVerificationInfo();
            setRiderHasCompletedTheStep(showBannerForScreen('verification'));
        }
        
    }, [lead])

    useEffect(() => {
        // useeffect for autofill
        if(leadAdditionalDetails?.zomatoVerificationType?.length) {
            setSelectedVerificationType(leadAdditionalDetails.zomatoVerificationType);
            setShowStepCompletedSection(true);
        }
        
    }, [leadAdditionalDetails])
    
  return (
    <>

        {
            showStepCompletedSection && !riderHasCompletedTheStep ? (
                <div style={{display: 'flex', alignItems: 'center', gap: '6px', marginTop: '16px', fontWeight: 700}}>
                    <CheckCircleOutlined style={{color: '#4BB543'}}/>
                    Details successfully submitted to Zomato.
                </div>
            ): null
        }

        {
            riderHasCompletedTheStep ? <RiderCompletedBanner />: null
        }

        <div className='verification-type-container'>
            <div className='label'>Select verification type<sup>*</sup></div>
            <Select
                style={{ width: '100%', textAlign: 'start', marginTop: '8px' }}
                placeholder="Select verification type"
                value={selectedVerificationType}
                onChange={handleChange}
                optionLabelProp="label"
                options={verificationTypes}
            />
        </div>

        {
            selectedVerificationType === 'Document Upload' ? (
                <ManualDocumentVerification 
                goToNextScreen={goToNextScreen} 
                lead={lead} 
                docVerificationData={docVerificationData}
                selectedVerificationType={selectedVerificationType}
                leadAdditionalDetails={leadAdditionalDetails}
                getLeadInformation={getLeadInformation}
                riderHasCompletedTheStep={riderHasCompletedTheStep}
                setRiderHasCompletedTheStep={setRiderHasCompletedTheStep}
                />
            ): null
        }

        {
            selectedVerificationType === 'DigiLocker Verification' ? (
                <DigilockerVerification 
                docVerificationData={docVerificationData}
                lead={lead}
                goToNextScreen={goToNextScreen}
                selectedVerificationType={selectedVerificationType}
                getLeadInformation={getLeadInformation}
                riderHasCompletedTheStep={riderHasCompletedTheStep}
                setRiderHasCompletedTheStep={setRiderHasCompletedTheStep}
                />
            ): null
        }
    
    </>
  )
}

export default VerificationStep
