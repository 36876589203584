import React from "react";

function SearchIcon({fillColor='none',height=17,width=17,strokeColor="#999"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fillColor}
      viewBox="0 0 17 17"
    >
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8.28 14.218a6.333 6.333 0 100-12.666 6.333 6.333 0 000 12.666zM15.28 14.885l-1.333-1.333"
      ></path>
    </svg>
  );
}

export default SearchIcon;