import React, { useContext, useEffect, useState } from 'react'
import '../styles/VehicleTypeSelection.css';
import { Button, Input, Select, Upload, notification } from 'antd';
import { UploadOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { getZomatoVehicleData, postAdditionalInfo, updateZomatoData, uploadImageToS3 } from '../../../../service/additionalInfoService';
import { AppContext } from '../../../../App';
import RiderCompletedBanner from './RiderCompletedBanner';

function VehicleTypeSelection({goToNextScreen,lead, getLeadInformation, leadAdditionalDetails, showBannerForScreen}) {
    const {mitraReducer,mitraDispatch, setAlert,setSpin } = useContext(AppContext);
    const [selectedVehicleType, setSelectedVehicleType] = useState();
    const [selectedEVType, setSelectedEVType] = useState();
    const [selectedEVOwnershipType, setSelectedEVOwnershipType] = useState();
    const [showEvDetailsSection, setShowEvDetailsSection] = useState(false);
    const [evProof, setEvProof] = useState();
    const [errors, setErrors] = useState();
    const [showStepCompletedSection, setShowStepCompletedSection] = useState(false);
    const [riderHasCompletedTheStep, setRiderHasCompletedTheStep] = useState(false);

    const [vehicleTypeInfo, setVehicleTypeInfo] = useState([
        {
            label: 'Motorcycle',
            value: 'Motorcycle'
        },
        {
            label: 'Electric scooter',
            value: 'Electric scooter'
        }
    ]);

    const [evTypeOptions, setevTypeOptions] = useState([
        {
            label: 'With number plate',
            value: 'With number plate'
        },
        {
            label: 'Without number plate',
            value: 'Without number plate'
        }
    ]);

    const [evOwnershipTypeOptions, setevOwnershipTypeOptions] = useState([
        {
            label: 'Owned',
            value: 'Owned'
        },
        {
            label: 'Rented',
            value: 'Rented'
        }
    ]);

    const handleChange = (item) => {
        console.log(item);
        if(item === 'Electric scooter') {
            setShowEvDetailsSection(true);
        } else {
            setShowEvDetailsSection(false);
        }
        setSelectedVehicleType(item);
        setRiderHasCompletedTheStep(false);
    };

    const handleEVTypeChange = (item) => {
        setSelectedEVType(item);
        setRiderHasCompletedTheStep(false);
    };

    const handleEVOwnershipTypeChange = (item) => {
        setSelectedEVOwnershipType(item);
        setRiderHasCompletedTheStep(false);
    };

    const showLoader = (value) => {
        setSpin({
            loading: value, //false or true when actived
            delay: null,
            tip: 'Uploading...'  //Loading copy here
        })
    }

    const checkErrors = (res) => {
        const newErrors = [];
        for (let key in res.data) {
            if (res.data[key] !== "200") {
                newErrors.push(`${key}: ${res.data[key]}`)
            }
        }
      
        if(newErrors.length) {
            // setErrors([...newErrors]);
            alert(newErrors.join("\n"))
            return true;
        } 
        return false;
      }

    const handleUpload =(info) => {
        showLoader(true);

        uploadImageToS3(info.file)
            .then(async(res)=> {
                showLoader(false);

                if(res?.data?.imageUrl) {
                    setEvProof(res.data.imageUrl);

                    const submissionData = {
                        evProof: {
                            url: res.data.imageUrl,
                            originalName: info?.file?.name,
                            size: info?.file?.size
                        }
                    }
        
                    const response = await postAdditionalInfo(submissionData, mitraReducer?.mitraInfo, lead);

                    checkErrors(response);
                    setRiderHasCompletedTheStep(false);
            
                }
            }).catch(e=> {
                showLoader(false);

                notification['error']({
                    message: 'Image upload failed, please try again later.',
                  });

                console.log(e);
            });
    };

    const submissionForPetrolBike = async() => {
        let submissionData = {};

        try{
            submissionData = {
                zomatoVehicleType: selectedVehicleType
            }

            const res = await postAdditionalInfo(submissionData, mitraReducer?.mitraInfo, lead);
            
            if(!checkErrors(res)) {
                getLeadInformation();
            } else {
                return;
            }

            const response = await updateZomatoData({
                phoneNumber: lead.phoneNumber,
                dataToUpdate: {
                    updateVehicleType: {
                        vehicleType: selectedVehicleType
                    }
                }
            });

            if(response?.data?.updateVehicleType?.status?.code === 200) {
                goToNextScreen();
            } else {
                notification['error']({
                    message: response?.data?.updateVehicleType?.message || 'Something went wrong, please try again in some time.',
                  });
            }

        } catch(e) {
            // do something
        }

    };

    const submissionForEbike = async() => {
        let dataToSubmit = {};

        if (!selectedEVType || !selectedEVOwnershipType || !evProof ) {
            notification['error']({
                message: 'Please enter all the fields to continue',
              });
            return
        } else {

            dataToSubmit = {
                zomatoVehicleType: selectedVehicleType,
                zomatoEvType: selectedEVType,
                zomatoEvOwnershipType: selectedEVOwnershipType,
            }
            const res = await postAdditionalInfo(dataToSubmit, mitraReducer?.mitraInfo, lead);

            if(!checkErrors(res)) {
                getLeadInformation();
            }

            const response = await updateZomatoData({
                phoneNumber: lead.phoneNumber,
                dataToUpdate: {
                    updateVehicleType: {
                        vehicleType: selectedVehicleType
                    },
                    updateEvDetails: {
                        ownershipType: selectedEVOwnershipType,
                        vehicleType: selectedEVType,
                        imageUrl: evProof
                    }
                }
            });

            if(response?.data?.updateVehicleType?.status?.code === 200) {
                goToNextScreen();
            } else {
                notification['error']({
                    message: response?.data?.updateVehicleType?.message || 'Something went wrong, please try again in some time.',
                  });
            }

        }
    }

    const submitVehicleTypeInfo = async() => {
        if(!selectedVehicleType) {
            notification['error']({
                message: 'Please enter all the fields to continue',
              });
            return
        }

        if(riderHasCompletedTheStep) {
            // for not changing the source of submitted details, not calling api again if data is unchanged
            goToNextScreen();
        } else{
            if(selectedVehicleType && selectedVehicleType === 'Motorcycle') {
                submissionForPetrolBike();
            } else {
                submissionForEbike();
            }
        }
    }

    const handleBeforeUpload = (file) => {
        // Return false to prevent upload
        return false;
      };

    const fetchVehicleDataFromZomato = async() => {
        const zomatoVehicleData = await getZomatoVehicleData({phoneNumber: lead.phoneNumber});

        if(zomatoVehicleData?.data?.vehicleTypeData?.value === 'Motorcycle') {
            setSelectedVehicleType('Motorcycle');
            setShowStepCompletedSection(true);
        } else if (zomatoVehicleData?.data?.vehicleTypeData?.value === 'Electric scooter') {
            // handle the Electric scooter part
            setSelectedVehicleType('Electric scooter');
            setShowEvDetailsSection(true);
            setShowStepCompletedSection(true);

            if(zomatoVehicleData?.data?.evDetails?.vehicleType?.length) {
                setSelectedEVType(zomatoVehicleData?.data?.evDetails?.vehicleType);
            }

            if(zomatoVehicleData?.data?.evDetails?.ownershipType?.length) {
                setSelectedEVOwnershipType(zomatoVehicleData?.data?.evDetails?.ownershipType);
            }

            if(leadAdditionalDetails?.evProof?.[0]?.url?.length) {
                setEvProof(leadAdditionalDetails?.evProof?.[0]?.url);
            }
        } else {
            // if nothing is there in the zomato db then auto fill from our DB.
            if(leadAdditionalDetails?.zomatoVehicleType?.length) {
                setSelectedVehicleType(leadAdditionalDetails?.zomatoVehicleType);
                setShowStepCompletedSection(true);

                if(leadAdditionalDetails?.zomatoVehicleType === 'Electric scooter') {
                    setShowEvDetailsSection(true);

                    if(leadAdditionalDetails?.zomatoEvType?.length) {
                        setSelectedEVType(leadAdditionalDetails?.zomatoEvType);
                    }
        
                    if(leadAdditionalDetails?.zomatoEvOwnershipType?.length) {
                        setSelectedEVOwnershipType(leadAdditionalDetails?.zomatoEvOwnershipType);
                    }
        
                    if(leadAdditionalDetails?.evProof?.[0]?.url?.length) {
                        setEvProof(leadAdditionalDetails?.evProof?.[0]?.url);
                    }
                } else {
                    setShowEvDetailsSection(false);
                }
            }
        }
    }

    useEffect(() => {
      // fetching data from zomato to autofill if this step was already completed
      if(lead) {
        fetchVehicleDataFromZomato();
        setRiderHasCompletedTheStep(showBannerForScreen('vehicleType'));
      }
      
    }, [lead, leadAdditionalDetails]) // since the api call is dependent on phone number and certain autofill condiiton is dependent on our DB data
    


  return (
    <>
        {
            showStepCompletedSection && !riderHasCompletedTheStep ? (
                <div style={{display: 'flex', alignItems: 'center', gap: '6px', marginTop: '16px', fontWeight: 700}}>
                    <CheckCircleOutlined style={{color: '#4BB543'}}/>
                    Vehicle details successfully submitted to Zomato.
                </div>
            ): null
        }

        {
            riderHasCompletedTheStep ? <RiderCompletedBanner />: null
        }

        <div className='vehicle-type-container'>

            <div className='container-header'>Vehicle Details</div>

            <div className="vehicle-details-container">

                <div className='label'>Select vehicle type<sup>*</sup></div>
                <Select
                    style={{ width: '100%', textAlign: 'start', marginTop: '8px' }}
                    placeholder="Select vehicle type"
                    value={selectedVehicleType}
                    onChange={handleChange}
                    optionLabelProp="label"
                    options={vehicleTypeInfo}
                />

                {
                    showEvDetailsSection ? (
                        <div className='ebike-details-container mt-24'>
                            <div className='label'>Select EV Type<sup>*</sup></div>
                            <Select
                                style={{ width: '100%', textAlign: 'start', marginTop: '8px' }}
                                placeholder="Select vehicle type"
                                value={selectedEVType}
                                onChange={handleEVTypeChange}
                                optionLabelProp="label"
                                options={evTypeOptions}
                            />

                            <div className='label mt-24'>Select EV Ownership Type<sup>*</sup></div>
                            <Select
                                style={{ width: '100%', textAlign: 'start', marginTop: '8px' }}
                                placeholder="Select vehicle type"
                                value={selectedEVOwnershipType}
                                onChange={handleEVOwnershipTypeChange}
                                optionLabelProp="label"
                                options={evOwnershipTypeOptions}
                            />

                            <div className="aadhar-steps-header" style={{marginTop: 28}}>Note</div>
                            <ul>
                                <li style={{textAlign: 'start'}}>Please make sure to upload the correct EV Proof to avoid unwanted rejections</li>
                            </ul>

                            <div className='label mt-24'>EV Proof<sup>*</sup></div>
                            {
                                evProof ? (
                                    <div style={{display: 'flex', gap: '8px'}}>
                                        <img src={evProof} width={150} height={150}/>
                                        <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>setEvProof(null)}>x</p>
                                    </div>
                                ) : (
                                    <Upload
                                        name="avatar"
                                        listType="picture-card"
                                        showUploadList={false}
                                        accept="image/*"
                                        onChange={handleUpload}
                                        beforeUpload={handleBeforeUpload}
                                        >
                                        <div>
                                            <Input type="file" accept="image/*" style={{ display: 'none' }} />
                                            <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                                        </div>
                                    </Upload>
                                )
                            }
                            
                        </div>
                    ): null
                }
            </div>

        </div>

        <div className='button-container'>
            <Button className='submit-button' onClick={()=>submitVehicleTypeInfo()}>Next</Button>
        </div>
    
    </>
  )
}

export default VehicleTypeSelection
