import React, { useContext, useEffect,useState } from "react";
import './LevelDetails.scss';
import { Timeline,Typography, Collapse, Card, Space } from "antd";
import {LockFilled, UnlockFilled,CaretUpOutlined} from '@ant-design/icons';
import { AppContext } from "../../App";
import { getProgressBarTimelineInformation } from "../../service/progressBarService";

const {Text} = Typography;
const {Panel} = Collapse;

const ProgressBarDetails = () => {
    const {mitraReducer,setSpin} = useContext(AppContext);
    const [currentTimelineBadge, setCurrentTimelineBadge] = useState([]);
    const [timelineData, setTimelineData] = useState([]);
    const mitraLevelInformation = {
        bronzeMitra: {
            headerText: 'Your Journey from Bronze to Silver Vahan Leader',
            startImage: '/image/progress-bar-bronze.png',
            endImage: '/image/progress-bar-silver.png',
            startText: 'Bronze',
            endText: 'Silver'
        },
        initialStage:{
            headerText: 'Your Journey towards Bronze Vahan Leader',
            startImage: '/image/progress-bar-initial.png',
            endImage: '/image/progress-bar-bronze.png',
            startText: 'Start',
            endText: 'Bronze'
        },
    };

    useEffect(()=> {
        if (mitraReducer?.mitraInfo?.tier === 'initiated')
            setCurrentTimelineBadge(mitraLevelInformation.initialStage);
        else if (mitraReducer?.mitraInfo?.tier === 'bronze')
            setCurrentTimelineBadge(mitraLevelInformation.bronzeMitra);
        getProgressBarTimelineInformation().then(response => {
            setTimelineData([...response.data.timeline]);
        }).catch(error => {
            console.log(error);
        })
    }, []);
    return (
        <Card className='progress-bar'>
            <div className='progress-bar-header'>{currentTimelineBadge.headerText}</div>
            <div className="progress-bar-detail-content">
                <div className='d-flex justify-content-between align-items-center custom-width-mobile' style={{gap:'5px', width:'100%'}}>
                    
                    <div className="current-position-pointer">
                        <img src={currentTimelineBadge.startImage} alt='starting-point'></img>
                        <Text className="pointer-text" style={{color:'black'}}>{currentTimelineBadge.startText}</Text>
                    </div>

                    {
                        timelineData.map((item,index)=> {
                            return (
                                <div 
                                key={index} 
                                className={item.status==='Completed' ? 'level-indicators level-completed': 
                                item.status==='In Progress' ? 'level-indicators level-in-progress': 'level-indicators level-yet-to-begin'}
                                >
                                    {item.status==='In Progress' ? (
                                        <div className="current-position-pointer">
                                            <CaretUpOutlined style={{marginTop:'5px'}}/>
                                            <Text className="pointer-text">You</Text>
                                        </div>
                                    
                                    ): null }
                                </div>
                            )
                        })
                    }
                    
                    <div className="current-position-pointer">
                        <img src={currentTimelineBadge.endImage} alt='starting-point'></img>
                        <Text className="pointer-text" style={{color:'black'}}>{currentTimelineBadge.endText}</Text>
                    </div>
                </div>
                <Timeline style={{marginTop:'15px', gap:'2em'}} className="timeline-details">
                   {
                   timelineData.map((item,index)=> {
                        return (
                            <Timeline.Item 
                            key={index} 
                            dot={item.status!=='Not Completed' ? 
                            <UnlockFilled 
                            style={
                                item.status==='Completed' ? {color:'#52C41A'}: {color:'black'}
                            }/>: 
                            <LockFilled /> }
                            style={item.status==='Not Completed'? {opacity:'0.5'}: null}
                            >
                                <div style={{display:"flex", flexDirection:'column', gap:'4px'}}>
                                    <strong className='d-flex' style={{marginTop: '3px',color:item.status==='Completed'?'#52C41A':null}}>{item.label}</strong>
                                    
                                    {
                                        item.status === 'In Progress' ? (
                                            <Space direction="vertical" size={24} style={{background:'#FFEAE0', padding:'10px',borderRadius:'5px', maxWidth:'300px',whiteSpace:'normal'}}>
                                                <Text className='d-flex' style={{textAlign:'start'}}>
                                                <div
                                                    className="d-flex flex-column justify-content-between align-items-start"
                                                    dangerouslySetInnerHTML={{ __html: item.description }}
                                                ></div>
                                                    
                                                </Text>
                                            </Space>
                                        ): null
                                    }
                                    {
                                        item.status === 'Not Completed' ? (
                                            <Space direction="vertical" size={24} style={{background:'#BFBFBF', padding:'10px',borderRadius:'5px', maxWidth:'300px',whiteSpace:'normal'}}>
                                                <Text className='d-flex' style={{textAlign:'start'}}>
                                                <div
                                                    className="d-flex flex-column justify-content-between align-items-start"
                                                    style={{opacity:'0.5'}}
                                                    dangerouslySetInnerHTML={{ __html: item.description }}
                                                ></div>
                                                    
                                                </Text>
                                            </Space>
                                        ): null
                                    }
                                    {
                                        item.status === 'Completed' ? (
                                            <Space direction="vertical" size={24} style={{ padding:'10px',borderRadius:'5px', maxWidth:'300px',whiteSpace:'normal'}}>
                                                
                                                <Text className='d-flex' style={{textAlign:'start'}}>
                                                <div
                                                    className="d-flex flex-column justify-content-between align-items-start flex-wrap"
                                                    dangerouslySetInnerHTML={{ __html: item.rewards }}
                                                    
                                                >
                                                </div>
                                                    
                                                </Text>
                                            </Space>
                                        ): null
                                    }
                                </div>
                            </Timeline.Item>
                        )
                   })
                   }
                </Timeline>
            </div>
        </Card>
    )
};

export default ProgressBarDetails;
