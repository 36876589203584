import React, { useState } from 'react';
import './Carousel.scss';
import { Card, Button } from 'antd';
import { CloseOutlined, LeftOutlined, RightOutlined, ArrowRightOutlined } from '@ant-design/icons';
import '../css/globalStyles.scss'
import { format } from 'date-fns';
import ReactPlayer from 'react-player/lazy';

const ProductUpdatesCarousel = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [videoUrl, setVideoUrl] = useState(false);
  const [videoUrlLink, setVideoUrlLink] = useState('');
  const [driveLink, setDriveLink] = useState(false);
  const totalSlides = slides?.length;
  const driveRegex = /drive.google.com\/file\/d\/(.*)\/view/;

  const toggleFullScreen = (videoUrl) => {
    const match = videoUrl.match(driveRegex);
    if(videoUrl) {
        setVideoUrl(true);
        if (match && match[1]) {
            setDriveLink(true);
            setVideoUrlLink(`https://drive.google.com/file/d/${match[1]}/preview`);
        } else {
            setVideoUrlLink(videoUrl);
        }
    }
    setIsFullScreen(!isFullScreen);
  };

  const redirectFromProductUpdates = (url) => {
    window.open(url);
  };

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % totalSlides);
  };

  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + totalSlides) % totalSlides);
  };

  return (
    <Card className='quick-links-card-container' style={{ width: '36%' }} bodyStyle={{ padding: '12px' }}>
    <div className='semibold16-24 quick-access-heading d-flex'>
        <div className='product-updates-header'>Product Updates
            {slides?.[currentSlide]?.isNewUpdate && <span className="new-tag">New</span>}
        </div>
        <div className="carousel-header">
            <div className="carousel-controls">
                <button disabled={currentSlide=== 0} onClick={prevSlide}><span className='carousel-icon' style={currentSlide=== 0 ? {} : {color: '#D34612'}}><LeftOutlined /></span></button>
                <span className='carousel-number'>{currentSlide+1}/{totalSlides}</span>
                <button disabled={currentSlide+1 === totalSlides} onClick={nextSlide}><span className='carousel-icon' style={currentSlide+1 === totalSlides ? {} : {color: '#D34612'}}><RightOutlined /></span></button>
            </div>
        </div>
    </div>
    <div className="carousel-container">
    {slides?.map((slide, index) => (
        <div key={index} className={index === currentSlide ? 'slide' : 'slide inactive'}>
            <div className='d-flex'>
                <div className='product-updates-header bold14-22'>
                    {slide.productName}
                </div>
                <div className="carousel-header regular14-22">
                {format(new Date(slide.startDate), 'dd MMMM yyyy')}
                </div>
            </div>
            <div className='d-flex' style={{marginTop: '10px'}}>
                {slide.videoUrl ?
                slide.videoUrl.match(driveRegex) ?
                <div className='product-updates-image' onClick={() => toggleFullScreen(slide?.videoUrl)}>
                    <iframe
                        width="280px"
                        height="120px"
                        src={`https://drive.google.com/file/d/${slide.videoUrl.match(driveRegex)[1]}/preview`}
                        frameBorder="0"
                        allowFullScreen
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        title="Video Player"
                    />
                </div>
                :
                <div className='product-updates-image'>
                    <ReactPlayer
                        onClick={() => toggleFullScreen(slide?.videoUrl)}
                        className="normal-image"
                        url={slide.videoUrl}
                        playing={false}
                        controls
                        muted={true}
                        width="280px"
                        height="120px"
                    />
                </div>
                :
                (slide.imageUrl ?
                    <div className='product-updates-image'>
                        <img className={isFullScreen ? 'full-screen-image' : 'normal-image'} onClick={() => setIsFullScreen(!isFullScreen)} src={slide.imageUrl}/>
                    </div> : null)
                }
                <div className="regular14-22" style={{textAlign: 'left'}}
                    dangerouslySetInnerHTML={{ __html: slide.productDescription }}>
                </div>
            </div>
            {slide.redirectionLink?.buttonText &&
            <Button onClick={() => redirectFromProductUpdates(slide?.redirectionLink?.url)} className='CTA-btn vl-primary-btn'>
                {slide.redirectionLink?.buttonText}<ArrowRightOutlined />
            </Button>
            }
        </div>
        ))}
    </div>
    {isFullScreen && 
    <>
        <CloseOutlined onClick={() => setIsFullScreen(!isFullScreen)} className='close-icon-video'/>
        {videoUrl ?
            <div className="video-overlay" onClick={() => setIsFullScreen(!isFullScreen)}>
                <div className="video-wrapper">
                    {driveLink ?
                        <iframe
                        src={videoUrlLink}
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        title="Video Player"
                        />
                    :
                    <ReactPlayer
                        url={videoUrlLink}
                        className="normal-image"
                        playing
                        controls
                        width="100%"
                        height="100%"
                    />
                    }
                </div>
            </div>
        :
        <div className="overlay" onClick={toggleFullScreen}></div>
        }
    </>
    }
    </Card>
  );
};

export default ProductUpdatesCarousel;
