import React from 'react'
import '../../../css/bulkAction.scss'
import { DownOutlined } from '@ant-design/icons';

function Step3() {
  return (
    <div className="step-container">
        <div className="step-heading">
            <div className="step-button"> Step 3</div>
            <div className="step-heading-text">Select 'Remote Onboarding' and click 'NEXT'</div>
        </div>
        <div className="step-details">
            <div className="details-full-width">
                <div className="onboarding-preference">
                    <div className="star">*</div>
                    <div className="onboarding-preference">Onboarding Process Preference</div>
                </div>
                <div className="preference-option"> 
                    <div>
                        Remote Onboarding
                    </div>
                    <div className="dropdown-arrow">
                        <DownOutlined />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Step3