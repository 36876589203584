import React from 'react'

function StepsIndicatorDesktop({step, handleStepClick}) {
  return (
    <div  
    className={`single-step ${step.isActive || step.isCompleted ? 'active-step':''} ${step?.id === 0 ? 'left-curved-border':''}`}
    onClick={()=>handleStepClick(step)}
    style={{cursor: (step?.isCompleted || step?.isActive) ? 'pointer':'not-allowed'}}
    >
        <div className={`step-count ${step.isActive || step.isCompleted ? 'active-step-count':''}`}>{step?.id + 1}</div>
        <span className={`step-header ${step.isActive || step.isCompleted ? 'active-step-header':''}`}>{step?.label}</span>
    </div>
  )
}

export default StepsIndicatorDesktop