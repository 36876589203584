import React, { useState, useContext } from 'react';
import { notification, Button, message, Modal, Input, Typography } from 'antd';
import { loginForPayoutDashboard, sendPayoutDashboardPasswordResetUrl } from '../../../../service/commonService.js';
import { CloseOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../App.js';
import './AccountLockingStyle.scss'

const { Text } = Typography;

const AccountLocking = (props) => {
    const { path, pathName, visibleAuth, setVisibleAuth, dontGoBack, onCancelClick } = props;

    const { mitraReducer } = useContext(AppContext);
    const [accessPasswordUsr, setAccessPasswordUsr] = useState('');
    const history = useHistory();

    const onResetPasswordClick = () => {

        const key = `open${Date.now()}`;
        const btn = (
            <Button type="primary" size="small" onClick={() => history.push(`/${path}`)}>
                Go to {pathName}
            </Button>
        );

        notification.open({
            message: `Notification`,
            description: `A password ${mitraReducer?.mitraInfo?.password ? 'reset' : 'creation'} SMS has been sent to your phone number ${mitraReducer?.mitraInfo?.phoneNumber} and email ${mitraReducer?.mitraInfo?.email}`,
            btn,
            key,
        });
        let isNewPassword = mitraReducer?.mitraInfo?.password ? false : true
        sendPayoutDashboardPasswordResetUrl(isNewPassword);
        history.push('/home')
    }

    const handleOkClick = () => {
        loginForPayoutDashboard({ password: accessPasswordUsr }).then(res => {
            if (res.data.success) {
                localStorage.setItem("payoutAccessToken", res.headers.payoutaccesstoken)
                setVisibleAuth(false);
            } else {
                message.error("Invalid credentials!")
            }
        }).catch((e) => {
            message.error("Invalid credentials!")
        })
    }

    const getButtons = () => {
        let buttonsArr = [];
        if (mitraReducer?.mitraInfo?.password) {
            buttonsArr.push(
                <Button
                    onClick={onResetPasswordClick}
                    type="secondary">
                    <Text >Reset Password</Text>
                </Button>
            );
            buttonsArr.push(
                <Button
                    onClick={handleOkClick}
                    type="primary">
                    <Text style={{ color: '#fff' }}> OK </Text>
                </Button>
            )
        }
        return buttonsArr;
    }

    return (
        <>
            <Modal
                title={null}
                visible={visibleAuth && !localStorage.getItem("payoutAccessToken")}
                onOk={() => {
                }}
                onCancel={() => {
                    dontGoBack ?
                        onCancelClick() : history.goBack()
                }}
                footer={getButtons()}
                className='al-modal-parent'
            >
                <div className='al-modal-content'>
                    <div className='alm-header d-flex justify-content-between align-items-center'>
                        <div className='alm-header-title d-flex justify-content-between align-items-center'>
                            <div className='d-flex align-items-center'>
                                <span className='alm-header-txt'>
                                    Authentication
                                </span>
                            </div>
                        </div>
                        <div className='alm-header-close jp-cp' onClick={onCancelClick}>
                            <CloseOutlined className='alm-header-close-icon' />
                        </div>
                    </div>
                    {
                        mitraReducer?.mitraInfo?.password ?
                            <Input.Password
                                placeholder={"Input password to access " + pathName}
                                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                onChange={(e) => {
                                    setAccessPasswordUsr(e.target.value)
                                }}
                                className='alm-password-inp'
                                defaultValue={accessPasswordUsr}
                            /> :
                            <Button
                                onClick={onResetPasswordClick}
                                type="secondary">
                                <Text > Create New Password</Text>
                            </Button>
                    }
                </div>
            </Modal>
        </>
    )
}

export default AccountLocking;
