import React, {useState, useEffect, useContext} from 'react';
import PageHeaderComponent from '../common/PageHeader';
import { Tabs, message } from 'antd';
import {InboxOutlined,BellOutlined} from '@ant-design/icons'
import Notification from './Notification.component';
import { getUnreadNotificationsCount } from '../../service/inboxComms';
import { AppContext } from '../../App';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';

const { TabPane } = Tabs;

const onChange = (key) => {
  console.log(key);
}

const NotificationTabs = () => {
    const [breadcrumb] = useState([{link: '/notification', label: 'Notification'}]);
    const { setSpin } = useContext(AppContext);
    const [unreadMessages, setUnreadMessages] = useState(0);

    useEffect(()=> {
        trackEvent('clicked_inbox_page', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        setSpin({
            loading: true, //false or true when actived
            delay: null,
            tip: 'Loading...',
          });
        getUnreadNotificationsCount().then((response)=> {
            setSpin({
                loading: false, //false or true when actived
                delay: null,
                tip: 'Loading...',
              });
              setUnreadMessages(response.data.totalUnreadMessages)
        }).catch(error=> {
            message.error('No data');
            setSpin({
            loading: false, //false or true when actived
            delay: null,
            tip: 'Loading...',
            });
        });
    }, []);

    return (
        <div className='Notification mainContentZ'>
            {/* <div className="header" style={{marginBottom:4,display:'flex',justifyContent:'space-between'}}>
                <PageHeaderComponent breadcrumb={breadcrumb} />
            </div>
            <Tabs 
            defaultActiveKey="inbox" 
            onChange={onChange} 
            size={'medium'}
            centered
            tabBarStyle={{display:'flex', justifyContent:'space-between'}}>
                <TabPane tab={<span className="d-flex align-items-center">
                    <InboxOutlined />Inbox
                    { unreadMessages!==0 && ` (${unreadMessages<10 ? '0'+unreadMessages: unreadMessages} Unread)`}</span>} key="inbox">
                    <Notification />
                </TabPane>
                <TabPane tab={<span className="d-flex align-items-center"><BellOutlined />Notifications (09 Unread)</span>} key="notification">
                    <div>Notifications Tab</div>
                </TabPane>
                
            </Tabs> */}
            <Notification unreadInboxMessages={unreadMessages}/>
        </div>
    );
};

export default NotificationTabs;
