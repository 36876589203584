import React, { useContext, useEffect, useState } from "react";
import '../whatsappTemplateModal/whatsappModal.css';
import { Modal, Button,Radio,Space, Input,Form, notification, Typography } from "antd";
import { PhoneOutlined } from '@ant-design/icons';
import { AppContext } from "../../App";
import {useHistory} from 'react-router-dom';
import { getRecentCallingNumbers } from "../../service/kaleyraCallService";
const { Text } = Typography;

const CallerNumberSelectionModal = ({showModal,closeModal,callerNumberList}) => {
  
    const history = useHistory();
    const [selectedNumber, setSelectedNumber] = useState('');
    const {mitraReducer,mitraDispatch} = useContext(AppContext);
    const [customFieldSelected, setCustomFieldSelected] = useState(false);
    const templates = callerNumberList;

    useEffect(() => {
      // getRecentCallingNumbers().then(res=> {
      //   setTemplates([...res.data, {id:'custom',phoneNumber:''}])
      // }).catch(err => {
      //   console.log(err);
      // });

      mitraReducer?.kaleyraCallInformation && setSelectedNumber(mitraReducer.kaleyraCallInformation.callerNumber)
    }, [mitraReducer]);

    const onRadioChange = (event) => {
        if (event.target.value === '') {
          setCustomFieldSelected(true);
        } else {
          setCustomFieldSelected(false);
        }
        setSelectedNumber(event.target.value);
    };

    const onTriggerClick = () => {
      if (!selectedNumber.match(/^\d{10}$/)) {
        notification['error']({
          message: 'Please enter a valid phone number !!'
        });
        return;
      }
        const callInfo = mitraReducer.kaleyraCallInformation;
        mitraDispatch({type:'KaleyraCallDetails', value: {callerNumber:selectedNumber,receiverNumber:callInfo.receiverNumber, currentLead:callInfo.currentLead, currentSelectedSiId:callInfo.currentSelectedSiId}});
        history.push(`/connecting-call`);
    };

    const onInputChange = (event) => {
      setSelectedNumber(event.target.value);
    }

    return (
        <Modal
        className="whatsapp-modal-style"
        style={{ textAlign: 'left' }}
        title="Which number do you want to use for calling ?"
        visible= {showModal}
        onCancel={closeModal}
        footer={[
          <Button 
          icon={<PhoneOutlined />} 
          onClick={onTriggerClick}
          type="primary">
            <Text style={{ color: '#fff' }}>Call Now</Text>
          </Button> 
        ]}
      >
        <Radio.Group onChange={onRadioChange} value={selectedNumber}>
          <Space direction="vertical">
            {templates.map((template) => {
              return (
                <Radio key={template.id} value={template.phoneNumber}>
                  {template.id !== 'custom' ? template.phoneNumber: 'Custom Number'}
                  {
                    customFieldSelected && template.id === 'custom' ? (
                      <Form>
                        <Form.Item
                          label="Enter number"
                          name="numberValue"
                          rules={[
                            {
                              pattern: /^\d{10}$/,
                              message: "Please enter a valid phone number",
                            },
                          ]}
                        >
                          <Input
                            onChange={(value) => {
                              onInputChange(value);
                            }}
                          />
                        </Form.Item>
                      </Form>
                    ): null
                  }
                </Radio>
              );
            })}
          </Space>
        </Radio.Group>
      </Modal>
    )
};

export default CallerNumberSelectionModal;
