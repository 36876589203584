import * as amplitude from 'amplitude-js';

function logEvent(eventName, group, screen, properties) {
    const trackingData = {
        product: 'mitraLeaderPwa',
        group,
        screen,
        ...properties
    };
    amplitude.getInstance().logEvent(eventName, trackingData);
}

function setTrackingUser(mitra) {
    const existingUser = amplitude.getInstance().options.userId;
    if (mitra && existingUser !== mitra.id) {
        amplitude.getInstance().setUserId(mitra.id);
        amplitude.getInstance().setUserProperties(mitra);
    }
}

export { logEvent, setTrackingUser };