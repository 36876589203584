import React, { useState } from 'react';
import { Modal, Rate, Input, Button, notification } from 'antd';
import './FeedbackModal.scss';
import {StarFilled, StarOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { saveFeedback } from '../service/homeService';

const FeedbackModal = ({ visible, onCancel }) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState();
  const [saveButtonDisable, setSaveButtonDisable] = useState(true);
  const [commentCount, setCommentCount] = useState(0);
  const key = `open${Date.now()}`;
  const placement = 'topRight';

  const handleRatingChange = value => {
    if(value > 0 && comment) {
      setSaveButtonDisable(false);
    } else {
      setSaveButtonDisable(true);
    }
    setRating(value);
  };
  const handleCommentChange = e => {
    if(rating && e.target.value) {
      setSaveButtonDisable(false);
    } else {
      setSaveButtonDisable(true);
    }
    const count = e.target.value.length;
    setCommentCount(count);
    setComment(e.target.value);
  };
  const handleSave = () => {
    saveFeedback(rating, comment).then((res) => {
      if(res?.data?.success) {
        notification.open({
          message: "Feedback saved successfully!",
          key,
          duration: 5,
          placement,
          icon: <CheckCircleOutlined className='green-positive' />,
      });
        handleCancel();
      }
    })
  };

  const handleCancel = () => {
    setRating(0);
    setComment();
    setCommentCount(0);
    onCancel();
  };

  return (
    <Modal
      title='Share Feedback'
      visible={visible}
      onOk={handleSave}
      onCancel={handleCancel}
      footer={[
        <div key={1} className='d-flex justify-content-end'>
            <Button disabled={saveButtonDisable} onClick={()=>handleSave()} style={saveButtonDisable ? {opacity: '0.4', fontWeight: 700}:{fontWeight: 700}} className='file-upload-button'> Send</Button>
        </div>
      ]}>
      <div className='feedback-modal-body'>
        <div>
          We value your experience and would love to hear what you think about the Vahan portal. Your feedback goes straight to our team to help us make things even better. Share your thoughts!
        </div>
        <Rate className= 'ratings' onChange={handleRatingChange} value={rating} character={({ index }) => {
          if (index + 1 <= rating) {
            return <StarFilled style={{ color: '#FFC53D' }} />;
          } else {
            return <StarOutlined style={{ color: '#00000040' }} />;
          }
        }} />
        <Input.TextArea
          placeholder='Tell us what you think...'
          value={comment}
          rows={5}
          maxLength={500}
          onChange={handleCommentChange}
        />
        <div style={{ marginTop: '5px', textAlign: 'right', color: '#888' }}>
          {commentCount}/500
        </div>
      </div>
    </Modal>
  );
};

export default FeedbackModal;
