import React from 'react'

function StepsIndicatorMobile({index, handleStepClick, step, steps}) {
  return (
    <div className='d-flex flex-column' style={{width:'100%'}} onClick={()=>handleStepClick(step)}>
        <div className={
            `steps-bar-indicator 
            ${index === 0 ? 'steps-bar-indicator-left-radius':
            index === steps.length-1 ? 'steps-bar-indicator-right-radius': null}
            `
            }>
                {
                    (step?.isCompleted || step?.isActive) ? (
                        <div 
                        className={`div-fill-container ${step?.isActive ? 'steps-bar-indicator-right-radius':''} ${index === 0 ? 'steps-bar-indicator-left-radius':''}`}
                        style={{width: index != steps.length-1 && step?.isActive ? '80%':'100%' }}
                        ></div>
                    ): null
                }
        </div>
        <div className="steps-text-indicator text-align-start" style={{height: '14px',marginTop:'6px'}}>
            <div className={`step-count ${step.isActive || step.isCompleted ? 'active-step-count':''}`}>{step?.id + 1}</div>
            <span className={`step-header ${step.isActive || step.isCompleted ? 'active-step-header':''}`}>{step?.label}</span>
        </div>
    </div>
  )
}

export default StepsIndicatorMobile