import React, { useState, useEffect, useReducer } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import PageHeaderComponent from '../../../../common/PageHeader';
import { Divider, Tag, Button, Skeleton, Empty, message, Select, Input, Pagination } from 'antd';
import { PlusOutlined, CloseOutlined, FileTextOutlined, FilterOutlined, SearchOutlined, PictureOutlined } from '@ant-design/icons';
import axios from 'axios';
import './ClientDetailsContainerStyle.scss'
import '../../../style/JobsPageContainerStyle.scss'
import CDAllCitiesListing from './CDAllCitiesListing';
import CDAllLocalitiesListing from './CDAllLocalitiesListing';
import PosterModal from '../../JobPoster/PosterModal';
import { getJobCities, getJobRequirementDetails, getJobLocalities } from '../../../../../service/jobDemandsService';
import SideSheet from '../../../../common/SideSheet'
import { getDisplayableClientName } from '../../../../../utils/commonFunction';
import PitchModal from '../../ClientPitch/PitchModal';
import FilterSideSheetComponent from '../../common/FilterSideSheet';
import { trackEvent } from '../../../../../service/engagementMonitoringService';
import CONSTANTS from '../../../../../constants/constants';


const ClientDetailsContainer = ({ jobDetailsData = {
    "vertical": "",
    "jobType": "",
    "maxSalary": "",
    "cityCount": "",
    "companyId": "",
    "companyKey": "",
    "companyName": "",
    "openings": "",
    "localities": "",
    "companyLogo": "",
    "requirements": {
        "education": [
        ],
        "jobPreference": ""
    },
    "jobLocations": "",
    "jobs": "",
    "salaryText": ""
} }) => {
    const history = useHistory();
    const location = useLocation();
    const { Option } = Select;

    const [jobDetails, setJobDetails] = useState(location.state && location.state.jobData ? location.state.jobData :
        {
            "vertical": "",
            "jobType": "",
            "maxSalary": "",
            "cityCount": "",
            "companyName": "",
            "openings": "",
            "localities": "",
            "companyId": "",
            "companyLogo": "",
            "requirements": {
                "education": [
                ],
                "jobPreference": ""
            },
            "jobLocations": "",
            "jobs": "",
            "salaryText": ""
        })
    const [cityListingData, setCityListingData] = useState([
        {
            "city": "",
            "totalOpenings": "",
            "totalNumberOfLocalities": ""
        },])
    const [totalCitiesPages, setTotalCitiesPages] = useState(0);
    const [currentCitiesPage, setCurrentCitiesPage] = useState(1);

    const [localityListingData, setLocalityListingdata] = useState([{
        "locality": "",
        "totalOpenings": "",
        "partTime": "",
        "fullTime": "",
        "onboardingFee": null,
        "upfrontFee": null,
        "vehicle": null,
        "joiningBonusAvailable": false
    }])
    const [totalLocalitiesPages, setTotalLocalitiesPages] = useState(0);
    const [currentLocalitiesPage, setCurrentLocalitiesPage] = useState(1);


    const [breadcrumb, setBreadcrumb] = useState([{ link: '/job-demands/job-requirements', label: 'Jobs' }, { link: '/job-demands/job-requirements', label: "Job Requirements" }]);
    const [isCitySelected, setIsCitySelected] = useState(false);
    const [selectedCityDetails, setSelectedCityDetails] = useState({
        "city": "",
        "totalOpenings": 0,
        "totalNumberOfLocalities": 0,
    },);

    const [showPosterModal, setShowPosterModal] = useState(false);
    const [cancelToken, setCancelToken] = useState(null); // State to store the cancel token
    const [loadingListing, setLoadingListing] = useState(false);
    const [cityListingPageNo, setCityListingPageNo] = useState(1);
    const [companyId, setCompanyID] = useState("");
    const [citiesFilterList, setCitiesFilterList] = useState([]);
    const [copyCitiesFilterList, setCopyCitiesFilterList] = useState([]);
    const [citySearch, setCitySearch] = useState("")
    const [filtersList, setFiltersList] = useState({
        city: "",
        localities: []
    });
    const [filterSidebar, setFilterSidebar] = useState(false);
    const [localitiesFilterList, setLocalitiesFilterList] = useState([])
    const [filteredLocalitiesList, setFilteredLocalitiesList] = useState([])
    const [searchLocality, setSearchLocality] = useState("")
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);
    const [allCitiesLabel, setAllCitiesLabel] = useState("All Cities")
    const [showPitchModal, setShowPitchModal] = useState(false)


    useEffect(() => {
        if (location && location.state && location.state.city) {
            handleCityFilterChange(location.state.city)
        }
    }, [])

    useEffect(() => {
        if (jobDetails && jobDetails.companyId && jobDetails.companyName) {
            if (isCitySelected) {
                setBreadcrumb([{ link: '/job-demands/job-requirements', label: 'Jobs' }, { link: '/job-demands/job-requirements', label: "Job Requirements" }, { link: `/job-demands/job-requirements/${jobDetails.companyId}`, label: jobDetails.companyName }, { link: `/job-demands/job-requirements/${jobDetails.companyId}`, label: filtersList.city }])
            }
            else {
                setBreadcrumb([{ link: '/job-demands/job-requirements', label: 'Jobs' }, { link: '/job-demands/job-requirements', label: "Job Requirements" }, { link: `/job-demands/job-requirements/${jobDetails.companyId}`, label: jobDetails.companyName }])
            }
        }
        extractCompanyIDFromURL()
        fetchCitiesFilter()
    }, [jobDetails])

    useEffect(() => {
        fetchJobRequirementsListing()
    }, [currentCitiesPage])

    useEffect(() => {
        fetchJobRequirementsListing()
    }, [currentLocalitiesPage])

    useEffect(() => {
        if (companyId !== "")
            fetchJobRequirementsListing()
    }, [companyId])

    useEffect(() => {
        fetchJobRequirementsListing()
    }, [isCitySelected])
    
    const extractCompanyIDFromURL = () => {
        const path = location.pathname;
        const parts = path.split('/');
        setCompanyID(parts[parts.length - 1])
    }

    const fetchCitiesFilter = () => {
        getJobCities().then((citiesList) => {
            if (citiesList && citiesList.data && citiesList.data.cityList) {
                setCitiesFilterList(citiesList?.data?.cityList)
                setCopyCitiesFilterList(citiesList?.data.cityList)
                if (filtersList.city == "")
                    autoSelectAllCities(citiesList?.data?.cityList)
            }
            else {
                setCitiesFilterList([])
                setCopyCitiesFilterList([])
            }
        })
    }

    const autoSelectAllCities = (allCities) => {
        let tempFiltersList = filtersList
        for (let i = 0; i < allCities.length; i++) {
            if (allCities[i] == allCitiesLabel) {
                tempFiltersList.city = allCities[i]
                setFiltersList(JSON.parse(JSON.stringify(tempFiltersList)));
            }
        }

    }

    const fetchJobRequirementsListing = () => {
        setLoadingListing(true)
        if (cancelToken) {
            cancelToken.cancel(); // Used to cancel previous request to prevent data discrepancy
        }
        const newCancelToken = axios.CancelToken.source(); // Create a new CancelToken
        setCancelToken(newCancelToken); // Set the new cancel token

        try {
            // Make API Call with all filters, only releveant ones get added to the URL
            getJobRequirementDetails(isCitySelected ? currentLocalitiesPage : currentCitiesPage, companyId, filtersList.city, JSON.stringify(filtersList.localities), cancelToken).then((listRes) => {
                setJobDetails(listRes.data.companyJobDetails[0])
                if (filtersList.city && filtersList.city !== allCitiesLabel) {
                    setLoadingListing(true)
                    if (listRes && listRes.data && listRes.data.details) {
                        setLoadingListing(false)
                        setLocalityListingdata(listRes && listRes.data && listRes.data.details)
                        setTotalLocalitiesPages(listRes.data.totalItems)
                    }
                    else {
                        setLoadingListing(false)
                        setLocalityListingdata([])
                        setTotalLocalitiesPages(0)
                        message.error("Something went wrong, please try again later", 4)
                    }
                }
                else {
                    if (listRes && listRes.data && listRes.data.details) {
                        setLoadingListing(false)
                        setCityListingData(listRes.data.details)
                        setTotalCitiesPages(listRes.data.totalItems)
                    }
                    else {
                        setLoadingListing(false)
                        setCityListingData([])
                        message.error("Something went wrong, please try again later", 4)
                    }
                }
            })
        }
        catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled');
                setLoadingListing(false)
            } else {
                console.log('Request err', error);
                setLoadingListing(false)
            }
        }
    }

    const onCityInpChange = (inp) => {
        inp.preventDefault();
        if (inp?.target?.value) {
            setCitySearch(inp.target.value)
            let tempCityFilterList = citiesFilterList.filter(city => city.toLowerCase().includes(inp.target.value.toLowerCase()))
            setCopyCitiesFilterList(JSON.parse(JSON.stringify(tempCityFilterList)))
        }
        else if (inp?.target?.value == "") {
            setCitySearch("")
            setCopyCitiesFilterList(JSON.parse(JSON.stringify(citiesFilterList)))
        }
    }

    const handleCityFilterChange = (selectedCity) => {
        if (selectedCity == allCitiesLabel) {
            setIsCitySelected(false);
        }
        else {
            setIsCitySelected(true);
        }

        if (selectedCity) {
            setCitySearch("")
            let tempFiltersList = filtersList
            tempFiltersList.city = selectedCity
            tempFiltersList.localities = []
            setFiltersList(JSON.parse(JSON.stringify(tempFiltersList)));
            fetchJobRequirementsListing()
            fetchLocalitiesFilter()
        }
    }

    const fetchLocalitiesFilter = () => {
        getJobLocalities(filtersList.city).then(data => {
            if (data && data.data) {
                setLocalitiesFilterList(data.data.localities)
                setFilteredLocalitiesList(data?.data?.localities)
            }
        })
    }

    const onCreatePosterClicked = () => {
        setShowPosterModal(true)
    }

    const onViewPostersClicked = () => {
        let client = {
            key: jobDetails.companyKey,
            label: jobDetails.companyName,
            value: true
        }
        history.push({
            pathname: `/job-demands/leads-from-posters`,
            state: client
        })
    }

    const onViewPitchClicekd = () => {
        trackEvent('clicked_view_pitch', { client: jobDetails.companyName }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        setShowPitchModal(true)
    }

    const onUserSelectedCity = (citySelected) => {
        setCurrentLocalitiesPage(1);
        setIsCitySelected(true);
        setBreadcrumb([{ link: '/job-demands/job-requirements', label: 'Jobs' }, { link: '/job-demands/job-requirements', label: "Job Requirements" }, { link: `/job-demands/job-requirements/${jobDetails.companyId}`, label: jobDetails.companyName }, { link: `/job-demands/job-requirements/${jobDetails.companyId}/${citySelected.city}`, label: citySelected.city }])
        let tempFiltersList = filtersList
        tempFiltersList.city = citySelected.city
        setFiltersList(JSON.parse(JSON.stringify(tempFiltersList)));
        fetchLocalitiesFilter()
        // fetchJobRequirementsListing()
    }

    const returnFilterLabel = (label, arrObj) => {
        let localityArrObj = []

        if (searchLocality == "") {
            localityArrObj = localitiesFilterList.slice(0, 5)
        }
        else {
            localityArrObj = filteredLocalitiesList
        }

        return <div className='tag-containers'>
            <div>LOCALITIES in <strong>{filtersList.city}</strong></div>
            {
                <div className='jp-localities-search'>
                    <Input value={searchLocality} placeholder="Search Locality" allowClear prefix={<SearchOutlined />} onChange={onLocalitySearch} />

                    <div className='tag-wrapper-container align-items-center'>
                        {localityArrObj.map((item, index) => {
                            return <div key={index}>
                                <Tag className={item && item.value ? 'tags tag-active' : 'tags'}
                                    key={index}
                                    onClick={() => { onClickTags(item, label, index) }}
                                >
                                    {item.name}
                                </Tag>
                            </div>

                        })}
                        {
                            searchLocality == "" && localitiesFilterList.length > 5 ?
                                <span className='jp-more-localities-txt'>
                                    + {localitiesFilterList.length - 5} localities
                                </span>
                                :
                                null
                        }
                    </div>


                </div>
            }
        </div>
    }

    const applyFilterCondition = () => {
        let result = false;

        if ((filtersList.localities && filtersList.localities.length > 0)) {
            result = true
        }
        return result;
    }

    const onClickTags = (item, label, index) => {
        let allFilterListCopy = filtersList

        if (searchLocality) {
            selectFilteredLocalityInArray(filteredLocalitiesList, item, index)
            localitiesFilterList.map((locality, localityInd) => {
                if (locality.key == item.key) {
                    locality.value = !locality.value
                    if (locality.value) {
                        const selectedLocality = localitiesFilterList.splice(localityInd, 1)[0];
                        localitiesFilterList.unshift(selectedLocality);
                    }
                }
            })
            setSearchLocality("")
        }
        else
            selectFilteredLocalityInArray(localitiesFilterList, item, index)

        allFilterListCopy.localities = []
        for (let i = 0; i < localitiesFilterList.length; i++) {
            if (localitiesFilterList[i].value) {
                allFilterListCopy.localities.push(localitiesFilterList[i].key)
            }
        }

        setFiltersList(JSON.parse(JSON.stringify(allFilterListCopy)))
        fetchJobRequirementsListing()
        forceUpdate()
    }

    const selectFilteredLocalityInArray = (arr, item, index) => {
        arr.map((locality, localityInd) => {
            if (locality.key == item.key) {
                locality.value = !locality.value
                if (locality.value) {
                    const selectedLocality = arr.splice(localityInd, 1)[0];
                    arr.unshift(selectedLocality);
                }
            }
        })
    }

    const onLocalitySearch = (inp) => {
        setSearchLocality(inp.target.value)
        if (inp && inp.target && inp.target.value) {
            let tempFilteredLocalityList = localitiesFilterList.filter(locality => locality.name.toLowerCase().includes(inp.target.value.toLowerCase()))
            setFilteredLocalitiesList(JSON.parse(JSON.stringify(tempFilteredLocalityList)))
        }
    }

    const removeSingleLocality = (locality, localityInd) => {
        let allFilterListCopy = filtersList

        localitiesFilterList[localityInd].value = false;

        allFilterListCopy.localities = []
        for (let i = 0; i < localitiesFilterList.length; i++) {
            if (localitiesFilterList[i].value) {
                allFilterListCopy.localities.push(localitiesFilterList[i].key)
            }
        }

        setFiltersList(JSON.parse(JSON.stringify(allFilterListCopy)))
        fetchJobRequirementsListing()
    }

    const clearFilters = () => {
        let existingFilters = filtersList

        let tempFilteredLocalitiesArr = filteredLocalitiesList
        let tempLocalitiesFilterList = localitiesFilterList
        tempFilteredLocalitiesArr.map((locality) => {
            locality.value = false
        })
        tempLocalitiesFilterList.map((locality) => {
            locality.value = false
        })
        setFilteredLocalitiesList(JSON.parse(JSON.stringify(tempFilteredLocalitiesArr)))
        setLocalitiesFilterList(JSON.parse(JSON.stringify(tempLocalitiesFilterList)))
        existingFilters.localities = []
        setFilterSidebar(false)
        forceUpdate()
        setFiltersList(JSON.parse(JSON.stringify(existingFilters)));
        fetchJobRequirementsListing()
    }

    return (
        <div className="client-details-container-parent mainContentZ h-100">
            <div className="containerTop">
                <div className="cd-new-breadcrumb-header header">
                    <>
                        <PageHeaderComponent backBtnBool={true} breadcrumb={breadcrumb} />
                    </>
                </div>
            </div>

            <Divider></Divider>

            <div className='cd-job-details-header'>
                <div className='cd-company-info-btns'>
                    <div className='d-flex align-items-center w-50'>
                        <img src={jobDetails.companyLogo} alt="comp_logo" className='cd-company-logo-img' />
                        <div className='cd-company-name-type'>
                            <div className='cd-company-name'>
                                {getDisplayableClientName(jobDetails.companyName)}
                            </div>
                            <div className='cd-company-type'>
                                {jobDetails.jobType}
                            </div>
                        </div>
                    </div>

                    <div className='cd-job-details-header-btns d-flex'>
                        <div className='cd-create-view-poster-btns d-flex'>
                            <Button className='jp-secondary-btn cd-job-details-header-btn cd-create-poster-btn cd-job-details-btn cd-job-details-btn-margin d-flex align-items-center jp-cp justify-content-center' onClick={() => onCreatePosterClicked()}>
                                <PlusOutlined className='cd-create-poster-btn-img cd-job-details-header-btn-img-orange jp-orange-txt' />
                                <div className='jp-secondary-btn-txt cd-create-poster-btn-txt jp-orange-txt'>
                                    Create Poster
                                </div>
                            </Button>

                            <Button className='jp-secondary-btn cd-job-details-header-btn cd-view-posters-btn cd-job-details-btn cd-job-details-btn-margin d-flex align-items-center jp-cp justify-content-center' onClick={() => onViewPostersClicked()}>
                                <PictureOutlined className='cd-view-posters-btn-img cd-job-details-btn-img-orange jp-orange-txt' />
                                <div className='jp-secondary-btn-txt cd-view-posters-btn-txt jp-orange-txt d-flex'>
                                    <span className='show-only-desktop'>View</span>&nbsp;Posters
                                </div>
                            </Button>
                        </div>

                        <Button className='jp-primary-btn cd-job-details-header-btn cd-view-pitch-btn cd-job-details-btn cd-job-details-btn-margin d-flex align-items-center jp-cp justify-content-center' onClick={() => onViewPitchClicekd()}>
                            <FileTextOutlined className='cd-view-pitch-btn-img cd-job-details-btn-img-white jp-white-txt' />
                            <div className='jp-primary-btn-txt cd-view-pitch-btn-txt jp-white-txt d-flex'>
                                <span className='show-only-desktop'>View</span>&nbsp;Pitch
                            </div>
                        </Button>
                    </div>
                </div>

                <div className='d-flex justify-content-between align-items-end'>
                    <div className='cd-requirements-section d-flex'>
                        <div className='cd-requirements-section-left'>
                            <div className='cd-requirements-section-header'>
                                BASIC REQUIREMENTS
                            </div>
                            <div className='cd-requirements-section-requirements'>
                                {
                                    jobDetails.requirements.education.map((requirement, idx) => {
                                        return (
                                            <div key={idx} className='cd-requirements-section-requirements-txt'>
                                                {requirement}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <div className='cd-requirements-section-mid cd-vertical-divider'>

                        </div>


                        {/* <Divider className='jdc-vertical-divider' /> */}

                        <div className='cd-requirements-section-right'>
                            <div className='cd-requirements-section-header'>
                                SALARY
                            </div>
                            <div className='cd-requirements-section-salary cd-requirements-section-requirements-txt'>
                                Upto ₹{jobDetails.maxSalary} per month
                            </div>
                        </div>
                    </div>

                    {
                        isCitySelected ?
                            totalLocalitiesPages > 0 && localityListingData && localityListingData.length > 0 ?
                                <div className='show-only-desktop-disp-block'>
                                    <Pagination
                                        total={totalLocalitiesPages}
                                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                        defaultPageSize={10}
                                        defaultCurrent={currentLocalitiesPage ? currentLocalitiesPage : 1}
                                        responsive={true}
                                        showLessItems={true}
                                        onChange={(page, pagesize) => { setCurrentLocalitiesPage(page) }}
                                        showSizeChanger={false}
                                    />
                                </div>
                                :
                                null
                            :
                            totalCitiesPages > 0 && cityListingData && cityListingData.length > 0 ?
                                <div className='show-only-desktop-disp-block'>
                                    <Pagination
                                        total={totalCitiesPages}
                                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                        defaultPageSize={10}
                                        defaultCurrent={currentCitiesPage ? currentCitiesPage : 1}
                                        responsive={true}
                                        showLessItems={true}
                                        onChange={(page, pagesize) => { setCurrentCitiesPage(page) }}
                                        showSizeChanger={false}
                                    />
                                </div>
                                :
                                null
                    }
                </div>
            </div>

            <div className='cd-filters-section align-items-start'>
                <div className='cd-filters-section-left d-flex align-items-center'>
                    <div className='cd-filters-txt-black'>
                        Showing jobs in
                    </div>

                    <div className='cd-filters-select d-flex align-items-center'>
                        <Select
                            placeholder={"Search City"}
                            onChange={(city) => { handleCityFilterChange(city) }}
                            value={filtersList.city}
                            className='jp-filters-section-select-mobile w-100'
                            dropdownRender={(menu) => (
                                <div className='w-100'>
                                    <Input
                                        prefix={<SearchOutlined style={{ color: "rgba(0, 0, 0, 0.45)" }} />}
                                        value={citySearch}
                                        onChange={onCityInpChange}
                                        placeholder="Search for a city"
                                        style={{ marginBottom: 8, maxWidth: "90%" }}
                                        allowClear
                                    />
                                    <div style={{ maxHeight: 200, overflowY: 'auto' }}>
                                        {menu}
                                    </div>
                                </div>
                            )}
                        >
                            {/* <Space style={{ padding: '0 8px 4px'}}>
                                                        <Input
                                                            value={citySearch}
                                                            defaultValue={citySearch}
                                                            placeholder="Search City"
                                                            allowClear
                                                            onChange={onCityInpChange}
                                                            onKeyDown={(e) => e.stopPropagation()}
                                                            onClick={(e) => e.stopPropagation()}
                                                            clearAll
                                                        />
                                                    </Space> */}
                            {copyCitiesFilterList?.map((item, index) => {
                                return <Option value={item} key={index}>{item}</Option>
                            })}
                        </Select>
                        {/* <Select
                            showSearch
                            placeholder="Select City"
                            style={{ width: '100%' }}
                            onChange={(city) => { handleCityFilterChange(city) }}
                            value={filtersList.city}
                        >
                            {citiesFilterList?.map((item, index) => {
                                return <Option value={item} key={index}>{item}</Option>
                            })}
                        </Select> */}
                    </div>

                    <div className='cd-filters-txt-black d-flex align-items-center'>
                        filtered by &nbsp;
                        <span className='d-flex align-items-center'>
                            <FilterOutlined className='cd-filter-icon' /> &nbsp;:
                        </span>
                        {
                            filtersList.localities.length == 0 &&
                            <span className='cd-filters-empty-txt'>
                                &nbsp;&nbsp;None
                            </span>
                        }
                    </div>

                    {
                        applyFilterCondition() ?
                            localitiesFilterList && localitiesFilterList.length > 0 ?

                                localitiesFilterList.map((locality, localityInd) => {
                                    return (
                                        locality.value ?
                                            <div className='d-flex cd-single-filter-item justify-content-center align-items-center'>
                                                <div className='single-filter-txt'>
                                                    {locality.name}
                                                </div>
                                                <CloseOutlined style={{ color: "rgba(0, 0, 0, 0.45)", marginLeft: "8px" }} onClick={() => removeSingleLocality(locality, localityInd)} />
                                            </div>
                                            : null

                                    )
                                })
                                : null
                            : null
                    }
                </div>

                <Button className='cd-filters-add-filter d-flex align-items-center' disabled={!isCitySelected || !localitiesFilterList.length} onClick={() => setFilterSidebar(true)}>
                    <PlusOutlined className='cd-filter-plus-icon' />
                    Add Filter
                </Button>
            </div>

            {
                isCitySelected ?
                    // <div>
                    <div className={`cd-all-localities-listing-container ${totalLocalitiesPages > 0 && localityListingData && localityListingData.length > 0 ? "cd-all-localities-listing-container-overflow" : ""}`}>
                        {
                            loadingListing ?
                                <Skeleton />
                                :
                                filtersList.city && localityListingData && localityListingData.length > 0 ?
                                    localityListingData.map((locality, localityInd) => {
                                        return (
                                            <CDAllLocalitiesListing key={localityInd} localityData={locality} localityInd={localityInd} selectedCity={filtersList.city} selectedJobDetails={jobDetails} />
                                        )
                                    })
                                    : localityListingData && localityListingData.length == 0 ?
                                        <Empty /> : null
                        }
                    </div>
                    // </div> 

                    :
                    // <div>
                    <div className={`cd-all-cities-listing-container ${totalCitiesPages > 0 && cityListingData && cityListingData.length > 0 ? "cd-all-cities-listing-container-overflow" : ""}`}>
                        {
                            loadingListing ?
                                <Skeleton />
                                :
                                cityListingData && cityListingData.length > 0 ?
                                    cityListingData.map((city, cityInd) => {
                                        return (
                                            <div key={cityInd}>
                                                <CDAllCitiesListing demandsData={city} demandInd={cityInd} onCitySelected={(citySelected) => onUserSelectedCity(citySelected)} />
                                            </div>
                                        )
                                    })
                                    : cityListingData && cityListingData.length == 0 ?
                                        <Empty /> : null
                        }
                    </div>
                // </div> 
            }

            {
                isCitySelected ?
                    totalLocalitiesPages > 0 && localityListingData && localityListingData.length > 0 ?
                        <div className='show-only-mobile'>
                            <Pagination
                                total={totalLocalitiesPages}
                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                defaultPageSize={10}
                                defaultCurrent={currentLocalitiesPage ? currentLocalitiesPage : 1}
                                responsive={true}
                                showLessItems={true}
                                onChange={(page, pagesize) => { setCurrentLocalitiesPage(page) }}
                                showSizeChanger={false}
                            />
                        </div>
                        :
                        null
                    :
                    totalCitiesPages > 0 && cityListingData && cityListingData.length > 0 ?
                        <div className='show-only-mobile'>
                            <Pagination
                                total={totalCitiesPages}
                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                defaultPageSize={10}
                                defaultCurrent={currentCitiesPage ? currentCitiesPage : 1}
                                responsive={true}
                                showLessItems={true}
                                onChange={(page, pagesize) => { setCurrentCitiesPage(page) }}
                                showSizeChanger={false}
                            />
                        </div>
                        :
                        null
            }

            {filterSidebar && localitiesFilterList && localitiesFilterList.length > 0 &&
                <FilterSideSheetComponent submit={{
                    disabled: false
                    , onClick: () => setFilterSidebar(false)
                }}
                    clearAll={{ disabled: false, onClick: () => clearFilters("all") }}
                    sidername={`Filter Jobs`}
                    onDismiss={() => { setFilterSidebar(false) }}>
                    <div className='filter-home-wrapper'>
                        {returnFilterLabel("Locality", filteredLocalitiesList)}
                    </div>
                </FilterSideSheetComponent>
            }

            {
                showPosterModal ?
                    <PosterModal client={{ label: jobDetails.companyName, key: jobDetails.companyName.toLowerCase(), value: true }} city={isCitySelected ? filtersList.city : ""} isModalOpen={showPosterModal} closeModal={() => setShowPosterModal(false)} />
                    :
                    null
            }

            {
                showPitchModal ?
                    <PitchModal companyDetails={{ companyName: jobDetails.companyName, logo: jobDetails.companyLogo, companyKey: jobDetails.companyKey }} isModalOpen={showPitchModal} closeModal={() => setShowPitchModal(false)} /> : null
            }

        </div>
    )
}

export default ClientDetailsContainer; 