import { Modal, notification } from 'antd';
import React, { useEffect, useRef } from 'react'
import { Form } from 'react-formio';
import { referMitra } from '../../service/referAndEarnService';
import {DollarCircleOutlined} from '@ant-design/icons';
import './referAndEarn.scss';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';

export default function ReferAndEarnForm({showForm, setShowForm}) {

    const formRef = useRef(null);

    const handleCancel = () => {
        setShowForm(false);
    };

    useEffect(()=> {
    },[]);

    const referAndEarnFormOptions = {
		hooks: {
			beforeSubmit: async (submission, next) => {
				try {
					// api call
                    // next();
                    const friendsPhoneNumber = submission.data.friendsPhoneNumber1;
                    const city = submission.data.city;
                    const name = submission.data.yourFriendsName;
                    referMitra(name,friendsPhoneNumber,city).then(res=> {
                          notification['success']({
                            message: `Referred Successfully, Our team will reach out to them in 24-48 hours`
                          });
                          setTimeout(() => {
                            window.location.reload(false);
                          }, 2000);
                    }).catch(
                        err => {
                            notification['error']({
                                message: `Candidate Already Referred !!`
                              });
                              setTimeout(() => {
                                window.location.reload(false);
                              }, 2000);
                        }
                    );
				} catch (error) {
                    console.log(error);
					next([{message: error.response ? error.response.data.message : "Something Went Wrong."}])
				}
			}
		}
	};

    const onSubmission = (val) => {
        // the function not being used currently, only triggered if next() in beforesubmit is uncommented.
        formRef.current.formio.emit('refreshData');
        formRef.current.formio.emit('resetForm');
    };

    const openTnC = () => {
        trackEvent('terms_conditions_opened', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        return window.open("/terms-and-conditions")
    }

    return (
        <div className='refer-and-earn-form-container'>
            <Modal
            title="Refer and Earn"
            visible={showForm}
            closable={true}
            onCancel={handleCancel}
            footer={null}
            bodyStyle={{ backgroundColor:'#FAFAFA' }}
            className="refer-and-earn-form-modal"
            >
                <Form 
                ref={formRef} 
                src={process.env.REACT_APP_REFER_AND_EARN_FORM} 
                options={referAndEarnFormOptions}
                onSubmitDone={(e)=>onSubmission(e)}
                />
                 <span className='refer-and-earn-tnc' onClick={()=>openTnC()}>Terms & Conditions</span>

            </Modal>
        </div>
    )
}
