import React, { forwardRef, useContext, useState, useEffect } from 'react'
import { Divider, Button, Select, Switch, Modal, Spin, notification, Input, InputNumber } from 'antd';
import { CheckCircleOutlined, LoadingOutlined, ExclamationCircleOutlined, CloseOutlined } from '@ant-design/icons';
import './RolePermissionsContainerStyle.scss'
import '../../../../../../css/globalStyles.scss'
import { getFeatureList, postFeatureControlChanges } from '../../../../../../service/accountService';
import { AppContext } from '../../../../../../App';
import axios from 'axios';
import { trackEvent } from '../../../../../../service/engagementMonitoringService';
import CONSTANTS from '../../../../../../constants/constants';

const RolePermissionsContainer = forwardRef(({ isModalOpen, closeModal }, ref) => {
    const { Option } = Select;
    const key = `open${Date.now()}`;
    const placement = 'topRight';
    const [adminKeyValue, setAdminKeyValue] = useState({
        key: "admin",
        value: "Admin",
    });
    const [teamLeaderKeyValue, setTeamLeaderKeyValue] = useState({
        key: "teamLeader",
        value: "Team Leads",
    });
    const [telecallerKeyValue, setTelecallerKeyValue] = useState({
        key: "teleCaller",
        value: "Telecallers",
    });
    const [selectedTab, setSelectedTab] = useState("admin")
    const [featuresList, setFeaturesList] = useState(
        {
            "roleWiseFeatureList": {
                "admin": {
                    "role": "",
                    "label": "",
                    "roleId": "",
                    "sectionList": [
                        {
                            "sectionLabel": "",
                            "featuresList": [
                                {
                                    "id": "",
                                    "label": "",
                                    "type": "",
                                    "config": {
                                    }
                                },
                            ]
                        },
                    ]
                }
            }
        }
    )
    const [isUpdated, setIsUpdated] = useState(false)
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
    const [updatedFeatureList, setUpdatedFeatureList] = useState([])
    const { mitraReducer } = useContext(AppContext);
    const [updatingFeatureList, setUpdatingFeatureList] = useState(false)
    const [autodialerTimeErr, setAutodialerTimeErr] = useState(false)
    const [callStatusAutoWrapErr, setCallStatusAutoWrapErr] = useState(false)
    const [autodialerTimeKey, setAutodialerTimeKey] = useState("autodialerTime")
    const [callStatusAutoWrapKey, setCallStatusAutoWrapKey] = useState("callStatusAutowrapTime")


    useEffect(() => {
        fetchFeaturesList()
    }, [])

    const fetchFeaturesList = () => {
        getFeatureList().then((featureListRes) => {
            if (featureListRes?.data?.roleWiseFeatureList) {
                setFeaturesList(featureListRes.data)
                // setUpdatedFeatureList(featureListRes.data)
                setAdminKeyValue({ ...adminKeyValue, key: featureListRes?.data?.roleWiseFeatureList[adminKeyValue.key]?.role, value: featureListRes?.data?.roleWiseFeatureList[adminKeyValue.key]?.label })
                setTeamLeaderKeyValue({ ...teamLeaderKeyValue, key: featureListRes?.data?.roleWiseFeatureList[teamLeaderKeyValue.key]?.role, value: featureListRes?.data?.roleWiseFeatureList[teamLeaderKeyValue.key]?.label })
                setTelecallerKeyValue({ ...telecallerKeyValue, key: featureListRes?.data?.roleWiseFeatureList[telecallerKeyValue.key]?.role, value: featureListRes?.data?.roleWiseFeatureList[telecallerKeyValue.key]?.label })
            }
            else {
                notification.open({
                    message: "Could not fetch Permissions, please try again later",
                    key,
                    duration: 5,
                    placement,
                    icon: <ExclamationCircleOutlined className='red-negative' />,
                });
            }
        }).catch((err) => {
            notification.open({
                message: "Something went wrong",
                key,
                duration: 5,
                placement,
                icon: <ExclamationCircleOutlined className='red-negative' />,
            });
        })
    }

    const changeRole = (role) => {
        setSelectedTab(role)
    }

    const renderFeatureList = (sectionList = [{
        "sectionLabel": "",
        "featuresList": [
            {
                "id": "",
                "label": "",
                "type": "",
                "config": {}
            }
        ]
    }], role) => {
        return (
            sectionList && sectionList.length > 0 ?
                sectionList.map((feature, featureInd) => {
                    return (
                        <div key={featureInd} className='rpc-feature-parent'>
                            <div className='rpc-feature-header bold14-22 d-flex'>{feature.sectionLabel}</div>
                            {
                                feature && feature.featuresList ?
                                    <ul className='rpc-feature-item-list'>
                                        {
                                            feature?.featuresList?.map((featureDescription, featureDescriptionInd) => {
                                                return (
                                                    <li key={featureDescriptionInd} className='rpc-feature-item w-100'>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <div className='d-flex regular14-22'>
                                                                {featureDescription?.label}
                                                            </div>
                                                            <div className='rpc-feature-value'>
                                                                {
                                                                    featureDescription.type == "boolean" ?
                                                                        <Switch onChange={() => changeFeatureAccess(role, feature, featureInd, featureDescription, featureDescriptionInd)} checked={featureDescription?.value} disabled={featureDescription?.config?.disable} />
                                                                        :
                                                                        featureDescription.type == "dropdown" ?
                                                                            <Select
                                                                                className='rpc-feature-dropdown'
                                                                                placeholder={`Select`}
                                                                                onChange={(featureToChange) => { changeFeatureAccess(role, feature, featureInd, featureDescription, featureDescriptionInd, featureToChange) }}
                                                                                value={featureDescription?.value}
                                                                            >
                                                                                {
                                                                                    featureDescription?.config?.options?.map((option) => {
                                                                                        return (
                                                                                            <Option value={option?.key} key={option?.key}>{option?.value}</Option>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Select>
                                                                            :
                                                                            featureDescription.type == "input" ?
                                                                                <div className='d-flex flex-dir-col rpc-num-inp align-items-end'>
                                                                                    <InputNumber
                                                                                        type='number'
                                                                                        onChange={(inp) => { changeFeatureAccess(role, feature, featureInd, featureDescription, featureDescriptionInd, inp) }}
                                                                                        value={featureDescription?.value}
                                                                                        defaultValue={featureDescription?.value} />
                                                                                    <div className='semibold12-20 red-negative rpc-num-inp-err'>
                                                                                        {
                                                                                            featureDescription?.key == autodialerTimeKey && autodialerTimeErr
                                                                                                ? "Please enter a value between 1-20 seconds"
                                                                                                : featureDescription?.key == callStatusAutoWrapKey && callStatusAutoWrapErr ?
                                                                                                    "Please enter a value between 5-30 seconds"
                                                                                                    : ""
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                // Default toggle on and disabled
                                                                                <Switch checked={true} disabled={true} />
                                                                }
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                    :
                                    null
                            }
                        </div>
                    )
                })
                :
                null
        )
    }

    const changeFeatureAccess = (role, feature, featureInd, featureDescription, featureDescriptionInd, featureToChange) => {
        setIsUpdated(true);
        let tempFeatureList = featuresList;
        // let tempFeatureList = updatedFeatureList;
        switch (tempFeatureList?.roleWiseFeatureList[role]?.sectionList[featureInd]?.featuresList[featureDescriptionInd]?.type) {
            case "dropdown":
                renderDropdown(tempFeatureList, role, featureInd, featureDescription, featureDescriptionInd, featureToChange)
                break;
            case "boolean":
                renderBoolean(tempFeatureList, role, featureInd, featureDescription, featureDescriptionInd, featureToChange)
                break;
            case "input":
                renderInput(tempFeatureList, role, featureInd, featureDescription, featureDescriptionInd, featureToChange)
                break;
        }
    }

    const renderDropdown = (tempFeatureList, role, featureInd, featureDescription, featureDescriptionInd, featureToChange) => {
        for (let i = 0; i < tempFeatureList.roleWiseFeatureList[role].sectionList[featureInd].featuresList[featureDescriptionInd]?.config?.options?.length; i++) {
            if (tempFeatureList?.roleWiseFeatureList[role]?.sectionList[featureInd]?.featuresList[featureDescriptionInd]?.config?.options[i]?.key == featureToChange) {
                // tempFeatureList.roleWiseFeatureList[role].sectionList[featureInd].featuresList[featureDescriptionInd].config.selectedOption = featureDescription?.config?.options[i]
                tempFeatureList.roleWiseFeatureList[role].sectionList[featureInd].featuresList[featureDescriptionInd].value = featureDescription?.config?.options[i]
                setFeaturesList(JSON.parse(JSON.stringify(tempFeatureList)))
                // setUpdatedFeatureList(JSON.parse(JSON.stringify(tempFeatureList)))
                const updatedFeatureConfig = {
                    featureId: tempFeatureList?.roleWiseFeatureList[role]?.sectionList[featureInd]?.featuresList[featureDescriptionInd]?.id,
                    // isEnable will always be true for dropdown
                    isEnable: true,
                    value: featureDescription?.config?.options[i].key,
                    targetMitraId: mitraReducer?.mitraInfo?.id,
                    roleId: tempFeatureList?.roleWiseFeatureList[role].roleId
                }
                createUpdatedFeaturesList(updatedFeatureConfig, "dropdown")
                break;
            }
        }
    }

    const renderBoolean = (tempFeatureList, role, featureInd, featureDescription, featureDescriptionInd, featureToChange) => {
        tempFeatureList.roleWiseFeatureList[role].sectionList[featureInd].featuresList[featureDescriptionInd].config.isEnabled = !tempFeatureList.roleWiseFeatureList[role].sectionList[featureInd].featuresList[featureDescriptionInd].value
        tempFeatureList.roleWiseFeatureList[role].sectionList[featureInd].featuresList[featureDescriptionInd].value = !tempFeatureList.roleWiseFeatureList[role].sectionList[featureInd].featuresList[featureDescriptionInd].value
        setFeaturesList(JSON.parse(JSON.stringify(tempFeatureList)))
        const updatedFeatureConfig = {
            featureId: tempFeatureList?.roleWiseFeatureList[role]?.sectionList[featureInd]?.featuresList[featureDescriptionInd]?.id,
            isEnable: tempFeatureList?.roleWiseFeatureList[role]?.sectionList[featureInd]?.featuresList[featureDescriptionInd]?.config?.isEnabled,
            value: tempFeatureList?.roleWiseFeatureList[role]?.sectionList[featureInd]?.featuresList[featureDescriptionInd]?.value,
            targetMitraId: mitraReducer?.mitraInfo?.id,
            roleId: tempFeatureList?.roleWiseFeatureList[role].roleId
        }
        createUpdatedFeaturesList(updatedFeatureConfig, "boolean")
    }

    const renderInput = (tempFeatureList, role, featureInd, featureDescription, featureDescriptionInd, featureToChange) => {
        if (tempFeatureList?.roleWiseFeatureList[role]?.sectionList[featureInd]?.featuresList[featureDescriptionInd]?.key == autodialerTimeKey) {
            featureToChange < 1 || featureToChange > 20 ? setAutodialerTimeErr(true) : setAutodialerTimeErr(false)
        }

        if (tempFeatureList?.roleWiseFeatureList[role]?.sectionList[featureInd]?.featuresList[featureDescriptionInd]?.key == callStatusAutoWrapKey) {
            featureToChange < 5 || featureToChange > 30 ? setCallStatusAutoWrapErr(true) : setCallStatusAutoWrapErr(false)
        }
        tempFeatureList.roleWiseFeatureList[role].sectionList[featureInd].featuresList[featureDescriptionInd].value = featureToChange
        setFeaturesList(JSON.parse(JSON.stringify(tempFeatureList)))
        const updatedFeatureConfig = {
            featureId: tempFeatureList?.roleWiseFeatureList[role]?.sectionList[featureInd]?.featuresList[featureDescriptionInd]?.id,
            // isEnable: tempFeatureList?.roleWiseFeatureList[role]?.sectionList[featureInd]?.featuresList[featureDescriptionInd]?.config?.isEnabled,
            // isEnable will always be true for input
            isEnable: true,
            value: parseInt(featureDescription?.value),
            targetMitraId: mitraReducer?.mitraInfo?.id,
            roleId: tempFeatureList?.roleWiseFeatureList[role].roleId
        }
        createUpdatedFeaturesList(updatedFeatureConfig, "input")
    }

    const createUpdatedFeaturesList = (featureConfig, type) => {
        let tempUpdatedList = updatedFeatureList
        if (type == "boolean") {
            let featureExistsInList = false
            for (let i = 0; i < tempUpdatedList.length; i++) {
                if (tempUpdatedList[i].featureId == featureConfig.featureId && tempUpdatedList[i].roleId == featureConfig.roleId) {
                    featureExistsInList = true
                    tempUpdatedList[i].isEnable = featureConfig.isEnable;
                    tempUpdatedList[i].value = featureConfig.isEnable;
                    break;
                }
            }

            if (!featureExistsInList) {
                tempUpdatedList.push(featureConfig);
            }
        }
        else if (type == "dropdown") {
            let featureExistsInList = false
            for (let i = 0; i < tempUpdatedList.length; i++) {
                if (tempUpdatedList[i].featureId == featureConfig.featureId && tempUpdatedList[i].roleId == featureConfig.roleId) {
                    featureExistsInList = true
                    tempUpdatedList[i].value = featureConfig.value
                }
            }
            if (!featureExistsInList) {
                tempUpdatedList.push(featureConfig);
            }
        }
        else if (type == "input") {
            let featureExistsInList = false
            for (let i = 0; i < tempUpdatedList.length; i++) {
                if (tempUpdatedList[i].featureId == featureConfig.featureId && tempUpdatedList[i].roleId == featureConfig.roleId) {
                    featureExistsInList = true
                    tempUpdatedList[i].value = featureConfig.value
                }
            }
            if (!featureExistsInList) {
                tempUpdatedList.push(featureConfig);
            }
        }
        setUpdatedFeatureList(JSON.parse(JSON.stringify(tempUpdatedList)))
    }

    const onCancel = () => {
        setIsUpdated(false)
        closeModal()
    }

    const onUpdateFeatureList = () => {
        setIsConfirmModalOpen(true)
    }

    const postFeatureListChanges = () => {
        setUpdatingFeatureList(true)
        postFeatureControlChanges(updatedFeatureList).then((featureChangesRes) => {
            setIsConfirmModalOpen(false)
            setUpdatingFeatureList(false)
            if (featureChangesRes?.data?.status) {
                trackEvent('updated_role_permissions_successfully', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                notification.open({
                    message: "Permissions updated successfully!",
                    key,
                    duration: 5,
                    placement,
                    icon: <CheckCircleOutlined className='green-positive' />,
                });
            }
            else {
                trackEvent('update_role_permissions_unsuccessfull', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                notification.open({
                    message: "Permissions could not be updated right now.",
                    key,
                    duration: 5,
                    placement,
                    icon: <ExclamationCircleOutlined className='red-negative' />,
                });
            }
        })
            .catch((err) => {
                setUpdatingFeatureList(false)
                setIsConfirmModalOpen(false)
                notification.open({
                    message: "Permissions could not be updated right now.",
                    key,
                    duration: 5,
                    placement,
                    icon: <ExclamationCircleOutlined className='red-negative' />,
                });
            })
    }

    return (
        <Modal
            title={null}
            centered
            visible={isModalOpen}
            footer={null}
            width={600}
            className="role-permissions-modal-parent"
            onCancel={closeModal}
        >
            <div className='role-permissions-container-parent'>

                <div className='rpc-header d-flex justify-content-between align-items-center'>
                    <div className='rpc-header-title d-flex justify-content-between align-items-center'>
                        <div className='d-flex align-items-center'>
                            <span className='rpc-header-txt'>
                                Role Permissions
                            </span>
                        </div>
                    </div>
                    <div className='rpc-header-close jp-cp' onClick={closeModal}>
                        <CloseOutlined className='rpc-header-close-icon' />
                    </div>
                </div>

                <Divider />

                <div className='gs-tabs-section d-flex justify-content-between'>
                    <div className='gs-tabs d-flex show-only-desktop'>
                        <Button onClick={() => changeRole(adminKeyValue.key)} className={selectedTab == adminKeyValue.key ? "gs-selected-tab" : "gs-unselected-tab"}>
                            {adminKeyValue.value}
                        </Button>
                        <Button onClick={() => changeRole(teamLeaderKeyValue.key)} className={selectedTab == teamLeaderKeyValue.key ? "gs-selected-tab" : "gs-unselected-tab"}>
                            {teamLeaderKeyValue.value}
                        </Button>
                        <Button onClick={() => changeRole(telecallerKeyValue.key)} className={selectedTab == telecallerKeyValue.key ? "gs-selected-tab" : "agsunselected-tab"}>
                            {telecallerKeyValue.value}
                        </Button>
                    </div>

                    <div className='show-only-mobile gs-tabs-container-mobile'>
                        <div className='gs-tabs d-flex w-50'>
                            <Select
                                className='rpc-section-dropdown w-100'
                                placeholder="Select Role"
                                onChange={(role) => { changeRole(role) }}
                                value={selectedTab}
                            >
                                <Option value={adminKeyValue.key} key={adminKeyValue.key}>{adminKeyValue.value}</Option>
                                <Option value={teamLeaderKeyValue.key} key={teamLeaderKeyValue.key}>{teamLeaderKeyValue.value}</Option>
                                <Option value={telecallerKeyValue.key} key={telecallerKeyValue.key}>{telecallerKeyValue.value}</Option>
                            </Select>
                        </div>
                    </div>
                </div>

                <div className='rpc-features-list'>
                    {
                        featuresList && Object.keys(featuresList).length ?
                            (
                                selectedTab && selectedTab == adminKeyValue.key ?
                                    renderFeatureList(featuresList.roleWiseFeatureList?.admin?.sectionList, adminKeyValue.key) :
                                    selectedTab && selectedTab == teamLeaderKeyValue.key ?
                                        renderFeatureList(featuresList.roleWiseFeatureList?.teamLeader?.sectionList, teamLeaderKeyValue.key) :
                                        renderFeatureList(featuresList.roleWiseFeatureList?.teleCaller?.sectionList, telecallerKeyValue.key)
                            )
                            :
                            null
                    }
                </div>

                <div className='rpc-footer d-flex justify-content-end align-items-center'>
                    {
                        <div className='rpc-footer-btns-container d-flex w-50 justify-content-end align-items-center'>
                            <span onClick={() => onCancel()} className='rpc-cancel-btn primary-orange2 bold16-24 jp-cp'>
                                Cancel
                            </span>
                            <Button onClick={() => onUpdateFeatureList()} className='vl-primary-btn rpc-submit-btn' disabled={!isUpdated || autodialerTimeErr || callStatusAutoWrapErr}>
                                <span className='plain-white bold16-24'>
                                    Save
                                </span>
                            </Button>

                        </div>
                    }
                </div>

                <Modal
                    title={null}
                    centered
                    visible={isConfirmModalOpen}
                    footer={null}
                    width={450}
                    className="rpc-confirm-modal-parent"
                    onCancel={() => setIsConfirmModalOpen(false)}
                >
                    <div className='rpc-confirm-modal-content'>
                        <div className='rpc-confirm-modal-header d-flex justify-content-between align-items-center'>
                            <div className='rpc-confirm-modal-header-title d-flex justify-content-between align-items-center'>
                                <div className='d-flex align-items-center'>
                                    <span className='rpc-confirm-modal-header-txt bold14-22'>
                                        Confirm change?
                                    </span>
                                </div>
                            </div>
                            <div className='rpc-confirm-modal-header-close jp-cp' onClick={() => setIsConfirmModalOpen(false)}>
                                <CloseOutlined className='rpc-confirm-modal-header-close-icon' />
                            </div>
                        </div>

                        <Divider />

                        <div className='rpc-confirm-modal-body regular14-22'>
                            Are you sure you want to make these changes to Role Permissions?
                        </div>

                        <Divider />

                        <div className='rpc-confirm-modal-btns-container d-flex justify-content-end'>
                            <span onClick={() => setIsConfirmModalOpen(false)} className='rpc-confirm-modal-cancel-btn primary-orange2 bold16-24 jp-cp'>
                                Cancel
                            </span>
                            <Button onClick={() => postFeatureListChanges()} className='vl-primary-btn rpc-confirm-modal-confirm-btn d-flex align-items-center'>
                                <span className='bold16-24 plain-white'>Confirm</span>
                                {
                                    updatingFeatureList ?
                                        <span><Spin indicator={<LoadingOutlined className='rpc-confirm-btn-loader' spin />} /></span>
                                        :
                                        null
                                }
                            </Button>

                        </div>
                    </div>
                </Modal>
            </div>
        </Modal>
    )
})

export default RolePermissionsContainer; 