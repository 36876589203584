import React, { useContext, useEffect, useState } from 'react'
import './styles/AdditionalInfoScreen.css';
import ZomatoOTP from './components/ZomatoOTP';
import VehicleTypeSelection from './components/VehicleTypeSelection';
import CitySelection from './components/CitySelection';
import ZoneSelection from './components/ZoneSelection';
import VerificationStep from './components/VerificationStep';
import UploadSelfie from './components/UploadSelfie';
import { RouterPrompt } from '../../RouterPrompt/RouterPrompt';
import commonFunction from '../../../utils/commonFunction';
import { useHistory } from "react-router-dom";
import { getCandidateInfo, getZomatoObSubmissionData, validateZomatoUser } from '../../../service/additionalInfoService';
import {CheckOutlined} from '@ant-design/icons'
import { message, notification } from 'antd';
import { AppContext } from '../../../App';
import AppDownload from './components/AppDownload';

function AdditionalInfoScreen() {
    const [currentTab, setCurrentTab] = useState();
    const [showPrompt, setShowPrompt] = useState(true);
    const history = useHistory();
    const [leadAdditionalDetails, setLeadAdditionalDetails] = useState({});
    const [lead] = useState(commonFunction.getAllsearchParams(history.location.search))
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const {mitraReducer,mitraDispatch, setAlert,setSpin } = useContext(AppContext);
    const [isInitialOtpVerificationCheck, setIsInitialOtpVerificationCheck] = useState(true);
    const [stepCompletionDataForBanner, setStepCompletionDataForBanner] = useState();
    /*
    lead variable contains the following info
        companyCity: "Pune",
        companyName: "Zomato"
        jobDemandId: "300e4189-66e4-46ac-a2b6-99d262934c86"
        jobId: "0001567a-886a-44dc-89f8-7c53911ec2e4"
        meta: "null"
        name: "Test"
        phoneNumber: "5235234234"
        scheduledInterviewId: "d0c2bc8c-a499-440c-bc93-27aab2db8649"
    */
    const [tabInfo, setTabInfo] = useState([
        {
            id: 'otpVerification',
            label: 'OTP Verification',
            status: 'pending',
            isClickable: true // This will be used when otp expires
        },
        {
            id: 'vehicleType',
            label: 'Vehicle Type',
            status: 'pending',
            isClickable: true
        },
        {
            id: 'city',
            label: 'City',
            status: 'pending',
            isClickable: true
        },
        {
            id: 'zone',
            label: 'Zone',
            status: 'pending',
            isClickable: true
        },
        {
            id: 'verification',
            label: 'Document Upload',
            status: 'pending',
            isClickable: true
        },
        {
            id: 'selfieUpload',
            label: 'Selfie Upload',
            status: 'pending',
            isClickable: true
        },
        {
            id: 'appDownload',
            label: 'App Download',
            status: 'pending',
            isClickable: true
        }
    ]);

    const showLoader = (value) => {
        setSpin({
            loading: value, //false or true when actived
            delay: null,
            tip: 'Loading...'  //Loading copy here
        })
    }

    const onTabClick = (item) => {
        if(item.status === 'pending') return;

        if(!item.isClickable) {
            notification['error'] ({
                message: 'Please verify the OTP to continue'
            });
            return;
        }

        const newTabInfo = [...tabInfo];

        for (let i = 0; i < newTabInfo.length; i++) {
            if(newTabInfo[i].id === item.id) {
                newTabInfo[i].status = 'inProgress';
                setCurrentTab(newTabInfo[i].id);
            } else {
                if(newTabInfo[i].status === 'inProgress') {
                    newTabInfo[i].status = 'done';
                }
            }
        }

        setTabInfo([...newTabInfo]);
    };

    async function getSubmissionDetails() {
        if(lead.phoneNumber) {
            const payload = {
                phoneNumber: lead.phoneNumber,
                source: 'mitra-leader',
                mlName: mitraReducer?.mitraInfo?.name
            };
            const response = await getZomatoObSubmissionData(payload);
    
            return response.data;
        }
    }

    const goToNextScreen = async() => {
        const newTabInfo = [...tabInfo];

        const submissionData = await getSubmissionDetails();
        setStepCompletionDataForBanner(submissionData);

        for (let i = 0; i < newTabInfo.length; i++) {
            if(newTabInfo[i].status === 'inProgress' && (i+1 < newTabInfo.length)) {
                newTabInfo[i].status = 'done';
                newTabInfo[i+1].status = 'inProgress';
                newTabInfo[i+1].isClickable = 'true';
                setCurrentTab(newTabInfo[i+1].id);
                break;
            }
        }

        setTabInfo([...newTabInfo]);
    };

    const goToPreviousScreen = () => {
        const newTabInfo = [...tabInfo];

        for (let i = 0; i < newTabInfo.length; i++) {
            if(newTabInfo[i].status === 'inProgress' && (i-1 > 0)) {
                newTabInfo[i].status = 'done';
                newTabInfo[i-1].status = 'inProgress';
                setCurrentTab(newTabInfo[i-1].id);
                break;
            }
        }

        setTabInfo([...newTabInfo]);
    }

    const getLeadInformation = async() => {
        const populatedRes = await getCandidateInfo(lead.phoneNumber);

        const populatedData = {}

        populatedRes.data.forEach(el => {
            try {
                const parsedValue = JSON.parse(el.value)
                if (typeof parsedValue === "number")
                    populatedData[el.type] = `${parsedValue}`
                else
                    populatedData[el.type] = [{ ...parsedValue, originalName: parsedValue.url, storage: "url" }]
            } catch (error) {
                populatedData[el.type] = el.value
            }
        });

        console.log(populatedData, 'leadAdditionalDetails');

        setLeadAdditionalDetails(populatedData);
    }

    // const determineCurrentStep = () => {
    //     const zomatoObStepsDetails = leadAdditionalDetails?.zomatoAppObFlowStatus?.[0];

    //     showLoader(true);

    //     if(zomatoObStepsDetails && Object.keys(zomatoObStepsDetails).length) {
    //         // logic here
    //         let currentStep = '';

    //         if (!((zomatoObStepsDetails?.vehicleType?.status === 'Sent to Zomato' && leadAdditionalDetails?.zomatoVehicleType === "Motorcycle") || (zomatoObStepsDetails?.vehicleType?.status === 'Sent to Zomato' && leadAdditionalDetails?.zomatoVehicleType === "Electric scooter" && zomatoObStepsDetails?.evProofImage?.status === 'Sent to Zomato' && zomatoObStepsDetails?.evDetails?.status === 'Sent to Zomato'))) currentStep = 'vehicleType';
    //         else if (zomatoObStepsDetails?.city?.status !== 'Sent to Zomato') currentStep = 'city';
    //         else if (zomatoObStepsDetails?.zone?.status !== 'Sent to Zomato') currentStep = 'zone';
    //         else if (!(zomatoObStepsDetails?.addressDetails?.status === 'Sent to Zomato' && zomatoObStepsDetails?.addressProofImage?.status === 'Sent to Zomato' && zomatoObStepsDetails?.panImage?.status === 'Sent to Zomato' && zomatoObStepsDetails?.panDetails?.status === 'Sent to Zomato') && (zomatoObStepsDetails?.digiLockerStatus?.status !== 'COMPLETED')) currentStep = 'verification';
    //         else if (zomatoObStepsDetails?.selfieImage?.status !== 'Sent to Zomato') currentStep = 'selfieUpload';
    //         else currentStep = 'appDownload';

    //         const updatedTabInfo = [...tabInfo];

    //         console.log(currentStep);

    //         let currentStepStatusIsInProgress = false;
    //         for (let i = 0; i < updatedTabInfo.length; i++) {
    //             if(updatedTabInfo[i].id === currentStep) {
    //                 updatedTabInfo[i].status = 'inProgress';
    //                 currentStepStatusIsInProgress = true;
    //                 setCurrentTab(updatedTabInfo[i].id);
    //             } else {
    //                 if(currentStepStatusIsInProgress) {
    //                     // this means we are checking tabs which comes after current tab
    //                     // meaning they are still pending
    //                     updatedTabInfo[i].status = 'pending';
    //                     updatedTabInfo[i].isClickable = false;
    //                 } else {
    //                     // this means that the current item is not the current step
    //                     // and is completed because we havent yet encountered 'currentStepStatusIsInProgress'
    //                     updatedTabInfo[i].status = 'done';
    //                     updatedTabInfo[i].isClickable = true;
    //                 }
    //             }
    //         }

    //         setTabInfo([...updatedTabInfo]);
    //         showLoader(false);
    //     } else {
    //         let currentStep = 'vehicleType';

    //         const updatedTabInfo = [...tabInfo];

    //         let currentStepStatusIsInProgress = false;
    //         for (let i = 0; i < updatedTabInfo.length; i++) {
    //             if(updatedTabInfo[i].id === currentStep) {
    //                 updatedTabInfo[i].status = 'inProgress';
    //                 currentStepStatusIsInProgress = true;
    //                 setCurrentTab(updatedTabInfo[i].id);
    //             } else {
    //                 if(currentStepStatusIsInProgress) {
    //                     // this means we are checking tabs which comes after current tab
    //                     // meaning they are still pending
    //                     updatedTabInfo[i].status = 'pending';
    //                     updatedTabInfo[i].isClickable = false;
    //                 } else {
    //                     // this means that the current item is not the current step
    //                     // and is completed because we havent yet encountered 'currentStepStatusIsInProgress'
    //                     updatedTabInfo[i].status = 'done';
    //                     updatedTabInfo[i].isClickable = true;
    //                 }
    //             }
    //         }

    //         setTabInfo([...updatedTabInfo]);
    //         showLoader(false);
    //     }
    // }

    const otpVerificationFailedProcess = () => {
        setIsOtpVerified(false);

        // make current step as otp step, disable all the tab clicks
        const newTabInfo = [...tabInfo];

        for (let i = 0; i < newTabInfo.length; i++) {
            if(newTabInfo[i].id === 'otpVerification') {
                newTabInfo[i].status = 'inProgress';
                setCurrentTab(newTabInfo[i].id);
            } else {
                newTabInfo[i].status = 'pending';
                newTabInfo[i].isClickable = false;
            }
        }

        setTabInfo([...newTabInfo]);
    }

    const getOtpValidationStatus = async() => {

        try {
        
        const response = await validateZomatoUser(lead);

        if (response.data && response.data.statusCode == 200) {
            // here we will check the current application step of the lead
        setIsOtpVerified(true);
} else {
        otpVerificationFailedProcess();
        }
    }catch(e) {
        otpVerificationFailedProcess();
    }

    }

    const determineCurrentStep = async() => {
        showLoader(true);

        const submissionData = await getSubmissionDetails();
        setStepCompletionDataForBanner(submissionData);

        const currentStep = submissionData.currentStep;

        let currentStepStatusIsInProgress = false;
        const updatedTabInfo = [...tabInfo];

        for (let i = 0; i < updatedTabInfo.length; i++) {
            if(updatedTabInfo[i].id === currentStep) {
                updatedTabInfo[i].status = 'inProgress';
                currentStepStatusIsInProgress = true;
                setCurrentTab(updatedTabInfo[i].id);
            } else {
                if(currentStepStatusIsInProgress) {
                    // this means we are checking tabs which comes after current tab
                    // meaning they are still pending
                    updatedTabInfo[i].status = 'pending';
                    updatedTabInfo[i].isClickable = false;
                } else {
                    // this means that the current item is not the current step
                    // and is completed because we havent yet encountered 'currentStepStatusIsInProgress'
                    updatedTabInfo[i].status = 'done';
                    updatedTabInfo[i].isClickable = true;
                }
            }
        }

        setTabInfo([...updatedTabInfo]);
        showLoader(false);
    }

    const showBannerForScreen = (stepName) => {
        if(stepCompletionDataForBanner) {
            const stepsWiseData = [...stepCompletionDataForBanner.steps];

            if(stepsWiseData.length) {
                for (let i = 0; i < stepsWiseData.length; i++) {
                    if( stepsWiseData[i].step === stepName) {
                        return stepsWiseData[i].source === 'user';
                    }
                }
            }
        }
        return false;
    }

    useEffect(() => {
        // Get all the lead information at first
        // check if the auth token is valid
        // if yes, then move the user to relevant page
        // if no, then move the user to otp page and disable all other pages

        if(lead) {
            getLeadInformation(); // Getting the information of the submitted details from our DB
            getOtpValidationStatus();
        }
    }, [lead])

    useEffect(() => {
      if(isInitialOtpVerificationCheck && isOtpVerified && leadAdditionalDetails && Object.keys(leadAdditionalDetails?.length || {})) {
        // Here if OTP is verified and we have the details to check the current step then call determineCurrentStep()
        determineCurrentStep();
        setIsInitialOtpVerificationCheck(false);
      }
    }, [isOtpVerified, leadAdditionalDetails])
    
    

  return (
    <div className="AdditionalInfoScreen mainContentZ">
        {
            <RouterPrompt
                when={showPrompt}
                title="Leave this page"
                cancelText="No"
                okText="Yes"
                onOK={() => true}
                onCancel={() => false}
                promptMessage= {"The lead has not yet been referred. Are you sure you want to leave the form?"}
            />
        }
        <div className='header-text'>Zomato Additional Form</div>
        <ul>
            <li style={{display: 'flex', textAlign: 'start', gap: '6px'}}>
                <b>Note:</b>
                <p>Please upload your candidates details and documents in this section. These will be directly submitted to Zomato. You can also upload the rider selfie image through this flow</p>
            </li>
        </ul>

        <div className="tab-container">
            {
                tabInfo.map((item, index) => {
                    return (
                        <div 
                        key={item.id} 
                        className={
                            `tab-item${item.status === 'inProgress' ? ' active': item.status === 'pending'? ' pending': ' done'}`
                        }
                        onClick={()=>onTabClick(item)}
                        >
                            {item.label} {item.status === 'done' ? <CheckOutlined style={{color: '#4BB543'}}/>: null}
                        </div>
                    )
                })
            }
        </div>

        <div className='form-content-container'>
            
            {
                currentTab === 'otpVerification' ? (
                    <ZomatoOTP 
                    goToNextScreen={goToNextScreen}
                    lead={lead}
                    isOtpVerified={isOtpVerified}
                    setIsOtpVerified={setIsOtpVerified}
                    determineCurrentStep={determineCurrentStep}
                    />
                ): null
            }

            {
                currentTab === 'vehicleType' ? (
                    <VehicleTypeSelection 
                    goToNextScreen={goToNextScreen}
                    lead={lead}
                    getLeadInformation={getLeadInformation}
                    leadAdditionalDetails={leadAdditionalDetails}
                    showBannerForScreen={showBannerForScreen}
                    />
                ): null
            }

            {
                currentTab === 'city' ? (
                    <CitySelection 
                    goToNextScreen={goToNextScreen}
                    lead={lead}
                    leadAdditionalDetails={leadAdditionalDetails}
                    getLeadInformation={getLeadInformation}
                    showBannerForScreen={showBannerForScreen}
                    />
                ): null
            }

            {
                currentTab === 'zone' ? (
                    <ZoneSelection 
                    goToNextScreen={goToNextScreen}
                    lead={lead}
                    leadAdditionalDetails={leadAdditionalDetails}
                    getLeadInformation={getLeadInformation}
                    showBannerForScreen={showBannerForScreen}
                    />
                ): null
            }

            {
                currentTab === 'verification' ? (
                    <VerificationStep 
                    goToNextScreen={goToNextScreen}
                    lead={lead}
                    leadAdditionalDetails={leadAdditionalDetails}
                    getLeadInformation={getLeadInformation}
                    showBannerForScreen={showBannerForScreen}
                    />
                ): null
            }

            {
                currentTab === 'selfieUpload' ? (
                    <UploadSelfie 
                    goToNextScreen={goToNextScreen}
                    lead={lead}
                    leadAdditionalDetails={leadAdditionalDetails}
                    getLeadInformation={getLeadInformation}
                    setShowPrompt={setShowPrompt}
                    showBannerForScreen={showBannerForScreen}
                    />
                ): null
            }

            {
                currentTab === 'appDownload' ? (
                    <AppDownload 
                    goToNextScreen={goToNextScreen}
                    lead={lead}
                    leadAdditionalDetails={leadAdditionalDetails}
                    getLeadInformation={getLeadInformation}
                    setShowPrompt={setShowPrompt}
                    />
                ): null
            }
            
        </div>
    </div>
  )
}

export default AdditionalInfoScreen
