import axios from "axios";
import axiosInstance from './interceptorService';

export const triggerRecruiterCall = ({fromNumber, toNumber, leadId, mitraId, managerMitraId, bridgeNumber,scheduledInterviewId}) => {
    const URL = process.env.REACT_APP_BACKEND_URL + '/triggerRecruiterCall';

    const formData = new FormData();
    formData.append('fromNumber', fromNumber);
    formData.append('toNumber', toNumber);
    formData.append('userId', leadId);
    formData.append('mitraId', mitraId);
    formData.append('managerMitraId', managerMitraId);
    formData.append('bridgeNumber', bridgeNumber);
    formData.append('scheduledInterviewId', scheduledInterviewId);

    return axiosInstance.post(URL, formData, { withCredentials: true });
};

export const getCallStatus = (callId) => {
    const URL = process.env.REACT_APP_BACKEND_URL + `/getCallStatus?callId=${callId}`;
    return axiosInstance.get(URL, { withCredentials: true });
};

export const addCallRemark = ({callId, comment, tag, isFollowUpNeeded,isCallConnected}) => {
    const URL = process.env.REACT_APP_BACKEND_URL + `/updateRecruiterCallLogs`;

    const formData = new FormData();
    formData.append('callId',callId);
    formData.append('comment',comment);
    formData.append('tag', tag);
    formData.append('isFollowUpNeeded', isFollowUpNeeded);
    formData.append('isCallConnected', isCallConnected);

    return axiosInstance.post(URL, formData, { withCredentials: true });
};

export const addCustomCallNotes = ({userId, recommendationHistoryId, mitraId, managerMitraId, comment, tag, isFollowUpNeeded, isCallConnected, receiverNumber, callerNumber}) => {
    const URL = process.env.REACT_APP_BACKEND_URL + `/addCustomCallNotes`;

    const formData = new FormData();
    formData.append('mitraId', mitraId);
    formData.append('userId', userId);
    formData.append('recommendationHistoryId', recommendationHistoryId);
    formData.append('managerMitraId',managerMitraId);
    formData.append('comment',comment);
    formData.append('tag', tag);
    formData.append('isFollowUpNeeded', isFollowUpNeeded);
    formData.append('callerNumber', callerNumber);
    formData.append('receiverNumber', receiverNumber);
    formData.append('isCallConnected', isCallConnected);

    return axiosInstance.post(URL, formData, { withCredentials: true });
};
export const getCallHistory = ({id, recommendationHistoryId, mitraId}) => {
    const URL = process.env.REACT_APP_BACKEND_URL + `/getRecruiterCallHistoryForLead?userId=${id}&recommendationHistoryId=${recommendationHistoryId}&mitraId=${mitraId}`;
    return axiosInstance.get(URL, { withCredentials: true });
};
export const getCallHistoryForWOReferral = ({mitraId, phoneNumber}) => {
    const URL = process.env.REACT_APP_BACKEND_URL + `/getRecruiterCallHistoryForLead?phoneNumber=${phoneNumber}&mitraId=${mitraId}`;
    return axiosInstance.get(URL, { withCredentials: true });
};

export const getLeadFollowUpStatus = (siId) => {
    const URL = process.env.REACT_APP_BACKEND_URL + `/getLeadFollowUpStatus?siId=${siId}`;
    return axiosInstance.get(URL, { withCredentials: true });
}

export const getCallNotification = () => {
   const URL = process.env.REACT_APP_BACKEND_URL + '/getNewIncomingCalls';
   return axiosInstance.get(URL, { withCredentials: true });
}

export const updateNotifiedIncomingCalls = () => {
    const URL = process.env.REACT_APP_BACKEND_URL + '/updateNotifiedIncomingCalls';
    return axiosInstance.post(URL, {}, { withCredentials: true });
 }

export const getRecentCallingNumbers = () => {
    const URL = process.env.REACT_APP_BACKEND_URL + '/getRecentCallingNumbers';
    return axiosInstance.get(URL, { withCredentials: true });
}
