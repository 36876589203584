import React, { useContext, useEffect, useState } from 'react'
import './SamvaadiniCallingConsent.css';
import { Button } from 'antd';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../App';
import { samvaadiniCallingConsent } from '../../service/commonService';

const LAYOUTS = {
    'CONSENT_FORM': 'CONSENT_FORM',
    'POST_SUBMISSION_LAYOUT': 'POST_SUBMISSION_LAYOUT'
}

function SamvaadiniCallingConsent() {

    const {mitraReducer, mitraDispatch,setAlert} = useContext(AppContext);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const clientName = params.get('clientName');
    const leadNumber = params.get('leadNumber');
    const leadName = params.get('leadName');

    const [currentLayout, setCurrentLayout] = useState(LAYOUTS.CONSENT_FORM);

    useEffect(() => {
      if (mitraReducer?.mitraInfo?.id && !mitraReducer?.mitraInfo?.hideSidebar) {
        const value = {...mitraReducer?.mitraInfo, hideSidebar: 'true'}
        mitraDispatch({type: 'LoginInfo', value: value})
      }
    }, [mitraReducer?.mitraInfo])
    

    const submitConsent = (value) => {
        const consent = value;
        try {
            const payload = {
                phoneNumber: leadNumber,
                type: consent
            }
            samvaadiniCallingConsent(payload);
        } catch(e) {
            console.log(e);
        }
        setCurrentLayout(LAYOUTS.POST_SUBMISSION_LAYOUT);
    }
    

  return (
    <div className='ai-calling-consent-main-container'>
        {
            currentLayout === LAYOUTS.CONSENT_FORM ? (
                <>
                    <div className="branding-info d-flex align-items-center justify-content-center">
                        <img src="/image/vahan-new-logo.png" style={{width: '35px'}}/>
                        <span className='bold20-28'>Vahan delivery job call</span>
                    </div>

                    <div className="referral-details d-flex flex-column">
                        <span className='semibold20-28'>Hi {leadName},</span>
                        <span className='semibold20-28'>You have been referred for a delivery Job with {clientName.toUpperCase()}.</span>
                    </div>

                    <div className="consent-copy semibold20-28">
                        Vahan will call you for the job onboarding
                    </div>

                    <div className="consent-btns-container d-flex flex-column">
                        <Button className='vl-primary-btn btn-pd' onClick={()=>submitConsent('optin')}>
                            <span className='bold18-26' style={{color: '#FFF'}}>I am interested</span>
                        </Button>

                        <Button className='vl-secondary-btn btn-pd' onClick={()=>submitConsent('optout')}>
                            <span className='bold18-26' style={{color: '#d34b18'}}>I am not interested</span>
                        </Button>
                    </div>
                </>
            ): (
                <>
                    <div className="d-flex align-items-center justify-content-center flex-column post-submission-ack">
                        <img src="/image/vahan-new-logo.png" style={{width: '95px'}}/>
                        <span className='bold20-28 ack-text'>Thank you!</span>
                    </div>
                </>
            )
        }
        
    </div>
  )
}

export default SamvaadiniCallingConsent