import React, { useContext, useEffect, useState } from 'react'
import { Button, Input, Select, Upload, notification } from 'antd';
import { UploadOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { AppContext } from '../../../../App';
import { uploadImageToS3 } from '../../../../service/additionalInfoService';
import { getBlinkitData, updateBlinkitData } from '../../../../service/BlinkitAdditionalFormService';

function BlinkitVehicleTypeSelection({lead,goToNextScreen}) {
    const {mitraReducer,mitraDispatch, setAlert,setSpin } = useContext(AppContext);
    const [selectedVehicleType, setSelectedVehicleType] = useState();
    const [selectedEVType, setSelectedEVType] = useState();
    const [selectedEVOwnershipType, setSelectedEVOwnershipType] = useState();
    const [showEvDetailsSection, setShowEvDetailsSection] = useState(false);
    const [evProof, setEvProof] = useState();
    const [vehicleTypeAndIdMap, setVehicleTypeIdMap] = useState({});

    const [vehicleTypeInfo, setVehicleTypeInfo] = useState([]);

    const [evTypeOptions, setevTypeOptions] = useState([
        {
            label: 'With number plate',
            value: 'With number plate'
        },
        {
            label: 'Without number plate',
            value: 'Without number plate'
        }
    ]);

    const [evOwnershipTypeOptions, setevOwnershipTypeOptions] = useState([
        {
            label: 'Owned',
            value: 'Owned'
        },
        {
            label: 'Rented',
            value: 'Rented'
        }
    ]);

    const handleChange = (item) => {
        // if(item === 'Electric scooter') {
        //     setShowEvDetailsSection(true);
        // } else {
        //     setShowEvDetailsSection(false);
        // }
        setSelectedVehicleType(item);
    };

    const handleEVTypeChange = (item) => {
        setSelectedEVType(item);
    };

    const handleEVOwnershipTypeChange = (item) => {
        setSelectedEVOwnershipType(item);
    };

    const showLoader = (value) => {
        setSpin({
            loading: value, //false or true when actived
            delay: null,
            tip: 'Uploading...'  //Loading copy here
        })
    }

    const checkErrors = (res) => {
        const newErrors = [];
        for (let key in res.data) {
            if (res.data[key] !== "200") {
                newErrors.push(`${key}: ${res.data[key]}`)
            }
        }
      
        if(newErrors.length) {
            // setErrors([...newErrors]);
            alert(newErrors.join("\n"))
            return true;
        } 
        return false;
      }

    const handleUpload =(info) => {
        showLoader(true);

        uploadImageToS3(info.file)
            .then(async(res)=> {
                showLoader(false);

                if(res?.data?.imageUrl) {
                    setEvProof(res.data.imageUrl);

                    // const submissionData = {
                    //     evProof: {
                    //         url: res.data.imageUrl,
                    //         originalName: info?.file?.name,
                    //         size: info?.file?.size
                    //     }
                    // }
        
                    // const response = await postAdditionalInfo(submissionData, mitraReducer?.mitraInfo, lead);

                    // checkErrors(response);
                    // setRiderHasCompletedTheStep(false);
            
                }
            }).catch(e=> {
                showLoader(false);

                notification['error']({
                    message: 'Image upload failed, please try again later.',
                  });

                console.log(e);
            });
    };

    const submitVehicleTypeInfo = async() => {
        if(!selectedVehicleType) {
            notification['error']({
                message: 'Please enter all the fields to continue',
              });
            return
        }

        showLoader(true);
        try {
            const payload = {
                phoneNumber: lead?.phoneNumber,
                userId: lead?.userId,
                mitraName: mitraReducer?.mitraInfo?.name,
                mitraId: mitraReducer?.mitraInfo?.id,
                dataToUpdate: {
                    updateVehicleType: {
                        vehicleId: vehicleTypeAndIdMap[selectedVehicleType],
                        blinkitVehicleType: selectedVehicleType
                    },
                }
            }

            const response = await updateBlinkitData(payload);

            if(response?.data?.updateVehicleType?.data?.status?.code === 200) {
                goToNextScreen();
            } else {
                notification['error'] ({
                    message: 'Error submitting vehicle details.'
                })
            }
            showLoader(false);
        } catch(err) {
            showLoader(false);
            notification['error'] ({
                message: 'Error submitting vehicle details.'
            })
        }
    }

    const handleBeforeUpload = (file) => {
        // Return false to prevent upload
        return false;
      };

    const fetchVehicleData = async() => {
        showLoader(true);

        try {
            // logic to fetch vehicle data
        const payload = {
            phoneNumber: lead?.phoneNumber,
            dataToFetch: {
                getVehicleType: {}
            }
        }

        const response = await getBlinkitData(payload);

        let evaluatedVehicleData = [];
        const idVehicleTypeMap = {};
        if(response?.data?.getVehicleType?.allVehicles?.length) {
            const vehicleTypes = response?.data?.getVehicleType?.allVehicles;

            for (let vehicle = 0; vehicle < vehicleTypes.length; vehicle++) {
                if(vehicleTypes[vehicle]?.title?.text && vehicleTypes[vehicle]?.title?.text!==`I don't have a vehicle`) {
                    evaluatedVehicleData.push({label: vehicleTypes[vehicle]?.title?.text, value: vehicleTypes[vehicle]?.title?.text});
                    idVehicleTypeMap[vehicleTypes[vehicle]?.title?.text] = vehicleTypes[vehicle]?.id;
                }

                if(vehicleTypes[vehicle]?.is_selected) {
                    setSelectedVehicleType(vehicleTypes[vehicle]?.title?.text);
                }
            }

            setVehicleTypeIdMap({...idVehicleTypeMap});

            setVehicleTypeInfo([...evaluatedVehicleData]);
            showLoader(false);
        } else {
            showLoader(false);
            notification['error']({
                message: 'Error fetching vehicle types, please try again later.'
            })
        }
        } catch (err) {
            showLoader(false);
            notification['error']({
                message: 'Error fetching vehicle types, please try again later.'
            })
        }
        
    }

    useEffect(() => {
        if(lead?.phoneNumber) {
            fetchVehicleData();
        }
    }, [lead])
    
    
  return (
    <>
        <div className='vehicle-type-container'>

            <div className='container-header'>Vehicle Details</div>

            <div className="vehicle-details-container">

                <div className='label'>Select vehicle type<sup>*</sup></div>
                <Select
                    style={{ width: '100%', textAlign: 'start', marginTop: '8px' }}
                    placeholder="Select vehicle type"
                    value={selectedVehicleType}
                    onChange={handleChange}
                    optionLabelProp="label"
                    options={vehicleTypeInfo}
                />

                {/* {
                    showEvDetailsSection ? (
                        <div className='ebike-details-container mt-24'>
                            <div className='label'>Select EV Type<sup>*</sup></div>
                            <Select
                                style={{ width: '100%', textAlign: 'start', marginTop: '8px' }}
                                placeholder="Select vehicle type"
                                value={selectedEVType}
                                onChange={handleEVTypeChange}
                                optionLabelProp="label"
                                options={evTypeOptions}
                            />

                            <div className='label mt-24'>Select EV Ownership Type<sup>*</sup></div>
                            <Select
                                style={{ width: '100%', textAlign: 'start', marginTop: '8px' }}
                                placeholder="Select vehicle type"
                                value={selectedEVOwnershipType}
                                onChange={handleEVOwnershipTypeChange}
                                optionLabelProp="label"
                                options={evOwnershipTypeOptions}
                            />

                            <div className="aadhar-steps-header" style={{marginTop: 28}}>Note</div>
                            <ul>
                                <li style={{textAlign: 'start'}}>Please make sure to upload the correct EV Proof to avoid unwanted rejections</li>
                            </ul>

                            <div className='label mt-24'>EV Proof<sup>*</sup></div>
                            {
                                evProof ? (
                                    <div style={{display: 'flex', gap: '8px'}}>
                                        <img src={evProof} width={150} height={150}/>
                                        <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>setEvProof(null)}>x</p>
                                    </div>
                                ) : (
                                    <Upload
                                        name="avatar"
                                        listType="picture-card"
                                        showUploadList={false}
                                        accept="image/*"
                                        onChange={handleUpload}
                                        beforeUpload={handleBeforeUpload}
                                        >
                                        <div>
                                            <Input type="file" accept="image/*" style={{ display: 'none' }} />
                                            <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                                        </div>
                                    </Upload>
                                )
                            }
                            
                        </div>
                    ): null
                } */}
            </div>

        </div>

        <div className='button-container'>
        <Button className='submit-button' onClick={()=>submitVehicleTypeInfo()}>Next</Button>
        </div>
    </>
  )
}

export default BlinkitVehicleTypeSelection
