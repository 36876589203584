import { Button, Select, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { getSwiggyProfileData, updateSwiggyData } from '../../../../service/SwiggyAdditionalInfoService';
import CONSTANTS from '../../../../constants/constants';

function DeliveryKitDetails({lead, goToNextScreen,  submissionDetails, setShowPrompt}) {
  const [selectedMerchSize, setSelectedMerchSize] = useState(null);
  const [merchSizeOptions, setMerchSizeOptions] = useState([]);
  const [itemId, setItemId] = useState(null);
  const history = useHistory();

  const fetchDataFromFallbackResponse = () => {
    const fallbackResponse = CONSTANTS.swiggyTshirtSizeOptionFallbackResponse;
    const options = fallbackResponse.price_details;

    setItemId(fallbackResponse?.item_id);

    for (let i = 0; i < options.length; i++) {
      options[i].label = options[i].size;
      options[i].value = options[i].size;
    }
    setMerchSizeOptions([...options]);
  }

  const getSizeOptions = async() => {
    if(lead?.phoneNumber) {
      try {
        const payload = {
          phoneNumber: lead?.phoneNumber,
          dataToFetch: {
            "getInventoryDetails":{}
          }
        }
  
        const response = await getSwiggyProfileData(payload);

        if(response?.data?.data?.getInventoryDetails) {
          const inventoryDetails = response?.data?.data?.getInventoryDetails;

          for (const key of Object.keys(inventoryDetails)) {
            if(inventoryDetails[key].item_name === 'T-Shirt') {
              const options = inventoryDetails[key].price_details;

              setItemId(inventoryDetails[key]?.item_id);
      
              for (let i = 0; i < options.length; i++) {
                options[i].label = options[i].size;
                options[i].value = options[i].size;
              }
              setMerchSizeOptions([...options]);
            }
          }
        } else {
          fetchDataFromFallbackResponse();
        }
  
        // if(response?.data?.data?.getInventoryDetails?.["1"].price_details?.length) {
        //   const options = response?.data?.data?.getInventoryDetails?.["1"].price_details;

        //   setItemId(response?.data?.data?.getInventoryDetails?.["1"]?.item_id);
  
        //   for (let i = 0; i < options.length; i++) {
        //     options[i].label = options[i].size;
        //     options[i].value = options[i].size;
        //   }
        //   setMerchSizeOptions([...options]);
        // }
      } catch(e) {
        // notification['info']({
        //   message: 'Fetched stored T-shirt sizes.'
        // })
        fetchDataFromFallbackResponse();
      }
    }
  }

  const onSubmitTshirtSize = async() => {
    try {
      const payload = {
        phoneNumber: lead?.phoneNumber,
        "dataToUpdate" : {
          "postInventoryDetails": [
             {
              "size": selectedMerchSize,
              "item_id": itemId
              }
          ]
        }
      }

      const response = await updateSwiggyData(payload);

      if(response?.data?.data?.[0]?.swiggyTshirtDetails) {
        const responseDetails = response?.data?.data?.[0]?.swiggyTshirtDetails;

        if(responseDetails?.JFDataUpdateSuccess && responseDetails?.statusMessage?.toLowerCase() === 'success') {
          // successfully submitted
          history.replace({ pathname: "/recruiter-candidates" });
        }
      }
      // check the response and go to next page
    } catch(e) {
      notification['error']({
        message: 'Error submitting t-shirt size.'
      })
    }
  }

  useEffect(() => {
    if(lead?.phoneNumber) {
      getSizeOptions();
      if(setShowPrompt) {
        setShowPrompt(false);
      }
    }
  }, [lead])

  const autoPopulateIfDataExists = () => {
    if(submissionDetails?.swiggyElementMap?.swiggyTshirtDetails?.value) {
      const parsedTshirtDetails = JSON.parse(submissionDetails?.swiggyElementMap?.swiggyTshirtDetails?.value);

      if(parsedTshirtDetails?.size) {
        setSelectedMerchSize(parsedTshirtDetails?.size);
        setItemId(parsedTshirtDetails?.item_id);
      }
    }
  }

  useEffect(() => {
    autoPopulateIfDataExists();
  }, [submissionDetails])
  

  const onMerchSelect = (value, selectedObject) => {
    setSelectedMerchSize(value);
  }
  
  return (
    <>
      <div className='pan-verification-container'>
        <div className='container-header'>Tshirt size</div>
        <div className='pan-form-container'>

          <div className='label mt-24'>Tshirt size<sup>*</sup></div>
          <Select
              style={{ width: '100%', textAlign: 'start', marginTop: '8px' }}
              placeholder="Select Tshirt size"
              value={selectedMerchSize}
              onChange={(v,o)=>onMerchSelect(v,o)}
              optionLabelProp="label"
              options={merchSizeOptions}
          />
        </div>
      </div>

      <div className='button-container'>
          <Button className='submit-button' onClick={()=>onSubmitTshirtSize()}>Submit Form</Button>
      </div>
    </>
  )
}

export default DeliveryKitDetails
