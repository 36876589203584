import React, {useState, useEffect, useContext} from 'react';
import { DatePicker, Button, PageHeader, Modal } from 'antd';
import SubmitPopup from "./SubmitPopup.component";
import {useParams} from "react-router-dom";
import {useForm} from 'react-hook-form';
import * as jobDemandsService from '../../service/jobDemandsService';

const JobPostingPage = () => {
    const {posterId} = useParams();
    const {register} = useForm();
    const [submitText, setSubmitText] = useState('Your response has been submitted! One of our representatives will call you back shortly!')
	const [phoneNumberError, setPhoneNumberError] = useState(false)
	const [nameError, setNameError] = useState(false)
	const [APIError, setAPIError] = useState("")
	const [showAPIError, setShowAPIError] = useState(false)
	const [name, setName] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
    const [showSubmitPopup, setShowSubmitPopup] = useState(false);
	const [jobPostingImageUrl, setJobPostingImageUrl] = useState({});

	const handleInputChange = (event, input) => {
        if(input === 'name') {
            setName(event.target.value);
        } else {
            setPhoneNumber(event.target.value);
        }
	};

    useEffect(() => {
        getJobPostingImage();
    }, []);

    const getJobPostingImage = async () => {
		jobDemandsService.getJobPosterById(posterId).then(response => {
            if(response?.data?.data) {
			    setJobPostingImageUrl(response.data.data);
            }
		});
		return '';
	}
    const createJobPostingResponse = async (posterId, name, phoneNumber) => {
		try {
			if (!/^[a-zA-Z\s]+$/.test(name)) {
				setNameError(true)
				return
			}
			else {
				setNameError(false)
			}
			if (!/^\d{10}$/.test(phoneNumber)) {
				setPhoneNumberError(true);
				return;
			}
			else {
				setPhoneNumberError(false)
			}
			jobDemandsService.createJobPostingResponse(posterId, name, phoneNumber).then((result) => {
				if (result && result?.data?.success) {
                    setShowSubmitPopup(true);
				}
			})
				.catch(error => {
					console.log(error.response)
					if (error?.response?.data) {
						setShowAPIError(true)
						setAPIError(error.response.data.message)
					}
				})
		} catch (error) {
			console.log(error);
		}
	}
    return (
        <div className="jobs-page" style={{marginTop: '1em'}}>
            <div title='Preview poster' className="preview">
                {jobPostingImageUrl ? <img className="companyName" src={jobPostingImageUrl} /> : ''}

                <div className="input-fields">
                    {
                        nameError ?
                            <div className="error">Please enter name</div>
                            : null
                    }
                    <input
                        className="dropdown-control"
                        ref={register({required: true, minLength: 2})}
                        type="text"
                        placeholder="Enter your full name"
                        name="name"
                        onChange={(e) => {handleInputChange(e, 'name')}}
                        required
                    />
                    {
                        phoneNumberError ?
                            <div className="error">Please enter a valid phone number</div>
                            : null
                    }
                    <input
                        className="dropdown-control"
                        ref={register({required: true, minLength: 10, maxLength: 10})}
                        type="tel"
                        placeholder="Your mobile number"
                        name="phoneNumber"
                        onChange={(e) => {handleInputChange(e, 'phoneNumber')}}
                        required
                    />
                    <Button className="createViewWeblink" onClick={(e) => {createJobPostingResponse(posterId, name, phoneNumber);}} style={{width: '95%'}}>
                        Apply Now
                    </Button>
                    {
                        showAPIError ?
                            <div className="error">{APIError}</div>
                        : null
                    }
                </div>
            </div>
            <SubmitPopup visible={showSubmitPopup} setShowPopup={setShowSubmitPopup} text={submitText}></SubmitPopup>
        </div>
    )
};

export default JobPostingPage;
