import React, { useState, useEffect, useReducer, useContext } from 'react'
import { useHistory } from 'react-router-dom';
import { Skeleton, Modal, Select } from 'antd';
import {
    DownOutlined, CloseOutlined, RightOutlined, NotificationFilled, PaperClipOutlined, DeleteOutlined, PictureOutlined, CheckOutlined, CopyOutlined
} from '@ant-design/icons';
import './PitchModalStyle.scss'
import { getPitchByClient } from '../../../../service/jobDemandsService';
import { getDisplayableClientName } from '../../../../utils/commonFunction';
import { trackEvent } from '../../../../service/engagementMonitoringService';
import CONSTANTS from '../../../../constants/constants';

const { Option } = Select;

const PitchModal = ({ isModalOpen, companyDetails = {
    logo: "",
    companyName: "",
    companyKey: ""
}, closeModal }) => {
    const history = useHistory();
    const [pitchData, setPitchData] = useState({
        "salaryAmount": "",
        "salary": [],
        "earningPerOrder": "",
        "hours": [],
        "orders": [],
        "joiningFees": "",
        "joiningFeeCondition": "",
        "insuranceCover": "",
        "tips": [],
        "additionalBenefits": [],
        "offers": [
            {
                "offerKey": "",
                "offerList": []
            }
        ]
    })
    const [showOtherDetails, setShowOtherDetails] = useState(false);
    const [showTips, setShowTips] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (companyDetails && companyDetails.companyKey) {
            setLoading(true)
            getPitchByClient(companyDetails.companyKey).then((pitchRes) => {
                if (pitchRes && pitchRes.data && pitchRes.data.details) {
                    setPitchData(pitchRes.data.details)
                    setLoading(false)
                }
                else {
                    setPitchData(null)
                    setLoading(false)
                }
            })
        }
    }, [])

    const showOtherDetailsSection = (showDetails = false) => {
        setShowOtherDetails(showDetails)
        if (showDetails)
            trackEvent('clicked_pitch_other_details', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    }

    const showTipsSection = (showTips = false) => {
        setShowTips(showTips)
        if (showTips)
            trackEvent('clicked_pitch_tips_max_earnings', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    }

    return (
        <Modal
            title={null}
            centered
            visible={isModalOpen}
            footer={null}
            width={600}
            className="pitch-modal-parent"
            onCancel={closeModal}
        >
            <div className='pitch-modal-content'>
                <div className='pim-header d-flex justify-content-between align-items-center'>
                    <div className='pim-header-title d-flex justify-content-between align-items-center'>
                        <div className='d-flex align-items-center'>
                            <img src={companyDetails.logo} alt="comp_logo" className='pim-comp-logo' />
                            <span className='pim-header-txt'>
                                Why {getDisplayableClientName(companyDetails.companyName)}?
                            </span>
                        </div>
                    </div>
                    <div className='pim-header-close jp-cp' onClick={closeModal}>
                        <CloseOutlined className='pim-header-close-icon' />
                    </div>
                </div>

                <div className='pim-body'>
                    {
                        pitchData && !loading ?
                            <div className='pim-content'>
                                <div className='pim-upper-body'>
                                    <div className='pim-salary-header d-flex justify-content-between'>
                                        <span className='pim-salary-header-txt pim-common-header-txt'>
                                            Salary
                                        </span>

                                        <span className='pim-salary-header-txt pim-common-header-txt'>
                                            {pitchData?.salaryAmount}
                                        </span>
                                    </div>

                                    <div className='pim-salary-body'>
                                        <ul className='pim-salary-body-list'>
                                            {
                                                pitchData.salary.map((salaryItem, salaryItemInd) => {
                                                    return (
                                                        <li key={salaryItemInd} className='pim-salary-body-list-item pim-common-list-item-txt'>{salaryItem}</li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>

                                    <div className='pim-avg-earnings-header d-flex justify-content-between'>
                                        <span className='pim-avg-earnings-header-txt pim-common-header-txt'>
                                            Average Earnings
                                        </span>

                                        <span className='pim-avg-earnings-header-txt pim-common-header-txt'>
                                            {pitchData?.earningPerOrder}
                                        </span>
                                    </div>

                                    <div className='pim-hours-order'>
                                        <div className='pim-hours'>
                                            <div className='pim-hours-header pim-common-header-txt'>
                                                Hours
                                            </div>
                                            <div className='pim-hours-body'>
                                                <ul className='pim-hours-body-list'>
                                                    {
                                                        pitchData.hours.map((hourItem, hourItemInd) => {
                                                            return (
                                                                <li key={hourItem} className='pim-hours-body-list-item pim-common-list-item-txt'>{hourItem}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>

                                        

                                        {
                                            pitchData.orders && (
                                                <>
                                                    <div className='pim-vertical-divider show-only-desktop' />
                                                    <div className='pim-orders'>
                                                        <div className='pim-orders-header pim-common-header-txt'>
                                                            Orders
                                                        </div>
                                                        <div className='pim-orders-body'>
                                                            <ul className='pim-orders-body-list'>
                                                                {
                                                                    pitchData.orders.map((orderItem, orderItemInd) => {
                                                                        return (
                                                                            <li key={orderItem} className='pim-orders-body-list-item pim-common-list-item-txt'>{orderItem}</li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>

                                    {
                                        pitchData?.additionalBenefits && (
                                            <>
                                                <div className='pim-additonal-benefits-header d-flex justify-content-between'>
                                                    <span className='pim-additonal-benefits-header-txt pim-common-header-txt'>
                                                        Additional Benefits
                                                    </span>
                                                </div>
                                                <div className='pim-additonal-benefits-body'>
                                                    <ul className='pim-additonal-benefits-body-list'>
                                                        {
                                                            pitchData.additionalBenefits.map((benefit, benefitIndex) => {
                                                                return (
                                                                    <li key={benefit} className='pim-additonal-benefits-body-list-item pim-common-list-item-txt'>{benefit}</li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>

                                <div className='pim-other-details'>
                                    <div className='pim-other-details-header d-flex align-items-center jp-cp' onClick={() => showOtherDetails ? showOtherDetailsSection(false) : showOtherDetailsSection(true)}>
                                        {
                                            showOtherDetails ?
                                                <DownOutlined className='pim-other-details-header-icon pim-arrow-icon' />
                                                :
                                                <RightOutlined className='pim-other-details-header-icon pim-arrow-icon' />
                                        }
                                        <span className='pim-body-header-txt pim-section-header'>
                                            Other Details
                                        </span>
                                    </div>
                                    {
                                        showOtherDetails ?
                                            <div className='pim-other-details-body'>
                                                {
                                                    pitchData.joiningFees ?
                                                        <div className='pim-joining-fees'>
                                                            <div className='d-flex justify-content-between'>
                                                                <span className='pim-common-header-txt'>
                                                                    Joining Fees
                                                                </span>
                                                                <span className='pim-common-header-txt'>
                                                                    {pitchData.joiningFees}
                                                                </span>
                                                            </div>

                                                            {
                                                                pitchData.joiningFeeCondition && pitchData.joiningFeeCondition !== "" ?
                                                                    <div className='pim-other-details-list'>
                                                                        <ul>
                                                                            <li className='pim-common-list-item-txt'>{pitchData.joiningFeeCondition}</li>
                                                                        </ul>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }

                                                        </div>

                                                        :
                                                        null
                                                }
                                                {
                                                    pitchData.insuranceCover ?
                                                        <div className='pim-insurance-header d-flex justify-content-between'>
                                                            <span className='pim-common-header-txt'>
                                                                Insurance Cover
                                                            </span>
                                                            <span className='pim-common-header-txt'>
                                                                {pitchData.insuranceCover}
                                                            </span>
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                            :
                                            null
                                    }
                                </div>

                                <div className='pim-tips'>
                                    <div className='pim-tips-header d-flex align-items-center jp-cp' onClick={() => showTips ? showTipsSection(false) : showTipsSection(true)}>
                                        {
                                            showTips ?
                                                <DownOutlined className='pim-tips-header-icon pim-arrow-icon' />
                                                :
                                                <RightOutlined className='pim-tips-header-icon pim-arrow-icon' />
                                        }
                                        <span className='pim-body-header-txt pim-section-header'>
                                            Tips for Maximizing Earnings
                                        </span>
                                    </div>
                                    {
                                        showTips ?
                                            pitchData.tips ?
                                                <div className='pim-tips-body'>
                                                    <div className='pim-tips-list'>
                                                        <ul>
                                                            {
                                                                pitchData.tips.map((pitchItem, pitchItemInd) => {
                                                                    return (
                                                                        <li className='pim-common-list-item-txt' key={pitchItemInd}>{pitchItem}</li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            :
                                            null
                                    }
                                </div>

                                {
                                    pitchData.offers && pitchData.offers.length > 0 ?
                                        <div className='pim-offers'>
                                            <div className='pim-offers-header d-flex align-items-center'>
                                                <NotificationFilled className='pim-offers-header-icon' />
                                                <span className='pim-offers-header-txt'>
                                                    OFFERS
                                                </span>
                                            </div>

                                            <div className='pim-offers-body d-flex'>
                                                {
                                                    pitchData.offers.map((offerItem, offerItemInd) => {
                                                        return (
                                                            <div className='pim-offers-card' key={offerItemInd}>
                                                                <span className='pim-offer-card-header pim-common-header-txt'>
                                                                    {offerItem.offerKey == "vahan wallet" ? "Vahan Wallet" : offerItem.offerKey == "vpl" ? "IPL Special Offer" : offerItem.offerKey}
                                                                </span>

                                                                <div className='pim-offers-card-body'>
                                                                    <ul>
                                                                        {
                                                                            offerItem.offerList.map((offerListItem, offerListItemInd) => {
                                                                                return (
                                                                                    <li className='pim-offer-card-list-item' key={offerListItemInd}>
                                                                                        {offerListItem}
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        :
                                        null
                                }

                            </div>
                            :
                            !pitchData && !loading ?
                                <span className='pim-no-data'>
                                    No pitch found for this client
                                </span>
                                :
                                <Skeleton className='pim-loading' />
                    }
                </div>
            </div>
        </Modal>
    )
}

export default PitchModal; 