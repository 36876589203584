import { Button, Input, notification, Select, Upload } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import {UploadOutlined} from '@ant-design/icons';
import { AppContext } from '../../../App';
import { postAdditionalInfo, uploadImageToS3 } from '../../../service/additionalInfoService';
import commonFunction from '../../../utils/commonFunction';
import { useHistory } from "react-router-dom";
import { getSwiggySocFormFields, submitSwiggySocForm } from '../../../service/SwiggyAdditionalInfoService';

function SwiggySocAdditionalInfo() {
    const {mitraReducer,mitraDispatch, setAlert,setSpin } = useContext(AppContext);
    const history = useHistory();
    const [lead] = useState(commonFunction.getAllsearchParams(history.location.search));
    const [errorField, setErrorField] = useState(null);

    const [formFields, setFormFields] = useState([]);

    const setValue = (fieldIndex, value) => {
        const modifiedFormFields = [...formFields];

        modifiedFormFields[fieldIndex].selectedValue = value;

        setFormFields([...modifiedFormFields]);
    }

    const handleBeforeUpload = (file) => {
        // Return false to prevent upload
        return false;
    };

    const showLoader = (value, message=null) => {
        setSpin({
            loading: value, //false or true when actived
            delay: null,
            tip: message || 'Updating Information...'  //Loading copy here
        })
      }

      const checkErrors = (res) => {
        const newErrors = [];
        for (let key in res.data) {
            if (res.data[key] !== "200") {
                newErrors.push(`${key}: ${res.data[key]}`)
            }
        }
    
        if(newErrors.length) {
            // setErrors([...newErrors]);
            alert(newErrors.join("\n"))
            return true;
        } 
        return false;
    }

    const handleUpload =(info, type, index) => { // type is one of the property of documents object
        showLoader(true);
    
        uploadImageToS3(info.file)
            .then(async(res)=> {
    
                if(res?.data?.imageUrl) {
    
                    const submissionData = {
                        [type]: {
                            url: res.data.imageUrl,
                            originalName: info?.file?.name,
                            size: info?.file?.size
                        }
                    }
    
                    const response = await postAdditionalInfo(submissionData, mitraReducer?.mitraInfo, lead);
    
                    if(!checkErrors(response)) {
                        setValue(index, res?.data?.imageUrl)
                    }
    
                    showLoader(false);
                } else {
                    showLoader(false);
                }
            }).catch(e=> {
                showLoader(false);
    
                notification['error']({
                    message: 'Image upload failed, please try again later.',
                  });
    
                console.log(e);
            })
      };

      const handleSubmit = async() => {
        for (let i = 0; i < formFields.length; i++) {
            if(formFields[i].required && !formFields[i].selectedValue) {
                setErrorField(formFields[i].key);
                notification['error']({
                    message: `${formFields[i].label} is required`
                })
                return;
            }
        }

        // call the API to send the data
        const payload = {
            "scheduledInterviewId": lead.scheduledInterviewId,
            "formFields": formFields
        }

        showLoader(true, 'Submitting Details');

        try {
            const response = await submitSwiggySocForm(payload);

            if(response?.data?.success) {
                notification['success'] ({
                    message: 'Details submitted successfully.'
                })

                history.replace({ pathname: "/recruiter-candidates" });
            } else {
                notification['error'] ({
                    message: 'Error submitting user data, please try again later.'
                })
            }

            showLoader(false, 'Fetching Details');
        }catch(err) {
            showLoader(false, 'Fetching Details');
            notification['error'] ({
                message: 'Error submitting details, please try again later.'
            })
        }
        
      }

      const getFormFields = async() => {
        showLoader(true, 'Fetching Details');
        try {
            const response = await getSwiggySocFormFields({scheduledInterviewId: lead.scheduledInterviewId});
            if(response?.data?.success && response?.data?.formFields?.length) {
                setFormFields([...response?.data?.formFields]);
            } else {
                notification['error'] ({
                    message: 'Error fetching details, please try again later.'
                })
            }

            showLoader(false, 'Fetching Details');
        } catch(err) {
            showLoader(false, 'Fetching Details');
            notification['error'] ({
                message: 'Error fetching form fields, please try again later.'
            })
        }
        
      }

      useEffect(() => {
        getFormFields();
      }, [])
      

  return (
    <div className="AdditionalInfoScreen mainContentZ">
        <div className='header-text'>Swiggy SOC Additional Form</div>
        <ul>
            <li style={{display: 'flex', textAlign: 'start', gap: '6px'}}>
                <b>Note:</b>
                <p>Please upload your candidates details and documents in this section. These will be directly submitted to Swiggy.</p>
            </li>
        </ul>
        <div className='form-content-container'>
            <div className="otp-verification-container">
                <div className='container-header'>Additional Details</div>

                <div className='pan-form-container'>

                    {
                       formFields?.map((field, index) => (
                        <>
                            <div className='label mt-24'>
                                {field?.label}
                                {field?.required ? <sup>*</sup>: null}
                            </div>
                            {
                                field?.type === 'input' ? (
                                    <Input
                                        maxLength={10}
                                        placeholder={`Enter ${field?.label}`}
                                        value={field?.selectedValue}
                                        onChange={(e) => setValue(index, e.target.value)}
                                        className={errorField===field?.key ? 'input-error':'input'}
                                    />
                                ): null
                            }

                            {
                                field?.type === 'dropDown' ? (
                                    <Select
                                        style={{ width: '100%', textAlign: 'start', marginTop: '8px' }}
                                        placeholder={`Select ${field?.label}`}
                                        value={field?.selectedValue}
                                        onChange={(item) => setValue(index, item)}
                                        optionLabelProp="value"
                                        options={field.options}
                                        className={errorField===field?.key ? 'input-error':''}
                                    />
                                ): null
                            }
                            
                            {
                                field?.type === 'upload' ? (
                                    field?.selectedValue ? (
                                    <div style={{display: 'flex', gap: '8px'}}>
                                        <img src={field?.selectedValue} width={150} height={150}/>
                                        <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>setValue(index, null)}>x</p>
                                    </div>
                                ) : (
                                    <Upload
                                        name="avatar"
                                        listType="picture-card"
                                        showUploadList={false}
                                        accept="image/*"
                                        className={errorField===field?.key ? 'input-error':''}
                                        onChange={(info)=>handleUpload(info, 'bankPassbookOrCancelledCheque', index)}
                                        beforeUpload={handleBeforeUpload}
                                        >
                                        <div>
                                            <Input type="file" accept="image/*" style={{ display: 'none' }} />
                                            <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                                        </div>
                                    </Upload>
                                ) ): null
                            }
                        </>
                       )) 
                    }

                </div>
            </div>
        </div>

        <div className='button-container'>
            <Button className='submit-button' onClick={()=>handleSubmit()}>Submit</Button>
        </div>
    </div>
  )
}

export default SwiggySocAdditionalInfo