import React, { useState, useContext, useEffect } from 'react';
import '../../../css/lead.scss';
// import useMoengage from '../../../hooks/useMoengage';
import { trackEvent } from '../../../service/engagementMonitoringService';
import CONSTANTS from '../../../constants/constants';
import useGAEventsTracker from '../../../service/useGSEventsTracker';
import moment from "moment";
import { CheckOutlined, UserAddOutlined, CloseOutlined, ExclamationCircleFilled, UpOutlined, DownOutlined, CloseCircleFilled, CheckCircleOutlined, HistoryOutlined, UploadOutlined, WhatsAppOutlined, UserOutlined, PhoneOutlined, CopyOutlined, EnvironmentOutlined, ArrowRightOutlined, InfoCircleOutlined, CalendarOutlined, DashOutlined, LineOutlined, EyeOutlined, BulbFilled, PhoneFilled } from '@ant-design/icons';
import { Button, Tag, Space, Divider } from 'antd';
import WhatsappTemplateModal from '../../whatsappTemplateModal/WhatsappTemplateModal';
import { useHistory } from 'react-router-dom';
import '../../../css/globalStyles.scss';

function PreReferredLead({ lead, index, setSpin, setShowDetail, setSelectedLead, mitra }) {
    const GAEventsTracker = useGAEventsTracker("Action buttons clicked")
    const [whatsAppMessageModal, setWhatsAppMessageModal] = useState(false);
    const [selectedApplicationIndex, setSelectedApplicationIndex] = useState(null);
    const [isDesktop, setIsDesktop] = useState(true);
    const history = useHistory();

    const onClickViewDetails = (e) => {
            trackEvent('view_details_button_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
            setSpin({
                loading: true, //false or true when actived
                delay: null,
                tip: 'Loading...'  //Loading copy here
            })
            setShowDetail(true);
            setSelectedLead(lead);
            GAEventsTracker('OpenViewDetails')
            setSpin({
                loading: false, //false or true when actived
                delay: null,
                tip: 'Loading...'  //Loading copy here
            })
            e.stopPropagation();
    }

    const openWhatsAppPop = (event, index) => {
        event.stopPropagation();
        setSelectedApplicationIndex(index);
        setWhatsAppMessageModal(true);
    }
    const copyPhoneNumberFunc = (event, phoneNumber) => {
        event.stopPropagation();
        navigator.clipboard.writeText(phoneNumber);
    }
    const whatsAppMessageModalCancel = (e) => {
        e.stopPropagation();
        setWhatsAppMessageModal(false);
    };
    const redirectToHomePgae = (e) => {
        history.push({
            pathname: '/home/add-candidate'
        });
    }
    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 768);
        };
        handleResize();

        // Add event listener to update value when window is resized
        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty dependency array to run effect only once on component mount



    return (
        <>
            {whatsAppMessageModal ?
                <WhatsappTemplateModal
                    lead={lead}
                    leadDetails={null}
                    whatsAppMessageModal={whatsAppMessageModal}
                    whatsAppMessageModalCancel={whatsAppMessageModalCancel}
                    selectedApplicationIndex={selectedApplicationIndex}
                ></WhatsappTemplateModal>
                : null}
            <div className="single-application-container" style={{ width: "100%" }} onClick={onClickViewDetails}>
                {/* <Divider style={{margin:'8px 0'}}/> */}
                <div id="single-container-hot-lead">
                    <Space key={index} direction='vertical' className="hot-lead-main-container-left-side">

                        <div className='d-flex justify-content-between' style={{ width: "100%" }}>
                            <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', width: "100%" }}>
                                <div className="single-job-container">
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className="client-logo">
                                                <img className="imgResponse" style={{ width: 16, heigth: 16 }} src={"/image/Rectangle-without-referral.png"} />
                                            </div>
                                            <div style={{ marginLeft: '1em', color: '#000000D9', fontWeight: '500', fontSize: '16px' }}>
                                                {lead.name}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex flex-row' style={styles.alignCenter}>
                                    <div className="leadsCardStatus d-flex flex-row" style={styles.alignCenter}>
                                        <Tag color="orange" style={{ fontWeight: 'bold', color: '#AF620F', borderColor: '#AF620F' }}>Pre-Referral</Tag>
                                    </div>

                                    <div className="referred-by" style={styles.alignCenter}>
                                        <CalendarOutlined style={{ marginLeft: '12px', marginRight: '3px' }} /> Uploaded on {moment(lead.addedOn).format('Do MMM YYYY')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Space>
                    {lead?.callLogsTagsArray?.length ?
                        <div className="hot-lead-main-container-right-side">
                            <div className='main-container-divider-right' style={isDesktop ? {height: '70px'} : {height: '100px'}}>
                                <Divider></Divider>
                                    <>
                                        <div className='hot-tip-main-container' style={{ marginTop: '10px' }}>
                                            <PhoneFilled className='call-notes-icon' />
                                            <span className='call-notes-heading'>Call Notes</span>
                                        </div>
                                        <div className='call-notes-tag'>
                                            {lead?.callLogsTagsArray?.map((item, idx) => (
                                                item ?
                                                    (item.toLowerCase().includes('not connected') ?
                                                        <Tag style={{ marginTop: 8 }}><CloseOutlined className='not-connected-tag' />{item}</Tag> :
                                                        (item.toLowerCase().includes('connected') ?
                                                            <Tag style={{ marginTop: 8 }}><CheckOutlined className='connected-tag' />{item}</Tag> :
                                                            <Tag style={{ marginTop: 8 }}>{item}</Tag>)
                                                    ) : null
                                            ))}
                                            {lead.callLogCommentArray?.[0] ?
                                                <div className='call-notes-comment'>
                                                    &ldquo;{lead.callLogCommentArray?.[0]}&rdquo;
                                                </div>
                                                : null}
                                        </div>
                                    </>
                            </div>
                        </div>
                    : null}
                </div>
                {/* for banners */}
                <div className='application-card-banners'>
                    <div style={{ alignItems: 'center' }} className='show-only-mobile'>
                        <div className='added-by-footer-hot-lead'>
                            {lead.addedBy ? <div className='added-mitra-name' style={{ width: '60%' }}><UserOutlined style={{ marginRight: '3px' }} />Added by&nbsp;<span className='display-mitra-name'>{lead.addedBy}</span></div> : null}
                        </div>
                    </div>
                    <Divider style={isDesktop ? {marginBottom: '16px'} : {}}></Divider>
                    <div className='d-flex justify-content-between show-only-desktop'>
                        {lead.addedBy ? <div className='added-mitra-name'><UserOutlined style={{ marginRight: '3px' }} />Added by&nbsp;<span className='display-mitra-name'>{lead.addedBy}</span></div> : null}
                        <div className='d-flex' style={{ alignItems: 'center', justifyContent: 'center' }}>
                            <div className="contact-buttons">
                                <Button onClick={(e) => copyPhoneNumberFunc(e, lead.phoneNumber)}
                                    icon={<CopyOutlined />} type="primary" ghost>
                                    {lead.phoneNumber}
                                </Button>
                                <Button onClick={(e) => openWhatsAppPop(e, index)} icon={<WhatsAppOutlined />} type="primary" ghost>Message</Button>
                                <Button onClick={(e) => redirectToHomePgae()} icon={<UserAddOutlined />} type="primary">Refer Candidate</Button>
                            </div>
                        </div>
                    </div>
                    {!isDesktop ? 
                    <div className='d-flex' style={{ alignItems: 'center', marginTop: '-10px', width: '100%' }}>
                            <div className="contact-buttons" style={{ width: '30%'}}>
                                <a
                                href={`tel:+91-${lead.phoneNumber}`}
                                onClick={(e) => {
                                    GAEventsTracker("CallCandidate"); e.stopPropagation();
                                    trackEvent('call_now_option_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)
                                }}>
                                <Button className='button-phone-number'
                                    icon={<PhoneOutlined />} type="primary" ghost>
                                </Button>
                                </a>
                                <Button onClick={(e) => openWhatsAppPop(e, index)} className='button-phone-number' type="primary" ghost icon={<WhatsAppOutlined />}></Button>
                            </div>
                        <div className='d-flex' style={{ width: '70%', justifyContent: 'flex-end'}}>
                            <Button style={{fontWeight: '600'}} className='d-flex align-items-center' onClick={(e) => redirectToHomePgae()} icon={<UserAddOutlined />} type="primary">Refer Candidate</Button>
                        </div>
                    </div> : null}
                </div>
            </div>
        </>
    );
}

const styles = {
    alignCenter: {
        alignItems: "center"
    },
    justifyCenter: {
        justifyContent: "center"
    },
    justifySb: {
        justifyContent: "space-between"
    }
}

export default PreReferredLead;
