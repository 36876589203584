import { Popover } from 'antd'
import React, { useState } from 'react'

function PopoverDropdown({children, content, visible, setVisible, onSelect=()=>1}) {
    
    const hide = (element) => {
        setVisible(false);
        onSelect(element)
      };

    const filterContent = (
        <div
        className="d-flex flex-column justify-content-start align-items-start"
        style={{ gap: '10px', fontFamily: 'Nunito Sans Bold', fontSize: '14px', lineHeight: '24px' }}
        >
        {content?.map((item) => {
        return (
            <button
            key={item.id}
            onClick={() => hide(item)}
            style={{
                gap: '10px',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: item?.isActive ? '600':'400',
                fontSize: '10px',
                lineHeight: '22px',
                textAlign:'start',
                color:'#252526',
                background: item?.isActive ? '#E5ECFF': '#FFF',
                width: '100%',
                borderRadius: '2px'
            }}
            >
            {item.name}
            </button>
        );
        })}
        </div>
    )

    const handleVisibleChange = (newVisible) => {
        setVisible(newVisible);
    };
  return (
    <Popover
        overlayStyle={{ width: '300px' }}
        placement="bottom"
        content={filterContent}
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisibleChange}
    >
        {children}
    </Popover>
  )
}

export default PopoverDropdown