import React, { useContext, useEffect, useState } from 'react'
import { Button, Select, notification } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { AppContext } from '../../../../App';
import { getBlinkitData, updateBlinkitData } from '../../../../service/BlinkitAdditionalFormService';

function BlinkitCitySelection({goToNextScreen,lead, leadAdditionalDetails}) {
    const [selectedCity, setSelectedCity] = useState();
    const [selectedCityDetails, setSelectedCityDetails] = useState();
    const [cityInfo, setCityInfo] = useState([]);
    const {mitraReducer,mitraDispatch, setAlert,setSpin } = useContext(AppContext);


    const showLoader = (value) => {
        setSpin({
            loading: value, //false or true when actived
            delay: null,
            tip: 'Loading...'  //Loading copy here
        })
    }

    const handleChange = (item, cityObject) => {
        setSelectedCity(item);
        setSelectedCityDetails(cityObject);
    };

    const checkErrors = (res) => {
        const newErrors = [];
        for (let key in res.data) {
            if (res.data[key] !== "200") {
                newErrors.push(`${key}: ${res.data[key]}`)
            }
        }
      
        if(newErrors.length) {
            // setErrors([...newErrors]);
            alert(newErrors.join("\n"))
            return true;
        } 
        return false;
      }

    const submitCity = async() => {

        if(!selectedCity) {
            notification['error']({
                message: 'Please select a city to continue',
              });
            return
        } else {
            // logic to submit city

            showLoader(true);
            try {
                const payload = {
                    phoneNumber: lead?.phoneNumber,
                    userId: lead?.userId,
                    mitraName: mitraReducer?.mitraInfo?.name,
                    mitraId: mitraReducer?.mitraInfo?.id,
                    dataToUpdate: {
                        updateCity: {
                            cityId: selectedCityDetails?.id,
                            blinkitWorkCity: selectedCity
                        }
                    }
                }

                const response = await updateBlinkitData(payload);

                if(response?.data?.updateCity?.data?.status?.code === 200) {
                    goToNextScreen();
                } else {
                    notification['error'] ({
                        message: 'Error submitting vehicle details.'
                    })
                }
                showLoader(false);
            } catch(err) {
                showLoader(false);
                notification['error'] ({
                    message: 'Error submitting vehicle details.'
                })
            }
        }
    }

    const fetchCitiesData = async() => {
        showLoader(true);
        try {

            const payload = {
                phoneNumber: lead?.phoneNumber,
                dataToFetch: {
                    getCity:{}
                }
            }
            const response = await getBlinkitData(payload);

            if(response?.data?.getCity?.status?.code === 200) {
                // do something
                showLoader(false);
                if(response?.data?.getCity?.allCities?.length) {
                    const cityData = response?.data?.getCity?.allCities;
                    for (let i = 0; i < cityData.length; i++) {
                        cityData[i].value = cityData?.[i]?.name?.text;
                        cityData[i].label = cityData?.[i]?.name?.text;

                        if(cityData[i].id === response?.data?.getCity?.selected?.id) {
                            // handling the condition wherein the city data was already submitted
                            setSelectedCity(response?.data?.getCity?.selected?.value);
                            setSelectedCityDetails(cityData[i]);
                        }
                    }
                    setCityInfo([...cityData]);
                }

            } else {
                showLoader(false);
                // do something
            }
            showLoader(false);
        } catch(e) {
            showLoader(false);
            //do something
            notification['error']({
                message: 'Error fetching cities.'
            })
        }
        
    }

    const filterOption = (input, option) =>(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    useEffect(() => {
        if(lead?.phoneNumber) {
            fetchCitiesData();
        }
      }, [lead])
    return (
        <>
    
            <div className='vehicle-type-container'>
                <div className='container-header'>City Details</div>
    
                <div className='city-details-container blinkit-city-container'>
                    <div className='label'>Select city<sup>*</sup></div>
                    <Select
                        style={{ width: '100%', textAlign: 'start', marginTop: '8px' }}
                        placeholder="Select one of the cities.."
                        value={selectedCity}
                        onChange={(v,o)=>handleChange(v,o)}
                        optionLabelProp="label"
                        options={cityInfo}
                        showSearch
                        filterOption={filterOption}
                    />
                </div>
            </div>
    
            <div className='button-container'>
                <Button className='submit-button' onClick={()=>submitCity()}>Next</Button>
            </div>
            
        </>
        
      )
}

export default BlinkitCitySelection
