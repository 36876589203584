import React from 'react';
import {PhoneOutlined} from '@ant-design/icons';


function CandidateDetailsHeader({lead, setShowDetail, setSelectedLead}) {
    return (
        <div className="contact-details">
            <div className="lead-name">
                {lead.lastName.includes('Unknown') ? lead.firstName : lead.firstName + " " + lead.lastName}
            </div>
            <div className="phone-container" onClick={(e) => {setShowDetail(true); setSelectedLead(lead)}}>
                <PhoneOutlined style={{margin: '4px 0px'}} />
                <div className="phoneNumber">{lead.parentPhoneNumber[0] ? `${lead.parentPhoneNumber[0]}(edited)`: lead.phoneNumber} </div>
            </div>
        </div>
    );
}

export default CandidateDetailsHeader;